import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';

import { CustomButton } from 'components/UIComponents/Button';
import ModalJoin from '../ModalJoin/ModalJoin';

import styles from './Hero.module.scss';

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isWidthMatches = useMediaQuery('(max-width:776px)');

  const handleModal = () => {
    const headerElement = document.getElementById('headerId');

    if (isModalOpen) {
      setIsModalOpen(false);

      if (headerElement) headerElement.style.paddingRight = '24px';
    } else {
      setIsModalOpen(true);

      if (headerElement && !isWidthMatches)
        headerElement.style.paddingRight = '41px';
    }
  };

  const style = {
    position: 'absolute' as 'absolute',
    bottom: '0',
    width: '100%',
  };

  return (
    <section className={styles.heroSection}>
      <div
        style={{
          gridColumnStart: 2,
          display: 'flex',
          flexDirection: 'column',
          justifySelf: 'center',
          width: isWidthMatches ? '100%' : '',
          alignItems: 'center',
        }}
      >
        <h1 className={styles.title}>LUXURY ALTERNATIVE ASSET MANAGEMENT</h1>
        <p className={styles.text}>
          Focused on selecting, securitizing and offering investment
          opportunities in the world's rarest luxury assets.
        </p>
        <CustomButton
          btnStyle="iconBtnTransparent"
          size="long"
          onClick={handleModal}
        >
          <span className={styles.buttonText}>
            JOIN OUR EXCLUSIVE COMMUNITY
          </span>
        </CustomButton>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isModalOpen}
          onClose={handleModal}
        >
          <Slide direction="up" in={isModalOpen} mountOnEnter unmountOnExit>
            <Box sx={style}>
              <ModalJoin handleModal={handleModal} />
            </Box>
          </Slide>
        </Modal>
      </div>
    </section>
  );
};

export default Hero;
