type MenuBarsBigProps = {
  color?: string;
};

const MenuBarsBig: React.FC<MenuBarsBigProps> = ({ color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M39.4643 8.21429H0.535714C0.393634 8.21429 0.257373 8.15784 0.156907 8.05738C0.0564412 7.95691 0 7.82065 0 7.67857L0 5.53571C0 5.39363 0.0564412 5.25737 0.156907 5.15691C0.257373 5.05644 0.393634 5 0.535714 5H39.4643C39.6064 5 39.7426 5.05644 39.8431 5.15691C39.9436 5.25737 40 5.39363 40 5.53571V7.67857C40 7.82065 39.9436 7.95691 39.8431 8.05738C39.7426 8.15784 39.6064 8.21429 39.4643 8.21429ZM39.4643 22.5H0.535714C0.393634 22.5 0.257373 22.4436 0.156907 22.3431C0.0564412 22.2426 0 22.1064 0 21.9643L0 19.8214C0 19.6793 0.0564412 19.5431 0.156907 19.4426C0.257373 19.3422 0.393634 19.2857 0.535714 19.2857H39.4643C39.6064 19.2857 39.7426 19.3422 39.8431 19.4426C39.9436 19.5431 40 19.6793 40 19.8214V21.9643C40 22.1064 39.9436 22.2426 39.8431 22.3431C39.7426 22.4436 39.6064 22.5 39.4643 22.5ZM39.4643 36.7857H0.535714C0.393634 36.7857 0.257373 36.7293 0.156907 36.6288C0.0564412 36.5283 0 36.3921 0 36.25L0 34.1071C0 33.9651 0.0564412 33.8288 0.156907 33.7283C0.257373 33.6279 0.393634 33.5714 0.535714 33.5714H39.4643C39.6064 33.5714 39.7426 33.6279 39.8431 33.7283C39.9436 33.8288 40 33.9651 40 34.1071V36.25C40 36.3921 39.9436 36.5283 39.8431 36.6288C39.7426 36.7293 39.6064 36.7857 39.4643 36.7857Z"
        fill={color}
      />
    </svg>
  );
};

export default MenuBarsBig;
