import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type LegalEntityInititalState from "./interface";

const initialState: LegalEntityInititalState = {
  entityFormationDocumentation: "",
  fileName: "",
};

const legalEntitySlice = createSlice({
  name: "legalEntity",
  initialState,
  reducers: {
    setEntityFile(state, { payload }: PayloadAction<LegalEntityInititalState>) {
      state.entityFormationDocumentation = payload.entityFormationDocumentation;
      state.fileName = payload.fileName;
    },
  },
});

export const { setEntityFile } = legalEntitySlice.actions;
export default legalEntitySlice.reducer;
