import ChartIcon from "assets/icons/ChooseUsIcons/Chart";
import CoinsIcon from "assets/icons/ChooseUsIcons/Coins";
import DiamondIcon from "assets/icons/ChooseUsIcons/Diamond";
import GlobeIcon from "assets/icons/ChooseUsIcons/Globe";
import UserIcon from "assets/icons/ChooseUsIcons/User";

import { Card } from "./types";

export const cards: Card[] = [
  {
    id: 1,
    Icon: DiamondIcon,
    text: "Invest in a curated selection of luxury assets such as precious gems, high jewelry, rare watches",
  },
  {
    id: 2,
    Icon: ChartIcon,
    text: "Diversify your portfolio and hedge against inflation with “hard” assets",
  },
  {
    id: 3,
    Icon: UserIcon,
    text: "Top industry experts advise on asset selection, valuation and authentication",
  },
  {
    id: 4,
    Icon: GlobeIcon,
    text: "Highly curated investment experiences with a like minded global investment community",
  },
  {
    id: 5,
    Icon: CoinsIcon,
    text: "Receive proceeds from sale of asset",
  },
  {
    id: 6,
    Icon: DiamondIcon,
    text: "Invest in a curated selection of luxury assets such as precious gems, high jewelry, rare watches",
  },
  {
    id: 7,
    Icon: ChartIcon,
    text: "Diversify your portfolio and hedge against inflation with “hard” assets",
  },
  {
    id: 8,
    Icon: UserIcon,
    text: "Top industry experts advise on asset selection, valuation and authentication",
  },
  {
    id: 9,
    Icon: GlobeIcon,
    text: "Highly curated investment experiences with a like minded global investment community",
  },
  {
    id: 10,
    Icon: CoinsIcon,
    text: "Receive proceeds from sale of asset",
  },
];
