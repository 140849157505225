import SwiperBlock from "../Swiper/SwiperBlock";

import styles from "./ChooseUs.module.scss";

const ChooseUs: React.FC = () => {
  return (
    <section className={styles.wrapper}>
      <h2 className={styles.title}>Why Luxus?</h2>
      <SwiperBlock />
    </section>
  );
};

export default ChooseUs;
