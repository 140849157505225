import { Page } from 'types/Page';
import { ROUTES } from 'utils/constants';

export const unsignedPages: Page[] = [
  { id: 1, name: 'ASSETS', url: '/assets', target: '_self' },
  /* {
    id: 2,
    name: "HOW IT WORKS",
    url: "https://luxusco.com/how-it-works/",
    target: "_blank",
  },*/
  // { id: 3, name: "VIP", url: "/vip", target: "_self" },
  /* { id: 4, name: "INSIGHTS", url: "/insights", target: "_self" },*/
];

export const signedPages: Page[] = [
  { id: 1, name: 'ASSETS', url: '/dashboard', target: '_self' },
  /*{ id: 2, name: "PORTFOLIO", url: "/portfolio", target: "_self" },*/
  /*{
    id: 3,
    name: "HOW IT WORKS",
    url: "https://luxusco.com/how-it-works/",
    target: "_blank",
  },*/
  // { id: 4, name: "VIP", url: "/vip", target: "_self" },
  /* { id: 5, name: "INSIGHTS", url: "/insights", target: "_self" },*/
];

export const AccreditedInvestorPages: Page[] = [
  { id: 1, name: 'THE TIARA', url: '/tiara', target: '_self' },
  { id: 2, name: 'MISSED THE CUT', url: ROUTES.THOROUGHBRED, target: '_self' },
];

export const aboutMenuPages: Page[] = [
  { id: 1, name: 'WHO WE ARE', url: '/AboutLuxus', target: '_self' },
  { id: 2, name: 'FAQ', url: '/faq', target: '_self' },
];

export enum HEADER_OPTIONS {
  BLACK = 'black',
  WHITE = 'white',
}
