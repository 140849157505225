import { ArrowBottomIcon } from "assets/icons/arrow-bottom";
import React, { useState } from "react";
import { DropDownSeparateProps, DropdownOption } from "./DropDown.type";
import { Container, ListContainer } from "./styledComponents";
import { dropdownStyles } from "./styles";

const DropdownSeparate: React.FC<DropDownSeparateProps> = ({
  options,
  value,
  onChange,
  arrowColor,
  borderColor,
  color,
  placeholder,
  placeholderColor = "#7B7B8F",
  maxHeight,
  name,
  changeSelectedDropdown,
  selectedDropdown,
}) => {
  const [selectedLabel, setSelectedLabel] = useState(
    value?.label || placeholder
  );

  const handleDropdown = () => {
    changeSelectedDropdown(name);
  };

  const onOptionClicked = (option: DropdownOption) => () => {
    onChange(option);
    changeSelectedDropdown("");
    setSelectedLabel(option.label);
  };

  const openDropdownCondition = name === selectedDropdown;

  return (
    <Container
      isOpen={openDropdownCondition}
      style={{ borderColor: borderColor, color: color, cursor: "pointer" }}
      onClick={handleDropdown}
    >
      <div
        style={Object.assign(
          {},
          value?.label
            ? dropdownStyles.header
            : dropdownStyles.headerPlaceholder,
          { color: placeholderColor }
        )}
      >
        {selectedLabel}
        <span
          style={{
            transition: "transform 0.3s ease",
            transform: `${
              openDropdownCondition ? "rotate(90deg)" : "rotate(360deg)"
            }`,
          }}
        >
          <ArrowBottomIcon color={arrowColor ? arrowColor : "#fff"} />
        </span>
      </div>
      <ListContainer isOpen={openDropdownCondition} style={{ maxHeight }}>
        {options.map((option) => (
          <li
            onClick={onOptionClicked(option)}
            key={option.value}
            style={dropdownStyles.listItem}
          >
            {option.label}
          </li>
        ))}
      </ListContainer>
    </Container>
  );
};

export default DropdownSeparate;
