export function debounce<T extends any[]>(
  func: (...args: T) => void,
  delay: number
) {
  let timerId: NodeJS.Timeout | null = null;
  return function (...args: T) {
    if (timerId !== null) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}
