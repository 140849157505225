export const classes = {
  modalWrapper: (visible: boolean) => ({
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",

    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: visible ? 100 : -1,
    opacity: visible ? 1 : 0,
    transition: "all 0.5s ease",
  }),

  modal: (visible: boolean) => ({
    maxWidth: "345px",
    width: "100%",
    height: "fit-content",
    background: "#F6F3F2",
    padding: "83px 40px 40px 40px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    transform: visible ? "translateY(-1%)" : "translateY(100%)",
    transition: "all 0.5s ease",
  }),

  title: {
    color: "#000",
    textAlign: "center",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Telugu MN",
    fontSize: "25px",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
  },

  subTitle: {
    color: "#000",
    textAlign: "center",
    leadingTrim: "both",
    fontFamily: "Telugu MN",
    fontSize: "24px",
    fontWeight: "400",
    letterSpacing: "0.37px",
    textTransform: "uppercase",
  },

  text: {
    color: "#000",
    leadingTrim: "both",
    fontFamily: "Futura PT Light",
    fontSize: "14px",
    fontWeight: "400",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
  },
};
