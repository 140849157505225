import { themeData } from "../../../../utils/theme";

export const commonStyles = {
  container: {
    "& .MuiTextField-root": {
      borderRadius: "5px",
    },
    "& .MuiOutlinedInput-root": {
      height: "58px",
      fontWeight: "300",
      fontSize: "20px",
    },
  },
};

export type ClassesNames = keyof typeof commonStyles;
