import Bloomberg from "../../assets/images/bloomberg.png";
import Bloomberg2x from "../../assets/images/bloomberg2x.png";
import BusinessInsider from "../../assets/images/business-insider.png";
import BusinessInsider2x from "../../assets/images/business-insider2x.png";
import Forbes from "../../assets/images/forbes.png";
import Forbes2x from "../../assets/images/forbes2x.png";
import TechCrunch from "../../assets/images/techcrunch.png";
import TechCrunch2x from "../../assets/images/techcrunch2x.png";

export const icons = [
  [Forbes, Forbes2x],
  [BusinessInsider, BusinessInsider2x],
  [Bloomberg, Bloomberg2x],
  [TechCrunch, TechCrunch2x],
];
