import { createStyles, makeStyles } from '@mui/styles'
import mixStyleRules from '../../../utils/mixStyleRules'
import { commonStyles } from './common'
import { FooterClassNameMap } from './index'

export const useMobileStyles: (props?: any) => FooterClassNameMap = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        secondContainer: {
          padding: '8px 0',
        },
        copyrightBox: {
          flexDirection: 'column',
          alignItems: 'inherit',
          borderTop: 'none',
          paddingTop: '0',
          marginTop: '0',
          height: '70px',
          justifyContent: 'space-around',
        },
        dividerBox: {
          paddingLeft: 'none',
          textAlign: 'center',
          alignContent: 'normal',
        },
        socialDataLink: {
          fontSize: '30px !important',
        },
        footerTextCols: {
          columns: '1',
        },
        finePrint: {
          marginBottom: '20px',
        },
        finePrintTitle: {
          paddingBottom: '0',
          margin: '20px 0 15px 3%',
          fontSize: '20px',
          fontWeight: '500',
        },
        upperFooter: {
          padding: '0',
          borderTop: '1px solid rgba(132, 132, 132, 0.7)',
          borderBottom: '0 solid transparent',
          margin: '20px 0 30px',
        },
      })
    )
)
