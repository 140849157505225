import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import App from "./App";
import { store } from "./store";
console.log("NEW FRONTEND VERSION: 1.1.6");
ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
  document.querySelector("#root")
);
