export const fileContentValue = {
  front: {
    content: "",
    name: "",
  },
  back: {
    content: "",
    name: "",
  },
  passport: {
    content: "",
    name: "",
  },
};
export const profileData = {
  domicile: "",
  firstName: "",
  lastName: "",
  socialSecurityNumber: "",
  dob: "",
  phone: "",
  primCountry: "",
  primAddress1: "",
  primAddress2: "",
  primCity: "",
  primState: "",
  primZip: "",
  passportNumber: "",
  passportScan: null,
  idType: "",
  idNumber: "",
  idFront: null,
  idBack: null,
};

export const complianceData = {
  accreditation: "",
  employment: "",
  annualIncome: "",
  netWorth: "",
  risk: "",
  associtationFinra: "",
  hasFinraLicense: "",
  finraLicense: "",
  associatedOrganization: "",
  memberOfTradedPublicly: "",
};
export const beneficialOwnerData = {
  firstName: "",
  lastName: "",
  primCountryCitizenship: "",
  primCountry: "",
  primAddress1: "",
  primCity: "",
  primState: "",
  primZip: "",
  phone: "",
  email: "",
  birthDate: "",
  ssn: "",
  idNumber: "",
  idType: "",
  passportNumber: "",
};

export const entityTypes = [
  "Corporation",
  "Limited Liability Company",
  "Limited Partnership",
  "General Partnership",
  "Limited Liability Partnership",
  "Sole Proprietorship",
] as Array<string>;

export const errorState = {
  firstName: false,
  lastName: false,
};
