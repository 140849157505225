import { ClassNameMap } from "@mui/styles";
import useGetDisplayType from "../../../../../../hooks/useGetDisplayType";
import getClassNameMap from "../../../../../../utils/getClassNameMap";

import type { ClassNames } from "./common";
import { useDesktopStyles } from "./desktop";
import { useMobileStyles } from "./mobile";
import { useTabletLandscapeStyles } from "./tabletLandscape";
import { useTabletPortraitStyles } from "./tabletPortrait";

export type Classes = ClassNameMap<ClassNames>;

export const useStyles = (): Classes => {
  const displayType = useGetDisplayType();

  return getClassNameMap(
    displayType,
    useDesktopStyles(),
    useMobileStyles(),
    useTabletLandscapeStyles(),
    useTabletPortraitStyles()
  );
};
