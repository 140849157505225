import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  monthSelect: {
    width: "146px",
    height: "58px",
  },
  selectPlaceholder: {
    color: "rgb(148, 148, 148)",
  },
  daySelect: {
    width: "100px",
    height: "58px",
  },
  yearSelect: {
    width: "122px",
    height: "58px",
  },
  errorText: {
    color: themeData.ErrorTextColor,
    fontSize: "0.75rem",
    lineHeight: "1.66",
    marginTop: "4px",
    marginX: "14px",
    marginBottom: "0px",
  },
};

export type ClassesNames = keyof typeof commonStyles;
