import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { PaymentType } from '../../../types/enums/PaymentType';
import PaymentEdit from '../../Profile/components/PaymentEdit';

interface PaymentSelectorProps {
  paymentChoices: { [type: string]: React.ReactFragment };
  onPaymentChoicesUpdated?: () => void;
  paymentSelected?: PaymentType;
  onPaymentSelectedUpdated?: (paymentType: PaymentType) => void;
  onAgreementsUpdated?: (agreed: boolean) => void;
}

const PaymentSelector = ({
  paymentChoices,
  onPaymentChoicesUpdated,
  paymentSelected,
  onPaymentSelectedUpdated,
  onAgreementsUpdated,
}: PaymentSelectorProps) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentType | undefined>(
    paymentSelected
  );
  const [showEditPayment, setShowEditPayment] = useState(false);
  const [singleEdit, setSingleEdit] = useState(false);
  const [creditTermsAgreed, setCreditTermsAgreed] = useState(false);

  useEffect(() => {
    var agreed =
      paymentMethod !== PaymentType.CARD || creditTermsAgreed === true;
    if (onAgreementsUpdated) onAgreementsUpdated(agreed);
  }, [creditTermsAgreed, paymentMethod, onAgreementsUpdated]);

  const onPaymentSelectionHandler = (selection: PaymentType) => () => {
    setPaymentMethod(selection);
    if (onPaymentSelectedUpdated) onPaymentSelectedUpdated(selection);
  };

  const onCreditTermsAgreedChanged = () => {
    setCreditTermsAgreed(!creditTermsAgreed);
  };

  const addPaymentOptions = (current: string[]) => {
    var label: string | null = null;
    var canAddType: PaymentType | undefined = undefined;

    if (!current.includes(PaymentType.BANK)) {
      canAddType = PaymentType.BANK;
      label = 'Add a bank account';
    }
    if (!current.includes(PaymentType.CARD)) {
      canAddType = PaymentType.CARD;
      label = 'Add a credit card';
    }
    if (
      !current.includes(PaymentType.CARD) &&
      !current.includes(PaymentType.BANK)
    ) {
      label = 'Add a payment method';
      canAddType = undefined;
    }
    // Both already exists then no other options.
    if (!label)
      return <Box sx={{ borderTop: '1px solid #d7d7d7' }}>&nbsp;</Box>;
    return (
      <Box
        sx={{
          // padding: '0.2rem',
          // padding: '2rem 1.5rem 1.5rem 1.5rem',
          padding: 0,

          borderTop: '1px solid #d7d7d7',
          borderBottom: '1px solid #d7d7d7',
        }}
      >
        <Button
          onClick={() => {
            setSingleEdit(canAddType !== undefined);
            setPaymentMethod(canAddType);
            setShowEditPayment(true);
          }}
          sx={{ padding: 0 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginY: '8px',
              height: '42px',
            }}
          >
            <AddCircleIcon
              style={{
                color: '#287EF7',
              }}
            />
            <Typography
              component="h6"
              align="left"
              sx={{
                color: '#277ef7',
                fontSize: '12px',
                marginLeft: '1rem',
              }}
            >
              {label}
            </Typography>
          </Box>
        </Button>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        width: { xs: '100%', md: '669px' },
        paddingX: { xs: '17px', md: '33px' },
        paddingY: '16px',
      }}
    >
      <Box
        sx={{
          marginTop: '1rem',
        }}
      >
        {Object.keys(paymentChoices).map((k) => {
          return (
            <Box
              key={k}
              onClick={onPaymentSelectionHandler(k as PaymentType)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 0,
                borderTop: '1px solid #d7d7d7',
                cursor: 'pointer',
              }}
            >
              {paymentMethod === k ? (
                <RadioButtonCheckedIcon
                  style={{
                    color: '#287EF7',
                    marginRight: '1rem',
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  style={{
                    color: '#287EF7',
                    marginRight: '1rem',
                  }}
                />
              )}
              {paymentChoices[k]}
            </Box>
          );
        })}
      </Box>
      {addPaymentOptions(Object.keys(paymentChoices))}
      <PaymentEdit
        showModal={showEditPayment}
        singleEdit={singleEdit}
        selectedType={paymentMethod}
        handleClose={() => {
          setShowEditPayment(false);
        }}
        onCancel={() => {
          setShowEditPayment(false);
        }}
        onSave={() => {
          setShowEditPayment(false);
          if (onPaymentChoicesUpdated) onPaymentChoicesUpdated();
        }}
      />
      <div>
        {paymentMethod === PaymentType.CARD ? (
          <Box
            style={{
              marginTop: '17px',
              display: 'flex',
            }}
          >
            <Box>
              {creditTermsAgreed ? (
                <CheckBoxIcon onClick={onCreditTermsAgreedChanged} />
              ) : (
                <CheckBoxOutlineBlankIcon
                  onClick={onCreditTermsAgreedChanged}
                />
              )}
            </Box>
            <Box>
              <Typography
                component="h6"
                align="left"
                sx={{
                  color: '#000000',
                  marginLeft: '5px',
                  fontSize: '12px',
                }}
              >
                I understand that the fees associated with this transaction are
                non-refundable.
              </Typography>
            </Box>
          </Box>
        ) : null}
      </div>
    </Box>
  );
};

export default PaymentSelector;
