import React, {useState, useEffect, useRef} from "react";
import {Box, Typography, Stack, Popover, useMediaQuery} from "@mui/material";

import {accountStatus} from "../../../../../utils";
import {IntentTradeType} from "../../../../../types/Trade";

import {Offering} from "../../../../../types/Offerings";

import {useStyles} from "./styles/";
import {Button} from "../../../../../components/simpleComponents/Button";
import {RowStack} from "../../../../../components/simpleComponents/Stack";
import {IOIPopover} from "../IOIPopover";
import {LinkElement} from "../../../AssetDetails/components/AssetInfo/LinkElement/LinkElement";

import useGetDisplayType from "../../../../../hooks/useGetDisplayType";
import {DisplayTypes} from "../../../../../types/enums/DisplayTypes";

import Info_icon from "../../../../../assets/images/InfoIcon.png";
import style from './style.module.scss'
import {CustomButton} from "../../../../../components/UIComponents/Button";

export interface AssetInfoProps {
  offer: Offering;
  onBuySharesClick: () => void;
  onNotifyClick: () => void;
  notifyDisabled: boolean;
}

const IOIInfo: React.FC<React.PropsWithChildren<AssetInfoProps>> = function ({
                                                                               offer,
                                                                               onBuySharesClick,
                                                                               onNotifyClick,
                                                                               notifyDisabled,
                                                                             }): JSX.Element {
  const {
    title,
    nameTitleStyles,
    boldText,
    dateOnMarkStyle,
    bottomStack,
    reserveShrBtn,
    image,
    popoverPaper,
    popoverText,
    popoverTriangle,
  } = useStyles();
  const desktop = !useGetDisplayType(DisplayTypes.Mobile);

  const tabletLandscape = useGetDisplayType(DisplayTypes.TabletLandscape);
  const tabletPortrait = useGetDisplayType(DisplayTypes.TabletPortrait);

  const [popoverAnchor, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverSecondAnchor, setPopoverSecondAnchor] =
    useState<HTMLImageElement | null>(null);
  const popoverContainerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const open = Boolean(popoverSecondAnchor);

  const [
    isIndicateInterestButtonDisabled,
    setIsIndicateInterestButtonDisabled,
  ] = useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onImageMouseEnter = (event: React.MouseEvent) =>
    setPopoverSecondAnchor(event.currentTarget as HTMLImageElement);
  const onImageMouseLeave = () => setPopoverSecondAnchor(null);

  useEffect(() => {
    if (offer) {
      const isDisapproved = accountStatus().status === "disapproved";
      const isApproved = accountStatus().isApproved;
      const isIntentTradeType = offer.status === IntentTradeType;

      setIsIndicateInterestButtonDisabled(
        !(isApproved || isIntentTradeType || isDisapproved)
      );
    }
  }, [offer]);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setAnchorEl(null);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  console.log("portrait", tabletPortrait);

  return (
    <div className={style.container}>
      <h2 className={style.name}>{offer?.name}</h2>
      {/*  <img src="/logo/diamond.svg" /> >*/}
      {/* <Typography className={dateOnMarkStyle}>
            LIVE ON {offer?.DateOnMarket ?? "9/9"}
          </Typography> */}

      {/* средний блок с описанием */}
      <hr/>
      <div className={style.descrContainer}>
        <h6 className={style.deal}>DEAL SHEET</h6>
        <LinkElement offer={offer} name="download" btnStyle={'secondary'} label={desktop ? 'DOWNLOAD' : 'DOWNLOAD DEAL SHEET'}/>
      </div>
      <div className={style.descrContainer}>
        <h6>OFFERING CIRCULAR</h6>
        <LinkElement offer={offer} type="secfiling" name="SEC" btnStyle={'sec'} label={'SEC'}/>
      </div>
      <div className={style.descrContainer}>
        <h6>THIRD PARTY REPORTS</h6>
        <div className={style.links}>
          <LinkElement offer={offer} name="GIA" btnStyle={'gia'}/>
          <LinkElement offer={offer} name="IGI" btnStyle={'igi'}/>
          <LinkElement offer={offer} name="FCRF" btnStyle={'fcrf'}/>
          <LinkElement offer={offer} name="ESG" btnStyle={'esg'} label={'ESG'}/>
        </div>
      </div>
      <hr/>

      <div className={style.descrContainer}>
        <h6>TOTAL ASSET VALUE</h6>
        <h4>${offer?.offering?.targetAmount as string}</h4>
      </div>
      <div className={style.descrContainer}>
        <h6>SHARE PRICE</h6>
        <h4>${offer?.offering?.unitPrice as string}</h4>
      </div>

      <div className={style.btnContainer}>
        {!notifyDisabled && (
          <CustomButton
            btnStyle={'grey'}
            onClick={onNotifyClick}
            disabled={notifyDisabled}
            style={{textDecoration: 'underline'}}
          >
            SKIP, NOTIFY ME WHEN IT’S LIVE
          </CustomButton>
        )}
        <CustomButton
          btnStyle={'primary'}
          onClick={onBuySharesClick}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          //disabled={isIndicateInterestButtonDisabled}
        >
          RESERVE SHARES
        </CustomButton>
      </div>


      {/* нижний блок с кнопками */}
      {/*{tabletLandscape || tabletPortrait ? (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          sx={{marginBottom: "10px"}}
          ref={wrapperRef}
        >
          {tabletLandscape && !notifyDisabled && (
            <Button
              view="link"
              onClick={onNotifyClick}
              sx={{
                fontSize: "12px !important",
                fontWeight: "300 !important",
                marginLeft: "0 !important",
                padding: "0 !important",
              }}
            >
              Skip, notify me when it’s live
            </Button>
          )}
          <CustomButton
            btnStyle={'primary'}
            onClick={onBuySharesClick}
            disabled={isIndicateInterestButtonDisabled}
          >
             INDICATE INTEREST
            RESERVE SHARES
          </CustomButton>
          <Button view="link" onClick={handlePopoverOpen}>
            <img src={Info_icon}/>
          </Button>
        </Stack>
      ) : (
        <Stack direction="row" spacing={2} className={bottomStack}>
          {!notifyDisabled && (
            <Button
              view="link"
              onClick={onNotifyClick}
              disabled={notifyDisabled}
            >
              Skip, notify me when it’s live
            </Button>
          )}
          <Button
            view="primary"
            onClick={onBuySharesClick}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            disabled={isIndicateInterestButtonDisabled}
            className={reserveShrBtn}
          >
            RESERVE SHARES
          </Button>*/}
      {/*<img
            src="/logo/fee_icon.svg"
            onMouseEnter={onImageMouseEnter}
            onMouseLeave={onImageMouseLeave}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            alt=""
            className={image}
          />*/}

      {/*<Box ref={popoverContainerRef}>
            <Popover
              id="mouse-over-popover"
              open={Boolean(popoverSecondAnchor)}
              anchorEl={popoverSecondAnchor}
              disableRestoreFocus
              onMouseLeave={onImageMouseLeave}
              sx={{
                pointerEvents: "none",
              }}
              anchorOrigin={{
                vertical: desktop ? "center" : "bottom",
                horizontal: "right",
              }}
              transformOrigin={{vertical: "center", horizontal: "left"}}
              PaperProps={{
                ref: popoverRef,
                className: popoverPaper,
              }}
              container={popoverContainerRef.current}
            >
              <Stack direction="row" spacing={1}>
                <Typography className={popoverText} fontWeight="500">
                  Management Fee:
                </Typography>
                <Typography className={popoverText} fontWeight="300">
                  75 bps
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography className={popoverText} fontWeight="500">
                  Performance Fee:
                </Typography>
                <Typography className={popoverText} fontWeight="300">
                  20% of Net Profits (8% hurdle)
                </Typography>
              </Stack>
              <Box className={popoverTriangle}/>
            </Popover>
          </Box>*/}
      {/*  </Stack>
      )}*/}
      {/*<IOIPopover popoverAnchor={popoverAnchor}/>*/}
    </div>
  );
};

export default IOIInfo;
