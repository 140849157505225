import { Offering } from '../types/Offerings';

import yellow_diamond from '../assets/offerImages/Yellow_Diamond.png';
import d_flawless_diamond from '../assets/offerImages/D_Flawless_Diamond.png';

const getOfferImageByName = (offer: Offering): string => {
  const converter: Record<'Yellow Diamond' | 'D Flawless Diamond', string> = {
    'D Flawless Diamond': d_flawless_diamond,
    'Yellow Diamond': yellow_diamond,
  };
  return (
    converter[offer.name as 'Yellow Diamond' | 'D Flawless Diamond'] ??
    offer.image.url
  );
};

export default function ReplaceOfferImage(offers: Offering[]): Offering[] {
  return offers.map((offer) => ({
    ...offer,
    image: {
      ...offer.image,
      url: getOfferImageByName(offer),
    },
  }));
}
