import { TextData } from "../ActiveTab/types";

import styles from "./TextsList.module.scss";

type TextsListProps = {
  title?: string;
  data: TextData[];
  fontSize?: string;
  alignItems?: "center" | "flex-start" | "flex-end";
};

const TextsList = ({
  title = "",
  data,
  fontSize = "16px",
  alignItems = "center",
}: TextsListProps) => {
  return (
    <div className={styles.container}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <ul className={styles.list}>
        {data.map(({ Icon, id, text }) => {
          return (
            <li className={styles.listItem} key={id} style={{ alignItems }}>
              <Icon />
              <p className={styles.text} style={{ fontSize }}>
                {text}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TextsList;
