import styles from "./Details.module.scss";
import { HORSE_DETAILS } from "./constants";

const Details = () => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {HORSE_DETAILS.map((detail) => (
          <li className={styles.listItem} key={detail.id}>
            <div className={styles.textWrapper}>
              <p className={styles.text}>{detail.title}</p>
              <p className={styles.text}>{detail.value}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Details;
