import { useState } from "react";

import HowItWorksSelectedOption from "./HowItWorksSelectedOption";

import styles from "./HowItWorks.module.scss";
import { EXPLANATIONS, NOTE, TEXT } from "./constants";
import { Explanation } from "./types";

const HowItWorks = () => {
  const [selectedOption, setSelectedOption] = useState<null | Explanation>(
    EXPLANATIONS[0]
  );

  const showSelectedOption = (option: Explanation) => {
    option.id === selectedOption?.id
      ? setSelectedOption(null)
      : setSelectedOption(option);
  };

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h2 className={styles.title}>HOW IT WORKS</h2>
          <p className={styles.secondaryTitle}>THE LIGHT OF AFRICA*</p>
          <p className={styles.text}>{TEXT}</p>
          {EXPLANATIONS.map(explanation => (
            <HowItWorksSelectedOption
              key={explanation.id}
              option={explanation}
              selectedOption={selectedOption}
              onClick={showSelectedOption}
            />
          ))}
          <p className={styles.note}>{NOTE}</p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
