import { Navigate, Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import { Footer } from "../components/Footer/Footer";

const useAuth = () => {
  const user = localStorage.getItem("rppToken");
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props: any) => {
  const auth = useAuth();
  if (auth) {
    return (
      <Box sx={{ backgroundColor: "inherit", height: "100%", width: "100%" }}>
        <Box sx={{ backgroundColor: "inherit" }}>
          <Outlet />
        </Box>
      </Box>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoutes;
