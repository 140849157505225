import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Offering } from "../../../../../types/Offerings";
import { settingsPreviewSlider, settingsThumbsSlider } from "./settings";
import { useStyles } from "./styles/";

export interface AssetImagesProps {
  assets: Offering["assets"];
}

interface ImgItem {
  id: number;
  src: string;
}
const AssetImages: React.FC<AssetImagesProps> = function ({
  assets,
}): JSX.Element {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width:600px)");

  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [videoSrc, setVideoSrc] = useState<string>("");

  const [nav1, setNav1] = useState<Slider | undefined>();
  const [nav2, setNav2] = useState<Slider | undefined>();

  const imageItems = useMemo((): ImgItem[] => {
    if (!assets) return [];
    return assets
      .filter(asset => asset.type === "image")
      .map((asset, index) => {
        return { id: index, src: asset.url };
      });
  }, [assets]);

  useEffect(() => {
    const videoSrc = assets?.find(item => item.type === "video")?.url;
    if (videoSrc) {
      setVideoSrc(videoSrc);
    }
  }, [assets]);

  const handlePreviewClick = () => () => {
    if (!videoRef) {
      return;
    }

    isVideoPlay ? videoRef.current?.pause() : videoRef.current?.play();
    setIsVideoPlay(!isVideoPlay);
  };

  settingsThumbsSlider.slidesToShow =
    imageItems.length > 3 ? 4 : imageItems.length;

  return (
    <Box className={classes.container} marginRight={isDesktop ? "15px" : "0px"}>
      {isDesktop && (
        <Box className={classes.thumbs}>
          <Slider
            {...settingsThumbsSlider}
            asNavFor={nav2}
            ref={slider1 => setNav1(slider1 || undefined)}
          >
            {imageItems.map(item => {
              return (
                <div key={item.id}>
                  <img src={item.src} />
                </div>
              );
            })}
          </Slider>
        </Box>
      )}

      <Box className={classes.preview}>
        <Slider
          {...settingsPreviewSlider}
          asNavFor={nav1}
          ref={slider2 => setNav2(slider2 || undefined)}
        >
          {imageItems.map(item => {
            return item.src.includes("video") ? (
              <video
                key={item.id}
                controls={isDesktop}
                ref={videoRef}
                src={videoSrc}
                poster={item.src}
                onClick={handlePreviewClick()}
              />
            ) : (
              <div key={item.id}>
                <img src={item.src} />
              </div>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

export default AssetImages;
