import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Offering } from '../../../types/Offerings';
import { OfferingFormater } from '../../../utils';
import themeData from '../../../utils/theme';

export interface QuantitySelectorProp {
  value?: number;
  currentOffering?: Offering;
  onValueChange: (value: number) => void;
  isIntent?: boolean;
}

const QuantitySelector = ({
  value,
  currentOffering,
  onValueChange,
  isIntent = false,
}: QuantitySelectorProp) => {
  const [quantity, setQuantity] = useState(value ?? 0);
  useEffect(() => {
    onValueChange(quantity);
  }, [quantity, onValueChange]);

  if (!currentOffering) return null;
  var itemFormatted = OfferingFormater(currentOffering, 'USD', true);

  const updQte = (inc: number) => () => {
    if (quantity + inc < 0) return;
    setQuantity(quantity + inc);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'quantity') {
      if (parseInt(value) >= 0) {
        setQuantity(parseInt(value));
      } else {
        setQuantity(0);
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        paddingX: { xs: '17px', md: '33px' },
        paddingY: '16px',
        width: { xs: '100%', lg: '669px' },
        height: { xs: '', lg: '135px' },
      }}
    >
      {/* Quantity row */}
      <Box
        sx={{
          // marginTop: '27px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          component="h6"
          align="left"
          sx={{
            color: themeData.GreyLabel,
            fontSize: '20px',
          }}
        >
          {isIntent
            ? 'How many shares are you interested in buying?'
            : 'How many shares would you like to purchase?'}
        </Typography>
        <TextField
          variant="outlined"
          margin="dense"
          required
          size="small"
          name="quantity"
          id="quantity"
          autoComplete="quantity"
          sx={{
            color: themeData.BlackColor,
            width: { xs: '160px', md: '120px' },
            // height: '42px'
            // paddingX: '14px'
          }}
          color={'primary'}
          value={quantity}
          onChange={onChangeHandler}
          inputProps={{ style: { fontSize: 50 } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" onClick={updQte(-1)}>
                <div
                  style={{
                    borderRight: '1px solid #E4E4E4',
                    // height: '36px',
                    // paddingRight: '10px',
                    // justifyItems: 'middle',
                    // alignItems: 'center'
                  }}
                >
                  <RemoveIcon
                    sx={{
                      marginTop: '5px',
                      marginLeft: '-10px',
                      cursor: 'pointer',
                    }}
                  />
                  {/* <IconButton aria-label='quantity' size='large' sx={{padding:'0px'}}> - </IconButton> */}
                </div>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" onClick={updQte(1)}>
                <div
                  style={{
                    borderLeft: '1px solid #E4E4E4',
                    // paddingLeft: '10px',
                  }}
                >
                  <AddIcon
                    sx={{
                      marginTop: '5px',
                      marginRight: '-10px',
                      cursor: 'pointer',
                    }}
                  />
                  {/* <IconButton aria-label='quantity' size='large' sx={{padding:'0px'}}>+</IconButton> */}
                </div>
              </InputAdornment>
            ),
            inputProps: {
              style: { textAlign: 'center' },
            },
          }}
        />
      </Box>
      {/* Totla Row (hidden) */}
      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          component="h6"
          align="left"
          sx={{
            color: themeData.GreyLabel,
            fontSize: '20px',
          }}
        >
          Total Value
        </Typography>
        <Typography
          component="h6"
          align="left"
          sx={{
            color: themeData.GreyLabel,
            fontSize: '20px',
          }}
        >
          {itemFormatted.totalFor(quantity)}
        </Typography>
      </Box>
      {/* Price Per Share Row */}
      {/* <Box
        sx={{
          marginTop: '20px',
          display: 'none',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography
          component='h6'
          align='left'
          sx={{
            color: themeData.GreyLabel,
            fontSize: '20px',
            fontWeight: 500,
          }}>
          Price per share
        </Typography>
        <Typography
          component='h6'
          align='left'
          sx={{
            color: themeData.GreyLabel,
            fontFamily: themeData.DefaultFontDemi,
            fontSize: '20px',
            fontWeight: 400,
          }}>
          {itemFormatted.perShareAmount}
        </Typography>
      </Box> */}
    </Box>
  );
};

export default QuantitySelector;
