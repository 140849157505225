import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomButton } from 'components/UIComponents/Button';
import { UnloggedCard } from 'pages/AssetsUnlogged/UnloggedCard/UnloggedCard';
import TiaraAsset from 'pages/Dashboard/components/TiaraAsset/TiaraAsset';

import { assets } from 'pages/AssetsUnlogged/data';

import style from './OfferingsUnlogged.module.scss';
import ThoroughbredAsset from 'pages/Dashboard/components/ThoroughbredAsset/ThoroughbredAsset';

const OfferingsUnlogged = () => {
  const [isShowMoreUpcoming, setIsShowMoreUpcoming] = useState(false);

  const navigate = useNavigate();

  const onByBtnClick = (): void => {
    navigate(`/login`);
  };

  return (
    <div className={style.assetContainer}>
      <div className={style.cardsContainer}>
        <TiaraAsset isShowMoreUpcoming={false} />
        {isShowMoreUpcoming && (
          <>
            <ThoroughbredAsset isShowMoreUpcoming={false} />
            {assets.map((item, index) => {
              return !isShowMoreUpcoming && index > 0 ? null : (
                <UnloggedCard
                  key={index}
                  {...item}
                  onByBtnClick={onByBtnClick}
                  isHideHint={item.name.toLowerCase().includes('kwiat')}
                />
              );
            })}
          </>
        )}
      </div>
      {!isShowMoreUpcoming && (
        <div className={style.buttonContainer}>
          <CustomButton
            onClick={() => setIsShowMoreUpcoming(true)}
            btnStyle={'secondary'}
          >
            VIEW ALL ASSETS
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default OfferingsUnlogged;
