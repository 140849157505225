import axios from "axios";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import {
  bankAccountForm,
  ChangePasswordResponse,
  creditCardForm,
  RegisterBankAccountResponse,
  RegisteredPaymentsResponse,
  RegisterRemoveCreditCardResponse,
  RemoveBankAccountResponse,
  UserProfileResponse,
  CreateUserResonse,
} from "../types/Account";
import { makeAuthErrorResponse } from "../types/AuthErrorResponse";
import {
  ComplianceDataType,
  UpdateComplianceDataType,
} from "../types/ComplianceData";
import { UploadedFile } from "../types/Files";
import { GetOfferingResponse, GetOfferingsResponse } from "../types/Offerings";
import {
  IntentDetail,
  PurchaseDetail,
  PurchaseResponse,
} from "../types/PurchaseDetail";
import {
  LegalEntityAccountForm,
  CreateLegalEntityAccountResponse,
  UpdateLegalEntityAccountForm,
  UpdateLegalEntityAccountResponse,
  GetLegalEntityAccountResponse,
  GetBenificialPartiesResponse,
  UpdatePartyRequest,
  UpdatePartyResponse,
  CreateBeneficialPartyResponse,
  CreatePartyRequest,
} from "../types/LegalEntityAccount";
import { FileUploadForm, FileUploadResponse, Path } from "../types/Files";
import type { RefreshSessionResult } from "../types/RefreshSession";

// Import types.
import {
  changePasswordForm,
  createUserForm,
  SigninId,
  signUpForm,
} from "../types/SigninId";
import {
  DefaultSystemConfig,
  FaqDataType,
  FaqsResponse,
  InstructionsResponse,
  InstructionType,
  SystemConfigResponse,
  SystemConfigType,
  WireDetailsResponse,
  WireDetailsType,
} from "../types/SystemConfig";
import { TokenData } from "../types/TokenData";
import { IntentTradeCountResponse, TradesResponse } from "../types/Trade";
import { ZohoEmailLookup } from "types/ZohoUser";

export const serverBasePath = `${process.env.REACT_APP_PROXY_URL}`;
export const xAppId = `${process.env.REACT_APP_XAPPID}`;
const resendValidationEmailToken = "4cc604e8-e389-4a65-a94b-ad176d40f27f";

const bc = require("braincloud");
export const brainCloudApi = new bc.BrainCloudWrapper("_mainWrapper");

export const useServerAPI = () => {
  let navigate = useNavigate();

  const getNextPacket = (): string => {
    var newPkt = "1";
    var pkt = localStorage.getItem("X-PACKETID");
    if (pkt) {
      newPkt = `${parseInt(pkt) + 1}`;
    }
    localStorage.setItem("X-PACKETID", newPkt);
    return newPkt;
  };

  const getAuthenticatedHeaders = () => {
    return {
      headers: {
        "X-PACKETID": getNextPacket(),
        Authorization: `Bearer ${localStorage.getItem("rppToken")}`,
      },
    };
  };

  const rpp = axios.create({
    baseURL: serverBasePath,
    headers: {
      "X-APPID": xAppId,
      "Content-Type": "application/json",
    },
  });

  const handleAxiosError = (error: any) => {
    console.error(`Axios ERROR`, error);
  };
  const handleUnexpectedError = (error: any) => {
    console.error(`ERROR`, error);
  };

  type ServerReq = {
    method: "GET" | "POST" | "PUT" | "PATCH";
    path: string;
    data: any;
    try?: number;
  };
  const MAX_REQ_TRIES = 3;

  const processResponse = (
    response: any,
    resolve: (value: unknown) => void,
    reject: (reason?: any) => void,
    request: ServerReq
  ) => {
    if (response.data?.error) {
      if (response.data?.error.reason_code === 40566) {
        // Out of order pkt, try
        console.warn(
          `Out of order packet detected, on try ${request.try} for ${request.path}`
        );
        if (request.try && request.try < MAX_REQ_TRIES) {
          console.log(`Re-trying packet for ${request.path}`);
          resolve(performRequest(request));
          return;
        }
      }
      console.error("Got an error willprocess it....", response.data?.error);
      if (
        response.data?.error.status === 403 ||
        response.data?.error.status === 401
      ) {
        localStorage.clear();
        // navigate("/login", {
        //   state: { error: makeAuthErrorResponse(response.data.error) },
        // });
      } else {
        reject(makeAuthErrorResponse(response.data.error));
      }
    } else {
      resolve(response.data);
    }
  };

  const performRequest = async (request: ServerReq): Promise<any> => {
    const { method, path, data } = request;
    if (request.try) {
      request.try += 1;
      console.warn(
        `Performing retry for packet number ${request.try} for ${request.path} .....`
      );
    } else request.try = 1;

    console.debug(`Performing ${method} on ${path} ....`);
    return new Promise((resolve, reject) => {
      try {
        switch (method) {
          case "GET":
            rpp
              .get(path, getAuthenticatedHeaders())
              .then((response) =>
                processResponse(response, resolve, reject, request)
              );
            break;
          case "POST":
            rpp
              .post(path, data, getAuthenticatedHeaders())
              .then((response) =>
                processResponse(response, resolve, reject, request)
              );
            break;
          case "PUT":
            rpp
              .put(path, data, getAuthenticatedHeaders())
              .then((response) =>
                processResponse(response, resolve, reject, request)
              );
            break;
          case "PATCH":
            rpp
              .patch(path, data, getAuthenticatedHeaders())
              .then((response) =>
                processResponse(response, resolve, reject, request)
              );
            break;
        }
      } catch (error) {
        console.error(`-------------error`, error);
        if (axios.isAxiosError(error)) {
          handleAxiosError(error);
        } else {
          handleUnexpectedError(error);
        }
      }
    });
  };

  /**
   * init BrainCloud api before use it
   */
  const bcInit = () => {
    brainCloudApi.initialize(
      process.env.REACT_APP_XAPPID,
      process.env.REACT_APP_SECRET,
      "1.0.0"
    );
  };

  /**
   * Attempt to restore the session based on saved information in cookies.
   * This will failed in the session is expired. It's intended to be able to
   * refresh (F5) a webpage and restore.
   */
  const bcReAutentificate = () => {
    bcInit();

    brainCloudApi.brainCloudClient.brainCloudManager._isAuthenticated = true;
    brainCloudApi.brainCloudClient.brainCloudManager._packetId =
      localStorage.getItem("lastPacketId");

    var sessionId = brainCloudApi.getStoredSessionId();
    brainCloudApi.brainCloudClient.brainCloudManager.setSessionId(sessionId);
  };

  return {
    getToken: (): string | null => {
      return localStorage.getItem("rppToken");
    },
    getTokenData: (): TokenData | undefined => {
      const token = localStorage.getItem("rppToken");
      const tokenParts = token?.split(".");
      if (tokenParts?.length === 3) {
        const buf = Buffer.from(tokenParts[1], "base64");
        if (buf) return JSON.parse(buf.toString("utf8"));
      }
      return undefined;
    },
    // ************************************************
    // Unauthneticated calls
    // ************************************************

    authenticate: (
      credentials:
        | { username: string; password: string }
        | { settoptoken: string }
    ) => {
      // reset packet id at login.
      localStorage.setItem("X-PACKETID", "0");
      const forceCreate = false;

      // bcInit();
      // brainCloudApi.authenticateEmailPassword(
      //   //@ts-ignore
      //   credentials?.username,
      //   //@ts-ignore
      //   credentials?.password,
      //   forceCreate,
      //   (result: any) => {}
      // );

      return rpp.post<any>("authentication/AUTHENTICATE", credentials);
    },

    resetEmailPassword: (data: SigninId) =>
      rpp.post(`authentication/FORGOT`, data),

    signUp: (data: signUpForm) => rpp.post(`authentication/SIGNUP`, data),

    // ************************************************
    // Authneticated calls
    // ************************************************

    // export const createUser = (data: createUserForm) => rpp.post(`script/RUN/CreateUser`, data, getAuthenticatedHeaders());
    createUser: (data: {
      profileData: createUserForm;
      complianceData: ComplianceDataType;
    }): Promise<CreateUserResonse> =>
      performRequest({
        method: "POST",
        path: `script/RUN/CreateUser`,
        data: data,
      }),

    resendValidationEmail: (data: { email: string }): Promise<any> => {
      const srv = serverBasePath
        .replace("restportalproxy", "portal")
        .replace("/v2", "");
      const path = `${srv}webhook/${xAppId}/resendValidationEmail`;
      return axios({
        method: "POST",
        url: path,
        headers: {
          "Content-Type": "application/json",
          "x-bc-secret": resendValidationEmailToken,
        },
        data: data,
      });
    },
    listUserFiles: (folder: string = ""): Promise<UploadedFile[]> => {
      if (!brainCloudApi.brainCloudManager.isAuthenticated()) {
        bcReAutentificate();
      }
      return new Promise((resolve, reject) => {
        brainCloudApi.file.listUserFiles(folder, true, (result: any) => {
          if (result.status == 200) {
            resolve(result.data.fileList);
          } else {
            reject("Error: " + JSON.parse(result));
          }
        });
      });
    },
    uploadBlob: (
      file: Blob | null,
      fileName: string,
      folder: string = ""
    ): Promise<any> => {
      if (!file) {
        return Promise.reject("Error: file is required");
      }

      if (!brainCloudApi.brainCloudManager.isAuthenticated()) {
        bcReAutentificate();
      }

      const xhr = new XMLHttpRequest();

      const shareable = true;
      const replaceIfExists = true;
      const fileSize = file ? file.size : 0;
      return new Promise((resolve, reject) => {
        // xhr.upload.addEventListener("progress", uploadProgress);
        xhr.addEventListener("load", () => resolve("Transfer complete"));
        xhr.addEventListener("error", (e: any) =>
          reject("Error uploading: " + e)
        );
        xhr.addEventListener("abort", () => reject("Aborted"));

        brainCloudApi.file.prepareFileUpload(
          folder,
          fileName,
          shareable,
          replaceIfExists,
          fileSize,
          function (result: any) {
            if (result.status == 200) {
              var uploadId = result.data.fileDetails.uploadId;
              brainCloudApi.file.uploadFile(xhr, file, uploadId);
            } else {
              reject("Error uploading: " + result);
            }
          }
        );
      });
    },
    /**
     *
     * @returns { data: {
     *                success: true,
     *                response: {
     *                  profile: ...
     *                }
     *              }
     *          }
     */
    getUserProfile: (): Promise<UserProfileResponse> =>
      performRequest({
        method: "POST",
        path: `/script/RUN/GetProfileData`,
        data: {},
      }),

    updateUserProfile: (
      profile: createUserForm,
      compliance: UpdateComplianceDataType | Record<string, never> = {}
    ): Promise<UserProfileResponse> =>
      performRequest({
        method: "POST",
        path: `script/RUN/UpdateProfileData`,
        data: { ...profile, ...compliance },
      }),
    changePassword: (
      data: changePasswordForm
    ): Promise<ChangePasswordResponse> =>
      performRequest({
        method: "POST",
        path: `script/RUN/ChangePassword`,
        data: data,
      }),

    getOfferings: (): Promise<GetOfferingsResponse> =>
      performRequest({
        method: "POST",
        path: `/script/RUN/GetOfferings`,
        data: {},
      }),
    getOffering: (offeringId: string): Promise<GetOfferingResponse> =>
      performRequest({
        method: "POST",
        path: `/script/RUN/GetOffering`,
        data: { offeringId },
      }),

    registerBankAccount: (
      data: bankAccountForm
    ): Promise<RegisterBankAccountResponse> =>
      performRequest({
        method: "POST",
        path: `/script/RUN/RegisterBankAccount`,
        data: data,
      }),
    updateBankAccount: (
      data: bankAccountForm
    ): Promise<RegisterBankAccountResponse> =>
      performRequest({
        method: "POST",
        path: `/script/RUN/UpdateBankAccount`,
        data: data,
      }),
    removeBankAccount: (): Promise<RemoveBankAccountResponse> =>
      performRequest({
        method: "POST",
        path: `/script/RUN/RemoveBankAccount`,
        data: {},
      }),

    registerCreditCard: (
      data: creditCardForm
    ): Promise<RegisterRemoveCreditCardResponse> =>
      performRequest({
        method: "POST",
        path: "/script/RUN/RegisterCreditCard",
        data: data,
      }),
    updateCreditCard: (
      data: creditCardForm
    ): Promise<RegisterRemoveCreditCardResponse> =>
      performRequest({
        method: "POST",
        path: "/script/RUN/UpdateCreditCard",
        data: data,
      }),
    removeCreditCard: (): Promise<RegisterRemoveCreditCardResponse> =>
      performRequest({
        method: "POST",
        path: "/script/RUN/RemoveCreditCard",
        data: {},
      }),

    getRegisteredPayments: (): Promise<RegisteredPaymentsResponse> =>
      performRequest({
        method: "POST",
        path: "/script/RUN/GetRegisteredPayments",
        data: {},
      }),

    /**
     *
     * @param data Purchase info
     * @param data.field2 Sign, which client put in sign modal window
     * @param data.field3 Date of signing, which is Date in format: Object, string in ISO format or number
     * @returns
     */
    makePurchase: (data: PurchaseDetail): Promise<PurchaseResponse> => {
      const dataClone = { ...data };
      if (dataClone.field3) {
        let stringDate;
        if (typeof dataClone.field3 === "string") stringDate = dataClone.field3;
        else if (typeof dataClone.field3 === "number")
          stringDate = new Date(dataClone.field3).toISOString();
        else stringDate = dataClone.field3.toISOString();
        const date = stringDate.split("T")[0].split("-").reverse();
        const [day, month] = date;
        date[0] = month;
        date[1] = day;

        dataClone.field3 = stringDate;
      }
      return performRequest({
        method: "POST",
        path: "/script/RUN/PurchaseShare",
        data: dataClone,
      });
    },
    registerIntentOfInvestment: (
      data: IntentDetail
    ): Promise<PurchaseResponse> =>
      performRequest({
        method: "POST",
        path: "/script/RUN/IntentToInvest",
        data: data,
      }),

    getTradesHistory: (offeringId?: string): Promise<TradesResponse> =>
      performRequest({
        method: "POST",
        path: "/script/RUN/GetTradesHistory",
        data: { offeringId },
      }),
    getIntentTradeCount: (): Promise<IntentTradeCountResponse> =>
      performRequest({
        method: "POST",
        path: "/script/RUN/HasIntentToInvestTrades",
        data: {},
      }),

    getSystemConfig: async (): Promise<SystemConfigType> => {
      const where = encodeURI(
        JSON.stringify({
          entityType: "SystemConfig",
          entityIndexedId: "SystemConfig",
        })
      );
      const orderBy = encodeURI(JSON.stringify({ updatedAt: -1 }));
      const listResp = (await performRequest({
        method: "GET",
        path: `/globalEntity/GET_LIST?maxReturn=1&where=${where}&orderBy:${orderBy}`,
        data: {},
      })) as SystemConfigResponse;

      if (listResp.status === 200 && listResp.data.entityListCount === 1) {
        return listResp.data.entityList[0].data;
      }
      return DefaultSystemConfig;
    },

    getInstructions: async (): Promise<InstructionType> => {
      const where = encodeURI(
        JSON.stringify({
          entityType: "SystemConfig",
          entityIndexedId: "Instructions",
        })
      );
      const orderBy = encodeURI(JSON.stringify({ updatedAt: -1 }));
      const listResp = (await performRequest({
        method: "GET",
        path: `/globalEntity/GET_LIST?maxReturn=1&where=${where}&orderBy:${orderBy}`,
        data: {},
      })) as InstructionsResponse;

      if (listResp.status === 200 && listResp.data.entityListCount === 1) {
        return listResp.data.entityList[0].data;
      }
      return {};
    },

    getFaqs: async (): Promise<FaqDataType[]> => {
      const where = encodeURI(
        JSON.stringify({ entityType: "SystemConfig", entityIndexedId: "FAQs" })
      );
      const orderBy = encodeURI(JSON.stringify({ updatedAt: -1 }));
      const listResp = (await performRequest({
        method: "GET",
        path: `/globalEntity/GET_LIST?maxReturn=1&where=${where}&orderBy:${orderBy}`,
        data: {},
      })) as FaqsResponse;

      if (listResp.status === 200 && listResp.data.entityListCount === 1) {
        return listResp.data.entityList[0].data.faqs;
      }
      return [];
    },

    getWireDetails: async (): Promise<WireDetailsType> => {
      const where = encodeURI(
        JSON.stringify({
          entityType: "SystemConfig",
          entityIndexedId: "WireDetails",
        })
      );
      const orderBy = encodeURI(JSON.stringify({ updatedAt: -1 }));
      const listResp = (await performRequest({
        method: "GET",
        path: `/globalEntity/GET_LIST?maxReturn=1&where=${where}&orderBy:${orderBy}`,
        data: {},
      })) as WireDetailsResponse;

      if (listResp.status === 200 && listResp.data.entityListCount === 1) {
        return listResp.data.entityList[0].data;
      }
      return { active: false, details: [] };
    },

    getZohoUserInfo: async (): Promise<ZohoEmailLookup> => {
      const res = await axios.post<ZohoEmailLookup>(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "/v1/zoho/lookupZohoLeadByEmail",
        {
          email: localStorage.getItem("email"),
        },
        {
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_ZOHO_TOKEN,
          },
        }
      );

      return res.data;
    },

    getIPFromAmazon: async (): Promise<string> => {
      return fetch("https://api.ipify.org/?format=json")
        .then((res) => {
          return res.json();
        })
        .then((data) => data.ip ?? "")
        .catch((error) => {
          console.error(error);
          return "";
        });
    },

    makeAccountForLegalEntity: async (
      data: LegalEntityAccountForm
    ): Promise<CreateLegalEntityAccountResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/CreateLegalEntityAccount",
        data,
      });
    },

    getLegalEntityForAccount: async (
      accountId: string
    ): Promise<GetLegalEntityAccountResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/GetLegalEntityForAccount",
        data: { accountId },
      });
    },

    updateLegalEntityAccount: async (
      data: UpdateLegalEntityAccountForm
    ): Promise<UpdateLegalEntityAccountResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/UpdateLegalEntityAccount",
        data,
      });
    },

    getBeneficialParties: async (
      accountId: string
    ): Promise<GetBenificialPartiesResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/GetBeneficialParties",
        data: { accountId },
      });
    },

    createBeneficialParty: async (data: {
      partyData: CreatePartyRequest;
      accountId: string;
    }): Promise<CreateBeneficialPartyResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/CreateBeneficialParty",
        data,
      });
    },

    updateParty: async (
      data: UpdatePartyRequest
    ): Promise<UpdatePartyResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/UpdateParty",
        data,
      });
    },

    deleteParty: async (partyId: string): Promise<UpdatePartyResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/DeleteParty",
        data: {
          partyId,
        },
      });
    },

    deleteAccountForLegalEntity: async (
      accountId: string
    ): Promise<UpdatePartyResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/DeleteAccountForLegalEntity",
        data: { accountId },
      });
    },

    getTradesHistoryForAccount: (
      accountId: string
    ): Promise<TradesResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/GetTradesHistoryForAccount",
        data: {
          accountId,
        },
      });
    },

    uploadFileForTransactApi: async (
      data: FileUploadForm<Path>
    ): Promise<FileUploadResponse> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/UploadDocumentInTransactApi",
        data: {
          ...data,
          file_name: `filename0= ${data.file_name}`,
          documentTitle: `documentTitle0= ${data.documentTitle}`,
        },
      });
    },

    refreshSession: async (): Promise<RefreshSessionResult> => {
      return performRequest({
        method: "POST",
        path: "/script/RUN/RefreshSession",
        data: {},
      });
    },
  };
};
