import React from 'react';
import style from './style.module.scss'
import {CustomButton} from "../../../../../components/UIComponents/Button";
import {useNavigate} from "react-router-dom";

export const VipExperience: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={style.vipBlock}>
      <div className={style.container}>
        <div className={style.infoBlock}>
          <h2>THE LUXUS VIP EXPERIENCE</h2>
          <p>Take your investment journey to the next level.</p>
          <CustomButton btnStyle={'secondaryBlack'} onClick={() => navigate("/vip")}>
            LEARN MORE
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
