import { TextFieldProps } from "@mui/material";
import classnames from "classnames";
import { ChangeEvent, FC } from "react";
import { useStyles } from "./styles";
import MuiPhoneNumber from "./components";

type MuiPhoneNumberProps = TextFieldProps & {
  autoFormat?: boolean;
  classes?: any;
  countryCodeEditable?: boolean;
  defaultCountry?: string;
  disableAreaCodes?: boolean;
  disableCountryCode?: boolean;
  disableDropdown?: boolean;
  dropdownClass?: string;
  enableLongNumbers?: boolean;
  excludeCountries?: string[];
  inputClass?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => void;
  onlyCountries?: string[];
  preferredCountries?: string[];
  regions?: [string] | string;
  helperText?: string;
};

interface IProps extends Omit<MuiPhoneNumberProps, "onChange"> {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const PhoneInput: FC<React.PropsWithChildren<IProps>> = (props) => {
  const classes = useStyles();
  const handleChange = (
    e: string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const val = { target: { value: e } };
    //@ts-ignore
    props.onChange(val);
  };

  return (
    <MuiPhoneNumber
      id={props.id}
      className={classnames(classes.input, props.className)}
      defaultCountry={"us"}
      value={props.value}
      variant="outlined"
      onChange={handleChange}
      error={props.error}
      helperText={props.helperText}
      enableLongNumbers="true"
    />
  );
};
