import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  leftCornerTitleBoxClass: {
    backgroundColor: "#6890FF",
    borderRadius: "6px",
    color: "#FFFFFF",
    fontSize: "25px",
    fontWeight: "600",
  },
};

export type ClassesNames = keyof typeof commonStyles;
