import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        downloadImage: {
          height: "41px",
          width: "134px",
        },
        secImage: {
          height: "41px",
          width: "87px",
        },
        giaImage: {
          height: "41px",
          width: "94px",
        },
        igiImage: {
          height: "41px",
          width: "80px",
        },
        fcrfImage: {
          height: "41px",
          width: "87px",
        },
      })
    )
);
