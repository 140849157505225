import { Button, Modal, Paper } from "@mui/material";
import { marked } from "marked";

type EFTDisclosureProps = {
  show: boolean;
  onClose: () => void;
};

const disclosureTemplate = `
**Introduction to Electronic Funds Transfers**

**[PLATFORM NAME]** processes Electronic Funds Transfers (EFTs) as an efficient method to receive electronic deposits from investors, to purchase and redeem investments and to issue refunds to investors. EFTs are processed through the vehicles described below:

-  The Automated Clearing House (ACH)
- The Fedwire
- Credit Card

**Electronic Funds Transfers Agreement and Disclosure**

This Electronic Funds Transfers Agreement and Disclosure (this Agreement) is the contract which covers you and our rights and responsibilities concerning the EFTs services offered to you by **[PLATFORM NAME]**. In this Agreement, the words “you,” “your,” and “yours” mean an individual who signs up on the **[PLATFORM NAME]** Online Platform (Platform) and utilizes the Platform to participate in offerings (and any authorized users). The words “we,” “us,” and “our” mean **[LEGAL ENTITY OF PLATFORM]**. EFTs are electronically initiated transfers of money from your bank account or credit card account, which you utilize the Platform to initiate, and which allow you to participate in the **[PLATFORM NAME]** offerings. By using any **[PLATFORM NAME]** service, you agree to the terms and conditions in this Agreement and any amendments for the EFTs services offered.

In order to use the payment functionality of **[PLATFORM NAME]**, you authorize us to share your identity, bank account data and credit card account data with North Capital Private Securities (NCPS), a broker-dealer registered with the Securities and Exchange Commision and the Financial Industry Regulatory Authority, Inc., and with North Capital Investment Technology (NCIT) for the purpose of processing your EFTs, and you are responsible for the accuracy and completeness of that data. You understand that you will access and are responsible for managing your bank account data and credit card account data through the Platform. You also authorize us and NCPS and NCIT to process EFTs as you direct through **[PLATFORM NAME]**. You acknowledge that none of NCPS, NCIT or we will be liable for any loss, expense or cost arising out of EFT services provided through your use of **[PLATFORM NAME]**, which are based on your instruction; NCPS and NCIT are third party beneficiaries to this Agreement.
`;

export const EFTDisclosure = ({ show, onClose }: EFTDisclosureProps) => {
  const disclosure = disclosureTemplate
    .replaceAll("[PLATFORM NAME]", "LUXUS Portal")
    .replaceAll("[LEGAL ENTITY OF PLATFORM]", "LUXUS Argyle LLC");
  return (
    <Modal
      open={show}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        sx={{
          size: "md",
          position: "absolute",
          top: "50%",
          left: "50%",
          maxHeight: "80%",
          minWidth: "400px",
          transform: "translate(-50%, -50%)",
          overflow: "scroll",
          padding: 5,
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: marked.parse(disclosure) }} />
        <Button
          onClick={onClose}
          sx={{ left: "50%", transform: "translate(-50%,0)" }}
        >
          OK
        </Button>
      </Paper>
    </Modal>
  );
};
