import React, { useEffect } from "react";
import ReactGA from "react-ga";

import { Offering } from "../../../types/Offerings";

import useIOIDetailsFacade from "./useIOIDetailsFacade";
import AssetImages from "../AssetDetails/components/AssetImages/AssetImages";

import Info from "./components/IOIInfo/IOIInfo";
import AssetOtherInfo from "../AssetDetails/components/AssetOtherInfo/AssetOtherInfo";
import { IOIThanks } from "../IOISubmit/components/Thanks";
import { Preloader } from "../../../components/simpleComponents/Preloader";
import { SimilarOfferingNew } from "../AssetDetails/components/SimilarOfferings/SimilarOfferingNew";
import style from "./style.module.scss";
import Header from "../../../components/Header/Header";
import { HEADER_OPTIONS } from "../../../components/Header/constants";
import { Footer } from "../../../components/Footer/Footer";

import { MAIN_URL, ROUTES } from "utils/constants";
import { handleSectionScroll } from "utils/handleSectionScroll";
import {
  SCROLL_QUERY_PARAM,
  STREAM_SECTION_ID,
} from "../Assets/components/StreamCountdown/constants";

const IOIDetails: React.FC = (): JSX.Element => {
  const [
    similarOffering,
    activeOffer,
    isLoading,
    onInterestClick,
    desktop,
    firstSectionData,
    secondSectionData,
    isThanks,
    handleNotifyClick,
    onBrowseMoreClick,
    notifyDisabled,
  ] = useIOIDetailsFacade();

  useEffect(() => {
    // window.scrollTo(0, 0);
    handleSectionScroll(SCROLL_QUERY_PARAM, STREAM_SECTION_ID);

    ReactGA.pageview(window.location.pathname + "/IOIDetails");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isThanks) {
    return (
      <IOIThanks
        offer={activeOffer}
        onClick={onBrowseMoreClick}
        desktop={desktop}
      >
        Thanks! We’ll notify you when shares are available for purchase.
      </IOIThanks>
    );
  }

  return isLoading ? (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <Preloader />
      <Footer
        pageName="IOIDetails page"
        pageUri={`${MAIN_URL}${ROUTES.IOI_DETAILS}`}
      />
    </>
  ) : (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.mainInfo}>
            <h2 className={style.titleMobile}>{activeOffer?.name}</h2>{" "}
            {/*- mobile title*/}
            <AssetImages
              assets={(activeOffer?.assets as Offering["assets"]) ?? []}
            />
            <Info
              offer={activeOffer}
              onBuySharesClick={onInterestClick}
              onNotifyClick={handleNotifyClick}
              notifyDisabled={notifyDisabled}
            ></Info>
          </div>
          <AssetOtherInfo offer={activeOffer} />
        </div>
        <h2 className={style.title}>SIMILAR OFFERINGS</h2>
        {similarOffering && <SimilarOfferingNew offer={similarOffering} />}
      </div>
      <Footer
        pageName="IOIDetails page"
        pageUri={`${MAIN_URL}${ROUTES.IOI_DETAILS}`}
      />
    </>
  );
};

export default IOIDetails;
