import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../utils/theme";

export const useDesktopStyles: (props?: any) => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      title: {
        marginBottom: "21px",
      },
    })
  )
);
