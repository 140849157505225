import { Box, InputAdornment, Typography } from "@mui/material";
import { EyeIcon } from "assets/icons/eye";
import React, { useState } from "react";
import { InputProps, InputSubmitProps } from "./InputSubmit.type";
import { StyledBlackTextField } from "./styledComponent";
import { styles } from "./styles";
import { EyeCloseIcon } from "../../../../assets/icons/EyeCloseIcon";
const CustomInputButton: React.FC<InputSubmitProps> = ({
  theme,
  buttonText,
  placeholder,
  value,
  onChange,
  onSubmit,
  name,
  eye,
  error,
  errorText,
  color,
  isWithoutZindex = false,
}) => {
  const [showPassword, setShowPassword] = useState(!eye);
  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <Box>
      <StyledBlackTextField
        variant="outlined"
        type={showPassword ? "text" : "password"}
        sx={{
          position: "relative",
          input: {
            color: color,
            "&::placeholder": {
              opacity: 1,
              fontSize: "14px",
              color: theme === "white" ? "#7B7B8F" : "#fff",
            },
          },
        }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        themes={theme}
        error={error ?? false}
        name={name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {eye && (
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    marginRight: "10px",
                    display: "flex",
                    cursor: "pointer",
                    padding: 0,
                  }}
                >
                  {showPassword ? (
                    <EyeIcon color={color} />
                  ) : (
                    <EyeCloseIcon color={color} />
                  )}
                </span>
              )}
              {buttonText && (
                <button
                  style={{
                    ...(theme === "white" ? styles.button : styles.whiteButton),
                    fontSize: "14px",
                    lineHeight: "17.95px",
                    letterSpacing: "0.7px",
                    cursor: "pointer",
                    zIndex: isWithoutZindex ? 0 : 1,
                  }}
                >
                  {buttonText}
                </button>
              )}
            </InputAdornment>
          ),
        }}
      />
      {error && (
        <Typography sx={{ color: "red", padding: "10px 0px 0px 10px" }}>
          {errorText}
        </Typography>
      )}
    </Box>
  );
};

const InputSubmit = {
  Black: (props: InputProps) => (
    <CustomInputButton
      theme="black"
      buttonText={props.buttonText}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      onSubmit={props.onSubmit}
      name={props.name}
      eye={props.eye}
      error={props.error}
      errorText={props.errorText}
      color={props.color}
      isWithoutZindex={props.isWithoutZindex}
    />
  ),
  White: (props: InputProps) => (
    <CustomInputButton
      theme="white"
      buttonText={props.buttonText}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      onSubmit={props.onSubmit}
      name={props.name}
      eye={props.eye}
      error={props.error}
      errorText={props.errorText}
      color={props.color}
      isWithoutZindex={props.isWithoutZindex}
    />
  ),
};

export default InputSubmit;
