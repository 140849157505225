import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      streetField: {
        height: "44px",
        "& .MuiInputBase-input": {
          height: "44px !important",
          padding: "0 14px",
          fontSize: "19px",
        },
      },
      cityField: {
        height: "44px",
        "& .MuiInputBase-input": {
          height: "44px !important",
          padding: "0 14px",
          fontSize: "19px",
        },
      },
      stateSelect: {
        height: "44px",
        fontSize: "19px",
      },
      zipCodeField: {
        height: "44px",
        "& .MuiInputBase-input": {
          height: "44px !important",
          padding: "0 14px",
          fontSize: "19px",
        },
      },
      selectPlaceholder: {
        fontSize: "19px",
      },
      content: {
        margin: "20px 0 0",
      },
    })
  )
);
