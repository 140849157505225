import { FormData } from './AccreditationForm.type';

export enum SHARES_QUANTITY {
  MIN = 2,
  MAX = 120,
  ALTERNATIVE_MIN = 1,
}

export const initialValues: FormData = {
  firstName: '',
  lastName: '',
  email: '',
  documentType: '',
  identityType: '',
  identityNumber: '',
  identitySide1: null,
  identitySide2: null,
  citizenship: '',
  accreditationDocument: null,
  finraNumber: '',
  phoneNumber: '',
  country: '',
  street: '',
  city: '',
  primState: '',
  primZip: '',
  ssn: '',
  securitiesNumber: SHARES_QUANTITY.MIN,
  birthDate: '',
  ip: '',
  streetAddressOptional: '',
  allowedOneShare: false,
};

export const initialFilesData = {
  front: {
    content: '',
    name: '',
  },
  back: {
    content: '',
    name: '',
  },
  passport: {
    content: '',
    name: '',
  },
  accreditationDocument: {
    content: '',
    name: '',
  },
};

export const documentTypes = [
  { value: 'Passport', label: 'Passport' },
  { value: 'ID', label: 'ID' },
];

export const documentTypesNonResident = [
  { value: 'Passport', label: 'Passport' },
  { value: 'ID', label: 'ID' },
];

export const documentTypesResident = [
  { value: 'Passport', label: 'Passport' },
  { value: 'ID', label: "Driver's License" },
];

export const accreditationDocumentTypes = [
  { value: 'Professional Letter', label: 'Professional Letter' },
  { value: 'Brokerage Statement', label: 'Brokerage Statement' },
  { value: 'W2 / K1', label: 'W2 / K1' },
  { value: 'FINRA CRD #', label: 'FINRA CRD #' },
];

export enum DOMICILES {
  US_CITIZEN = 'U.S. citizen',
  US_RESIDENT = 'U.S. resident',
  NON_US_CITIZEN = 'non-resident',
}

export const ERROR_INITIAL_STATE = Object.freeze({
  firstName: '',
  lastName: '',
  primState: '',
  country: '',
  street: '',
  city: '',
  primZip: '',
  ssn: '',
  securitiesNumber: '',
  birthDate: '',
});

export const DEFAULT_MESSAGE =
  'Thank you for your interest in The Tiara Offering from LUXUS. You will receive instructions to complete your wire payment shortly via email.';

export const CREDIT_CARD_SUCCESS_MESSAGE =
  'Thank you for investing with LUXUS! We are processing your Credit Card Payment. You will receive an email shortly with your confirmation for the Tiara offering';

export const ACH_SUCCESS_MESSAGE =
  'Thank you for investing with LUXUS! We are processing your ACH Payment. You will receive an email shortly with your confirmation for the Tiara offering';

export enum STEPS {
  MAIN_FORM = 'mainForm',
  SSN_FORM = 'ssnForm',
  ATTESTATIONS_FORM = 'attestationsForm',
  LOAD_DOCUMENTS_FORM = 'loadDocumentsForm',
  PDF_PREVIEW = 'pdfPreview',
  ACH_CREDIT_CARD_FORM_RESIDENT = 'achCreditCardFormResident',
  CREDIT_CARD_FORM_NON_RESIDENT = 'creditCardFormNonResident',
  SUCCESS_SCREEN_ACH = 'successScreenAch',
  SUCCESS_SCREEN_CC = 'successScreenCC',
  SUCCESS_SCREEN_WIRE = 'successScreenWire',
}

export const ACCEPTED_MIME_TYPES = Object.freeze([
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/heic',
]);

export enum FIELD_NAMES {
  DOCUMENT_TYPE = 'documentType',
  IDENTITY_SIDE1 = 'identitySide1',
  IDENTITY_SIDE2 = 'identitySide2',
  DOCUMENT_NUMBER = 'documentNumber',
  IS_2_IDENTITY_REQUIRED = 'isIdentitySide2Required',
}

export const mainFormInitialValues = {
  firstName: '',
  lastName: '',
  country: '',
  isCountryRequired: false,
  street: '',
  streetAddressOptional: '',
  city: '',
  isStateRequired: false,
  primState: '',
  primZip: '',
  birthDate: '',
};
