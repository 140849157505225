import { combineReducers } from "@reduxjs/toolkit";

import user from "./User";
import system from "./System";
import legalEntity from "./LegalEntity";
import ConfigureProfileSlice from './ConfigureProfile/ConfigureProfileSlice';

const rootReducer = combineReducers({
  user,
  system,
  legalEntity,
  ConfigureProfileSlice,
})

export default rootReducer;
