import { forwardRef, type ForwardedRef } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { CustomButton } from "components/UIComponents/Button";
import { logout } from "utils";

import styles from "./AccountDropdown.module.scss";

type AccountDropdownProps = {
  name: string | null;
  handleOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAccountMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  headerScrollCondition?: boolean;
};

const AccountDropdown = forwardRef(
  (
    {
      name,
      handleOpenMenu,
      setIsAccountMenuOpen,
      headerScrollCondition = false,
    }: AccountDropdownProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const navigate = useNavigate();

    const handleButton = (route: string) => {
      const step = localStorage.getItem("configureProfileStep");
      const link = localStorage.getItem("link");

      if (link) {
        localStorage.removeItem("link");
        navigate(link);
        return;
      }
      if (step) {
        navigate(`/configureProfile/form`);
        return;
      }
      navigate(route);
      handleOpenMenu(false);
      if (setIsAccountMenuOpen) setIsAccountMenuOpen(false);
    };

    const wrapperStyles = classNames(styles.wrapper, {
      [styles.scrolledHeader]: headerScrollCondition,
    });

    return (
      <div ref={ref} className={wrapperStyles}>
        <span className={styles.name}>{name}</span>
        <ul className={styles.buttonsWrapper}>
          <li>
            <CustomButton
              btnStyle="secondaryGrey"
              size="long"
              onClick={() => handleButton("/portfolio")}
            >
              VIEW PORTFOLIO
            </CustomButton>
          </li>
          <li>
            <CustomButton
              btnStyle="primary"
              size="long"
              onClick={() => handleButton("/profile")}
            >
              VIEW PROFILE
            </CustomButton>
          </li>
          <li>
            <CustomButton btnStyle="secondary" size="long" onClick={logout}>
              LOG OUT
            </CustomButton>
          </li>
        </ul>
      </div>
    );
  }
);

// const AccountDropdown: React.FC<AccountDropdownProps> = ({
//   name,
//   handleOpenMenu,
//   setIsAccountMenuOpen,
//   headerScrollCondition = false,
// }) => {
//   const navigate = useNavigate();

//   const handleButton = (route: string) => {
//     const step = localStorage.getItem("configureProfileStep");
//     const link = localStorage.getItem("link");

//     if (link) {
//       localStorage.removeItem("link");
//       navigate(link);
//       return;
//     }
//     if (step) {
//       navigate(`/configureProfile/form`);
//       return;
//     }
//     navigate(route);
//     handleOpenMenu(false);
//     if (setIsAccountMenuOpen) setIsAccountMenuOpen(false);
//   };

//   const wrapperStyles = classNames(styles.wrapper, {
//     [styles.scrolledHeader]: headerScrollCondition,
//   });

//   return (
//     <div className={wrapperStyles}>
//       <span className={styles.name}>{name}</span>
//       <ul className={styles.buttonsWrapper}>
//         <li>
//           <CustomButton
//             btnStyle="secondaryGrey"
//             size="long"
//             onClick={() => handleButton("/portfolio")}
//           >
//             VIEW PORTFOLIO
//           </CustomButton>
//         </li>
//         <li>
//           <CustomButton
//             btnStyle="primary"
//             size="long"
//             onClick={() => handleButton("/profile")}
//           >
//             VIEW PROFILE
//           </CustomButton>
//         </li>
//         <li>
//           <CustomButton btnStyle="secondary" size="long" onClick={logout}>
//             LOG OUT
//           </CustomButton>
//         </li>
//       </ul>
//     </div>
//   );
// };

export default AccountDropdown;
