import { AssetDetail } from './types';

export const ASSET_DETAILS: AssetDetail[] = [
  { title: 'Total Amount', id: 1, text: '$60,000' },
  { title: 'Minimum Investment', id: 2, text: '$1,000' },
  { title: 'Maximum Investment', id: 3, text: '$6,000' },
  { title: 'Total Winnings', id: 4, text: '$512,277' },
  { title: '2024 Winnings', id: 5, text: '$266,500' },
  { title: 'Sire', id: 6, text: 'Quality Road' },
  { title: 'Trainer', id: 7, text: 'John W Sadler' },
  { title: 'Trainer Wins', id: 8, text: '2700' },
  { title: 'Last Three Races', id: 9, text: '2nd-1st-1st' },
  { title: 'Breeder', id: 10, text: 'Framont LTD' },
  { title: 'Groomed at', id: 11, text: "Lane's End" },
];
