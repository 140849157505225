import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        padding: "60px 0 43px 0",
      },
      title: {
        fontSize: "23px",
        marginBottom: "10px",
      },
      buttonsBox: {
        width: "70%",
      },
      text: {
        fontSize: "15px",
        maxWidth: "278px",
        textAlign: "center",
      },
      btn: {
        border: "none",
        "& svg": {
          fontSize: "15px",
        },
      },
      btnTitle: {
        fontSize: "15px",
        maxWidth: "70px",
        textAlign: "center",
        marginTop: "5px",
      },
    })
  )
);
