import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          width: "496px",
          margin: "0 auto",
          overflow: "visible",
          padding: "31px 0 45px 0",
        },
        imgBox: {
          width: "205px",
          "& img": {
            width: "203px",
            border: "1px solid #DFDFE3",
            maxWidth: "600px",
          },
        },
        title: {
          fontSize: "25px",
        },
        signBtn: {
          fontSize: "16px",
          height: "41px",
          width: "100%",
        },
        content: {
          marginBottom: "0",
        },
        content2: {
          marginBottom: "0",
          padding: "25px 25px 0",
        },
        content3: {
          "& button": {
            fontSize: "16px",
            width: "192px",
            height: "48px",
          },
        },
      })
    )
);
