import { forwardRef } from "react";
import { Controller, Control, type UseFormClearErrors } from "react-hook-form";

import { InputField } from "components/simpleComponents/Input/InputField/InputField";

type UniversallInputProps = {
  name: string;
  placeholder: string;
  disabled?: boolean;
  control: Control<any>;
  isError?: boolean;
  errorMessage?: string;
  clearErrors?: UseFormClearErrors<any>;
  variant?: "black" | "white";
};

export const ControledField = forwardRef<HTMLElement, UniversallInputProps>(
  (
    {
      name,
      placeholder,
      disabled = false,
      control,
      isError = false,
      errorMessage = "",
      clearErrors = null,
      variant = "black",
    },
    ref
  ) => (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => {
        return variant === "black" ? (
          <InputField.Black
            name={name}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => {
              clearErrors && clearErrors(name);
              onChange(e.target.value);
            }}
            error={isError}
            errorText={errorMessage}
          />
        ) : (
          <InputField.White
            name={name}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => {
              clearErrors && clearErrors(name);
              onChange(e.target.value);
            }}
            error={isError}
            errorText={errorMessage}
          />
        );
      }}
    />
  )
);
