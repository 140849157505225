import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () => createStyles(mixStyleRules(commonStyles, {
      input: {
          input: {
              "&.MuiFormControl-root": {
                  borderRadius: "5px",
              },
              "& .MuiOutlinedInput-root": {
                  paddingLeft: 0,
                  fontSize: "16.55px",
                  fontWeight: "300",
              },
              "& .MuiInput-underline::before": {
                  content: "none",
              },
          },
      },
  }))
);
