import * as yup from "yup";
import { countries } from "../../../../utils/CoutriesList";
import { domicileChoices } from "../../../../types/Account";
import states from "../../../../utils/states";
import { accreditationDocumentType } from "../../../../utils/accreditationTypes";
import { identityDocumentType } from "../../../../utils/idTypes";

import { ACCEPTED_MIME_TYPES } from "./AccreditationForm.data";

const DOCUMENT_SUPPORTED_FORMATS = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const IMAGE_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/bmp",
];

export const accreditationSchema = yup
  .object()
  .shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    birthday: yup
      .string()
      .required("Birthday is required")
      .test("valid-date", "Data format is mm.dd.yyyy.", function (value) {
        const dateRegex =
          /^(0[1-9]|1[012])\.(0[1-9]|[12][0-9]|3[01])\.(19|20)\d\d$/;
        return dateRegex.test(value);
      })
      .required("Birthday is required"),
    citizenship: yup
      .string()
      .required("Citizenship is required")
      .oneOf(domicileChoices.map((citizenship) => citizenship.value)),
    ssn: yup.string().when(["citizenship"], {
      is: (citizenship: string) => citizenship === "U.S. citizen",
      then: (schema) =>
        schema
          .required("SSN is required")
          .test("valid-ssn", "SSN format is XXX-XX-XXXX", function (value) {
            const dateRegex = /^\d{3}-\d{2}-\d{4}$/;
            return dateRegex.test(value);
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    country: yup
      .string()
      .required("Country is required")
      .oneOf(countries.map((country) => country.value)),
    street: yup.string().required("Street is required"),
    city: yup.string().required("City is required"),
    state: yup.string().when(["country"], {
      is: (country: string) => country === "United States of America",
      then: (schema) =>
        schema
          .required("State is required")
          .oneOf(states.map((state) => state.id)),
      otherwise: (schema) => schema.notRequired(),
    }),
    region: yup.string().when(["country"], {
      is: (country: string) => country === "United States of America",
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required("Region is required"),
    }),
    zipCode: yup.string().required("ZIP Code is required"),

    // accreditation document type
    documentType: yup
      .string()
      .required("Document type is required")
      .oneOf(accreditationDocumentType.map((type) => type.value)),
    // TODO: add dynamic validation for document file if type is everything except FINRA
    document: yup
      .mixed((input): input is File => input instanceof File)
      .test(
        "file-format",
        "Invalid file format. Use .pdf .doc .docs",
        function (value) {
          if (!!value) {
            return DOCUMENT_SUPPORTED_FORMATS.includes((value as File).type);
          }
        }
      )
      .required("Document is required"),
    // TODO: requeired only if document type is FINRA
    fintraCRD: yup.string().required("FINRA CRD #"),

    // TODO: required only if identity document type is ID
    id: yup.string().required(),
    // Identity document type
    identityType: yup
      .string()
      .required("Identity type is required")
      .oneOf(identityDocumentType.map((type) => type.value)),

    // TODO: required only if identity document type is ID
    typeOfIdFront: yup
      .mixed((input): input is File => input instanceof File)
      .test(
        "file-formatFront",
        "Invalid file format. Use .jpg, .jpeg, .bmp, .png",
        function (value) {
          if (!!value) {
            return IMAGE_SUPPORTED_FORMATS.includes((value as File).type);
          }
        }
      )
      .required("Document is required"),
    // TODO: required only if identity document type is ID
    typeOfIdBack: yup
      .mixed((input): input is File => input instanceof File)
      .test(
        "file-formatBack",
        "Invalid file format. Use .jpg, .jpeg, .bmp, .png",
        function (value) {
          if (!!value) {
            return IMAGE_SUPPORTED_FORMATS.includes((value as File).type);
          }
        }
      )
      .required("Document is required"),
    // TODO: required only if identity document type is Passport
    passportFile: yup
      .mixed((input): input is File => input instanceof File)
      .test(
        "file-formatFront",
        "Invalid file format. Use .jpg, .jpeg, .bmp, .png",
        function (value) {
          if (!!value) {
            return IMAGE_SUPPORTED_FORMATS.includes((value as File).type);
          }
        }
      )
      .required("Document is required"),
    // TODO: required only if identity document type is Passport
    passportNum: yup.string().required("Passport number is required"),
  })
  .required();

export const mainFormSchema = yup
  .object({
    firstName: yup.string().required("This field is required!"),
    lastName: yup.string().required("This field is required!"),
    street: yup.string().required("This field is required!"),
    city: yup.string().required("This field is required!"),
    primZip: yup.string().required("This field is required!"),
    birthDate: yup
      .string()
      .matches(
        /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/,
        "Invalid date format. Please use MM-DD-YYYY!"
      )
      .required("This field is required!"),
    streetAddressOptional: yup.string(),
    isCountryRequired: yup.boolean(),
    isStateRequired: yup.boolean(),
    country: yup.string().when("isCountryRequired", {
      is: true,
      then: () => yup.string().required("This field is required!"),
      otherwise: () => yup.string().notRequired(),
    }),
    primState: yup.string().when("isStateRequired", {
      is: true,
      then: () => yup.string().required("This field is required!"),
      otherwise: () => yup.string().notRequired(),
    }),
  })
  .required();

const documentSchema = yup
  .mixed<File>()
  .required("Please upload identity documents!")
  .test(
    "is-valid-type",
    "Not supported file type. Please upload PDF, JPG, PNG, or HEIC file",
    (value) =>
      value instanceof File && ACCEPTED_MIME_TYPES.includes(value?.type)
  );

export const documentFormSchema = yup.object({
  documentType: yup.string().required("This field is required!"),
  documentNumber: yup.string().required("This field is required!"),
  identitySide1: documentSchema,
  isIdentitySide2Required: yup.boolean(),
  identitySide2: yup.mixed<File>().when("isIdentitySide2Required", {
    is: true,
    then: () => documentSchema,
    otherwise: () => yup.mixed().notRequired(),
  }),
});
