import { useEffect, useState } from "react";

import { CustomButton } from "../../../../components/UIComponents/Button";
import { Preloader } from "../../../../components/simpleComponents/Preloader";
import CurrentOfferingAlt from "../../../NewAssests/Assets/components/CurrentOfferingAlt";
import UpcomingOfferings from "../../../NewAssests/Assets/components/UpcomingOfferings/UpcomingOfferings";
import { VipExperience } from "../../../NewAssests/Assets/components/VipExperience/VipExperience";
import JoinForm from "../../../NewAssests/Assets/components/JoinForm/JoinForm";
import TiaraAsset from "../TiaraAsset/TiaraAsset";

import style from "./style.module.scss";

import useAssetsFacade from "../../../NewAssests/Assets/useAssetsFacade";
import { MAIN_URL, ROUTES } from "utils/constants";
import ThoroughbredAsset from "../ThoroughbredAsset/ThoroughbredAsset";
import { handleSectionScroll } from "utils/handleSectionScroll";
import {
  SCROLL_QUERY_PARAM,
  STREAM_SECTION_ID,
} from "pages/NewAssests/Assets/components/StreamCountdown/constants";

export const DashboardAssets = () => {
  useEffect(() => {
    handleSectionScroll(SCROLL_QUERY_PARAM, STREAM_SECTION_ID);
  }, []);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);

  const [
    desktop,
    isShowMoreCurrent,
    setIsShowMoreCurrent,
    isShowMoreUpcoming,
    setIsShowMoreUpcoming,
    activeOfferings,
    futureOfferings,
    isLoading,
    getOnItemBuyClick,
    getOnItemIOIClick,
    setSortType,
    userFirstName,
  ] = useAssetsFacade();

  return isLoading ? (
    <Preloader />
  ) : (
    <div className={style.container}>
      <h1>Welcome, {userFirstName}</h1>
      <h6 className={style.text}>
        The Era of Luxury Assets as an Investment Class is Here.
      </h6>
      <ThoroughbredAsset isShowMoreUpcoming={isShowMoreUpcoming} />
      {isShowMoreUpcoming && (
        <CurrentOfferingAlt
          offerings={activeOfferings}
          isShowMoreUpcoming={isShowMoreUpcoming}
        />
      )}
      {!isShowMoreUpcoming && (
        <div className={style.btnContainer}>
          <CustomButton
            onClick={() => setIsShowMoreUpcoming(true)}
            btnStyle={"secondary"}
          >
            VIEW ALL ASSETS
          </CustomButton>
        </div>
      )}
      <h2 className={style.soon}>COMING SOON...</h2>
      <div className={style.soonOfferings}>
        {/* <UpcomingOfferings
          offerings={futureOfferings}
          isShowMoreUpcoming={isShowMoreUpcoming}
        /> */}
      </div>
      <VipExperience />
      <div className={"formContainer"}>
        <h2 className={"title"}>JOIN OUR NEWSLETTER</h2>
        <h6 className={"text"}>
          Subscribe to our newsletter for insights, updates and more
        </h6>
        <JoinForm
          pageName="Dashboard page"
          pageUri={`${MAIN_URL}${ROUTES.DASHBOARD}`}
          buttonText={"SUBSCRIBE"}
          setIsUserSubscribed={setIsUserSubscribed}
          isUserSubscribed={isUserSubscribed}
        />
      </div>
    </div>
  );
};
