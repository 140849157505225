import Details from "../Details/Details";
import Gallery from "../Gallery/Gallery";
import Pedigree from "../Pedigree/Pedigree";
import TextsList from "../TextsList/TextsList";
import TrackRecord from "../TrackRecord/TrackRecord";

import styles from "./ActiveTab.module.scss";
import { PERKS } from "./constants";

type ActiveTabProps = {
  id: number;
};

const ActiveTab = ({ id }: ActiveTabProps) => {
  return (
    <div className={styles.container}>
      {id === 1 && <Details />}
      {id === 2 && <Pedigree />}
      {id === 3 && <TextsList data={PERKS} />}
      {id === 4 && <Gallery />}
    </div>
  );
};

export default ActiveTab;
