import React from "react";
import { HEADER_OPTIONS } from "../../components/Header/constants";
import Header from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { DashboardAssets } from "./components/DashboardAssets/DashboardAssets";
import { MAIN_URL, ROUTES } from "utils/constants";

export const Dashboard = () => {
  return (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <DashboardAssets />
      <Footer
        removeForm={true}
        pageName="Dashbord page"
        pageUri={`${MAIN_URL}${ROUTES.DASHBOARD}`}
      />
    </>
  );
};
