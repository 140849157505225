type MenuBarsSmallProps = {
  color?: string;
};

const MenuBarsSmall: React.FC<MenuBarsSmallProps> = ({ color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M23.6786 5.42857H0.321429C0.23618 5.42857 0.154424 5.39471 0.0941442 5.33443C0.0338647 5.27415 0 5.19239 0 5.10714L0 3.82143C0 3.73618 0.0338647 3.65442 0.0941442 3.59414C0.154424 3.53386 0.23618 3.5 0.321429 3.5H23.6786C23.7638 3.5 23.8456 3.53386 23.9059 3.59414C23.9661 3.65442 24 3.73618 24 3.82143V5.10714C24 5.19239 23.9661 5.27415 23.9059 5.33443C23.8456 5.39471 23.7638 5.42857 23.6786 5.42857ZM23.6786 14H0.321429C0.23618 14 0.154424 13.9661 0.0941442 13.9059C0.0338647 13.8456 0 13.7638 0 13.6786L0 12.3929C0 12.3076 0.0338647 12.2259 0.0941442 12.1656C0.154424 12.1053 0.23618 12.0714 0.321429 12.0714H23.6786C23.7638 12.0714 23.8456 12.1053 23.9059 12.1656C23.9661 12.2259 24 12.3076 24 12.3929V13.6786C24 13.7638 23.9661 13.8456 23.9059 13.9059C23.8456 13.9661 23.7638 14 23.6786 14ZM23.6786 22.5714H0.321429C0.23618 22.5714 0.154424 22.5376 0.0941442 22.4773C0.0338647 22.417 0 22.3352 0 22.25L0 20.9643C0 20.879 0.0338647 20.7973 0.0941442 20.737C0.154424 20.6767 0.23618 20.6429 0.321429 20.6429H23.6786C23.7638 20.6429 23.8456 20.6767 23.9059 20.737C23.9661 20.7973 24 20.879 24 20.9643V22.25C24 22.3352 23.9661 22.417 23.9059 22.4773C23.8456 22.5376 23.7638 22.5714 23.6786 22.5714Z"
        fill={color}
      />
    </svg>
  );
};

export default MenuBarsSmall;
