import { themeData } from '../../../../utils/theme';

export const commonStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    width: '100px',
    height: '32px',
  },
  decBtn: {
    flexShrink: 2,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: '0 !important',
    borderTopLeftRadius: '50px !important',
    borderBottomLeftRadius: '50px !important',
  },
  incBtn: {
    flexShrink: 2,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: '0 !important',
    borderTopRightRadius: '50px !important',
    borderBottomRightRadius: '50px !important',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 1,
    width: '40px',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '400',
    border: '1px solid #D9D9D9',
    borderRight: 'none',
    borderLeft: 'none',
    textAlign: 'center',
    padding: '0px !important',
    fontFamily: 'Futura PT !important',
    lineHeight: '21px'
  },
  icon: {
    color: '#7B7B8F',
    width: '14px'
  },
};

export type ClassesNames = keyof typeof commonStyles;
