import { ClassNameMap } from "@mui/styles";
import { DisplayTypes } from "../types/enums/DisplayTypes";
/**
 * Return classname map in dependence from user screen width
 * @param {Displaytypes} display - type of user sreen
 * @param {ClassNameMap} desktop - class name map for desktop screen
 * @param mobile - class name map for dmobile screen
 * @param tabletLandscape - class name map for tablet screen in landscape orientation
 * @param tabletPortrait - class name map for tablet screen in portrait orientation
 * @returns {ClassNameMap} - class name map which need use for user screen
 */
export default function getClassNameMap<T extends string>(
  display: DisplayTypes,
  desktop: ClassNameMap<T>,
  mobile: ClassNameMap<T>,
  tabletLandscape: ClassNameMap<T>,
  tabletPortrait: ClassNameMap<T>
): ClassNameMap<T> {
  if (display === DisplayTypes.Desktop) return desktop;
  if (display === DisplayTypes.TabletLandscape) return tabletLandscape;
  if (display === DisplayTypes.TabletPortrait) return tabletPortrait;

  return mobile;
}
