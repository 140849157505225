import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, MenuItem, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ValidationErrors } from 'final-form';
import moment from 'moment';
import { Select, TextField } from 'mui-rff';
import { Fragment } from 'react';
import { Form } from 'react-final-form';
import SectionTitle from '../../../components/SectionTitle';
import { SsnField } from '../../../components/SSNField';
import {
  domicileChoices,
  UserProfile,
  UserProfileForm,
} from '../../../types/Account';
import {
  DefaultSystemConfig,
  SystemConfigType,
} from '../../../types/SystemConfig';
import { PhoneFormatter } from '../../../utils';
import themeData from '../../../utils/theme';
import '../ProfileForm.css';
import { stateCodes } from './states';

export type ProfileFormProps = {
  data?: UserProfile;
  onCancel: Function;
  onSave: (props: UserProfile) => void;
  title: string;
  submitTitle?: string;
};

type DateType = {
  year: string;
  month: string;
  date: string;
};

const getDobOrEmpty = (dob?: string) => {
  var defaultDob = { year: '', month: '', date: '' };

  if (typeof dob === 'string') {
    const dobDate = moment(dob, 'MM-DD-YYYY');
    defaultDob = {
      year: dobDate.year().toString(),
      month: dobDate.format('MM'),
      date: dobDate.date().toString(),
    };
  }
  return defaultDob;
};

const validateAge = (date: DateType): boolean => {
  console.debug(`ValidateAge `, date);
  if (!date.date || !date.month || !date.year) return false;
  const d = new Date(
    parseInt(date.year),
    parseInt(date.month),
    parseInt(date.date)
  );
  console.debug(` Current DOB is ${d.toISOString()}`);
  const dob = moment(d);
  const now = moment();
  console.debug(
    ` Current DOB moment is ${dob.format()}  now: ${now}`,
    now.diff(dob, 'years')
  );
  if (now.diff(dob, 'years') < 18) {
    return false;
  }
  return true;
};

const profileDataFromForm = (input: UserProfileForm) => {
  const data: UserProfile = {
    associatedAccounts: [],
    dob: `${input.dob.month}-${input.dob.date.padStart(2, '0')}-${
      input.dob.year
    }`,
    domicile: input.domicile,
    emailAddress: '',
    firstName: input.firstName,
    lastName: input.lastName,
    phone: input.phone,
    primAddress1: input.primAddress1,
    primAddress2: input.primAddress2,
    primCity: input.primCity,
    primCountry: input.primCountry,
    primState: input.primState,
    primZip: input.primZip,
    socialSecurityNumber: input.socialSecurityNumber,
  };
  return data;
};
const profileFormFromData = (inputs?: UserProfile) => {
  const form: UserProfileForm = {
    firstName: inputs?.firstName ?? '',
    lastName: inputs?.lastName ?? '',
    domicile: inputs?.domicile ?? '',
    primCountry: inputs?.primCountry ?? 'USA',
    dob: getDobOrEmpty(inputs?.dob),
    phone: inputs?.phone ?? '',
    primAddress1: inputs?.primAddress1 ?? '',
    primAddress2: inputs?.primAddress2 ?? '',
    primCity: inputs?.primCity ?? '',
    primState: inputs?.primState ?? '',
    primZip: inputs?.primZip ?? '',
    socialSecurityNumber: inputs?.socialSecurityNumber ?? '',
  };
  return form;
};

const ProfileForm = (props: ProfileFormProps) => {
  const sysConfig = JSON.parse(
    localStorage.getItem('SystemConfig') ?? JSON.stringify(DefaultSystemConfig)
  ) as SystemConfigType;

  var months = moment.months().map((month) => ({
    title: month,
    value: moment().month(month).format('MM'),
  }));
  const userProfileForm = profileFormFromData(props.data);

  const handleOnSubmitForm = (values: UserProfileForm) => {
    props.onSave(profileDataFromForm(values));
  };

  const getArrayOfYears = () => {
    const currentYear = new Date().getFullYear();
    const range = (start: number, stop: number, step: number) =>
      Array.from({ length: (stop - start) / step + 1 }, (_, i) =>
        (start + i * step).toString()
      );
    return range(currentYear - 18, currentYear - 118, -1);
  };

  const handleCancel = () => {
    props.onCancel();
  };

  const validateProfileForm = (values: UserProfileForm): ValidationErrors => {
    var errors: { [field: string]: string } = {};

    console.debug(`ValidateProfileForm `, values);

    if (values.dob.year && !validateAge(values.dob)) {
      errors['dob.year'] = 'Must be 18 years old.';
    }
    if (
      !!!values.dob.year ||
      !!!values.dob.month ||
      !!!values.dob.date ||
      values.dob.year.length < 1 ||
      values.dob.month.length < 1 ||
      values.dob.date.length < 1
    ) {
      errors['dob.year'] = 'Date of Birth is required.';
    }

    if (values.socialSecurityNumber.match(/[^\d-]/)) {
      errors.socialSecurityNumber = 'Only digit and "-" allowed.';
    } else if (
      values.socialSecurityNumber.length > 0 &&
      values.socialSecurityNumber.replaceAll('-', '').length < 9
    ) {
      errors.socialSecurityNumber = 'Should be 9 digits long.';
    }
    if (values.phone.match(/[^\d-()+\b\s]/)) {
      errors.phone = 'Only digit and "()-" allowed.';
    }
    // else if (
    //   values.phone.length > 0 &&
    //   values.phone.replaceAll(/[-()+\b\s]/g, "").length < 10
    // ) {
    //   errors.phone = "Should be 10 digits long including area code.";
    // }
    if (
      !!!values.socialSecurityNumber ||
      values.socialSecurityNumber.length < 9
    ) {
      errors.socialSecurityNumber = 'Valid Social Security number is required';
    }
    if (!!!values.primAddress1 || values.primAddress1.length < 1) {
      errors.primAddress1 = 'Street address is required';
    }
    if (!!!values.primCity || values.primCity.length < 1) {
      errors.primCity = 'City is required';
    }
    if (!!!values.primZip || values.primZip.length < 5) {
      errors.primZip = 'Valid ZIP is required';
    }
    if (!!!values.primState || values.primState.length < 1) {
      errors.primState = 'State is required';
    }
    if (!!!values.domicile || values.domicile.length < 1) {
      errors.domicile = 'Citizenship is required';
    }
    if (!!!values.firstName || values.firstName.length < 1) {
      errors.firstName = 'First Name is required';
    }
    if (!!!values.lastName || values.lastName.length < 1) {
      errors.lastName = 'Last Name is required';
    }
    return Object.keys(errors).length > 0 ? errors : undefined;
  };
  const formatField = (value: string, name: string): string => {
    // if (name === 'socialSecurityNumber') {
    //   return SSNFormatter(value);
    // }
    if (name === 'phone') {
      const formated = PhoneFormatter(value);
      return formated;
    }
    return value;
  };
  userProfileForm.primState =
    stateCodes.find(
      ({ label, id }) =>
        label === userProfileForm.primState || id === userProfileForm.primState
    )?.id ?? '';
  userProfileForm.domicile =
    domicileChoices.find(
      ({ label, value }) =>
        label === userProfileForm.domicile || value === userProfileForm.domicile
    )?.value ?? '';

  return (
    <Form
      onSubmit={handleOnSubmitForm}
      initialValues={userProfileForm}
      validate={validateProfileForm}
      // validateOnBlur
      render={({
        handleSubmit,
        values,
        submitFailed,
        dirtyFields,
        hasValidationErrors,
        errors,
      }) => (
        <Fragment>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            sx={{
              maxWidth: '722px',
              minWidth: { xs: 'auto', md: '700px' },
              margin: 'auto',
            }}
          >
            <SectionTitle
              title={props.title}
              titleColor={themeData.GreyLabel}
            />
            <Typography
              component="h4"
              variant="h6"
              sx={{
                color: '#000000',
              }}
              style={{ fontSize: '13px', marginTop: 26 }}
            >
              Full legal name*
            </Typography>
            <Grid container spacing={2}>
              {/* First Name */}
              <Grid item md={6} xs={12} textAlign={'left'} alignSelf="start">
                <TextField
                  variant="outlined"
                  margin="dense"
                  size="small"
                  required
                  fullWidth
                  id="firstName"
                  name="firstName"
                  autoComplete="firstName"
                  autoFocus
                  placeholder="First Name"
                />
              </Grid>
              {/* Last Name */}
              <Grid
                item
                md={6}
                xs={12}
                style={{ display: 'flex', flexFlow: 'row-reverse' }}
              >
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="lastName"
                  size="small"
                  name="lastName"
                  autoComplete="lastName"
                  placeholder="Last Name"
                />
              </Grid>
              {/* DOB */}
              <Grid item md={6} xs={12} textAlign={'left'} alignSelf="start">
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{
                    color: '#000000',
                  }}
                  style={{
                    fontSize: '13px',
                    marginTop: 14,
                    marginBottom: '8px',
                  }}
                >
                  Date of Birth*
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Select
                      name="dob.month"
                      label="Month"
                      formControlProps={{ margin: 'none' }}
                      size="small"
                      error={submitFailed && !!errors && !!errors['dob.year']}
                    >
                      {months.map((m) => (
                        <MenuItem key={m.value} value={m.value}>
                          {m.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Select
                      name="dob.date"
                      label="Day"
                      formControlProps={{ margin: 'none' }}
                      size="small"
                      error={submitFailed && !!errors && !!errors['dob.year']}
                    >
                      {Array.from({ length: 31 }, (_, i) =>
                        (i + 1).toString()
                      ).map((m) => (
                        <MenuItem key={m} value={m}>
                          {m}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Select
                      name="dob.year"
                      label="Year"
                      formControlProps={{ margin: 'none' }}
                      size="small"
                      error={submitFailed && !!errors && !!errors['dob.year']}
                    >
                      {getArrayOfYears().map((y) => (
                        <MenuItem key={y} value={y}>
                          {y}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                {!!errors && !!errors['dob.year'] && submitFailed && (
                  <Typography
                    sx={{
                      color: 'error.main',
                      fontSize: '0.75rem',
                      lineHeight: '1.66',
                      marginTop: '4px',
                      marginX: '14px',
                      marginBottom: '0px',
                    }}
                    // variant='caption' sx={{color: 'red'}}
                  >
                    {errors['dob.year']}
                  </Typography>
                )}
              </Grid>
              {/* SSN */}
              <Grid
                item
                md={6}
                xs={12}
                textAlign={'left'}
                alignSelf="start"
                display={{
                  xs: sysConfig.showProfileSSN ? 'block' : 'none',
                  md: 'block',
                }}
                visibility={sysConfig.showProfileSSN ? undefined : 'hidden'}
              >
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    component="h4"
                    variant="h6"
                    sx={{
                      color: '#000000',
                    }}
                    style={{ fontSize: '13px', marginTop: 14 }}
                  >
                    SSN (Social Security number)
                  </Typography>
                  <Tooltip title="Nine-digit Social Security number">
                    <InfoIcon
                      fontSize="inherit"
                      style={{ marginLeft: '8px', marginTop: '14px' }}
                    />
                  </Tooltip>
                </Box>
                <SsnField
                  variant="outlined"
                  margin="dense"
                  required
                  size="small"
                  fullWidth
                  id="ssn"
                  name="socialSecurityNumber"
                  autoComplete="ssn"
                  placeholder="SSN"
                />
              </Grid>
              {/* Address */}
              <Grid item md={6} xs={12} textAlign={'left'} alignSelf="start">
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    component="h4"
                    variant="h6"
                    sx={{
                      color: '#000000',
                    }}
                    style={{ fontSize: '13px', marginTop: 14 }}
                  >
                    Primary address*
                  </Typography>
                  <Tooltip title="The legal address where you reside">
                    <InfoIcon
                      fontSize="inherit"
                      style={{ marginLeft: '8px', marginTop: '14px' }}
                    />
                  </Tooltip>
                </Box>

                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  id="primAddress1"
                  name="primAddress1"
                  autoComplete="primAddress1"
                  placeholder="Street Address"
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  id="primCity"
                  name="primCity"
                  autoComplete="primCity"
                  placeholder="City"
                />
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="primState"
                      label="State"
                      formControlProps={{ margin: 'dense' }}
                      size="small"
                    >
                      {stateCodes.map((m) => (
                        <MenuItem key={m.id} value={m.id}>
                          {m.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      required
                      size="small"
                      fullWidth
                      id="primZip"
                      name="primZip"
                      autoComplete="primZip"
                      placeholder="ZIP"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                textAlign={'left'}
                alignSelf="start"
                display={{
                  xs: sysConfig.showProfilePhone ? 'block' : 'none',
                  md: 'block',
                }}
                visibility={sysConfig.showProfilePhone ? undefined : 'hidden'}
              >
                <Typography
                  component="h4"
                  variant="h6"
                  sx={{
                    color: '#000000',
                  }}
                  style={{ fontSize: '13px', marginTop: 14 }}
                >
                  Phone
                </Typography>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  size="small"
                  id="phone"
                  name="phone"
                  autoComplete="phone"
                  placeholder="Phone"
                  fieldProps={{ format: formatField }}
                />
              </Grid>
              <Grid item md={6} xs={12} textAlign={'left'} alignSelf="start">
                <Select
                  name="domicile"
                  label="Citizenship"
                  formControlProps={{ margin: 'dense' }}
                  size="small"
                >
                  {domicileChoices.map((m) => (
                    <MenuItem key={m.value} value={m.value}>
                      {m.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} textAlign={'left'} alignSelf="start">
                <Grid container spacing={3}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      sx={{
                        width: '100%',
                        marginTop: 1,
                        color: '#274f85',
                        borderRadius: '5px !important',
                      }}
                      onClick={() => {
                        handleCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        backgroundColor: '#274f85',
                        marginTop: 1,
                        color: '#ffffff',
                        borderRadius: '5px !important',
                      }}
                      onClick={handleSubmit}
                    >
                      {props.submitTitle ?? 'Continue'}
                    </Button>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    />
  );
};

export default ProfileForm;
