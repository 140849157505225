import { CustomButton } from "components/UIComponents/Button";
import UserDetailsLine from "../UserDetailsLine/UserDetailsLine";

import { UserProfile } from "types/Account";

import styles from "./UserDetails.module.scss";
import { BUTTON_OPTIONS, BUTTON_TEXT } from "pages/NewProfile/constants";
import { useUserDetailsFacade } from "./useUserDetailsFacade";

type UserDetailsProps = {
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  profileData: UserProfile;
};

const UserDetails = ({ setIsEdit, profileData }: UserDetailsProps) => {
  const {
    userName,
    emailAddress,
    firstUserDetailsArray,
    secondUserDetailsArray,
  } = useUserDetailsFacade(profileData);

  return (
    <section className={styles.wrapper}>
      <div className={styles.wrapperHeader}>
        <div>
          <p className={styles.name}>{userName}</p>
          <p className={styles.email}>{emailAddress}</p>
        </div>
        <div className={styles.buttonWrapper}>
          <CustomButton
            onClick={() => setIsEdit(true)}
            btnStyle={BUTTON_OPTIONS.EDIT}
          >
            <span className={styles.buttonText}>{BUTTON_TEXT.EDIT}</span>
          </CustomButton>
        </div>
      </div>
      <div className={styles.wrapperInformation}>
        <ul className={styles.firstBlock}>
          {firstUserDetailsArray.map((item) => {
            return (
              <li key={item.id}>
                <UserDetailsLine
                  name={item.firstColumn}
                  information={item.secondColumn}
                  isFirstItem={item.isFirstItem}
                  isLastItem={item.isLastItem}
                />
              </li>
            );
          })}
        </ul>
        <ul className={styles.secondBlock}>
          {secondUserDetailsArray.map((item) => {
            return (
              <li key={item.id}>
                <UserDetailsLine
                  name={item.firstColumn}
                  information={item.secondColumn}
                  isFirstItem={item.isFirstItem}
                  isLastItem={item.isLastItem}
                  isTextOnTop={item.isTextOnTop}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default UserDetails;
