export enum DIETARY_NEEDS {
  NONE = 'None',
  VEGETARIAN = 'Vegetarian',
  VEGAN = 'Vegan',
  GLUTEN_FREE = 'Gluten Free',
  PRESCATARIAN = 'Prescatarian',
}

export const DIETARY_NEEDS_OPTIONS = [
  { label: DIETARY_NEEDS.NONE, value: DIETARY_NEEDS.NONE },
  { label: DIETARY_NEEDS.VEGETARIAN, value: DIETARY_NEEDS.VEGETARIAN },
  { label: DIETARY_NEEDS.VEGAN, value: DIETARY_NEEDS.VEGAN },
  { label: DIETARY_NEEDS.GLUTEN_FREE, value: DIETARY_NEEDS.GLUTEN_FREE },
  { label: DIETARY_NEEDS.PRESCATARIAN, value: DIETARY_NEEDS.PRESCATARIAN },
];

export enum TRIP_VARIANTS {
  INDIVIDUAL_FULL = 'Individual, Full Experience',
  COUPLE_FULL = 'Couple, Full Experience',
  INDIVIDUAL_ONE_DINNER = 'Individual, One Dinner',
  INDIVIDUAL_THREE_DINNERS = 'Individual, Three Dinners',
}

export const TRIP_OPTIONS = [
  {
    label: TRIP_VARIANTS.INDIVIDUAL_FULL,
    value: TRIP_VARIANTS.INDIVIDUAL_FULL,
  },
  {
    label: TRIP_VARIANTS.COUPLE_FULL,
    value: TRIP_VARIANTS.COUPLE_FULL,
  },
  {
    label: TRIP_VARIANTS.INDIVIDUAL_ONE_DINNER,
    value: TRIP_VARIANTS.INDIVIDUAL_ONE_DINNER,
  },
  {
    label: TRIP_VARIANTS.INDIVIDUAL_THREE_DINNERS,
    value: TRIP_VARIANTS.INDIVIDUAL_THREE_DINNERS,
  },
];
