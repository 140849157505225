import { Box, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";

import { CustomButton } from "components/UIComponents/Button";
import BaseLayout from "layout/BaseLayout/baseLayout";
import { ModalWindow } from "./components/ModalWindow/ModalWindow";
import LuxusLogoNoTextWhite from "assets/icons/LuxusLogoNoTextWhite";
import Partners from "pages/Thoroughbred/components/ThoroughbredPage/components/Partners/Partners";
import AnnounceDetails from "./components/AnnounceDetails/AnnounceDetails";

import { classes } from "./styles";
import stylesPage from "./styles.module.scss";
import { MAIN_URL, ROUTES } from "utils/constants";

const ThoroughbredAnnounces = () => {
  const isMobile = useMediaQuery("(max-width: 575px)");
  const isTablet = useMediaQuery("(max-width: 880px)");
  const isWideScreen = useMediaQuery("(min-width:2440px)");
  const isSmallText = useMediaQuery("(max-width: 992px)");

  const [isModalVisible, setIsModalVisible] = useState(false);

  function changeModalVisible() {
    setIsModalVisible((prev) => !prev);
  }

  return (
    <BaseLayout
      pageName="Royal Ascot page"
      pageUri={`${MAIN_URL}${ROUTES.ROYAL_ASCOT_SIGN_UP}`}
    >
      <Box
        sx={classes.heroContainer(isTablet, isMobile)}
        style={{ overflow: "hidden" }}
      >
        <Box
          sx={classes.title(isMobile)}
          style={{ marginBottom: isSmallText ? "10px" : "20px" }}
        >
          <LuxusLogoNoTextWhite />
        </Box>
        <Typography sx={classes.title(isSmallText)}>
          Experience the Investment
        </Typography>
        <Typography sx={classes.ascotText(isSmallText)}>Royal Ascot</Typography>
        <Typography sx={classes.yearText(isSmallText)}>2024</Typography>
        {isTablet && <Box sx={classes.line} />}
        <iframe
          title="Royal Ascot"
          src="https://player.vimeo.com/video/949112288?h=17ad83c8c3&byline=0&autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1"
          width="3840"
          height="2160"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            overflow: "hidden",
            overflowX: "hidden",
            overflowY: "hidden",
            zIndex: 1,
            height: isTablet ? "100%" : "",
            width: isTablet ? "" : "100%",
            position: "absolute",
            top: isWideScreen ? "-50%" : isTablet ? "0px" : "-850px",
          }}
        ></iframe>
        <Typography sx={classes.hint(isSmallText)}>
          Stay tuned for the ipo announcement next week
        </Typography>
      </Box>
      <Box
        sx={classes.bodyWrapper(isTablet)}
        style={{
          zIndex: 11,
          position: "relative",
          paddingBottom: "80px",
        }}
      >
        <Box sx={classes.bodyContainer(isTablet)} style={{ gap: "0px" }}>
          <div className={stylesPage.textsWrapper}>
            <CustomButton
              onClick={changeModalVisible}
              btnStyle="iconBtnPrimaryBackgroundWithoutIcon"
            >
              GET ON THE LIST
            </CustomButton>
            <p className={stylesPage.note}>For Accredited Investors Only</p>
            <Partners />
            <AnnounceDetails />
          </div>
        </Box>
      </Box>
      <ModalWindow
        changeVisible={changeModalVisible}
        isModalVisible={isModalVisible}
      />
    </BaseLayout>
  );
};

export default ThoroughbredAnnounces;
