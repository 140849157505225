import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        text: {
          fontSize: "21px",
        },
        checkbox: {
          "& svg": {
            height: "25px",
            width: "25px",
          },
        },
      })
    )
);
