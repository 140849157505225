import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { CustomButton } from "components/UIComponents/Button";
import JoinForm from "../JoinForm/JoinForm";
import ModalJoinSubscribed from "./ModalJoinSubscribed";

import styles from "./ModalJoin.module.scss";
import { MAIN_URL } from "utils/constants";

type ModalJoinProps = {
  handleModal: () => void;
};

const ModalJoin: React.FC<ModalJoinProps> = ({ handleModal }) => {
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <CustomButton btnStyle="iconBtn" onClick={handleModal}>
          CLOSE
        </CustomButton>
      </div>
      {isUserSubscribed ? (
        <ModalJoinSubscribed />
      ) : (
        <div>
          <h2 className={styles.title}>JOIN OUR NEWSLETTER</h2>
          <p className={styles.text}>
            Subscribe to our newsletter for insights, updates and more
          </p>
          <JoinForm
            pageName="Home page"
            pageUri={MAIN_URL}
            isUserSubscribed={isUserSubscribed}
            setIsUserSubscribed={setIsUserSubscribed}
            buttonText="SIGN UP"
          />
          {/* <p className={styles.note}>
            Already part of the LUXUS community?{" "}
            <span className={styles.link} onClick={() => navigate("/login")}>
              Sign in
            </span>
          </p> */}
        </div>
      )}
    </div>
  );
};

export default ModalJoin;
