import themeData from "../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    placeContent: "center",
    padding: "120px 0 127px 0",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%", // Fix IE 11 issue.
  },
  userNameText: {
    textAlign: "center",
    color: "#000000",
    height: "35px",
    fontSize: "30px",
    paddingBottom: "10px",
  },
  welcomeText: {
    textAlign: "center",
    color: "#000000",
    height: "35px",
    fontSize: "30px",
    paddingBottom: "30px",
  },
  stepText: {
    textAlign: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "20px",
    fontWeight: "300",
    width: "120%",
  },
  stepper: {
    width: "125%",
    marginBottom: "40px",
  },
  stepperConnector: {
    borderColor: "#4e4e56",
    margin: "15px 0 0 7px",
    width: "131px",
    zIndex: -1,
  },
  step: {
    "& svg": {
      width: "55px",
      height: "55px",
      zIndex: "1",
      borderRadius: "29px",
    },
    "& text": {
      fill: "black !important",
    },
  },
  currStep: {
    "& svg": {
      color: "#DFDFE3 !important",
    },
  },
  notCurrStep: {
    "& svg": {
      color: "black !important",
    },
  },
  blackBtn: {
    fontSize: "20px",
    fontWeight: "300",
    marginBottom: "10px",
  },
  legalEntityBtn: {
    fontSize: "20px",
    fontWeight: "300",
    marginBottom: "10px",
  },
  textBtn: {
    color: themeData.WhiteColor,
    backgroundColor: "#6890FF",
    fontSize: "20px",
    fontWeight: "300",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#516fc1",
    },
  },
  stepLabel: {
    "& .MuiStepLabel-labelContainer": {
      width: "75px",
    },
    "& .MuiStepLabel-label": {
      fontWeight: "300",
    },
    "& .MuiStepIcon-text": {
      fontSize: "10px",
    },
  },
  link: {
    color: "black",
    textDecorationColor: "black",
  },
  skip: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "10px",
  },
};

export type ClassesNames = keyof typeof commonStyles;
