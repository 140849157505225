import React from "react";
import {Box, Typography, Button} from "@mui/material";

import useSuccessfullBuyFacade from "./useSucessfullBuyFacade";
import {useStyles} from "./styles/";
import style from './style.module.scss'

import {Preloader} from "../../../components/simpleComponents/Preloader";
import {CustomButton} from "../../../components/UIComponents/Button";

const SuccessfullBuy: React.FC = function (): JSX.Element {
  const [imageSrc, offerName, isLoading, onButtonClick, isDesktop] =
    useSuccessfullBuyFacade();

  const classes = useStyles();

  return isLoading ? (
    <Preloader/>
  ) : (
    <div className={style.container}>
      <img src={imageSrc} alt=""/>
      <h2>{offerName}</h2>
      <p>
        Thank you for your purchase!
        <br className={classes.brElem}/>
        Please check your email for a receipt.
      </p>
      <CustomButton onClick={onButtonClick}
                    btnStyle={'primary'}>
        BROWSE MORE ASSETS
      </CustomButton>
    </div>
  );
};

export default SuccessfullBuy;
