import { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";

import { SuccessModal } from "components/Footer/SuccessModal/SuccessModal";
import BaseLayout from "layout/BaseLayout/baseLayout";
import TiaraAsset from "pages/Dashboard/components/TiaraAsset/TiaraAsset";
import JoinForm from "pages/NewAssests/Assets/components/JoinForm/JoinForm";
import LuxusBigLogo from "assets/icons/LuxusBigLogo";
import GarrardBigLogo from "assets/icons/GarrardBigLogo";
import { TiaraForm } from "./components/TiaraForm";
import { ReactComponent as WomenInFinance } from "public/images/100WIF_white 1.svg";
import SuccessMessage from "./components/SuccessMessage/SuccessMessage";
import { Preloader } from "components/simpleComponents/Preloader";

import { icons } from "pages/Derby/Derby.data";
import { classes } from "pages/Derby/styles";
import { MAIN_URL, ROUTES } from "utils/constants";

import stylesTiara from "./styles.module.scss";
import { STEPS } from "./constants";

export const Tiara100Wif = () => {
  const isMobile = useMediaQuery("(max-width: 530px)");
  const isTablet = useMediaQuery("(max-width: 880px)");
  const isWideScreen = useMediaQuery("(min-width:2440px)");
  const [isLoading, setIsLoading] = useState(false);

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [step, setStep] = useState<STEPS>(STEPS.FORM);

  return (
    <BaseLayout
      pageName="Tiara 100wif page"
      pageUri={`${MAIN_URL}${ROUTES.TIARA_100WIF}`}
      removeForm={true}
    >
      <Box
        sx={classes.heroContainer(isTablet, isMobile)}
        style={{ overflow: "hidden" }}
      >
        <Typography
          sx={{
            ...classes.title(isTablet),
            zIndex: 11,
            testTransform: "uppercase",
          }}
        >
          <div className={stylesTiara.topLogoContainer}>
            <span className={stylesTiara.luxusContainer}>
              <LuxusBigLogo />
            </span>
            <span className={stylesTiara.x}>X</span>
            <span className={stylesTiara.wifContainer}>
              <WomenInFinance />
            </span>
          </div>
        </Typography>
        <Typography
          sx={{
            ...classes.title(isTablet),
            zIndex: 11,
            textTransform: "uppercase",
            fontSize: isMobile ? "20px" : "40px",
          }}
        >
          What if you could own a piece of history in the making?
        </Typography>
        {isTablet && <Box sx={classes.line} />}
        <iframe
          title="Tiara"
          src="https://player.vimeo.com/video/905988828?h=17ad83c8c3&byline=0&autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1"
          width="3840"
          height="2160"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            overflow: "hidden",
            overflowX: "hidden",
            overflowY: "hidden",
            zIndex: 1,
            height: isTablet ? "100%" : "",
            width: isTablet ? "" : "100%",
            position: "absolute",
            top: isWideScreen ? "-50%" : isTablet ? "0px" : "-850px",
          }}
        ></iframe>
      </Box>
      <Box
        sx={classes.bodyWrapper(isTablet)}
        style={{
          zIndex: 11,
          position: "relative",
        }}
      >
        <Box sx={classes.bodyContainer(isTablet)} style={{ gap: "0px" }}>
          <div>
            <Typography
              sx={{
                ...classes.text(isTablet),
                fontFamily: "Futura PT",
                fontSize: isMobile ? "16px" : "25px",
                marginBottom: "30px",
              }}
            >
              Thank you for joining us tonight at Christies to celebrate
              International Women’s Day.
            </Typography>
            <Typography
              sx={{
                ...classes.text(isTablet),
                fontFamily: "Futura PT Light",
                fontSize: isMobile ? "16px" : "25px",
                marginBottom: "30px",
                maxWidth: isMobile ? "500px" : "853px",
              }}
            >
              LUXUS is thrilled to present a limited Investment Opportunity to
              100 Women in Finance, ahead of the official press announcement!
            </Typography>
            <Typography
              sx={{
                ...classes.text(isTablet),
                fontFamily: "Futura PT Light",
                fontSize: isMobile ? "16px" : "25px",
                marginBottom: "30px",
                maxWidth: isMobile ? "500px" : "833px",
              }}
            >
              Invest in the private offering of "The Tiara," meticulously
              crafted by Garrard, Royal Jewelers with a legacy dating back to
              1735 and commissioned exclusively by LUXUS.
              <br />
            </Typography>
            <Typography
              sx={{
                ...classes.text(isTablet),
                fontFamily: "Futura PT Light",
                fontSize: isMobile ? "16px" : "25px",
                marginTop: "10px",
                maxWidth: isMobile ? "500px" : "833px",
              }}
            >
              Simply complete the form below to embark on this journey with
              LUXUS and Garrard and to join the select few owning a piece of
              history.
            </Typography>
          </div>
          {step === STEPS.FORM && (
            <div className={stylesTiara.subdocFormContainer}>
              {isLoading ? (
                <Preloader isWhite isSmallHeight />
              ) : (
                <TiaraForm setIsLoading={setIsLoading} changeStep={setStep} />
              )}
            </div>
          )}
          {step === STEPS.SUCCESS_MESSAGE && <SuccessMessage />}
          <TiaraAsset isShowMoreUpcoming={false} isWithOneButton={true} />
        </Box>
        <div className={stylesTiara.logoSection}>
          <div className={stylesTiara.luxusLogoContainer}>
            <LuxusBigLogo />
          </div>
          <p className={stylesTiara.logoSectionText}>DESIGNED BY:</p>
          <div>
            <div className={stylesTiara.garrardLogoContainer}>
              <GarrardBigLogo />
            </div>
          </div>
        </div>
        <Typography sx={classes.title(isTablet)}>
          AT THE INTERSECTION
          <br /> OF FINANCE & LUXURY
        </Typography>
        <Box component={"ul"} sx={classes.iconList(isTablet)}>
          {icons.map((link) => (
            <Box component={"li"} key={link[0]}>
              <Box
                component={"img"}
                src={link[0]}
                srcSet={`${link[0]} 2000w, ${link[1]} 2001w`}
                alt={link[0].split("/").pop()}
                sx={classes.icon(isTablet)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <div className={stylesTiara.formContainer}>
        <h2 className={stylesTiara.title}>JOIN OUR NEWSLETTER</h2>
        <h6 className={stylesTiara.text}>
          Subscribe to our newsletter for insights, updates and more
        </h6>
        <JoinForm
          pageName="TIara 100wif page"
          pageUri={`${MAIN_URL}${ROUTES.TIARA_100WIF}`}
          buttonText="SUBSCRIBE"
        />
      </div>
      <SuccessModal
        visible={successModalVisible}
        setModalVisible={setSuccessModalVisible}
      />
    </BaseLayout>
  );
};
