import { BankAccountData, CreditCardData } from "../../../types/Account";
import { PaymentType } from "../../../types/enums/PaymentType";
import { Offering } from "../../../types/Offerings";
import OfferingNumbersFormatter from "../../../utils/OfferingNumbersFormatter";
import ReplaceOfferImage from "../../../utils/ReplaceOfferImage";

export const proceedOffer = (offer: Offering): Offering => {
  return OfferingNumbersFormatter(ReplaceOfferImage([offer])[0]);
};

const getOffer = async (
  id: string,
  api: any,
  setOffer: React.Dispatch<React.SetStateAction<Offering | undefined>>
): Promise<void> => {
  const result = await api.getOffering(id);

  if (result.data.response) {
    setOffer(proceedOffer(result.data.response));
  }
};

export const getPaymentsInfo = async (
  api: any,
  setBankData: React.Dispatch<
    React.SetStateAction<BankAccountData | undefined>
  >,
  setCardData: React.Dispatch<React.SetStateAction<CreditCardData | undefined>>,
  setChosenMethods: React.Dispatch<React.SetStateAction<PaymentType>>
): Promise<void> => {
  const result = await api.getRegisteredPayments();

  if (result?.data?.response?.data?.bankAccount?.AccountNumber) {
    setBankData(result.data.response.data.bankAccount as BankAccountData);
    setChosenMethods(PaymentType.BANK);
  }

  if (result?.data?.response?.data?.creditCard?.creditCardNumber) {
    setCardData(result.data.response.data.creditCard as CreditCardData);
    setChosenMethods(PaymentType.CARD);
  }
};

export const getData = async (
  id: string,
  api: any,
  setOffer: React.Dispatch<React.SetStateAction<Offering | undefined>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setBankData: React.Dispatch<
    React.SetStateAction<BankAccountData | undefined>
  >,
  setCardData: React.Dispatch<React.SetStateAction<CreditCardData | undefined>>,
  setChosenMethods: React.Dispatch<React.SetStateAction<PaymentType>>
): Promise<void> => {
  setIsLoading(true);

  await getOffer(id, api, setOffer);
  await getPaymentsInfo(api, setBankData, setCardData, setChosenMethods);

  setIsLoading(false);
};
