import { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";

import { Preloader } from "components/simpleComponents/Preloader";
import { CustomButton } from "components/UIComponents/Button";
import { ControledField } from "./ControledField";
import ControlledDropdown from "./ControlledDropdown/ControlledDropdown";
import InputMask from "./InputMask/InputMask";
import PurchaseCounter from "./PurchaseCounter/PurchaseCounter";

import { countries } from "utils/CoutriesList";
import stateCodes from "utils/states";
import { ipSelector } from "store/selectors/system";
import {
  UserDataNonResident,
  sendUserDataToInitiateSubdocFlow,
} from "apis/backend";

import { FormData, MainFormValues } from "../AccreditationForm.type";
import {
  DOMICILES,
  SHARES_QUANTITY,
  STEPS,
  mainFormInitialValues,
} from "../AccreditationForm.data";
import { mainFormSchema } from "../schema";

import style from "./style.module.scss";

type FirstStepProps = {
  form: FormData;
  setForm: React.Dispatch<React.SetStateAction<FormData>>;
  guid: string;
  setStep: (step: STEPS) => void;
};

const FirstStep = ({ form, setForm, guid, setStep }: FirstStepProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const ip = useSelector(ipSelector);

  const minimumNumberOfShares = form.allowedOneShare
    ? SHARES_QUANTITY.ALTERNATIVE_MIN
    : SHARES_QUANTITY.MIN;

  const nonResidencyCondition = form.citizenship === DOMICILES.NON_US_CITIZEN;
  const residencyCondition =
    form.citizenship === DOMICILES.US_CITIZEN ||
    form.citizenship === DOMICILES.US_RESIDENT;

  const states = stateCodes.map(({ label, id }) => ({
    label,
    value: id,
  }));

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<MainFormValues>({
    resolver: yupResolver(mainFormSchema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: mainFormInitialValues,
  });

  const handleCountChange = useCallback(
    function handleCountChange(newCount: number) {
      setForm((state) => ({ ...state, securitiesNumber: newCount }));
    },
    [setForm]
  );

  async function onSubmit(data: MainFormValues) {
    if (
      form.securitiesNumber < minimumNumberOfShares ||
      form.securitiesNumber > SHARES_QUANTITY.MAX
    ) {
      return;
    }

    const userData = {
      sharesQty: form.securitiesNumber,
      dob: data.birthDate,
      streetAddress: data.street,
      city: data.city,
      zip: data.primZip,
      firstName: data.firstName,
      lastName: data.lastName,
    };

    if (nonResidencyCondition) {
      const userDataNonResident: UserDataNonResident = {
        ...userData,
        country: data.country || "",
        createdIpAddress: ip,
      };

      try {
        setIsLoading(true);
        const data = await sendUserDataToInitiateSubdocFlow(
          guid,
          userDataNonResident
        );
        setIsLoading(false);

        if (data) {
          setStep(STEPS.LOAD_DOCUMENTS_FORM);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      setForm((state) => ({
        ...state,
        ...userData,
        ip,
        street: data.street,
        primState: data.primState || "",
        primZip: data.primZip,
        birthDate: data.birthDate,
        streetAddressOptional: data.streetAddressOptional,
      }));
      setStep(STEPS.SSN_FORM);
    }
  }

  useEffect(() => {
    reset({
      firstName: form.firstName,
      lastName: form.lastName,
      isCountryRequired: nonResidencyCondition,
      isStateRequired: residencyCondition,
    });
  }, [
    form.citizenship,
    form.firstName,
    form.lastName,
    nonResidencyCondition,
    reset,
    residencyCondition,
  ]);

  return (
    <>
      {isLoading ? (
        <Preloader isWhite />
      ) : (
        <div className={style.content}>
          <h2 className={style.title}>THE TIARA OFFERING</h2>
          <p className={style.text}>
            Thank you for your interest in the Tiara Offering. To continue with
            the investment, we will need to verify your identity
          </p>
          <form className={style.mainForm} onSubmit={handleSubmit(onSubmit)}>
            <div className={style.row}>
              <ControledField
                control={control}
                placeholder="FIRST NAME"
                {...register("firstName")}
                isError={!!errors.firstName}
                errorMessage={errors.firstName?.message}
              />
              <ControledField
                control={control}
                placeholder="LAST NAME"
                {...register("lastName")}
                isError={!!errors.lastName}
                errorMessage={errors.lastName?.message}
              />
            </div>
            {nonResidencyCondition && (
              <ControlledDropdown
                placeholderColor="#fff"
                isError={!!errors.country}
                errorMessage={errors.country?.message}
                placeholder="COUNTRY OF CITIZENSHIP"
                name="country"
                dropdownOptions={countries}
                control={control}
                initValue={{
                  value: "country",
                  label: "COUNTRY OF CITIZENSHIP",
                }}
              />
            )}
            <div className={style.row}>
              <ControledField
                control={control}
                placeholder="STREET ADDRESS"
                {...register("street")}
                isError={!!errors.street}
                errorMessage={errors.street?.message}
              />
              {residencyCondition && (
                <ControledField
                  control={control}
                  placeholder="ADDRESS LINE 2"
                  {...register("streetAddressOptional")}
                />
              )}
            </div>
            <ControledField
              control={control}
              placeholder="CITY"
              {...register("city")}
              isError={!!errors.city}
              errorMessage={errors.city?.message}
            />
            {residencyCondition && (
              <ControlledDropdown
                placeholderColor="#fff"
                isError={!!errors.primState}
                errorMessage={errors.primState?.message}
                placeholder="STATE"
                name="primState"
                dropdownOptions={states}
                control={control}
                initValue={{
                  label: "STATE",
                  value: "STATE",
                }}
              />
            )}
            <ControledField
              control={control}
              placeholder={nonResidencyCondition ? "POST CODE" : "ZIP CODE"}
              {...register("primZip")}
              isError={!!errors.primZip}
              errorMessage={errors.primZip?.message}
            />
            <div className={style.birthDateContainer}>
              <Controller
                name="birthDate"
                control={control}
                render={({ field }) => (
                  <InputMask
                    mask="99-99-9999"
                    placeholder="DATE OF BIRTH"
                    register={register}
                    isError={!!errors.birthDate}
                    errorMessage={errors.birthDate?.message}
                    {...field}
                  />
                )}
              />
            </div>
            <PurchaseCounter
              minimumNumberOfShares={minimumNumberOfShares}
              securitiesNumber={form.securitiesNumber}
              handleCountChange={handleCountChange}
            />
            <div className={style.center} style={{ marginTop: "48px" }}>
              <CustomButton type="submit" btnStyle="secondaryBlack">
                CONTINUE
              </CustomButton>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default FirstStep;
