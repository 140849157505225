import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        marginTop: "0",
      },
      label: {
        marginLeft: "10px",
        marginBottom: "0",
        "& span": {
          fontSize: "15px",
        },
      },
      finraTypeContainer: {
        margin: "0 auto",
        width: "100%",
        "& .MuiFormGroup-root": {
          width: "100%",
        },
      },
    })
  )
);
