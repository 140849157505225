import { CloseIcon } from "assets/icons/close";
import { FileUploadIcon } from "assets/icons/file-upload";
import React, { ChangeEvent, useCallback, useState } from "react";
import { Filename, UploadCircle, UploadFrame } from "./styledComponents";
import { useDropzone } from "react-dropzone";
import { FileUploadProps, FileUploadType } from "./FileUpload.type";
import { useMediaQuery } from "@mui/material";

const FileUploadComponent: React.FC<FileUploadProps> = ({
  theme,
  onChange,
  value,
  setFileData,
}) => {
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  const [isUploaded, setIsUploaded] = useState(false);
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onChange(event.target.files[0]);
    }
  };

  const proceedFile = (file: File | null): void => {
    if (!file) return;
    if (file.size > 10 ** 7) return;
    onChange(file);
    setIsUploaded(true);

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setFileData(fileReader.result as string, file.name.split(".")[0]);
    };
    fileReader.readAsDataURL(file);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      proceedFile(acceptedFiles[0]);
    },
    [isUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "image/*": [".png", ".jpeg", ".jpeg", ".heic", ".heif"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/rtf": [".rtf"],
    },
    noDragEventsBubbling: true,
  });

  const removeFile = () => {
    onChange(null);
  };

  return (
    <UploadFrame theme={theme}>
      {value ? (
        <UploadCircle theme onClick={removeFile}>
          <CloseIcon color={theme === "black" ? "#FFFFFF" : "#000000"} />
          <Filename theme={theme}>{value.name}</Filename>
        </UploadCircle>
      ) : (
        <>
          <UploadCircle theme={theme} {...getRootProps()}>
            <FileUploadIcon color={theme === "black" ? "#FFFFFF" : "#000000"} />
            <i
              className="material-icons"
              style={
                isMobileScreen
                  ? { fontSize: "12px" }
                  : { maxWidth: "9vw", fontSize: "12px" }
              }
            >
              DROP FILE HERE OR
              <br />
              <label
                style={{
                  cursor: "pointer",
                  color: theme === "black" ? "#FFFFFF" : "#000000",
                  textDecoration: "underline",
                }}
              >
                {" "}
                BROWSE
              </label>
            </i>
          </UploadCircle>
          <input
            type="file"
            accept="image/*,.doc,.docx,.rtf,.pdf"
            style={{ display: "none" }}
            onChange={onFileChange}
            id="file-upload"
            {...getInputProps()}
          />
        </>
      )}
    </UploadFrame>
  );
};

const FileUpload = {
  Black: (props: FileUploadType) => (
    <FileUploadComponent
      theme="black"
      value={props.value}
      onChange={props.onChange}
      setFileData={props.setFileData}
    />
  ),
  White: (props: FileUploadType) => (
    <FileUploadComponent
      theme="white"
      value={props.value}
      onChange={props.onChange}
      setFileData={props.setFileData}
    />
  ),
};

export default FileUpload;
