import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import ReactGA from "react-ga";

import { useStyles } from "./styles";

interface DirectorBoardMemberFormProps {
  setMemberOfTradedPubliclyProfile(value: string): void;
  initMemberOfTradedPubliclyProfile: string;
}

const DirectorBoardMemberForm: React.FC<DirectorBoardMemberFormProps> =
  function ({
    setMemberOfTradedPubliclyProfile,
    initMemberOfTradedPubliclyProfile,
  }): JSX.Element {
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + "/DirectorBoardMember");
    }, []);

    const getInitValue = (): any => {
      if (initMemberOfTradedPubliclyProfile === "yes") return true;
      if (initMemberOfTradedPubliclyProfile === "no") return false;
      return;
    };
    const [isHave, setIsHave] = useState<boolean>(getInitValue());

    const onInputChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setIsHave(event.target.value === "yes" ? true : false);
      setMemberOfTradedPubliclyProfile(event.target.value);
    };

    const classes = useStyles();

    return (
      <FormControl className={classes.container}>
        <RadioGroup
          name="accreditaion-status-group"
          row
          onChange={onInputChange}
        >
          <FormControlLabel
            key="yes"
            label="Yes"
            control={<Radio color="default" size="medium" checked={isHave} />}
            value={"yes"}
            className={classes.label}
          />
          <FormControlLabel
            key="no"
            label="No"
            control={
              <Radio color="default" size="medium" checked={isHave === false} />
            }
            value={"no"}
            className={classes.label}
          />
        </RadioGroup>
      </FormControl>
    );
  };

export default DirectorBoardMemberForm;
