export const classes = {
  wrapper: () => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    alignItems: "center",
    overflow: "hidden",
  }),
  textContainer: () => ({
    maxWidth: "640px",
    margin: "0 auto",
    padding: "0 15px",
  }),
  titleGallery: (isMobile: boolean) => ({
    color: "#F6F3F2",
    textAlign: "center" as "center",
    leadingTrim: "both",
    textEdge: "cap",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.50)",
    fontFamily: '"Telugu MN"',
    fontSize: isMobile ? "30px" : "40px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.8px",
    textTransform: "uppercase" as "uppercase",
    margin: "0",
  }),
  textGallery: (isMobile: boolean) => ({
    color: "#F6F3F2",
    textAlign: "center" as "center",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Telugu MN",
    fontSize: isMobile ? "14px" : "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.8px",
    marginTop: "28px",
  }),
  container: () => ({
    maxWidth: "1440px",
    overflow: "hidden",
    marginBottom: "47px",
  }),
  sliderImage: {
    height: "250px",
    objectFit: "cover",
    overflow: "hidden",
  },
};
