import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useServerAPI } from "../../../apis";

import { Offering } from "../../../types/Offerings";

import OfferingNumbersFormatter from "../../../utils/OfferingNumbersFormatter";
import ReplaceOfferImage from "../../../utils/ReplaceOfferImage";
import ReactGA from "react-ga";

import useGetDisplayType from "../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../types/enums/DisplayTypes";

export default function useAssetsDetailsFacade(): [
  Offering,
  boolean,
  (val: number) => void,
  () => void,
  boolean,
  boolean
] {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/IOISubmit");
  }, []);

  const api = useServerAPI();
  const id = useParams().id;
  const navigate = useNavigate();

  const proceedOffer = (offer: Offering): Offering => {
    return OfferingNumbersFormatter(ReplaceOfferImage([offer])[0]);
  };

  const desktop = useGetDisplayType([
    DisplayTypes.Desktop,
    DisplayTypes.TabletLandscape,
  ]);

  const [activeOffer, setActiveOffer] = useState<Offering>();
  const [isLoadind, setIsLoading] = useState(false);
  const [isThanks, setIsThanks] = useState(false);

  const getOfferById = async (): Promise<void> => {
    const result = await api.getOffering(id as string);
    if (result.data.response) {
      setActiveOffer(proceedOffer(result.data.response));
    }
  };

  const getData = async (): Promise<void> => {
    setIsLoading(true);
    await getOfferById();
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  const onSubmitClick = async (itemsCount: number): Promise<void> => {
    if (itemsCount <= 0) {
      return;
    }

    setIsLoading(true);
    try {
      const result = await api.registerIntentOfInvestment({
        transactionUnits: itemsCount,
        offeringId: Number(id),
        NotifyInLive: false,
      });
      if (result.data.success) {
        setIsThanks(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onBrowseMoreClick = (): void => {
    navigate(`/`);
  };

  return [
    activeOffer as Offering,
    isLoadind,
    onSubmitClick,
    onBrowseMoreClick,
    desktop,
    isThanks,
  ];
}
