import { themeData } from "../../../../../../utils/theme";

export const commonStyles = {
  container: {
    background: "#1D1D1D",
    borderRadius: "5px",
    padding: "22px",
  },
  btn: {
    width: "100%",
  },
  caption: {
    fontSize: "16px",
    fontWeight: "450",
    color: "#FFFFFF",
  },
  text: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#FFFFFF",
  },
  row: {
    marginBottom: "20px",
  },
};

export type ClassNames = keyof typeof commonStyles;
