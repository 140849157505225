import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
    createStyles(
        mixStyleRules(commonStyles, {
            formFieldSmall: {
                width: "200px",
                marginBottom: "10px",
                "& .MuiOutlinedInput-root": {
                    height: "44px",
                    fontWeight: "300",
                    fontSize: "15px",
                },
                "& .MuiAutocomplete-input": {
                    padding: "7.5px 4px 7.5px 6px !important",
                },
                "& input::placeholder": {
                    fontSize: "15px",
                }
            },
        })
    )
);