import TreeLineSmall from "assets/icons/TreeLineSmall";

import styles from "./FamilyNode.module.scss";

type FamilyNodeProps = {
  firstName: string;
  secondName: string;
  firstImage: string;
  secondImage: string;
  topName: string;
  topImage: string;
};

const FamilyNode = ({
  firstName,
  firstImage,
  secondImage,
  secondName,
  topImage,
  topName,
}: FamilyNodeProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.arrowContainer}>
        <div className={styles.nodeContainerTop}>
          <img className={styles.image} src={topImage} alt={topName} />
          <p className={styles.text}>{topName}</p>
        </div>
        <TreeLineSmall width="100%" />
      </div>
      <div className={styles.nodesContainer}>
        <div className={styles.nodeContainer}>
          <img className={styles.image} src={firstImage} alt={firstName} />
          <p className={styles.text}>{firstName}</p>
        </div>
        <div className={styles.nodeContainer}>
          <img className={styles.image} src={secondImage} alt={secondName} />
          <p className={styles.text}>{secondName}</p>
        </div>
      </div>
    </div>
  );
};

export default FamilyNode;
