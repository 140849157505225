import { Footer } from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { Auth } from "./Auth/Auth";

import { HEADER_OPTIONS } from "../../components/Header/constants";

import { MAIN_URL, ROUTES } from "utils/constants";

export const VipPage = () => {
  return (
    <>
      <Header color={HEADER_OPTIONS.WHITE} />
      <Auth />
      <Footer
        pageName="Vip page"
        pageUri={`
        ${MAIN_URL}${ROUTES.VIP}
      `}
      />
    </>
  );
};
