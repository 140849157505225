export const classes = {
  videoContainer: (isMobile: boolean, VideoEndPoint: boolean) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: VideoEndPoint ? "285px" : isMobile ? "520px" : "747px",
    overflow: "hidden",
  }),
  aboutLuxusContainer: (isMobile: boolean) => ({
    display: "grid",
    width: "100%",
    height: isMobile ? "fit-content" : "800px",
    backgroundColor: "#1a1a1a",
    color: "#fff",
    gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
    gridTemplateRows: isMobile ? "none" : "1fr",
    gap: isMobile ? "18px" : "70px",
    maxHeight: isMobile ? "auto" : "800px",
  }),
  jewelryImageContainer: (isMobile: boolean) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: isMobile ? "hidden" : "visible",
    height: isMobile ? "200px" : "auto",
    width: isMobile ? "unset" : "600px",
    justifySelf: isMobile ? "unset" : "end",
    paddingTop: isMobile ? "220px" : "0",
  }),
  jewelryImage: (isMobile: boolean) => ({
    overflow: isMobile ? "hidden" : "visible",
    objectPosition: "bottom",
    transform: isMobile ? "translateY(-30%)" : "translateY(0%)",
    height: isMobile ? "300%" : "100%",
    maxHeight: isMobile ? "400px" : "800px",
  }),
  aboutLuxusTextBox: (isMobile: boolean) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: isMobile ? "fit-content" : "100%",
    justifySelf: isMobile ? "unset" : "start",
  }),
  ourTeamWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F2F2F0",
    padding: "64px 0px",
  },
  outTeamContainer: (isMobile: boolean) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: isMobile ? "24px" : "82px",
    padding: isMobile ? "32px 20px" : "20px 0px",
    flexDirection: isMobile ? "column-reverse" : "row",
    textAlign: isMobile ? "center" : "left",
  }),
  ourTeamTitleContainer: (isMobile: boolean) => ({
    padding: isMobile ? "0px 20px" : "0px",
    marginBottom: "24px",
  }),
  ourTeamTextContainer: (isMobile: boolean) => ({
    padding: isMobile ? "0px 20px" : "0px",
  }),
  secondTitle: {
    fontSize: "20px",
    letterSpacing: "0.4px",
    lineHeight: "25.64px",
    fontFamily: "Futura PT Book",
  },
  text: {
    fontFamily: "Futura PT Book",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21px",
  },
  title: {
    fontSize: "30px",
    lineHeight: "36.43px",
    textAlign: "center",
    fontFamily: "Telugu MN",
    letterSpacing: "0.6px",
  },
  partnersWrapper: (isMobile: boolean) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: isMobile ? "48px 0px" : "80px 0px",
    gap: "64px",
    overflow: "hidden",
  }),
  partnersContainer: (isMobile: boolean) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: "1040px",
    gap: isMobile ? "48px" : "46px 80px",
    padding: "0px 20px",
    width: "calc(100% - 40px)",
  }),
  advisorsWrapper: (isMobile: boolean) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: isMobile ? "48px 20px" : "80px 0px",
    gap: isMobile ? "59px" : "64px",
  }),
  advisorsContainer: (isMobile: boolean) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: isMobile ? "48px" : "52px 80px",
    textAlign: "center",
  }),
};
