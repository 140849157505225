import React, { useState } from "react";
import {CountrySelect} from "../../../../../../components/simpleComponents/CountrySelect";
import { NewUserProfileForm } from "../../../ConfigureProfile";
import {SelectChangeEvent} from "@mui/material";
import {useStyles} from "./styles";

interface CountryFormProps {
    getPropertySetter<T extends keyof NewUserProfileForm>(
        property: T
    ): (value: NewUserProfileForm[T]) => void;
    initPrimCountry: string;
}

const CountryForm: React.FC<CountryFormProps> = function ({ getPropertySetter, initPrimCountry }): JSX.Element {
    const [primCountry, setPrimCountry] = useState<string>(initPrimCountry || "")

    const getOnSelectHandler = (key: string) => (event: SelectChangeEvent) => {
        const { value } = event.target;
        setPrimCountry(value);
        getPropertySetter("primCountry")(value);
    };

    const classes = useStyles();

    return (
        <CountrySelect
            value={primCountry}
            onChange={(val) => {
                getOnSelectHandler("primCountry")({
                    //@ts-ignore
                    target: { value: val },
                });
            }}
            placeholder="Country"
            className={classes.countryField}
        />
    )
};

export default CountryForm;