import { themeData } from "../../../../../../utils/theme";

export const commonStyles = {
  progressBarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  progressBarContent: {
    marginRight: "30px",
    width: "100%",
  },
  progressBarOne: {
    backgroundColor: "#1d1d1d !important",
    height: "10px !important",
    borderRadius: "5px",
  },
  progressBarRoot: {
    backgroundColor: "#6890ff !important",
    height: "18px !important",
  },
  nameTitleBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "20px",
  },
  nameTitle: {
    fontSize: "38px",
    fontWeight: "450",
    marginRight: "8px",
  },
  hrElem: {
    height: "2.5px",
    backgroundColor: "#E5E5E5",
    border: "none",
    margin: "20px 0 10px 0",
  },
  infoBox: {
    display: "flex",
    flexDirection: "column",
  },
  infoItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "13px",
    "&:last-child": {
      marginBottom: "0",
      flexWrap: "nowrap",
    },
  },
  infoItemTitle: {
    fontSize: "25px",
    fontWeight: "300",
    "&:last-child": {
      marginRight: "30px",
    },
  },
  amountInfoBox: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "23px",
  },
  amountInfoItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "17px",
    "&:last-child": {
      marginBottom: "0",
    },
  },
  amountInfoItemTitle: {
    fontSize: "25px",
    fontWeight: "300",
    marginRight: "30px",
  },
  amountInfoItemValue: {
    fontSize: "25px",
    fontWeight: "600",
  },
  soldOutBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  buySharesBtn: {
    backgroundColor: "#6890FF",
    padding: "10px 20px",
    fontSize: "25px",
    fontWeight: "400",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#516fc1",
    },
  },
  buySharesBtnDisabled: {
    backgroundColor: "#E0E0E0",
    color: "#00000050",
  },
  feeIcon: {
    height: "62px",
    width: "59px",
  },
  popoverPaper: {
    backgroundColor: "#DFDFE3 !important",
    maxWidth: "340px",
    padding: "15px 10px",
    position: "relative",
    overflow: "visible",
  },
  popoverTitle: {
    fontSize: "18px",
    fontWeight: "500",
  },
  popoverText: {
    fontSize: "18px",
    fontWeight: "300",
  },
  popoverTriangle: {
    position: "relative",
    "&::before": {
      backgroundColor: "#DFDFE3 !important",
      content: '""',
      display: "block",
      position: "absolute",
      width: 20,
      height: 20,
      left: -23,
      transform: "rotate(45deg)",
      top: -33,
      zIndex: "1",
    },
  },
  btnStack: {},
  soldOutBtn: {},
  browseAssetsBtn: {},
};

export type ClassesNames = keyof typeof commonStyles;
