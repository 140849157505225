import React, {ChangeEvent, useEffect, useState} from "react";
import { Box, TextField, Typography } from "@mui/material";
import ReactGA from "react-ga";

import { useStyles } from "./styles";
import {PhoneInput} from "../../../../../../components/simpleComponents/PhoneInput";

interface PhoneNumberFormProps {
  setProfilePhoneNumber(value: string): void;
  initPhoneNumber: string;
}

const PhoneNumberForm: React.FC<PhoneNumberFormProps> = function ({
  setProfilePhoneNumber,
  initPhoneNumber,
}): JSX.Element {
  const [phoneNumber, setPhoneNumber] = useState(
    initPhoneNumber ? initPhoneNumber.replaceAll("-", "") : ""
  );
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/PhoneNumberForm");
  }, []);

  const isPhoneNumberValid = (value: string): boolean => {
    if (value.match(/[^\d-()+\b\s]/)) {
      setErrorText('Only digit and "()-" allowed.');
      return false;
    }
    // if (
    //   (value.length > 0 && value.replaceAll(/[-()+\b\s]/g, "").length < 10) ||
    //   value.replaceAll(/[-()+\b\s]/g, "").length > 10
    // ) {
    //   setErrorText("Should be 10 digits long including area code.");
    //   return false;
    // }

    setErrorText("");
    return true;
  };

  const getInputHandler =
      (key: string) =>
          (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            if (key === "phone") {
              isPhoneNumberValid(value);
              const currentValue = value
                  .replaceAll(/[()-\s]/gi, "")
                  .split("")
                  .reduce((acc, curr, index) => {
                    if (index !== 2 && index !== 5) {
                      return acc + curr;
                    }
                    return acc + curr + "-";
                  }, "");

              setProfilePhoneNumber(value);
              setPhoneNumber(currentValue);
              return;
            }

            setProfilePhoneNumber(value);
            setPhoneNumber(value);
          };

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <PhoneInput
          className={classes.input}
          value={phoneNumber}
          onChange={getInputHandler("phone")}
      />
    </Box>
  );
};

export default PhoneNumberForm;
