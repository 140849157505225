import { useState } from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";

import { ImageModal } from "../ImageModal/ImageModal";

import { settings, sliderData } from "./constants";
import { classes } from "./styles";

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const nextImage = () => {
    if (selectedImage !== null) {
      if (selectedImage === sliderData.length - 1) {
        setSelectedImage(0);
      } else {
        setSelectedImage(selectedImage + 1);
      }
    }
  };

  const prevImage = () => {
    if (selectedImage !== null) {
      if (selectedImage === 0) {
        setSelectedImage(sliderData.length - 1);
      } else {
        setSelectedImage(selectedImage - 1);
      }
    }
  };

  return (
    <div style={classes.wrapper()}>
      <div style={classes.container()}>
        <Slider {...settings}>
          {sliderData.map((item, index) => (
            <Box
              component={"img"}
              src={item}
              key={index}
              sx={classes.sliderImage}
              onClick={() => {
                setSelectedImage(index);
              }}
            />
          ))}
        </Slider>
        {selectedImage !== null && (
          <ImageModal
            image={sliderData[selectedImage]}
            nextImage={nextImage}
            prevImage={prevImage}
            setVisible={() => setSelectedImage(null)}
          />
        )}
      </div>
    </div>
  );
};

export default Gallery;
