import CheckBox from "components/simpleComponents/CheckBox/CheckBox";
import { ATTESTATIONS_OPTIONS } from "./constants";
import { CustomButton } from "components/UIComponents/Button";

import { STEPS } from "../../AccreditationForm.data";

import styles from "./AttestationsForm.module.scss";

type AttestationsFormProps = {
  setStep: (step: STEPS) => void;
  handleChange: (event: React.SyntheticEvent, checked: boolean) => void;
  isShowError: boolean;
  handleSubmit: () => void;
};

const AttestationsForm = ({
  setStep,
  handleChange,
  isShowError,
  handleSubmit,
}: AttestationsFormProps) => {
  return (
    <>
      <form>
        <ul className={styles.list}>
          {ATTESTATIONS_OPTIONS.map((option) => (
            <li key={option.id}>
              <CheckBox.White
                label={option.label}
                handleChange={handleChange}
                props={option.props}
                isCheckboxOnTop
              />
            </li>
          ))}
        </ul>
        {isShowError && (
          <p className={styles.error}>Please select at least one option</p>
        )}
        <div className={styles.buttonContainer}>
          <CustomButton
            btnStyle="secondaryBlack"
            onClick={() => {
              setStep(STEPS.SSN_FORM);
            }}
          >
            BACK
          </CustomButton>
          <CustomButton
            disabled={isShowError}
            btnStyle="secondaryBlack"
            onClick={handleSubmit}
          >
            SUBMIT
          </CustomButton>
        </div>
      </form>
    </>
  );
};

export default AttestationsForm;
