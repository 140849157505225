import Dropdown from "components/simpleComponents/Dropdown/DropDown";

import styles from "./NewPortfolioHeader.module.scss";

import { SelectOption } from "./types";

const NewPortfolioHeader = () => {
  const handleChange = (option: SelectOption) => {};

  // TODO: Finish this component.

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Your Portfolio</h1>
      {/* <p className={styles.text}>
        Below is a summary of your shares purchased as
      </p>
      <Dropdown
        options={[{ label: "INDIVIDUAL", value: "INDIVIDUAL" }]}
        value={{ label: "INDIVIDUAL", value: "INDIVIDUAL" }}
        onChange={handleChange}
        arrowColor="black"
        borderColor="black"
      /> */}
    </div>
  );
};

export default NewPortfolioHeader;
