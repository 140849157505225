export const partners = [
  '/images/partners/igi.png',
  '/images/partners/jpmc.png',
  // '/images/partners/fred-leighton.png',
  // '/images/partners/kwiat.png',
  '/images/partners/dalmore.png',
  '/images/partners/northcapital.png',
  '/images/partners/crowdcheck.png',
  '/images/partners/akin-gump.png',
  // '/images/partners/quintessentially.png',
];

export const advisors = [
  '/images/advisors/jp-morgan-chase.png',
  '/images/advisors/lazard.png',
  // '/images/advisors/ubs.png',
  // '/images/advisors/conde-nast-hearst.png',
  '/images/advisors/goldman sachs.png',
  '/images/advisors/wealth-x.png',
  '/images/advisors/riot-games.png',
  // '/images/advisors/hermes.png',
  '/images/advisors/caa.png',
];
