export const LEFT_COLUMN_DETAILS = [
  {
    display: 'front,details',
    label: 'MARKET CAPITALIZATION',
    sort: '01',
    type: 'text',
    value: '$60,000',
  },
  {
    display: 'front,details',
    label: `MINIMUM INVESTMENT`,
    sort: '02',
    type: 'text',
    value: '$1,000',
  },
  {
    display: 'front,details',
    label: 'Groomed at',
    sort: '03',
    type: 'text',
    value: "Lane's End",
  },
];

export const RIGHT_COLUMN_DETAILS = [
  {
    display: 'front,details',
    label: 'TRAINER',
    sort: '04',
    type: 'text',
    value: 'John W.Sadler',
  },
  {
    display: 'front,details',
    label: 'Total Winnings',
    sort: '05',
    type: 'text',
    value: '$512,277',
  },
  {
    display: 'front,details',
    label: '2024 Winnings',
    sort: '06',
    type: 'text',
    value: '$266,500',
  },
];
