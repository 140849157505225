import { FC } from "react";
import { FormControlLabel } from "@mui/material";

import { BlackStyledCheckbox, WhiteStyledCheckbox } from "./styledComponents";
import { CheckBoxProps } from "./CheckBox.type";
import styles from "./CheckBox.module.scss";

const BlackCheckbox: FC<CheckBoxProps> = ({
  label,
  handleChange,
  isCheckboxOnTop = false,
  backgroundColor = "#fff",
  ...props
}) => {
  return (
    <div style={{ backgroundColor: backgroundColor, padding: 3 }}>
      <FormControlLabel
        control={<BlackStyledCheckbox {...props} />}
        label={label}
        style={
          isCheckboxOnTop
            ? { color: "#000", display: "flex", alignItems: "flex-start" }
            : { color: "#000" }
        }
        onChange={handleChange}
        value={props.props?.value}
        name={props.props?.name}
        className={styles.label}
      />
    </div>
  );
};

const WhiteCheckbox: FC<CheckBoxProps> = ({
  label,
  handleChange,
  isCheckboxOnTop = false,
  backgroundColor = "#000",
  ...props
}) => {
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        padding: 3,
      }}
    >
      <FormControlLabel
        control={<WhiteStyledCheckbox {...props} />}
        label={label}
        style={
          isCheckboxOnTop
            ? {
                color: "#fff",
                display: "flex",
                alignItems: "flex-start",
              }
            : { color: "#fff" }
        }
        onChange={handleChange}
        value={props.props?.value}
        name={props.props?.name}
        className={styles.label}
      />
    </div>
  );
};

const CheckBox = {
  Black: BlackCheckbox,
  White: WhiteCheckbox,
};

export default CheckBox;
