export const LEFT_COLUMN_DETAILS = [
  {
    display: "front,details",
    label: "Luxury Brand",
    sort: "01",
    type: "text",
    value: "GARRARD",
  },
  {
    display: "front,details",
    label: "MARKET CAPITALIZATION",
    sort: "02",
    type: "text",
    value: "$3,000,000",
  },
  {
    display: "front,details",
    label: `MINIMUM INVESTMENT AMOUNT`,
    sort: "03",
    type: "text",
    value: "$5,000",
  },
];

export const RIGHT_COLUMN_DETAILS = [
  {
    display: "front,details",
    label: "PRICE PER SHARE",
    sort: "04",
    type: "text",
    value: "$2,500",
  },
  {
    display: "front,details",
    label: "Management fee",
    sort: "05",
    type: "text",
    value: "2.5% PER ANNUM",
  },
  {
    display: "front,details",
    label: "Performance Fee",
    sort: "06",
    type: "text",
    value: "20% OF NET PROFITS SUBJECT TO AN 8% HURDLE",
  },
];

export const IMAGE_COMMENT =
  "*The above sketch is a representation of The Tiara but does not reflect the actual design. The Tiara is currently in production and will be unveiled at the Tower of London later in 2024.";
