import { ClassNameMap } from "@mui/styles";
import useGetDisplayType from "../../../../../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../../../../../types/enums/DisplayTypes";
import getClassNameMap from "../../../../../../../utils/getClassNameMap";

import type { ClassesNames } from "./common";
import { useDesktopStyles } from "./desktop";
import { useMobileStyles } from "./mobile";
import { useTabletLandscapeStyles } from "./tabletLandscape";
import { useTabletPortraitStyles } from "./tabletPortrait";

export type Classes = ClassNameMap<ClassesNames>;

export const useStyles = (): Classes => {
    return getClassNameMap(
        useGetDisplayType(),
        useDesktopStyles(),
        useMobileStyles(),
        useTabletLandscapeStyles(),
        useTabletPortraitStyles()
    );
};
