import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { AssetInfoProps } from "./AssetInfo";
import { accountStatus } from "../../../../../utils";
import { IntentTradeType } from "../../../../../types/Trade";

import useGetDisplayType from "../../../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../../../types/enums/DisplayTypes";

export default function useAssetInfoFacade({
  offer,
}: AssetInfoProps): [
  string,
  number,
  string,
  string,
  React.MutableRefObject<HTMLDivElement | null>,
  React.MutableRefObject<HTMLDivElement | null>,
  HTMLImageElement | null,
  boolean,
  (event: React.MouseEvent) => void,
  () => void,
  boolean,
  boolean
] {
  const displayType = useGetDisplayType();
  const desktop = !useGetDisplayType(DisplayTypes.Mobile);

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const popoverContainerRef = useRef<HTMLDivElement>(null);

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLImageElement | null>(
    null
  );
  const [isBuyButtonDisabled, setIsBuyButtonDisabled] = useState(false);

  const open = Boolean(popoverAnchor);

  const onImageMouseEnter = (event: React.MouseEvent) =>
    setPopoverAnchor(event.currentTarget as HTMLImageElement);
  const onImageMouseLeave = () => setPopoverAnchor(null);

  useEffect(() => {
    if (offer)
      setIsBuyButtonDisabled(
        !accountStatus().isApproved || offer.status === IntentTradeType
      );
  }, [offer]);

  const popoverDisplayTopDiff: Record<DisplayTypes, number> = {
    desktop: -10,
    mobile: 15,
    tabletLandsape: 0,
    tabletPortrait: -95,
  };

  const popoverDisplayLeftDiff: Record<DisplayTypes, number> = {
    desktop: 20,
    mobile: 15,
    tabletLandsape: 5,
    tabletPortrait: -85,
  };

  const diffs = {
    left: popoverDisplayLeftDiff[displayType],
    top: popoverDisplayTopDiff[displayType],
  };

  useEffect(() => {
    setTimeout(() => {
      if (popoverRef.current?.style.top && Boolean(popoverAnchor)) {
        const newLeft =
          Number(popoverRef.current?.style.left.replace("px", "")) + diffs.left;
        const newTop =
          Number(popoverRef.current?.style.top.replace("px", "")) - diffs.top;
        popoverRef.current.style.left = `${newLeft}px`;
        popoverRef.current.style.top = `${newTop}px`;
      }
    }, 0);
  }, [popoverAnchor]);

  return [
    offer?.name,
    offer?.minFundedPct as number,
    offer?.offering?.targetAmount as string,
    offer?.offering?.unitPrice as string,
    popoverRef,
    popoverContainerRef,
    popoverAnchor,
    open,
    onImageMouseEnter,
    onImageMouseLeave,
    desktop,
    isBuyButtonDisabled,
  ];
}
