import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          margin: "0 auto 0",
          width: "695px",
        },
          textBtn: {
              color: themeData.WhiteColor,
              backgroundColor: "#6890FF",
              fontSize: "16px",
              fontWeight: "300",
              marginBottom: "10px",
              "&:hover": {
                  backgroundColor: "#516fc1",
              },
          },
          legalEntityBtn: {
              fontSize: "16px",
              fontWeight: "300",
              marginBottom: "10px",
              marginRight: "10px"
          },
      })
    )
);
