export const INITIAL_FORM_STATE = {
  accountHolderName: "",
  bankName: "",
  accountNumber: "",
  routingNumber: "",
  accountType: "",
};

export const ACCOUNT_TYPES = [
  {
    label: "Checking",
    value: "Checking",
  },
  {
    label: "Savings",
    value: "Savings",
  },
];

export const INITIAL_ERROR_FORM_STATE = {
  accountHolderName: "",
  bankName: "",
  accountNumber: "",
  routingNumber: "",
  accountType: "",
};

export enum FIELD_NAMES {
  ACCOUNT_HOLDER_NAME = "accountHolderName",
  BANK_NAME = "bankName",
  ACCOUNT_NUMBER = "accountNumber",
  ROUTING_NUMBER = "routingNumber",
  ACCOUNT_TYPE = "accountType",
}
