import React, { useState, useEffect, useRef } from "react";
import { Box, TextField, Typography, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { themeData } from "../../../../../../utils/theme";
import ReactGA from "react-ga";

import { useStyles } from "./styles";

interface SSNParts {
  first: string;
  second: string;
  third: string;
}

interface SocialSecurityNumberFormProps {
  setProfileSocialSecurityNumber(value: string): void;
  initSsn: string;
}

const SocialSecurityNumberForm: React.FC<SocialSecurityNumberFormProps> =
  function ({ setProfileSocialSecurityNumber, initSsn }): JSX.Element {
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + "/SocialSecurityNumberForm");
    }, []);

    const initFirstPart = initSsn.split("").slice(0, 3).join("");
    const initSecondPart = initSsn.split("").slice(3, 5).join("");
    const initThirdPart = initSsn.split("").splice(5, 10).join("");

    const [prevSsnParts, setPrevSsnParts] = useState<SSNParts>({
      first: initFirstPart ?? "",
      second: initSecondPart ?? "",
      third: initThirdPart ?? "",
    });
    const [ssnParts, setSSNParts] = useState<SSNParts>({
      first: initFirstPart ?? "",
      second: initSecondPart ?? "",
      third: initThirdPart ?? "",
    });
    const [errorText, setErrorText] = useState("");
    const [isShowSsn, setIsShowSsn] = useState(false);

    const secondInputRef = useRef<HTMLInputElement>();
    const thirdInputRef = useRef<HTMLInputElement>();

    const validateSSN = (): boolean => {
      const { first, second, third } = ssnParts;
      const ssn = (first + second + third).length;
      if (ssn === 0) return false;
      const result = ssn > 8;
      if (!result) setErrorText("Valid Social Security number is required");
      else setErrorText("");
      return result;
    };

    const getOnInputChange =
      (type: keyof SSNParts) =>
      (event: React.ChangeEvent): void =>
        setSSNParts({
          ...ssnParts,
          [type]: (event.target as HTMLInputElement).value,
        });

    useEffect(() => {
      const { first, second, third } = ssnParts;
      if (prevSsnParts.first !== first && first.length === 3)
        secondInputRef.current?.focus();
      if (prevSsnParts.second !== second && second.length === 2)
        thirdInputRef.current?.focus();
      if (
        validateSSN() &&
        first.length === 3 &&
        second.length === 2 &&
        third.length === 4
      )
        setProfileSocialSecurityNumber(first + second + third);
      else setProfileSocialSecurityNumber("");
      setPrevSsnParts(ssnParts);
    }, [ssnParts]);

    const classes = useStyles();

    const DashComponent: React.FC = function (): JSX.Element {
      return <div className={classes.dash} />;
    };

    return (
      <>
        <Box className={classes.container}>
          <TextField
            placeholder="***"
            type={isShowSsn ? "text" : "password"}
            variant="outlined"
            className={classes.firstField}
            value={ssnParts.first}
            onChange={getOnInputChange("first")}
            inputProps={{
              maxLength: "3",
            }}
          />
          <DashComponent />
          <TextField
            placeholder="**"
            type={isShowSsn ? "text" : "password"}
            variant="outlined"
            className={classes.secondField}
            value={ssnParts.second}
            onChange={getOnInputChange("second")}
            inputProps={{
              maxLength: "2",
              ref: secondInputRef,
            }}
          />
          <DashComponent />
          <TextField
            placeholder="****"
            type={isShowSsn ? "text" : "password"}
            variant="outlined"
            className={classes.thirdField}
            value={ssnParts.third}
            onChange={getOnInputChange("third")}
            inputProps={{
              maxLength: "4",
              ref: thirdInputRef,
            }}
          />
          <IconButton
            className={classes.iconBtn}
            onClick={() => setIsShowSsn(!isShowSsn)}
          >
            {isShowSsn ? (
              <Visibility sx={{ color: themeData.BlackColor }} />
            ) : (
              <VisibilityOff sx={{ color: themeData.BlackColor }} />
            )}
          </IconButton>
        </Box>
        {errorText !== "" && (
          <Typography className={classes.errorText}>{errorText}</Typography>
        )}
      </>
    );
  };

export default SocialSecurityNumberForm;
