import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        margin: "19px 89px",
      },
      image: {
        height: "250px",
        width: "250px",
        marginBottom: "24px",
      },
      offerName: {
        fontSize: "30px",
        fontWeight: "450",
        marginBottom: "5px",
        whiteSpace: "nowrap",
      },
      text: {
        fontSize: "18px",
        whiteSpace: "nowrap",
      },
    })
  )
);
