import React from 'react';

// inspired from https://stackoverflow.com/questions/52324664/how-to-use-iframe-in-react-js

type IframeProps = {
  source: string;
  title: string;
  className?: string;
  style?: React.CSSProperties;
};
const Iframe = ({ source, title, style, className }: IframeProps) => {
  if (!source) {
    return <div>Loading...</div>;
  }
  const newStyle = {
    flexGrow: 1,
    border: 'none',
    margin: 20,
    padding: 0,
    ...style,
  };
  console.debug(`New SX`, newStyle);

  const src = source;
  return <iframe title={title} src={src} style={newStyle}></iframe>;
};

export default Iframe;
