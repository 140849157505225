import { CssBaseline, Typography, useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useServerAPI } from '../../apis';
import { ErrorMessage } from '../../components/ErrorMessage';
import { BankAccountData, CreditCardData } from '../../types/Account';
import { PaymentType } from '../../types/enums/PaymentType';
import { LocationState } from '../../types/LocationState';
import { PurchaseDetail, PurchaseSummary } from '../../types/PurchaseDetail';
import {
  DefaultSystemConfig,
  InstructionType,
  SystemConfigType,
} from '../../types/SystemConfig';
import { Trade } from '../../types/Trade';
import { useWindowDimensions } from '../../utils';
import { themeData } from '../../utils/theme';
import { AssetPurchaseDetails } from './components/AssetPurchaseDetails';
import { AssetReviewDetails } from './components/AssetReviewDetails';
import { CancelButton } from './components/CancelButton';
import { ConfirmLeave } from './components/ConfirmLeave';
import { ContinueButton } from './components/ContinueButton';
import { PaymentMethodTile } from './components/PaymentMethodTile';
import PaymentSelector from './components/PaymentSelector';
import PurchaseAgreementCheckbox from './components/PurchaseAgreementCheckbox';
import PurchaseInstructions from './components/PurchaseInstructions';
import QuantityPurchaseSelector from './components/QuantityPurchaseSelector';
import TermOfUseCheckbox from './components/TermOfUseCheckbox';
import { WirePaymentMethodTile } from './components/WirePaymentMethodTile';
import ReactGA from 'react-ga';
import { Preloader } from '../../components/simpleComponents/Preloader';

export const BuyShares = () => {
  const desktop = useMediaQuery('(min-width:900px)');
  const { height } = useWindowDimensions();

  let navigate = useNavigate();
  let location = useLocation();
  let api = useServerAPI();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + '/BuyShares');
  }, []);

  let { offering, trade } = location.state as LocationState;
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState<string | undefined>();
  const [purchaseQte, setPurchaseQte] = useState(
    parseInt(trade?.totalShares ?? '1')
  );
  const [currentOffering, setCurrentOffering] = useState(offering);
  const [paymentChoices, setPaymentChoices] = useState<{
    [type: string]: React.ReactFragment;
  }>();
  const [paymentMethodShortIds, setPaymentMethodShortIds] = useState<{
    [type: string]: string;
  }>({});
  const [paymentMethod, setPaymentMethod] = useState<PaymentType>();
  const [readyToBuy, setReadyToBuy] = useState(false);
  const [purchaseSummary, setPurchaseSummary] = useState<
    PurchaseSummary | undefined
  >();
  const [cancelModelView, setCancelModelView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsOfUseAgreed, setTermsOfUseAgreed] = useState(false);
  const [usePurchaseAgreeCheckBox, setUsePurchaseAgreeCheckBox] =
    useState(false);
  const [purchaseAgreementAgreed, setPurchaseAgreementAgreed] = useState(false);
  const [purchaseAgreementUrl, setPurchaseAgreementUrl] = useState('');
  const [purchaseAgreementName, setPurchaseAgreementName] = useState('');
  const [instructions, setInstructions] = useState<InstructionType>();

  const sysConfig = JSON.parse(
    localStorage.getItem('SystemConfig') ?? JSON.stringify(DefaultSystemConfig)
  ) as SystemConfigType;

  const onQuantityChanged = (q: number) => {
    setPurchaseQte(q);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + '/BuyShares');
  }, []);

  useEffect(() => {
    if (!currentOffering && !trade) {
      console.warn(`No offering selected returning to landing page`);
      navigate('/');
    } else {
      const purchaseAgreementDoc =
        currentOffering?.assets?.filter(
          (a) => a.type === 'purchaseagreement'
        ) ?? [];
      const usePchAgree = purchaseAgreementDoc.length > 0; // && !sysConfig.purchaseUseDocusign;
      setUsePurchaseAgreeCheckBox(usePchAgree);
      if (!usePchAgree) {
        setPurchaseAgreementAgreed(true);
      } else {
        setPurchaseAgreementUrl(purchaseAgreementDoc[0].url);
        setPurchaseAgreementName(purchaseAgreementDoc[0].label);
      }
    }
  }, [currentOffering]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeStep]);

  useEffect(() => {
    if (!loading && currentOffering && !paymentChoices) {
      fetchPaymentsData();
    }
  }, [loading, paymentChoices, currentOffering]);

  useEffect(() => {
    if (!offering && trade) {
      fetchTradeData(trade);
    }
  }, [trade]);

  useEffect(() => {
    if (!loading && !instructions) {
      api
        .getInstructions()
        .then((instructionsData) => {
          setInstructions(instructionsData);
        })
        .catch((e) => {
          console.error(`Could not retrived instructions `, e);
        });
    }
  }, [loading, instructions]);

  const fetchTradeData = async (trade: Trade) => {
    setLoading(true);
    api.getOffering(trade.offeringId).then((resp) => {
      if (resp.status === 200 && resp.data.response) {
        setCurrentOffering(resp.data.response);
        setLoading(false);
      } else {
        navigate('/');
      }
    });
  };
  const fetchPaymentsData = async () => {
    try {
      let payments: { [type: string]: React.ReactFragment } = {};
      let shortIds: { [type: string]: string } = {};
      const wirePayementConfig = await api.getWireDetails();
      if (wirePayementConfig && wirePayementConfig.active) {
        shortIds[PaymentType.WIRE] = '';
        payments[PaymentType.WIRE] = (
          <WirePaymentMethodTile
            details={wirePayementConfig}
            key={PaymentType.WIRE}
            offering={currentOffering}
            investorName={localStorage.getItem('userName') ?? 'Investor Name'}
          />
        );
      }
      const paymentResponse = await api.getRegisteredPayments();
      if (
        paymentResponse.data.response.data.bankAccount &&
        !paymentResponse.data.response.data.bankAccount.statusCode
      ) {
        let p = paymentResponse.data.response.data
          .bankAccount as BankAccountData;
        shortIds[PaymentType.BANK] = p.AccountNumber.slice(-4);
        payments[PaymentType.BANK] = (
          <PaymentMethodTile
            paymentMethod={{ type: PaymentType.BANK, ...p }}
            key={PaymentType.BANK}
            onPaymentChoicesUpdated={fetchPaymentsData}
          />
        );
      }
      if (
        paymentResponse.data.response.data.creditCard &&
        !paymentResponse.data.response.data.creditCard.statusCode
      ) {
        let p = paymentResponse.data.response.data.creditCard as CreditCardData;
        shortIds[PaymentType.CARD] = p.creditCardNumber.slice(-4);
        payments[PaymentType.CARD] = (
          <PaymentMethodTile
            paymentMethod={{ type: PaymentType.CARD, ...p }}
            key={PaymentType.CARD}
            onPaymentChoicesUpdated={fetchPaymentsData}
          />
        );
      }

      setPaymentChoices(payments);
      setPaymentMethodShortIds(shortIds);
    } catch (error: any) {
      console.error('err:myAccount:getRegisteredPayments:catch', error);
      setError(error.response?.data?.error);
    }
  };

  const onPaymentChoiceChanged = (p: PaymentType) => {
    setPaymentMethod(p);
  };

  const onAgreedChanged = (value: boolean) => {
    setReadyToBuy(value && !!paymentMethod);
  };

  const performPurchase = async (): Promise<PurchaseSummary | string> => {
    if (paymentMethod && currentOffering) {
      const purchase: PurchaseDetail = {
        offeringId: parseInt(currentOffering.offeringId),
        transactionUnits: purchaseQte,
        description: currentOffering.name,
        transactionType: paymentMethod ?? PaymentType.BANK,
        tradeId: trade ? trade.orderId : undefined,
      };

      const res = await api.makePurchase(purchase);
      if (res.data.success) {
        if (res.data.response.tradeResponse?.error) {
          const err =
            res.data.response.tradeResponse.error['Error(s)'] ??
            res.data.response.tradeResponse.error.statusDesc;
          return err;
        } else {
          return {
            fundStatus: 'Registered',
            totalAmount: res.data.response.tradeResponse.transactionAmount,
            transactionDate: res.data.response.tradeResponse.transactionDate,
            transactionType: paymentMethod,
            transactionUnits: purchaseQte,
          };
        }
      }
      console.error('Purchase failed', res);
      return 'Purchase failed';
    } else {
      return 'No payment method selected';
    }
  };

  // =======================================================
  // Steps
  // =======================================================

  const stepOrder = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: desktop ? 'row' : 'column',
        }}
      >
        <Box sx={{ marginBottom: '17px', marginRight: '0px' }}>
          <AssetPurchaseDetails currentOffering={currentOffering} largeImg />
        </Box>
        <Box>
          {instructions && (
            <PurchaseInstructions
              text={instructions['buy_stepOrder_text']}
              sx={{ marginLeft: '20px' }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{}}>
          <QuantityPurchaseSelector
            value={purchaseQte}
            currentOffering={currentOffering}
            onValueChange={onQuantityChanged}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '135px',
            marginTop: '17px',
            justifyContent: 'space-between',
          }}
        >
          <ContinueButton
            title="Select Payment"
            disabled={purchaseQte < 1}
            onClick={() => {
              setError(undefined);
              setActiveStep(1);
            }}
            sx={{
              width: { md: '318.5px', xs: '50%' },
            }}
          />
          <Box sx={{ width: { md: 0, xs: '17px' }, height: '1px' }} />
          <CancelButton
            title="Cancel"
            onClick={() => {
              setError(undefined);
              setCancelModelView(true);
            }}
            sx={{
              width: { md: '318.5px', xs: '50%' },
            }}
          />
        </Box>
      </Box>
    </Box>
  );

  const stepSelectPayment = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: desktop ? 'row' : 'column',
        }}
      >
        <Box sx={{ marginBottom: '17px', marginRight: '0px' }}>
          <AssetReviewDetails
            currentOffering={currentOffering}
            title="Select Payment Method for Your Order"
            subTitle="Order Details"
            message=""
            quantity={purchaseQte}
            // paymentMethod={paymentMethod}
          />
        </Box>
        <Box>
          {instructions && (
            <PurchaseInstructions
              text={instructions['buy_stepSelectPayment_text']}
              sx={{ marginLeft: '20px' }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <PaymentSelector
            paymentChoices={paymentChoices ?? {}}
            onPaymentChoicesUpdated={fetchPaymentsData}
            onPaymentSelectedUpdated={onPaymentChoiceChanged}
            paymentSelected={paymentMethod}
            onAgreementsUpdated={onAgreedChanged}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '135px',
            marginTop: '17px',
            justifyContent: 'space-between',
          }}
        >
          <ContinueButton
            title="Review Order"
            disabled={loading || !readyToBuy}
            onClick={() => {
              setError(undefined);
              setActiveStep(2);
            }}
            sx={{ width: '318.5px' }}
          />
          <Box sx={{ width: { md: 0, xs: '17px' }, height: '1px' }} />
          <CancelButton
            title="Back"
            onClick={() => {
              setError(undefined);
              setActiveStep(activeStep - 1);
            }}
            sx={{ width: '318.5px' }}
          />
        </Box>
      </Box>
    </Box>
  );

  const agreeBoxes = (
    <>
      <TermOfUseCheckbox onChange={(value) => setTermsOfUseAgreed(value)} />
      {/* {usePurchaseAgreeCheckBox && (
        <PurchaseAgreementCheckbox
          documentUrl={purchaseAgreementUrl}
          documentName={purchaseAgreementName}
          onChange={(value) => setPurchaseAgreementAgreed(value)}
        />
      )} */}
    </>
  );
  const stepVerify = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: desktop ? 'row' : 'column',
        }}
      >
        <Box sx={{ marginRight: '0px' }}>
          {loading ? (
            <Preloader sx={{ width: { md: '469px', xs: '100%' } }} />
          ) : (
            <AssetReviewDetails
              currentOffering={currentOffering}
              title="Review Your Purchase"
              // message='Agreeing to the above will proceed with the purchase.'
              message={agreeBoxes}
              // message={<TermOfUseCheckbox onChange={(value=>setTermsOfUseAgreed(value))}/>}
              quantity={purchaseQte}
              paymentMethod={paymentMethod}
              paymentShortId={paymentMethodShortIds[paymentMethod ?? '']}
            />
          )}
          {/* <TermOfUseCheckbox /> */}
        </Box>
        <Box>
          {instructions && (
            <PurchaseInstructions
              text={instructions['buy_stepVerify_text']}
              sx={{ marginLeft: '20px' }}
            />
          )}
        </Box>
      </Box>
      {!loading && usePurchaseAgreeCheckBox && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              paddingX: '28px',
              paddingY: '18px',
              // height: '135px',
              marginTop: '17px',
              justifyContent: 'space-between',
              backgroundColor: themeData.WhiteColor,
            }}
          >
            <PurchaseAgreementCheckbox
              documentUrl={purchaseAgreementUrl}
              documentName={purchaseAgreementName}
              onChange={(value) => setPurchaseAgreementAgreed(value)}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '135px',
            marginTop: '17px',
            justifyContent: 'space-between',
          }}
        >
          <ContinueButton
            title="Submit Order"
            disabled={loading || !termsOfUseAgreed || !purchaseAgreementAgreed}
            onClick={() => {
              setError(undefined);
              setLoading(true);
              performPurchase().then((summ) => {
                if (typeof summ === 'string') {
                  setError(summ);
                  setLoading(false);
                } else {
                  const summary = summ as PurchaseSummary;
                  setPurchaseSummary(summary);
                  setActiveStep(3);
                  setLoading(false);
                }
              });
            }}
            sx={{
              width: { md: '318.5px', xs: '50%' },
            }}
          />
          <Box sx={{ width: { md: 0, xs: '17px' }, height: '1px' }} />
          <CancelButton
            title="Back"
            disabled={loading}
            onClick={() => {
              setError(undefined);
              setTermsOfUseAgreed(false); // reset if going back
              setActiveStep(activeStep - 1);
            }}
            sx={{
              width: { md: '318.5px', xs: '50%' },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '135px',
            justifyContent: 'space-between',
          }}
        ></Box>
      </Box>
    </Box>
  );

  const stepComplete = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ marginBottom: '17px', marginRight: '20px' }}>
          {loading ? (
            <Preloader sx={{ width: '469px' }} />
          ) : (
            <AssetReviewDetails
              currentOffering={currentOffering}
              title="Congrats! Your purchase is confirmed."
              message=""
              quantity={purchaseQte}
              isIntent
            />
          )}
        </Box>
        {instructions && (
          <PurchaseInstructions
            text={instructions['buy_stepComplete_text']}
            sx={{ marginLeft: '20px' }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          // alignContent: 'space-between'
        }}
      >
        <Box
          sx={{
            marginRight: '20px',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <ContinueButton
            title="View in portfolio"
            onClick={() => navigate('/portfolio')}
            disabled={loading}
            sx={{ width: '669px', height: '61px', marginRight: '20px' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '135px',
            justifyContent: 'space-between',
          }}
        ></Box>
      </Box>

      {/* <ErrorMessage message={error} /> */}
    </Box>
  );

  // =======================================================
  // Page
  // =======================================================

  return (
    <Box>
      <Box
        sx={{
          minHeight: `${themeData.minPageHeight(height)}px`,
          backgroundColor: themeData.LightBackgroundColor,
        }}
      >
        <Container
          sx={{
            paddingTop: '2rem',
            paddingBottom: '15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <CssBaseline />
          <Box sx={{ width: { md: '669px', xs: '95%' } }}>
            <Typography
              align="center"
              sx={{
                color: themeData.BlackColor,
                fontSize: '50px',
                textAlign: 'left',
                fontFamily: themeData.PrimaryFont,
              }}
            >
              Purchase Shares
            </Typography>
          </Box>
          {loading ? (
            <Preloader />
          ) : (
            <Box sx={{ width: { md: '669px', xs: '95%' } }}>
              {activeStep === 0 && stepOrder}
              {activeStep === 1 && stepSelectPayment}
              {activeStep === 2 && stepVerify}
              {activeStep === 3 && stepComplete}
              <ErrorMessage message={error} sx={{ fontSize: '20px' }} />
            </Box>
          )}
        </Container>
      </Box>
      <ConfirmLeave
        show={cancelModelView}
        onClose={() => setCancelModelView(false)}
        onLeave={() => navigate(-1)}
        onStay={() => setCancelModelView(false)}
        prompt="You haven’t completed your purchase yet, are you sure you want to leave?"
      />
    </Box>
  );
};

export default BuyShares;
