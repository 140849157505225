import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "85%",
    },
    countryField: {
        width: "314px",
        height: "43px",
        paddingBottom: "15px",
    },
};

export type ClassesNames = keyof typeof commonStyles;