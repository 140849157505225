import TreeLineSmall from "assets/icons/TreeLineSmall";

import styles from "./FamilyNodeSmall.module.scss";

type FamilyNodeProps = {
  firstName: string;
  secondName: string;
  firstImage: string;
  secondImage: string;
  topName?: string;
  topImage?: string;
  gap?: string;
  width?: string;
};

const FamilyNodeSmall = ({
  firstName,
  firstImage,
  secondImage,
  secondName,
  topImage = "",
  topName = "",
  gap = "50px",
  width,
}: FamilyNodeProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.arrowContainer} style={{ marginTop: "-30px" }}>
        <div className={styles.nodeContainerTop}>
          {!!topImage && (
            <>
              <img className={styles.image} src={topImage} alt={topName} />
              <p className={styles.text}>{topName}</p>
            </>
          )}
        </div>
        <TreeLineSmall width={width} />
      </div>
      <div className={styles.nodesContainer} style={{ gap }}>
        <div className={styles.nodeContainer}>
          <img className={styles.image} src={firstImage} alt={firstName} />
          <p className={styles.text}>{firstName}</p>
        </div>
        <div className={styles.nodeContainer}>
          <img className={styles.image} src={secondImage} alt={secondName} />
          <p className={styles.text}>{secondName}</p>
        </div>
      </div>
    </div>
  );
};

export default FamilyNodeSmall;
