import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import ReactGA from "react-ga";

import { useServerAPI } from "../../../apis";

import useGetDisplayType from "../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../types/enums/DisplayTypes";

import ReplaceOfferImage from "../../../utils/ReplaceOfferImage";

export default function useSuccessfullBuyFacade(): [
  string,
  string,
  boolean,
  () => void,
  boolean
] {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/SuccessfullBuy");
  }, []);

  const id = useParams().id;
  const api = useServerAPI();
  const navigate = useNavigate();
  const isDesktop = !useGetDisplayType(DisplayTypes.Mobile);

  const [imageSrc, setImageSrc] = useState("");
  const [offerName, setOfferName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/SuccessfullBuy");
  }, []);

  useEffect(() => {
    setIsLoading(true);
    api
      .getOffering(id as string)
      .then((response) => {
        if (response.data.response.image.url) {
          const proceedOffer = ReplaceOfferImage([response.data.response])[0];
          setImageSrc(proceedOffer.image.url);
          setOfferName(proceedOffer.name);
        }
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onButtonClick = () => navigate("/");

  return [imageSrc, offerName, isLoading, onButtonClick, isDesktop];
}
