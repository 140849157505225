import { Button, SxProps, Theme } from '@mui/material';
import { themeData } from '../../../utils/theme';

type ContinueButtonProps = {
  title: string;
  disabled: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
};

export const ContinueButton = ({
  title,
  disabled = false,
  onClick,
  sx,
}: ContinueButtonProps) => (
  <Button
    type="submit"
    variant="contained"
    fullWidth
    disabled={disabled}
    sx={{
      backgroundColor: themeData.HighlightColor,
      display: 'block',
      width: '367px',
      height: '61px',
      borderRadius: '3px !important',
      fontFamily: themeData.DefaultFontDemi,
      fontWeight: 400,
      '&:disabled': {
        color: themeData.DisabledBackground,
        background: themeData.DisabledColor,
      },
      textTransform: 'uppercase',
      padding: '8px 0',
      fontSize: '22px',
      lineHeight: 1,
      ...sx,
    }}
    onClick={() => {
      if (onClick) onClick();
    }}
  >
    {title}
  </Button>
);

export default ContinueButton;
