import { TButtonType } from '.';

export const getVariant = (view: TButtonType) => {
  switch (view) {
    case 'link':
      return 'text';

    case 'secondary':
      return 'outlined';

    default:
      return 'contained';
  }
};
