export const classes = {
  modalWrapper: (visible: boolean) => ({
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: visible ? 100 : -1,
    opacity: visible ? 1 : 0,
    transition: "all 0.5s ease",
  }),
  modal: (isMobile: boolean, visible: boolean) => ({
    position: "fixed",
    width: isMobile ? "calc(100% - 40px)" : "100%",
    height: isMobile ? "fit-content" : "610px",
    minHeight: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: "20px 20px 0px 0px",
    padding: isMobile ? "20px" : "0px",
    bottom: 0,
    left: 0,
    zIndex: 101,
    transform: visible ? "translateY(0)" : "translateY(100%)",
    transition: "all 0.5s ease",
  }),
  btnContainer: (isMobile: boolean) => ({
    display: "flex",
    justifyContent: isMobile ? "center" : "start",
    margin: "20px 20px 10px 20px",
    width: "calc(100% - 40px)",
  }),
  bodyContainer: (isMobile: boolean) => ({
    display: "flex",
    flexDirection: "column",
    gap: isMobile ? "32px" : "48px",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    maxWidth: "510px",
    height: "100%",
    paddingBottom: "160px",
  }),
  form: (isMobile: boolean) => ({
    width: "100%",
    display: "flex",
    gap: isMobile ? "10px" : "16px",
    flexDirection: "column",
  }),
  row: (isMobile: boolean) => ({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    gap: isMobile ? "10px" : "20px",
  }),
  title: {
    fontSize: "30px",
    fontFamily: "Telugu MN",
    lineHeight: "36.43px",
    letterSpacing: "2px",
    marginBottom: "16px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    lineHeight: "25.64px",
  },
};
