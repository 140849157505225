import { useNavigate } from "react-router-dom";

import { CustomButton } from "components/UIComponents/Button";
import PaymentDetailsLine from "../PaymentDetailsLine/PaymentDetailsLine";
import { AssociatedAccount } from "types/Account";

import styles from "./EntitiesDetails.module.scss";

type EntitiesDetailsProps = {
  entities: AssociatedAccount[];
};

const EntitiesDetails = ({ entities }: EntitiesDetailsProps) => {
  const navigate = useNavigate();

  const handleAddEntityButton = () => navigate("/add-entity");

  return (
    <section className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <h2 className={styles.title}>ENTITIES</h2>
        <div className={styles.buttonWrapper}>
          <CustomButton
            onClick={handleAddEntityButton}
            btnStyle="buttonTransparentWhiteText"
          >
            <span className={styles.buttonText}>ADD ENTITY</span>
          </CustomButton>
        </div>
      </div>
      <div className={styles.paymentDetailsWrapper}>
        {entities.length > 0 && (
          <ul className={styles.list}>
            {entities.map((entity, index) => {
              return (
                <li key={entity.accountId}>
                  <PaymentDetailsLine
                    isFirstItem={index === 0}
                    isLastItem={index === entities.length - 1}
                    isExpanded={false}
                    firstColumn={entity.accountName}
                    secondColumn={entity.entityType}
                    id={entity.accountId}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </section>
  );
};

export default EntitiesDetails;
