import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      tab: {
        fontSize: "23px",
        lineHeight: "46px",
      },
      text: {
        fontSize: "19px",
        lineHeight: "38px",
      },
    })
  )
);
