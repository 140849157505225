import styled from '@emotion/styled';
import themeData from 'utils/theme';
import { ContainerProps, InputProps } from './PhoneDropDown.type';
export const ListContainer = styled.ul<ContainerProps>`
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  z-index: 1000;
  overflow: auto;
  padding: 0;
  margin: 0;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  z-index: ${(props) => (props.isOpen ? '1' : '-1')};
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: ${(props) => (props.isOpen ? '30vh' : '0px')};
  transition: max-height 0.5s ease;
  border-radius: 0px 0px 0px 15px;
  border: 1px solid #7d7d7d;
  border-top: none;
`;
export const Container = styled.div<ContainerProps>`
  border: 1px solid #ccc;
  border-radius: ${(props) => (props.isOpen ? '15px 15px 0px 0px' : '30px')};
  transition: border-radius 0.5s ease;
  display: flex;
  height: 38px;
  position: relative;
  font-size: 14px;
`;

export const Header = styled.div<{ theme: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 9px);
  background-color: ${(props) =>
    props.theme === 'white' ? 'transparent' : themeData.BlackColor};
  border-radius: 50px;
`;

export const Button = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 16px;
  background-color: ${themeData.WhiteColor};
  border-radius: ${(props) => (props.isOpen ? '15px 15px 0px 0px' : '50px')};
  height: 100%;
  width: fit-content;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => (props?.color === 'white' ? '#000' : '#fff')};
`;

export const Input = styled.input<InputProps>`
  margin-left: 5px;
  border: none;
  width: 100%;
  height: 95%;
  outline: none;
  color: ${(props) => (props.theme === 'white' ? '#000' : 'white')};
  font-size: 14px;
  border-radius: ${(props) =>
    props.isOpen ? '0px 15px 0px 0px' : '0px 30px 30px 0px'};
  background-color: transparent;
  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: ${(props) => props.placeholderColor || '#7B7B8F'};
    opacity: 1;
  }
`;
