import { Offering } from '../types/Offerings';
import formatNumber from './FormatNumber';

export default function OfferingNumbersFormatter(offer: Offering): Offering {
  const newOffer = { ...offer };

  if (newOffer.offering?.unitPrice)
    newOffer.offering.unitPrice = formatNumber(
      newOffer.offering?.unitPrice.split('.')[0] as string
    );
  if (newOffer.offering?.totalShares)
    newOffer.offering.targetAmount = formatNumber(
      newOffer.offering.targetAmount.split('.')[0] as string
    );

  return newOffer;
}
