import { themeData } from "../../../../../../utils/theme";

export const commonStyles = {
  title: {
    display: "flex",
    flexDirection: "column",
    marginRight: "30px",
  },
  boldText: {
    fontSize: "25px",
    fontWeight: "600",
  },
  nameTitleStyles: {
    fontSize: "32px",
    fontWeight: "450",
    marginRight: "8px",
  },
  dateOnMarkStyle: {
    color: "#CBCBCB",
    fontSize: "26px",
    marginBottom: "10px",
    width: "fit-content",
  },
  bottomStack: {
    marginTop: "38px",
  },
  reserveShrBtn: {},
  image: {
    height: "62px",
    width: "59px",
  },
  popoverPaper: {
    backgroundColor: "#DFDFE3 !important",
    maxWidth: "340px",
    padding: "15px 10px",
    position: "relative",
    overflow: "visible",
    fontSize: "",
  },
  popoverText: {
    fontSize: "18px",
  },
  popoverTriangle: {
    position: "relative",
    "&::before": {
      backgroundColor: "#DFDFE3  !important",
      content: '""',
      display: "block",
      position: "absolute",
      width: 20,
      height: 20,
      left: -23,
      transform: "rotate(45deg)",
      top: -33,
      zIndex: "1",
    },
  },
};

export type ClassNames = keyof typeof commonStyles;
