import DiamondMedium from 'assets/icons/DiamondMedium';

export const PERKS = [
  {
    Icon: DiamondMedium,
    text: 'To be announced soon!',
    id: 1,
  },
  {
    Icon: DiamondMedium,
    text: 'To be announced soon!',
    id: 2,
  },
  {
    Icon: DiamondMedium,
    text: 'To be announced soon!',
    id: 3,
  },
  // {
  //   Icon: DiamondMedium,
  //   text: "Join us in the winner's circle if your horse wins (when available)",
  //   id: 4,
  // },
  // {
  //   Icon: DiamondMedium,
  //   text: "Attend morning workouts",
  //   id: 5,
  // },
  // {
  //   Icon: DiamondMedium,
  //   text: "Opportunities to meet the horse, trainer, or jockey",
  //   id: 6,
  // },
  // {
  //   Icon: DiamondMedium,
  //   text: "Get invites to racing related experiences: auction events, stable tours and more",
  //   id: 7,
  // },
];
