export const commonStyles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
    },
    formFieldSmall: {
        width: "200px",
        marginBottom: "10px",
        "& .MuiOutlinedInput-root": {
            height: "58px",
            fontWeight: "300",
            fontSize: "20px",
        },
        "& .MuiAutocomplete-input": {
            padding: "7.5px 4px 7.5px 6px !important",
        },
    },
    formField: {
        width: "413px",
        marginBottom: "10px",
        "& .MuiOutlinedInput-root": {
            height: "58px",
            fontWeight: "300",
            fontSize: "20px",
        },
        "& .MuiAutocomplete-input": {
            padding: "7.5px 4px 7.5px 6px !important",
        },
    },
    uploadMainContainer: {
        marginTop: "21px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    uploadContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    title: {
        fontSize: "25px",
        fontWeight: "450",
        marginBottom: "20px",
    },
};

export type ClassesNames = keyof typeof commonStyles;