import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

const nameField = {
  width: "172px",
  height: "50px",
  marginBottom: "20px",
  "& input": {
    fontSize: "17px",
    "&::placeholder": {
      fontSize: "17px",
    },
  },
};

const otherFields = {
  width: "320px",
  height: "50px",
  marginBottom: "20px",
  "& input": {
    fontSize: "17px",
    "&::placeholder": {
      fontSize: "17px",
    },
  },
};

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        padding: "0",
      },
      footerContainer: {
        width: "100%",
      },
      formContainer: {
        padding: "93px 35px 239px",
        minWidth: "390px",
      },
      title: {
        height: "auto",
        fontSize: "26px",
      },
      text: {
        fontSize: "17px",
        textAlign: "center",
        width: "200px",
        margin: "0 auto !important",
        marginBottom: "20px",
      },
      firstNameField: { ...nameField },
      lastNameField: { ...nameField },
      emailField: { ...otherFields },
      passField: { ...otherFields },
      agreemntText: {
        margin: "0 0 30px 0",
        fontSize: "13px",
      },
      checkboxContainer: {
        alignItems: "flex-start",
      },
    })
  )
);
