import React, { FC, useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

import { InputField } from "components/simpleComponents/Input/InputField/InputField";
import { PhoneDropDownComponent } from "components/simpleComponents/PhoneDropDown/PhoneDropDown";
import RadioButton from "components/simpleComponents/Radio/RadioButton";
import { classes } from "pages/Derby/components/styles";
import Dropdown from "components/simpleComponents/Dropdown/DropDown";
import { domicileChoices } from "types/Account";
import { CustomButton } from "components/UIComponents/Button";

import { MAIN_URL, ROUTES } from "utils/constants";
import { getHubSpotCookie, sendHubSpotData } from "apis/hubSpot";
import { useServerAPI } from "apis";

import { STEPS } from "../constants";
import styles from "../styles.module.scss";

interface Props {
  changeStep: (step: STEPS) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const TiaraForm: FC<Props> = ({ changeStep, setIsLoading }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [licenseAgree, setLicenseAgree] = useState(false);
  const [borderColor, setBorderColor] = useState("#7B7B8F");
  const [citizenStatus, setCitizenStatus] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const isTablet = useMediaQuery("(max-width: 992px)");

  const api = useServerAPI();

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    licenseAgree: "",
    duplicate: "",
    citizenStatus: "",
  });

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async () => {
    const newError = {} as typeof error;
    if (!firstName) newError.firstName = "First name is required";
    if (!lastName) newError.lastName = "Last name is required";
    if (!email) newError.email = "Email is required";
    if (!validateEmail(email)) newError.email = "Email is not valid";
    if (phone.length < 5) newError.phone = "Phone is required";
    if (!licenseAgree) newError.licenseAgree = "License agree is required";
    if (!citizenStatus) newError.citizenStatus = "Citizenship is required";

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    try {
      const ip = await api.getIPFromAmazon();

      const formId = process.env.REACT_APP_HUBSPOT_FORM_NEWSLETTER_GUID || "";
      const trackingCookie = getHubSpotCookie() || "";

      const response = await sendHubSpotData(
        {
          firstName,
          lastName,
          email,
          ipAddress: ip,
          trackingCookie,
          pageUri: `${MAIN_URL}${ROUTES.TIARA_100WIF}`,
          pageName: "100WIF Page",
          citizenshipStatus: citizenStatus?.value,
          phoneNumber: `+${phone}`,
        },
        formId
      );

      if (response.inlineMessage === "Thanks for submitting the form.") {
        changeStep(STEPS.SUCCESS_MESSAGE);
      }
    } catch (error: any) {
      console.log(error);

      if (
        error?.response?.data?.message[0] ===
        "phoneNumber must be a valid phone number"
      ) {
        setError((prev) => ({
          ...prev,
          duplicate: "Invalid phone number!",
        }));
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (name === "firstName") {
      if (value) setError((prev) => ({ ...prev, firstName: "" }));
      setFirstName(value);
    } else if (name === "lastName") {
      if (value) setError((prev) => ({ ...prev, lastName: "" }));
      setLastName(value);
    } else if (name === "email") {
      if (value && validateEmail(value))
        setError((prev) => ({ ...prev, duplicate: "", email: "" }));
      setEmail(value);
    } else if (name === "phone") {
      if (value) setError((prev) => ({ ...prev, duplicate: "", phone: "" }));
      setPhone(value);
    }
  };

  useEffect(() => {
    if (error.citizenStatus && !citizenStatus) {
      setBorderColor("#FF0000");
    } else {
      setBorderColor("#7B7B8F");
    }
  }, [error.citizenStatus, citizenStatus]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      ></Box>
      <Box sx={{ ...classes.form, ...{ gap: isTablet ? "18px" : "10px" } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isTablet ? "column" : "row",
            gap: isTablet ? "18px" : "25px",
          }}
        >
          <InputField.Black
            placeholder="FIRST NAME"
            name="firstName"
            onChange={handleChange}
            value={firstName}
            error={!!error.firstName}
            errorText={error.firstName}
          />
          <InputField.Black
            placeholder="LAST NAME"
            name="lastName"
            onChange={handleChange}
            value={lastName}
            error={!!error.lastName}
            errorText={error.lastName}
          />
        </Box>
        <InputField.Black
          placeholder="EMAIL ADDRESS"
          name="email"
          onChange={handleChange}
          value={email}
          error={!!error.email}
          errorText={error.email}
        />
        <PhoneDropDownComponent.Black
          placeholderColor="white"
          borderColor="#7B7B8F"
          onChange={(phone) => {
            setPhone(phone);
            if (phone.length > 5)
              setError((prev) => ({ ...prev, duplicate: "", phone: "" }));
          }}
          value={phone}
          error={!!error.phone}
        />
        {error.phone && (
          <span style={{ color: "#FF0000" }}>Phone is required</span>
        )}
        <Dropdown
          placeholderColor="white"
          color="white"
          borderColor={borderColor}
          arrowColor="white"
          options={domicileChoices}
          value={citizenStatus}
          onChange={(option) => {
            if (citizenStatus)
              setError((prev) => ({ ...prev, citizenStatus: "" }));
            setCitizenStatus(option);
          }}
          placeholder="CITIZENSHIP STATUS"
        />
        {error.citizenStatus && !citizenStatus && (
          <span style={{ color: "#FF0000" }}>Citizenship is required</span>
        )}
        {!!error.duplicate && (
          <p className={styles.duplicateError}>{error.duplicate}</p>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          width: "100%",
          marginTop: isTablet ? "22px" : "26px",
          paddingLeft: isTablet ? "0px" : "10px",
        }}
      >
        <RadioButton.White
          label=""
          value={licenseAgree}
          onChange={() => {}}
          onClick={() => {
            setLicenseAgree((prev) => !prev);
            setError((prev) => ({ ...prev, licenseAgree: "" }));
          }}
          error={!!error.licenseAgree}
        />
        <Typography
          sx={classes.text}
          style={{
            textAlign: "start",
            color: "white",
            fontSize: isTablet ? "12px" : "14px",
          }}
        >
          I have read and agree to the{" "}
          <Link target="_blank" style={{ color: "inherit" }} to={"/privacy"}>
            Privacy Policy
          </Link>
          ,{"  "}
          <Link
            target="_blank"
            style={{ color: "inherit" }}
            to={"/termsOfService"}
          >
            Terms of Service
          </Link>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: isTablet ? "22px" : "26px",
        }}
      >
        <CustomButton btnStyle="secondaryBlack" onClick={handleSubmit}>
          SUBMIT
        </CustomButton>
      </Box>
    </>
  );
};
