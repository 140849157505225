import { Button, Grid, Modal, Paper, Typography } from '@mui/material';
import { marked } from 'marked';
import { Offering } from '../../../types/Offerings';
import {
  WireDetailDataType,
  WireDetailsType,
} from '../../../types/SystemConfig';
import themeData from '../../../utils/theme';

type WireInstructionsProps = {
  show: boolean;
  onClose: () => void;
  details: WireDetailsType;
  offering: Offering;
  investorName: string;
};

export const sorter = (a: WireDetailDataType, b: WireDetailDataType) => {
  if (a.sort > b.sort) return 1;
  if (a.sort < b.sort) return -1;
  return 0;
};

export const WireInstructions = ({
  show,
  onClose,
  details,
  offering,
  investorName,
}: WireInstructionsProps) => {
  return (
    <Modal
      open={show}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        sx={{
          size: 'md',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxHeight: '80%',
          minWidth: '400px',
          transform: 'translate(-50%, -50%)',
          overflow: 'scroll',
          padding: 5,
        }}
      >
        <Grid container>
          {details.details.sort(sorter).map((d) => {
            var detail = d.text
              .replaceAll(
                '__escrowName__',
                offering?.escrowName ?? offering?.name
              )
              .replaceAll('__investorName__', investorName);
            return (
              <>
                <Grid item md={6} xs={12}>
                  <Typography sx={{ fontFamily: themeData.DefaultFontDemi }}>
                    {d.label}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div
                    dangerouslySetInnerHTML={{ __html: marked.parse(detail) }}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
        <Button
          onClick={onClose}
          sx={{ left: '50%', transform: 'translate(-50%,0)' }}
        >
          OK
        </Button>
      </Paper>
    </Modal>
  );
};
