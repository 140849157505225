import { SxProps, Theme, Typography } from '@mui/material';
import themeData from '../utils/theme';

type ErrorMessageProp = {
  message?: string;
  sx?: SxProps<Theme>;
};

export const ErrorMessage = ({ message, sx }: ErrorMessageProp) => {
  if (message) {
    console.error(message);
    return (
      <Typography
        component="h6"
        align="center"
        sx={{
          color: themeData.ErrorTextColor,
          fontSize: '16px',
          marginTop: '1rem',
          ...sx,
        }}
      >
        {decodeURI(message ?? '')}
      </Typography>
    );
  }
  return null;
};

export default ErrorMessage;
