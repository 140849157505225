import { NodeWithTypeArguments } from "typescript";
import { TradeStatuses } from "./enums/TradeStatus";
import { Offering } from "./Offerings";

export type Trade = {
  RRApprovalStatus: string;
  offeringId: string;
  orderId: string;
  partyId: string;
  accountId: string;
  totalAmount: string;
  totalShares?: string;
  esignStatus: string;
  accountName: string;
  accreditedStatus: string;
  issueName: string;
  transactionType: string;
  orderStatus: TradeStatuses;
  createdDate?: string;
  trade_createdDate: string;
  field1?: string;
  field3?: string;
  ccDetails?: {
    accountId: string;
    tradeld: string;
    offeringid: string;
    totalAmount: string;
    vantivTransactionId: string;
    vantivTransactionMessage: string;
    vantivTokenMessage: string;
    RefNum: string;
    errors: string;
    transactionstatus: string;
    fundStatus: string;
    createdDate: string;
    updatedDate: string;
  };
  achDetails?: {
    Accountfullname: string;
    RefNum: string;
    accountId: string;
    createdDate: string;
    errors: string; // ex. "R01 - Insufficient funds",
    fileId: string;
    fundStatus: string; // ex. "Returned",
    offeringId: string;
    totalAmount: string;
    tradeId: string;
    transactionstatus: string;
    updatedDate: string;
  };
  [props: string]: string | object | undefined;
};

export const IntentTradeType = "IntentToInvest";

export type Summary = {
  totalAmount: number;
  totalShares: number;
  count: number;
};
export type TradeSummary = {
  CREATED: Summary;
  FUNDED: Summary;
  SETTLED: Summary;
  "UNWIND PENDING": Summary;
  "UNWIND SETTLED": Summary;
  [status: string]: Summary;
} & Summary;

export type OfferingTrades = {
  issueName: string;
  offeringId: string;
  imageUrl: string;
  trades?: Trade[];
  offering: Offering;
} & TradeSummary;

export type TradesResponse = {
  data: {
    response: {
      [offeringId: string]: OfferingTrades;
      summary: OfferingTrades;
    };
  };
  success: boolean;
};

export type IntentTradeCountType = {
  hasIntentToInvestTrades: boolean;
  count: number;
};

export type IntentTradeCountResponse = {
  data: {
    response: IntentTradeCountType;
    success: boolean;
  };
  status: number;
};
