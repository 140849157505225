import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { RadioData, Radios, TextField } from 'mui-rff';
import { useEffect } from 'react';
import { Form } from 'react-final-form';
import SectionTitle from '../../../components/SectionTitle';
import { ComplianceDataType } from '../../../types/ComplianceData';
import { DollarAmountFormatter } from '../../../utils';
import themeData from '../../../utils/theme';
import '../ProfileForm.css';
import './ComplianceForm.css';

export type ComplianceFormProps = {
  data?: ComplianceDataType;
  onCancel: Function;
  onSave: (values: ComplianceDataType) => void;
};

const disableEmployment = true;
const disableRisk = true;
export const disabledFinraAssociation = true;

const complinaceFormData = (inputs?: ComplianceDataType) => {
  const form: ComplianceDataType = {
    accreditation: inputs?.accreditation ?? '',
    annualIncome: inputs?.annualIncome ?? '',
    netWorth: inputs?.netWorth ?? '',
    associtationFinra: inputs?.associtationFinra ?? '',
    hasFinraLicense: inputs?.hasFinraLicense ?? '',
    finraLicense: inputs?.finraLicense ?? '',
    associatedOrganization: inputs?.associatedOrganization ?? '',
    memberOfTradedPublicly: inputs?.memberOfTradedPublicly ?? '',
    employment: inputs?.employment ?? '',
    risk: inputs?.risk ?? '',
  };
  return form;
};

const accreditationChoices: RadioData[] = [
  {
    label: (
      <Box sx={{ paddingTop: '9px' }}>
        <Typography sx={{ fontFamily: themeData.DefaultFont }}>
          I am an Accredited Investor,
        </Typography>
        <Typography sx={{ fontFamily: themeData.DefaultFontBook }}>
          because I have an individual income of more than $200,000 per year, or
          a joint income of $300,000 in each of the last two years.
        </Typography>
      </Box>
    ),
    value: 'accredited-1',
  },
  {
    label: (
      <Box sx={{ paddingTop: '9px' }}>
        <Typography sx={{ fontFamily: themeData.DefaultFont }}>
          I am an Accredited Investor,
        </Typography>
        <Typography sx={{ fontFamily: themeData.DefaultFontBook }}>
          because I have a net worth exceeding $1,000,000, excluding my primary
          residence, either individuality or jointly with my spouse.
        </Typography>
      </Box>
    ),
    value: 'accredited-2',
  },
  {
    label: (
      <Box sx={{ paddingTop: '9px' }}>
        <Typography sx={{ fontFamily: themeData.DefaultFont }}>
          I am not Accredited,
        </Typography>
        <Typography sx={{ fontFamily: themeData.DefaultFontBook }}>
          I acknowledge that my investment(s) in this offering is less than 10%
          of my net worth or annual gross income.
        </Typography>
      </Box>
    ),
    value: 'not-accredited',
  },
];

const riskChoices: RadioData[] = [
  {
    label: (
      <Box sx={{ paddingTop: '9px' }}>
        <Typography sx={{ fontFamily: themeData.DefaultFont }}>
          Conservative
        </Typography>
        <Typography sx={{ fontFamily: themeData.DefaultFontBook }}>
          I want to preserve my initial principal, with minimum risk, even if
          this means this account does not generate significant income or
          returns.
        </Typography>
      </Box>
    ),
    value: 'conservative',
  },
  {
    label: (
      <Box sx={{ paddingTop: '9px' }}>
        <Typography sx={{ fontFamily: themeData.DefaultFont }}>
          Moderate
        </Typography>
        <Typography sx={{ fontFamily: themeData.DefaultFontBook }}>
          I am willing to accept some risk to my initial principal and tolerate
          some volatility to seek higher returns.
        </Typography>
      </Box>
    ),
    value: 'moderate',
  },
  {
    label: (
      <Box sx={{ paddingTop: '9px' }}>
        <Typography sx={{ fontFamily: themeData.DefaultFont }}>
          Growth
        </Typography>
        <Typography sx={{ fontFamily: themeData.DefaultFontBook }}>
          I am willing to accept more risk to my initial principal to seek
          maximum returns and understand I could lose most, or all, of the money
          invested.
        </Typography>
      </Box>
    ),
    value: 'growth',
  },
];

export const finraChoices: RadioData[] = [
  { label: 'Series 7 (General Securities Representative)', value: 'series7' },
  { label: 'Series 65 (Investment Adviser Representative)', value: 'series65' },
  {
    label: 'Series 87 (Private Securities Offerings Representative)',
    value: 'series87',
  },
];

const ComplianceForm = ({ data, onCancel, onSave }: ComplianceFormProps) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  async function onSubmitComplianceForm(values: ComplianceDataType) {
    values.annualIncome = values.annualIncome
      .replaceAll('$', '')
      .replaceAll(',', '');
    values.netWorth = values.netWorth.replaceAll('$', '').replaceAll(',', '');
    if (values.hasFinraLicense === 'no') values.finraLicense = '';
    onSave(values);
  }

  async function validateComplianceForm(values: ComplianceDataType) {
    var errors: { [field: string]: string } = {};
    if (!!!values.accreditation || values.accreditation.length < 1) {
      errors.accreditation = 'You must answer this question.';
    }
    if (!!!values.annualIncome || values.annualIncome.length < 1) {
      errors.annualIncome = 'Annual Income cannot be blank';
    }
    if (!!!values.netWorth || values.netWorth.length < 1) {
      errors.netWorth = 'Net Worth cannot be blank';
    }

    if (!!!values.hasFinraLicense || values.hasFinraLicense.length < 1) {
      errors.hasFinraLicense = 'You must answer this question.';
    }
    if (
      values?.hasFinraLicense === 'yes' &&
      (!!!values.finraLicense || values.finraLicense.length < 1)
    ) {
      errors.finraLicense = 'You must select a FINRA license.';
    }

    if (
      !!!values.memberOfTradedPublicly ||
      values.memberOfTradedPublicly.length < 1
    ) {
      errors.memberOfTradedPublicly = 'You must answer this question.';
    }

    if (!disableEmployment) {
      if (!!!values.employment || values.employment.length < 1) {
        errors.employment = 'You must answer this question.';
      }
    }
    if (!disableRisk) {
      if (!!!values.risk || values.risk.length < 1) {
        errors.risk = 'You must answer this question.';
      }
    }
    if (!disabledFinraAssociation) {
      if (!!!values.associtationFinra || values.associtationFinra.length < 1) {
        errors.associtationFinra = 'You must answer this question.';
      }
      if (
        values?.associtationFinra === 'yes' &&
        (!!!values.associatedOrganization ||
          values.associatedOrganization.length < 1)
      ) {
        errors.associatedOrganization =
          'You must provide the name of the institution or organization';
      }
    }
    console.log(errors);
    return Object.keys(errors).length > 0 ? errors : null;
  }

  const formatField = (value: string, name: string): string => {
    if (['annualIncome', 'netWorth'].includes(name)) {
      if (value) {
        var cleanValue = value.replaceAll(/[^0-9^$^,]*/g, '');
        if (cleanValue) return DollarAmountFormatter(cleanValue);
      }
      return '';
    }
    return value;
  };

  return (
    <Box sx={{ maxWidth: '722px', margin: 'auto' }}>
      <SectionTitle
        title="Investor Qualification"
        titleColor={themeData.GreyLabel}
      />
      <Box>
        <Form
          onSubmit={onSubmitComplianceForm}
          initialValues={complinaceFormData(data)}
          validate={validateComplianceForm}
          render={({ handleSubmit, values }) => (
            <Box component="form">
              <Box sx={{ marginTop: '17px' }}>
                <Radios
                  label="What is your Accreditation Status?"
                  name="accreditation"
                  required
                  data={accreditationChoices}
                  sx={{ alignSelf: 'flex-start', marginLeft: '20px' }}
                />
              </Box>
              <Box
                sx={{
                  marginTop: '17px',
                  display: disableEmployment ? 'none' : 'initial',
                }}
              >
                <Radios
                  label="Current Employment Status?"
                  name="employment"
                  required
                  data={[
                    { value: 'employed', label: 'Employed' },
                    { value: 'selfEmployed', label: 'Self-Employed' },
                    { value: 'unemployed', label: 'Unemployed' },
                    { value: 'retired', label: 'Retired' },
                    { value: 'student', label: 'Student' },
                  ]}
                  radioGroupProps={{ row: true }}
                  sx={{ alignSelf: 'flex-start', marginLeft: '20px' }}
                />
              </Box>
              <Box sx={{ marginTop: '17px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '20px',
                  }}
                >
                  <Box display={'flex'} flexDirection={'row'}>
                    <Typography
                      component="h4"
                      variant="h6"
                      sx={{
                        color: '#000000',
                      }}
                      style={{ fontSize: '13px', marginTop: 14 }}
                    >
                      Annual Income *
                    </Typography>
                    <Tooltip title="Dollar amount only, no cents.">
                      <InfoIcon
                        fontSize="inherit"
                        style={{ marginLeft: '8px', marginTop: '14px' }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    variant="outlined"
                    name="annualIncome"
                    required
                    id="annual-income"
                    margin="dense"
                    size="small"
                    placeholder="Annual Income"
                    fieldProps={{ format: formatField }}
                  />
                  <Box display={'flex'} flexDirection={'row'}>
                    <Typography
                      component="h4"
                      variant="h6"
                      sx={{
                        color: '#000000',
                      }}
                      style={{ fontSize: '13px', marginTop: 14 }}
                    >
                      Net Worth *
                    </Typography>
                    <Tooltip title="Dollar amount only, no cents.">
                      <InfoIcon
                        fontSize="inherit"
                        style={{ marginLeft: '8px', marginTop: '14px' }}
                      />
                    </Tooltip>
                  </Box>
                  <TextField
                    variant="outlined"
                    name="netWorth"
                    required
                    id="net-worth"
                    margin="dense"
                    size="small"
                    placeholder="Net Worth"
                    fieldProps={{ format: formatField }}
                  />
                  <Typography sx={{ fontFamily: themeData.DefaultFontBook }}>
                    Individual Net Worth - value of all assets including
                    investments but not including equity in primary residence,
                    minus any debts
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: '17px',
                  display: disableRisk ? 'none' : 'initial',
                }}
              >
                <Radios
                  label="Please select the degree of risk that you are willing to take with the assets in this account"
                  name="risk"
                  required
                  data={riskChoices}
                  sx={{ alignSelf: 'flex-start', marginLeft: '20px' }}
                />
              </Box>
              <Box
                sx={{
                  marginTop: '17px',
                  display: disabledFinraAssociation ? 'none' : 'initial',
                }}
              >
                <Radios
                  label="Are you or anyone in your household associated with a FINRA member, organization, or the SEC?"
                  name="associtationFinra"
                  required
                  data={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  radioGroupProps={{ row: true }}
                  sx={{ alignSelf: 'flex-start', marginLeft: '20px' }}
                />
                {values?.associtationFinra === 'yes' && (
                  <>
                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography
                        component="h4"
                        variant="h6"
                        sx={{
                          color: '#000000',
                        }}
                        style={{ fontSize: '13px', marginTop: 14 }}
                      >
                        Institution or Organization *
                      </Typography>
                      <Tooltip title="Please Provide the name of the institution or organization you are associated to">
                        <InfoIcon
                          fontSize="inherit"
                          style={{ marginLeft: '8px', marginTop: '14px' }}
                        />
                      </Tooltip>
                    </Box>
                    <TextField
                      variant="outlined"
                      name="associatedOrganization"
                      required
                      margin="dense"
                      size="small"
                      id="associated-organization"
                      placeholder="Organization Name"
                    />
                  </>
                )}
              </Box>
              <Box sx={{ marginTop: '17px' }}>
                <Radios
                  label="Do you hold a FINRA License?"
                  name="hasFinraLicense"
                  required
                  data={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  radioGroupProps={{ row: true }}
                  sx={{ alignSelf: 'flex-start', marginLeft: '20px' }}
                />
                {values?.hasFinraLicense === 'yes' && (
                  <>
                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography
                        component="h4"
                        variant="h6"
                        sx={{
                          color: '#000000',
                        }}
                        style={{ fontSize: '13px', marginTop: 14 }}
                      >
                        Select your FINRA License *
                      </Typography>
                    </Box>
                    <Radios
                      label=""
                      name="finraLicense"
                      required
                      data={finraChoices}
                      sx={{ alignSelf: 'flex-start', marginLeft: '20px' }}
                    />
                  </>
                )}
              </Box>
              <Box sx={{ marginTop: '17px' }}>
                <Radios
                  label="Are you or anyone in your household or immediate family a 10% shareholder, officer, or member of the board of directors of a publicly traded company?"
                  name="memberOfTradedPublicly"
                  required
                  dir="horizontal"
                  data={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  radioGroupProps={{ row: true }}
                  sx={{ alignSelf: 'flex-start', marginLeft: '20px' }}
                />
              </Box>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: '1rem',
                }}
              >
                <Grid item xs={3} />
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    sx={{
                      width: '100%',
                      marginTop: 1,
                      color: '#274f85',
                      borderRadius: '5px !important',
                    }}
                    onClick={() => onCancel()}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      backgroundColor: '#274f85',
                      marginTop: 1,
                      color: themeData.WhiteColor,
                      borderRadius: '5px !important',
                    }}
                    onClick={handleSubmit}
                  >
                    Continue
                  </Button>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ComplianceForm;
