import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { FieldProps } from 'react-final-form';
import { TextField, TextFieldProps } from 'mui-rff';
import { SSNFormatter } from '../utils';

export type SsnFieldProps = Partial<
  Omit<TextFieldProps, 'type' | 'InputProps'>
> &
  FieldProps<any, any, HTMLElement, any>;
export const SsnField = (props: SsnFieldProps) => {
  const [showSsn, setShowSsn] = useState(false);
  const handleClickShowSsn = () => setShowSsn(!showSsn);

  const formatField = (value: string, name: string): string => {
    if (showSsn) return SSNFormatter(value);
    return value;
  };

  return (
    <TextField
      {...props}
      fieldProps={{ format: formatField }}
      inputProps={
        !showSsn
          ? { style: { fontFamily: 'Verdana', letterSpacing: '2px' } }
          : undefined
      }
      type={showSsn ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowSsn}
            >
              {showSsn ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
