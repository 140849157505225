export const settingsThumbsSlider = {
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  focusOnSelect: true,
};

export const settingsPreviewSlider = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: false,
  verticalSwiping: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        dots: true,
      },
    },
  ],
};
