import { Box, Typography, useMediaQuery } from '@mui/material'
import InputSubmit from 'components/simpleComponents/Input/InputSubmit/InputSubmit'
import DiamondLayout from 'layout/DiamondLayout/DiamondLayout'
import { useEffect, useState } from 'react'
import { useServerAPI } from '../../../apis'
import { Preloader } from '../../../components/simpleComponents/Preloader'
import AuthErrorResponse from '../../../types/AuthErrorResponse'
import { SigninId } from '../../../types/SigninId'
import { validateEmail } from '../../../utils/Validators'
import PasswordResetDone from './PasswordResetDone'

type ForgotFormData = {
  email: string
}

const ForgotPage = () => {
  let api = useServerAPI()
  const isMobileScreen = useMediaQuery('(max-width:768px)')
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)
  const [inputs, setInputs] = useState<ForgotFormData>({ email: '' })
  const [emailValid, setEmailValid] = useState(false)
  const [emailErrorText, setEmailErrorText] = useState<string>()
  const [authError, setAuthError] = useState<AuthErrorResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const email = localStorage.getItem('email')
    if (email) {
      setInputs({ email })
      setEmailValid(validateEmail(email))
    }
  }, [])

  const onEmailPasswordReset = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    if (!emailValid) {
      setEmailErrorText('Invalid email address format')
      return
    }
    setAuthError(null)
    setIsLoading(true)
    const body: SigninId = inputs
    try {
      const response = await api.resetEmailPassword(body)
      setIsLoading(false)
      if (response) {
        console.debug('Forgot Password returned', response)
        if (response.status === 200) {
          setPasswordResetSuccess(true)
        }
      }
    } catch (error: any) {
      console.error('error', error)
      setAuthError(error)
    }
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'email') {
      if (!validateEmail(value)) {
        setEmailValid(false)
        setEmailErrorText('Invalid email address format')
      } else {
        setEmailValid(true)
        setEmailErrorText(undefined)
      }
    }
    setInputs({ ...inputs, [name]: value })
  }

  const passwordResetForm = (
    <>
      <Box
        style={{ width: '100%' }}
        component={'form'}
        sx={{ maxWidth: '515px', margin: '0 auto' }}
        onSubmit={onEmailPasswordReset}
      >
        <Typography
          component='h2'
          variant='h2'
          fontFamily={'Telugu MN'}
          lineHeight={'36.43px'}
          align='center'
          letterSpacing={'2px'}
          fontSize={isMobileScreen ? '25px' : '30px'}
          textTransform={'uppercase'}
          color={'#fff'}
          paddingBottom={'5px'}
        >
          FORGOT PASSWORD
        </Typography>
        <Typography
          variant='h6'
          color='#fff'
          fontSize={isMobileScreen ? '16px' : '20px'}
          lineHeight={'25.64px'}
          fontWeight={400}
          align='center'
          maxWidth={'423px'}
          margin={'0 auto'}
          paddingBottom={isMobileScreen ? '24px' : '64px'}
        >
          Enter the email address associated with your account and we’ll send
          you a link to reset your password.
        </Typography>
        <InputSubmit.Black
          buttonText='SUBMIT'
          placeholder='EMAIL ADDRESS'
          value={inputs.email}
          onChange={onChangeHandler}
          onSubmit={onEmailPasswordReset}
          name='email'
          eye={false}
          error={!emailValid}
          errorText={emailErrorText}
        />
        {authError && (
          <Typography
            variant='body2'
            color='textSecondary'
            align='center'
            style={{ color: 'red', margin: '-1rem auto 0.8rem' }}
          >
            {authError?.statusCode === 403
              ? 'Account already registered.'
              : JSON.parse(authError.message)?.status_message}
          </Typography>
        )}
        <Box />
      </Box>
    </>
  )

  const currentForm = passwordResetSuccess ? (
    <PasswordResetDone />
  ) : (
    passwordResetForm
  )
  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            backgroundColor: 'white',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Preloader />
        </Box>
      ) : (
        <DiamondLayout link='/reset'>
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              backgroundColor: '#030303',
              display: 'flex',
              justifyContent: isMobileScreen ? 'flex-end' : 'space-between',
              alignItems: 'center',
              flexDirection: isMobileScreen ? 'column-reverse' : 'center',
              gap: isMobileScreen ? '24px' : '0px',
              paddingTop: isMobileScreen ? '24px' : '0px',
            }}
          >
            {currentForm}
          </Box>
        </DiamondLayout>
      )}
    </>
  )
}

export default ForgotPage
