interface ParsedField {
  [key: string]: string | number | undefined;
  holdFINRA?: string;
  typeFINRA?: string;
  memberOfTradedPublicly?: string;
  associatedOrganization?: string;
  shareHolders?: string;
  annualIncome?: string;
  accreditationStatus?: string;
}

export default function parseFieldFromUserProfile(field: string): ParsedField {
  const result: ParsedField = {};
  field
    .replaceAll(/[{}]/gi, "")
    .split(",")
    .forEach((pair) => {
      const [key, value] = pair.split(":");
      result[key] = value;
    });
  return result;
}
