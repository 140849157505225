import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BasicOfferCard,
  ChildsCardProps
} from "../../../../Assets/components/CurrentOfferingAlt/components/BasicOfferCard/BasicOfferCard";
import Diamond from './../../../../../AssetsUnlogged/assets/diamond.png'


type ActiveOfferingCardProps = ChildsCardProps;

const ActiveOfferingCard: React.FC<ActiveOfferingCardProps> = function (
  props
): JSX.Element {
  const navigate = useNavigate();
  const onByBtnClick = (): void => {
    navigate(`/assetDetails/${props.offer.offeringId}`);
  };
  return (
    <BasicOfferCard
      {...props}
      buyButtonText="VIEW ASSET"
      onBuyBtnClick={onByBtnClick}
      lastInfoTitleType="GIA Report Grade"
      type="large"
      btnType={"inlineGrey"}
      img={Diamond}
    />
  );
};

export default ActiveOfferingCard;
