import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  downloadImage: {
    widht: "100%",
  },
  secImage: {
    widht: "100%",
  },
  giaImage: {
    widht: "100%",
  },
  igiImage: {
    widht: "100%",
  },
  fcrfImage: {
    widht: "100%",
  },
};

export type ClassesNames = keyof typeof commonStyles;
