import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      monthSelect: {
        height: "45px",
        width: "114px",
        "& .MuiSelect-select": {
          fontSize: "16px",
        },
      },
      daySelect: {
        height: "45px",
        width: "78px",
        "& .MuiSelect-select": {
          fontSize: "16px",
        },
      },
      yearSelect: {
        height: "45px",
        width: "95px",
        "& .MuiSelect-select": {
          fontSize: "16px",
        },
      },
      selectPlaceholder: {
        fontSize: "16px",
      },
    })
  )
);
