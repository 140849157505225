import LuxusLogoNoText from 'assets/icons/LuxusLogoNoText';

import styles from './MatchHighlights.module.scss';

const MatchHighlights = () => {
  return (
    <section className={styles.sectionContainer}>
      <div className={styles.internalContainer}>
        <div className={styles.firstSection}>
          <LuxusLogoNoText />
          <h2 className={styles.title}>last match highlights</h2>
          <p className={styles.subtitle}>Watch Missed The Cut Live!</p>
          <p className={styles.text}>
            Join us for a front-row seat to all the fun at the Hardwicke Stakes,
            the final race of Royal Ascot 2024. We’ll have highlights of all the
            excitement here.
          </p>
        </div>
        <div className={styles.secondSection}>
          <img
            className={styles.image}
            src="/images/stream-background.jpg"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default MatchHighlights;
