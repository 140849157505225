import styled from "@emotion/styled";

export const UploadFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) =>
    theme === "black" ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)"};
  width: 100%;
  height: 100%;
  padding: 10px 0px;
`;

export const UploadCircle = styled.div`
  border-color: ${({ theme }) => (theme === "black" ? "#FFFFFF" : "#000000")};
  border: 2px dashed;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  gap: 1vh;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: center;
  height: 184px;
  width: 184px;
  text-align: center;
`;

export const UploadInput = styled.input`
  display: none;
`;

export const Filename = styled.div`
  color: ${({ theme }) => (theme === "black" ? "#FFFFFF" : "#000000")};
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
