import React from 'react';
import { Offering } from '../../../../../types/Offerings';
import ActiveOfferingCard from './components/ActiveOfferCard';
import FeaturedOfferingCard from './components/FeaturedOfferingCard';
import SoldOutOfferingCard from './components/SoldOutOfferingCard';
import SoldPrivatelyOfferingCard from './components/SoldPrivatelyOfferCard';
import './style.scss';
import TiaraAsset from 'pages/Dashboard/components/TiaraAsset/TiaraAsset';
import ThoroughbredAsset from 'pages/Dashboard/components/ThoroughbredAsset/ThoroughbredAsset';

interface CurrentOfferingAltProps {
  offerings: Offering[];
  isShowMoreUpcoming: boolean;
}

const CurrentOfferingAlt: React.FC<CurrentOfferingAltProps> = function ({
  offerings,
  isShowMoreUpcoming,
}): JSX.Element {
  return (
    <div className={'cardsContainer'}>
      <ThoroughbredAsset isShowMoreUpcoming={isShowMoreUpcoming} />
      {offerings.map((offer, index) =>
        !isShowMoreUpcoming && index > 0 ? null : (
          <React.Fragment key={offer.id}>
            {offer.status === 'Active' && <ActiveOfferingCard offer={offer} />}
            {offer.status === 'IOI' && <FeaturedOfferingCard offer={offer} />}
            {offer.status === 'SoldOut' && (
              <SoldOutOfferingCard offer={offer} />
            )}
            {offer.status === 'soldPrivately' && (
              <SoldPrivatelyOfferingCard offer={offer} />
            )}
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default CurrentOfferingAlt;
