import { useState } from "react";

import SecondStepAlternative from "./components/SecondStepAlternative";
import FourthStep from "./components/FourthStep";
import FifthStep from "./components/FifthStep";
import SixthStep from "./components/SixthStep";
import SsnForm from "./components/SsnForm";
import FirstStep from "./components/FirstStep";
import { Preloader } from "components/simpleComponents/Preloader";
import { SuccessScreenAch } from "./components/SuccessScreenAch";
import { SuccessScreenWire } from "./components/SuccessScreenWire";
import { SuccessScreenCC } from "./components/SuccessScreenCC";
import LoadDocumentsForm from "./components/LoadDocumentsForm";

import { STEPS } from "./AccreditationForm.data";
import { FormData } from "./AccreditationForm.type";

type AccreditationFormProps = {
  step: STEPS;
  setStep: React.Dispatch<React.SetStateAction<STEPS>>;
  guid?: string;
  isLoading: boolean;
  form: FormData;
  setForm: React.Dispatch<React.SetStateAction<FormData>>;
};

export const AccreditationForm = ({
  step,
  setStep,
  guid = "",
  isLoading,
  form,
  setForm,
}: AccreditationFormProps) => {
  const [ssnNumber, setSsnNumber] = useState("");

  return (
    <>
      {isLoading ? (
        <Preloader isWhite />
      ) : (
        <div>
          {step === STEPS.MAIN_FORM && (
            <FirstStep
              guid={guid}
              form={form}
              setForm={setForm}
              setStep={setStep}
            />
          )}
          {step === STEPS.SSN_FORM && (
            <SsnForm setStep={setStep} setSsnNumber={setSsnNumber} />
          )}
          {step === STEPS.ATTESTATIONS_FORM && (
            <SecondStepAlternative
              guid={guid}
              form={form}
              setStep={setStep}
              ssn={ssnNumber}
            />
          )}
          {step === STEPS.LOAD_DOCUMENTS_FORM && (
            <LoadDocumentsForm
              citizenship={form.citizenship}
              guid={guid}
              setStep={setStep}
            />
          )}
          {step === STEPS.PDF_PREVIEW && (
            <FourthStep guid={guid} form={form} setStep={setStep} />
          )}
          {step === STEPS.ACH_CREDIT_CARD_FORM_RESIDENT && (
            <FifthStep
              guid={guid}
              setStep={setStep}
              securitiesQuantity={form.securitiesNumber}
            />
          )}
          {step === STEPS.CREDIT_CARD_FORM_NON_RESIDENT && (
            <SixthStep guid={guid} setStep={setStep} />
          )}
          {step === STEPS.SUCCESS_SCREEN_ACH && <SuccessScreenAch />}
          {step === STEPS.SUCCESS_SCREEN_CC && <SuccessScreenCC />}
          {step === STEPS.SUCCESS_SCREEN_WIRE && <SuccessScreenWire />}
        </div>
      )}
    </>
  );
};
