import classNames from 'classnames';

import { TRIP_VARIANTS } from '../RoyalAscotForm/ContactsForm/constants';

import styles from './Indicator.module.scss';

type IndicatorProps = {
  currentStep: number;
  tripVariant: TRIP_VARIANTS;
};

const Indicator = ({ currentStep, tripVariant }: IndicatorProps) => {
  const indicatorStepsSingle = [
    { text: 'Contacts', isFilled: true },
    { text: 'Address', isFilled: currentStep > 1 },
  ];

  const indicatorStepsCouple = [
    { text: 'Contacts', isFilled: true },
    { text: 'Address', isFilled: currentStep > 1 },
    { text: 'Guest contacts', isFilled: currentStep > 2 },
    { text: 'Guest address', isFilled: currentStep > 3 },
  ];

  const indicatorSteps =
    tripVariant === TRIP_VARIANTS.INDIVIDUAL_FULL ||
    tripVariant === TRIP_VARIANTS.INDIVIDUAL_ONE_DINNER ||
    tripVariant === TRIP_VARIANTS.INDIVIDUAL_THREE_DINNERS
      ? indicatorStepsSingle
      : indicatorStepsCouple;

  return (
    <div className={styles.container}>
      {indicatorSteps.map((step) => {
        return (
          <div key={step.text} className={styles.indicatorWrapper}>
            <div
              className={classNames(styles.indicator, {
                [styles.indicatorFilled]: step.isFilled,
              })}
            />
            <p className={styles.text}>{step.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Indicator;
