import { Checkbox } from "@mui/material";
import { styled } from "@mui/system";
export const BlackStyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#000",
  padding: "0 9px 0 9px",
  "&.Mui-checked": {
    color: "#000",
  },
}));

export const WhiteStyledCheckbox = styled(Checkbox)({
  color: "#fff",
  padding: "0 9px 0 9px",
  "&.Mui-checked": {
    color: "#fff",
  },
});
