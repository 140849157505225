import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../../../utils/theme";

export const useTabletLandscapeStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        padding: "14px 27.5px 17px",
      },
      caption: {
        fontSize: "16px",
      },
      btn: {
        fontSize: "18px",
        width: "100%",
        height: "48px",
      },
      text: {
        fontSize: "16px",
      },
    })
  )
);
