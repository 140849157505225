import type { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import { BankAccountData, CreditCardData } from "../../../types/Account";
import { PaymentMode } from "../../../types/enums/PaymentMode";
import { PaymentType } from "../../../types/enums/PaymentType";
import { PurchaseDetail } from "../../../types/PurchaseDetail";
import { getPaymentsInfo } from "./data";
import { useServerAPI } from "../../../apis";

/**
 * handle SUBMIT ORDER button click
 * @param purchase
 * @param api
 * @param navigate
 * @param setIsBuyLoadActive
 * @returns
 */
export const handleBuyShareClick =
  (
    purchase: PurchaseDetail,
    api: ReturnType<typeof useServerAPI>,
    navigate: NavigateFunction,
    setIsBuyLoadActive: Dispatch<SetStateAction<boolean>>
  ) =>
  async (): Promise<void> => {
    if (!purchase.transactionType) {
      return;
    }
    setIsBuyLoadActive(true);
    const result = await api
      .makePurchase(purchase)
      .finally(() => setIsBuyLoadActive(false));

    if (result.data.success) {
      navigate(`/buySucessfull/${purchase.offeringId}`);
    }
  };

/**
 * open CHANGE PAYMENT TYPE modal window
 * @param setMode
 * @returns
 */
export const handleChangePaymentMethodClick =
  (setMode: React.Dispatch<React.SetStateAction<PaymentMode>>) => (): void => {
    setMode(PaymentMode.PAYMENT);
  };

/**
 * close CHANGE PAYMENT TYPE modal window
 * @param setMode
 * @param setChosenMethods
 * @returns
 */
export const handleCloseModal =
  (
    setMode: React.Dispatch<React.SetStateAction<PaymentMode>>,
    setChosenMethods: React.Dispatch<React.SetStateAction<PaymentType>>
  ) =>
  (selected: PaymentType): void => {
    setChosenMethods(selected);
    setMode(PaymentMode.EMPTY);
  };

/**
 * open EDIT PAYMENT DETAILS  modal window
 * @param setMode
 * @returns
 */
export const handleEditPayment =
  (setMode: React.Dispatch<React.SetStateAction<PaymentMode>>) =>
  (selected: PaymentMode) =>
  (): void => {
    setMode(selected);
  };

/**
 * close EDIT PAYMENT DETAILS  modal window
 * @param setMode
 * @returns
 */
export const handleCloseEditPayment =
  (setMode: React.Dispatch<React.SetStateAction<PaymentMode>>) => (): void => {
    setMode(PaymentMode.PAYMENT);
  };

/**
 * save PAYMENT DETAILS params
 * @param api
 * @param setMode
 * @param setBankData
 * @param setCardData
 * @returns
 */
export const handleSavePayment =
  (
    api: any,
    setMode: React.Dispatch<React.SetStateAction<PaymentMode>>,
    setBankData: React.Dispatch<
      React.SetStateAction<BankAccountData | undefined>
    >,
    setCardData: React.Dispatch<
      React.SetStateAction<CreditCardData | undefined>
    >,
    setChosenMethods: React.Dispatch<React.SetStateAction<PaymentType>>
  ) =>
  async (): Promise<void> => {
    setMode(PaymentMode.LOADING);
    await getPaymentsInfo(api, setBankData, setCardData, setChosenMethods);
    setMode(PaymentMode.PAYMENT);
  };

export const handleCloseAgreementModal =
  (setIsAgreementOpen: (value: React.SetStateAction<boolean>) => void) =>
  (): void => {
    setIsAgreementOpen(false);
  };

export const handleDoneAgreementModal =
  (
    setIsAgreementOpen: (value: React.SetStateAction<boolean>) => void,
    setSign: React.Dispatch<React.SetStateAction<string>>
  ) =>
  (value: string) => {
    setSign(value);
    setIsAgreementOpen(false);
  };
