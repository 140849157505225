import { makeStyles, createStyles } from "@mui/styles";

export const useStyle = makeStyles(() =>
  createStyles({
    leftCornerTitleBoxClass: {
      backgroundColor: "#6890FF",
      borderRadius: "6px",
      color: "#FFFFFF",
      fontSize: "21px",
      fontWeight: "500",
    },
  })
);
