import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Box,
} from "@mui/material";
import ReactGA from "react-ga";

import { useStyles } from "./styles";

interface FinraLicenseProfileFormProps {
  setFinraLicenseProfile(value: string): void;
  initIsHaveLicense: string;
  initFinraLicenseProfile: string;
}

const FinraLicenseProfileForm: React.FC<FinraLicenseProfileFormProps> =
  function ({
    setFinraLicenseProfile,
    initIsHaveLicense,
    initFinraLicenseProfile,
  }): JSX.Element {
    const finraChoices = [
      { label: "Series 7 (General Securities Representative)", value: "7" },
      {
        label: "Series 65 (Investment Adviser Representative)",
        value: "65",
      },
      {
        label: "Series 87 (Private Securities Offerings Representative)",
        value: "87",
      },
    ];

    const [finraLicense, setFinraLicense] = useState<string>(
      initFinraLicenseProfile
    );

    const onFinraLicenseChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      setFinraLicense(event.target.value);
      setFinraLicenseProfile(event.target.value);
    };

    const classes = useStyles();

    return (
      <>
        <FormControl className={classes.finraTypeContainer}>
          <RadioGroup
            onChange={onFinraLicenseChange}
            name="accreditaion-status-group"
          >
            {finraChoices.map((choice) => (
              <FormControlLabel
                key={choice.value}
                label={choice.label}
                control={
                  <Radio
                    color="default"
                    size="medium"
                    checked={choice.value === finraLicense}
                  />
                }
                value={choice.value}
                className={classes.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    );
  };

export default FinraLicenseProfileForm;
