import { useEffect, useState } from "react";

import { Preloader } from "components/simpleComponents/Preloader";
import { CustomButton } from "components/UIComponents/Button";
import TermOfUseCheckbox from "pages/BuyShares/components/TermOfUseCheckbox";

import { initiateCardPaymentFlow, finalizeCardPaymentFlow } from "apis/backend";
import { STEPS } from "../../AccreditationForm.data";

import styles from "./CreditCardForm.module.scss";

type CreditCardFormProps = {
  guid: string;
  setStep: (step: STEPS) => void;
  setIsCardAccepted: (isCardAccepted: boolean) => void;
  isCardAccepted: boolean;
};

const CreditCardForm = ({
  guid,
  setStep,
  setIsCardAccepted,
  isCardAccepted,
}: CreditCardFormProps) => {
  const [paymentLink, setPaymentLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFirstBoxChecked, setIsFirstBoxChecked] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await finalizeCardPaymentFlow(guid);
      setIsLoading(false);

      setStep(STEPS.SUCCESS_SCREEN_CC);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    setErrorMessage("");

    const initiateCardPayment = async () => {
      try {
        setIsLoading(true);
        const data = await initiateCardPaymentFlow(guid, signal);

        setPaymentLink(data.stripeLink);
        setIsLoading(false);
      } catch (err: any) {
        console.log(err);
        setIsLoading(false);

        if (err?.response?.data?.statusCode === "710") {
          setErrorMessage("Credit Card details already added for this account");
        }
      }
    };

    initiateCardPayment();

    return () => {
      abortController.abort();
    };
  }, [guid]);

  useEffect(() => {
    const handlePayment = async (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);

        if (data.statusCode === 101) {
          setIsCardAccepted(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener("message", handlePayment);

    return () => {
      window.removeEventListener("message", handlePayment);
    };
  });

  return (
    <>
      <div className={styles.stripeContainer}>
        {isLoading ? (
          <div className={styles.preloaderWrapper}>
            <Preloader isWhite isSmallHeight />
          </div>
        ) : (
          <>
            {!isCardAccepted && (
              <p className={styles.text}>
                Complete your investment by securely inputting your credit card
                payment details through our partner Stripe
              </p>
            )}
            {isCardAccepted ? (
              <p className={styles.textConfirmation}>
                Thank you! Your card has been saved
              </p>
            ) : (
              <iframe
                src={paymentLink}
                width={340}
                height={380}
                title="Stripe payment form"
              />
            )}
            {isCardAccepted && (
              <>
                <div className={styles.checkBoxTermsContainer}>
                  <TermOfUseCheckbox
                    onChange={() =>
                      setIsFirstBoxChecked((prevState) => !prevState)
                    }
                  />
                </div>
                <div className={styles.buttonWrapper}>
                  <CustomButton
                    disabled={!isFirstBoxChecked || !isCardAccepted}
                    onClick={handleSubmit}
                    btnStyle="secondaryBlack"
                  >
                    SUBMIT PAYMENT
                  </CustomButton>
                </div>
              </>
            )}
          </>
        )}
        {errorMessage && !isCardAccepted && (
          <p className={styles.error}>{errorMessage}</p>
        )}
      </div>
    </>
  );
};

export default CreditCardForm;
