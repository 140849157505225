import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        padding: "60px 0 133px 0",
      },
      content: {
        "& >.MuiBox-root:first-child": {
          width: "370px",
          margin: "0 auto",
          "& p": {
            fontSize: "12px",
            lineHeight: "20px",
          },
          "& .MuiLinearProgress-root": {
            width: "95%",
            margin: "0 auto",
          },
        },
      },
      progressBarRoot: {
        height: "11px !important",
        borderRadius: "7px",
      },
      questionTitle: {
        fontSize: "20px",
        maxWidth: "350px",
      },
      askText: {
        fontSize: "13px",
        marginBottom: "30px",
      },
      buttonsBox: {
        marginTop: "82px",
      },
      backBtn: {
        fontSize: "15px!important",
        padding: "9px 27px",
      },
      nextBtn: {
        fontSize: "15px!important",
        padding: "9px 27px",
      },
      popoverPaper: {
        maxWidth: "300px",
        textAlign: "center",
      },
      description: {
        fontSize: "15px",
        maxWidth: "350px",
        marginTop: "28px",
        marginBottom: "0",
      },
    })
  )
);
