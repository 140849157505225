import React from "react";
import {
  Box,
  Typography,
  Button as MuiButton,
  Popover,
  Stack,
} from "@mui/material";
import classNames from "classnames";
import LinearWithValueLabel from "../../../../../components/LinearProgressWithLabel";

import {Offering} from "../../../../../types/Offerings";

import useAssetInfoFacade from "./useAssetInfoFacade";
import {useStyles} from "./styles/";

import {LinkElement} from "./LinkElement/LinkElement";
import {Button} from "../../../../../components/simpleComponents/Button";
import style from './style.module.scss'
import {CustomButton} from "../../../../../components/UIComponents/Button";
import {useNavigate} from "react-router-dom";

export interface AssetInfoProps {
  offer: Offering;
  onBuySharesClick: () => void;
}

const AssetInfo: React.FC<React.PropsWithChildren<AssetInfoProps>> = function (
  props
): JSX.Element {
  const [
    name,
    funded,
    totalShares,
    unitPrice,
    popoverRef,
    popoverContainerRef,
    popoverAnchor,
    open,
    onImageMouseEnter,
    onImageMouseLeave,
    desktop,
    isBuyButtonDisabled,
  ] = useAssetInfoFacade(props);

  const {
    btnStack,
    soldOutBtn,
    browseAssetsBtn,
  } = useStyles();

  const navigate = useNavigate();

  return (
    <div className={style.container}>
      {/*<Box className={progressBarContainer}>
        <Box className={progressBarContent}>
          <Box className={nameTitleBox}>
            <Typography className={nameTitle}>{name}</Typography>
          </Box>
          { (props.offer?.status !== "soldPrivately" && props.offer?.status !== "SoldOut") && (
              <LinearWithValueLabel
                  progress={funded}
                  postPercentLabel="funded"
                  classes={{
                    root: progressBarOne,
                    bar1Determinate: progressBarRoot,
                  }}
                  mainBoxSx={{
                    marginBottom: "0px",
                  }}
              />)
          }
        </Box>
      </Box>*/}
      {props.children}
      <h2>{name}</h2>
      <hr/>
      <div className={style.infoContainer}>
        <h6 className={style.deal}>DEAL SHEET</h6>
        <LinkElement offer={props.offer}
                     name="download"
                     btnStyle={'secondary'}
                     label={desktop ? 'DOWNLOAD' : 'DOWNLOAD DEAL SHEET'}/>
      </div>
      <div className={style.infoContainer}>
        <h6>OFFERING CIRCULAR</h6>
        <LinkElement offer={props.offer}
                     type="secfiling"
                     name="SEC"
                     btnStyle={'sec'}
                     label={'SEC'}/>
      </div>
      <div className={style.infoContainer}>
        <h6>THIRD PARTY REPORTS</h6>
        <div className={style.links}>
          <LinkElement offer={props.offer} name="GIA" btnStyle={'gia'}/>
          <LinkElement offer={props.offer} name="IGI" btnStyle={'igi'}/>
          <LinkElement offer={props.offer} name="FCRF" btnStyle={'fcrf'}/>
          <LinkElement offer={props.offer} name="ESG" btnStyle={'esg'} label={'ESG'}/>
        </div>
      </div>
      <hr/>

      <div className={style.priceContainer}>
        <div className={style.infoContainer}>
          <h6>TOTAL ASSET VALUE</h6>
          <h4>${totalShares}</h4>
        </div>
        <div className={style.infoContainer}>
          <h6>SHARE PRICE</h6>
          <h4>${unitPrice}</h4>
        </div>
      </div>

      {props.offer?.status !== "SoldOut" ? (
        props.offer?.status === "soldPrivately" ? (
          <div className={style.btnContainer}>
            <CustomButton btnStyle={'primary'} disabled={true}>
              SOLD PRIVATELY
            </CustomButton>
            <CustomButton btnStyle={'primary'} onClick={() => navigate("/")}>
              BROWSE MORE ASSETS
            </CustomButton>
          </div>
        ) : (
          <div className={style.btnContainer}>
            <CustomButton
              onClick={props.onBuySharesClick}
              disabled={isBuyButtonDisabled}
              /*className={classNames(buySharesBtn, {
                [buySharesBtnDisabled]: isBuyButtonDisabled,
              })}*/
              btnStyle={'primary'}
            >
              BUY SHARES
            </CustomButton>
            <CustomButton btnStyle={'primary'} onClick={() => navigate("/")}>
              BROWSE MORE ASSETS
            </CustomButton>
            {/*<img
              src="/logo/fee_icon.svg"
              onMouseEnter={onImageMouseEnter}
              onMouseLeave={onImageMouseLeave}
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              alt=""
              className={feeIcon}
            />*/}
            {/*<Box ref={popoverContainerRef}>
              <Popover
                id="mouse-over-popover"
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                disableRestoreFocus
                onMouseLeave={onImageMouseLeave}
                sx={{
                  pointerEvents: "none",
                }}
                anchorOrigin={{
                  vertical: desktop ? "center" : "bottom",
                  horizontal: "right",
                }}
                transformOrigin={
                  desktop
                    ? {vertical: "center", horizontal: "left"}
                    : {vertical: "top", horizontal: "right"}
                }
                PaperProps={{
                  ref: popoverRef,
                  className: popoverPaper,
                }}
                container={popoverContainerRef.current}
              >
                <Stack direction="row" spacing={1}>
                  <Typography className={popoverTitle}>
                    Management Fee:
                  </Typography>
                  <Typography className={popoverText}>75 bps</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography className={popoverTitle}>
                    Performance Fee:
                  </Typography>
                  <Typography className={popoverText}>
                    20% of Net Profits (8% hurdle)
                  </Typography>
                </Stack>
                <Box className={popoverTriangle}/>
              </Popover>
            </Box>*/}
          </div>
        )
      ) : (
        <Stack
          className={btnStack}
          direction={{xs: "column", sm: "row"}}
          spacing={2}
        >
          <Button className={soldOutBtn} view="primary" disabled={true}>
            SOLD OUT
          </Button>
          <Button className={browseAssetsBtn} view="primary" href="/">
            BROWSE MORE ASSETS
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default AssetInfo;
