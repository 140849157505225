import { BankAccountData, CreditCardData } from "../../../../../types/Account";

type CardTypes = "MC" | "VI" | "DI" | "AMEX" | "DIN";

interface BankInfo {
  label: string;
  numberEnd: string;
}

interface CardInfo {
  url: string;
  numberEnd: string;
}

/**
 * return url from type card
 * @param type
 * @returns
 */

const getCardUrl = (type: CardTypes): string => {
  const urls: Record<CardTypes, string> = {
    MC: "/logo/master.svg",
    VI: "/logo/visa.svg",
    DI: "/logo/discover.svg",
    DIN: "/logo/diners.svg",
    AMEX: "/logo/amex.svg",
  };

  return urls[type];
};

/**
 * return 4 digits from card/bank acc number
 * @param value
 * @returns
 */
const getLastFourDigitFromNumber = (value: string): string =>
  value
    ? value
        .split("")
        .filter((digit) => digit !== "*")
        .join("")
    : "";

/**
 * make simple card structure
 * @param cardData
 * @returns
 */
export const getCardInfo = (
  cardData: CreditCardData | undefined
): CardInfo | undefined =>
  cardData
    ? {
        url: getCardUrl(cardData.cardType as CardTypes),
        numberEnd: getLastFourDigitFromNumber(cardData.creditCardNumber),
      }
    : undefined;

/**
 * make simple bank structure
 * @param bankData
 * @returns
 */
export const getBankInfo = (
  bankData: BankAccountData | undefined
): BankInfo | undefined =>
  bankData
    ? {
        label: "Bank Account",
        numberEnd: getLastFourDigitFromNumber(bankData.AccountNumber),
      }
    : undefined;
