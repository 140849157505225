import Counter from "components/simpleComponents/Counter/Counter";
import { InputField } from "components/simpleComponents/Input/InputField/InputField";

import { SHARES_QUANTITY } from "../../AccreditationForm.data";

import style from "./PurchaseCounter.module.scss";

type PurchaseCounterProps = {
  securitiesNumber: number;
  handleCountChange: (newCount: number) => void;
  isError?: boolean;
  minimumNumberOfShares: number;
};

const PurchaseCounter = ({
  securitiesNumber,
  handleCountChange,
  minimumNumberOfShares,
}: PurchaseCounterProps) => {
  return (
    <div className={style.purchaseContainer}>
      <div className={style.counterContainer}>
        <span className={style.smallTitle}>Number of shares</span>
        <Counter
          initialValue={securitiesNumber}
          handleOtherCountChange={handleCountChange}
          inputColor="#fff"
          borderColor="#7B7B8F"
        />
      </div>
      {securitiesNumber < minimumNumberOfShares && (
        <span style={{ color: "#FF0000", marginLeft: "10px" }}>
          The minimal number of shares is {minimumNumberOfShares}!
        </span>
      )}
      {securitiesNumber > SHARES_QUANTITY.MAX && (
        <span style={{ color: "#FF0000", marginLeft: "10px" }}>
          The maximum number of shares is {SHARES_QUANTITY.MAX}!
        </span>
      )}
      <div className={style.counterContainer}>
        <span className={style.smallTitle}>The aggregate purchase price</span>
        <InputField.Black
          placeholder="PHONE NUMBER"
          name="phoneNumber"
          onChange={() => {}}
          value={`${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(securitiesNumber * 2500)}`}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default PurchaseCounter;
