import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import JewelryImage from "../../../assets/images/Jewelry.png";
import { Link } from "react-router-dom";

const PasswordResetDone = () => {
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  return (
    <>
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: isMobileScreen ? "24px" : "0px",
          paddingTop: isMobileScreen ? "24px" : "0px",
        }}
        sx={{ maxWidth: "515px", margin: "0 auto" }}
      >
        <Typography
          component="h2"
          variant="h2"
          fontFamily={"Telugu MN"}
          lineHeight={"36.43px"}
          align="center"
          letterSpacing={"2px"}
          fontSize={"30px"}
          textTransform={"uppercase"}
          color={"#fff"}
          paddingBottom={isMobileScreen ? "0px" : "30px"}
        >
          RESET LINK SENT
        </Typography>
        <Typography
          variant="h6"
          color="#fff"
          fontSize={"20px"}
          lineHeight={"25.64px"}
          fontWeight={400}
          align="center"
          paddingBottom={"30px"}
          maxWidth={"423px"}
          margin={"0 auto"}
        >
          Please check your email and click the link to reset your password.
        </Typography>
        {/* <Link
          to="/login"
          style={{
            borderRadius: "50px",
            width: "140px",
            height: "41px",
            textDecoration: "none",
            color: "#000",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          GO TO SIGN IN
        </Link> */}
      </Box>
      <Box>
        <img
          style={{ maxHeight: isMobileScreen ? "28vh" : "99vh" }}
          src={JewelryImage}
          alt="JewelryImage"
          width={"100%"}
          height={"100%"}
        />
      </Box>
    </>
  );
};

export default PasswordResetDone;
