type HeaderArrowTopProps = {
  color?: string;
};

const HeaderArrowTop: React.FC<HeaderArrowTopProps> = ({ color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
    >
      <path
        d="M3.59656 2.16772C3.8197 1.94458 4.18208 1.94458 4.40522 2.16772L7.83264 5.59514C8.05579 5.81828 8.05579 6.18066 7.83264 6.4038C7.60951 6.62694 7.24713 6.62694 7.02399 6.4038L4 3.37981L0.976012 6.40202C0.752873 6.62516 0.390494 6.62516 0.167355 6.40202C-0.0557849 6.17888 -0.0557849 5.8165 0.167355 5.59336L3.59478 2.16594L3.59656 2.16772Z"
        fill={color}
      />
    </svg>
  );
};

export default HeaderArrowTop;
