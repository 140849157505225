import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ControlledDropdown from './ControlledDropdown/ControlledDropdown';
import { CustomButton } from 'components/UIComponents/Button';
import { ControledField } from './ControledField';
import ControlledFileUpload from './ControlledFileUpload/ControlledFileUpload';
import { Preloader } from 'components/simpleComponents/Preloader';

import { uploadIdentityFile } from 'apis/backend';

import { documentFormSchema } from '../schema';
import {
  documentTypesNonResident,
  documentTypesResident,
  DOMICILES,
  STEPS,
  FIELD_NAMES,
} from '../AccreditationForm.data';
import { DocumentFormValues } from '../AccreditationForm.type';

import style from './style.module.scss';

type LoadDocumentsFormProps = {
  citizenship: string;
  guid: string;
  setStep: (step: STEPS) => void;
};

const LoadDocumentsForm = ({
  citizenship,
  guid,
  setStep,
}: LoadDocumentsFormProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<DocumentFormValues>(documentFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      isIdentitySide2Required: false,
      documentNumber: '',
    },
  });

  const watchDocumentType = watch(FIELD_NAMES.DOCUMENT_TYPE);
  const watchIdentitySide1 = watch(FIELD_NAMES.IDENTITY_SIDE1) as File;
  const watchIdentitySide2 = watch(FIELD_NAMES.IDENTITY_SIDE2) as File;

  const documentTypeCondition = citizenship === DOMICILES.NON_US_CITIZEN;

  const secondaryDocumentPlaceholder = documentTypeCondition
    ? 'ID Number'
    : "Driver's License Number";

  const currentDocumentType = documentTypeCondition
    ? documentTypesNonResident
    : documentTypesResident;

  async function onSubmit(formData: DocumentFormValues) {
    try {
      setIsLoading(true);

      const data = await uploadIdentityFile(
        guid,
        formData.identitySide1,
        formData.identitySide2
      );

      setIsLoading(false);

      if (data[0].statusCode === '101') {
        setStep(STEPS.PDF_PREVIEW);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    reset({
      ...getValues(),
      isIdentitySide2Required: watchDocumentType === 'ID',
    });
  }, [getValues, reset, watchDocumentType]);

  return (
    <>
      {isLoading ? (
        <Preloader isWhite />
      ) : (
        <div className={style.content}>
          <h2>THE TIARA OFFERING</h2>
          <p className={style.text}>
            The following information is required to verify your status as an
            accredited investor
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={style.row}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <ControlledDropdown
                isError={!!errors.documentType}
                errorMessage={errors.documentType?.message}
                placeholderColor="#fff"
                dropdownOptions={currentDocumentType}
                name={FIELD_NAMES.DOCUMENT_TYPE}
                control={control}
                initValue={{
                  value: 'document',
                  label: 'Choose document type',
                }}
                placeholder="Choose document type"
              />
              <ControledField
                {...register(FIELD_NAMES.DOCUMENT_NUMBER)}
                isError={!!errors.documentNumber}
                errorMessage={errors.documentNumber?.message}
                placeholder={
                  watchDocumentType === currentDocumentType[0].value
                    ? 'Passport Number'
                    : secondaryDocumentPlaceholder
                }
                control={control}
              />
            </div>
            {watchDocumentType &&
            watchDocumentType !== currentDocumentType[0].value ? (
              <div className={style.idContainer}>
                <div className={style.uploadFileBlock}>
                  <p>FRONT</p>
                  <ControlledFileUpload
                    control={control}
                    name={FIELD_NAMES.IDENTITY_SIDE1}
                    file={watchIdentitySide1}
                  />
                </div>
                <div className={style.uploadFileBlock}>
                  <p>BACK</p>
                  <ControlledFileUpload
                    control={control}
                    name={FIELD_NAMES.IDENTITY_SIDE2}
                    file={watchIdentitySide2}
                  />
                </div>
              </div>
            ) : (
              <div className={style.uploadFileBlock}>
                <p>PASSPORT</p>
                <ControlledFileUpload
                  control={control}
                  name={FIELD_NAMES.IDENTITY_SIDE1}
                  file={watchIdentitySide1}
                />
              </div>
            )}
            {(errors.identitySide1 || errors.identitySide2) && (
              <p className={style.errorDocsMessage}>
                {errors.identitySide1?.message || errors.identitySide2?.message}
              </p>
            )}
            <div className={style.center}>
              <CustomButton type="submit" btnStyle="secondaryBlack">
                SUBMIT
              </CustomButton>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default LoadDocumentsForm;
