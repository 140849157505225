import { Box } from '@mui/material';
import { CloseIcon } from 'assets/icons/close';
import { FC, useEffect, useState } from 'react';
import { FaqProps } from './Faq.type';

const FaqSelectOption: FC<FaqProps> = ({ option, selectedOption, onClick }) => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (selectedOption?.question === option.question) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [selectedOption]);
  const { question, answer } = option;
  return (
    <Box
      padding={'16px 0px'}
      sx={{ cursor: 'pointer', borderBottom: '1px solid white' }}
      position={'relative'}
      zIndex={1}
      onClick={(e) => {
        onClick(option);
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
        height={'100%'}
      >
        <p
          style={{
            fontSize: '20px',
            lineHeight: '25px',
            letterSpacing: '1px',
            fontFamily: 'Futura PT Book',
            color: '#fff',
          }}
          dangerouslySetInnerHTML={{ __html: question.toUpperCase() }}
        />
        <Box
          style={{
            transition: 'transform 0.3s ease',
            transform: `${isActive ? 'rotate(90deg)' : 'rotate(45deg)'}`,
            maxWidth: 10,
            maxHeight: 13,
          }}
        >
          <CloseIcon width={15} height={18} />
        </Box>
      </Box>
      <Box
        sx={{
          opacity: isActive ? 1 : 0,
          zIndex: isActive ? 1 : 0,
          transition:
            'max-height 0.5s ease , opacity 0.5s ease, margin-top 0.5s ease',
          maxHeight: isActive ? '130vh' : '0px',
          marginTop: isActive ? '16px' : '1px',
        }}
      >
        {answer(isActive)}
      </Box>
    </Box>
  );
};

export default FaqSelectOption;
