import { useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';

import { CustomButton } from 'components/UIComponents/Button';
import { BecomeVipModal } from '../components/BecomeVipModal';
import { ImageModal } from './components/ImageModal/ImageModal';
import { TierList } from './components/TierList/TierList';

import { classes } from '../styles';

import { settings, sliderData } from './Slider.data';
import styles from './Auth.module.scss';

export const Auth = () => {
  const isMobileScreen = useMediaQuery('(max-width:776px)');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const nextImage = () => {
    if (selectedImage !== null) {
      if (selectedImage === sliderData.length - 1) {
        setSelectedImage(0);
      } else {
        setSelectedImage(selectedImage + 1);
      }
    }
  };

  const prevImage = () => {
    if (selectedImage !== null) {
      if (selectedImage === 0) {
        setSelectedImage(sliderData.length - 1);
      } else {
        setSelectedImage(selectedImage - 1);
      }
    }
  };

  return (
    <>
      <Box sx={classes.heroWrapper(isMobileScreen)}>
        <Box sx={classes.heroContainer(isMobileScreen)}>
          <Typography variant="h1" sx={classes.title(isMobileScreen)}>
            LUXUS VIP EXPERIENCES
          </Typography>
          <Typography variant="h5" sx={classes.text(isMobileScreen)}>
            LUXUS VIP offers investors exclusive access to exquisite experiences
            and intimate events worldwide, immersed in the luxury of
            high-jewelry, thoroughbred horse racing, fine wine and spirits, and
            beyond. VIP members receive invitations to prestigious gatherings,
            including private dinners, premier sporting events, and early
            notifications of upcoming LUXUS IPOs. Register here to discover more
            about LUXUS VIP and elevate your lifestyle.
          </Typography>
          <CustomButton
            btnStyle={'iconBtnTransparent'}
            onClick={() => setModalVisible(true)}
          >
            LEARN MORE
          </CustomButton>
        </Box>
        <div className={styles.background} />
      </Box>
      {/* <Box sx={classes.vipTierListWrapper(isMobileScreen)}>
        <Box sx={classes.vipTierListContainer}>
          <Box>
            <Typography variant="h2" sx={classes.subTitle}>
              {isMobileScreen ? "WELCOME TO LUXUS!" : "EXPLORE OUR VIP TIERS"}
            </Typography>
            <Typography
              sx={classes.vipTierListText}
              style={{ marginTop: "24px", maxWidth: "660px" }}
            >
              {isMobileScreen
                ? "Please finish creating your profile and we will add you to our complimentary VIP tier, Sapphire. As you begin investing, you will move into our different LUXUS VIP tiers. VIP tiers below for future exploration."
                : "THE LUXUS VIP INVESTMENT EXPERIENCE is an exclusive membership that includes access to LUXUS investment opportunities, exclusive content and experiences available only to our members. Members must maintain one of the below investment tiers across the LUXUS offerings in order to gain access."}
            </Typography>
          </Box>
          <TierList />
        </Box>
        <Typography sx={classes.italicText}>
          * Offers and experiences are subject to change.
        </Typography>
      </Box> */}
      <Box
        sx={{
          width: '100%',
          overflowX: 'hidden',
          marginBottom: '10px',
          marginTop: '16px',
        }}
      >
        <Slider {...settings}>
          {sliderData.map((item, index) => (
            <Box
              component={'img'}
              src={item}
              key={index}
              sx={classes.sliderImage}
              onClick={() => {
                setSelectedImage(index);
              }}
            />
          ))}
        </Slider>
      </Box>
      <BecomeVipModal
        visible={modalVisible}
        setModalVisible={setModalVisible}
        isAuth={true}
      />
      {selectedImage !== null && (
        <ImageModal
          image={sliderData[selectedImage]}
          nextImage={nextImage}
          prevImage={prevImage}
          setVisible={() => setSelectedImage(null)}
        />
      )}
    </>
  );
};
