import { Card, CardMedia, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PaymentType } from '../../../types/enums/PaymentType';
import { Offering } from '../../../types/Offerings';
import { OfferingFormater } from '../../../utils/OfferingFormatter';
import themeData from '../../../utils/theme';

export interface AssetDetailsProps {
  currentOffering?: Offering;
  title: string;
  subTitle?: string;
  message: string | JSX.Element;
  quantity: number;
  isIntent?: boolean;
  paymentMethod?: PaymentType;
  paymentShortId?: string;
}

const paymentMethodNames = {
  [PaymentType.BANK]: 'Bank Account',
  [PaymentType.CARD]: 'Credit Card',
  [PaymentType.WIRE]: 'Wire Transfer',
};
export const AssetReviewDetails = ({
  currentOffering,
  quantity,
  title,
  subTitle,
  message,
  isIntent = false,
  paymentMethod,
  paymentShortId,
}: AssetDetailsProps) => {
  if (!currentOffering) return null;

  var itemFormatted = OfferingFormater(currentOffering, 'USD', true);
  var summary = [
    {
      title: 'Asset',
      value: currentOffering.name,
    },
    {
      title: 'No. of Shares',
      value: quantity,
    },
    {
      title: 'Total Value',
      value: itemFormatted.totalFor(quantity),
    },
  ];
  if (paymentMethod && !isIntent) {
    const method = paymentMethodNames[paymentMethod];
    summary.push({
      title: 'Payment type',
      value: `${method}${paymentShortId ? ' ' + paymentShortId : ''}`,
    });
  }
  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        padding: '28px',
        paddingTop: '14px',
        width: { xs: '100%', md: '669px' },
      }}
    >
      {/* Top Line  */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingBottom: '14px',
          paddingTop: { xs: '10px', md: 0 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            color: themeData.GreyLabel,
            justifyContent: 'center',
            height: { xs: 'auto', md: '57px' },
            alignItems: 'center',
          }}
        >
          <Typography
            component="h6"
            align="left"
            sx={{
              color: themeData.BlackColor,
              fontSize: '30px',
            }}
          >
            {title}
            {/* {isIntent ? 'Review Your Indication of Interest' : 'Review Your Purchase'} */}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        {/* Image */}
        <Card
          sx={{
            width: { xs: '100%', md: '225px' },
            marginRight: '28px',
            boxShadow: 'none',
            borderRadius: '0px',
            border: '1px solid ' + themeData.BorderColor,
            marginY: { xs: '10px', md: 0 },
          }}
        >
          <CardMedia
            component="img"
            image={currentOffering?.image?.url}
            alt={currentOffering?.image?.label}
          />
        </Card>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'left',
            marginY: 'auto',
          }}
        >
          {subTitle && (
            <Typography
              component="h6"
              align="left"
              sx={{
                fontFamily: themeData.DefaultFontHeavy,
                color: themeData.HighlightColor,
                fontSize: '20px',
                fontWeight: 500,
                marginTop: { xs: '1rem', md: 0 },
                marginBottom: '0.5rem',
              }}
            >
              {subTitle}
            </Typography>
          )}
          {summary.map((s, i) => (
            <Box
              key={i}
              sx={{
                marginY: '5px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography
                component="h6"
                align="left"
                sx={{
                  color: themeData.BlackColor,
                  fontSize: '20px',
                  fontWeight: 400,
                  width: '120px',
                  fontFamily: themeData.DefaultFontBook,
                }}
              >
                {s.title}
              </Typography>
              <Typography
                component="h6"
                align="left"
                sx={{
                  color: themeData.BlackColor,
                  fontSize: '20px',
                  fontFamily: themeData.DefaultFontDemi,
                  fontWeight: 500,
                  marginLeft: '.5em',
                }}
              >
                {s.value}
              </Typography>
            </Box>
          ))}
          <Box
            sx={{
              marginTop: '1rem',
              maxWidth: ' 363px',
            }}
          >
            <Typography
              sx={{
                fontFamily: themeData.DefaultFontBook,
                fontSize: '18px',
                color: themeData.HighlightColor,
              }}
            >
              {message}
              {/* Agreeing to the above opts you into future communications from LUXUS about completing your investment in
              this asset. */}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
