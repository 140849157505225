import axios from "axios";

const baseurl = process.env.REACT_APP_BACKEND_BASE_URL || "";

type HubSpotProfileData = {
  email: string;
  firstName: string;
  lastName: string;
  ipAddress: string;
  trackingCookie: string;
  pageUri: string;
  pageName: string;
  phoneNumber?: string;
  citizenshipStatus?: string;
  sharesQty?: number;
  reserveAmount?: string;
};

interface HasEmail {
  email: string;
}

export const getHubSpotCookie = () => {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
};

export const sendHubSpotData = async <T extends HasEmail>(
  userData: T,
  formId: string
) => {
  const response = await axios.post(
    `${baseurl}/v1/hubspot/forms/${formId}/submit`,
    { ...userData, email: userData.email.replaceAll(" ", "") },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_TOKEN}`,
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};
