const LuxusLogoNoText = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <g clipPath="url(#clip0_5761_2755)">
        <path
          d="M0.0791016 16.4253L7.51895 9.06739L13.5539 15.02L9.08356 19.4411L6.09804 16.4884L7.50299 15.099L9.08356 16.6621L10.7599 15.02L7.51895 11.8148L2.87304 16.4095L9.00373 22.4727L22.7978 8.83055L16.6671 2.76739L12.0371 7.34634L15.2143 10.4884L16.8108 8.90949L15.2781 7.3937L16.6831 6.00423L19.6047 8.8937L15.1983 13.2832L9.22725 7.37791L16.6671 0.0200195L33.319 16.4884L25.8791 23.8463L19.9081 17.9411L24.2985 13.599L27.2202 16.4884L25.8152 17.8779L24.2826 16.3621L22.686 17.9411L25.8631 21.0832L30.4931 16.5042L24.3624 10.4411L10.5683 24.0832L16.699 30.1463L21.3449 25.5516L18.104 22.3463L16.4276 24.0042L18.0082 25.5674L16.6032 26.9569L13.6177 24.0042L18.088 19.5832L24.1229 25.5516L16.6831 32.9095L0.0791016 16.4253Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_5761_2755">
          <rect width="33" height="33" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LuxusLogoNoText;
