import { height } from "@mui/system";
import { themeData } from "../../../../../../utils/theme";

export const commonStyles = {
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "80%",
    maxWidth: "900px",
    transform: "translate(-50%, -50%)",
    padding: "43px 23px 23px 23px",
    backgroundColor: "#F4F4F4",
    borderRadius: "5px",
  },
  paperFullScreen: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "97.5%",
    maxWidth: "100%",
    height: "92%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
    padding: "43px 23px 23px 23px",
    backgroundColor: "#F4F4F4",
    borderRadius: "5px",
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0",
    borderTop: "1px solid #DFDFE3",
  },
  headerBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-20px",
  },
  textBox: {
    position: "relative",
  },
  textArea: {
    width: "100%",
    height: "600px",
    marginBottom: "17px",
    fontWeight: "450",
    fontSize: "20px",
    lineHeight: "26px",
    padding: "0 120px",
    boxSizing: "border-box",
    resize: "none",
    scrollbarFaceColor: "#ff8c00",
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      height: "15px",
      width: "7px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "white",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(159, 159, 159)",
      borderRadius: "4px",
    },
  },
  closeBtn: {
    position: "absolute",
    right: "-15px",
    top: "2px",
    width: "28px",
    height: "28px",
    color: "#7B7B8F",
    backgroundColor: "transparent",
  },
  doneBtn: {
    height: "58px",
  },
  clearBtn: {
    height: "58px",
    backgroundColor: themeData.BlackColor,
  },
  dateField: {
    "& input": {
      fontWeight: "300",
      fontSize: "20px",
      lineHeight: "26px",
      textAlign: "center",
      backgroundColor: themeData.WhiteColor,
      height: "26px!important",
    },
  },
  signField: {
    flexGrow: "1",
    "& input": {
      fontWeight: "450",
      fontSize: "20px",
      lineHeight: "26px",
      fontStyle: "italic",
      backgroundColor: themeData.WhiteColor,
      height: "26px!important",
    },
  },
  expandIcon: {
    zIndex: "1",
    position: "absolute",
    bottom: "25px",
    right: "7px",
    cursor: "pointer",
  },
  errorText: {
    fontSize: "20px",
    color: "#EC3F3F",
    marginTop: "5px",
  },
};

export type ClassesNames = keyof typeof commonStyles;
