import BaseLayout from "layout/BaseLayout/baseLayout";
import Hero from "./components/Hero/Hero";
import IntersectionBlock from "pages/NewAssests/Assets/components/IntersectionBlock/IntersectionBlock";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import MatchHighlights from "./components/MatchHighlights/MatchHighlights";

import { MAIN_URL, ROUTES } from "utils/constants";

const Stream = () => {
  return (
    <BaseLayout pageName="Stream page" pageUri={`${MAIN_URL}${ROUTES.STREAM}`}>
      <div>
        <Hero />
        <IntersectionBlock />
        <VideoPlayer />
        <MatchHighlights />
      </div>
    </BaseLayout>
  );
};

export default Stream;
