import { themeData } from "../../../../../../utils/theme";

export const commonStyles = {
  container: {
    backgroundColor: "#000000",
    borderRadius: "6px",
    padding: "33px 21px",
    width: "100%",
    maxWidth: "600px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "450",
    color: themeData.WhiteColor,
    marginBottom: "17px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "17px",
  },
  text: {
    fontSize: "18px",
    fontWeight: "450",
    color: themeData.WhiteColor,
    whiteSpace: "nowrap",
  },
  boldText: {
    fontSize: "18px",
    fontWeight: "700",
    color: themeData.WhiteColor,
    whiteSpace: "nowrap",
  },
  changeBtn: {
    color: "#6890FF",
    fontSize: "18",
    fontWeight: "300",
    textDecoration: "none",
  },
  hr: {
    backgroundColor: "#4E4E56",
    border: "none",
    height: "3px",
    marginBottom: "32px",
  },
  img: {
    height: "32px",
    width: "33px",
    marginRight: "15px",
  },
  label: {
    color: "black",
    fontSize: "18",
    fontWeight: "400",
    marginRight: "50px",
  },
  labelLong: {
    paddingRight: "30px",
  },
};

export type ClassesNames = keyof typeof commonStyles;
