/**
 *
 * @param email string The email address to check.
 * @returns boolean "true" if a valid email, otherwise "false".
 */
export const validateEmail = (email: string) => {
  /* eslint-disable no-control-regex */
  return !!email.match(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
  );
};

export const validateSSN = (ssn: string) => {
  if (!ssn) {
    return "This field is required!";
  } else if (!/^\d{3}-\d{2}-\d{4}$/.test(ssn)) {
    return "SSN format is XXX-XX-XXXX";
  }
  return "";
};

export function validateDate(date: string) {
  const regex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
  return regex.test(date);
}
