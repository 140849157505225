import React, {ChangeEvent, FC, useState} from "react";

import style from "./style.module.scss";
import {useBuyAsSelectFieldFacade} from "../BuyAsSelectField/useBuyAsSelectFieldFacade";

interface BuyFieldProps {
  setAccountId: (value: string) => void;
}

export const SelectIdField: FC<BuyFieldProps> = function (
  props
): JSX.Element {
  const {options, value, onChange} = useBuyAsSelectFieldFacade(props);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (newValue: string) => {
    toggleDropdown();
    onChange({target: {value: newValue}} as ChangeEvent<{ value: string }>);
  };

  return (
    <div className={style.container}>
      <div className={style.content}>
        <h3>I am purchasing these shares as...</h3>
        <div className={isOpen ? style.open : style.dropdownContainer}>
          <div className={style.dropdownTrigger} onClick={toggleDropdown}>
            {options.find(option => option.value === value)?.label || value}
          </div>
          {isOpen && (
            <div className={style.dropdownMenu}>
              {options.map(({value, label}) => (
                <div
                  key={value}
                  className={style.dropdownItem}
                  onClick={() => handleOptionClick(value)}
                >
                  {label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};