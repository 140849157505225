import { Add, Remove } from '@mui/icons-material';
import { Box, IconButton, SvgIcon, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useStyles } from './styles';
import { styled } from '@mui/styles';

interface QtyProps {
  count: number;
  onDecClick: () => void;
  onIncClick: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
export const Qty: FC<QtyProps> = ({
  count,
  onDecClick,
  onIncClick,
  onChange,
}) => {
  const { container, decBtn, incBtn, text, icon } = useStyles();
  return (
    <Box className={container}>
      <IconButton className={decBtn} onClick={onDecClick}>
        <SvgIcon className={icon}>
          <Remove />
        </SvgIcon>
      </IconButton>
      <input className={text} value={count} onChange={(e) => onChange(e)} />
      <IconButton className={incBtn} onClick={onIncClick}>
        <SvgIcon className={icon}>
          <Add />
        </SvgIcon>
      </IconButton>
    </Box>
  );
};
