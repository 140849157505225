import { themeData } from "../../../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    overflow: "hidden",
    "& .slick-dots": {
      bottom: "10px",
      "& li": {
        height: "10px",
        width: "10px",
        margin: '0 10px 0 0'
      },
      "& li button:before": {
        backgroundColor: "transparent",
        color: "transparent",
        border: "1px solid #7B7B8F",
        borderRadius: "50%",
        display: "inline-block",
        height: "9px",
        width: "9px",
      },
      "& li.slick-active button:before": {
        backgroundColor: "#7B7B8F",
        border: "1px solid #7B7B8F",
      },
    },
    "& .slick-slide > div": {
      padding: "0",
    },
  },
  thumbs: {
    width: "77px",
    height: "380px",
    marginRight: "12px",
    boxSizing: "border-box",
    "& img": {
      width: "75px",
      cursor: "pointer",
      border: "1px solid #F2F2F0",
      marginBottom:'2px'
    },
  },
  preview: {
    width: "329px",
    height: '330px',
    textAlign: "center",
    border: '1px solid #F2F2F0',
    padding: 0,
    marginBottom: '16px',
    "& img": {
      maxWidth: "330px",
      width: "100%",
      cursor: "pointer",
    },
    "& video": {
      maxWidth: "330px",
      width: "100%",
      cursor: "pointer",
    },
  },
  // Media Query for Mobile
  '@media (max-width: 576px)': {
    preview: {
      width: '99%',
      maxWidth: '350px',
      height: '100%',
      maxHeight: '350px',
      "& img": {
        maxWidth: "350px",
        width: "100%",
        cursor: "pointer",
      },
      "& video": {
        maxWidth: "350px",
        width: "100%",
        cursor: "pointer",
      },
    },
  },
};

export type ClassesNames = keyof typeof commonStyles;
