import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import ControlledPhoneDropDown from "components/UIComponents/ControlledPhoneDropDown/ControlledPhoneDropDown";
import { CustomButton } from "components/UIComponents/Button";
import ControlledDropdownSeparate from "components/simpleComponents/ControlledDropdownSeparate/ControlledDropdown";
import { ControledField } from "pages/Accreditation/components/AccreditationForm/components/ControledField";
import CheckboxIconChecked from "assets/icons/CheckboxIconChecked";
import CheckboxIconNotChecked from "assets/icons/CheckboxIconNotChecked";

import { classes } from "pages/Derby/components/styles";

import { AddressGuestProps, ContactsGuestProps } from "../types";

import styles from "./ContactsForm.module.scss";
import {
  DIETARY_NEEDS_OPTIONS,
  TRIP_OPTIONS,
  TRIP_VARIANTS,
} from "./constants";

type ContactsFormProps = {
  setGuest: React.Dispatch<
    React.SetStateAction<ContactsGuestProps | AddressGuestProps | null>
  >;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setTripVariant?: React.Dispatch<React.SetStateAction<TRIP_VARIANTS>>;
  currentStep: number;
};

const ContactsForm = ({
  setGuest,
  setCurrentStep,
  setTripVariant,
  currentStep,
}: ContactsFormProps) => {
  const [isLicenseAgree, setIsLicenseAgree] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState("");

  function changeSelectedDropdown(name: string) {
    name === selectedDropdown
      ? setSelectedDropdown("")
      : setSelectedDropdown(name);
  }

  const checkBoxErrorCondition =
    !isLicenseAgree && isFormTouched && currentStep === 1;

  const isFirstStepCondition = currentStep < 2;

  const yupRequiredString = yup
    .string()
    .trim()
    .required("This field is required");

  const contactsFormSchema = yup.object().shape({
    firstname: yupRequiredString,
    lastname: yupRequiredString,
    email: yup
      .string()
      .email("Email is not valid")
      .required("This field is required"),
    phone: yupRequiredString,
    dietary_needs: yup.string().trim(),
    tripOption: isFirstStepCondition
      ? yup.string().required("This field is required")
      : yup.string(),
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<ContactsGuestProps>({
    resolver: yupResolver(contactsFormSchema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      dietary_needs: "",
      tripOption: "",
    },
  });

  function onSubmit(data: ContactsGuestProps) {
    setIsFormTouched(true);

    if (!isLicenseAgree && isFirstStepCondition) return;

    setGuest((prevState) => ({
      ...prevState,
      ...data,
    }));

    setTripVariant && setTripVariant(getValues("tripOption") as TRIP_VARIANTS);

    setCurrentStep((prev) => prev + 1);
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputsWrapper}>
        <ControledField
          placeholder="FIRST NAME"
          control={control}
          variant="white"
          name="firstname"
          isError={!!errors.firstname}
          errorMessage={errors.firstname?.message}
        />
        <ControledField
          placeholder="LAST NAME"
          control={control}
          variant="white"
          name="lastname"
          isError={!!errors.lastname}
          errorMessage={errors.lastname?.message}
        />
        <ControledField
          placeholder="EMAIL ADDRESS"
          control={control}
          variant="white"
          name="email"
          isError={!!errors.email}
          errorMessage={errors.email?.message}
        />
        <ControlledPhoneDropDown
          control={control}
          name="phone"
          isError={!!errors.phone}
          errorMessage={errors.phone?.message}
          placeholder="PHONE NUMBER"
        />
        {isFirstStepCondition && (
          <ControlledDropdownSeparate
            selectedDropdown={selectedDropdown}
            changeSelectedDropdown={changeSelectedDropdown}
            borderColor="black"
            arrowColor="black"
            control={control}
            dropdownOptions={TRIP_OPTIONS}
            placeholder="TRIP OPTION"
            name="tripOption"
            initValue={{
              label: "TRIP OPTION",
              value: "TRIP OPTION",
            }}
            isError={!!errors.tripOption}
            errorMessage={errors.tripOption?.message}
          />
        )}
        <ControlledDropdownSeparate
          selectedDropdown={selectedDropdown}
          changeSelectedDropdown={changeSelectedDropdown}
          borderColor="black"
          arrowColor="black"
          control={control}
          dropdownOptions={DIETARY_NEEDS_OPTIONS}
          placeholder="DIETARY NEEDS"
          name="dietary_needs"
          initValue={{
            label: "DIETARY NEEDS",
            value: "DIETARY NEEDS",
          }}
          maxHeight="150px"
        />
      </div>
      {isFirstStepCondition && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "100%",
          }}
        >
          <Checkbox
            onClick={() => setIsLicenseAgree((prev) => !prev)}
            value={isLicenseAgree}
            sx={{
              maxHeight: "42px",
              maxWidth: "42px",
              marginRight: "10px",
              marginLeft: "-9px",
              "& rect": { stroke: checkBoxErrorCondition ? "red" : "black" },
            }}
            icon={<CheckboxIconNotChecked />}
            checkedIcon={<CheckboxIconChecked />}
          />
          <Typography sx={classes.text} style={{ textAlign: "start" }}>
            I have read and agree to the{" "}
            <Link target="_blank" style={{ color: "inherit" }} to={"/privacy"}>
              Privacy Policy
            </Link>
            ,{"  "}
            <Link
              target="_blank"
              style={{ color: "inherit" }}
              to={"/termsOfService"}
            >
              Terms of Service
            </Link>
          </Typography>
        </Box>
      )}
      <div className={styles.buttonWrapper}>
        <CustomButton size="long" btnStyle="secondary" type="submit">
          Next step
        </CustomButton>
      </div>
    </form>
  );
};

export default ContactsForm;
