import GarrardRoyalAscot from 'assets/icons/GarrardRoyalAscot';
import JohnArthur from 'assets/icons/JohnArthur';
import LanesEnd from 'assets/icons/LanesEnd';
import QuintessentiallyIcon from 'assets/icons/QuintessentiallyIcon';
import { CustomButton } from 'components/UIComponents/Button';
import TripPrice from '../TripPrice/TripPrice';

import styles from './TripDetails.module.scss';
import { FILE_URL } from './constants';

const TripDetails = () => {
  function handleClick() {
    window.open(FILE_URL, '_blank');
  }

  return (
    <div className={styles.container}>
      <p className={styles.text}>Dates: June 16th - 19th</p>
      <p className={styles.text} style={{ marginTop: '30px' }}>
        Full Itinerary
      </p>
      <div className={styles.pricesWrapper}>
        <TripPrice option="single" price="$15,000" />
        <TripPrice option="couple" price="$25,000" />
      </div>
      <p className={styles.iconSecondHint}>
        Price includes all activities and transport listed in the itinerary.
        Flights and accommodation are not included.
      </p>
      <p className={styles.text}>Dinners Only</p>
      <div className={styles.secondPricesWrapper}>
        <TripPrice
          option="one dinner"
          price="$1,500"
          comment="for each individual dinner"
        />
        <TripPrice
          option="three dinners"
          price="$5,000"
          comment="for all three dinners during Royal Ascot itinerary"
        />
      </div>
      <div className={styles.buttonWrapper}>
        <CustomButton onClick={handleClick} btnStyle="primaryMedium">
          View itinerary
        </CustomButton>
      </div>
      <p className={styles.text}>In Partnership with:</p>
      <div className={styles.iconWrapper}>
        <QuintessentiallyIcon />
      </div>
      <p className={styles.iconText}>Quintessentially</p>
      <p className={styles.iconHint}>on travel and logistics</p>
      <p className={styles.iconsTitle}>Featuring Experiences With:</p>
      <div className={styles.iconsContainer}>
        <GarrardRoyalAscot />
        <JohnArthur />
        <LanesEnd />
      </div>
    </div>
  );
};

export default TripDetails;
