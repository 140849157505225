import { Box, SxProps, Theme } from '@mui/material';
import { marked } from 'marked';
import themeData from '../../../utils/theme';

type PurchaseInstructionsProps = {
  isPurchase?: boolean;
  sx?: SxProps<Theme>;
  text?: string;
};

export const PurchaseInstructions = ({
  isPurchase,
  text,
  sx,
}: PurchaseInstructionsProps) => {
  return (
    <Box
      sx={{
        backgroundColor: themeData.LightBackgroundColor,
        padding: '6px',
        paddingTop: '0px',
        width: { xs: '100%', md: '367px' },
        display: 'flex',
        flexDirection: 'column',
        color: themeData.HighlightColor,
        fontSize: '19px',
        fontWeight: 400,
        ...sx,
      }}
    >
      {text && <div dangerouslySetInnerHTML={{ __html: marked.parse(text) }} />}
    </Box>
  );
};

export default PurchaseInstructions;
