import { themeData } from "../../../../utils/theme";

export const commonStyles = {
  input: {
    "&.MuiFormControl-root": {
      borderRadius: "5px",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: 0,
      fontSize: "20px",
      fontWeight: "300",
    },
    "& .MuiInput-underline::before": {
      content: "none",
    },
  },
  decBtn: {},
};

export type ClassesNames = keyof typeof commonStyles;
