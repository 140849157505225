import Diamond from './assets/diamond.png';
import Dahlia from './assets/dahlia.png';
import Brilliant from './assets/brilliant.png';
import { ButtonStyle } from '../../components/UIComponents/Button';
import Bracelet from '../../assets/images/assets/futureOffer/bracelet.png';
import Sapphire from '../../assets/images/assets/futureOffer/sapphire.png';
import Cushion from '../../assets/images/assets/futureOffer/cushion.png';
import DiamondBlue from '../../assets/images/assets/futureOffer/diamond.png';

export type CardType = {
  name: string;
  shortdesc: string;
  leftDetails: DescriptionType[];
  rightDetails: DescriptionType[];
  btnType: ButtonStyle;
  buttonText: string;
  image: string;
  leftCornerTitle: string;
  value: string;
  price: number;
  factSheetButtonText: string;
  factSheetLink: string;
  //onClick: () => {}
};

type DescriptionType = {
  label: string;
  value: string;
};

export const assets: CardType[] = [
  {
    name: 'THE KWIAT Round Brilliant',
    shortdesc:
      'The Kwiat | LUXUS Round Brilliant is a D-Flawless diamond, which represents the pinnacle of the investment-grade precious gem category.',
    leftDetails: [
      { label: 'Shape', value: 'ROUND BRILLIANT' },
      { label: 'Carats', value: '5' },
      { label: 'Color', value: 'D' },
    ],
    rightDetails: [
      { label: 'Clarity', value: 'FLAWLESS' },
      { label: 'CUT', value: 'TRIPLE EXCELLENT' },
      { label: 'Type', value: 'IIA' },
    ],
    btnType: 'primary',
    buttonText: 'RESERVE SHARES',
    factSheetButtonText: 'FACT SHEET',
    factSheetLink:
      'https://d1p3kxhg87s54m.cloudfront.net/fact-sheets/DFlawless_FactSheet.pdf',
    image: Brilliant,
    leftCornerTitle: 'FEATURED OFFERING',
    price: 250,
    value: '400,000',
  },
  {
    name: 'THE GOLDEN DAHLIA',
    shortdesc:
      'The Golden Dahlia is of the rarest shape, color and size and comparable only to Auction headliners in terms of its quality.',
    leftDetails: [
      { label: 'SHAPE', value: 'EMERALD CUT' },
      { label: 'CARATS', value: '11.74 CT' },
      { label: 'COLOR ORIGIN', value: 'NATURAL' },
    ],
    rightDetails: [
      { label: 'COLOR', value: 'FANCY VIVID YELLOW' },
      { label: 'CLARITY', value: 'VVS1' },
      { label: 'GIA REPORT GRADE', value: '#4708020001' },
    ],
    btnType: 'inlineGrey',
    buttonText: 'VIEW ASSET',
    factSheetButtonText: 'FACT SHEET',
    factSheetLink:
      'https://d1p3kxhg87s54m.cloudfront.net/fact-sheets/TheGoldenDahlia_FactSheet.pdf',
    image: Dahlia,
    leftCornerTitle: 'SOLD PRIVATELY',
    price: 300,
    value: '1.5M',
  },
  {
    name: 'RARE ARGYLE PINK DIAMOND',
    shortdesc:
      'RARE ARGYLE PINK DIAMOND\n' +
      'Sourced from the now closed Argyle Mine in Perth Australia, this diamond is of the highest caliber possible for Argyle Pink diamonds.',
    leftDetails: [
      { label: 'SHAPE', value: 'OVAL BRILLIANT' },
      { label: 'CARATS', value: '0.54' },
      { label: 'COLOR ORIGIN', value: 'NATURAL' },
    ],
    rightDetails: [
      { label: 'COLOR', value: 'FANCY VIVID PURPLISH PINK' },
      { label: 'CLARITY', value: 'VS2' },
      { label: 'ORIGIN / MINE', value: 'ARGYLE DIAMOND MINE' },
    ],
    btnType: 'inlineGrey',
    buttonText: 'VIEW ASSET',
    factSheetButtonText: 'FACT SHEET',
    factSheetLink:
      'https://d1p3kxhg87s54m.cloudfront.net/fact-sheets/Rare_Argyle_Pink_Diamond_LUXUS_FactSheet.pdf',
    image: Diamond,
    leftCornerTitle: 'SOLD OUT',
    price: 200,
    value: '400,000',
  },
];

type OfferType = {
  name: string;
  img: string;
};
export const futureOfferings: OfferType[] = [
  { name: 'Belperron Cuff Bracelet', img: Bracelet },
  { name: 'Important Kashmir Sapphire', img: Sapphire },
  { name: 'Emerald Blue Diamond', img: DiamondBlue },
  { name: 'Kwiat LUXUS Cushion', img: Cushion },
];
