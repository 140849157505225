import { Container, CssBaseline, Modal, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useServerAPI } from '../../apis';
import { UserProfile } from '../../types/Account';
import useWindowDimensions from '../../utils/WindowDimensions';
import { ProfileConfirmView } from './components/ProfileConfirmView';
import ProfileForm from './components/ProfileForm';
import ComplianceForm from './components/ComplianceForm';
import VerifyingDetails from './components/VerifyingDetails';
import { Offering } from '../../types/Offerings';
import { ComplianceDataType } from '../../types/ComplianceData';
import { createUserForm } from '../../types/SigninId';
import ReactGA from 'react-ga';
import { Preloader } from '../../components/simpleComponents/Preloader';

type CompleteProfileState = {
  thenNavigateTo?: string;
  offering?: Offering;
  skipProfile?: boolean;
};

const CompleteProfile = () => {
  let api = useServerAPI();
  let navigate = useNavigate();
  const location = useLocation();
  const [stepNumber, setStepNumber] = useState(1);
  const [userProfileData, setUserProfileData] = useState<UserProfile>();
  const [complianceData, setComplianceData] = useState<ComplianceDataType>();
  const [isLoading, setIsLoading] = useState(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [isFormCancelled, setIsFormCancelled] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleClose = () => setIsFormCancelled(false);
  const { height } = useWindowDimensions();
  let { thenNavigateTo, offering: currentOffering } =
    (location.state as CompleteProfileState) ?? {};

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + '/CompleteProfile');
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + '/CompleteProfile');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!userProfileData) fetchUserProfile();
  }, [userProfileData]);

  // useEffect(() => {
  //   if (skipProfile && stepNumber === 1) setStepNumber(2);
  // }, [stepNumber, skipProfile]);

  const fetchUserProfile = () => {
    setIsLoading(true);
    api
      .getUserProfile()
      .then((userProfileRes) => {
        if (userProfileRes.data.response.error) {
          console.error(
            `ProfileEdit: Got error trying to retrive user profile `,
            userProfileRes.data.response.error
          );
        } else if (userProfileRes?.data?.response?.profile?.dob) {
          setUserProfileData(userProfileRes.data.response.profile);
          setIsEditMode(true);
        } else {
          setIsEditMode(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(
          `ProfileEdit: Got error trying to retrive user profile`,
          error
        );
        setIsLoading(false);
      });
  };

  const handleOnSubmitForm = (inputs: UserProfile) => {
    if (
      inputs.firstName !== '' &&
      inputs.lastName !== '' &&
      inputs.domicile !== '' &&
      inputs.dob !== '' &&
      inputs.primCountry !== '' &&
      inputs.primAddress1 !== '' &&
      inputs.primCity !== '' &&
      inputs.primState !== '' &&
      inputs.primZip !== ''
    ) {
      setUserProfileData(inputs);
      setStepNumber(2);
    }
  };

  const handleOnSubmitVerification = async () => {
    try {
      if (isEditMode) {
        setIsLoading(true);

        var profileData = {
          ...userProfileData,
          ...complianceData,
        } as unknown as createUserForm;
        // var complianceResponse = await api.updateUserProfile(profileData);
        // if (!complianceResponse.data.response?.error) {
        //   if (complianceResponse.data.response?.profile?.name) localStorage.setItem('userName', complianceResponse.data.response.profile.name);
        //   if (complianceResponse.data.response?.profile?.firstName) localStorage.setItem('userFirstName', complianceResponse.data.response.profile.firstName);
        //   if (complianceResponse.data.response?.profile?.lastName) localStorage.setItem('userLastName', complianceResponse.data.response.profile.lastName);
        // }
        setIsLoading(false);
        setSubmittedSuccessfully(true);
        setStepNumber(4);
      } else if (userProfileData && complianceData) {
        setIsLoading(true);
        // var response = await api.createUser({profileData: userProfileData, complianceData: complianceData});
        // if (response.data.response) response = response.data.response;
        setIsLoading(false);
        // console.debug('Create User returned', response);
        // localStorage.setItem('partyId', response.data.partyId);
        // if (response.data?.verifyStatus) localStorage.setItem('verifyStatus', response.data?.verifyStatus);
        // if (response.data?.name) localStorage.setItem('userName', response.data?.name);
        // if (response.data?.firstName) localStorage.setItem('userFirstName', response.data?.firstName);
        // if (response.data?.lastName) localStorage.setItem('userLastName', response.data?.lastName);

        setSubmittedSuccessfully(true);
        setStepNumber(4);
      }
    } catch (error: any) {
      console.error('err', error.response?.data?.error);
      setIsLoading(false);
    }
  };
  const handleComplianceForm = async (values: ComplianceDataType) => {
    setComplianceData(values);
    // alert(JSON.stringify(values));
    setStepNumber(3);
  };

  if (isLoading) return <Preloader />;

  // console.debug(`Will go to ${thenNavigateTo} after`,currentOffering);

  /* ****************************************
  Steps definitions.
  **************************************** */
  const profileFormStep = (
    <ProfileForm
      onCancel={() => {
        navigate(-1);
      }}
      onSave={handleOnSubmitForm}
      data={userProfileData}
      title="Account Details"
    />
  );
  const complianceFormStep = (
    <ComplianceForm
      data={complianceData}
      onCancel={() => {
        // if (skipProfile) {
        //   navigate(-1);
        // } else {
        setStepNumber(1);
        // }
      }}
      onSave={(values) => handleComplianceForm(values)}
    />
  );
  const confirmStep = (
    <ProfileConfirmView
      onCancel={() => {
        setStepNumber(2);
      }}
      onSave={() => handleOnSubmitVerification()}
      profileData={userProfileData}
      complianceData={complianceData}
    />
  );
  const verifyStep = (
    <VerifyingDetails
      onComplete={() => {
        localStorage.setItem('completedFinQuestions', 'true');
        if (thenNavigateTo)
          navigate(thenNavigateTo, { state: { offering: currentOffering } });
        else navigate('/');
      }}
    />
  );

  return (
    <Box>
      {/* <Box display='flex' flexDirection='column' alignItems={'center'} marginTop={10} marginBottom={10}> */}
      <Box
        sx={{
          backgroundColor: 'inherit',
          height: '100%',
          width: '100%',
          paddingBottom: '5rem',
          minHeight: height,
        }}
      >
        <CssBaseline />
        {!submittedSuccessfully ? (
          <>
            {/* <Stepper activeStep={stepNumber} alternativeLabel style={{marginTop: 30}}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper> */}
            <Box
              sx={{
                paddingTop: '5rem',
              }}
            >
              <Container
                maxWidth={'md'}
                sx={{
                  backgroundColor: '#ffffff',
                  padding: '25px',
                }}
              >
                {stepNumber === 1 && profileFormStep}
                {stepNumber === 2 && complianceFormStep}
                {stepNumber === 3 && confirmStep}
                {stepNumber === 4 && verifyStep}
              </Container>
            </Box>
          </>
        ) : (
          verifyStep
        )}
        <Modal
          open={isFormCancelled}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: '#ffffff',
              p: 4,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                color: '#000000',
                fontSize: '28px',
              }}
            >
              Are you sure?
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                marginTop: '35px',
                color: '#333333',
                fontSize: '15px',
              }}
            >
              To participate in investments, you must complete your profile.
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'flex-end'}
              sx={{
                marginTop: '40px',
              }}
            >
              <Button
                sx={{
                  fontSize: '13px',
                  border: '1px solid #274f85',
                  color: '#274f85',
                  padding: '4px 2rem',
                  borderRadius: '5px',
                  marginRight: '15px',
                  '&:hover': {
                    background: '#274f85',
                    color: '#ffffff',
                  },
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Leave
              </Button>
              <Button
                sx={{
                  fontSize: '13px',
                  backgroundColor: '#274f85',
                  border: '1px solid #274f85',
                  color: '#ffffff',
                  padding: '4px 2rem',
                  borderRadius: '5px',
                  '&:hover': {
                    background: '#274f85',
                  },
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                Stay
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default CompleteProfile;
