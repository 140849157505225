const DiamondMedium = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M19.8867 7.76562L15.5117 1.51562C15.3945 1.34961 15.2031 1.25 15 1.25H4.9648C4.79683 1.25 4.60543 1.34961 4.48824 1.5168L0.113239 7.7668C-0.0564485 8.0091 -0.0332453 8.33672 0.168161 8.55234L9.54316 18.5523C9.66402 18.6797 9.82808 18.75 9.99996 18.75C10.1718 18.75 10.3373 18.6786 10.4558 18.5523L19.8308 8.55227C20.0351 8.33594 20.0546 8.00781 19.8867 7.76562ZM14.9765 2.93477L18.1757 7.5H11.5L14.9765 2.93477ZM9.99996 7.40625L6.26168 2.5H13.7382L9.99996 7.40625ZM5.0234 2.93477L8.49996 7.5H1.82535L5.0234 2.93477ZM9.99996 17.2109L2.06754 8.75H17.9308L9.99996 17.2109Z"
        fill="white"
      />
    </svg>
  );
};

export default DiamondMedium;
