import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faLocationDot,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import classNames from "classnames";
import { FC, useState } from "react";
import { PrivacyPage } from "../../pages/Privacy/Privacy";
import { TermsOfUsePage } from "../../pages/Terms/TermsOfUse";
import { themeData } from "../../utils/theme";

import { LogoIcon } from "assets/icons/logo";
import { useNavigate } from "react-router-dom";
import useGetDisplayType from "../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../types/enums/DisplayTypes";
import { FooterLinkProps } from "./Footer.type";
import FooterForm from "./FooterForm/FooterForm";
import { useStyles } from "./styles/index";

const FooterLink: FC<FooterLinkProps> = ({ href, children, props }) => (
  <Link
    href={href}
    {...props}
    color={"inherit"}
    underline={"always"}
    sx={{ wordBreak: "break-all" }}
  >
    {children}
  </Link>
);

export const Footer = (props: {
  align?: string;
  removeForm?: boolean;
  pageName: string;
  pageUri: string;
}) => {
  const mobile = useGetDisplayType(DisplayTypes.Mobile);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 960px)");

  const contactData = [
    {
      icon: faLocationDot,
      target: "_blank",
      text: "1270 Ave of the Americas, 7th Fl – 1071 <br/> New York, NY 10020",
      link: "https://www.google.com/maps/place/1270+6th+Ave+7th+1071,+New+York,+NY+10020/@40.7600704,-73.9820039,17z/data=!3m1!4b1!4m5!3m4!1s0x89c258f928e3f70d:0x39744640afd26d8e!8m2!3d40.7600664!4d-73.9798152",
    },
    {
      icon: faPhone,
      target: undefined,
      text: "212.300.9890",
      link: "tel:212.300.9890",
    },
    {
      icon: faEnvelope,
      target: undefined,
      text: "Information",
      link: "mailto:info@luxusco.com",
    },
    {
      icon: faEnvelope,
      target: undefined,
      text: "Tech Support",
      link: "mailto:techsupport@luxusco.com",
    },

    {
      icon: faUser,
      target: undefined,
      text: "Careers",
      link: "mailto:careers@luxusco.com",
    },
    {
      icon: faEnvelope,
      target: undefined,
      text: "Press",
      link: "mailto:press@luxusco.com",
    },
  ];

  const linksData = [
    {
      icon: undefined,
      target: "_blank",
      text: "WHO WE ARE",
      link: "/AboutLuxus",
    },
    {
      icon: undefined,
      target: "_blank",
      text: "FAQ",
      link: "/faq",
    },
  ];

  const socialData = [
    // {
    //   icon: faFacebookF,
    //   target: "_blank",
    //   text: "Facebook",
    //   link: "https://www.facebook.com/luxusassets/",
    // },
    {
      icon: faLinkedinIn,
      target: "_blank",
      text: "LinkedIn",
      link: "https://www.linkedin.com/company/luxusassets/",
    },
    {
      icon: faInstagram,
      target: "_blank",
      text: "Instagram",
      link: "https://www.instagram.com/luxusassets/",
    },
    // {
    //   icon: faYoutube,
    //   target: "_blank",
    //   text: "Twitter",
    //   link: "https://www.youtube.com/channel/UCqu86Tl5DZM4cm1M9QHwN5A",
    // },
    // {
    //   icon: faTiktok,
    //   target: "_blank",
    //   text: "Twitter",
    //   link: "https://www.tiktok.com/@luxusassets",
    // },
    // {
    //   icon: faXTwitter,
    //   target: "_blank",
    //   text: "Twitter",
    //   link: "https://twitter.com/luxusassets",
    // },
  ];

  const {
    container,
    secondContainer,
    muiContainer,
    luxusLogo,
    contactList,
    contactListText,
    socialDataBox,
    socialDataLink,
    finePrint,
    copyrightBox,
    allRightsReserved,
    linkSettingsWhite,
    text,
    links,
    dividerBox,
    contactDataText,
    textBox,
    iconContactListItem,
    iconContacts,
    upperFooter,
    footerTextCols,
    finePrintTitle,
  } = useStyles();

  return (
    <Box
      component={"footer"}
      id="LuxusFooter" //important, used to figureout it's height
      className={container}
    >
      <Box className={secondContainer}>
        <Container maxWidth={false} className={muiContainer}>
          {!props?.removeForm && (
            <FooterForm pageName={props.pageName} pageUri={props.pageUri} />
          )}
          <Box>
            <Grid container spacing={2} justifyContent={"space-between"}>
              <Grid item xs={12} sm={4}>
                <List className={contactList} dense>
                  {contactData.map((i, idx) => {
                    if (i.icon === undefined) {
                      return (
                        <Typography
                          key={idx}
                          className={classNames(finePrint, contactDataText)}
                        >
                          {i.text}
                        </Typography>
                      );
                    } else {
                      return (
                        <ListItem
                          disablePadding
                          key={i.link || i.text}
                          sx={{
                            marginBottom:
                              idx !== contactData.length - 1 ? "8px" : "0px",
                          }}
                        >
                          <ListItemIcon className={iconContactListItem}>
                            <FontAwesomeIcon
                              icon={i.icon}
                              className={iconContacts}
                            />
                          </ListItemIcon>
                          <ListItemButton
                            disableGutters
                            className={contactList}
                            component={"a"}
                            target={i.target}
                            href={i.link}
                          >
                            <ListItemText className={contactList}>
                              <div
                                className={contactListText}
                                dangerouslySetInnerHTML={{ __html: i.text }}
                              />
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  })}
                </List>
                {/* </Typography> */}
              </Grid>
              <Grid item>
                <Box>
                  {/* <List className={contactList} dense>
                    {linksData.map((i, idx) => (
                      <ListItem disablePadding key={i.link || i.text}>
                        <ListItemButton
                          disableGutters
                          className={contactList}
                          component={"a"}
                          target={i.target}
                          onClick={() => {
                            navigate(i.link);
                          }}
                        >
                          <ListItemText className={contactList}>
                            <div
                              className={contactListText}
                              dangerouslySetInnerHTML={{ __html: i.text }}
                            />
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List> */}
                  <Box className={socialDataBox}>
                    {socialData.map((i, idx) => (
                      <Link
                        key={idx}
                        target={i.target}
                        href={i.link}
                        className={socialDataLink}
                      >
                        <FontAwesomeIcon
                          icon={i.icon}
                          color={themeData.WhiteColor}
                        />
                      </Link>
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "24px 0px",
            }}
          >
            <Link
              sx={{ cursor: "pointer" }}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              <LogoIcon />
            </Link>
          </Box>
          <Box>
            <Box
              className={footerTextCols}
              sx={{
                columns: isMobile ? 1 : isTablet ? 2 : 3,
                gap: "20px",
              }}
            >
              <Typography className={finePrint}>
                Disclaimer
                <br />
                IN MAKING AN INVESTMENT DECISION, INVESTORS MUST RELY ON THEIR
                OWN EXAMINATION OF THE ISSUER AND THE TERMS OF THE OFFERING,
                INCLUDING THE MERITS AND RISKS INVOLVED. REGULATION D AND
                REGULATION A OFFERINGS ARE SPECULATIVE, ILLIQUID, AND INVOLVE A
                HIGH DEGREE OF RISK, INCLUDING THE POSSIBLE LOSS OF YOUR ENTIRE
                INVESTMENT.
              </Typography>
              <Typography className={finePrint}>
                This profile may contain forward-looking statements and
                information relating to, among other things, the company, its
                business plan and strategy, and its markets or industry. These
                statements reflect management’s current views regarding future
                events based on available information and are subject to risks
                and uncertainties that could cause the company’s actual results
                to differ materially.
              </Typography>
              <Typography className={finePrint}>
                Investors are cautioned not to place undue reliance on these
                forward-looking statements as they are meant for illustrative
                purposes, and they do not represent guarantees of future
                results, levels of activity, performance, or achievements, all
                of which cannot be made. Moreover, although management believes
                that the expectations reflected in the forward-looking
                statements are reasonable, neither Luxus Alternatives Inc. nor
                anyone acting on its behalf can give any assurance that such
                expectations will prove to have been correct nor do they have a
                duty to update any such statements to conform them to actual
                results. By accessing this site and any pages on this site, you
                agree to be bound by our Terms of Use and Privacy Policy, as may
                be amended.
              </Typography>
              <Typography className={finePrint}>
                Securities offered pursuant to Regulation D are limited to
                accredited investors only. Luxus will verified each investor’s
                status as an accredited investor prior to accepting any
                subscription for securities.
              </Typography>
              <Typography className={finePrint}>
                Securities offered via Regulation A through Dalmore Group LLC,
                registered broker dealer, member of{" "}
                <FooterLink
                  href="https://www.finra.org/#/"
                  props={{ underline: "none" }}
                >
                  FINRA
                </FooterLink>{" "}
                , member of{" "}
                <FooterLink
                  href="https://www.sipc.org/"
                  props={{ underline: "none" }}
                >
                  SIPC
                </FooterLink>{" "}
                acting as broker of record.
              </Typography>
              <Typography className={finePrint}>
                FROM TIME TO TIME, AFFILIATES OF LUXUS WILL SEEK TO QUALIFY
                ADDITIONAL SECURITIES UNDER REGULATION A. FOR OFFERINGS THAT
                HAVE NOT YET BEEN QUALIFIED, NO MONEY OR OTHER CONSIDERATION IS
                BEING SOLICITED AND, IF SENT IN RESPONSE, WILL NOT BE ACCEPTED.
                NO OFFER TO BUY SECURITIES CAN BE ACCEPTED, AND NO PART OF THE
                PURCHASE PRICE CAN BE RECEIVED,
              </Typography>
              <Typography className={finePrint}>
                UNTIL AN OFFERING STATEMENT FILED WITH THE SEC HAS BEEN
                QUALIFIED BY THE SEC OR UNLESS AS STATED IN THE OFFERING
                MATERIALS RELATING TO AN INVESTMENT OPPORTUNITY, AS APPLICABLE.
                ANY SUCH OFFER MAY BE WITHDRAWN OR REVOKED, WITHOUT OBLIGATION
                OR COMMITMENT OF ANY KIND, AT ANY TIME BEFORE NOTICE OF
                ACCEPTANCE GIVEN AFTER THE DATE OF QUALIFICATION BY THE SEC OR
                AS STATED IN THE OFFERING MATERIALS RELATING TO AN INVESTMENT
                OPPORTUNITY, AS APPLICABLE. AN INDICATION OF INTEREST INVOLVES
                NO OBLIGATION OR COMMITMENT OF ANY KIND.
              </Typography>
              <Typography className={finePrint}>
                PLEASE NOTE INVESTORS IN THIS OFFERING WILL BE CLIENTS OF THE
                ISSUER AND NOT DALMORE GROUP, LLC (“DALMORE”), A REGISTERED
                BROKER-DEALER AND MEMBER FINRA/SIPC. DALMORE’S ROLE IN THE
                TRANSACTION IS TO FACILITATE BACK OFFICE AND REGULATORY
                FUNCTIONS RELATED TO THE REGULATION A TRANSACTION, AND ACTS ONLY
                AS THE BROKER/DEALER OF RECORD FOR THE OFFERING LISTED. DALMORE
                IS NOT PROVIDING INVESTMENT ADVICE OR RECOMMENDATIONS, OR LEGAL
                OR TAX ADVICE.
              </Typography>
            </Box>
          </Box>
          {mobile && <Box className={upperFooter} />}
          <Box className={copyrightBox} lineHeight={0.5}>
            <Box className={allRightsReserved}>
              {/*  <Tooltip title={`Version ${process.env.REACT_APP_VERSION}`}>*/}
              <Typography className={text}>
                COPYRIGHT ©{new Date().getFullYear()} ALL RIGHTS RESERVED |
              </Typography>
              {/*</Tooltip>*/}
            </Box>
            <Box className={dividerBox}>
              <Link
                target="_blank"
                href={"/termsOfService"}
                className={linkSettingsWhite}
              >
                TERMS OF SERVICE
              </Link>
              {mobile && <br />}
            </Box>
            <Box className={dividerBox}>
              <Link
                target="_blank"
                href={"/privacy"}
                className={linkSettingsWhite}
              >
                | PRIVACY POLICY
              </Link>
              {mobile && <br />}
            </Box>
            <TermsOfUsePage
              show={showTerms}
              onClose={() => setShowTerms(false)}
            />
            <PrivacyPage
              show={showPrivacy}
              onClose={() => setShowPrivacy(false)}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
