import React, { useState, useRef, useEffect } from "react";
import { Box, TextField, Stack, Typography } from "@mui/material";
import DropZone from "../UploadIdForm/DropZone";

import { useStyles } from "./styles";

interface PassportFormProps {
  setProfilePassportNumber(value: string): void;
  uploadFile: (
    data: Record<"documentTitle" | "file_name" | "userfile0", string>
  ) => Promise<void>;
}

const PassportForm: React.FC<PassportFormProps> = function ({
  setProfilePassportNumber,
  uploadFile,
}): JSX.Element {
  const [passportNumber, setPassportNumber] = useState("");
  const passportFile = useRef({ content: "", fileName: "" });

  const onChange = (e: React.ChangeEvent) => {
    setPassportNumber((e.target as HTMLInputElement).value);
    setProfilePassportNumber((e.target as HTMLInputElement).value);
  };

  const classes = useStyles();

  useEffect(() => {
    return () => {
      if (!passportFile.current.content) return;
      uploadFile({
        documentTitle: "Passport",
        file_name: passportFile.current.fileName,
        userfile0: passportFile.current.content,
      });
    };
  }, []);

  return (
    <Box className={classes.container}>
      <Stack direction="row" spacing={2}>
        <TextField
          id="passportNumber"
          className={classes.formFieldSmall}
          placeholder="Passport Number"
          value={passportNumber}
          onChange={onChange}
        />
      </Stack>
      <Box className={classes.uploadMainContainer}>
        <DropZone
          setFileData={(data: string) => {
            passportFile.current.content = data;
          }}
        />
      </Box>
    </Box>
  );
};

export default PassportForm;
