import { forwardRef } from "react";
import { Controller, Control } from "react-hook-form";

import Dropdown from "components/simpleComponents/Dropdown/DropDown";
import { DropdownOption } from "components/simpleComponents/Dropdown/DropDown.type";

import styles from "./ControlledDropdown.module.scss";

type ControlledDropdownProps = {
  name: string;
  placeholder: string;
  control: Control<any>;
  isError?: boolean;
  errorMessage?: string;
  dropdownOptions: DropdownOption[];
  initValue: DropdownOption;
  placeholderColor?: string;
  arrowColor?: string;
  borderColor?: string;
  maxHeight?: string;
};

const ControlledDropdown = forwardRef<HTMLDivElement, ControlledDropdownProps>(
  (
    {
      name,
      isError,
      errorMessage,
      dropdownOptions,
      placeholder,
      control,
      initValue,
      placeholderColor,
      arrowColor,
      borderColor = "#7B7B8F",
      maxHeight,
    },
    ref
  ) => {
    function getBorderColor(isError: boolean, borderColor: string): string {
      if (isError) {
        return "#FF0000";
      }
      return borderColor;
    }

    return (
      <div>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange } }) => {
            return (
              <Dropdown
                maxHeight={maxHeight}
                arrowColor={arrowColor}
                placeholderColor={placeholderColor}
                value={initValue}
                options={dropdownOptions}
                onChange={(option) => onChange(option.value)}
                placeholder={placeholder}
                borderColor={getBorderColor(!!isError, borderColor)}
              />
            );
          }}
        />
        {isError && <span className={styles.errorMessage}>{errorMessage}</span>}
      </div>
    );
  }
);

ControlledDropdown.displayName = "ControlledDropdown";

export default ControlledDropdown;
