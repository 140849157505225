import classNames from "classnames";
import moment from "moment";

import formatNumber from "utils/FormatNumber";
import { transformStatus } from "utils/transformStatus";

import styles from "./AssetsItemExpand.module.scss";

type AssetsItemExpandProps = {
  date?: string | object;
  amount: string;
  status?: string | object;
};

const AssetsItemExpand = ({ date, amount, status }: AssetsItemExpandProps) => {
  const transformedDate = moment(date).format("LL");

  const transformedAmount = formatNumber(amount.split(".")[0]);

  const orderStatus = transformStatus(status);

  const statusStyles = classNames({
    [styles.pendingSpan]: orderStatus === "Pending",
  });

  return (
    <li className={styles.listItem}>
      <div className={styles.spanWrapper}>
        <span>{transformedDate}</span>
      </div>
      <div className={styles.spanWrapper}>
        <span>{`$${transformedAmount}`}</span>
      </div>
      <div className={styles.spanWrapper}>
        <span className={statusStyles}>{orderStatus}</span>
      </div>
    </li>
  );
};

export default AssetsItemExpand;
