import { Card, CardMedia, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { Offering } from '../../../types/Offerings';
import { OfferingFormater } from '../../../utils/OfferingFormatter';
import themeData from '../../../utils/theme';

export interface AssetDetailsProps {
  currentOffering?: Offering;
  isIntent?: boolean;
  largeImg?: boolean;
}
export const AssetPurchaseDetails = ({
  currentOffering,
  isIntent = false,
  largeImg = false,
}: AssetDetailsProps) => {
  const desktop = useMediaQuery('(min-width:900px)');
  if (!currentOffering) return null;

  var itemFormatted = OfferingFormater(currentOffering, 'USD', true);
  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        padding: { xs: '17px', md: '33px' },
        width: { xs: '100%', md: '669px' },
      }}
    >
      {/* Top Line  */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: desktop ? 'row' : 'column',
        }}
      >
        <Box>
          {isIntent && (
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: '227px' },
                height: '57px',
                backgroundColor: {
                  xs: themeData.WhiteColor,
                  md: themeData.HighlightColor,
                },
                color: {
                  xs: themeData.HighlightColor,
                  md: themeData.WhiteColor,
                },
                borderRadius: '3px',
                // marginRight: '42px',
                marginBottom: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                border: {
                  xs: `2px solid ${themeData.HighlightColor}`,
                  md: 'none',
                },
              }}
            >
              <Typography
                align="center"
                sx={{
                  fontSize: '25px',
                  fontFamily: themeData.PrimaryFont,
                }}
              >
                Coming Soon
              </Typography>
            </Box>
          )}

          {/* Image */}
          <Card
            sx={{
              width: { xs: '100%', md: largeImg ? '290px' : '227px' },
              boxShadow: 'none',
              borderRadius: '0px',
              border: '1px solid ' + themeData.BorderColor,
              marginY: { xs: '10px', md: 0 },
            }}
          >
            <CardMedia
              component="img"
              image={currentOffering?.image?.url}
              alt={currentOffering?.image?.label}
            />
          </Card>
        </Box>
        <Box
          sx={{
            marginLeft: { md: '0px', xs: '10px' },
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'row', marginLeft: '42px' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'left',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  color: themeData.GreyLabel,
                  justifyContent: 'center',
                  // height: '57px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  component="h6"
                  align="left"
                  sx={{
                    color: themeData.BlackColor,
                    fontSize: '30px',
                    lineHeight: 1.2,
                  }}
                >
                  {currentOffering?.name}
                </Typography>
              </Box>
              <Typography
                component="h6"
                align="left"
                sx={{
                  fontFamily: themeData.DefaultFontHeavy,
                  color: themeData.HighlightColor,
                  fontSize: '20px',
                  fontWeight: 500,
                  marginTop: { xs: '1rem', md: '20px' },
                  marginBottom: '0.5rem',
                }}
              >
                Investment Terms
              </Typography>
              {currentOffering.investSpecs
                .filter((i) => i.display?.includes('ioi'))
                .map((s, i) => (
                  <Box
                    key={i}
                    sx={{
                      marginY: '5px',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Typography
                      component="h6"
                      align="left"
                      sx={{
                        color: themeData.BlackColor,
                        fontSize: '20px',
                        fontWeight: 400,
                        fontFamily: themeData.DefaultFontBook,
                      }}
                    >
                      {s.title}
                    </Typography>
                    <Typography
                      component="h6"
                      align="left"
                      sx={{
                        color: themeData.BlackColor,
                        fontSize: '20px',
                        fontWeight: 500,
                        marginLeft: '.5em',
                      }}
                    >
                      {itemFormatted.investSpecValue(s.value)}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
