import { dispatch } from "../store";
import { setUserProfileData } from "../store/slices/User";
import { useServerAPI } from "../apis";
import parseFieldFromUserProfile from "./parseFieldFromUserProfile";

export default async function requestUserProfile(
  api: ReturnType<typeof useServerAPI>
): Promise<void> {
  const userProfile = await api
    .getUserProfile()
    .then((response) => {
      if (response.data?.response?.profile?.field1)
        parseFieldFromUserProfile(response.data.response.profile.field1);
      if (!response.data.response.error) return response.data.response.profile;
      return undefined;
    })
    .catch(() => undefined);

  dispatch(setUserProfileData(userProfile));
}
