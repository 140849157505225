import { useState, useEffect } from "react";

import SheetTitle from "./SheetTitle";
import ArrowBottom from "assets/icons/PortfolioIcons/ArrowBottom";
import AssetsItem from "../AssetsItem/AssetsItem";

import { OfferingTrades } from "types/Trade";
import formatNumber from "utils/FormatNumber";

import styles from "./PortfolioInfoMobile.module.scss";

type PortfolioInfoMobileProps = {
  shares: OfferingTrades[];
};

const PortfolioInfoMobile = ({ shares }: PortfolioInfoMobileProps) => {
  const sortedShares = [...shares].sort((a, b) => {
    return b.totalAmount - a.totalAmount;
  });

  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    const initialValue = 0;

    const totalSum = shares.reduce(
      (accumulator, shares) => accumulator + shares.totalAmount,
      initialValue
    );

    setTotalSum(totalSum);
  }, [shares]);

  const formattedAmount = formatNumber(totalSum.toString());

  return (
    <div>
      <SheetTitle>
        <div className={styles.textWrapper}>
          <p className={styles.text}>ASSET</p>
          <ArrowBottom />
        </div>
        <div className={styles.textWrapper}>
          <p className={styles.text}>AMOUNT</p>
          <ArrowBottom />
        </div>
      </SheetTitle>
      {shares.length > 0 && (
        <ul className={styles.list}>
          {sortedShares.map((share) => (
            <AssetsItem key={share.offeringId} share={share} />
          ))}
        </ul>
      )}
      <SheetTitle>
        <span className={styles.text}>TOTAL</span>
        <span className={styles.text}>${formattedAmount}</span>
      </SheetTitle>
    </div>
  );
};

export default PortfolioInfoMobile;
