const firstAccreditationOption =
  "I am an Accredited Investor, because I have an individual income of more than $200,000 per year, or a joint income of $300,000 in each of the last two years.";

const secondAccreditationOption =
  "I am an Accredited Investor, because I have a net worth exceeding $1,000,000, excluding my primary residence, either individuality or jointly with my spouse.";

const notAccreditedOption =
  " I am not Accredited, I acknowledge that my investment(s) in this offering is less than 10% of my net worth or annual gross income.";

export const getAccreditationStatus = (value: string) => {
  if (value === "accredited-1") {
    return firstAccreditationOption;
  } else if (value === "accredited-2") {
    return secondAccreditationOption;
  } else {
    return notAccreditedOption;
  }
};
