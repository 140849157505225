import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        padding: "0",
      },
      paymentName: {
        marginRight: "0 !important",
        width: "fit-content",
        justifySelf: "start",
      },
      paymentNumber: {
        margin: "0 !important",
        width: "fit-content",
        justifySelf: "start",
      },
      paymentType: {
        margin: "0 !important",
        width: "fit-content",
        justifySelf: "end",
      },
      paymentOperandBox: {
        justifySelf: "end",
        justifyContent: "space-between",
        width: "70px",
        "& button": {
          padding: "0",
        },
      },
      paymentBox: {
        gridTemplateColumns: "50% 50%",
        width: "90%",
      },
      addPaymentBox: {
        marginLeft: "0",
        marginTop: "20px",
      },
    })
  )
);
