import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { CloseIcon } from "assets/icons/close";

import { Explanation } from "./types";

type HowItWorksSelectedOptionProps = {
  option: Explanation;
  selectedOption: Explanation | null;
  onClick: (option: Explanation) => void;
};

const HowItWorksSelectedOption: FC<HowItWorksSelectedOptionProps> = ({
  option,
  selectedOption,
  onClick,
}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    selectedOption?.id === option.id ? setIsActive(true) : setIsActive(false);
  }, [option, selectedOption]);

  const { question, answer } = option;

  return (
    <Box
      padding={"10px 0px"}
      sx={{ cursor: "pointer", borderTop: "1px solid white" }}
      position={"relative"}
      zIndex={1}
      onClick={(e) => {
        onClick(option);
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
      >
        <Box
          fontSize={"20px"}
          lineHeight={"normal"}
          letterSpacing={"0.4px"}
          fontWeight={"400"}
        >
          {question.toUpperCase()}
        </Box>
        <Box
          style={{
            transition: "transform 0.3s ease",
            transform: `${isActive ? "rotate(90deg)" : "rotate(45deg)"}`,
            maxWidth: 15,
            maxHeight: 18,
          }}
        >
          <CloseIcon width={15} height={18} />
        </Box>
      </Box>
      <Box
        sx={{
          opacity: isActive ? 1 : 0,
          zIndex: isActive ? 1 : 0,
          transition: "max-height 0.5s ease , opacity 0.5s ease",
          maxHeight: isActive ? "30vh" : "0px",
          pointerEvents: isActive ? "auto" : "none",
        }}
      >
        <Typography fontSize={"16px"}
                    lineHeight={"21px"}
                    fontWeight={"400"}
                    color={"#fff"}
                    fontFamily={'Futura PT Book'}
                    marginTop={'5px'}
        >
          {answer}
        </Typography>
      </Box>
    </Box>
  );
};

export default HowItWorksSelectedOption;
