import { forwardRef } from "react";
import { Controller, Control } from "react-hook-form";

import FileUpload from "components/simpleComponents/FileUpload/FileUpload";

type ControlledFileUploadProps = {
  control: Control<any>;
  name: string;
  file: File;
};

const ControlledFileUpload = forwardRef(
  ({ control, name, file }: ControlledFileUploadProps, ref) => {
    return (
      <>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange } }) => {
            return (
              <FileUpload.Black
                onChange={(file) => onChange(file)}
                setFileData={() => {}}
                value={file}
              />
            );
          }}
        />
      </>
    );
  }
);

export default ControlledFileUpload;
