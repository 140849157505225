import { Offering } from '../types/Offerings';

export function sortByFeatured(offer: Offering, nextOffer: Offering): number {
  if (!offer.DateOnMarket || !nextOffer.DateOnMarket) return 0;

  const offerDateOnMarket = offer.DateOnMarket.split('/').map((item) =>
    Number(item)
  );
  const nextOfferDateOnMarket = nextOffer.DateOnMarket.split('/').map((item) =>
    Number(item)
  );

  const monthDiff = offerDateOnMarket[0] - nextOfferDateOnMarket[0];

  if (monthDiff !== 0) return monthDiff;

  const dayDiff = offerDateOnMarket[1] - nextOfferDateOnMarket[1];

  return dayDiff;
}

export function sortByPriceAppreciation(
  offer: Offering,
  nextOffer: Offering
): number {
  return offer.increasePercent - nextOffer.increasePercent;
}

export function sortByTotalAssetsValue(
  offer: Offering,
  nextOffer: Offering
): number {
  if (!offer.offering?.targetAmount || !nextOffer.offering?.targetAmount)
    return 0;
  return (
    parseFloat(offer.offering?.targetAmount as string) -
    parseFloat(nextOffer.offering?.targetAmount as string)
  );
}

export function sortBySharePrice(offer: Offering, nextOffer: Offering): number {
  if (!offer.offering?.unitPrice || !nextOffer.offering?.unitPrice) return 0;
  return (
    parseFloat(offer.offering?.unitPrice as string) -
    parseFloat(nextOffer.offering?.unitPrice as string)
  );
}

export function sortByMinFundedPict(
  offer: Offering,
  nextOffer: Offering
): number {
  if (!offer.minFundedPct || !nextOffer.minFundedPct) return 0;
  return (offer.minFundedPct as number) - (nextOffer.minFundedPct as number);
}
