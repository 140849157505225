import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import themeData from 'utils/theme';
import { InputFieldProps } from './InputFiled.type';

export const StyledBlackTextField = styled(TextField)<InputFieldProps>(
  ({ theme, error, disabled }) => ({
    '& .MuiInputBase-input:-webkit-autofill': {
      transition: 'background-color 600000s 0s, color 600000s 0s',
    },
    '& .MuiInputBase-root input': {
      fontSize: 14,
      paddingLeft: '20px',
    },
    '& .MuiInputBase-root': {
      width: '100%',
      height: '38px',
      color: disabled ? themeData.DisabledColor : themeData.WhiteColor,
      ...(error && {
        color: themeData.ErrorTextColor,
      }),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: error ? themeData.ErrorTextColor : '#7B7B8F',
        borderRadius: '50px',
      },
      '&:hover fieldset': {
        borderColor: error ? themeData.ErrorTextColor : themeData.WhiteColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: error ? themeData.ErrorTextColor : themeData.WhiteColor,
      },
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#fff',
      border: '1px #393939 solid',
      borderRadius: '50px',
      backgroundColor: 'transparent',
      '-webkit-text-fill-color': '#a5a3a3',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
      color: '#fff',
      backgroundColor: 'transparent',
      '-webkit-text-fill-color': '#a5a3a3',
    },

    ...(disabled && {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'transparent',
      },
    }),
  })
);

export const StyledWhiteTextField = styled(TextField)<InputFieldProps>(
  ({ theme, error, disabled, fontSize }) => ({
    '& .MuiInputBase-input:-webkit-autofill': {
      transition: 'background-color 600000s 0s, color 600000s 0s',
    },
    '& .MuiInputBase-root input': {
      fontSize: fontSize ? fontSize : 14,
      paddingLeft: '20px',
    },
    '& .MuiInputBase-root': {
      width: '100%',
      height: '38px',
      color: disabled ? themeData.DisabledColor : '#000',
      ...(error && {
        color: themeData.ErrorTextColor,
      }),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: error ? themeData.ErrorTextColor : '#000',
        borderRadius: '50px',
      },
      '&:hover fieldset': {
        borderColor: error ? themeData.ErrorTextColor : '#7B7B8F',
      },
      '&.Mui-focused fieldset': {
        borderColor: error ? themeData.ErrorTextColor : '#7B7B8F',
      },
    },
    ...(disabled && {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    }),
  })
);
