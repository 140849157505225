import { makeStyles, createStyles } from "@mui/styles";

export const useStyle = makeStyles(() =>
  createStyles({
    leftCornerTitleBoxClass: {
      backgroundColor: "#7B7B8F",
      borderRadius: "6px",
      color: "#FFFFFF",
      fontSize: "21px",
      fontWeight: "500",
      clipPath: "polygon(0 0, 100% 0%, 83% 100%, 0% 100%)",
    },
  })
);
