import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    marginTop: "17px",
  },
  span: {},
  spanBold: {
    fontWeight: "400 !important",
  },
  label: {
    marginBottom: "15px",
    "& span": { fontWeight: 200 },
  },
};

export type ClassesNames = keyof typeof commonStyles;
