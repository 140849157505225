export type bankAccountForm = {
  ExtAccountfullname: string;
  Extnickname: string;
  ExtBankname: string;
  ExtRoutingnumber: string;
  ExtAccountnumber: string;
  accountType?: string;
};

export type BankAccountData = {
  statusCode?: string;
  AccountName: string;
  AccountNickName: string;
  AccountRoutingNumber: string;
  AccountNumber: string;
  accountType: string;
  error?: ErrorType;
};

export type CreditCardData = { statusCode?: string } & creditCardForm;

export type ErrorType = {
  error?: any;
  statusCode: string;
  statusDesc: string;
  "Error(s)"?: string;
};

export type RegisterBankAccountResponse = {
  data: {
    response: {
      data:
        | {
            accountId: string;
            ExtAccountfullname: string;
            Extnickname: string;
            ExtRoutingnumber: string;
            ExtAccountnumber: string;
            types: string;
            accountType: string;
            statusCode: "101";
          }
        | {
            statusCode: string;
            statusDesc: string;
          };
    };
    success: boolean;
  };
  status: number;
};
export type RemoveBankAccountResponse = {
  data: {
    response: {
      data:
        | {
            accountId: string;
            Ext_status: string;
          }
        | {
            statusCode: string;
            statusDesc: string;
          };
    };
    success: boolean;
  };
  status: number;
};

export type creditCardForm = {
  creditCardName: string;
  creditCardNumber: string;
  expirationDate: string;
  cvvNumber: string;
  cardType?: "MC" | "VI" | "DI" | "AMEX" | "DIN" | null;
};

export type RegisterRemoveCreditCardResponse = {
  data: {
    response: {
      data:
        | string
        | {
            statusCode: string;
            statusDesc: string;
          };
    };
    success: boolean;
  };
  status: number;
};

export type RegisteredPaymentsResponse = {
  data: {
    response: {
      data: {
        bankAccount: BankAccountData | ErrorType;
        creditCard: CreditCardData | ErrorType;
      };
    };
    success: boolean;
  };
  status: number;
};

export interface AssociatedAccount {
  accountId: string;
  accountName: string;
  type: string;
  entityType: string;
  residentType: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  email: string;
  phone: string;
  taxID: null;
  kycStatus: string;
  kycDate: null;
  amlStatus: string;
  amlDate: null;
  suitabilityScore: null;
  suitabilityDate: null;
  suitabilityApprover: null;
  accreditedStatus: string;
  accreditedInvestor: string;
  accreditedInvestorDate: null;
  "506cLimit": null;
  accountTotalLimit: null;
  singleInvestmentLimit: null;
  associatedAC: null;
  syndicate: string;
  tags: string;
  notes: string;
  approvalStatus: string;
  approvalPrincipal: null;
  approvalLastReview: null;
  field1: string;
  field2: string;
  field3: string;
  createdDate: string;
  updatedDate: string;
}

export type UserProfileForm = {
  domicile: string;
  firstName: string;
  lastName: string;
  socialSecurityNumber: string;
  dob: {
    year: string;
    month: string;
    date: string;
  };
  phone: string;
  primCountry: string;
  primAddress1: string;
  primAddress2: string;
  primCity: string;
  primState: string;
  primZip: string;
};

export type AssociatedAccountForm = Pick<
  AssociatedAccount,
  | "accountName"
  | "entityType"
  | "address1"
  | "city"
  | "state"
  | "zip"
  | "country"
  | "phone"
>;

export type UserProfile = {
  associatedAccounts: AssociatedAccount[];
  domicile: string;
  firstName: string;
  lastName: string;
  socialSecurityNumber: string;
  dob: string;
  phone: string;
  primCountry: string;
  primAddress1: string;
  primAddress2: string;
  primCity: string;
  primState: string;
  primZip: string;
  emailAddress: string;
  field1?: string;
  [props: string]: any;
};

export type UserProfileResponse = {
  data: {
    response: {
      profile: UserProfile;
      error?: {
        statusCode: string;
        statusDesc: string;
      };
    };
    success: boolean;
  };
  status: number;
};
export type ChangePasswordResponse = {
  data: {
    response: {
      message: string;
      success: boolean;
    };
    success: boolean;
  };
  status: number;
};

export const domicileChoices = [
  { label: "U.S. Citizen", value: "U.S. citizen" },
  { label: "U.S. Resident", value: "U.S. resident" },
  { label: "Non US citizen", value: "non-resident" },
];

export interface CreateUserResonse {
  data: {
    response: {
      status: number;
      data: {
        partyId: string;
        KYCstatus: string;
        AMLstatus: string;
        accountId: string;
        kycAmlResponse: Record<string, string | number | boolean>;
        verifyStatus: string;
        accountResponse: {
          accountId: string;
        };
        linkResponse: Record<string, string | number | boolean>;
        name: string;
        firstName: string;
        lastName: string;
      };
    };
    success: boolean;
  };
  status: number;
}
