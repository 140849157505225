import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, useAppSelector } from 'store'
import {
  setAccountId,
  setEntityProfile,
  setLegalEntityAccResponse,
} from 'store/slices/ConfigureProfile/ConfigureProfileSlice'
import { useServerAPI } from '../../../../apis'
import { displayFileSelector } from '../../../../store/selectors/legalEntity'
import { ipSelector } from '../../../../store/selectors/system'
import { loginDataSelector } from '../../../../store/selectors/user'
import requestUserProfile from '../../../../utils/requestUserProfile'
export interface LegalEntityProfile {
  name: string
  entityType: string
  primCountry: string
  primAddress1: string
  primAddress2: string
  primCity: string
  primState: string
  primZip: string
  phone: string
  ein: string
  entityFormationDocumentation: File | null
  accreditationLLCstatus: string
}

export const LegalEntityDefaultState = {
  name: '',
  entityType: '',
  primCountry: '',
  primAddress1: '',
  primAddress2: '',
  primCity: '',
  primState: '',
  primZip: '',
  phone: '',
  ein: '',
  entityFormationDocumentation: null,
  accreditationLLCstatus: '',
}

interface useAccreditationStatusFormFacadeResult {
  getLegalEntityProfilePropertySetter: <K extends keyof LegalEntityProfile>(
    key: K
  ) => (value: LegalEntityProfile[K]) => void
  getLegalEntityProfilePropertyGetter: () => LegalEntityProfile
  createLegalEntityAccount: () => void
  updateLegalEntityAccount: () => void
  validateLegalEntity: () => boolean
  isNextDisable: (step: number) => boolean
  legalEntityProfile: LegalEntityProfile
  isLoading: boolean
  uploadFileForEntity: () => void
  accountId: string
  entityPartyId: string
  onStartBrowsingBtnClick: () => void
}

export function useAccreditationStatusFormFacade(
  initialState?: LegalEntityProfile,
  dispatch?: AppDispatch
): useAccreditationStatusFormFacadeResult {
  const api = useServerAPI()
  const navigate = useNavigate()
  const legalEntityAccResponse = useAppSelector(
    state => state.ConfigureProfileSlice.legalAccount.legalEntityAccResponse
  )
  const isUserProfileRequested = useRef<{ value: boolean }>({ value: false })
  const createdIpAddress = useSelector(ipSelector)

  const legalEntityProfile = useAppSelector(
    state => state.ConfigureProfileSlice.legalEntityProfile
  )
  const accountId = useAppSelector(
    state => state.ConfigureProfileSlice.legalAccount.accountId
  )
  const [entityPartyId, setEntityPartyId] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const loginData = useSelector(loginDataSelector)

  const file = useSelector(displayFileSelector)

  const getLegalEntityProfilePropertySetter =
    <K extends keyof LegalEntityProfile>(key: K) =>
    (value: LegalEntityProfile[K]) => {
      dispatch?.(setEntityProfile({ key, value }))
    }

  const getLegalEntityProfilePropertyGetter = () => {
    return legalEntityProfile
  }

  const createLegalEntityAccount = () => {
    setIsLoading(true)
    if (validateLegalEntity()) {
      api
        .makeAccountForLegalEntity(legalEntityProfile)
        .then(res => {
          dispatch?.(
            setAccountId(res.data.response.data.createAccountResult.accountId)
          )
          api
            .getLegalEntityForAccount(
              res.data.response.data.createAccountResult.accountId
            )
            .then(res => setEntityPartyId(res.data.response.partyId))
          dispatch?.(setLegalEntityAccResponse(res))
        })
        .finally(() => setIsLoading(false))
    } else {
      console.log('Validation error!')
    }
  }

  const uploadFileForEntity = (): void => {
    if (!file || !legalEntityAccResponse) return

    if (!legalEntityAccResponse) return
    api.uploadFileForTransactApi({
      path: 'uploadEntityDocument',
      partyId: entityPartyId,
      documentTitle: 'Entity image',
      file_name: file.fileName,
      userfile0: file.entityFormationDocumentation,
      createdIpAddress,
    })
  }

  const updateLegalEntityAccount = () => {
    setIsLoading(true)
    api
      .updateLegalEntityAccount({ ...legalEntityProfile, accountId: accountId })
      .finally(() => setIsLoading(false))
  }

  const validateLegalEntity = (): boolean => {
    let result = true
    const nonValidateKeys: (keyof LegalEntityProfile)[] =
      legalEntityProfile.primCountry === 'United States of America'
        ? [
            'primAddress2',
            'entityFormationDocumentation',
            'ein',
            'accreditationLLCstatus',
          ]
        : [
            'primAddress2',
            'entityFormationDocumentation',
            'primState',
            'ein',
            'accreditationLLCstatus',
          ]
    const properties = (
      Object.keys(legalEntityProfile) as (keyof LegalEntityProfile)[]
    ).filter(property => !nonValidateKeys.includes(property))
    properties.forEach(property => {
      if (legalEntityProfile[property] === '') result = false
    })

    return result
  }

  const isNextDisable = (stepNumber: number): boolean => {
    const p: boolean[] = [
      !!legalEntityProfile.name,
      !!legalEntityProfile.entityType,
      !!legalEntityProfile.primCountry,
      legalEntityProfile.primCountry === 'United States of America'
        ? !!legalEntityProfile.primAddress1 &&
          !!legalEntityProfile.primCity &&
          !!legalEntityProfile.primState &&
          !!legalEntityProfile.primZip
        : !!legalEntityProfile.primAddress1 &&
          !!legalEntityProfile.primCity &&
          !!legalEntityProfile.primCountry &&
          !!legalEntityProfile.primZip,
      legalEntityProfile.phone.replaceAll(/[-()+\b\s]/g, '').length >= 10,
      !!legalEntityProfile.ein,
      !legalEntityProfile.entityFormationDocumentation,
      !legalEntityProfile.entityFormationDocumentation,
      !legalEntityProfile.entityFormationDocumentation,
      !legalEntityProfile.entityFormationDocumentation,
    ]
    return !p[stepNumber]
  }

  const onStartBrowsingBtnClick = (): void => {
    setIsLoading(true)
    requestUserProfile(api).finally(() => {
      isUserProfileRequested.current.value = true
      setIsLoading(false)
      navigate('/')
    })
  }

  useEffect(() => {
    return () => {
      if (!isUserProfileRequested.current.value) requestUserProfile(api)
    }
  }, [])

  return {
    getLegalEntityProfilePropertySetter,
    getLegalEntityProfilePropertyGetter,
    createLegalEntityAccount,
    updateLegalEntityAccount,
    validateLegalEntity,
    isNextDisable,
    legalEntityProfile,
    isLoading,
    uploadFileForEntity,
    accountId,
    entityPartyId,
    onStartBrowsingBtnClick,
  }
}
