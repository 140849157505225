export default function formatNumber(value: string): string {
  if (!value) return '';
  const digits = value.replaceAll(/[^\d]/g, '').split('');
  let counter = 0;
  for (let i = digits.length - 1; i > 0; i -= 1) {
    counter++;
    if (counter === 3) {
      digits[i] = `,${digits[i]}`;
      counter = 0;
    }
  }

  return digits.join('');
}
