import React, { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Button, TextField } from '@mui/material';
import { stylesV2 } from './style';
import { CounterProps } from './Counter.type';

const CounterV2: FC<CounterProps> = ({
  initialValue = 0,
  inputColor = '#000000',
  borderColor = '#F2F2F0',
  handleOtherCountChange = () => {},
}) => {
  const [count, setCount] = useState(initialValue);

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setCount(newValue);
    }
  };

  useEffect(() => {
    handleOtherCountChange(count);
  }, [count, handleOtherCountChange]);

  return (
    <Box
      display="flex"
      alignItems="center"
      overflow="hidden"
      border={`1px solid ${borderColor}`}
      borderRadius="50px"
      width="fit-content"
      maxHeight="38px"
      maxWidth="150px"
    >
      <Button sx={stylesV2.leftButton} onClick={handleDecrement}>
        -
      </Button>
      <TextField
        value={count}
        onChange={handleInputChange}
        type="text"
        sx={{
          mx: 2,
          width: '60px',
          height: '40px',
          margin: 0,
          border: 'none',
          '.MuiInputBase-input': {
            height: '20px',
            padding: '10px',
            textAlign: 'center',
            color: inputColor,
          },
        }}
      />
      <Button sx={stylesV2.rightButton} onClick={handleIncrement}>
        +
      </Button>
    </Box>
  );
};

export default CounterV2;
