export default function cloneStyleRules<T extends Record<keyof T, T[keyof T]>>(
  obj: T
): T {
  const result = {} as T;

  Object.keys(obj).forEach((dirtKey: string) => {
    const key = dirtKey as keyof T;
    if (typeof obj[key] !== 'object') result[key] = obj[key];
    else {
      result[key] = cloneStyleRules(obj[key]);
    }
  });

  return result;
}
