import styles from './TripPrice.module.scss';

type TripPriceProps = {
  option: string;
  price: string;
  comment?: string;
};

const TripPrice = ({ option, price, comment }: TripPriceProps) => {
  return comment ? (
    <div className={styles.secondWrapper}>
      <span className={styles.option}>{option}</span>
      <span className={styles.price}>{price}</span>
      <span className={styles.comment}>{comment}</span>
    </div>
  ) : (
    <div className={styles.wrapper}>
      <span className={styles.option}>{option}</span>
      <span className={styles.price}>{price}</span>
    </div>
  );
};

export default TripPrice;
