import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from '@mui/material';
import ReactGA from 'react-ga';
import classNames from 'classnames';

import { useServerAPI } from '../../../apis';
import CompleteProfile from './CompleteProfile/CompleteProfile';
import AddPaymentMethods from './AddPaymentMethods/AddPaymentMethods';
import useGetDisplayType from '../../../hooks/useGetDisplayType';
import { DisplayTypes } from '../../../types/enums/DisplayTypes';

import { Preloader } from '../../../components/simpleComponents/Preloader';
import { loginDataSelector } from '../../../store/selectors/user';
import { Button } from '../../../components/simpleComponents/Button';
import { useStyles } from './styles';
import refreshSession from '../../../utils/refreshSession';
import WelcomePage from './WelcomePage/WelcomePage';

export interface NewUserProfileForm {
  domicile: string;
  firstName: string;
  lastName: string;
  socialSecurityNumber: string;
  dob: string;
  phone: string;
  primCountry: string;
  primAddress1: string;
  primAddress2: string;
  primCity: string;
  primState: string;
  primZip: string;
  passportNumber?: string;
  passportScan?: File | null;
  idType?: string;
  idNumber?: string;
  idFront?: File | null;
  idBack?: File | null;
}

export interface NewNonUSUserProfileForm {
  dob: string;
  firstName: string;
  lastName: string;
}

export interface NewCompliance {
  accreditation: string;
  employment: string;
  annualIncome: string;
  netWorth: string;
  risk: string;
  associtationFinra: string;
  hasFinraLicense: string;
  finraLicense: string;
  associatedOrganization: string;
  memberOfTradedPublicly: string;
}

const ConfigureProfile: React.FC = (): JSX.Element => {
  const steps = [
    {
      label: 'Complete your profile',
    },
    {
      label: 'Add payment method',
    },
    {
      label: 'Start browsing assets!',
    },
  ];
  const desktop = !useGetDisplayType(DisplayTypes.Mobile);
  const api = useServerAPI();
  const navigate = useNavigate();
  const loginData = useSelector(loginDataSelector);
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + '/сonfigureProfile');
  }, []);

  const [currentStep, setCurrentStep] = useState(0);

  const [userName, setUserName] = useState('');
  const [profile, setProfile] = useState<NewUserProfileForm>({
    domicile: '',
    firstName: '',
    lastName: '',
    socialSecurityNumber: '',
    dob: '',
    phone: '',
    primCountry: '',
    primAddress1: '',
    primAddress2: '',
    primCity: '',
    primState: '',
    primZip: '',
    passportNumber: '',
    passportScan: null,
    idType: '',
    idNumber: '',
    idFront: null,
    idBack: null,
  });

  const [compliance, setCompliance] = useState<NewCompliance>({
    accreditation: '',
    employment: '',
    annualIncome: '',
    netWorth: '',
    risk: '',
    associtationFinra: '',
    hasFinraLicense: '',
    finraLicense: '',
    associatedOrganization: '',
    memberOfTradedPublicly: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const getProfilePropertySetter =
    <T extends keyof NewUserProfileForm>(key: T) =>
    (value: NewUserProfileForm[T]) =>
      setProfile((profileInner) => ({ ...profileInner, [key]: value }));

  const getCompliancePropertySetter =
    <T extends keyof NewCompliance>(key: T) =>
    (value: NewCompliance[T]) =>
      setCompliance({ ...compliance, [key]: value });

  const [isCompleteProfileActive, setIsCompleteProfileActive] = useState(false);
  const [isAddpaymentMethodsActive, setIsAddpaymentMethodsActive] =
    useState(false);
  const [isAddLegalEntityActive, setIsAddLegalEntityActive] = useState(false);
  const isUserCreated = useRef(false);

  const onButtonClick = async (): Promise<void> => {
    if (currentStep === 0) setIsCompleteProfileActive(true);
    if (currentStep === 1) setIsAddpaymentMethodsActive(true);
    if (currentStep === 2) navigate("/add-entity");
  };

  const onStartBrowsingClick = (): void => {
    currentStep === 2 && navigate('/');
  };

  const onLinkClick = async (): Promise<void> => {
    currentStep === 1 && navigate('/');
  };

  const validateProfile = (): boolean => {
    let result = true;
    const nonValidateKeys: (keyof NewUserProfileForm)[] = [
      'primAddress2',
      'passportScan',
      'idFront',
      'idBack',
    ];
    const properties = (
      Object.keys(profile) as (keyof NewUserProfileForm)[]
    ).filter((property) => !nonValidateKeys.includes(property));
    properties.forEach((property) => {
      if (profile[property] === '') result = false;
    });

    return result;
  };

  const validateCompliance = (): boolean => {
    let result = true;
    const emptyProperties: (keyof NewCompliance)[] = [
      'employment',
      'netWorth',
      'risk',
      'associtationFinra',
      'associatedOrganization',
    ];
    const properties = (Object.keys(profile) as (keyof NewCompliance)[]).filter(
      (property) => !emptyProperties.includes(property)
    );
    properties.forEach((property) => {
      if (property === 'finraLicense' && compliance.hasFinraLicense === 'no')
        return;
      if (compliance[property] === '') result = false;
    });
    return result;
  };

  const updateUser = async (): Promise<void> => {
    await api
      .updateUserProfile(profile, compliance)
      .then(() => refreshSession(api));
  };

  const takeTextForStep = (): string => {
    if (currentStep === 0)
      return 'Before you get started, tell us a little bit about you and your accreditation status so we can tailor your account to you.';
    if (currentStep === 1)
      return 'Add your bank account and credit cards to your account now to make purchasing later easier.';
    return "You're ready to start investing! Remember, if you ever have any other questions, check out our FAQ page or shoot us a quick email.";
  };

  const takeSvgColor = (index: number): string => {
    if (index === currentStep) return '#DFDFE3 !important';
    if (index < currentStep) return 'black !important';
    return '#F4F4F4 !important';
  };

  useEffect(() => {
    if (!isCompleteProfileActive && validateCompliance() && validateProfile()) {
      setCurrentStep(1);
    } else console.log('ERROR');
  }, [isCompleteProfileActive]);

  useEffect(() => {
    setIsLoading(true);
    api
      .getZohoUserInfo()
      .then((response) => {
        if (response) {
          setUserName(response.First_Name);
          getProfilePropertySetter('firstName')(response.First_Name);
          getProfilePropertySetter('lastName')(response.Last_Name);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const isNextDisabledResident = (stepNumber: number): boolean => {
    const p: boolean[] = [
      !!profile.dob,
      !!profile.domicile,
      true, //!!profile.primCountry,
      !!profile.primAddress1 &&
        !!profile.primCity &&
        !!profile.primState &&
        !!profile.primZip,
      !!profile.socialSecurityNumber,
      true,
      profile.phone.replaceAll(/[-()+\b\s]/g, '').length >= 10,
      true,
      !!compliance.accreditation,
      !!compliance.annualIncome,
      !!compliance.hasFinraLicense,
      !!compliance.finraLicense,
      !!compliance.memberOfTradedPublicly,
    ];
    return !p[stepNumber];
  };

  const isNextDisabledCitizen = (stepNumber: number): boolean => {
    const p: boolean[] = [
      !!profile.dob,
      !!profile.domicile,
      !!profile.primAddress1 &&
        !!profile.primCity &&
        !!profile.primState &&
        !!profile.primZip,
      true,
      !!profile.socialSecurityNumber,
      true,
      profile.phone.replaceAll(/[-()+\b\s]/g, '').length >= 10,
      true,
      !!compliance.accreditation,
      !!compliance.annualIncome,
      !!compliance.hasFinraLicense,
      !!compliance.finraLicense,
      !!compliance.memberOfTradedPublicly,
    ];
    return !p[stepNumber];
  };

  const isNextDisabledNonCitizen = (stepNumber: number): boolean => {
    const p: boolean[] = [
      !!profile.dob,
      !!profile.domicile,
      !!profile.primCountry,
      true,
      !!profile.primAddress1 &&
        !!profile.primCity &&
        !!profile.primCountry &&
        !!profile.primZip,
      true,
      profile.phone.replaceAll(/[-()+\b\s]/g, '').length >= 10,
      true,
      !!compliance.accreditation,
      !!compliance.annualIncome,
      !!compliance.hasFinraLicense,
      !!compliance.finraLicense,
      !!compliance.memberOfTradedPublicly,
    ];
    return !p[stepNumber];
  };

  const ConfigureProfileMenu = (
    <Container maxWidth="xs" className={classes.container}>
      <Box className={classes.content}>
        <Typography
          component="h4"
          variant="h6"
          className={classes.userNameText}
        >
          {userName},
        </Typography>
        <Typography component="h4" variant="h6" className={classes.welcomeText}>
          Welcome to LUXUS!
        </Typography>
        <Typography component="span" className={classes.stepText}>
          {takeTextForStep()}
        </Typography>
        <Stepper
          activeStep={currentStep}
          alternativeLabel
          className={classes.stepper}
          // orientation={desktop ? "horizontal" : "vertical"}
          connector={
            <StepConnector classes={{ line: classes.stepperConnector }} />
          }
        >
          {steps.map((step, index) => (
            <Step
              key={step.label}
              className={classNames(
                classes.step,
                { [classes.currStep]: index === currentStep },
                { [classes.notCurrStep]: index < currentStep }
              )}
            >
              <StepLabel className={classes.stepLabel}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {currentStep === 0 || currentStep === 1 ? (
          <Button
            className={classes.blackBtn}
            view="black"
            onClick={onButtonClick}
          >
            {steps[currentStep].label}
          </Button>
        ) : (
          <Box className={classes.buttonsBox}>
            <Button
              className={classes.legalEntityBtn}
              view="black"
              onClick={onButtonClick}
            >
              Add Legal Entity
            </Button>
            <Button
              className={classes.textBtn}
              view="primary"
              onClick={onStartBrowsingClick}
            >
              Start Browsing!
            </Button>
          </Box>
        )}

        {desktop && currentStep === 0 && (
          <Link to="/" className={classes.link}>
            I'll do this later
          </Link>
        )}
        {desktop && currentStep === 1 && (
          <Typography onClick={onLinkClick} className={classes.skip}>
            I'll do this later
          </Typography>
        )}
      </Box>
    </Container>
  );

  return isLoading ? (
    <Preloader />
  ) : (
    <>
      {!isCompleteProfileActive &&
        !isAddpaymentMethodsActive &&
        !isAddLegalEntityActive && (
          // ConfigureProfileMenu
          // <ConfigureForm />
          // <VerifyIdentity />
          // <QuestionPage />
          <WelcomePage />
        )}
      {isCompleteProfileActive && (
        <CompleteProfile
          profile={profile}
          complience={compliance}
          getProfilePropertySetter={getProfilePropertySetter}
          getCompliancePropertySetter={getCompliancePropertySetter}
          isNextDisableResident={isNextDisabledResident}
          isNextDisableCitizen={isNextDisabledCitizen}
          isNextDisableNonResident={isNextDisabledNonCitizen}
          close={() => {
            updateUser();
            setIsCompleteProfileActive(false);
            setCurrentStep(1);
          }}
        />
      )}
      {isAddpaymentMethodsActive && (
        <AddPaymentMethods
          onSkipClick={() => {
            setIsAddpaymentMethodsActive(false);
            setCurrentStep(2);
          }}
        />
      )}
    </>
  );
};

export default ConfigureProfile;
