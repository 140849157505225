import Rectangle from 'assets/icons/Rectangle';

export const ASCOT_RACE_DATA = [
  {
    id: 1,
    Icon: Rectangle,
    text: 'Exclusive Access - Enjoy owner-level privileges with VIP Boxes and exclusive paddock passes at Royal Ascot',
  },
  {
    id: 2,
    Icon: Rectangle,
    text: 'Aligned Incentives: Benefit from a transparent incentive model offering 80% of winnings, (instead of 80% fees on traditional betting platforms)',
  },
  {
    id: 3,
    Icon: Rectangle,
    text: "Hands-On Experiences at Lane's End: Meet legendary stallions and famous brood mares with exclusive behind-the-scenes tours at Lane's End",
  },
  {
    id: 4,
    Icon: Rectangle,
    text: 'Expert Insights: Participate in an in-person masterclass on horse breeding and thoroughbred racing taught by industry leading experts',
  },
];
