import React, { useEffect } from 'react';
import { UnloggedCard } from './UnloggedCard/UnloggedCard';
import { assets, futureOfferings } from './data';
import { CustomButton } from '../../components/UIComponents/Button';
import useAssetsFacade from '../NewAssests/Assets/useAssetsFacade';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import TiaraAsset from 'pages/Dashboard/components/TiaraAsset/TiaraAsset';

import { HEADER_OPTIONS } from '../../components/Header/constants';

import style from './style.module.scss';
import { MAIN_URL, ROUTES } from 'utils/constants';
import ThoroughbredAsset from 'pages/Dashboard/components/ThoroughbredAsset/ThoroughbredAsset';
import { handleSectionScroll } from 'utils/handleSectionScroll';
import {
  SCROLL_QUERY_PARAM,
  STREAM_SECTION_ID,
} from 'pages/NewAssests/Assets/components/StreamCountdown/constants';

export const AssetsUnlogged: React.FC = () => {
  useEffect(() => {
    handleSectionScroll(SCROLL_QUERY_PARAM, STREAM_SECTION_ID);
  }, []);
  const [
    desktop,
    isShowMoreCurrent,
    setIsShowMoreCurrent,
    isShowMoreUpcoming,
    setIsShowMoreUpcoming,
  ] = useAssetsFacade();

  const navigate = useNavigate();

  const onByBtnClick = (): void => {
    navigate(`/login`);
  };

  return (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <div className={style.assetContainer}>
        <h1>OUR ASSETS</h1>
        <h6>The Era of Luxury Assets as an Investment Class is Here.</h6>
        <div className={style.cardsContainer}>
          <TiaraAsset isShowMoreUpcoming={isShowMoreUpcoming} />
          {isShowMoreUpcoming && (
            <>
              <ThoroughbredAsset isShowMoreUpcoming={isShowMoreUpcoming} />
              {assets.map((item, index) =>
                !isShowMoreUpcoming && index > 0 ? null : (
                  <UnloggedCard
                    key={index}
                    {...item}
                    onByBtnClick={onByBtnClick}
                    factSheetButtonText={item.factSheetButtonText}
                    factSheetLink={item.factSheetLink}
                    isHideHint={item.name.toLowerCase().includes('kwiat')}
                  />
                )
              )}
            </>
          )}
        </div>
        {!isShowMoreUpcoming && (
          <div className={style.buttonContainer}>
            <CustomButton
              onClick={() => setIsShowMoreUpcoming(true)}
              btnStyle={'secondaryForBg'}
            >
              VIEW ALL ASSETS
            </CustomButton>
          </div>
        )}
        {/* <h2 className={style.soonTitle}>COMING SOON...</h2> */}
        {/* <div className={style.soonContainer}>
          {futureOfferings.map((item, index) => (
            <SoonCard key={index} {...item} />
          ))}
        </div> */}
      </div>
      <Footer pageName="Assets page" pageUri={`${MAIN_URL}${ROUTES.ASSETS}`} />
    </>
  );
};
