const Rectangle = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="8.5"
        width="11"
        height="11"
        transform="rotate(-45 0.5 8.5)"
        fill="white"
      />
    </svg>
  );
};

export default Rectangle;
