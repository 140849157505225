import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        marginRight: "0",
      },
      preview: {
        width: "370px",
        height: "370px",
        margin: "0 auto",
      },
    })
  )
);
