import { themeData } from "../../../../utils/theme";

export const commonStyles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflow: "hidden",
    padding: "95px 0 45px 0",
    boxSizing: "border-box",
  },
  content: {
    maxWidth: "600px",
    width: "100%",
    marginBottom: "45px",
  },
  content2: {
    maxWidth: "600px",
    width: "100%",
    marginBottom: "33px",
    padding: "25px",
  },
  content3: {
    maxWidth: "600px",
    width: "100%",
    textAlign: "center",
  },
  imgBox: {
    width: "100%",
    "& img": {
      width: "100%",
      border: "1px solid #DFDFE3",
      maxWidth: "600px",
    },
  },
  title: {
    fontSize: "30px",
    fontWeight: "450",
    marginBottom: "17px",
  },
  signBtn: {
    width: "100%",
  },
};

export type ClassesNames = keyof typeof commonStyles;
