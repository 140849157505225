import React, {useEffect, useState} from "react";
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {useSelector} from "react-redux";

import useGetDisplayType from "../../../hooks/useGetDisplayType";
import {DisplayTypes} from "../../../types/enums/DisplayTypes";

import {useStyles} from "./styles/";

import PaymentMethods from "./components/PaymentMethod/PaymentMethods";
import {Preloader} from "../../../components/simpleComponents/Preloader";
import {ChangePaymentModal} from "./components/ChangePaymentModal";
import PaymentPopUp from "../../../components/NewPaymentPopUp/PaymentPopUp";
import {PaymentMode} from "../../../types/enums/PaymentMode";
import ReactGA from "react-ga";
import {useNavigate, useParams} from "react-router-dom";
import {useServerAPI} from "../../../apis";
import {Offering} from "../../../types/Offerings";
import {BankAccountData, CreditCardData} from "../../../types/Account";
// import { getBankInfo, getCardInfo } from "./utils";
import {getData} from "./data";
import {
  handleBuyShareClick,
  handleChangePaymentMethodClick,
  handleCloseAgreementModal,
  handleCloseEditPayment,
  handleCloseModal,
  handleDoneAgreementModal,
  handleEditPayment,
  handleSavePayment,
} from "./handlers";
import {Button} from "../../../components/simpleComponents/Button";
import {Details} from "./components/Details";
import {Toggles} from "./components/Toggles";
import {PaymentType} from "../../../types/enums/PaymentType";
import {AgreementModal} from "./components/AgreementModal";
import {BuyAsSelectField} from "./components/BuyAsSelectField";
import {ipSelector} from "../../../store/selectors/system";
import {SelectIdField} from "./components/SelectIdField";
import style from './style.module.scss'
import {CustomButton} from "../../../components/UIComponents/Button";

const BuyAsset: React.FC = function (): JSX.Element {
  const classes = useStyles();
  const ip = useSelector(ipSelector);

  const isDesktopAndTablet = !useGetDisplayType(DisplayTypes.Mobile);
  const isDesktop = useGetDisplayType(DisplayTypes.Desktop);
  const [isLoading, setIsLoading] = useState(false);
  const [isBuyLoadActive, setIsBuyLoadActive] = useState(false);
  const [reservIp, setReserveIp] = useState<string>();

  const id = useParams().id;
  const api = useServerAPI();
  const navigate = useNavigate();

  const [offer, setOffer] = useState<Offering>();
  const [bankData, setBankData] = useState<BankAccountData>();
  const [cardData, setCardData] = useState<CreditCardData>();

  const [isAgreeWithSubscribtion, setIsAgreeWithSubscribtion] = useState(false);
  const [isAgreeWithTerms, setIsAgreeWithTerms] = useState(false);
  const [sign, setSign] = useState("");
  const [dateSign, setDateSign] = useState(new Date());
  const [isAgreementOpen, setIsAgreementOpen] = useState<boolean>(false);
  const [accountId, setAccountId] = useState("");

  const [chosenPaymentMethods, setChosenMethods] = useState<PaymentType>(
    PaymentType.WIRE
  );

  const [itemsCount, setItemsCount] = useState(1);
  const [mode, setMode] = useState<PaymentMode>(PaymentMode.EMPTY);

  const isSignDisabled: boolean = !(
    (itemsCount > 0 && isAgreeWithSubscribtion)
    // && isAgreeWithTerms
  );

  const isBuyDisabled: boolean = !(
    !isSignDisabled &&
    Boolean(sign) &&
    isAgreeWithTerms
  );

  const handleToggleSubscribtion = (): void =>
    setIsAgreeWithSubscribtion(!isAgreeWithSubscribtion);

  const handleToggleTerms = (): void => setIsAgreeWithTerms(!isAgreeWithTerms);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/BuyAsset");
    if (id) {
      getData(
        id,
        api,
        setOffer,
        setIsLoading,
        setBankData,
        setCardData,
        setChosenMethods
      );
    }
    if (!ip) {
      api.getIPFromAmazon().then((res) => {
        if (res) setReserveIp(res);
      });
    }
  }, []);

  const handlePurchaseArgument = {
    transactionType: chosenPaymentMethods,
    transactionUnits: itemsCount,
    offeringId: parseInt(id || "0", 10),
    field2: JSON.stringify({sign, ip: ip || reservIp}),
    field3: dateSign,
  };

  return isLoading || !offer ? (
    <Preloader/>
  ) : (
    <div className={style.container}>
      {/* Photo and PaymentBox */}
      <div className={style.content}>
        <img src={offer?.image?.url ?? ""}/>
        <h2 className={style.title}>{offer?.name}</h2>
        <div className={style.priceBlock}>
          <div className={style.price}>
            <h6>TOTAL ASSET VALUE</h6>
            <h4>${offer?.offering?.targetAmount}</h4>
          </div>
          <div className={style.price}>
            <h6>SHARE PRICE</h6>
            <h4>${offer?.offering?.unitPrice}</h4>
          </div>
        </div>
        {/* <Details offer={offer}/>*/}
        {/*<Stack direction="row" spacing={3}>
          <Box className={classes.imgBox}>
            <img src={offer?.image?.url ?? ""} />
            <Details offer={offer} />
          </Box>

          {isDesktopAndTablet && (
            <PaymentMethods
              bankData={bankData}
              cardData={cardData}
              itemsCount={itemsCount}
              changeItemsCount={setItemsCount}
              unitPrice={parseFloat(offer.offering?.unitPrice || "0")}
              paymentType={chosenPaymentMethods}
              handleChangePayment={handleChangePaymentMethodClick(setMode)}
            />
          )}
        </Stack>*/}

        <SelectIdField setAccountId={setAccountId}/>
        {/* Toggles and SIGN button */}
        <div className={style.conformation}>
          <div>
            <Toggles
              isDesktop={isDesktopAndTablet}
              toggleSubscribtion={handleToggleSubscribtion}
              toggleTerms={handleToggleTerms}
            >
              <CustomButton
                disabled={isSignDisabled}
                onClick={() => setIsAgreementOpen(true)}
                btnStyle={'secondary'}
              >
                SIGN
              </CustomButton>
            </Toggles>
          </div>
          <PaymentMethods
            bankData={bankData}
            cardData={cardData}
            itemsCount={itemsCount}
            changeItemsCount={setItemsCount}
            unitPrice={parseFloat(offer.offering?.unitPrice || "0")}
            paymentType={chosenPaymentMethods}
            handleChangePayment={handleChangePaymentMethodClick(setMode)}
          />
        </div>
        {/* {!isDesktop && <BuyAsSelectField setAccountId={setAccountId} />}
      {!isDesktopAndTablet && (
        <PaymentMethods
          bankData={bankData}
          cardData={cardData}
          itemsCount={itemsCount}
          changeItemsCount={setItemsCount}
          unitPrice={parseFloat(offer.offering?.unitPrice || "0")}
          paymentType={chosenPaymentMethods}
          handleChangePayment={handleChangePaymentMethodClick(setMode)}
        />
      )}*/}
        {/* SUMBIT ORDER button */}
        <CustomButton
          btnStyle={'primary'}
          disabled={isBuyDisabled || isBuyLoadActive}
          onClick={handleBuyShareClick(
            accountId
              ? {accountId, ...handlePurchaseArgument}
              : handlePurchaseArgument,
            api,
            navigate,
            setIsBuyLoadActive
          )}
        >
          SUBMIT ORDER
        </CustomButton>
      </div>
      {/* CHANGE PAYMENT modal window */}
      <ChangePaymentModal
        isLoading={mode === PaymentMode.LOADING}
        isOpen={mode === PaymentMode.PAYMENT || mode === PaymentMode.LOADING}
        paymentType={chosenPaymentMethods}
        creditCardData={cardData}
        bankAccountData={bankData}
        onClose={handleCloseModal(setMode, setChosenMethods)}
        onEdit={handleEditPayment(setMode)}
        offering={offer}
      />

      {/* EDIT PAYMENT modal window */}
      <PaymentPopUp
        selectedType={mode}
        showModal={
          mode === PaymentMode.BANK ||
          mode === PaymentMode.CARD ||
          mode === PaymentMode.NEW
        }
        handleClose={handleCloseEditPayment(setMode)}
        onCancel={handleCloseEditPayment(setMode)}
        onSave={handleSavePayment(
          api,
          setMode,
          setBankData,
          setCardData,
          setChosenMethods
        )}
        creditCardData={mode === PaymentMode.NEW ? undefined : cardData}
        bankAccountData={mode === PaymentMode.NEW ? undefined : bankData}
      />

      {/* SUBSCRIPTION AGREEMENT modal */}
      <AgreementModal
        isDesktop={isDesktopAndTablet}
        isOpen={isAgreementOpen}
        dateSign={dateSign}
        handleClose={handleCloseAgreementModal(setIsAgreementOpen)}
        handleDone={handleDoneAgreementModal(setIsAgreementOpen, setSign)}
      />
    </div>
  );
};

export default BuyAsset;
