export const agreementText = `

                SUBSCRIPTION AGREEMENT

THIS INVESTMENT INVOLVES A HIGH DEGREE OF RISK. THIS INVESTMENT IS SUITABLE ONLY FOR PERSONS WHO CAN BEAR THE ECONOMIC RISK FOR AN INDEFINITE PERIOD OF TIME AND WHO CAN AFFORD TO LOSE THEIR ENTIRE INVESTMENT. FURTHERMORE, INVESTORS MUST UNDERSTAND THAT SUCH INVESTMENT IS ILLIQUID AND IS EXPECTED TO CONTINUE TO BE ILLIQUID FOR AN INDEFINITE PERIOD OF TIME. NO PUBLIC MARKET EXISTS FOR THE SECURITIES, AND NO PUBLIC MARKET IS EXPECTED TO DEVELOP FOLLOWING THIS OFFERING.

THE SECURITIES OFFERED HEREBY HAVE NOT BEEN REGISTERED UNDER THE SECURITIES ACT OF 1933, AS AMENDED (THE “ACT”), OR ANY STATE SECURITIES OR BLUE SKY LAWS AND ARE BEING OFFERED AND SOLD IN RELIANCE ON EXEMPTIONS FROM THE REGISTRATION REQUIREMENTS OF THE ACT AND STATE SECURITIES OR BLUE SKY LAWS. ALTHOUGH AN OFFERING STATEMENT HAS BEEN FILED WITH THE SECURITIES AND EXCHANGE COMMISSION (THE “SEC”), THAT OFFERING STATEMENT DOES NOT INCLUDE THE SAME INFORMATION THAT WOULD BE INCLUDED IN A REGISTRATION STATEMENT UNDER THE ACT. THE SECURITIES HAVE NOT BEEN APPROVED OR DISAPPROVED BY THE SEC, ANY STATE SECURITIES COMMISSION OR OTHER REGULATORY AUTHORITY, NOR HAVE ANY OF THE FOREGOING AUTHORITIES PASSED UPON THE MERITS OF THIS OFFERING OR THE ADEQUACY OR ACCURACY OF THE SUBSCRIPTION AGREEMENT OR ANY OTHER MATERIALS OR INFORMATION MADE AVAILABLE TO SUBSCRIBER IN CONNECTION WITH THIS OFFERING OVER THE WEBSITE MAINTAINED BY THE COMPANY OR THROUGH DALMORE GROUP, LLC (THE “BROKER”).

INVESTORS WHO ARE NOT “ACCREDITED INVESTORS” (AS THAT TERM IS DEFINED IN SECTION 501 OF REGULATION D PROMULGATED UNDER THE ACT) ARE SUBJECT TO LIMITATIONS ON THE AMOUNT THEY MAY INVEST, AS SET OUT IN SECTION 4. THE COMPANY IS RELYING ON THE REPRESENTATIONS AND WARRANTIES SET FORTH BY EACH SUBSCRIBER IN THIS SUBSCRIPTION AGREEMENT AND THE OTHER INFORMATION PROVIDED BY SUBSCRIBER IN CONNECTION WITH THIS OFFERING TO DETERMINE THE APPLICABILITY TO THIS OFFERING OF EXEMPTIONS FROM THE REGISTRATION REQUIREMENTS OF THE ACT.

THE OFFERING MATERIALS MAY CONTAIN FORWARD-LOOKING STATEMENTS AND INFORMATION RELATING TO, AMONG OTHER THINGS, THE COMPANY, ITS BUSINESS PLAN AND STRATEGY, AND ITS INDUSTRY. THESE FORWARD-LOOKING STATEMENTS ARE BASED ON THE BELIEFS OF, ASSUMPTIONS MADE BY, AND INFORMATION CURRENTLY AVAILABLE TO THE COMPANY’S MANAGEMENT. WHEN USED IN THE OFFERING MATERIALS, THE WORDS “ESTIMATE,” “PROJECT,” “BELIEVE,” “ANTICIPATE,” “INTEND,” “EXPECT” AND SIMILAR EXPRESSIONS ARE INTENDED TO IDENTIFY FORWARD-LOOKING STATEMENTS, WHICH CONSTITUTE FORWARD LOOKING STATEMENTS. THESE STATEMENTS REFLECT MANAGEMENT’S CURRENT VIEWS WITH RESPECT TO FUTURE EVENTS AND ARE SUBJECT TO RISKS AND UNCERTAINTIES THAT COULD CAUSE THE COMPANY’S ACTUAL RESULTS TO DIFFER MATERIALLY FROM THOSE CONTAINED IN THE FORWARD-LOOKING STATEMENTS. INVESTORS ARE CAUTIONED NOT TO PLACE UNDUE RELIANCE ON THESE FORWARD-LOOKING STATEMENTS, WHICH SPEAK ONLY AS OF THE DATE ON WHICH THEY ARE MADE. THE COMPANY DOES NOT UNDERTAKE ANY OBLIGATION TO REVISE OR UPDATE THESE FORWARD-LOOKING STATEMENTS TO REFLECT EVENTS OR CIRCUMSTANCES AFTER SUCH DATE OR TO REFLECT THE OCCURRENCE OF UNANTICIPATED EVENTS.

THE COMPANY MAY NOT BE OFFERING THE SECURITIES IN EVERY STATE. THE OFFERING MATERIALS DO NOT CONSTITUTE AN OFFER OR SOLICITATION IN ANY STATE OR JURISDICTION IN WHICH THE SECURITIES ARE NOT BEING OFFERED.

THE COMPANY RESERVES THE RIGHT IN ITS SOLE DISCRETION AND FOR ANY REASON WHATSOEVER TO MODIFY, AMEND AND/OR WITHDRAW ALL OR A PORTION OF THE OFFERING AND/OR ACCEPT OR REJECT IN WHOLE OR IN PART ANY PROSPECTIVE INVESTMENT IN THE SECURITIES OR TO ALLOT TO ANY PROSPECTIVE INVESTOR LESS THAN THE AMOUNT OF SECURITIES SUCH INVESTOR DESIRES TO PURCHASE. EXCEPT AS OTHERWISE INDICATED, THE OFFERING MATERIALS SPEAK AS OF THEIR DATE. NEITHER THE DELIVERY NOR THE PURCHASE OF THE SECURITIES SHALL, UNDER ANY CIRCUMSTANCES, CREATE ANY IMPLICATION THAT THERE HAS BEEN NO CHANGE IN THE AFFAIRS OF THE COMPANY SINCE THAT DATE.


`;
