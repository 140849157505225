import classNames from "classnames";
import moment from "moment";

import formatNumber from "utils/FormatNumber";
import { transformStatus } from "utils/transformStatus";

import styles from "./AssetsItemExpandDesktop.module.scss";

type AssetsItemExpandDesktopProps = {
  date?: string | object;
  amount: string;
  status?: string | object;
  itemAmount?: string;
};

const AssetsItemExpandDesktop = ({
  date,
  amount,
  status,
  itemAmount,
}: AssetsItemExpandDesktopProps) => {
  const transformedDate = moment(date).format("LL");

  const transformedAmount = formatNumber(amount.split(".")[0]);

  const orderStatus = transformStatus(status);

  const sharesQuantity = itemAmount?.split(".")[0] || 0;

  const statusStyles = classNames({
    [styles.pendingSpan]: orderStatus === "Pending",
  });

  return (
    <li className={styles.listItem}>
      <div className={styles.spanWrapper}>
        <span>{transformedDate}</span>
      </div>
      <div className={styles.spanWrapper}>
        <span>${transformedAmount}</span>
      </div>
      <div className={styles.spanWrapper}>
        <span>{sharesQuantity}</span>
      </div>
      <div className={styles.spanWrapper}>
        <span className={statusStyles}>{orderStatus}</span>
      </div>
    </li>
  );
};

export default AssetsItemExpandDesktop;
