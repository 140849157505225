import React from "react";
import { Box, Typography, Button, useMediaQuery, Link } from "@mui/material";
import LinearWithValueLabel from "../../../../../../components/LinearProgressWithLabel";

import "../../OfferCard.css";

export interface OfferCardProps {
  name: string;
  totalPrice: string;
  pricePerShare: string;
  imageSrc: string;
  funded: number;
  onBuyButtonClick: () => void;
  viewDealSheetUrl: string;
  status?: string;
}

const OfferCard: React.FC<OfferCardProps> = function ({
  imageSrc,
  name,
  totalPrice,
  pricePerShare,
  funded,
  onBuyButtonClick,
  viewDealSheetUrl,
  status,
}): JSX.Element {
  const desktop = useMediaQuery("(min-width:900px)");

  return (
    <Box
      height={desktop ? "333px" : "441px"}
      width={desktop ? "399px" : "362px"}
      border={"3px solid #CBCBCB"}
      borderRadius="10px"
      position={"relative"}
    >
      <Box
        className={desktop ? "offer-card" : "offer-card-mobile"}
        sx={{
          padding: "40px 20px 20px 20px",
        }}
      >
        <img
          className="asset-image"
          alt={name}
          src={imageSrc}
          style={
            desktop
              ? {}
              : {
                  margin: "0 auto",
                  widows: "179px",
                }
          }
        />
        <Typography
          sx={{
            gridArea: "name",
            fontSize: "25px",
            fontWeight: "450",
            margin: desktop ? "0 0 10px 0" : "0 auto 10px auto",
            lineHeight: "25px",
            width: "212px",
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            gridArea: "total-asset-title",
            fontSize: "20px",
            fontWeight: "250",
            margin: desktop ? "" : "0 auto",
          }}
        >
          Total Asset Value
        </Typography>
        <Typography
          sx={{
            gridArea: "total-asset-value",
            fontSize: "20px",
            fontWeight: "600",
            margin: desktop ? "0 0 5px 0" : "0 auto",
          }}
        >
          ${totalPrice}
        </Typography>
        <Typography
          sx={{
            gridArea: "price-per-share-title",
            fontSize: "20px",
            fontWeight: "250",
            margin: desktop ? "" : "0 auto",
          }}
        >
          Price Per Share
        </Typography>
        <Typography
          sx={{
            gridArea: "price-per-share-value",
            fontSize: "20px",
            fontWeight: "600",
            margin: desktop ? "" : "0 auto",
          }}
        >
          ${pricePerShare}
        </Typography>
      </Box>
      <Box
        sx={{
          width: desktop ? "62%" : "85%",
          padding: desktop ? "0" : "0 28px",
          margin: desktop ? "0 auto" : "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          position: "absolute",
          bottom: "11px",
          left: "20%",
        }}
      >
        <Box
          sx={{
            width: desktop ? "75%" : "85%",
            paddingTop: "7px",
            marginBottom: "15px",
          }}
        >
          <LinearWithValueLabel
            progress={funded}
            postPercentLabel="Funded"
            classes={{
              root: "offer-card-linear-progress-bar-1",
              bar1Determinate: "offer-card-linear-progress-bar-root",
            }}
            mainBoxSx={{
              marginBottom: "0",
              width: "206px",
            }}
            percentSx={{ position: "absolute", left: "5px" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "rc",
            justifyContent: "space-between",
          }}
        >
          <Link
            href={viewDealSheetUrl}
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              padding: "0 10px",
              fontSize: "19px",
              fontWeight: "400",
              backgroundColor: "#F4F4F4",
              borderRadius: "6px",
              border: "none",
              "&:hover": {
                backgroundColor: "rgba(86, 141, 255, 0.04)",
              },
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            VIEW DEAL SHEET
          </Link>
          <Button
            variant="contained"
            onClick={onBuyButtonClick}
            sx={{
              padding: "1px 10px !important",
              fontSize: "18px !important",
              fontWeight: "400 !important",
              "&:hover": {
                backgroundColor: "#b0b0ba",
              },
            }}
          >
            {status === "Active" && "Buy"}
            {status === "IOI" && "IOI"}
            {status === "SoldOut" && "View"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OfferCard;
