import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Footer } from "components/Footer/Footer";
import Header from "components/Header/Header";
import { useServerAPI } from "./apis";
import AssestsAlt from "./pages/NewAssests/Assets/AssetsAlt";
import { userProfileSelector } from "./store/selectors/user";
import requestUserProfile from "./utils/requestUserProfile";

import { HEADER_OPTIONS } from "components/Header/constants";
import { MAIN_URL } from "utils/constants";

import "./Main.css";

const App = () => {
  const api = useServerAPI();
  const userProfile = useSelector(userProfileSelector);

  const isUserAuthorized = !!localStorage.getItem("rppToken");

  useEffect(() => {
    if (!userProfile && isUserAuthorized) requestUserProfile(api);
  });

  return (
    <div>
      <Header color={HEADER_OPTIONS.WHITE} />
      <AssestsAlt />
      <Footer removeForm={true} pageName="Home page" pageUri={MAIN_URL} />
    </div>
  );
};

export default App;
