import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BasicOfferCard,
  ChildsCardProps
} from "../../../../Assets/components/CurrentOfferingAlt/components/BasicOfferCard/BasicOfferCard";
import Diamond from './../../../../../AssetsUnlogged/assets/diamond.png'

import { useStyle } from "./styles";

type SoldOutOfferingCardProps = ChildsCardProps;

const SoldOutOfferingCard: React.FC<SoldOutOfferingCardProps> = function (
  props
): JSX.Element {
  const navigate = useNavigate();
  const { leftCornerTitleBoxClass } = useStyle();
  const onByBtnClick = (): void => {
    navigate(`/assetDetails/${props.offer.offeringId}`);
  };
  return (
    <BasicOfferCard
      {...props}
      leftCornerTitleBoxClass={leftCornerTitleBoxClass}
      leftCornerTitle="SOLD OUT"
      buyButtonText="View Asset"
      onBuyBtnClick={onByBtnClick}
      lastInfoTitleType="Origin / Mine"
      type="large"
      btnType={"inlineGrey"}
      img={Diamond}
    />
  );
};

export default SoldOutOfferingCard;
