import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfile } from "../../../types/Account";

import type UserInititalState from "./interface";

const initialState: UserInititalState = {
  username: "",
  password: "",
  userProfile: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginData(
      state,
      {
        payload,
      }: PayloadAction<Pick<UserInititalState, "username" | "password">>
    ) {
      state.username = payload.username;
      state.password = payload.password;
    },
    setUserProfileData(
      state,
      { payload }: PayloadAction<UserProfile | undefined>
    ) {
      state.userProfile = payload;
    },
    resetUserProfile(state, action) {
      state.userProfile = undefined;
    },
  },
});

export const { setLoginData, setUserProfileData, resetUserProfile } =
  userSlice.actions;
export default userSlice.reducer;
