export const HORSE_DETAILS = [
  {
    id: 1,
    title: 'Color',
    value: 'Chestnut',
  },
  {
    id: 2,
    title: 'Gender',
    value: 'Colt',
  },
  {
    id: 3,
    title: 'Birth Date',
    value: 'Feb 9, 2019',
  },
  {
    id: 4,
    title: 'Birth Place',
    value: 'Kentucky',
  },
  {
    id: 5,
    title: 'Groomed at',
    value: "Lane's End",
  },
  {
    id: 6,
    title: 'Breeder',
    value: 'Framont LTD',
  },
  {
    id: 7,
    title: 'Total Raise',
    value: '$60k',
  },
  {
    id: 8,
    title: 'Location',
    value: 'Lexington, KY',
  },
  {
    id: 9,
    title: 'Trainer',
    value: 'John W Sadler',
  },
];
