import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "85%",
  },
  sign: {
    fontSize: "30px",
    fontWeight: "450",
    lineHeight: "59px",
    height: "52px",
    marginRight: "5px",
  },
  field: {
    width: "270px",
    height: "43px",
    margin: "15px 0",
  },
};

export type ClassesNames = keyof typeof commonStyles;
