import React from 'react';
import { Box, Typography } from '@mui/material';

interface TabPanelProps {
  index: number;
  value: number;
}

const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = function (
  props
): JSX.Element {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
