import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "85%",
  },
  numberField: {
    width: "314px",
    height: "43px",
    paddingBottom: "15px",
    "& label": {
      marginRight: "15px",
    },
  },
  errorText: {
    color: themeData.ErrorTextColor,
    fontSize: "0.75rem",
    lineHeight: "1.66",
    marginTop: "4px",
    marginX: "14px",
    marginBottom: "0px",
  },
  input: {
    width: "314px",
    height: "43px",
    paddingBottom: "15px",
    "& label": {
      marginRight: "15px",
    },
  }
};

export type ClassesNames = keyof typeof commonStyles;
