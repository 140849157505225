import React from "react";

import useBasicOfferCardFacade from "./useBasicOfferCardFacade";
import {
  ButtonStyle,
  CustomButton,
} from "../../../../../../../components/UIComponents/Button";
import { Offering } from "../../../../../../../types/Offerings";

import "./style.scss";

export interface BasicOfferCardProps {
  offer: Offering;
  buyButtonText: string;
  onBuyBtnClick: () => void;
  lastInfoTitleType: string;
  leftCornerTitle?: string;
  leftCornerTitleBoxClass?: string;
  type?: "large" | "small";
  btnType: ButtonStyle;
  img: string;
  isHideHint?: boolean;
}

export type ChildsCardProps = Omit<
  BasicOfferCardProps,
  | "leftCornerTitle"
  | "leftCornerTitleBoxClass"
  | "buyButtonText"
  | "onBuyBtnClick"
  | "lastInfoTitleType"
  | "btnType"
  | "img"
>;

export const BasicOfferCard: React.FC<BasicOfferCardProps> = function (
  props
): JSX.Element {
  const {
    offer,
    buyButtonText,
    leftCornerTitle,
    onBuyBtnClick,
    btnType,
    img,
    isHideHint = false,
  } = props;
  const { formatNumber, onViewDealSheetButtonClick } =
    useBasicOfferCardFacade(props);

  const leftDetails = offer.details.slice(0, 3);
  const rightDetails = offer.details.slice(3, 6);

  return (
    <div className={"container"}>
      {leftCornerTitle && !isHideHint && (
        <div className={"labelItem"}>
          <h2 className={"labelText"}>{leftCornerTitle}</h2>
        </div>
      )}
      <div className={"content"}>
        <img src={img} className={"image"} alt={offer.name} />{" "}
        {/*src={offer.imageBlack?.url}*/}
        <div className={"contentInfo"}>
          <h2 className={"itemTitle"}>{offer.name}</h2>
          <h3 className={"itemDescription"}>{offer.shortdesc ?? ""}</h3>
          <div className={"moreDescription"}>
            <div className={"infoContainer"}>
              <hr className={"line"} />
              {leftDetails.map((item) => (
                <div key={item.label}>
                  <div className={"table"}>
                    <h4 className={"labelName"}>{item.label}</h4>
                    <h4 className={"valueText"}>{item.value}</h4>
                  </div>
                  <hr className={"line"} />
                </div>
              ))}
            </div>

            <div className={"infoContainer"} style={{ width: "100%" }}>
              <hr className={"lineMore"} />
              {rightDetails.map((item) => (
                <div key={item.label}>
                  <div className={"table"}>
                    <h4 className={"labelName"}>{item.label}</h4>
                    <h4 className={"valueText"}>{item.value}</h4>
                  </div>
                  <hr className={"line"} />
                </div>
              ))}
            </div>
          </div>
          <div className={"offeringsContainer"}>
            <div className={"offeringsBlock"}>
              <div className={"offering"}>
                <p className={"price"}>
                  ${formatNumber(offer.offering?.targetAmount ?? "")}
                </p>
                <p className={"priceDescription"}>Value</p>
              </div>
              <div className={"offering"}>
                <p className={"price"}>
                  ${formatNumber(offer.offering?.unitPrice ?? "")}
                </p>
                <p className={"priceDescription"}>/share</p>
              </div>
            </div>
            <div className={"buttonsContainer"}>
              <CustomButton
                btnStyle={"secondaryBlack"}
                onClick={onViewDealSheetButtonClick}
              >
                <span className="buttonsContainer__text">VIEW DEAL SHEET</span>
              </CustomButton>
              <CustomButton onClick={onBuyBtnClick} btnStyle={btnType}>
                <span className="buttonsContainer__text">{buyButtonText}</span>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
