import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      select: {
        height: "44px",
        width: "240px",
      },
      placeholder: {
        fontSize: "18px",
      },
      nonUs: {
        fontSize: "18px",
        textAlign: "center",
      },
      formBox: {
        width: "auto",
      },
    })
  )
);
