import EditIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Offering } from '../../../types/Offerings';
import { WireDetailsType } from '../../../types/SystemConfig';
import themeData from '../../../utils/theme';
import { WireInstructions } from './WireInstructions';

interface WirePaymentMethodTileProps {
  details: WireDetailsType;
  offering?: Offering;
  investorName: string;
}

export const WirePaymentMethodTile = ({
  details,
  offering,
  investorName,
}: WirePaymentMethodTileProps) => {
  const [showEditPayment, setShowEditPayment] = useState(false);
  const narrowScreen = useMediaQuery('(max-width:400px)');

  if (!offering) return null;

  const name = 'Wire Transfer';

  const editMethodHandler = () => {
    setShowEditPayment(true);
  };

  return (
    <>
      <Grid container marginY={'0.5em'}>
        {/* <Box display={'flex'} flexDirection='row' alignItems={'center'}> */}
        <Grid item md={5} xs={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              align: 'left',
              // marginLeft: '0.5rem',
              width: { md: '120px', xs: '100%' },
              overflow: 'clip',
            }}
          >
            <Typography
              component="h6"
              noWrap
              sx={{
                color: '#000000',
                fontSize: '14px',
              }}
            >
              {name}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={5} xs={5}>
          <Box
            display={'flex'}
            flexDirection="row"
            flex={2}
            sx={{
              marginLeft: '1rem',
              height: '100%',
            }}
          >
            <Typography
              component="h6"
              align="left"
              textOverflow={'ellipsis'}
              noWrap
              sx={{
                fontFamily: themeData.DefaultFontBook,
                color: '#000000',
                fontSize: '14px',
                alignSelf: 'center',
              }}
            >
              Click to see instructions
            </Typography>
          </Box>
        </Grid>
        <Grid item md={2} xs={1}>
          <Box
            display={'flex'}
            flexDirection="row"
            sx={{
              marginLeft: '1rem',
              width: '40px',
              height: '100%',
            }}
          >
            <Button onClick={editMethodHandler}>
              {narrowScreen ? (
                <EditIcon />
              ) : (
                <Typography
                  component="h6"
                  align="right"
                  sx={{
                    color: '#277ef7',
                    fontSize: '11px',
                  }}
                >
                  Instructions
                </Typography>
              )}
            </Button>
          </Box>
        </Grid>
        {/* </Box> */}
      </Grid>
      <WireInstructions
        details={details}
        show={showEditPayment}
        offering={offering}
        investorName={investorName}
        onClose={() => {
          setShowEditPayment(false);
        }}
      />
    </>
  );
};
