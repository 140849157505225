import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
    container: {
        marginTop: "17px",
    },
    label: {
        marginBottom: "15px",
    },
    finraTypeContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    text: {
        color: "#0F0F0F",
        marginBottom: "1.5rem",
        fontSize: "15px",
        fontWeight: "300",
        marginTop: "14px",
    },
};

export type ClassesNames = keyof typeof commonStyles;
