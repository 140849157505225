import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          width: "633px",
          margin: "0 auto",
          overflow: "visible",
          padding: "31px 0 45px 0",
        },
        imgBox: {
          width: "261px",
          "& img": {
            width: "259px",
            border: "1px solid #DFDFE3",
            maxWidth: "600px",
          },
        },
        title: {
          fontSize: "31px",
        },
        signBtn: {
          fontSize: "21px",
          height: "52px",
          width: "146px",
        },
        content: {
          marginBottom: "0",
        },
        content2: {
          marginBottom: "0",
          padding: "25px 25px 0",
        },
        content3: {
          "& button": {
            fontSize: "21px",
            width: "246px",
            height: "61px",
          },
        },
      })
    )
);
