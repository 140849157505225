import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import TiaraImage from 'assets/images/tiaraSvg.svg';
import { CustomButton } from 'components/UIComponents/Button';

import { ROUTES } from 'utils/constants';

import {
  LEFT_COLUMN_DETAILS,
  RIGHT_COLUMN_DETAILS,
  IMAGE_COMMENT,
} from './constants';
import styles from './TiaraAsset.module.scss';

type TiaraAssetProps = {
  isShowMoreUpcoming: boolean;
  isWithOneButton?: boolean;
};

const TiaraAsset = ({
  isShowMoreUpcoming,
  isWithOneButton = false,
}: TiaraAssetProps) => {
  const navigate = useNavigate();

  const containerClasses = classNames(styles.container, {
    [styles.containerWithOneButton]: isWithOneButton,
  });

  const offeringsContainerClasses = classNames(styles.offeringsContainer, {
    [styles.offeringsContainerWithOneButton]: isWithOneButton,
  });

  const navigateToFactSheet = () => {
    const a = document.createElement('a');
    a.href = process.env.REACT_APP_FRONTEND_BASE_URL + ROUTES.TIARA_FACT_SHEET;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
    a.remove();
  };

  return (
    <>
      <div className={containerClasses}>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <img
              src={TiaraImage}
              className={styles.imageTiara}
              alt="The Tiara"
            />
            <span className={styles.commentText}>{IMAGE_COMMENT}</span>
          </div>
          <div className={styles.contentInfo}>
            <h2 className={styles.itemTitle}>THE Tiara</h2>
            <h3 className={styles.itemDescription}>
              LUXUS, in partnership with Garrard, is commissioning a bespoke,
              one-of-a-kind Tiara made up of 
              <strong>investment grade</strong> diamonds and precious gems.
            </h3>
            <div className={styles.moreDescription}>
              <div className={styles.infoContainer}>
                <hr className={styles.line} />
                {LEFT_COLUMN_DETAILS.map((item) => (
                  <div key={item.label}>
                    <div className={styles.table}>
                      <h4 className={styles.labelName}>{item.label}</h4>
                      <h4 className={styles.valueText}>{item.value}</h4>
                    </div>
                    <hr className={styles.line} />
                  </div>
                ))}
              </div>
              <div className={styles.infoContainer} style={{ width: '100%' }}>
                <hr className={styles.lineMore} />
                {RIGHT_COLUMN_DETAILS.map((item) => (
                  <div key={item.label}>
                    <div className={styles.table}>
                      <h4 className={styles.labelName}>{item.label}</h4>
                      <h4 className={styles.valueText}>{item.value}</h4>
                    </div>
                    <hr className={styles.line} />
                  </div>
                ))}
              </div>
            </div>
            <div className={offeringsContainerClasses}>
              {!isWithOneButton && (
                <div className={styles.offeringsBlock}>
                  For Accredited Investors Only
                </div>
              )}
              <div className={styles.buttonsContainer}>
                <div>
                  <CustomButton
                    btnStyle="secondaryBlack"
                    onClick={() => navigate(ROUTES.FAQ_TIARA)}
                  >
                    <span className={styles.buttonText}>FAQ</span>
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    btnStyle="secondaryBlack"
                    onClick={() => navigateToFactSheet()}
                  >
                    <span className={styles.buttonText}>FACT SHEET</span>
                  </CustomButton>
                </div>
                {!isWithOneButton && (
                  <CustomButton onClick={() => navigate(ROUTES.TIARA)}>
                    <span className={styles.buttonText}>INVEST NOW</span>
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TiaraAsset;
