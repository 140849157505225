import { OfferingDetail } from "../../../../../../../types/Offerings";
import defaultFormatNumber from "../../../../../../../utils/FormatNumber";
import { viewDealsSheetUrl } from "../../../CurrentOffering/CurrentOfferingGrid";
import navigateTo from "../../../../../../../utils/navigateTo";
import {BasicOfferCardProps} from "./BasicOfferCard";


interface BasicOfferCardFacadeResult {
  getValueFromDetails: <T extends OfferingDetail["label"]>(key: T) => string;
  formatNumber: (number: string) => string;
  onViewDealSheetButtonClick: () => void;
}

export default function useBasicOfferCardFacade({
  offer,
}: BasicOfferCardProps): BasicOfferCardFacadeResult {
  const getValueFromDetails = <T extends OfferingDetail["label"]>(key: T) =>
    offer.details.find(({ label }) => label === key)?.value ?? "";

  const formatNumber = (number: string): string => {
    const cleanNumber = number.replaceAll(/\D/g, "");
    const numb = Number(cleanNumber);
    if (numb > 1000000) {
      const diff = (numb / 1000000).toFixed(1);

      return `${diff}M`;
    }

    return defaultFormatNumber(cleanNumber);
  };

  const onViewDealSheetButtonClick = (): void => {
    navigateTo(viewDealsSheetUrl(offer));
  };

  return {
    getValueFromDetails,
    formatNumber,
    onViewDealSheetButtonClick,
  };
}
