import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import './App.css';
import './FuturaFont.css';
import './TeluguFont.css';

// import AssetDetails from './pages/AssetDetails/AssetDetails';
import BuyShares from './pages/BuyShares/BuyShares';
// import IOIShares from './pages/BuyShares/IOIShares';
import LoginPage from './pages/Login/LoginPage';
import PendingValidationPage from './pages/Login/PendingValidationPage/PendingValidationPage';
import SignupPage from './pages/Login/SingupPage/SignupPage';
import CompleteProfile from './pages/Profile/CompleteProfile';
import ProfileEdit from './pages/Profile/ProfileEdit';
// import CompleteProfile from './pages/Profile/CompleteProfile/CompleteProfile';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import NewProfile from 'pages/NewProfile/NewProfile';
import UiKit from 'pages/UiKit/UiKit';
import { useServerAPI } from './apis';
import About from './pages/AboutLuxus/About';
import { BlogPage } from './pages/Blog/BlogPage';
import AccountVerifaed from './pages/Login/AccountVerified/AccountVerified';
import Process from './pages/LuxusProcess/Process';
import AssetDetails from './pages/NewAssests/AssetDetails/AssetDetails';
import BuyAsset from './pages/NewAssests/BuyAssest/BuyAsset';
import IOIDetails from './pages/NewAssests/IOIDetails';
import { IOISubmit } from './pages/NewAssests/IOISubmit';
import SuccessfullBuy from './pages/NewAssests/SucessfullBuy/SuccessfullBuy';
import NewPortfolio from './pages/NewPortfolio/NewPortfolio';
import OverviewPage from './pages/Overview/OverviewPage';
import ConfigureProfile from './pages/Profile/ConfigureProfile/ConfigureProfile';
import { Tiara100Wif } from 'pages/Tiara100Wif/Tiara/Tiara100Wif';

import { muiTheme } from './utils/theme';
import { ipSelector } from './store/selectors/system';
import defineIp from './utils/defineIp';
import { setDisplayTypeInStorage } from './utils/getDispalyType';

// Import types.
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import ConfigureForm from 'pages/Profile/ConfigureProfile/ConfigureForm/ConfigureForm';
import TermsOfService from 'pages/TermsOfService/TermsOfService';
import ProtectedRouteWithoutLayout from 'utils/ProtectedRouteWithoutLayout';

import Main from 'Main';
import { Derby } from 'pages/Derby/Derby';
import Faq from 'pages/Faq/Faq';
import { VipPage } from 'pages/Vip/VipPage';
import { AssetsUnlogged } from './pages/AssetsUnlogged/AssetsUnlogged';
import { Dashboard } from './pages/Dashboard/Dashboard';
import ForgotPage from './pages/Login/ForgotPage/ForgotPage';
import ProtectedRoutes from './utils/ProtectedRoute';
import { Tiara } from 'pages/Tiara/Tiara';
import { SubdocAnswer } from './pages/SubdocAnswer/SubdocAnswer';
import { Subdoc } from './pages/Subdoc/Subdoc';
import Accreditation from './pages/Accreditation/Accreditation';
import TiaraFaq from 'pages/TiaraFaq/TiaraFaq';
import RoyalAscot from 'pages/RoyalAscot/RoyalAscot';
import ThoroughbredAccreditation from 'pages/Thoroughbred/components/Accreditation/Accreditation';
import Thoroughbred from 'pages/Thoroughbred/components/ThoroughbredPage/Thoroughbred';
import ThoroughbredAnnounces from 'pages/ThoroughbredAnnounce/ThoroughbredAnnounces';
import Stream from 'pages/Stream/Stream';

import { ROUTES } from 'utils/constants';

if (process.env.STAGE !== 'QA') {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID || '');
}

const App = () => {
  const api = useServerAPI();
  const ip = useSelector(ipSelector);

  useEffect(() => {
    if (ip === '') defineIp(api);
  });

  useEffect(() => {
    setDisplayTypeInStorage();
    window.addEventListener('resize', setDisplayTypeInStorage);
    return () => window.removeEventListener('resize', setDisplayTypeInStorage);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/termsOfService" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route path="/reset" element={<ForgotPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/AboutLuxus" element={<About />} />
          {/* <Route path="/derby" element={<Derby />} /> */}
          <Route path="/tiara" element={<Tiara />} />
          <Route path="/tiara-100wif" element={<Tiara100Wif />} />
          <Route path="/faq-tiara" element={<TiaraFaq />} />
          {/* <Route path="/subdoc/:guid" element={<Subdoc />} />
          <Route path="/subdoc_redirect/:guid" element={<SubdocAnswer />} /> */}
          <Route path="/subdoc/:guid" element={<Accreditation />} />
          <Route path="/royal-ascot-sign-up" element={<RoyalAscot />} />
          {/* <Route
            path="/thoroughbred_subdoc/:guid"
            element={<ThoroughbredAccreditation />}
          /> */}
          <Route path={ROUTES.THOROUGHBRED} element={<Thoroughbred />} />
          <Route
            path={ROUTES.THOROUGHBRED_ANNOUNCE}
            element={<ThoroughbredAnnounces />}
          />
          <Route path={ROUTES.STREAM} element={<Stream />} />
          {/* <Route
            path="/configureProfile"
            element={
              <ProtectedRouteWithoutLayout>
                <ConfigureProfile />
              </ProtectedRouteWithoutLayout>
            }
          /> */}
          {/* <Route
            path="/configureProfile/form"
            element={
              <ProtectedRouteWithoutLayout>
                <ConfigureForm />
              </ProtectedRouteWithoutLayout>
            }
          /> */}
          {/* <Route
            path="/add-entity"
            element={
              <ProtectedRouteWithoutLayout>
                <ConfigureForm externalStep={6} />
              </ProtectedRouteWithoutLayout>
            }
          /> */}

          <Route path="/assets" element={<AssetsUnlogged />} />
          <Route path="/vip" element={<VipPage />} />
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<NewProfile />} />
            <Route path="/configureProfile" element={<ConfigureProfile />} />
            <Route path="/complete-profile" element={<CompleteProfile />} />
            <Route path="/profileEdit" element={<ProfileEdit />} />
            {/* <Route path='/assetDetails' element={<AssetDetails />} /> */}
            <Route path="/assetDetails/:id" element={<AssetDetails />} />
            <Route path="/buyShares" element={<BuyShares />} />
            <Route path="/buyAsset/:id" element={<BuyAsset />} />
            <Route path="/buySucessfull/:id" element={<SuccessfullBuy />} />
            <Route path="/ioiDetails/:id" element={<IOIDetails />} />
            <Route path="/ioiSubmit/:id" element={<IOISubmit />} />
            <Route path="/portfolio" element={<NewPortfolio />} />
          </Route>

          <Route
            path="/pendingValidation"
            element={<PendingValidationPage />}
          />
          <Route path="*" element={<Navigate to="/" />} />
          {/* <Route path="/accountVerifaed" element={<AccountVerifaed />} /> */}
          {/* Test routes for new components */}
          {/* <Route path="/configureProfile" element={<ConfigureProfile />} /> */}
          {/* <Route path="/uikit" element={<UiKit />} /> */}
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
