export const classes = {
  modalWrapper: {
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000080',
    zIndex: 100,
  },
  modal: (isMobile: boolean) => ({
    position: 'fixed',
    display: 'flex',
    gap: isMobile ? '10px' : '50px',
    maxWidth: 'max(50%, 850px)',
    margin: isMobile ? '25px' : '50px',
    width: isMobile ? 'calc(100% - 150px)' : 'calc(100% - 200px)',
    alignItems: 'center',
  }),
  arrowIcon: (rotate: number, isMobile: boolean) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    marginBottom: 'auto',
    marginTop: 'auto',
    left: rotate === 90 ? (isMobile ? '-60px' : '-80px') : 'unset',
    right: rotate === 90 ? 'unset' : isMobile ? '-60px' : '-80px',

    rotate: `${rotate}deg`,
    width: 'fit-content',
    height: 'fit-content',
    cursor: 'pointer',
  }),
}
