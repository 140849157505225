import React, { useState, useRef } from "react";
import useGetDisplayType from "../../../../../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../../../../../types/enums/DisplayTypes";

export default function useInfoPanelFacade(): [
  React.RefObject<HTMLVideoElement>,
  () => void,
  boolean
] {
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const desktop = !useGetDisplayType(DisplayTypes.Mobile);

  const videoRef = useRef<HTMLVideoElement>(null);

  const onVideoClick = (): void => {
    if (isVideoPlay) videoRef.current?.pause();
    else videoRef.current?.play();
    setIsVideoPlay(!isVideoPlay);
  };

  return [videoRef, onVideoClick, desktop];
}
