export const classes = {
  heroContainer: (isTablet: boolean, isMobile: boolean) => ({
    height: "35vw",
    minHeight: isMobile ? "380px" : isTablet ? "500px" : "400px",
    width: "100%",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "end",
    maxHeight: "1000px",
    position: "relative",
    background:
      "linear-gradient(to top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%)",

    "::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      zIndex: 10,
      right: 0,
      height: "50%",
      background:
        "linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
    },
  }),

  title: (isMobile: boolean) => ({
    textAlign: "center",
    color: "#F6F3F2",
    fontFamily: "Telugu MN",
    fontSize: isMobile ? "30px" : "40px",
    letterSpacing: isMobile ? "unset" : "0.8px",
    fontWeight: "400",
    lineHeight: "normal",
    textTransform: "uppercase",
    maxWidth: isMobile ? "600px" : "880px",
    padding: "0 10px",
    zIndex: 11,
  }),

  line: {
    width: "53px",
    height: "3px",
    flexShrink: 0,
    backgroundColor: "#F6F3F2",
    marginTop: "14px",
  },

  text: (isMobile: boolean) => ({
    color: "#F6F3F2",
    fontFamily: "Futura Book PT",
    fontSize: isMobile ? "16px" : "25px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  }),

  bodyWrapper: (isMobile: boolean) => ({
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "100px",
  }),

  bodyContainer: (isMobile: boolean) => ({
    textAlign: "center",
    maxWidth: isMobile ? "460px" : "1040px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 24px",
    gap: isMobile ? "26px" : "60px",
    marginTop: isMobile ? "13px" : "64px",
    marginBottom: isMobile ? "48px" : "120px",
  }),

  image: (isMobile: boolean) => ({
    width: "100%",
    objectFit: "cover",
    maxWidth: isMobile ? "300px" : "600px",
  }),

  iconList: (isMobile: boolean) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: isMobile ? "calc(100% - 48px)" : "800px",
    gap: isMobile ? "15px" : "111px",
    marginTop: "72px",
    padding: "0 24px",
  }),

  icon: (isMobile: boolean) => ({
    minWidth: isMobile ? "70px" : "110px",
    maxWidth: "120px",
    width: "100%",
  }),

  newsLetterWrapper: (isMobile: boolean) => ({
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    padding: isMobile
      ? "69px 26.969px 54.864px 21.969px"
      : "69px 46.969px 54.864px 41.969px",
  }),

  newsLetterContainer: (isMobile: boolean) => ({
    maxWidth: "540px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "17px",
  }),

  newsLetterTitle: {
    color: "#000",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Telugu MN",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.421px",
    textTransform: "uppercase",
  },

  newsLetterText: {
    color: "#000",
    leadingTrim: "both",
    textEdge: "cap",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.5",
    marginTop: "14px",
    fontFamily: "Futura PT Book",
    fontSize: "20px",
    letterSpacing: "0.02em",
  },

  row: (isMobile: boolean) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: isMobile ? "14px" : "20px",
    marginTop: "30px",
    marginBottom: "22px",
  }),
};
