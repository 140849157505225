import {
  Box,
  Container,
  CssBaseline,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation, useNavigate } from "react-router-dom";
import { useServerAPI } from "../../../apis";
import useCountDown from "hooks/useCountDown";
import DiamondLayout from "layout/DiamondLayout/DiamondLayout";
import { Button } from "pages/Profile/ConfigureProfile/Button/Button";
import { useStyles } from "./styles/";

const PendingValidationPage = () => {
  const api = useServerAPI();
  const location = useLocation();
  const navigate = useNavigate();

  const { counter, start, reset, clear } = useCountDown(60);

  const onResendRequest = () => {
    const state = location.state as { email?: string } | undefined;
    if (state?.email && !counter) {
      api.resendValidationEmail({ email: state?.email });
      // Timer reset
      reset();
      start();
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/pendingValidation");
    start();

    return () => {
      clear();
    };
  }, []);

  const classes = useStyles();

  return (
    <>
      <DiamondLayout>
        <Container maxWidth="sm">
          <CssBaseline />
          <Box className={classes.content}>
            <Box className={classes.content2}>
              <Typography
                fontSize={"30px"}
                lineHeight={"normal"}
                letterSpacing={"0.6px"}
                color={"#fff"}
                textAlign={"center"}
                fontFamily={"Telugu MN"}
              >
                VERIFICATION SENT
              </Typography>
              <Typography
                textAlign={"center"}
                fontSize={"20px"}
                lineHeight={"normal"}
                letterSpacing={"0.4px"}
                color={"#fff"}
                padding={"40px 0px"}
              >
                Please check your email and click the verification link to
                activate your account.
              </Typography>
              {counter > 0 && (
                <Typography
                  textAlign={"center"}
                  fontSize={"20px"}
                  lineHeight={"normal"}
                  letterSpacing={"0.4px"}
                  color={"#fff"}
                  paddingBottom={"40px"}
                >
                  Resend available in {counter} seconds.
                </Typography>
              )}
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Button.White
                  value="RESEND"
                  onClick={onResendRequest}
                  disabled={counter > 0}
                />
                {/* <Button.White
                  value="GO TO SIGN IN"
                  onClick={() => {
                    navigate("/login");
                  }}
                /> */}
              </Box>
            </Box>
          </Box>
        </Container>
      </DiamondLayout>
    </>
  );
};

export default PendingValidationPage;
