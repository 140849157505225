import React, { FC, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Preloader } from "../../../components/simpleComponents/Preloader";
import { SubmitBox } from "./components/SubmitBox";
import { Table } from "./components/Table";
import { IOIThanks } from "./components/Thanks";
import { useStyles } from "./styles/";
import useIOISubmitFacade from "./useIOISubmitFacade";
import style from "./style.module.scss";
import "./text-style.scss";

import { CustomButton } from "../../../components/UIComponents/Button";
import Header from "../../../components/Header/Header";
import { HEADER_OPTIONS } from "../../../components/Header/constants";
import { Footer } from "../../../components/Footer/Footer";
import { MAIN_URL, ROUTES } from "utils/constants";

export const IOITip: string = `An indication of interest (IOI) is a <b class="boldText">non-binding interest</b>
to purchase shares in this asset once the offering is open for
investment on the LUXUS platform. <br />
<br />
Limited quantities of shares will be available in this offering.
<b class="boldText">
  Investors placing IOIs will receive priority in the IPO
  allocation.
</b>
Please only place an IOI for the shares you intend to actually
purchase.
<b class="boldText">
  If you do not purchase the shares indicated in your IOI, you
  will not receive priority allocations in future LUXUS IPOs.
</b>`;

export const IOISubmit: FC = () => {
  const [
    offer,
    isLoading,
    onInterestClick,
    onBrowseMoreClick,
    desktop,
    isThanks,
  ] = useIOISubmitFacade();
  const classes = useStyles();

  const [accountId, setAccountId] = useState("");
  const [count, setCount] = useState<number>(1);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/IOISubmit");
  }, []);

  if (isLoading) {
    return (
      <>
        <Header color={HEADER_OPTIONS.BLACK} />
        <Preloader />
        <Footer
          pageName="IOI_SUBMIT page"
          pageUri={`${MAIN_URL}${ROUTES.IOI_SUBMIT}`}
        />
      </>
    );
  }

  if (isThanks) {
    return (
      <IOIThanks offer={offer} onClick={onBrowseMoreClick} desktop={desktop}>
        Thank you for indicating your interest!--We’ll notify you when this
        asset is available.
      </IOIThanks>
    );
  }

  return (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <div className={style.container}>
        <div className={style.content}>
          <img src={offer?.image?.url ?? ""} alt={""} />
          <h2>{offer?.name}</h2>
          <div className={style.main}>
            <div className={style.priceBlock}>
              <div className={style.priceItem}>
                <h6>TOTAL ASSET VALUE</h6>
                <h4>${offer?.offering?.targetAmount}</h4>
              </div>
              <div className={style.priceItem}>
                <h6>SHARE PRICE</h6>
                <h4>${offer?.offering?.unitPrice}</h4>
              </div>
            </div>
            {/* <SelectIdField setAccountId={setAccountId}/>*/}
          </div>

          <div className={style.interestBlock}>
            <div className={style.text}>
              <h3>WHAT IS AN INDICATION OF INTEREST?</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: IOITip,
                }}
              />
            </div>
            <div className={style.btnMobile}>
              {" "}
              {/*- mobile reserve btn*/}
              <CustomButton
                btnStyle={"primary"}
                onClick={() => onInterestClick(count)}
              >
                RESERVE SHARES
              </CustomButton>
            </div>
            <SubmitBox
              count={count}
              setCount={setCount}
              unitPrice={parseInt(offer?.offering?.unitPrice || "0", 10) ?? 0}
            />
          </div>
          <div className={style.btnContainer}>
            <CustomButton
              btnStyle={"primary"}
              onClick={() => onInterestClick(count)}
            >
              RESERVE SHARES
            </CustomButton>
          </div>
        </div>
      </div>
      <Footer
        pageName="IOI_SUBMIT page"
        pageUri={`${MAIN_URL}${ROUTES.IOI_SUBMIT}`}
      />
    </>
  );
};
