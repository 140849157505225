import { Box, Modal, Typography, Button } from '@mui/material';
import React from 'react';

type ConfirmLeaveProps = {
  show: boolean;
  onClose?: () => void;
  onStay?: () => void;
  onLeave?: () => void;
  prompt: string;
};

export const ConfirmLeave = ({
  show,
  onClose,
  onStay,
  onLeave,
  prompt,
}: ConfirmLeaveProps) => {
  return (
    <Modal
      open={show}
      onClose={() => {
        if (onClose) onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#ffffff',
          p: 4,
          borderRadius: '10px',
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            color: '#000000',
            fontSize: '18px',
          }}
        >
          Are you sure?
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{
            marginTop: '1rem',
            color: '#555555',
            fontSize: '12px',
          }}
        >
          {prompt}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={'flex-end'}
          sx={{
            marginTop: '1rem',
          }}
        >
          <Button
            sx={{
              fontSize: '10px',

              border: '1px solid #000000',
              color: '#ffffff',
              borderRadius: '5px !important',
              marginRight: '15px',
              backgroundColor: '#000000',
              '&:hover': {
                background: '#000000',
                color: '#ffffff',
              },
            }}
            onClick={() => {
              if (onStay) onStay();
            }}
          >
            Stay
          </Button>
          <Button
            sx={{
              fontSize: '10px',
              backgroundColor: '#d9001b',

              border: '1px solid #d9001b',
              color: '#ffffff',
              borderRadius: '5px !important',
              '&:hover': {
                background: '#d9001b',
              },
            }}
            onClick={() => {
              if (onLeave) onLeave();
            }}
          >
            Leave
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
