import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles({
    ...mixStyleRules(commonStyles, {
      title: {
        fontSize: "30px",
        marginBottom: "20px",
        fontWeight: "400",
      },
      text: {
        fontSize: "18px",
        marginBottom: "20px",
        fontWeight: "300",
      },
      linkText: {
        fontSize: "15px",
        fontWeight: "300",
      },
      link: {
        fontSize: "15px",
        fontWeight: "300",
      },
    }),
    delimeter: {
      width: "100%",
    },
  })
);
