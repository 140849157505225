import React, { ChangeEvent, FC, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

import { InputField } from "components/simpleComponents/Input/InputField/InputField";
import InputSubmit from "components/simpleComponents/Input/InputSubmit/InputSubmit";
import RadioButton from "components/simpleComponents/Radio/RadioButton";
import { PhoneDropDownComponent } from "components/simpleComponents/PhoneDropDown/PhoneDropDown";

import { useServerAPI } from "apis";
import { MAIN_URL, ROUTES } from "utils/constants";
import { getHubSpotCookie, sendHubSpotData } from "apis/hubSpot";

import { classes } from "./styles";

export const VipForm: FC<{
  isAuth: boolean;
  isUserSubscribed: boolean;
  setIsUserSubscribed: (value: boolean) => void;
}> = ({ isAuth, isUserSubscribed, setIsUserSubscribed }) => {
  const isMobileScreen = useMediaQuery("(max-width:776px)");
  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    agreement: false,
    phoneNumber: "",
  };

  const initialErrorState = {
    firstName: "",
    lastName: "",
    email: "",
    agreement: "",
  };

  const [formState, setFormState] = useState(initialFormState);
  const [error, setError] = useState(initialErrorState);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const api = useServerAPI();

  const isValid = (name: string, value: string | boolean): string => {
    if (typeof value === "boolean") {
      return value ? "" : "Required";
    }
    if (value.length === 0 && name !== "phoneNumber") {
      return "Required";
    }
    if (name === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return "Invalid email";
    }
    if (
      name !== "email" &&
      !(value.length <= 50 && /^[A-Za-z0-9-`']*$/.test(value))
    ) {
      return "Invalid name";
    }
    return "";
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
    if (isValid(name, value) === "") {
      setError((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleAgreement = () => {
    setFormState((prev) => ({ ...prev, agreement: !prev.agreement }));
    if (!formState.agreement) {
      setError((prev) => ({ ...prev, agreement: "" }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setPhoneErrorMessage("");

    const errors = Object.keys(formState).reduce((acc, key) => {
      acc[key as keyof typeof initialErrorState] = isValid(
        key,
        formState[key as keyof typeof initialErrorState]
      );
      return acc;
    }, {} as typeof initialErrorState);
    if (Object.values(errors).some((x) => x !== "")) {
      setError(errors);
      return;
    }
    if (!isAuth) {
      localStorage.setItem("signUpFirstName", formState.firstName);
      localStorage.setItem("signUpLastName", formState.lastName);
      localStorage.setItem("signUpEmail", formState.email);
      localStorage.setItem("signUpPhoneNumber", formState.phoneNumber);
    }

    if (isUserSubscribed) {
      return;
    }

    try {
      const ip = await api.getIPFromAmazon();

      const formId = process.env.REACT_APP_HUBSPOT_FORM_NEWSLETTER_GUID || "";
      const trackingCookie = getHubSpotCookie() || "";

      const dataToSend = !!formState.phoneNumber
        ? {
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.email,
            phoneNumber: formState.phoneNumber,
          }
        : {
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.email,
          };

      const response = await sendHubSpotData(
        {
          ...dataToSend,
          ipAddress: ip,
          trackingCookie,
          pageUri: `${MAIN_URL}${ROUTES.VIP}`,
          pageName: "VIP Page",
        },
        formId
      );

      if (response.inlineMessage === "Thanks for submitting the form.") {
        setIsUserSubscribed(true);
      }
    } catch (error: any) {
      console.log(error);

      if (
        error?.response?.data?.message[0] ===
        "phoneNumber must be a valid phone number"
      ) {
        setPhoneErrorMessage("Invalid phone number!");
      }
    }
  };

  return (
    <Box sx={classes.bodyContainer(isMobileScreen)}>
      <Box>
        <Typography variant="h2" sx={classes.title}>
          BECOME A VIP
        </Typography>
        <Typography variant="h6" sx={classes.subTitle}>
          Interested in our VIP program? Join the waitlist here
        </Typography>
      </Box>
      <Box
        component={"form"}
        noValidate
        onSubmit={handleSubmit}
        sx={classes.form(isMobileScreen)}
      >
        <Box sx={classes.row(isMobileScreen)}>
          <InputField.White
            placeholder="FIRST NAME"
            name="firstName"
            value={formState.firstName}
            onChange={handleChange}
            error={error.firstName !== ""}
          />
          <InputField.White
            placeholder="LAST NAME"
            name="lastName"
            value={formState.lastName}
            onChange={handleChange}
            error={error.lastName !== ""}
          />
        </Box>
        <PhoneDropDownComponent.White
          error={!!phoneErrorMessage}
          buttonType="button"
          onChange={(inputPhoneValue) => {
            setFormState((state) => ({
              ...state,
              phoneNumber: inputPhoneValue,
            }));
          }}
          value={formState.phoneNumber}
        />
        {!!phoneErrorMessage && (
          <Typography sx={{ color: "red" }}>{phoneErrorMessage}</Typography>
        )}
        <InputSubmit.White
          isWithoutZindex
          name="email"
          placeholder="EMAIL ADDRESS"
          value={formState.email}
          onChange={handleChange}
          buttonText="SIGN UP"
          eye={false}
          onSubmit={handleSubmit}
          color="#000"
          error={error.email !== ""}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RadioButton.Black
            label=""
            value={formState.agreement}
            onChange={() => {}}
            onClick={() => {
              handleAgreement();
            }}
            error={error.agreement !== ""}
          />
          <Typography>
            I have read and agree to the{" "}
            <Link target="_blank" style={{ color: "inherit" }} to={"/privacy"}>
              Privacy Policy
            </Link>
            ,{"  "}
            <Link
              target="_blank"
              style={{ color: "inherit" }}
              to={"/termsOfService"}
            >
              Terms of Service
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
