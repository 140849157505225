const logout = () => {
  // Remove all saved data from localStorage.
  localStorage.clear();
  // Reload the page so the user sees the login screen.
  window.location.reload();
};

/**
 * Redirect/reload the page with original query string parameters.
 * Use case:
 *      Restores original query string parameters that were lost after SSO redirects for example.
 *
 */
const reloadUrlWithOrigParams = () => {
  let queryString = localStorage.getItem('queryString');
  queryString = queryString != null ? decodeURIComponent(queryString) : '';
  window.location.href =
    '//' + window.location.host + window.location.pathname + '?' + queryString;
};

export { logout, reloadUrlWithOrigParams };

export function stripHTML(html?: string): string | undefined {
  if (html) {
    let doc = new DOMParser().parseFromString(
      html.replaceAll('<br />', '\n'),
      'text/html'
    );
    console.debug('Stripped HTML ', doc);
    // var text:string|null = null;
    return doc.body.textContent ?? undefined;
  }
  return undefined;
}
