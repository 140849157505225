import { FC, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { classes } from 'pages/Derby/components/styles';

import { ModalForm } from './ModalForm';
import LuxusLogoNoText from 'assets/icons/LuxusLogoNoText';
import styles from './styles.module.scss';

interface Props {
  isModalVisible: boolean;
  changeVisible: () => void;
}

export const ModalWindow: FC<Props> = ({ isModalVisible, changeVisible }) => {
  const [step, setStep] = useState(1);

  const isMobile = useMediaQuery('(max-width: 575px)');

  const closeModal = () => {
    changeVisible();
  };

  return (
    <>
      <Box sx={classes.modalWrapper(isModalVisible)} onClick={closeModal}>
        <Box
          sx={classes.modal(isModalVisible)}
          style={{
            paddingTop: '40px',
            gap: '22px',
            maxHeight: isMobile ? '95%' : 'unset',
            overflowY: isMobile ? 'scroll' : 'unset',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {step === 1 && (
            <ModalForm changeVisible={closeModal} changeStep={setStep} />
          )}
          {/* {step === 2 && (
            <>
              <div className={styles.logoWrapper}>
                <LuxusLogoNoText />
              </div>
              <Typography sx={classes.title}>Success</Typography>
              <Typography sx={{ fontFamily: "Futura PT Book" }}>
                You're now being redirected to our payment process.
              </Typography>
            </>
          )} */}
        </Box>
      </Box>
    </>
  );
};
