import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

const btnStyles = {
  padding: "8px 6px",
  "& svg": {
    fontSize: "15px",
  },
};

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          width: "109px",
          height: "32px",
        },
        text: {
          fontSize: "15px",
        },
        decBtn: {
          borderTopLeftRadius: "6px !important",
          borderBottomLeftRadius: "6px !important",
          ...btnStyles,
        },
        incBtn: {
          borderTopRightRadius: "6px !important",
          borderBottomRightRadius: "6px !important",
          ...btnStyles,
        },
      })
    )
);
