import { useEffect, useState } from "react";

import ArrowBottom from "assets/icons/PortfolioIcons/ArrowBottom";
import AssetsItemDesktop from "../AssetsItemDesktop/AssetsItemDesktop";
import SheetTitle from "../PortfolioInfoMobile/SheetTitle";

import { OfferingTrades } from "types/Trade";
import formatNumber from "utils/FormatNumber";

import styles from "./PortfolioInfoDesktop.module.scss";

type PortfolioInfoDesktopProps = {
  shares: OfferingTrades[];
};

const PortfolioInfoDesktop = ({ shares }: PortfolioInfoDesktopProps) => {
  const sortedShares = [...shares].sort((a, b) => {
    return b.totalAmount - a.totalAmount;
  });

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalShares, setTotalShares] = useState(0);

  useEffect(() => {
    let totalAmount = 0;
    let totalShares = 0;

    shares.forEach(share => {
      totalAmount += share.totalAmount;
      totalShares += share.totalShares;
    });

    setTotalAmount(totalAmount);
    setTotalShares(totalShares);
  }, [shares]);

  const formattedAmount = formatNumber(totalAmount.toString());

  return (
    <div>
      <SheetTitle>
        <div className={styles.textWrapper}>
          <p className={styles.text}>ASSET</p>
          <ArrowBottom />
        </div>
        <div className={styles.textWrapper}>
          <p className={styles.text}>AMOUNT</p>
          <ArrowBottom />
        </div>
        <div className={styles.textWrapper}>
          <p className={styles.text}>SHARES</p>
          <ArrowBottom />
        </div>
      </SheetTitle>
      {shares.length === 0 && <br />}
      {shares.length > 0 && (
        <ul className={styles.list}>
          {sortedShares.map(share => (
            <AssetsItemDesktop key={share.offeringId} share={share} />
          ))}
        </ul>
      )}
      <SheetTitle>
        <div className={styles.textWrapper}>
          <p className={styles.text}>TOTAL</p>
        </div>
        <div className={styles.textWrapper}>
          <p className={styles.text}>${formattedAmount}</p>
        </div>
        <div className={styles.textWrapper}>
          <p className={styles.text}>{totalShares}</p>
        </div>
      </SheetTitle>
    </div>
  );
};

export default PortfolioInfoDesktop;
