import { type ChangeEvent } from "react";

import { InputField } from "components/simpleComponents/Input/InputField/InputField";
import { PhoneDropDownComponent } from "components/simpleComponents/PhoneDropDown/PhoneDropDown";
import Dropdown from "components/simpleComponents/Dropdown/DropDown";

import { countries } from "utils/CoutriesList";
import { domicileChoices } from "types/Account";
import { UserProfile } from "types/Account";
import { Errors } from "../EditUserData/useEditUserDataFacade";

import styles from "./EditFormInputs.module.scss";

type EditFormProps = {
  profileData: UserProfile;
  profileSetter: (key: string) => (value: string) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errors: Errors;
};

const EditFormInputs = ({
  profileData,
  handleChange,
  profileSetter,
  errors,
}: EditFormProps) => {
  const citizenStatus =
    domicileChoices.find((x) => x.value === profileData.domicile) ??
    domicileChoices[0];

  const country = countries.find(
    (x) => x.value === profileData.primCountry
  ) || {
    value: "COUNTRY",
    label: "COUNTRY",
  };

  return (
    <div className={styles.formWrapper}>
      <div className={styles.pairWrapper}>
        <div className={styles.separateWrapper}>
          <InputField.White
            placeholder="DATE OF BIRTH"
            value={profileData.dob}
            name="dob"
            onChange={handleChange}
            error={!!errors?.dob}
            errorText={errors?.dob}
          />
        </div>
        <div className={styles.separateWrapper}>
          <PhoneDropDownComponent.White
            placeholder="123 - 1234"
            borderColor="#000000"
            color="#000000"
            onChange={(value: string) => profileSetter("phone")(value)}
          />
        </div>
      </div>
      <div className={styles.pairWrapper}>
        <div className={styles.separateWrapper}>
          <Dropdown
            options={domicileChoices}
            value={citizenStatus}
            onChange={(option) => profileSetter("domicile")(option.value)}
            arrowColor="#000000"
          />
        </div>
        <div className={styles.separateWrapper}>
          <Dropdown
            options={countries}
            value={country}
            arrowColor="#000000"
            onChange={(option) => {
              profileSetter("primCountry")(option.value);
            }}
          />
        </div>
      </div>
      <InputField.White
        placeholder="STREET"
        value={profileData.primAddress1}
        name="primAddress1"
        onChange={handleChange}
        error={!!errors?.primAddress1}
        errorText={errors?.primAddress1}
      />
      <div className={styles.pairWrapper}>
        <div className={styles.separateWrapper}>
          <InputField.White
            placeholder="CITY"
            value={profileData.primCity}
            name="primCity"
            onChange={handleChange}
            error={!!errors?.primCity}
            errorText={errors?.primCity}
          />
        </div>
        <div className={styles.separateWrapper}>
          <InputField.White
            placeholder="ZIP CODE"
            value={profileData.primZip}
            name="primZip"
            onChange={handleChange}
            error={!!errors?.primZip}
            errorText={errors?.primZip}
          />
        </div>
      </div>
    </div>
  );
};

export default EditFormInputs;
