import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';

import { CloseIcon } from 'assets/icons/close';
import Dropdown from 'components/simpleComponents/Dropdown/DropDown';
import { InputField } from 'components/simpleComponents/Input/InputField/InputField';
import { PhoneDropDownComponent } from 'components/simpleComponents/PhoneDropDown/PhoneDropDown';
import CheckboxIconChecked from 'assets/icons/CheckboxIconChecked';
import CheckboxIconNotChecked from 'assets/icons/CheckboxIconNotChecked';

import { classes } from 'pages/Derby/components/styles';
import { MAIN_URL, ROUTES } from 'utils/constants';
import { useServerAPI } from 'apis';
import { getHubSpotCookie, sendHubSpotData } from 'apis/hubSpot';
import { domicileChoices } from 'types/Account';

import styles from './styles.module.scss';
import { CustomButton } from 'components/UIComponents/Button';
import { Preloader } from 'components/simpleComponents/Preloader';

interface Props {
  changeVisible: () => void;
  changeStep: (step: number) => void;
}

export const ModalForm: FC<Props> = ({ changeVisible, changeStep }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [licenseAgree, setLicenseAgree] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [borderColor, setBorderColor] = useState('#000');
  const [citizenStatus, setCitizenStatus] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [error, setError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    licenseAgree: '',
    duplicate: '',
    citizenStatus: '',
  });

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const api = useServerAPI();

  const handleSubmit = async () => {
    const newError = {} as typeof error;
    if (!firstName) newError.firstName = 'First name is required';
    if (!lastName) newError.lastName = 'Last name is required';
    if (!email) newError.email = 'Email is required';
    if (!validateEmail(email)) newError.email = 'Email is not valid';
    if (phone.length < 5) newError.phone = 'Phone is required';
    if (!licenseAgree) newError.licenseAgree = 'License agree is required';
    if (!citizenStatus) newError.citizenStatus = 'Citizenship is required';

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    setIsSubmitting(true);

    try {
      const ip = await api.getIPFromAmazon();

      const formId = process.env.REACT_APP_HUBSPOT_FORM_THOROUGHBRED || '';
      const trackingCookie = getHubSpotCookie() || '';

      const { redirectUrl, inlineMessage } = await sendHubSpotData(
        {
          firstName,
          lastName,
          email,
          ipAddress: ip,
          trackingCookie,
          pageUri: `${MAIN_URL}${ROUTES.THOROUGHBRED}`,
          pageName: 'Thoroughbred Page',
          citizenshipStatus: citizenStatus?.value,
          phoneNumber: `+${phone}`,
        },
        formId
      );

      if (inlineMessage === 'Thanks for submitting the form.') {
        setIsSubmitting(false);
        window.open(redirectUrl, '_self');
        // changeStep(2);

        // setTimeout(() => {
        //   window.open(redirectUrl, "_self");
        // }, 5000);
      }
    } catch (error: any) {
      setIsSubmitting(false);
      console.log(error.response);
      if (
        error?.response?.data?.message.find(
          (e: string) => e === 'phoneNumber must be a valid phone number'
        )
      ) {
        setError((prev) => ({
          ...prev,
          phone: 'Phone number is not valid',
        }));
      }
      if (
        error?.response?.data?.message.find(
          (e: string) => e === 'email must be an email'
        )
      ) {
        setError((prev) => ({
          ...prev,
          email: 'Email is not valid',
        }));
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (name === 'firstName') {
      if (value) setError((prev) => ({ ...prev, firstName: '' }));
      setFirstName(value);
    } else if (name === 'lastName') {
      if (value) setError((prev) => ({ ...prev, lastName: '' }));
      setLastName(value);
    } else if (name === 'email') {
      if (value && validateEmail(value))
        setError((prev) => ({ ...prev, duplicate: '', email: '' }));
      setEmail(value);
    } else if (name === 'phone') {
      if (value) setError((prev) => ({ ...prev, duplicate: '', phone: '' }));
      setPhone(value);
    }
  };

  useEffect(() => {
    if (error.citizenStatus && !citizenStatus) {
      setBorderColor('#FF0000');
    } else {
      setBorderColor('#000');
    }
  }, [error.citizenStatus, citizenStatus]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Box sx={{ cursor: 'pointer' }} onClick={changeVisible}>
          <CloseIcon color={'#000000'} />
        </Box>
      </Box>
      <Typography sx={classes.subTitle}>MISSED THE CUT</Typography>
      <Typography sx={classes.text}>
        To invest in Missed The Cut for Royal Ascot 2024, please complete the
        registration form below.
      </Typography>
      <Box sx={classes.form}>
        <InputField.White
          placeholder="FIRST NAME"
          name="firstName"
          onChange={handleChange}
          value={firstName}
          error={!!error.firstName}
          errorText={error.firstName}
        />
        <InputField.White
          placeholder="LAST NAME"
          name="lastName"
          onChange={handleChange}
          value={lastName}
          error={!!error.lastName}
          errorText={error.lastName}
        />
        <InputField.White
          placeholder="EMAIL ADDRESS"
          name="email"
          onChange={handleChange}
          value={email}
          error={!!error.email}
          errorText={error.email}
        />
        <PhoneDropDownComponent.White
          onChange={(phone) => {
            setPhone(phone);
            if (phone.length > 5)
              setError((prev) => ({ ...prev, duplicate: '', phone: '' }));
          }}
          value={phone}
          error={!!error.phone}
        />
        {error.phone && <span style={{ color: '#FF0000' }}>{error.phone}</span>}
        <Dropdown
          borderColor={borderColor}
          arrowColor="black"
          options={domicileChoices}
          value={citizenStatus}
          onChange={(option) => {
            if (citizenStatus)
              setError((prev) => ({ ...prev, citizenStatus: '' }));
            setCitizenStatus(option);
          }}
          placeholder="CITIZENSHIP STATUS"
        />
        {error.citizenStatus && !citizenStatus && (
          <span style={{ color: '#FF0000' }}>Citizenship is required</span>
        )}
        {!!error.duplicate && (
          <p className={styles.duplicateError}>{error.duplicate}</p>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <Checkbox
          onClick={() => {
            setLicenseAgree((prev) => !prev);
            setError((prev) => ({ ...prev, licenseAgree: '' }));
          }}
          value={licenseAgree}
          sx={{
            maxHeight: '42px',
            maxWidth: '42px',
            marginRight: '10px',
            marginLeft: '-9px',
            '& rect': { stroke: !!error.licenseAgree ? 'red' : 'black' },
          }}
          icon={<CheckboxIconNotChecked />}
          checkedIcon={<CheckboxIconChecked />}
        />
        <Typography sx={classes.text} style={{ textAlign: 'start' }}>
          I have read and agree to the{' '}
          <Link target="_blank" style={{ color: 'inherit' }} to={'/privacy'}>
            Privacy Policy
          </Link>
          ,{'  '}
          <Link
            target="_blank"
            style={{ color: 'inherit' }}
            to={'/termsOfService'}
          >
            Terms of Service
          </Link>
        </Typography>
      </Box>
      {isSubmitting ? (
        <Preloader sx={{ maxHeight: '45px' }} />
      ) : (
        <CustomButton
          btnStyle="secondary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          SUBMIT
        </CustomButton>
      )}
    </>
  );
};
