import { StyleRules } from "@mui/styles";
import { themeData } from "../../../utils/theme";

/* eslint-disable no-restricted-globals */
const styles = {
  container: {
    position: "relative",
    width: "100%",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  secondContainer: {
    backgroundColor: "#000",
    padding: "64px 24px 92px",
    display: "flex",
    fontSize: "16px",
    fontWeight: 300,
  },
  muiContainer: {
    // padding: "0 !important",
    maxWidth: "1040px",
    margin: "0 auto",
    width: "100%",
  },
  luxusLogo: {
    margin: "5px 20px 20px 0px",
  },
  contactList: {
    lineHeight: 1,
    padding: 0,
    color: themeData.WhiteColor,
    fontSize: "16px",
    textRendering: "optimizeLegibility",
    fontFamily: themeData.DefaultFontBook,
  },
  contactListText: {
    lineHeight: 1,
    padding: 0,
    fontFamily: themeData.DefaultFontBook,
    color: themeData.WhiteColor,
    fontSize: "16px",
  },
  socialDataBox: {},
  socialDataLink: {
    marginRight: "24px",
  },
  finePrint: {
    color: "#fff",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Futura PT Book",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    marginBottom: "15px",
    fontStyle: "italic",
  },
  copyrightBox: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    lineHeight: 0.5,
    paddingTop: "24px",
    paddingBottom: "18px",
    marginTop: "10px",
    flexDirection: "row",
    alignItems: "baseline",
  },
  allRightsReserved: {
    flexDirection: "row",
    marginX: "2px",
  },
  linkSettingsWhite: {
    color: themeData.WhiteColor,
    textAlign: "left",
    marginRight: "2px",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: themeData.DefaultFontBook,
    cursor: "pointer",
    lineHeight: "18px",
    letterSpacing: "0.05em",
    textDecorationLine: "none",
    transition: "0.2s",
    "&:hover": {
      color: "#7B7B8F",
      transition: "0.2s",
    },
  },
  text: {
    color: "#dbdbdb",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0.05em",
    fontFamily: themeData.DefaultFontBook,
  },
  links: {
    cursor: "pointer",
    wordBreak: "break-all",
    textDecorationLine: "none",
    transition: "0.2s",
    "&:hover": {
      color: "#568DFF",
      transition: "0.2s",
    },
  },
  dividerBox: {
    alignContent: "flex-end",
    marginRight: 0,
    marginY: "10px",
    paddingLeft: "1px",
    textAlign: undefined,
    paddingBottom: "2px",
  },
  contactDataText: {
    fontSize: "20px",
    color: themeData.WhiteColor,
    maxWidth: "230px",
    minHeight: "30px",
    marginTop: "5px",
    marginBottom: "5px",
    textOverflow: "clip",
    lineHeight: "30px",
    fontWeight: "bold",
  },
  textBox: {
    maxWidth: "99%",
  },
  iconContactListItem: {
    minWidth: "24px",
  },
  iconContacts: {
    color: themeData.WhiteColor,
    fontSize: "16px",
  },
  upperFooter: {
    padding: "50px 0 20px 0",
    borderTop: "1px solid rgba(132, 132, 132, 0.7)",
    borderBottom: "1px solid rgba(132, 132, 132, 0.7)",
    margin: "20px 0",
  },
  footerTextCols: {
    //overflow: 'hidden',
    maxWidth: "1040px",
    width: "100%",
  },
  finePrintTitle: {
    fontSize: "20px",
    marginLeft: "3%",
    paddingBottom: "5px",
    color: "rgb(171,171,182)", //themeData.FinePrintColor,
    fontWeight: 800,
    lineHeight: "20px",
    fontFamily: "Futura PT Book",
  },
  italicText: {
    fontStyle: "italic",
  },
};

export const commonStyles = { ...styles } as StyleRules<
  {},
  keyof typeof styles
>;

export type FooterClasses = keyof typeof commonStyles;
