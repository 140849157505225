const LuxusLogoNoTextWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
    >
      <g clipPath="url(#clip0_7093_15750)">
        <path
          d="M0.191895 39.8189L18.2753 21.9816L32.9437 36.4122L22.0782 47.1299L14.8216 39.972L18.2364 36.6036L22.0782 40.3931L26.1528 36.4122L18.2753 28.6419L6.98286 39.7806L21.8842 54.4792L55.4121 21.4074L40.5108 6.70887L29.2572 17.8094L36.9795 25.4266L40.8601 21.5988L37.1347 17.9242L40.5496 14.5558L47.651 21.5605L36.9407 32.2017L22.4274 17.8859L40.5108 0.048584L80.9849 39.972L62.9016 57.8093L48.3883 43.4936L59.0598 32.9672L66.1612 39.972L62.7463 43.3405L59.021 39.6658L55.1405 43.4936L62.8628 51.1108L74.1164 40.0103L59.2151 25.3117L25.6871 58.3835L40.5884 73.0821L51.8808 61.9433L44.0033 54.173L39.9287 58.1921L43.7705 61.9816L40.3556 65.35L33.099 58.1921L43.9645 47.4744L58.633 61.9433L40.5496 79.7806L0.191895 39.8189Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7093_15750">
          <rect width="81" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LuxusLogoNoTextWhite;
