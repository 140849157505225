import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { BankAccountData, CreditCardData } from '../../../types/Account';
import { PaymentType } from '../../../types/enums/PaymentType';
import PaymentEdit from '../../Profile/components/PaymentEdit';
import EditIcon from '@mui/icons-material/Edit';

interface PaymentMethodTileProps {
  paymentMethod:
    | ({ type: PaymentType.BANK } & BankAccountData)
    | ({ type: PaymentType.CARD } & CreditCardData);
  onPaymentChoicesUpdated?: Function;
}

export const PaymentMethodTile = ({
  paymentMethod,
  onPaymentChoicesUpdated,
}: PaymentMethodTileProps) => {
  const [showEditPayment, setShowEditPayment] = useState(false);
  const narrowScreen = useMediaQuery('(max-width:400px)');

  const labels = {
    [PaymentType.BANK]: 'Bank Account',
    [PaymentType.CARD]: 'Credit Card',
    VI: 'Visa',
    MC: 'Master Card',
    DI: 'Discover',
    AMEX: 'American Express',
    DIN: 'Diners Club',
  };

  const name =
    paymentMethod.type === PaymentType.BANK
      ? paymentMethod.AccountNickName
      : labels[paymentMethod.cardType ?? 'VI'];
  const acctNumber =
    paymentMethod.type === PaymentType.BANK
      ? paymentMethod.AccountNumber
      : paymentMethod.creditCardNumber;

  const editMethodHandler = () => {
    setShowEditPayment(true);
  };

  return (
    <>
      <Grid container marginY={'0.5em'}>
        {/* <Box display={'flex'} flexDirection='row' alignItems={'center'}> */}
        <Grid item md={5} xs={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              align: 'left',
              // marginLeft: '0.5rem',
              width: { md: '120px', xs: '100%' },
              overflow: 'clip',
            }}
          >
            <Typography
              component="h6"
              noWrap
              sx={{
                color: '#000000',
                fontSize: '14px',
              }}
            >
              {labels[paymentMethod.type]}
            </Typography>
            <Typography
              component="h6"
              sx={{
                alignSelf: narrowScreen ? 'safe end' : 'auto',
                color: '#000000',
                fontSize: '14px',
                width: 'fit-content',
                textAlign: 'right',
              }}
            >
              {acctNumber}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={5} xs={5}>
          <Box
            display={'flex'}
            flexDirection="row"
            flex={2}
            sx={{
              marginLeft: '1rem',
              height: '100%',
            }}
          >
            <Typography
              component="h6"
              align="left"
              textOverflow={'ellipsis'}
              noWrap
              sx={{
                color: '#000000',
                fontSize: '14px',
                alignSelf: 'center',
              }}
            >
              {name}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={2} xs={1}>
          <Box
            display={'flex'}
            flexDirection="row"
            sx={{
              marginLeft: '1rem',
              width: '40px',
              height: '100%',
            }}
          >
            <Button onClick={editMethodHandler}>
              {narrowScreen ? (
                <EditIcon />
              ) : (
                <Typography
                  component="h6"
                  align="right"
                  sx={{
                    color: '#277ef7',
                    fontSize: '11px',
                  }}
                >
                  Edit
                </Typography>
              )}
            </Button>
          </Box>
        </Grid>
        {/* </Box> */}
      </Grid>
      <PaymentEdit
        showModal={showEditPayment}
        singleEdit={true}
        bankAccountData={
          paymentMethod.type === PaymentType.BANK
            ? (paymentMethod as BankAccountData)
            : undefined
        }
        creditCardData={
          paymentMethod.type === PaymentType.CARD
            ? (paymentMethod as CreditCardData)
            : undefined
        }
        selectedType={paymentMethod.type}
        handleClose={() => {
          setShowEditPayment(false);
        }}
        onCancel={() => {
          setShowEditPayment(false);
        }}
        onSave={() => {
          setShowEditPayment(false);
          if (onPaymentChoicesUpdated) onPaymentChoicesUpdated();
        }}
      />
    </>
  );
};
