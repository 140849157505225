export const classes = {
  heroWrapper: (isMobile: boolean) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: isMobile ? "769px" : "1011px",
    width: "100%",
    textAlign: "center",
    color: "white",
    zIndex: 3,
  }),
  bgImage: (isMobile: boolean) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: isMobile ? "bottom" : "center",
    zIndex: 1,
    filter: "brightness(1.30)",
  }),
  heroContainer: (isMobile: boolean) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: isMobile ? "27px" : "48px",
    maxWidth: "890px",
    padding: isMobile ? "0 20px" : "0px",
    zIndex: 2,
  }),
  title: (isMobile: boolean) => ({
    fontSize: isMobile ? "30px" : "40px",
    fontFamily: "Telugu MN",
    lineHeight: isMobile ? "36.43px" : "48.57px",
    letterSpacing: "2px",
    boxShadow:
      "1px 1px 74px 0px rgba(0,0,0,0.75), inset 1px -200px 20px 1px rgba(0,0,0,0.3) ",
    borderRadius: "15px",
  }),
  subTitle: {
    fontSize: "30px",
    fontFamily: "Telugu MN",
    lineHeight: "36.43px",
    letterSpacing: "2px",
    fontWeight: 400,
  },
  text: (isMobile: boolean) => ({
    fontSize: isMobile ? "20px" : "25px",
    lineHeight: isMobile ? "25.64px" : "32.05px",
    maxWidth: "654px",
    fontFamily: "Futura PT Book",
    boxShadow:
      "1px 1px 74px 0px rgba(0,0,0,0.75), inset 1px -200px 20px 1px rgba(0,0,0,0.4) ",
    borderRadius: "20px",
  }),
  italicText: {
    fontSize: "12px",
    fontStyle: "italic",
    lineHeight: "15.36px",
    color: "#7B7B8F",
    fontFamily: "Futura PT Book",
  },
  vipTierListWrapper: (isMobile: boolean) => ({
    width: "calc(100% - 40px)",
    minHeight: "600px",
    height: "fit-content",
    padding: isMobile ? "32px 20px 16px 20px" : "64px 20px 24px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center",
    marginBottom: isMobile ? "0px" : "80px",
  }),
  vipTierListContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "48px",
  },
  vipTierListText: {
    fontSize: "16px",
    lineHeight: "20.51px",
    fontFamily: "Futura PT Book",
  },
  sliderImage: {
    height: "250px",
    objectFit: "cover",
    overflow: "hidden",
  },
};
