import { Box, Typography } from "@mui/material";
import React, { ChangeEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { InputField } from "components/simpleComponents/Input/InputField/InputField";
import InputSubmit from "components/simpleComponents/Input/InputSubmit/InputSubmit";
import RadioButton from "components/simpleComponents/Radio/RadioButton";
import { SuccessModal } from "components/Footer/SuccessModal/SuccessModal";

import { useServerAPI } from "apis";
import { getHubSpotCookie, sendHubSpotData } from "apis/hubSpot";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  agreement: false,
};

const initialErrorState = {
  firstName: "",
  lastName: "",
  email: "",
  agreement: "",
};

type JoinFormProps = {
  buttonText: string;
  setIsUserSubscribed?: (value: boolean) => void;
  isUserSubscribed?: boolean;
  pageUri: string;
  pageName: string;
};

const JoinForm: React.FC<JoinFormProps> = ({
  buttonText,
  pageUri,
  pageName,
}) => {
  const formSubmitted = useRef<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [formState, setFormState] = useState(initialFormState);
  const [error, setError] = useState(initialErrorState);

  const api = useServerAPI();

  const isValid = (name: string, value: string | boolean): string => {
    if (typeof value === "boolean") {
      return value ? "" : "Required";
    }
    if (value.length === 0) {
      return "Required";
    }
    if (name === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return "Invalid email";
    }
    if (
      name !== "email" &&
      !(value.length <= 50 && /^[A-Za-z0-9-`']*$/.test(value))
    ) {
      return "Invalid name";
    }
    return "";
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
    if (isValid(name, value) === "") {
      setError((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleAgreement = () => {
    setFormState((prev) => ({ ...prev, agreement: !prev.agreement }));
    if (!formState.agreement) {
      setError((prev) => ({ ...prev, agreement: "" }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = Object.keys(formState).reduce((acc, key) => {
      acc[key as keyof typeof initialErrorState] = isValid(
        key,
        formState[key as keyof typeof initialErrorState]
      );
      return acc;
    }, {} as typeof initialErrorState);
    if (Object.values(errors).some((x) => x !== "")) {
      setError(errors);
      return;
    }

    localStorage.setItem("signUpFirstName", formState.firstName);
    localStorage.setItem("signUpLastName", formState.lastName);
    localStorage.setItem("signUpEmail", formState.email);

    if (formSubmitted.current) {
      return;
    }

    try {
      const ip = await api.getIPFromAmazon();

      const formId = process.env.REACT_APP_HUBSPOT_FORM_NEWSLETTER_GUID || "";
      const trackingCookie = getHubSpotCookie() || "";

      const response = await sendHubSpotData(
        {
          firstName: formState.firstName,
          lastName: formState.lastName,
          email: formState.email,
          ipAddress: ip,
          trackingCookie,
          pageUri,
          pageName,
        },
        formId
      );

      if (response.inlineMessage === "Thanks for submitting the form.") {
        formSubmitted.current = true;
        setModalVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: "508px",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "10px",
            "@media (min-width: 576px)": {
              flexDirection: "row",
              gap: "20px",
            },
          }}
        >
          <InputField.White
            onChange={handleChange}
            value={formState.firstName}
            placeholder={"FIRST NAME"}
            name="firstName"
            error={!!error.firstName}
          />
          <InputField.White
            onChange={handleChange}
            value={formState.lastName}
            placeholder={"LAST NAME"}
            name="lastName"
            error={!!error.lastName}
          />
        </Box>
        <Box sx={{ padding: "16px 0px" }}>
          <InputSubmit.White
            name="email"
            buttonText={buttonText}
            placeholder="EMAIL ADDRESS"
            onSubmit={handleSubmit}
            onChange={handleChange}
            value={formState.email}
            eye={false}
            error={!!error.email}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RadioButton.Black
            label=""
            value={formState.agreement}
            onChange={() => {}}
            onClick={handleAgreement}
            error={!!error.agreement}
          />
          <Typography sx={{ color: "black", fontFamily: "Futura PT Book" }}>
            I have read and agree to the{" "}
            <Link target="_blank" style={{ color: "inherit" }} to={"/privacy"}>
              Privacy Policy
            </Link>
            ,{"  "}
            <Link
              target="_blank"
              style={{ color: "black" }}
              to={"/termsOfService"}
            >
              Terms of Service
            </Link>
          </Typography>
        </Box>
      </form>

      <SuccessModal visible={modalVisible} setModalVisible={setModalVisible} />
    </>
  );
};

export default JoinForm;
