import PlaidIcon from "assets/icons/PlaidIcon";
import { CustomButton } from "components/UIComponents/Button";

import styles from "./PlaidButton.module.scss";

type PlaidButtonProps = {
  handleClick: () => void;
};

const PlaidButton = ({ handleClick }: PlaidButtonProps) => {
  return (
    <>
      <CustomButton btnStyle="inlineDarkAlternative" onClick={handleClick}>
        <span className={styles.buttonText}>Pay with</span>
        <PlaidIcon />
      </CustomButton>
    </>
  );
};

export default PlaidButton;
