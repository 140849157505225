import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BasicOfferCard,
  ChildsCardProps,
} from "../../../../Assets/components/CurrentOfferingAlt/components/BasicOfferCard/BasicOfferCard";

import { useStyles } from "./styles/";
import Dahlia from "./../../../../../AssetsUnlogged/assets/dahlia.png";
import { handleSectionScroll } from "utils/handleSectionScroll";
import {
  SCROLL_QUERY_PARAM,
  STREAM_SECTION_ID,
} from "pages/NewAssests/Assets/components/StreamCountdown/constants";

type FeaturedOfferingCardProps = ChildsCardProps;

const FeaturedOfferingCard: React.FC<FeaturedOfferingCardProps> = function (
  props
): JSX.Element {
  const navigate = useNavigate();
  const { leftCornerTitleBoxClass } = useStyles();

  useEffect(() => {
    handleSectionScroll(SCROLL_QUERY_PARAM, STREAM_SECTION_ID);
  }, []);

  const onByBtnClick = (): void => {
    navigate(`/assetDetails/${props.offer.offeringId}`);
  };

  return (
    <BasicOfferCard
      {...props}
      leftCornerTitleBoxClass={leftCornerTitleBoxClass}
      leftCornerTitle="SOLD PRIVATELY"
      buyButtonText="VIEW ASSET"
      onBuyBtnClick={onByBtnClick}
      lastInfoTitleType="GIA Report Grade"
      type="large"
      btnType={"inlineGrey"}
      img={Dahlia}
    />
  );
};

export default FeaturedOfferingCard;
