import { Offering } from '../types/Offerings';
import { NumberFormatter } from './DataFormatter';
import formatNumber from './FormatNumber';

export interface OfferingFormated {
  foundedPercent: string;
  foundedPercentValue: number;
  purchasedSharedCount: number;
  availabelSharedCount: number;
  purchasedShared: string;
  availabelShared: string;
  valueAmount: string;
  perShareAmount: string;
  isActive: boolean;
  investSpecValue: Function;
  totalFor: Function;
}

/**
 *
 * Currently only deals with one format/language but cane be exteded to support other if needed.
 *
 * @param offering
 * @returns OfferingFormated object with formated values
 */
export const OfferingFormater = (
  offering: Offering,
  currency?: string,
  addCents?: boolean
): OfferingFormated => {
  // const centsSuffx = addCents ? `.00` : '';
  const centsSuffx = addCents ? `` : '';
  const suffix = currency ? ` ${currency}` : '';

  var formatedData: OfferingFormated = {
    foundedPercent: '',
    purchasedSharedCount: 0,
    availabelSharedCount: 0,
    purchasedShared: '',
    availabelShared: '',
    foundedPercentValue: 0,
    valueAmount: '',
    perShareAmount: '',
    isActive: false,
    investSpecValue: (value: string) => {},
    totalFor: (qte: number) => {},
  };

  const calcTotal = (unitPrice: string) => (qte: number) => {
    return `$${nf.format(parseInt(unitPrice) * qte)}${centsSuffx}${suffix}`;
  };

  const specValue = (offering: Offering) => (value: string) => {
    const exp = /__([a-zA-Z\d]*)__/;
    const match = value.match(exp);
    const key = (match?.length ?? 0) > 1 ? (match ?? [])[1] : '';
    if (key && offering.offering && offering.offering[key]) {
      var newValue = value.replace(exp, offering.offering[key]);
      switch (key) {
        case 'targetAmount':
          newValue = formatedData.valueAmount;
          break;
        case 'totalShares':
          newValue = `${formatedData.availabelShared}`;
          break;
        case 'unitPrice':
          newValue = formatedData.perShareAmount;
          break;
      }
      return newValue;
    }
    return value;
  };

  var nf = new Intl.NumberFormat();
  if (offering.offering) {
    formatedData.purchasedSharedCount =
      parseFloat(offering.offering.totalShares) -
      parseFloat(offering.offering.remainingShares);
    formatedData.purchasedShared = NumberFormatter(
      formatedData.purchasedSharedCount
    );
    formatedData.availabelSharedCount = parseFloat(
      offering.offering.totalShares
    );
    formatedData.availabelShared = NumberFormatter(
      formatedData.availabelSharedCount
    );
    formatedData.valueAmount = `$${nf.format(
      parseInt(offering.offering?.targetAmount)
    )}${suffix}`;
    formatedData.foundedPercentValue = Math.round(
      100 *
        (formatedData.purchasedSharedCount /
          parseFloat(offering.offering.totalShares))
    );
    formatedData.foundedPercent = `${formatedData.foundedPercentValue}%`;
    formatedData.perShareAmount = `$${nf.format(
      parseInt(offering.offering?.unitPrice)
    )}${centsSuffx}${suffix}`;
    formatedData.isActive = offering.status === 'Active';
    formatedData.totalFor = calcTotal(offering.offering?.unitPrice);
    formatedData.investSpecValue = specValue(offering);
  }
  return formatedData;
};
