import PdfViewer from "./PdfViewer/PdfViewer";
import { useState, useEffect } from "react";

import InputSubmit from "components/simpleComponents/Input/InputSubmit/InputSubmit";
import { Preloader } from "components/simpleComponents/Preloader";

import {
  getPdfPreview,
  initiateAchPaymentFlow,
  signPdfDocument,
} from "apis/backend";
import { FormData } from "../AccreditationForm.type";
import { DOMICILES, STEPS } from "../AccreditationForm.data";

import style from "./style.module.scss";

const { ClientJS } = require("clientjs");

type FourthStepProps = {
  form: FormData;
  setStep: (step: STEPS) => void;
  guid: string;
};

const FourthStep = ({ setStep, guid, form }: FourthStepProps) => {
  const [isError, setIsError] = useState(false);
  const [pdfPreview, setPdfPreview] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await getPdfPreview(guid);

        const blob = new Blob([data], { type: "application/pdf" });

        setPdfPreview(URL.createObjectURL(blob));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [guid]);

  const client = new ClientJS();

  const residencyCondition =
    form.citizenship === DOMICILES.US_CITIZEN ||
    form.citizenship === DOMICILES.US_RESIDENT;

  const nonResidentCreditCardPaymentCondition =
    form.citizenship === DOMICILES.NON_US_CITIZEN && form.securitiesNumber <= 2;

  const successScreenPaymentCondition =
    (form.citizenship === DOMICILES.NON_US_CITIZEN &&
      form.securitiesNumber > 2) ||
    (residencyCondition && form.securitiesNumber > 40);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const targetElement = event.target as HTMLFormElement;
    const inputValue = (
      targetElement.elements[0] as HTMLInputElement
    ).value.trim();

    if (!inputValue) {
      setIsError(true);
      return;
    }

    // client data fingerprint
    const fingerprint = client.getFingerprint();

    try {
      setIsLoading(true);

      await signPdfDocument(guid);
      setIsLoading(false);

      if (nonResidentCreditCardPaymentCondition) {
        setStep(STEPS.CREDIT_CARD_FORM_NON_RESIDENT);
      }

      if (successScreenPaymentCondition) {
        await initiateAchPaymentFlow(guid, {});

        setStep(STEPS.SUCCESS_SCREEN_WIRE);
      }

      if (residencyCondition && form.securitiesNumber <= 40) {
        setStep(STEPS.ACH_CREDIT_CARD_FORM_RESIDENT);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  return (
    <>
      <div className={style.pdfContainer}>
        <p className={style.text}>You can check your document below.</p>
        <div className={style.viewerContainer}>
          {isLoading ? (
            <div className={style.preloaderContainer}>
              <Preloader isWhite />
            </div>
          ) : (
            <PdfViewer pdfPreview={pdfPreview} />
          )}
        </div>
        <form className={style.form} onSubmit={handleSubmit}>
          <p className={style.text}>
            <span className={style.commentStyle}>Electronic Signature:</span>{" "}
            Entering your name in the signature field constitutes your
            electronic signature for the subscribing to the offering. Any record
            containing an electronic signature shall be deemed for all purposes
            to have been “signed” and will constitute an “original” when printed
            from electronic records established and maintained by LUXUS, its
            affiliates, assigns or its agents.
          </p>
          <InputSubmit.Black
            eye={false}
            buttonText="SIGN"
            onSubmit={handleSubmit}
            placeholder="Type your name here"
            name="name"
            onChange={() => setIsError(false)}
          />
          {isError && <p className={style.error}>Please add your name!</p>}
        </form>
      </div>
    </>
  );
};

export default FourthStep;
