import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Typography,
  Stack,
} from "@mui/material";
import moment from "moment";
import ReactGA from "react-ga";

import { useStyles } from "./styles";

interface BirthdayFormProps {
  birthday: string | undefined;
  setDate(value: string): void;
}

interface DateParts {
  month: number | undefined;
  date: number | undefined;
  year: number | undefined;
}

const BirthdayForm: React.FC<BirthdayFormProps> = function ({
  setDate,
  birthday,
}): JSX.Element {
  const months = moment.months().map((month) => ({
    title: month,
    value: moment().month(month).format("MM"),
  }));

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/birthDayForm");
  }, []);

  const [dateParts, setDateParts] = useState<DateParts>({
    month: Number(birthday?.split("-")[0]) || undefined,
    date: Number(birthday?.split("-")[1]) || undefined,
    year: Number(birthday?.split("-")[2]) || undefined,
  });

  const [isAgeErrorActive, setIsAgeErrorActive] = useState<boolean>();

  const validateAge = (): boolean => {
    const { month, date, year } = dateParts;
    if (!month || !date || !year) return false;
    const d = new Date(year, month, date);
    const dob = moment(d);
    const now = moment();
    if (now.diff(dob, "years") < 18) {
      return false;
    }
    return true;
  };

  const checkAgeError = (): boolean => {
    const result = validateAge();
    setIsAgeErrorActive(!result);
    return result;
  };

  const getDaysAmount = (): number[] => {
    const { month } = dateParts;
    if (month !== undefined) {
      return new Array(
        moment(`${month < 10 ? `0${month}` : month}`, "MM").daysInMonth()
      )
        .fill(null)
        .map((_, index) => index + 1);
    }
    return new Array(31).fill(null).map((_, index) => index + 1);
  };

  useEffect(() => {
    const { month, date, year } = dateParts;
    if (month && date && year && checkAgeError())
      setDate(
        `${month.toString().padStart(2, "0")}-${date
          .toString()
          .padStart(2, "0")}-${year}`
      );
  }, [dateParts]);

  const getOnFieldChange =
    (type: keyof DateParts) =>
    (event: SelectChangeEvent): void => {
      const newDateParts = { ...dateParts };
      newDateParts[type] = Number(event.target.value);
      setDateParts(newDateParts);
    };

  //Must be 18 years old.
  const baseYear = new Date().getFullYear() - 18;

  const classes = useStyles();

  return (
    <>
      <Stack direction="row" spacing={1}>
        <FormControl>
          <Select
            displayEmpty
            id="birthday-month-select"
            placeholder="Month"
            value={dateParts.month ? dateParts.month.toString() : ""}
            onChange={getOnFieldChange("month")}
            className={classes.monthSelect}
          >
            <MenuItem disabled value="">
              <span className={classes.selectPlaceholder}>Month</span>
            </MenuItem>
            {months.map((month, index) => (
              <MenuItem value={index + 1}>{month.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            displayEmpty
            placeholder="Month"
            id="birthday-date-select"
            value={dateParts.date ? dateParts.date.toString() : ""}
            onChange={getOnFieldChange("date")}
            className={classes.daySelect}
          >
            <MenuItem disabled value="">
              <span className={classes.selectPlaceholder}>Date</span>
            </MenuItem>
            {getDaysAmount().map((date) => (
              <MenuItem value={date}>{date}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            displayEmpty
            id="birthday-year-select"
            value={dateParts.year ? dateParts.year.toString() : ""}
            onChange={getOnFieldChange("year")}
            className={classes.yearSelect}
          >
            <MenuItem disabled value="">
              <span className={classes.selectPlaceholder}>Year</span>
            </MenuItem>
            {new Array(90).fill(null).map((_, index) => {
              const value = baseYear - index;
              return <MenuItem value={value}>{value}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Stack>
      {isAgeErrorActive && (
        <Typography className={classes.errorText}>
          Must be 18 years old.
        </Typography>
      )}
    </>
  );
};

export default BirthdayForm;
