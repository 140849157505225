export const NumberFormatter = (
  value?: string | number,
  suffix?: string | string[]
) => {
  var nf = new Intl.NumberFormat();
  if (!value) return "";
  const num = typeof value === "string" ? parseInt(value) : value;
  if (suffix && !isNaN(num)) {
    if (typeof suffix === "string") {
      return `${nf.format(num)} ${suffix}`;
    } else if (Array.isArray(suffix)) {
      return `${nf.format(num)} ${
        suffix[Math.min(num - 1, suffix.length - 1)]
      }`;
    }
  }
  return `${nf.format(num)}`;
};

export const AmountFormatter = (value: string | number) => {
  var amt = typeof value === "string" ? parseFloat(value) : value;
  var af = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  return `${af.format(amt)}`;
};

export const DollarAmountFormatter = (value: string | number) => {
  if (typeof value === "string") {
    value = value.replaceAll("$", "").replaceAll(",", "");
    if (value.length === 0) return "";
  }
  var amt = typeof value === "string" ? parseFloat(value) : value;
  var af = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  return `${af.format(amt)}`;
};

export const DateFormatter = (value?: string | Date) => {
  const df = new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeStyle: "medium",
  });
  if (typeof value === "string") {
    // Ensure the date is properly formated for ISO
    if (value.charAt(10) !== "T") value = value.replace(" ", "T");
    if (value.match(/[0-9]$/)) value += "Z";
  }
  const aDate = typeof value === "string" ? Date.parse(value) : value;
  if (aDate) return `${df.format(aDate)}`;
  return "";
};

export const SSNFormatter = (value: string): string => {
  if (
    value &&
    !value.match(/[^\d-]/) &&
    value.replaceAll("-", "").length === 9
  ) {
    // Only format if valid chars
    const nums = value.replaceAll("-", "");
    return (
      nums.substring(0, 3) +
      "-" +
      nums.substring(3, 5) +
      "-" +
      nums.substring(5)
    );
  }
  return value;
};
export const SSNObfuscator = (value: string): string => {
  if (
    value &&
    !value.match(/[^\d-]/) &&
    value.replaceAll("-", "").length === 9
  ) {
    // Only format if valid chars
    const nums = value.replaceAll("-", "");
    return "***-**-" + nums.substring(5);
  }
  return value;
};
export const PhoneFormatter = (value: string): string => {
  var chkValue = value ? value.replaceAll(/[-()+\b\s/]/g, "") : undefined;
  if (chkValue && !chkValue.match(/[^\d]/) && chkValue.length > 9) {
    // Only format if valid chars
    if (chkValue.startsWith("1")) chkValue = chkValue.substring(1);
    return `${chkValue.substring(0, 3)}-${chkValue.substring(
      3,
      6
    )}-${chkValue.substring(6)}`;
  }
  return value;
};

export const MonthYearFormatter = (value: string): string => {
  if (
    typeof value === "string" &&
    !value.match(/[^\d/]/) &&
    value.replaceAll("/", "").length === 4
  ) {
    // Only format if valid chars
    const nums = value.replaceAll("/", "");
    return nums.substring(0, 2) + "/" + nums.substring(2);
  }
  return value;
};

/**
 * Adding separator to string value
 * from 12345678911234567
 * to 1234 5678 9123 4567
 * @param value
 * @param limit
 * @param separator
 * @returns
 */
export const SeparatorFormatter = (
  value: string = "",
  limit: number = 4,
  separator: string = " "
): string => {
  const output = [];
  const unmaskedValue = OnlyNumbersFormatter(value);

  for (let i = 0; i < unmaskedValue.length; i++) {
    if (i !== 0 && i % limit === 0) {
      output.push(separator);
    }

    output.push(unmaskedValue[i]);
  }

  return output.join("");
};

/**
 * return only number from string
 * @param value
 * @returns
 */
export const OnlyNumbersFormatter = (value: string = ""): string => {
  return value.replace(/[^\d]/g, "");
};

export const transformFirstCharToUpperCase = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatFinraLicense = (finraStatus: string, typeFinra: string) => {
  const finraStatusUpperCase = transformFirstCharToUpperCase(finraStatus);

  const typeFinraUpperCase =
    typeFinra.charAt(0).toUpperCase() + typeFinra.slice(1);

  return `${finraStatusUpperCase}, ${typeFinraUpperCase}`;
};
