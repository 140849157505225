import { useNavigate } from "react-router-dom";

import { CustomButton } from "components/UIComponents/Button";

import { CREDIT_CARD_SUCCESS_MESSAGE } from "../AccreditationForm.data";

import style from "./style.module.scss";

export const SuccessScreenCC = () => {
  const navigate = useNavigate();

  return (
    <div className={style.content}>
      <h2>SUCCESS!</h2>
      <p className={style.text}>{CREDIT_CARD_SUCCESS_MESSAGE}</p>
      <div className={style.center}>
        <CustomButton btnStyle="secondaryBlack" onClick={() => navigate("/")}>
          RETURN TO HOME
        </CustomButton>
      </div>
    </div>
  );
};
