import { Box, Typography, useMediaQuery } from "@mui/material";
import { Button } from "pages/Profile/ConfigureProfile/Button/Button";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { classes } from "./styles";

export const SuccessScreen: FC<{ isAuth: boolean }> = ({ isAuth }) => {
  const isMobileScreen = useMediaQuery("(max-width:776px)");
  const navigate = useNavigate();

  return (
    <Box sx={classes.bodyContainer(isMobileScreen)}>
      <Box sx={{ marginTop: isMobileScreen ? "81px" : "0px" }}>
        <Typography variant="h2" sx={classes.title}>
          SUCCESS
        </Typography>
        <Typography variant="h6" sx={classes.subTitle}>
          Thank you for joining the waitlist. You will be contacted by a member
          of our team shortly.{" "}
          {!isAuth &&
            "Click the button below to create a LUXUS account to start your investment journey."}
        </Typography>
      </Box>
      {!isAuth ? (
        <Box sx={{ marginTop: "32px" }}>
          <Button.Black
            onClick={() => navigate("/signup")}
            value={"FINISH SIGN UP"}
          />
        </Box>
      ) : (
        <Box sx={{ marginTop: "32px" }}>
          <Button.Black
            styles={{ width: "fit-content", padding: "0px 16px" }}
            onClick={() => navigate("/")}
            value={"RETURN TO HOMEPAGE"}
          />
        </Box>
      )}
    </Box>
  );
};
