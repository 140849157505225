import { useSelector } from "react-redux";
import {ChangeEvent, useState} from "react";
import type { SelectChangeEvent } from "@mui/material";
import { userProfileSelector } from "../../../../../store/selectors/user";
import type { BuyAsSelectFieldProps } from ".";

interface OptionItem {
  value: string;
  label: string;
}

interface UseBuyAsSelectFieldFacadeResult {
  options: OptionItem[];
  value: string;
  onChange: (event: ChangeEvent<{ value: string }>) => void;
}

export function useBuyAsSelectFieldFacade({
  setAccountId,
}: BuyAsSelectFieldProps): UseBuyAsSelectFieldFacadeResult {
  const associatedAccounts =
    useSelector(userProfileSelector)?.associatedAccounts ?? [];

  const options: OptionItem[] = [
    {
      label: "An Individual",
      value: "Individual",
    },
    ...associatedAccounts.map(({ accountId, accountName }) => ({
      label: accountName,
      value: accountId,
    })),
  ];

  const [value, setValue] = useState(options[0].value);

  const onChange = (event: ChangeEvent<{ value: string }>) => {
    const newValue = event.target.value;
    setValue(newValue);
    setAccountId(newValue === options[0].value ? "" : newValue);
  };

  return { options, value, onChange };
}
