export const AccreditationStatusConfig = [
  {
    bold: "I am an Accredited Investor, ",
    regular:
      "because I have an individual income of more than $200,000 per year, or a joint income of $300,000 in each of the last two years.",
    value: "200or300",
  },
  {
    bold: "I am an Accredited Investor, ",
    regular:
      "because I have a net worth exceeding $1,000,000, excluding my primary residence, either individuality or jointly with my spouse.",
    value: "over1m",
  },
  {
    bold: "I am not Accredited, ",
    regular:
      "I acknowledge that my investment(s) in this offering is less than 10% of my net worth or annual gross income.",
    value: "notaccredited",
  },
];
