export const styles = {
  leftButton: {
    color: '#7B7B8F',
    borderRadius: '50px 0px 0px 50px',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s',
  },
  rightButton: {
    color: '#7B7B8F',
    borderRadius: '0px 50px 50px 0px',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s',
  },
  counter: {
    color: 'white',
  },
};

export const stylesV2 = {
  leftButton: {
    color: '#7B7B8F',
    minWidth: '44px !important',
    borderRadius: '50px 0px 0px 50px',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s',
  },
  rightButton: {
    color: '#7B7B8F',
    minWidth: '44px !important',
    borderRadius: '0px 50px 50px 0px',
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s',
  },
  counter: {
    color: 'white',
  },
};
