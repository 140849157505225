import { useMediaQuery } from "@mui/material";

import Medal from "assets/icons/Medal";
import FamilyTree from "../FamilyTree/FamilyTree";
import FamilyTreeSmall from "../FamilyTreeSmall/FamilyTreeSmall";

import styles from "./Pedigree.module.scss";

const Pedigree = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <div>
            <Medal />
          </div>
          <p className={styles.listItemText}>
            Quality Road’s stakes wins include wins in the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2010_Woodward_G1"
            >
              2010 Woodward (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2010_Metropolitan_H_Met_Mile_G1"
            >
              2010 Metropolitan (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2010_Donn_Hcp_G1"
            >
              2010 Donn Handicap (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2010_Hals_Hope_G3"
            >
              2010 Ghostzapper (G3),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_Amsterdam_G2"
            >
              2009 Amsterdam (G2),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_Florida_Derby_G1"
            >
              2009 Florida Derby (G1),
            </a>{" "}
            and the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_Fountain_of_Youth_G2"
            >
              2009 Fountain of Youth (G2)
            </a>
          </p>
        </li>
        <li className={styles.listItem}>
          <div>
            <Medal />
          </div>
          <p className={styles.listItemText}>
            {" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.bloodhorse.com/stallion-register/stallions/133834/quality-road"
            >
              Quality Road
            </a>{" "}
            stands at Lane's End for $200,000 (2024).
          </p>
        </li>
        <li className={styles.listItem}>
          <div>
            <Medal />
          </div>
          <p className={styles.listItemText}>
            Beauly’s stakes wins include wins in the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2017_Indiana_General_Assembly_Distaff"
            >
              2017 Indiana General Assembly Distaff,
            </a>{" "}
            and the
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2016_Maiden_Fillies_Stakes_3"
            >
              {" "}
              2016 Maiden Fillies' Stakes.
            </a>
          </p>
        </li>
        <li className={styles.listItem}>
          <div>
            <Medal />
          </div>
          <p className={styles.listItemText}>
            Elusive Quality’s stakes wins include wins in the
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/1998_Poker_Handicap_G3"
            >
              1998 Poker (G3),
            </a>{" "}
            and the
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/1998_Jaipur_G3"
            >
              1998 Jaipur (G3)
            </a>
          </p>
        </li>
        <li className={styles.listItem}>
          <div>
            <Medal />
          </div>
          <p className={styles.listItemText}>
            Sea the Stars’ stakes wins include wins in the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_Prix_de_lArc_de_Triomphe_G1"
            >
              {" "}
              2009 Prix De l'Arc de Triomphe (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_Irish_Champion_Stakes_G1"
            >
              2009 Irish Champion Stakes (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_Juddmonte_International_G1"
            >
              2009 Juddmonte International Stakes (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_Coral_Eclipse_G1"
            >
              2009 Coral-Eclipse (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_Investec_Derby_G1"
            >
              2009 Epsom Derby (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2009_2000_Guineas_G1"
            >
              2009 English 2000 Guineas (G1),
            </a>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/2008_Beresford_Stakes_G2"
            >
              2008 Beresford Stakes (G2),
            </a>{" "}
            and the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles.listItemLink}
              href="https://www.horseracingnation.com/race/Leo_20080817_Race_1_MSW"
            >
              2008 Maiden
            </a>{" "}
          </p>
        </li>
      </ul>
      {isMobile ? <FamilyTreeSmall /> : <FamilyTree />}
    </div>
  );
};

export default Pedigree;
