import AssetThoroughbred from 'components/simpleComponents/AssetThoroughbred/AssetThoroughbred';

import styles from './Offering.module.scss';
import { ASSET_DETAILS } from './constants';
import { ROUTES } from 'utils/constants';

const Offering = () => {
  function handleClickFirstButton() {
    const a = document.createElement('a');
    a.href =
      process.env.REACT_APP_FRONTEND_BASE_URL +
      ROUTES.MISSED_THE_CUT_FACT_SHEET;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
    a.remove();
  }

  function handleClickSecondButton() {}

  return (
    <section className={styles.container}>
      <AssetThoroughbred
        image="/images/thoroughbredAsset.jpg"
        labelText="COMPLETED OFFERING"
        title="Missed the Cut"
        assetDetails={ASSET_DETAILS}
        onClickFirstButton={handleClickFirstButton}
        onClickSecondButton={handleClickSecondButton}
        mainButtonStyle="secondaryGreyBorder"
        secondaryButtonStyle="thirdGreyAlternative"
      />
    </section>
  );
};

export default Offering;
