import React from "react";
import { useMediaQuery, Box } from "@mui/material";
import OfferCard from "./components/OfferCard";
import IOIOfferCard from "../IOIOffering/components/IOIOfferCard";
import { Offering } from "../../../../../types/Offerings";

import { useOfferGridStyles } from "../styles/offerGridStyles";
import SoldOutCard from "./components/SoldOutCard";

interface CurrentOfferingGridProps {
  isShowMoreCurrent: boolean;
  offerings: Offering[];
  getOnBuyClick: (id: string) => () => void;
  getOnIOIClick: (id: string) => () => void;
}

export const viewDealsSheetUrl = (offer: Offering): string =>
  offer.assets?.find(
    (asset) =>
      asset.type === "download" &&
      !asset.label.includes("GIA") &&
      !asset.label.includes("IGI") &&
      !asset.label.includes("FCRF")
  )?.url || "";

const CurrentOfferingGrid: React.FC<CurrentOfferingGridProps> = function ({
  isShowMoreCurrent,
  offerings,
  getOnBuyClick,
  getOnIOIClick,
}): JSX.Element {
  const desktop = useMediaQuery("(min-width:900px)");

  const { container } = useOfferGridStyles();

  return (
    <Box className={container}>
      {desktop
        ? offerings.map((offer) => {
            return (
              <Box>
                {offer.status === "Active" && (
                  <OfferCard
                    name={offer.name}
                    totalPrice={offer.offering?.targetAmount as string}
                    pricePerShare={offer.offering?.unitPrice as string}
                    imageSrc={offer.image.url}
                    funded={offer.minFundedPct as number}
                    onBuyButtonClick={getOnBuyClick(offer.offeringId)}
                    viewDealSheetUrl={viewDealsSheetUrl(offer)}
                  />
                )}
                {offer.status === "IOI" && (
                  <IOIOfferCard
                    imageSrc={offer.image.url}
                    name={offer.name}
                    totalPrice={offer.offering?.targetAmount ?? ""}
                    pricePerShare={offer.offering?.unitPrice ?? ""}
                    onMarketDate={offer.DateOnMarket}
                    onBuyButtonClick={getOnIOIClick(
                      offer.offering?.offeringId ?? ""
                    )}
                    viewDealSheetUrl={viewDealsSheetUrl(offer)}
                  />
                )}
                {offer.status === "SoldOut" && (
                  <SoldOutCard
                    name={offer.name}
                    totalPrice={offer.offering?.targetAmount as string}
                    pricePerShare={offer.offering?.unitPrice as string}
                    imageSrc={offer.image.url}
                    funded={offer.minFundedPct as number}
                    onBuyButtonClick={getOnBuyClick(offer.offeringId)}
                    viewDealSheetUrl={viewDealsSheetUrl(offer)}
                  />
                )}
              </Box>
            );
          })
        : offerings.map((offer, index) => {
            return !isShowMoreCurrent && index > 1 ? null : (
              <>
                {offer.status === "Active" && (
                  <OfferCard
                    name={offer.name}
                    totalPrice={offer.offering?.targetAmount as string}
                    pricePerShare={offer.offering?.unitPrice as string}
                    imageSrc={offer.image.url}
                    funded={offer.minFundedPct as number}
                    onBuyButtonClick={getOnBuyClick(offer.offeringId)}
                    viewDealSheetUrl={viewDealsSheetUrl(offer)}
                  />
                )}
                {offer.status === "IOI" && (
                  <IOIOfferCard
                    imageSrc={offer.image.url}
                    name={offer.name}
                    totalPrice={offer.offering?.targetAmount ?? ""}
                    pricePerShare={offer.offering?.unitPrice ?? ""}
                    onMarketDate={offer.DateOnMarket}
                    onBuyButtonClick={getOnIOIClick(
                      offer.offering?.offeringId ?? ""
                    )}
                    viewDealSheetUrl={viewDealsSheetUrl(offer)}
                  />
                )}
                {offer.status === "SoldOut" && (
                  <SoldOutCard
                    name={offer.name}
                    totalPrice={offer.offering?.targetAmount as string}
                    pricePerShare={offer.offering?.unitPrice as string}
                    imageSrc={offer.image.url}
                    funded={offer.minFundedPct as number}
                    onBuyButtonClick={getOnBuyClick(offer.offeringId)}
                    viewDealSheetUrl={viewDealsSheetUrl(offer)}
                  />
                )}
              </>
            );
          })}
    </Box>
  );
};

export default CurrentOfferingGrid;
