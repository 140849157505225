import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        downloadImage: {
          height: "33px",
          width: "109px",
        },
        secImage: {
          height: "33px",
          width: "71px",
        },
        giaImage: {
          height: "33px",
          width: "76px",
        },
        igiImage: {
          height: "33px",
          width: "60px",
        },
        fcrfImage: {
          height: "33px",
          width: "71px",
        },
      })
    )
);
