import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "./IntersectionBlock.module.scss";
import { iconsMobile, iconsDesktop } from "./constants";
import { Icon } from "./types";

const IntersectionBlock = () => {
  const isWidthMatches = useMediaQuery("(min-width: 576px)");

  const icons = isWidthMatches ? iconsDesktop : iconsMobile;
  return (
    <section className={styles.section}>
      <h2 className={styles.title}>AT THE INTERSECTION OF FINANCE & LUXURY</h2>
      <ul className={styles.iconList}>
        {icons.map(({ id, path, alt, width, height, link }: Icon) => (
          <li key={id} className={styles.iconItem}>
            <a href={link} target="_blank" rel="noreferrer noopener">
              <img src={path} alt={alt} width={width} height={height} />
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default IntersectionBlock;
