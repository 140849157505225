import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

const btnStyles = {
  padding: "8px 6px",
  "& svg": {
    fontSize: "19px",
  },
};

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          width: "139px",
          height: "41px",
        },
        text: {
          fontSize: "19px",
          width: "73px",
        },
        decBtn: {
          borderTopLeftRadius: "6px !important",
          borderBottomLeftRadius: "6px !important",
          ...btnStyles,
        },
        incBtn: {
          borderTopRightRadius: "6px !important",
          borderBottomRightRadius: "6px !important",
          ...btnStyles,
        },
      })
    )
);
