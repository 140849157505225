import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DisplayTypes } from "../../../types/enums/DisplayTypes";

import type { SystemInitialState } from "./interface";

const initialState: SystemInitialState = {
  displayType: DisplayTypes.Mobile,
  ip: "",
};

const systemSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDisplayType(state, { payload }: PayloadAction<DisplayTypes>) {
      state.displayType = payload;
    },
    setIp(state, { payload }: PayloadAction<string>) {
      state.ip = payload;
    },
  },
});

export const { setDisplayType, setIp } = systemSlice.actions;
export default systemSlice.reducer;
