import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(mixStyleRules(commonStyles, {
      container: {
          fontSize: "16px",
          "& .MuiTextField-root": {
              borderRadius: "5px",
          },
          "& .MuiOutlinedInput-root": {
              height: "44px",
              fontWeight: "400",
              paddingRight: "20px !important",
          },
          "& .MuiAutocomplete-input": {
              padding: "4.5px 4px 7.5px 6px !important",
              maxWidth: "100px",
          },
          "& input::placeholder": {
              fontSize: "15px",
          }
      },
  }))
);
