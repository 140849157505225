import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

type ZohoLead = {
  firstName: string;
  lastName: string;
  email: string;
};

export const submitZohoLead = (data: ZohoLead) => {
  const url = 'https://crm.zoho.com/crm/WebToLeadForm';

  const formData = {
    xnQsjsdp:
      '056ec9f4f22cbb054dfd7e31a187b36e056040734ee84a665f219c92d7077e24',
    zc_gad: '',
    xmIwtLD: 'c2be93417ef42dc4032c61ed7d937cfe0ee5831e6b99086643b36652ef202ca9',
    actionType: 'TGVhZHM=',
    'First Name': data.firstName,
    'Last Name': data.lastName,
    Email: data.email,
    'Lead Source': 'Online Store',
  };

  console.debug(' ZOHO form data ', formData);

  axios.post(url, qs.stringify(formData));
};

// For future integration with Zoho

const refreshZohoToken = async (): Promise<void> => {
  const refresh_token =
    '1000.4ba787c7d29210c8a65f41febd67595b.d9fca76ed7353bffa882fd6fbee04d41';
  const client_id = '1000.282XS6QTSFRO74HLD33LDDG7QEUBVJ';
  const client_secret = '858574c5926d2bb1e03539bc68db92d5ef3c8576dc';

  const url = `https://restportalproxy.braincloudservers.com/v2/script/RUN/RefreshZohoToken`;

  const getNextPacket = (): string => {
    var newPkt = '1';
    var pkt = localStorage.getItem('X-PACKETID');
    if (pkt) {
      newPkt = `${parseInt(pkt) + 1}`;
    }
    localStorage.setItem('X-PACKETID', newPkt);
    return newPkt;
  };

  const xAppId = `${process.env.REACT_APP_XAPPID}`;

  const data = {
    client_id: client_id,
    client_secret: client_secret,
    refresh_token: refresh_token,
  };

  const config: AxiosRequestConfig = {
    headers: {
      'X-PACKETID': getNextPacket(),
      Authorization: `Bearer ${localStorage.getItem('rppToken')}`,
      'X-APPID': xAppId,
      'Content-Type': 'application/json',
    },
  };

  axios.post(url, qs.stringify(data), config);
};
