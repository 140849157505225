import React, { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

import { CloseIcon } from "assets/icons/close";
import { InputField } from "components/simpleComponents/Input/InputField/InputField";
import CheckboxIconChecked from "assets/icons/CheckboxIconChecked";
import CheckboxIconNotChecked from "assets/icons/CheckboxIconNotChecked";

import { classes } from "pages/Derby/components/styles";
import { MAIN_URL, ROUTES } from "utils/constants";
import { useServerAPI } from "apis";
import { getHubSpotCookie, sendHubSpotData } from "apis/hubSpot";

import styles from "./styles.module.scss";
import { CustomButton } from "components/UIComponents/Button";
import PurchaseCounterV2 from "pages/Thoroughbred/components/Accreditation/components/AccreditationForm/components/PurchaseCounter/PurchaseCounterV2";
import { modalClasses } from "./styles";
import LuxusLogoNoText from "assets/icons/LuxusLogoNoText";
import { SHARES_QUANTITY } from "./constants";

interface Props {
  changeVisible: () => void;
  changeStep: (step: number) => void;
}

export const ModalForm: FC<Props> = ({ changeVisible, changeStep }) => {
  const [searchParams] = useSearchParams();

  const [firstName, setFirstName] = useState(
    searchParams.get("firstName") || ""
  );
  const [lastName, setLastName] = useState(searchParams.get("lastName") || "");
  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [licenseAgree, setLicenseAgree] = useState(false);
  const [securitiesCounter, setSecuritiesCounter] = useState(4);

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    licenseAgree: "",
    duplicate: "",
  });

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const api = useServerAPI();

  const handleSubmit = async () => {
    if (
      securitiesCounter < SHARES_QUANTITY.MIN ||
      securitiesCounter > SHARES_QUANTITY.MAX
    ) {
      return;
    }

    const newError = {} as typeof error;
    if (!firstName) newError.firstName = "First name is required";
    if (!lastName) newError.lastName = "Last name is required";
    if (!email) newError.email = "Email is required";
    if (!validateEmail(email)) newError.email = "Email is not valid";
    if (!licenseAgree) newError.licenseAgree = "License agree is required";

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    try {
      const ip = await api.getIPFromAmazon();

      const formId =
        process.env.REACT_APP_HUBSPOT_FORM_THOROUGHBRED_ANNOUNCE || "";
      const trackingCookie = getHubSpotCookie() || "";

      const { inlineMessage } = await sendHubSpotData(
        {
          firstName,
          lastName,
          email,
          sharesQty: securitiesCounter,
          reserveAmount: new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(securitiesCounter * 250),
          ipAddress: ip,
          trackingCookie,
          pageUri: `${MAIN_URL}${ROUTES.THOROUGHBRED_ANNOUNCE}`,
          pageName: "Thoroughbred announce Page",
        },
        formId
      );

      if (inlineMessage === "Thanks for submitting the form.") {
        changeStep(2);
      }
    } catch (error: any) {
      console.log(error.response);
      if (
        error?.response?.data?.message[0] ===
        "phoneNumber must be a valid phone number"
      ) {
        setError((prev) => ({
          ...prev,
          duplicate: "Invalid phone number!",
        }));
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (name === "firstName") {
      if (value) setError((prev) => ({ ...prev, firstName: "" }));
      setFirstName(value);
    } else if (name === "lastName") {
      if (value) setError((prev) => ({ ...prev, lastName: "" }));
      setLastName(value);
    } else if (name === "email") {
      if (value && validateEmail(value))
        setError((prev) => ({ ...prev, duplicate: "", email: "" }));
      setEmail(value);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Box sx={{ cursor: "pointer" }} onClick={changeVisible}>
          <CloseIcon color={"#000000"} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LuxusLogoNoText />
      </Box>

      <Typography sx={modalClasses.subTitle}>
        Experience The Investment
      </Typography>
      <Typography sx={modalClasses.text2}>
        Join the list to invest in a champion thoroughbred from Lane's End and
        Woodford Racing!
      </Typography>
      <Box sx={classes.form}>
        <InputField.White
          placeholder="FIRST NAME"
          name="firstName"
          onChange={handleChange}
          value={firstName}
          error={!!error.firstName}
          errorText={error.firstName}
        />
        <InputField.White
          placeholder="LAST NAME"
          name="lastName"
          onChange={handleChange}
          value={lastName}
          error={!!error.lastName}
          errorText={error.lastName}
        />
        <InputField.White
          placeholder="EMAIL ADDRESS"
          name="email"
          onChange={handleChange}
          value={email}
          error={!!error.email}
          errorText={error.email}
        />
        {!!error.duplicate && (
          <p className={styles.duplicateError}>{error.duplicate}</p>
        )}
        <Typography sx={modalClasses.text}>
          The minimum investment amount is $1,000 or 4 shares.
        </Typography>
        <PurchaseCounterV2
          minimumNumberOfShares={4}
          securitiesNumber={securitiesCounter}
          handleCountChange={setSecuritiesCounter}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <Checkbox
          onClick={() => {
            setLicenseAgree((prev) => !prev);
            setError((prev) => ({ ...prev, licenseAgree: "" }));
          }}
          value={licenseAgree}
          sx={{
            maxHeight: "42px",
            maxWidth: "42px",
            marginRight: "10px",
            marginLeft: "-9px",
            "& rect": { stroke: !!error.licenseAgree ? "red" : "black" },
          }}
          icon={<CheckboxIconNotChecked />}
          checkedIcon={<CheckboxIconChecked />}
        />
        <Typography sx={classes.text} style={{ textAlign: "start" }}>
          I have read and agree to the{" "}
          <Link target="_blank" style={{ color: "inherit" }} to={"/privacy"}>
            Privacy Policy
          </Link>
          ,{"  "}
          <Link
            target="_blank"
            style={{ color: "inherit" }}
            to={"/termsOfService"}
          >
            Terms of Service
          </Link>
        </Typography>
      </Box>
      <CustomButton btnStyle="secondary" onClick={handleSubmit}>
        SUBMIT
      </CustomButton>
    </>
  );
};
