import themeData from '../../../utils/theme';
import { CSSProperties } from 'react';
export const styles: Record<string, CSSProperties> = {
  toggleContainer: {
    border: '1px solid black',
    display: 'flex',
    borderRadius: '50px',
    overflow: 'hidden',
    backgroundColor: themeData.WhiteColor,
    position: 'relative',
  },
  toggleOption: {
    flex: 1,
    padding: '15px 20px',
    textAlign: 'center',
    backgroundColor: 'transparent',
    color: '#000',
    border: 'none',
    cursor: 'pointer',
    zIndex: 1,
  },
  selected: {
    backgroundColor: themeData.BlackColor,
    color: themeData.WhiteColor,
  },
  slider: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '50%',
    backgroundColor: themeData.BlackColor,
    borderRadius: '50px',
    transition: 'left 0.3s',
  },
  rightRadius: {
    borderRadius: '0px 50px 50px 0px',
  },
  leftRadius: {
    borderRadius: '50px 0px 0px 50px',
  },
};
