import { CustomButton } from "components/UIComponents/Button";
import EditFormInputs from "../EditFormInputs/EditFormInputs";
import InputSubmit from "components/simpleComponents/Input/InputSubmit/InputSubmit";
import ChangePasswordInputs from "../ChangePasswordInputs/ChangePasswordInputs";

import { UserProfile } from "types/Account";
import { BUTTON_OPTIONS, BUTTON_TEXT } from "pages/NewProfile/constants";

import styles from "./EditUserData.module.scss";
import useEditUserDataFacade from "./useEditUserDataFacade";

export type EditUserDataProps = {
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  profileData: UserProfile;
  fetchProfileData: () => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditUserData = ({
  setIsEdit,
  profileData,
  fetchProfileData,
  setIsLoading,
}: EditUserDataProps) => {
  const {
    handleSubmit,
    userName,
    emailAddress,
    profileDataState,
    getProfilePropertySetter,
    handleUserInputsChange,
    errors,
    handlePasswordChange,
    passwordErrors,
  } = useEditUserDataFacade({
    setIsEdit,
    profileData,
    fetchProfileData,
    setIsLoading,
  });

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className={styles.wrapperHeader}>
          <div>
            <p className={styles.name}>{userName}</p>
            <p className={styles.email}>{emailAddress}</p>
          </div>
          <div className={styles.buttonWrapper}>
            <CustomButton btnStyle={BUTTON_OPTIONS.SAVE} type="submit">
              <span className={styles.buttonText}>{BUTTON_TEXT.SAVE}</span>
            </CustomButton>
          </div>
        </div>
        <div className={styles.editContainer}>
          <EditFormInputs
            profileData={profileDataState}
            handleChange={handleUserInputsChange}
            profileSetter={getProfilePropertySetter}
            errors={errors}
          />
          <ChangePasswordInputs
            handlePasswordChange={handlePasswordChange}
            passwordErrors={passwordErrors}
          />
        </div>
      </form>
    </section>
  );
};

export default EditUserData;
