import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    marginTop: "17px",
  },
  label: {
    marginBottom: "15px",
  },
};

export type ClassesNames = keyof typeof commonStyles;
