import { useState, useEffect, ChangeEvent, ChangeEventHandler } from 'react';
import { useMediaQuery } from '@mui/material';
import { PaymentMethodsProps } from './PaymentMethods';
import formatNumber from '../../../../../utils/FormatNumber';

type UsePaymentMethodFacade = {
  isDesktop: boolean;
  sum: string;
  getItemCountHandler: (type: 'inc' | 'dec') => () => void;
  onChangeHandler: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export default function usePaymentMethodFacade({
  itemsCount,
  unitPrice,
  changeItemsCount,
}: PaymentMethodsProps): UsePaymentMethodFacade {
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const calculatedSum = formatNumber((itemsCount * unitPrice).toString());
  const [sum, setSum] = useState(calculatedSum);

  const getItemCountHandler = (type: 'inc' | 'dec') => (): void => {
    if (type === 'inc') return changeItemsCount(itemsCount + 1);
    if (itemsCount === 0) return;
    return changeItemsCount(itemsCount - 1);
  };

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    return changeItemsCount(parseInt(event.target.value));
  };

  useEffect(() => {
    setSum(calculatedSum);
  }, [itemsCount]);

  return { isDesktop, sum, getItemCountHandler, onChangeHandler };
}
