import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        padding: "39px 0 60px 0",
      },
      userNameText: {
        height: "35px",
        fontSize: "23px",
        paddingBottom: "0",
      },
      welcomeText: {
        height: "35px",
        fontSize: "23px",
        paddingBottom: "15px",
      },
      stepText: {
        fontSize: "14px",
        width: "315px",
        marginBottom: "100px",
      },
      stepper: {
        width: "351px",
        marginBottom: "80px",
      },
      stepperConnector: {
        borderColor: "#F4F4F4",
        width: "78px",
        borderTopWidth: "5px",
        margin: "6px 0 0 0",
      },
      blackBtn: {
        fontSize: "15px",
        padding: "8px 16px",
      },
        textBtn: {
          fontSize: "16px",
          padding: "6px 16px",
        },
        step: {
            "& svg": {
                width: "40px",
                height: "40px",
                zIndex: "1",
                borderRadius: "29px",
            },
            "& text": {
                fill: "black !important",
            },
        },
        currStep: {
            "& svg": {
                color: "#DFDFE3 !important",
            },
        },
        notCurrStep: {
            "& svg": {
                color: "black !important",
            },
        },
        buttonsBox: {
            flexDirection: "column-reverse",
            marginBottom: "10px",
        },
    })
  )
);
