import React from "react";
import { useNavigate } from "react-router-dom";
import {BasicOfferCard, ChildsCardProps} from "../BasicOfferCard/BasicOfferCard";
import {useStyle} from "../SoldPrivatelyOfferCard/styles";
import Diamond from './../../../../../../AssetsUnlogged/assets/diamond.png'

type ActiveOfferingCardProps = ChildsCardProps;

const ActiveOfferingCard: React.FC<ActiveOfferingCardProps> = function (
  props
): JSX.Element {
  const navigate = useNavigate();
  const { leftCornerTitleBoxClass } = useStyle();
  const onByBtnClick = (): void => {
    navigate(`/assetDetails/${props.offer.offeringId}`);
  };
  return (
    <BasicOfferCard
      {...props}
      leftCornerTitleBoxClass={leftCornerTitleBoxClass}
      leftCornerTitle="SOLD OUT"
      buyButtonText="VIEW ASSET"
      onBuyBtnClick={onByBtnClick}
      lastInfoTitleType="GIA Report Grade"
      btnType={'inlineGrey'}
      img={Diamond}
    />
  );
};

export default ActiveOfferingCard;
