import { DisplayTypes } from "../types/enums/DisplayTypes";
import { useSelector } from "react-redux";

import { displayTypeSelector } from "../store/selectors/system";

export default function useGetDisplayType(type: DisplayTypes): boolean;
export default function useGetDisplayType(type: DisplayTypes[]): boolean;
export default function useGetDisplayType(type?: undefined): DisplayTypes;
export default function useGetDisplayType(type?: any): any {
  const display = useSelector(displayTypeSelector);
  if (type && typeof type !== "string")
    return (type as DisplayTypes[]).some((dispType) => dispType === display);
  if (type) return type === display;
  return display;
}
