import { Box, Typography } from "@mui/material";
import React, { FC } from "react";
import { Button } from "../../../../../components/simpleComponents/Button";
import { Offering } from "../../../../../types/Offerings";
import { useStyles } from "./styles/";

import useGetDisplayType from "../../../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../../../types/enums/DisplayTypes";
import style from "./style.module.scss";
import { CustomButton } from "../../../../../components/UIComponents/Button";
import { HEADER_OPTIONS } from "../../../../../components/Header/constants";
import Header from "../../../../../components/Header/Header";
import { Footer } from "../../../../../components/Footer/Footer";
import { MAIN_URL, ROUTES } from "utils/constants";

export const IOIThanks: FC<{
  offer: Offering;
  onClick: () => void;
  desktop: boolean;
}> = ({ offer, onClick, children }) => {
  const desktop = !useGetDisplayType(DisplayTypes.Mobile);

  return (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <div className={style.container}>
        <div className={style.content}>
          <img src={offer?.image?.url ?? ""} alt={""} />
          <h2>{offer?.name}</h2>
          <div>
            {children &&
              (children as string).split("--").map((item) => <p>{item}</p>)}
          </div>
          <CustomButton btnStyle={"primary"} onClick={onClick}>
            BROWSE MORE ASSETS
          </CustomButton>
        </div>
      </div>
      <Footer
        pageName="IOIDetails page"
        pageUri={`${MAIN_URL}${ROUTES.IOI_DETAILS}`}
      />
    </>
  );
};
