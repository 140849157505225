import axios from "axios";

export interface ProfileData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  citizenshipStatus?: string;
  source?: "common" | "100WIF";
}

const baseurl = process.env.REACT_APP_BACKEND_BASE_URL || "";

export const createKlaviyoProfile = async (data: ProfileData) => {
  if (data.phoneNumber) {
    data.phoneNumber = `+${data.phoneNumber}`;
  }
  try {
    const res = await axios.post(
      `${baseurl}/v1/klaviyo/createSubscriber`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            process.env.REACT_APP_KLAVIYO_API_KEY || ""
          }`,
        },
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const addKlaviyoProfileToList = async (
  profileId: string,
  listId: string
) => {
  if (!profileId || !listId) {
    return;
  }
  try {
    const res = await axios.post(
      `${baseurl}/v1/klaviyo/list/${listId}/subscriber/${profileId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${
            process.env.REACT_APP_KLAVIYO_API_KEY || ""
          }`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
