import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServerAPI } from '../../apis';
import { UserProfile } from '../../types/Account';
import ProfileForm from './components/ProfileForm';
import ReactGA from 'react-ga';
import { Preloader } from '../../components/simpleComponents/Preloader';

export const ProfileEdit = () => {
  let api = useServerAPI();
  let navigate = useNavigate();
  const [userProfileData, setUserProfileData] = useState<UserProfile>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + '/ProfileEdit');
    window.scrollTo(0, 0);
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUserProfile = () => {
    setIsLoading(true);
    api
      .getUserProfile()
      .then((userProfileRes) => {
        if (userProfileRes.data.response.error) {
          console.error(
            `ProfileEdit: Got error trying to retrive user profile `,
            userProfileRes.data.response.error
          );
        } else {
          setUserProfileData(userProfileRes.data.response.profile);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(
          `ProfileEdit: Got error trying to retrive user profile`,
          error
        );
        setIsLoading(false);
      });
  };

  const handleOnSubmitForm = async (inputs: UserProfile) => {
    if (
      inputs.firstName !== '' &&
      inputs.lastName !== '' &&
      inputs.domicile !== '' &&
      inputs.dob !== '' &&
      inputs.primCountry !== '' &&
      inputs.primAddress1 !== '' &&
      inputs.primCity !== '' &&
      inputs.primState !== '' &&
      inputs.primZip !== ''
    ) {
      try {
        if (inputs) {
          setIsLoading(true);
          const response = await api.updateUserProfile(inputs);
          setIsLoading(false);
          if (!response.data.response?.error) {
            if (response.data.response?.profile?.name)
              localStorage.setItem(
                'userName',
                response.data.response.profile.name
              );
            if (response.data.response?.profile?.firstName)
              localStorage.setItem(
                'userFirstName',
                response.data.response.profile.firstName
              );
            if (response.data.response?.profile?.lastName)
              localStorage.setItem(
                'userLastName',
                response.data.response.profile.lastName
              );
            navigate('/myAccount');
          }
        }
      } catch (error: any) {
        console.error('err', error.response?.data?.error);
        setIsLoading(false);
      }
    }
  };

  if (isLoading)
    return (
      <Box textAlign={'center'} marginTop={10} marginBottom={10}>
        <Preloader />
      </Box>
    );

  return (
    <Box>
      <Container
        maxWidth="md"
        className="data-window"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 10,
          marginBottom: 10,
          paddingTop: '25px',
          paddingBottom: '25px',
        }}
      >
        <ProfileForm
          onCancel={() => {
            navigate(-1);
          }}
          onSave={handleOnSubmitForm}
          data={userProfileData}
          title="Account Details"
          submitTitle="Save"
        />
      </Container>
    </Box>
  );
};

export default ProfileEdit;
