const TreeLineBig = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="214"
      height="95"
      viewBox="0 0 214 95"
      fill="none"
    >
      <path
        d="M203.643 0.801645C203.057 0.215858 202.107 0.215858 201.521 0.801645L191.975 10.3476C191.39 10.9334 191.39 11.8831 191.975 12.4689C192.561 13.0547 193.511 13.0547 194.097 12.4689L202.582 3.98362L211.067 12.4689C211.653 13.0547 212.603 13.0547 213.189 12.4689C213.774 11.8831 213.774 10.9334 213.189 10.3476L203.643 0.801645ZM0.944698 92.8338C0.944698 93.6622 1.61628 94.3338 2.4447 94.3338C3.27313 94.3338 3.9447 93.6622 3.9447 92.8338L0.944698 92.8338ZM201.082 1.8623L201.082 29.1118L204.082 29.1118L204.082 1.8623L201.082 1.8623ZM186.987 43.207L18.0398 43.2069L18.0398 46.2069L186.987 46.207L186.987 43.207ZM0.9447 60.3021L0.944698 92.8338L3.9447 92.8338L3.9447 60.3021L0.9447 60.3021ZM18.0398 43.2069C8.59846 43.2069 0.9447 50.8607 0.9447 60.3021L3.9447 60.3021C3.9447 52.5175 10.2553 46.2069 18.0398 46.2069L18.0398 43.2069ZM201.082 29.1118C201.082 36.8963 194.771 43.207 186.987 43.207L186.987 46.207C196.428 46.207 204.082 38.5532 204.082 29.1118L201.082 29.1118Z"
        fill="#7B7B8F"
      />
    </svg>
  );
};

export default TreeLineBig;
