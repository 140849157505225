import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../utils/theme";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () => createStyles(mixStyleRules(commonStyles, {
      progressBarRoot: {
          backgroundColor: "#ececec !important",
          height: "16px !important",
      },
      questionTitle: {
          textAlign: "center",
          color: "#000000",
          fontSize: "24px",
      },
  }))
);
