import { FC, ReactNode, useMemo } from "react";
import { Button as MuiButton, ButtonProps } from "@mui/material";
import { useStyles } from "./style";
import { getVariant } from "./utils";
import classnames from "classnames";

export type TButtonType =
  | "primary"
  | "secondary"
  | "link"
  | "gray"
  | "black"
  | "grayBlack";

interface IButtonProps
  extends Pick<
    ButtonProps,
    | "autoFocus"
    | "children"
    | "className"
    | "onClick"
    | "href"
    | "onMouseEnter"
    | "onMouseLeave"
    | "sx"
  > {
  view?: TButtonType;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  testId?: string;
  disabled?: boolean;
  disableRipple?: boolean;
  type?: "submit" | "reset" | "button";
}

export const Button: FC<IButtonProps> = ({
  view = "primary",
  type = "button",
  startIcon,
  endIcon,
  className,
  children,
  href,
  testId = "button",
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled = false,
  disableRipple = false,
  sx,
}) => {
  const {
    btn,
    textButton,
    linkButton,
    secondaryButton,
    grayButton,
    blackButton,
    grayBlackButton,
  } = useStyles();

  const presetStyle = {
    [textButton]: view === "primary",
    [grayButton]: view === "gray",
    [linkButton]: view === "link",
    [secondaryButton]: view === "secondary",
    [blackButton]: view === "black",
    [grayBlackButton]: view === "grayBlack",
  };

  return (
    <MuiButton
      className={classnames(btn, presetStyle, className)}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={testId}
      disabled={disabled}
      disableRipple={disableRipple}
      type={type}
      variant={getVariant(view)}
      href={href}
      sx={sx}
    >
      {children}
    </MuiButton>
  );
};
