import { Explanation } from "./types";

export const EXPLANATIONS: readonly Explanation[] = Object.freeze([
  {
    id: 0,
    question: "01. IDENTIFY OPPORTUNITY",
    answer:
      "A company operated by LUXUS will own the assets, and you will own part of that company. By securitizing the asset in a cost effective legal structure, LUXUS enables beneficial ownership to be shared amongst all of our investors.",
  },

  {
    id: 1,
    question: "02. ASSET IS SELECTED",
    answer:
      "The assets are stored in highly secure, well-insured vaults provided by our brand partners. The brands are also picking up these costs, so they are not passed on to our investor community. LUXUS is working with brand partners and with leading luxury industry experts to organize exclusive showings of the assets for our investors, while minimizing risks and costs for all parties ",
  },
  {
    id: 2,
    question: "03. ASSET IS SECURITIZED AND SYNDICATED",
    answer:
      "We determine the macro theme within luxury that we think will generate the most attractive returns and consult with our brand partners who recommend assets based on their intimate knowledge of specific luxury submarkets (i.e. precious gems, high jewelry, rare watches etc.). We want to make sure we find the best possible assets and maintain our focus on getting the first few selections right. Our independent advisory board evaluates these selections based on the all the criteria we outline in the LUXUS Process.",
  },
  {
    id: 3,
    question: "04. ASSET IS TRADED OR LATER SOLD",
    answer:
      "LUXUS will offer assets opportunistically based on market demand, the speed with which we can navigate regulatory processes, and the state of market conditions for our assets. It is our mission to bring the best quality assets to market, as quickly as possible.",
  },
]);

export const TEXT =
  "The 103-carat ‘Light of Africa,” diamond fetched just over $20 million, topping its $18 million high estimate, at Christie’s New York Magnificent Jewels sale in 2022.";

export const NOTE =
  "* The Light of Africa is being used as an example of the LUXUS process only and LUXUS is not selling shares of this diamond.";
