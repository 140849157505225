import React, { useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../../../store";

interface DropZoneProps {
  setFileData: (content: string, fileName: string) => void;
}

const DropZone: React.FC<DropZoneProps> = function ({
  setFileData,
}): JSX.Element {
  const [file, setFile] = useState<any>();
  const [isUploaded, setIsUploaded] = useState(false);

  const proceedFile = (file: File | null): void => {
    if (!file) return;
    if (file.size > 10 ** 7) return;
    setFile(file);
    setIsUploaded(true);

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setFileData(fileReader.result as string, file.name.split(".")[0]);
    };
    fileReader.readAsDataURL(file);
  };

  const fileUploadHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    proceedFile(file);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      proceedFile(acceptedFiles[0]);
    },
    [isUploaded]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "image/*": [".png", ".jpeg", ".jpeg", ".heic", ".heif"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/rtf": [".rtf"],
    },
    noDragEventsBubbling: true,
  });

  const clearFile = (e: React.MouseEvent) => {
    e.stopPropagation();
    setFile(null);
    setIsUploaded(false);
  };

  //const onLabelClick = (e: React.MouseEvent) => e.stopPropagation();

  const classes = useStyles();
  return (
    <Box className={classes.dropZoneBox} {...getRootProps()}>
      {!isUploaded ? (
        <Box className={classes.dropZoneBorder}>
          <CloudUploadIcon className={classes.icon} />
          <Typography className={classes.dropZoneText}>
            Drop your image here
          </Typography>
          <Typography className={classes.dropZoneText}>or</Typography>
          {/* <input
            id="file-upload"
            type="file"
            onChange={fileUploadHandler}
            className={classes.fileUploadContainerInput}
            accept="image/*,.doc,.docx,.rtf,.pdf"
          /> */}
          <input
            className={classes.fileUploadContainerInput}
            accept="image/*,.doc,.docx,.rtf,.pdf"
            type="file"
            {...getInputProps()}
          />
          <label
            htmlFor="file-upload"
            className={classes.dropZoneBtn}
            //onClick={onLabelClick}
          >
            Browse
          </label>
        </Box>
      ) : (
        <Box className={classes.dropZoneBorder}>
          <TaskOutlinedIcon className={classes.icon} />
          <Typography className={classes.fileName}>{file.name}</Typography>
          <CloseRoundedIcon className={classes.closeIcon} onClick={clearFile} />
        </Box>
      )}
    </Box>
  );
};

export default DropZone;
