import { AssetDetail } from 'pages/Thoroughbred/components/ThoroughbredPage/components/Offering/types';

import styles from './AssetThoroughbred.module.scss';
import { ButtonStyle, CustomButton } from 'components/UIComponents/Button';

type AssetThoroughbredProps = {
  image: string;
  labelText?: string;
  title: string;
  assetDetails: AssetDetail[];
  onClickFirstButton: () => void;
  mainButtonStyle: ButtonStyle;
  secondaryButtonStyle: ButtonStyle;
  onClickSecondButton: () => void;
};

const AssetThoroughbred = ({
  image,
  labelText = '',
  title,
  assetDetails,
  onClickFirstButton,
  mainButtonStyle,
  onClickSecondButton,
  secondaryButtonStyle,
}: AssetThoroughbredProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          {labelText && <span className={styles.label}>{labelText}</span>}
          <img src={image} alt="Asset" className={styles.image} />
        </div>
        <div className={styles.textsContainer}>
          <h2 className={styles.title}>{title}</h2>
          <ul className={styles.list}>
            {assetDetails.map(({ id, title, text }) => (
              <li key={id} className={styles.listItem}>
                <p className={styles.listTextTitle}>{title}</p>
                <p className={styles.listText}>{text}</p>
              </li>
            ))}
          </ul>
          <div className={styles.buttonWrapper}>
            <CustomButton
              onClick={onClickFirstButton}
              size="long"
              btnStyle={mainButtonStyle}
            >
              FACT SHEET
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetThoroughbred;
