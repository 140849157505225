export const modalClasses = {
  subTitle: {
    color: '#000',
    textAlign: 'center',
    leadingTrim: 'both',
    fontFamily: 'Telugu MN',
    fontSize: '22px',
    fontWeight: '400',
    letterSpacing: '0.37px',
    textTransform: 'uppercase',
  },

  text: {
    color: '#000',
    leadingTrim: 'both',
    fontFamily: 'Futura PT',
    fontSize: '15px',
    fontWeight: '400',
  },

  text2: {
    color: '#000',
    leadingTrim: 'both',
    fontFamily: 'Futura PT Light',
    fontSize: '16px',
    fontWeight: '400',
  },
};
