import { useState, useEffect } from 'react';

import type { WireDetailsType } from '../../../../../../../types/SystemConfig';

import { useServerAPI } from '../../../../../../../apis';

interface WireInstructionFacadeOutput {
  isLoading: boolean;
  details: WireDetailsType | undefined;
  investorName: string;
}

export default function useWireInstructionsFacade(): WireInstructionFacadeOutput {
  const api = useServerAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<WireDetailsType>();

  const investorName = localStorage.getItem('userName') ?? 'Investor Name';

  useEffect(() => {
    setIsLoading(true);
    api
      .getWireDetails()
      .then((response) => setDetails(response))
      .finally(() => setIsLoading(false));
  }, []);

  return {
    isLoading,
    details,
    investorName,
  };
}
