import * as yup from "yup";

const stringRequiredSchema = yup.string().required("This field is required!");

export const achFormSchema = yup.object().shape({
  accountHolderName: stringRequiredSchema,
  bankName: stringRequiredSchema,
  routingNumber: stringRequiredSchema,
  accountNumber: stringRequiredSchema,
  accountType: stringRequiredSchema,
});
