import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        progressBarContainer: {
          marginBottom: "16px",
        },
        nameTitleBox: {
          marginBottom: "0",
        },
        nameTitle: {
          fontSize: "24px",
        },
        infoItem: {
          marginBottom: "2px",
        },
        infoItemTitle: {
          fontSize: "16px",
        },
        hrElem: {
          margin: "9px 0 5px",
        },
        progressBarOne: {
          height: "8px !important",
        },
        amountInfoBox: {
          marginBottom: "15px",
        },
        amountInfoItem: {
          marginBottom: "8px",
        },
        amountInfoItemTitle: {
          fontSize: "16px",
        },
        amountInfoItemValue: {
          fontSize: "16px",
        },
        buySharesBtn: {
          height: "38px",
          width: "133px",
          fontSize: "14px",
        },
        feeIcon: {
          height: "38px",
          width: "38px",
        },
        popoverPaper: {
          maxWidth: "260px",
          padding: "10px 5px",
        },
        popoverTitle: {
          fontSize: "13px",
          fontWeight: "500",
        },
        popoverText: {
          fontSize: "13px",
          fontWeight: "300",
        },
        popoverTriangle: {
          position: "relative",
          "&::before": {
            backgroundColor: "#DFDFE3 !important",
            content: '""',
            display: "block",
            position: "absolute",
            width: 15,
            height: 15,
            left: -15,
            transform: "rotate(45deg)",
            top: -26,
            zIndex: "1",
          },
        },
        soldOutBtn: {
          fontSize: "14px",
          padding: "1px 10px",
          height: "38px",
        },
        browseAssetsBtn: {
          fontSize: "14px",
          height: "38px",
          padding: "1px 10px",
        },
      })
    )
);
