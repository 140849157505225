import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  TextField,
  Stack,
  Typography,
  InputLabel,
} from "@mui/material";
import DropZone from "../UploadIdForm/DropZone";

import { useStyles } from "./styles";
import { NewUserProfileForm } from "../../../ConfigureProfile";
import useGetDisplayType from "../../../../../../hooks/useGetDisplayType";
import {DisplayTypes} from "../../../../../../types/enums/DisplayTypes";

interface IdFormProps {
  initIdType: string | undefined;
  initIdNumber: string | undefined;
  setProfileIdType(value: string): void;
  setProfileIdNumber(value: string): void;
  uploadFile: (
    data: Record<"documentTitle" | "file_name" | "userfile0", string>
  ) => Promise<void>;
  stepNumber: number;
}

const IdForm: React.FC<IdFormProps> = function ({
  initIdType,
  initIdNumber,
  setProfileIdType,
  setProfileIdNumber,
  uploadFile,
  stepNumber,
}): JSX.Element {
  const displayType = useGetDisplayType();

  const [idType, setIdType] = useState<string>(initIdType || "");
  const [idNumber, setIdNumber] = useState<string>(initIdNumber || "");
  const filesContent = useRef({
    front: {
      content: "",
      fileName: "",
    },
    back: {
      content: "",
      fileName: "",
    },
  });

  const passportFile = useRef({ content: "", fileName: "" });

  const onIdTypeChange = (e: SelectChangeEvent) => {
    setIdType(e.target.value);
    setProfileIdType(e.target.value);
  };

  const onIdNumberChange = (e: React.ChangeEvent) => {
    setIdNumber((e.target as HTMLInputElement).value);
    setProfileIdNumber((e.target as HTMLInputElement).value);
  };

  const getFileContentSetter =
    (type: "front" | "back") => (content: string, fileName: string) => {
      filesContent.current[type].content = content;
      filesContent.current[type].fileName = fileName;
    };

  const options = [
    {
      label: "Drivers License",
      value: "Drivers License",
    },
    {
      label: "Passport",
      value: "Passport",
    },
    {
      label: "ID Card",
      value: "ID Card",
    },
  ];

  const classes = useStyles();

  useEffect(() => {
    return () => {
      if (stepNumber === 5) {
        const content = filesContent.current;
        if (
            !content.back.content ||
            !content.front.content ||
            !content.front.fileName ||
            !content.back.fileName
        )
          return;
        Object.keys(content).forEach((key) => {
          const titlePart = key
              .split("")
              .map((char, index) => (index === 0 ? char.toUpperCase() : char))
              .join("");
          const documentTitle = `${idType} ${titlePart}`;
          uploadFile({
            documentTitle,
            file_name: content[key as keyof typeof content].fileName,
            userfile0: content[key as keyof typeof content].content,
          });
        });
      }
    };
  }, [stepNumber]);

  useEffect(() => {
    return () => {
      if (!passportFile.current.content) return;
      uploadFile({
        documentTitle: "Passport",
        file_name: passportFile.current.fileName,
        userfile0: passportFile.current.content,
      });
    };
  }, []);

  return (
    <Box className={classes.container}>
      <Stack direction={{"xs": "column", "md": "row", "sm": "row"}} spacing={2}>
        <Select
          sx={{ height: "58px" }}
          displayEmpty
          className={classes.formFieldSmall}
          renderValue={
            idType !== ""
              ? undefined
              : () => <div className={classes.placeholder}>ID Type</div>
          }
          id="idType"
          value={idType}
          onChange={onIdTypeChange}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <TextField
          id="idNumber"
          className={classes.formFieldSmall}
          placeholder="ID Number"
          value={idNumber}
          onChange={onIdNumberChange}
        />
      </Stack>
      { idType !== "Passport" ?
          <Stack
              direction="row"
              spacing={2}
              className={classes.uploadMainContainer}
          >
            <Box className={classes.uploadContainer}>
              <Typography className={classes.title}>Front</Typography>
              <DropZone setFileData={getFileContentSetter("front")}/>
            </Box>
            <Box className={classes.uploadContainer}>
              <Typography className={classes.title}>Back</Typography>
              <DropZone setFileData={getFileContentSetter("back")} />
            </Box>
          </Stack> : <Box className={classes.uploadMainContainer}>
            <DropZone
                setFileData={(data: string) => {
                  passportFile.current.content = data;
                }}
            />
          </Box>
      }
    </Box>
  );
};

export default IdForm;
