import { Box, Typography } from "@mui/material";
import { classes } from "pages/Derby/components/styles";
import { Button } from "pages/Profile/ConfigureProfile/Button/Button";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TiaraForm } from "./TiaraForm";

interface Props {
  visible: boolean;
  changeVisible: () => void;
}

export const TiaraModal: FC<Props> = ({ visible, changeVisible }) => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const closeModal = () => {
    changeVisible();
  };

  return (
    <>
      <Box sx={classes.modalWrapper(visible)} onClick={closeModal}>
        <Box
          sx={classes.modal(visible)}
          style={{ paddingTop: "40px", gap: "22px" }}
          onClick={(e) => e.stopPropagation()}
        >
          {step === 1 && (
            <TiaraForm changeVisible={closeModal} changeStep={setStep} />
          )}
          {step === 2 && (
            <>
              <Typography sx={classes.title}>Success!</Typography>
              <Typography sx={{ fontFamily: "Futura PT Book" }}>
                Thank you for completing the registration form. You will receive
                subscription and funding instructions shortly.
              </Typography>
              <Typography sx={{ fontFamily: "Futura PT Book" }}>
                If you have not received an email within the next thirty
                minutes, please check your spam.
              </Typography>
              <Button.Black
                width="100%"
                onClick={() => navigate("/")}
                value={"Return to Homepage"}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
