import RoyalAscotHorses from "assets/images/royalAscotHorses.png";

import styles from "./SuccessMessage.module.scss";
import LuxusLogoNoText from "assets/icons/LuxusLogoNoText";

type SuccessMessageProps = {
  message: string;
};

const SuccessMessage = ({ message }: SuccessMessageProps) => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={RoyalAscotHorses} alt="Royal horses" />
      <div className={styles.textsWrapper}>
        <h2 className={styles.text}>{message}</h2>
        <p className={styles.text}>
          You are now being redirected to execute the payment...
        </p>
      </div>
      <LuxusLogoNoText />
    </div>
  );
};

export default SuccessMessage;
