import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  SvgIcon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { Edit, Delete } from "@mui/icons-material";

import { useServerAPI } from "../../../../../../apis/index";
import {
  BankAccountData,
  CreditCardData,
} from "../../../../../../types/Account";
import { themeData } from "../../../../../../utils/theme";
import { PaymentType } from "../../../../../../types/enums/PaymentType";
import PaymentPopUp from "../../../../../../components/NewPaymentPopUp/PaymentPopUp";
import { Preloader } from "../../../../../../components/simpleComponents/Preloader";

import { useStyles } from "./styles";

interface PaymentMethods {
  bankAccount: BankAccountData | undefined;
  creditCard: CreditCardData | undefined;
}

interface PaymentMethodsListProps {
  onDoneButtonClick(): void;
}

const PaymentMethodsList: React.FC<PaymentMethodsListProps> = function ({
  onDoneButtonClick,
}): JSX.Element {
  const api = useServerAPI();

  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods>();
  const [popUpType, setPopUpType] = useState<PaymentType | undefined>(
    undefined
  );

  const getPayment = () => {
    setIsLoading(true);
    api
      .getRegisteredPayments()
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then((data) => {
        if (data?.success) {
          const bankData = data.response.data.bankAccount;
          const cardData = data.response.data.creditCard;

          setPaymentMethods({
            bankAccount:
              bankData.statusCode === "149" ||
              bankData.error?.statusCode === "400"
                ? undefined
                : (bankData as BankAccountData),
            creditCard:
              cardData.statusCode === "715" ||
              (cardData as any).error?.statusDesc === "500" ||
              Object.keys(cardData).length === 0
                ? undefined
                : (cardData as CreditCardData),
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getPayment();
  }, []);

  const getOnDeleteIconClick =
    (type: "bank" | "card") => async (): Promise<void> => {
      setIsLoading(true);
      if (type === "bank") await api.removeBankAccount();
      else await api.removeCreditCard();
      getPayment();
    };

  const getOnEditIconClick =
    (type: PaymentType.BANK | PaymentType.CARD) => () => {
      setPopUpType(type);
    };

  const closePaymentPopUp = (): void => setPopUpType(undefined);

  const classes = useStyles();

  return isLoading ? (
    <Preloader />
  ) : (
    <Container className={classes.container}>
      <Box className={classes.content}>
        <Typography component="h4" variant="h6" className={classes.title}>
          Payment Methods
        </Typography>
        {/* Cards */}

        <Box className={classes.paymentBox}>
          {paymentMethods?.creditCard && (
            <>
              <Typography component="span" className={classes.paymentName}>
                {paymentMethods.creditCard.creditCardName}
              </Typography>
              <Typography component="span" className={classes.paymentType}>
                {paymentMethods.creditCard.cardType}
              </Typography>
              <Typography component="span" className={classes.paymentNumber}>
                {paymentMethods.creditCard.creditCardNumber
                  ?.split("")
                  .map((digit, index) => (index < 11 ? "*" : digit)) ?? ""}
              </Typography>
              <Box className={classes.paymentOperandBox}>
                <IconButton>
                  <SvgIcon
                    component={Edit}
                    onClick={getOnEditIconClick(PaymentType.CARD)}
                  />
                </IconButton>
                <IconButton onClick={getOnDeleteIconClick("card")}>
                  <SvgIcon component={Delete} />
                </IconButton>
              </Box>
            </>
          )}
          <Box className={classes.addPaymentBox}>
            <IconButton
              onClick={() => setPopUpType(PaymentType.CARD)}
              className={classes.addPaymentBtn}
            >
              <SvgIcon component={Add} />
            </IconButton>
            <Typography
              component="span"
              align="center"
              className={classes.addPaymentBtnTitle}
            >
              Add credit card
            </Typography>
          </Box>
        </Box>
        {/* Bank */}
        <Box className={classes.paymentBox}>
          {paymentMethods?.bankAccount && (
            <>
              <Typography component="span" className={classes.paymentName}>
                {paymentMethods.bankAccount.AccountName}
              </Typography>
              <Typography component="span" className={classes.paymentType}>
                {paymentMethods.bankAccount.accountType}
              </Typography>
              <Typography component="span" className={classes.paymentNumber}>
                {paymentMethods.bankAccount.AccountNumber?.split("").map(
                  (digit, index) => (index < 11 ? "*" : digit)
                ) ?? ""}
              </Typography>
              <Box className={classes.paymentOperandBox}>
                <IconButton>
                  <SvgIcon
                    component={Edit}
                    onClick={getOnEditIconClick(PaymentType.BANK)}
                  />
                </IconButton>
                <IconButton>
                  <SvgIcon
                    component={Delete}
                    onClick={getOnDeleteIconClick("bank")}
                  />
                </IconButton>
              </Box>
            </>
          )}
          <Box className={classes.addPaymentBox}>
            <IconButton
              onClick={() => setPopUpType(PaymentType.BANK)}
              className={classes.addPaymentBtn}
            >
              <SvgIcon component={Add} />
            </IconButton>
            <Typography component="span" className={classes.addPaymentBtnTitle}>
              Add bank account
            </Typography>
          </Box>
        </Box>
      </Box>
      {popUpType && (
        <PaymentPopUp
          selectedType={popUpType}
          showModal
          handleClose={() => {
            closePaymentPopUp();
          }}
          onCancel={() => {
            closePaymentPopUp();
          }}
          onSave={() => {
            getPayment();
            closePaymentPopUp();
          }}
          bankAccountData={paymentMethods?.bankAccount}
          creditCardData={paymentMethods?.creditCard}
        />
      )}
      <Button onClick={onDoneButtonClick} className={classes.doneBtn}>
        Done
      </Button>
    </Container>
  );
};

export default PaymentMethodsList;
