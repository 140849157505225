import { useState } from "react";

import BaseLayout from "layout/BaseLayout/baseLayout";
import JoinForm from "pages/NewAssests/Assets/components/JoinForm/JoinForm";
import AscotRace from "./components/AscotRace/AscotRace";
import AssetDetails from "./components/AssetDetails/AssetDetails";
import { ModalWindow } from "./components/ModalWindow/ModalWindow";
import Offering from "./components/Offering/Offering";
import Partners from "./components/Partners/Partners";
import Hero from "./components/Hero/Hero";
import Races from "./components/Races/Races";

import { MAIN_URL, ROUTES } from "utils/constants";
import styles from "./styles.module.scss";

const Thoroughbred = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  function changeModalVisible() {
    setIsModalVisible((prev) => !prev);
  }

  return (
    <BaseLayout
      removeForm
      pageName="Thoroughbred page"
      pageUri={`${MAIN_URL}${ROUTES.THOROUGHBRED}`}
    >
      <div className={styles.pageWrapper}>
        <Hero changeModalVisible={changeModalVisible} />
        <Partners />
        <AscotRace />
        <Offering />
        <AssetDetails />
        <ModalWindow
          changeVisible={changeModalVisible}
          isModalVisible={isModalVisible}
        />
        <div className={styles.formContainer}>
          <h2 className={styles.title}>JOIN OUR NEWSLETTER</h2>
          <h6 className={styles.text}>
            Subscribe to our newsletter for insights, updates and more
          </h6>
          <JoinForm
            pageName="Thoroughbred page"
            pageUri={`${MAIN_URL}${ROUTES.THOROUGHBRED}`}
            buttonText="SUBSCRIBE"
          />
        </div>
      </div>
    </BaseLayout>
  );
};

export default Thoroughbred;
