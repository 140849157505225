import React from "react";

import { Offering } from "../../../types/Offerings";

import AssetImages from "./components/AssetImages/AssetImages";
import AssetInfo from "./components/AssetInfo/AssetInfo";
import AssetOtherInfo from "./components/AssetOtherInfo/AssetOtherInfo";

import useAssetsDetailsFacade from "./useAssestDetailsFacade";
import { Preloader } from "../../../components/simpleComponents/Preloader";
import { SimilarOfferingNew } from "./components/SimilarOfferings/SimilarOfferingNew";
import style from "./style.module.scss";
import Header from "../../../components/Header/Header";
import { HEADER_OPTIONS } from "../../../components/Header/constants";
import { Footer } from "../../../components/Footer/Footer";
import { MAIN_URL, ROUTES } from "utils/constants";

const AssetDetails: React.FC = (): JSX.Element => {
  const [similarOffer, activeOffer, isLoading, onBuySharesClick, desktop] =
    useAssetsDetailsFacade();

  return isLoading ? (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <Preloader />
      <Footer
        pageName="Asset details page"
        pageUri={`${MAIN_URL}${ROUTES.ASSET_DETAILS}`}
      />
    </>
  ) : (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.infoBlock}>
            <h2 className={style.title}>{activeOffer?.name}</h2>{" "}
            {/*- mobile title*/}
            <AssetImages
              assets={(activeOffer?.assets as Offering["assets"]) ?? []}
            />
            <AssetInfo
              offer={activeOffer}
              onBuySharesClick={onBuySharesClick}
            />
          </div>
          <AssetOtherInfo offer={activeOffer} />
          {similarOffer && <SimilarOfferingNew offer={similarOffer} />}
        </div>
      </div>
      <Footer
        pageName="Asset details page"
        pageUri={`${MAIN_URL}${ROUTES.ASSET_DETAILS}`}
      />
    </>
  );
};

export default AssetDetails;
