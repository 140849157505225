import React from "react";

import useNewPortfolioFacade from "./useNewPortfolioFacade";

import Header from "components/Header/Header";
import { Preloader } from "../../components/simpleComponents/Preloader";
import NewPortfolioHeader from "./components/NewPortfolioHeader/NewPortfolioHeader";
import PortfolioInfoDesktop from "./components/PortfolioInfoDesktop/PortfolioInfoDesktop";
import PortfolioInfoMobile from "./components/PortfolioInfoMobile/PortfolioInfoMobile";

import styles from "./NewPortfolio.module.scss";

import { HEADER_OPTIONS } from "components/Header/constants";
import { Footer } from "../../components/Footer/Footer";
import { MAIN_URL, ROUTES } from "utils/constants";

const NewPortfolio: React.FC = function (): JSX.Element {
  const [
    shares,
    isLoading,
    portfolioSctructure,
    setPortfolioSctructure,
    desktop,
    setWatchFor,
    isWidthMatches,
  ] = useNewPortfolioFacade();

  return (
    <>
      <div className={styles.wrapper}>
        <Header color={HEADER_OPTIONS.BLACK} />
        {/* <PortfolioHeader
        itemsStructure={portfolioSctructure}
        setPortfolioItemsStructure={setPortfolioSctructure}
        setWatchFor={setWatchFor}
      /> */}
        {isLoading ? (
          <Preloader />
        ) : (
          <div className={styles.informationWrapper}>
            <NewPortfolioHeader />
            {isWidthMatches ? (
              <PortfolioInfoMobile shares={shares} />
            ) : (
              <PortfolioInfoDesktop shares={shares} />
            )}
          </div>
        )}
      </div>
      <Footer
        pageName="Portfolio page"
        pageUri={`${MAIN_URL}${ROUTES.PORTFOLIO}`}
      />
    </>
  );
};

export default NewPortfolio;
