import { useEffect, useState } from "react";

import PlaidButton from "components/simpleComponents/PlaidButton/PlaidButton";
import { CustomButton } from "components/UIComponents/Button";

import { attachAchPlaid } from "apis/backend";

import { AchFormData } from "../../AccreditationForm.type";

import styles from "./PlaidWindow.module.scss";

type PlaidWindowProps = {
  isPlaidDataAccepted: boolean;
  setIsShowPlaid: React.Dispatch<React.SetStateAction<boolean>>;
  isShowPlaid: boolean;
  setIsPlaidDataAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  guid: string;
  setForm: React.Dispatch<React.SetStateAction<AchFormData>>;
  setIsFirstBoxChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

const PlaidWindow = ({
  isPlaidDataAccepted,
  setIsShowPlaid,
  isShowPlaid,
  guid,
  setForm,
  setIsPlaidDataAccepted,
  setIsFirstBoxChecked,
}: PlaidWindowProps) => {
  const [plaidLink, setPlaidLink] = useState("");

  function handlePlaidButtonClick() {
    setIsShowPlaid(true);
    setIsFirstBoxChecked(false);
  }

  useEffect(() => {
    let isMounted = true;

    (async () => {
      try {
        const res = await attachAchPlaid(guid);

        if (isMounted) {
          setPlaidLink(res.plaidLink);
        }
      } catch (err) {
        console.error(err);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [guid]);

  useEffect(() => {
    const handlePayment = async (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);

        if (data.statusCode === "101") {
          const dataFromPlaid = data?.plaidAccountDetails[0];

          const userData = {
            accountHolderName: dataFromPlaid.account_name,
            bankName: dataFromPlaid.institution_name,
            accountNumber: dataFromPlaid.account_number,
            routingNumber: dataFromPlaid.routing_number,
            accountType: dataFromPlaid.account_type,
          };

          setForm((state) => ({ ...state, ...userData }));

          setIsShowPlaid(false);

          setIsPlaidDataAccepted(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    window.addEventListener("message", handlePayment);

    return () => {
      window.removeEventListener("message", handlePayment);
    };
  });

  return (
    <>
      <div className={styles.plaidContainer}>
        {!isPlaidDataAccepted && (
          <PlaidButton handleClick={handlePlaidButtonClick} />
        )}
        {isShowPlaid && (
          <iframe
            src={plaidLink}
            width={380}
            height={640}
            title="Plaid payment form"
          />
        )}
        {isShowPlaid && (
          <CustomButton
            onClick={() => setIsShowPlaid(false)}
            btnStyle="inlineDarkAlternative"
          >
            BACK TO MANUAL ACH FORM
          </CustomButton>
        )}
      </div>
    </>
  );
};

export default PlaidWindow;
