import { Box, Typography, colors } from "@mui/material";
import { Link } from "react-router-dom";
import React, { FC, useEffect, useState } from "react";
import { CloseIcon } from "assets/icons/close";
import { InputField } from "components/simpleComponents/Input/InputField/InputField";
import { PhoneDropDownComponent } from "components/simpleComponents/PhoneDropDown/PhoneDropDown";
import RadioButton from "components/simpleComponents/Radio/RadioButton";
import { classes } from "pages/Derby/components/styles";
import { Button } from "pages/Profile/ConfigureProfile/Button/Button";
import styles from "../styles.module.scss";
import Dropdown from "components/simpleComponents/Dropdown/DropDown";
import { domicileChoices } from "types/Account";
import { useServerAPI } from "apis";
import { MAIN_URL, ROUTES } from "utils/constants";
import { getHubSpotCookie, sendHubSpotData } from "apis/hubSpot";
import { addKlaviyoProfileToList, createKlaviyoProfile } from "apis/klaviyo";

interface Props {
  changeVisible: () => void;
  changeStep: (step: number) => void;
}

export const TiaraForm: FC<Props> = ({ changeVisible, changeStep }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [licenseAgree, setLicenseAgree] = useState(false);
  const [borderColor, setBorderColor] = useState("#000");
  const [citizenStatus, setCitizenStatus] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    licenseAgree: "",
    duplicate: "",
    citizenStatus: "",
  });

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const api = useServerAPI();

  const handleSubmit = async () => {
    const newError = {} as typeof error;
    if (!firstName) newError.firstName = "First name is required";
    if (!lastName) newError.lastName = "Last name is required";
    if (!email) newError.email = "Email is required";
    if (!validateEmail(email)) newError.email = "Email is not valid";
    if (phone.length < 5) newError.phone = "Phone is required";
    if (!licenseAgree) newError.licenseAgree = "License agree is required";
    if (!citizenStatus) newError.citizenStatus = "Citizenship is required";

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }

    // HubSpot form logic  -- START --

    // try {
    //   const ip = await api.getIPFromAmazon();

    //   const formId = process.env.REACT_APP_HUBSPOT_FORM_NEWSLETTER_GUID || "";
    //   const trackingCookie = getHubSpotCookie() || "";

    //   const response = await sendHubSpotData(
    //     {
    //       firstName,
    //       lastName,
    //       email,
    //       ipAddress: ip,
    //       trackingCookie,
    //       pageUri: `${MAIN_URL}${ROUTES.TIARA}`,
    //       pageName: "Tiara Page",
    //       citizenshipStatus: citizenStatus?.value,
    //       phoneNumber: `+${phone}`,
    //     },
    //     formId
    //   );

    //   if (response.inlineMessage === "Thanks for submitting the form.") {
    //     changeStep(2);
    //   }
    // } catch (error: any) {
    //   console.log(error.response);
    //   if (
    //     error?.response?.data?.message[0] ===
    //     "phoneNumber must be a valid phone number"
    //   ) {
    //     setError((prev) => ({
    //       ...prev,
    //       duplicate: "Invalid phone number!",
    //     }));
    //   }
    // }

    // HubSpot form -- END --

    try {
      const profileData = await createKlaviyoProfile({
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phone,
        citizenshipStatus: citizenStatus?.value,
        source: "common",
      });

      if (profileData?.data?.id) {
        const res = await addKlaviyoProfileToList(
          profileData.data.id,
          process.env.REACT_APP_KB_LIST_ID || ""
        );
        changeStep(2);
      } else {
        if (profileData?.response?.data?.errors?.length > 0) {
          const errors = profileData.response.data.errors;
          if (errors[0]?.code === "duplicate_profile") {
            setError((prev) => ({
              ...prev,
              duplicate: "Email or phone number is already in use",
            }));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (name === "firstName") {
      if (value) setError((prev) => ({ ...prev, firstName: "" }));
      setFirstName(value);
    } else if (name === "lastName") {
      if (value) setError((prev) => ({ ...prev, lastName: "" }));
      setLastName(value);
    } else if (name === "email") {
      if (value && validateEmail(value))
        setError((prev) => ({ ...prev, duplicate: "", email: "" }));
      setEmail(value);
    } else if (name === "phone") {
      if (value) setError((prev) => ({ ...prev, duplicate: "", phone: "" }));
      setPhone(value);
    }
  };

  useEffect(() => {
    if (error.citizenStatus && !citizenStatus) {
      setBorderColor("#FF0000");
    } else {
      setBorderColor("#000");
    }
  }, [error.citizenStatus, citizenStatus]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <Box sx={{ cursor: "pointer" }} onClick={changeVisible}>
          <CloseIcon color={"#000000"} />
        </Box>
      </Box>
      <Typography sx={classes.subTitle}>The tiara</Typography>
      <Typography sx={classes.text}>
        To purchase shares of The Tiara designed by Garrard exclusively for
        LUXUS, begin by completing the registration form below. All purchasers
        in the offering must be accredited investors. LUXUS will be required to
        verify your accredited investor status.
      </Typography>
      <Box sx={classes.form}>
        <InputField.White
          placeholder="FIRST NAME"
          name="firstName"
          onChange={handleChange}
          value={firstName}
          error={!!error.firstName}
          errorText={error.firstName}
        />
        <InputField.White
          placeholder="LAST NAME"
          name="lastName"
          onChange={handleChange}
          value={lastName}
          error={!!error.lastName}
          errorText={error.lastName}
        />
        <InputField.White
          placeholder="EMAIL ADDRESS"
          name="email"
          onChange={handleChange}
          value={email}
          error={!!error.email}
          errorText={error.email}
        />
        <PhoneDropDownComponent.White
          onChange={(phone) => {
            setPhone(phone);
            if (phone.length > 5)
              setError((prev) => ({ ...prev, duplicate: "", phone: "" }));
          }}
          value={phone}
          error={!!error.phone}
        />
        {error.phone && (
          <span style={{ color: "#FF0000" }}>Phone is required</span>
        )}
        <Dropdown
          borderColor={borderColor}
          arrowColor="black"
          options={domicileChoices}
          value={citizenStatus}
          onChange={(option) => {
            if (citizenStatus)
              setError((prev) => ({ ...prev, citizenStatus: "" }));
            setCitizenStatus(option);
          }}
          placeholder="CITIZENSHIP STATUS"
        />
        {error.citizenStatus && !citizenStatus && (
          <span style={{ color: "#FF0000" }}>Citizenship is required</span>
        )}
        {!!error.duplicate && (
          <p className={styles.duplicateError}>{error.duplicate}</p>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <RadioButton.Black
          label=""
          value={licenseAgree}
          onChange={() => {}}
          onClick={() => {
            setLicenseAgree((prev) => !prev);
            setError((prev) => ({ ...prev, licenseAgree: "" }));
          }}
          error={!!error.licenseAgree}
        />
        <Typography sx={classes.text} style={{ textAlign: "start" }}>
          I have read and agree to the{" "}
          <Link target="_blank" style={{ color: "inherit" }} to={"/privacy"}>
            Privacy Policy
          </Link>
          ,{"  "}
          <Link
            target="_blank"
            style={{ color: "inherit" }}
            to={"/termsOfService"}
          >
            Terms of Service
          </Link>
        </Typography>
      </Box>
      <Button.Black width="100%" onClick={handleSubmit} value={"SUBMIT"} />
    </>
  );
};
