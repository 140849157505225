import SilverLine from "assets/icons/SilverLine";
import LanesEnd from "assets/icons/LanesEnd";
import WoodfordRacing from "assets/icons/WoodfordRacing";

import styles from "./Partners.module.scss";

const Partners = () => {
  return (
    <section className={styles.container}>
      <SilverLine />
      <div className={styles.textContainer}>
        <p className={styles.text}>In Partnership With</p>
        <div className={styles.iconsContainer}>
          <LanesEnd />
          <WoodfordRacing />
        </div>
      </div>
      <SilverLine />
    </section>
  );
};

export default Partners;
