import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      progressBarContainer: {
        width: "90%",
      },
      progressBarContent: {
        marginRight: "0",
      },
      nameTitleBox: {
        marginBottom: "10px",
      },
      nameTitle: {
        fontSize: "30px",
      },
      infoItemTitle: {
        fontSize: "20px",
        "&:last-child": {
          marginRight: "8px",
        },
      },
      amountInfoItemTitle: {
        fontSize: "20px",
      },
      amountInfoItemValue: {
        fontSize: "20px",
      },
      soldOutBox: {
        justifyContent: "center",
      },
      buySharesBtn: {
        fontSize: "20px",
      },
      infoBox: {
        width: "370px",
      },
      popoverPaper: {
        padding: "10px",
        fontSize: "18px",
      },
      popoverTriangle: {
        "&::before": {
          backgroundColor: "#DFDFE3 !important",
          content: '""',
          display: "block",
          position: "absolute",
          width: 30,
          height: 30,
          bottom: "48px",
          transform: "rotate(45deg)",
          left: "calc(80% + 18px)",
          zIndex: "1",
        },
      },
    })
  )
);
