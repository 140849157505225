import { Container, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import moment from 'moment';
import { UserProfile } from '../../../types/Account';
import { themeData } from '../../../utils/theme';
import { ComplianceDataType } from '../../../types/ComplianceData';
import { DollarAmountFormatter, SSNObfuscator } from '../../../utils';
import { finraChoices, disabledFinraAssociation } from './ComplianceForm';

export type ProfileConfirmViewProps = {
  profileData?: UserProfile;
  complianceData?: ComplianceDataType;
  onCancel: Function;
  onSave: Function;
};

const acreditationLabels: { [label: string]: string } = {
  'accredited-1': 'Accredited Investor',
  'accredited-2': 'Accredited Investor',
  'not-accredited': 'Not Accredited',
  na: 'Invalid Answer',
};

export const ProfileConfirmView = ({
  profileData,
  complianceData,
  onCancel,
  onSave,
}: ProfileConfirmViewProps) => {
  // if (!profileData) return <div />;

  return (
    <Container maxWidth={'sm'}>
      <Box>
        <Typography
          component="h4"
          variant="h6"
          align="left"
          sx={{
            color: themeData.GreyLabel,
            marginTop: '2rem',
          }}
          style={{ fontSize: '16px' }}
        >
          Review and confirm your information
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{
            maxWidth: '722px',
            marginTop: '20px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {profileData && (
                <>
                  {/* ------ Name ----- */}
                  <Typography
                    component="p"
                    align="left"
                    sx={{
                      color: themeData.GreyLabel,
                      //
                    }}
                    style={{ fontSize: '12px' }}
                  >
                    Full Legal Name
                  </Typography>
                  <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    sx={{
                      color: themeData.BlackColor,
                      //
                    }}
                    style={{ fontSize: '14px' }}
                  >
                    {profileData.firstName} {profileData.lastName}
                  </Typography>

                  {/* ------Address ----- */}
                  <Typography
                    component="p"
                    align="left"
                    sx={{
                      color: themeData.GreyLabel,
                      //
                      marginTop: '1rem',
                    }}
                    style={{ fontSize: '12px' }}
                  >
                    Address
                  </Typography>
                  <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    sx={{
                      color: themeData.BlackColor,
                      //
                    }}
                    style={{ fontSize: '14px' }}
                  >
                    {profileData?.primAddress1}
                    <br />
                    {profileData?.primAddress2}
                    {profileData?.primCity}, {profileData?.primState}
                    <br />
                    {profileData?.primCountry}, {profileData?.primZip}
                  </Typography>

                  {/* ------ Phone ----- */}
                  <Typography
                    component="p"
                    align="left"
                    sx={{
                      color: themeData.GreyLabel,
                      //
                      marginTop: '1rem',
                    }}
                    style={{ fontSize: '12px' }}
                  >
                    Phone number
                  </Typography>
                  <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    sx={{
                      color: themeData.BlackColor,
                      //
                    }}
                    style={{ fontSize: '14px' }}
                  >
                    {profileData.phone}
                  </Typography>

                  {/* ------Date of Birth ----- */}
                  <Typography
                    component="p"
                    align="left"
                    sx={{
                      color: themeData.GreyLabel,

                      marginTop: '1rem',
                    }}
                    style={{ fontSize: '12px' }}
                  >
                    Date of Birth
                  </Typography>
                  <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    sx={{
                      color: themeData.BlackColor,
                    }}
                    style={{ fontSize: '14px' }}
                  >
                    {moment(profileData.dob, 'MM-DD-YYYY').format(
                      'MMMM DD,YYYY'
                    )}
                  </Typography>

                  {/* ------Social Security Number ----- */}
                  {profileData?.socialSecurityNumber && (
                    <Typography
                      component="p"
                      align="left"
                      sx={{
                        color: themeData.GreyLabel,

                        marginTop: '1rem',
                      }}
                      style={{ fontSize: '12px' }}
                    >
                      SSN (Social Security Number)
                    </Typography>
                  )}
                  {profileData?.socialSecurityNumber && (
                    <Typography
                      component="p"
                      align="left"
                      sx={{
                        color: themeData.BlackColor,
                      }}
                      style={{ fontSize: '14px' }}
                    >
                      {SSNObfuscator(profileData?.socialSecurityNumber)}
                    </Typography>
                  )}
                </>
              )}
              {/* ------Compliance data----- */}
              {/* ------ Acreditation ----- */}
              <Typography
                component="p"
                align="left"
                sx={{
                  color: themeData.GreyLabel,

                  marginTop: '1rem',
                }}
                style={{ fontSize: '12px' }}
              >
                Accreditation Status
              </Typography>
              <Typography
                component="h4"
                variant="h6"
                align="left"
                sx={{
                  color: themeData.BlackColor,
                }}
                style={{ fontSize: '14px' }}
              >
                {acreditationLabels[complianceData?.accreditation ?? 'na']}
              </Typography>

              {/* ------ Annual Income ----- */}
              <Typography
                component="p"
                align="left"
                sx={{
                  color: themeData.GreyLabel,

                  marginTop: '1rem',
                }}
                style={{ fontSize: '12px' }}
              >
                Annual Income
              </Typography>
              <Typography
                component="h4"
                variant="h6"
                align="left"
                sx={{
                  color: themeData.BlackColor,
                }}
                style={{ fontSize: '14px' }}
              >
                {DollarAmountFormatter(complianceData?.annualIncome ?? '')}
              </Typography>
              {/* ------ Net Worth ----- */}
              <Typography
                component="p"
                align="left"
                sx={{
                  color: themeData.GreyLabel,

                  marginTop: '1rem',
                }}
                style={{ fontSize: '12px' }}
              >
                Net Worth
              </Typography>
              <Typography
                component="h4"
                variant="h6"
                align="left"
                sx={{
                  color: themeData.BlackColor,
                }}
                style={{ fontSize: '14px' }}
              >
                {DollarAmountFormatter(complianceData?.netWorth ?? '')}
              </Typography>

              {/* ------ FINRA associated----- */}
              {!disabledFinraAssociation && (
                <>
                  <Typography
                    component="p"
                    align="left"
                    sx={{
                      color: themeData.GreyLabel,

                      marginTop: '1rem',
                    }}
                    style={{ fontSize: '12px' }}
                  >
                    Associated with a FINRA member, organization, or the SEC
                  </Typography>
                  <Typography
                    component="h4"
                    variant="h6"
                    align="left"
                    sx={{
                      color: themeData.BlackColor,
                    }}
                    style={{ fontSize: '14px' }}
                  >
                    {complianceData?.associtationFinra === 'yes' ? 'Yes' : 'No'}
                  </Typography>
                  {!disabledFinraAssociation &&
                    complianceData?.associtationFinra === 'yes' && (
                      <Typography
                        component="h4"
                        variant="h6"
                        align="left"
                        sx={{
                          color: themeData.BlackColor,
                        }}
                        style={{ fontSize: '14px' }}
                      >
                        {complianceData?.associatedOrganization}
                      </Typography>
                    )}
                </>
              )}
              {/* ------ FINRA license----- */}
              <Typography
                component="p"
                align="left"
                sx={{
                  color: themeData.GreyLabel,

                  marginTop: '1rem',
                }}
                style={{ fontSize: '12px' }}
              >
                {/* Associated with a FINRA member, organization, or the SEC */}
                Have FINRA lincense
              </Typography>
              <Typography
                component="h4"
                variant="h6"
                align="left"
                sx={{
                  color: themeData.BlackColor,
                }}
                style={{ fontSize: '14px' }}
              >
                {complianceData?.hasFinraLicense === 'yes' ? 'Yes' : 'No'}
              </Typography>
              {complianceData?.hasFinraLicense === 'yes' && (
                <Typography
                  component="h4"
                  variant="h6"
                  align="left"
                  sx={{
                    color: themeData.BlackColor,
                  }}
                  style={{ fontSize: '14px' }}
                >
                  {
                    finraChoices.find(
                      (i) => i.value === complianceData?.finraLicense
                    )?.label
                  }
                </Typography>
              )}

              {/* ------ Shareolder... public company----- */}
              <Typography
                component="p"
                align="left"
                sx={{
                  color: themeData.GreyLabel,

                  marginTop: '1rem',
                }}
                style={{ fontSize: '12px' }}
              >
                Shareholder, officer, or member of the board of directors of a
                publicly traded company
              </Typography>
              <Typography
                component="h4"
                variant="h6"
                align="left"
                sx={{
                  color: themeData.BlackColor,
                }}
                style={{ fontSize: '14px' }}
              >
                {complianceData?.memberOfTradedPublicly === 'yes'
                  ? 'Yes'
                  : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                onClick={() => onCancel()}
                component="h4"
                variant="h6"
                align="right"
                sx={{
                  color: '#274f85',
                }}
                style={{ fontSize: '13px' }}
              >
                Edit
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <div style={{ marginTop: '40px' }}>
          <hr />
        </div>
        <Box>
          <Box
            display={'flex'}
            flexDirection="row"
            justifyContent={'center'}
            alignContent="center"
            sx={{ marginTop: '20px' }}
          >
            <HowToRegIcon style={{ color: '#aaaaaa' }} />
            <Typography
              component="h4"
              variant="h6"
              align="center"
              justifyContent={'center'}
              sx={{
                color: '#aaaaaa',
              }}
              style={{ fontSize: '12px' }}
            >
              We use a 3rd party to verify your information
            </Typography>
          </Box>
          <Typography
            component="h4"
            variant="h6"
            align="center"
            justifyContent={'center'}
            sx={{
              color: '#333333',
              marginTop: '20px',
            }}
            style={{ fontSize: '12px' }}
          >
            Your security is important to us.Learn how your <br />
            information is protected in our Privacy Policy
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: '1rem',
          }}
        >
          <Grid item xs={6}>
            <Button
              variant="outlined"
              sx={{
                width: '100%',
                marginTop: 1,
                color: '#274f85',
                borderRadius: '5px !important',
              }}
              onClick={() => onCancel()}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              sx={{
                width: '100%',
                backgroundColor: '#274f85',
                marginTop: 1,
                color: themeData.WhiteColor,
                borderRadius: '5px !important',
              }}
              onClick={() => onSave()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
