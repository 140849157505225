import { Label } from "@mui/icons-material";
import { Typography } from "@mui/material";

interface StepConfig {
  questionTitle: string;
  percent: number;
  popUpLinkLabel?: string;
  popUpMessage?: string | JSX.Element;
  description?: string;
  buttonLabel?: string;
}

export const stepsNonUSCitizenConfig: StepConfig[] = [
    {
        questionTitle: "When is your birthday?",
        percent: 9,
        popUpMessage:
            "LUXUS is accessible to US investors over 18 years of age. We need to ask for your date of birth in order to complete your account verification. Rest assured, this info will never be shared and is kept secure.",
    },
    {
        questionTitle: "What is your citizenship status?",
        percent: 18,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "What country are you a citizen of?",
        percent: 27,
        popUpMessage:
            "LUXUS is accessible to US investors over 18 years of age. We need to ask for your date of birth in order to complete your account verification. Rest assured, this info will never be shared and is kept secure.",
    },
    {
        questionTitle: "",
        percent: 0,
    },
    {
        questionTitle: "What is your address?",
        percent: 35,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "Please fill out the number on your passport and upload a photo scan.",
        percent: 45,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "What is your phone number?",
        percent: 50,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle:
            "Now we're going to ask you some questions about your income and accreditation status.",
        percent: 65,
        description:
            "This information is needed to verify your credibility as an investor with LUXUS, we'll need to collect some basic information from you. Rest assured, we will never share or sell this information and all data is secured.",
    },
    {
        questionTitle: "What is your Accreditation Status?",
        percent: 70,
    },
    {
        questionTitle: "What is your annual income?",
        percent: 80,
    },
    {
        questionTitle: "Do you hold a FINRA License?",
        percent: 90,
        popUpLinkLabel: "What is this?",
        popUpMessage:
            "You must be registered with FINRA  (Financial Industry Regulatory Authority) if you're engaged in the securities business of a firm,  which includes salespersons, branch managers, department supervisors, partners, officers and directors. It is not a requirement to hold a FINRA License to invest with LUXUS. ",
    },
    {
        questionTitle: "What FINRA License do you have?",
        percent: 90,
        popUpLinkLabel: "What is this?",
        popUpMessage:
            "You must be registered with FINRA  (Financial Industry Regulatory Authority) if you're engaged in the securities business of a firm,  which includes salespersons, branch managers, department supervisors, partners, officers and directors. It is not a requirement to hold a FINRA License to invest with LUXUS. ",
    },
    {
        questionTitle:
            "Are you or anyone in your household or immediate family a 10% shareholder, officer, or member of the board of directors of a publicly traded company? *",
        percent: 99,
        popUpLinkLabel: "Why are we asking this?",
        popUpMessage: (
            <Typography sx={{ fontWeight: "300" }}>
                In accordance with the Section 16 of the Exchange Act, an SEC reporting
                company's directors and officers, as well as shareholders who own more
                than 10% of a class of the company's equity securities registered under
                the Exchange Act. The rules under Section 16 require these insiders to
                report most of their transactions involving the company's equity
                securities to the SEC within two business days on Forms{" "}
                <a
                    href="https://www.sec.gov/about/forms/form3.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    3
                </a>
                ,{" "}
                <a
                    href="https://www.sec.gov/about/forms/form4.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    4
                </a>{" "}
                or{" "}
                <a
                    href="https://www.sec.gov/about/forms/form5.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    5
                </a>
                .
            </Typography>
        ),
        buttonLabel: "Finish",
    }
]

export const stepsUSResidentConfig: StepConfig[] = [
    {
        questionTitle: "When is your birthday?",
        percent: 9,
        popUpMessage:
            "LUXUS is accessible to US investors over 18 years of age. We need to ask for your date of birth in order to complete your account verification. Rest assured, this info will never be shared and is kept secure.",
    },
    {
        questionTitle: "What is your citizenship status?",
        percent: 18,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "What country are you a citizen of?",
        percent: 27,
        popUpMessage:
            "LUXUS is accessible to US investors over 18 years of age. We need to ask for your date of birth in order to complete your account verification. Rest assured, this info will never be shared and is kept secure.",
    },
    {
        questionTitle: "What is your U.S. address?",
        percent: 36,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "What is your Social Security #?",
        percent: 45,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "Please fill out the number on your government-issued ID and upload a photo scan.",
        percent: 54,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "What is your phone number?",
        percent: 63,
        popUpMessage:
            "Since you hold an account with an SEC regulated broker-dealer, you are required to provide your Social Security number by Federal Law. This information will never be shared and is kept secure. ",
    },
    {
        questionTitle:
            "Now we're going to ask you some questions about your income and accreditation status.",
        percent: 70,
        description:
            "This information is needed to verify your credibility as an investor with LUXUS, we'll need to collect some basic information from you. Rest assured, we will never share or sell this information and all data is secured.",
    },
    {
        questionTitle: "What is your Accreditation Status?",
        percent: 78,
    },
    {
        questionTitle: "What is your annual income?",
        percent: 85,
    },
    {
        questionTitle: "Do you hold a FINRA License?",
        percent: 90,
        popUpLinkLabel: "What is this?",
        popUpMessage:
            "You must be registered with FINRA  (Financial Industry Regulatory Authority) if you're engaged in the securities business of a firm,  which includes salespersons, branch managers, department supervisors, partners, officers and directors. It is not a requirement to hold a FINRA License to invest with LUXUS. ",
    },
    {
        questionTitle: "What FINRA License do you have?",
        percent: 90,
        popUpLinkLabel: "What is this?",
        popUpMessage:
            "You must be registered with FINRA  (Financial Industry Regulatory Authority) if you're engaged in the securities business of a firm,  which includes salespersons, branch managers, department supervisors, partners, officers and directors. It is not a requirement to hold a FINRA License to invest with LUXUS. ",
    },
    {
        questionTitle:
            "Are you or anyone in your household or immediate family a 10% shareholder, officer, or member of the board of directors of a publicly traded company? *",
        percent: 99,
        popUpLinkLabel: "Why are we asking this?",
        popUpMessage: (
            <Typography sx={{ fontWeight: "300" }}>
                In accordance with the Section 16 of the Exchange Act, an SEC reporting
                company's directors and officers, as well as shareholders who own more
                than 10% of a class of the company's equity securities registered under
                the Exchange Act. The rules under Section 16 require these insiders to
                report most of their transactions involving the company's equity
                securities to the SEC within two business days on Forms{" "}
                <a
                    href="https://www.sec.gov/about/forms/form3.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    3
                </a>
                ,{" "}
                <a
                    href="https://www.sec.gov/about/forms/form4.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    4
                </a>{" "}
                or{" "}
                <a
                    href="https://www.sec.gov/about/forms/form5.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    5
                </a>
                .
            </Typography>
        ),
        buttonLabel: "Finish",
    }
];

export const stepsUSCitizenConfig: StepConfig[] = [
    {
        questionTitle: "When is your birthday?",
        percent: 9,
        popUpMessage:
            "LUXUS is accessible to US investors over 18 years of age. We need to ask for your date of birth in order to complete your account verification. Rest assured, this info will never be shared and is kept secure.",
    },
    {
        questionTitle: "What is your citizenship status?",
        percent: 18,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "What is your U.S. address?",
        percent: 27,
        popUpMessage:
            "LUXUS is accessible to US investors over 18 years of age. We need to ask for your date of birth in order to complete your account verification. Rest assured, this info will never be shared and is kept secure.",
    },
    {
        questionTitle: "",
        percent: 0,
        popUpMessage: "",
    },
    {
        questionTitle: "What is your Social Security #?",
        percent: 36,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "Please fill out the number on your government-issued ID and upload a photo scan.",
        percent: 45,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle: "What is your phone number?",
        percent: 54,
        popUpMessage:
            "We’ll need to collect some basic info to complete your profile. Rest assured, this info will never be shared and is kept secure. ",
    },
    {
        questionTitle:
            "Now we're going to ask you some questions about your income and accreditation status.",
        percent: 54,
        description:
            "This information is needed to verify your credibility as an investor with LUXUS, we'll need to collect some basic information from you. Rest assured, we will never share or sell this information and all data is secured.",
    },
    {
        questionTitle: "What is your Accreditation Status?",
        percent: 63,
    },
    {
        questionTitle: "What is your annual income?",
        percent: 72,
    },
    {
        questionTitle: "Do you hold a FINRA License?",
        percent: 90,
        popUpLinkLabel: "What is this?",
        popUpMessage:
            "You must be registered with FINRA  (Financial Industry Regulatory Authority) if you're engaged in the securities business of a firm,  which includes salespersons, branch managers, department supervisors, partners, officers and directors. It is not a requirement to hold a FINRA License to invest with LUXUS. ",
    },
    {
        questionTitle: "What FINRA License do you have?",
        percent: 90,
        popUpLinkLabel: "What is this?",
        popUpMessage:
            "You must be registered with FINRA  (Financial Industry Regulatory Authority) if you're engaged in the securities business of a firm,  which includes salespersons, branch managers, department supervisors, partners, officers and directors. It is not a requirement to hold a FINRA License to invest with LUXUS. ",
    },
    {
        questionTitle:
            "Are you or anyone in your household or immediate family a 10% shareholder, officer, or member of the board of directors of a publicly traded company? *",
        percent: 99,
        popUpLinkLabel: "Why are we asking this?",
        popUpMessage: (
            <Typography sx={{ fontWeight: "300" }}>
                In accordance with the Section 16 of the Exchange Act, an SEC reporting
                company's directors and officers, as well as shareholders who own more
                than 10% of a class of the company's equity securities registered under
                the Exchange Act. The rules under Section 16 require these insiders to
                report most of their transactions involving the company's equity
                securities to the SEC within two business days on Forms{" "}
                <a
                    href="https://www.sec.gov/about/forms/form3.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    3
                </a>
                ,{" "}
                <a
                    href="https://www.sec.gov/about/forms/form4.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    4
                </a>{" "}
                or{" "}
                <a
                    href="https://www.sec.gov/about/forms/form5.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    5
                </a>
                .
            </Typography>
        ),
        buttonLabel: "Finish",
    }
];
