import styles from "./AnnounceDetails.module.scss";
import { DETAILS } from "./constants";

const AnnounceDetails = () => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {DETAILS.map(({ id, text, title }) => (
          <li key={id} className={styles.listItem}>
            <span className={styles.title}>{title}</span>
            <span className={styles.text}>{text}</span>
          </li>
        ))}
      </ul>
      <div className={styles.wrapper}>
        <p className={styles.secondTitle}>
          About Lane’s End and Woodford Racing
        </p>
        <p className={styles.secondText}>
          Located in the heart of Central Kentucky, Lane’s End stands as a
          global leader in breeding and stallion operations, boasting over 320
          Grade 1 winners and more than $1.5 billion in sales since 1983.  Their
          team is driven by one mission: to help their partners achieve their
          breeding, sales and racing goals across the globe. Similarly, Woodford
          Racing’s track record is unmatched — whether on or off the track, the
          value they provide is truly unrivaled.
        </p>
      </div>
    </div>
  );
};

export default AnnounceDetails;
