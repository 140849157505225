import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useServerAPI } from "../../../apis";

import type { Offering } from "../../../types/Offerings";
import useGetDisplayType from "../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../types/enums/DisplayTypes";

import OfferingNumbersFormatter from "../../../utils/OfferingNumbersFormatter";
import ReactGA from "react-ga";
import {
  SCROLL_QUERY_PARAM,
  STREAM_SECTION_ID,
} from "../Assets/components/StreamCountdown/constants";
import { handleSectionScroll } from "utils/handleSectionScroll";

export default function useAssetsDetailsFacade(): [
  Offering,
  Offering,
  boolean,
  () => void,
  boolean
] {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/AssetsDetails");
  }, []);

  const api = useServerAPI();

  const id = useParams().id;

  const navigate = useNavigate();

  const proceedOffer = (offer: Offering): Offering => {
    return OfferingNumbersFormatter(offer);
  };

  const desktop = !useGetDisplayType([
    DisplayTypes.Mobile,
    DisplayTypes.TabletPortrait,
  ]);

  const [activeOffer, setActiveOffer] = useState<Offering>();
  const [similarOffer, setSimilarOffer] = useState<Offering>();
  const [isLoadind, setIsLoading] = useState(false);

  const getFeaturedOffer = async (): Promise<void> => {
    const result = await api.getOfferings();
    const featured = result.data.response.items.find(
      (item) => item.offeringId !== id
    );

    if (featured) setSimilarOffer(proceedOffer(featured));
  };

  const getOfferById = async (): Promise<void> => {
    const result = await api.getOffering(id as string);
    if (result.data.response) {
      await setActiveOffer(proceedOffer(result.data.response));
    }
  };

  const getData = async (): Promise<void> => {
    setIsLoading(true);
    await getOfferById();
    await getFeaturedOffer();
    setIsLoading(false);
  };

  useEffect(() => {
    handleSectionScroll(SCROLL_QUERY_PARAM, STREAM_SECTION_ID);

  }, []);

  useEffect(() => {
    getData();
  }, [id]);

  const onBuySharesClick = (): void => {
    navigate(`/buyAsset/${id}`);
  };

  return [
    similarOffer as Offering,
    activeOffer as Offering,
    isLoadind,
    onBuySharesClick,
    desktop,
  ];
}
