import themeData from './theme';

type AccountStatusReturn = {
  status: string;
  label: string;
  color: string;
  isRefused: boolean;
  isApproved: boolean;
  isProfileCreated: boolean;
  completedFinQuestions: boolean;
};

export const accountStatus = (): AccountStatusReturn => {
  const KycStatusLevels = [
    'disapproved',
    'not started',
    'need more info',
    'pending',
    'started',
    'manually approved',
    'auto approved',
    'Xdisapproved',
  ];
  const ProfileCreated = [
    'disapproved',
    'need more info',
    'pending',
    'started',
    'manually approved',
    'auto approved',
  ];

  const statusTranslate: { [prop: string]: { label: string; color: string } } =
    {
      // disapproved: {label: 'Trading has been declined', color: themeData.ErrorTextColor},
      disapproved: {
        label: 'Trading not approved yet',
        color: themeData.ErrorTextColor,
      },
      'not started': {
        label: 'You haven’t completed your investing information',
        color: themeData.HighlightColor,
      },
      'need more info': {
        label: 'More information is required',
        color: themeData.HighlightColor,
      },
      pending: {
        label: 'We’re verifying your trading information',
        color: themeData.HighlightColor,
      },
      started: {
        label: 'We’re verifying your trading information',
        color: themeData.HighlightColor,
      },
      'manually approved': {
        label: 'verified account',
        color: themeData.SuccessTextColor,
      },
      'auto approved': {
        label: 'verified account',
        color: themeData.SuccessTextColor,
      },
    };

  var status = localStorage.getItem('verifyStatus') ?? 'not started';
  var completedFinQuestions =
    localStorage.getItem('completedFinQuestions') ?? 'false';
  return {
    status: status,
    label: statusTranslate[status]?.label ?? '',
    color: statusTranslate[status]?.color ?? themeData.TransparentColor,
    isRefused: KycStatusLevels.indexOf(status ?? '') === 0,
    isApproved: KycStatusLevels.indexOf(status ?? '') > 4,
    isProfileCreated: ProfileCreated.indexOf(status ?? '') > 0,
    completedFinQuestions: completedFinQuestions === 'true',
  };
};

export default accountStatus;
