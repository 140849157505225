import { FaqData } from "./Faq.type";

export const faqs: FaqData[] = [
  {
    sort: 0,
    question: "WHO OWNS THE ASSETS?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        A series of a limited liability company operated by LUXUS will own the
        assets, and you will own part of that company. By securitizing the asset
        in a cost effective legal structure, LUXUS enables beneficial ownership
        to be shared amongst all of our investors.
        <br />
        <br /> Multi asset portfolios are coming later in 2024! Stay tuned for
        those.
      </p>
    ),
    text: "A series of a limited liability company operated by LUXUS will own the assets, and you will own part of that company. By securitizing the asset in a cost effective legal structure, LUXUS enables beneficial ownership to be shared amongst all of our investors. Multi asset portfolios are coming later in 2024! Stay tuned for those.",
  },

  {
    sort: 1,
    question: "WHERE ARE THE ASSETS STORED? CAN I EVER SEE THEM?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        The assets are stored in highly secure, well-insured vaults that are
        standard in the industry and provided by our strategic partners. Our
        partners are handling these costs, so they are not passed on to our
        investor community.
        <br />
        <br /> LUXUS is working with strategic partners and with leading luxury
        industry experts to organize public viewings of the assets for all
        investors, while minimizing risks and costs for all parties. These
        public viewings may take place at the luxury brands’ physical locations
        or in bespoke gallery spaces displaying LUXUS assets exclusively. <br />
        <br /> Our LUXUS VIP members will be able to exclusively preview the
        assets at select events around the world.
      </p>
    ),
    text: `The assets are stored in highly secure, well-insured vaults that are standard in the industry and provided by our strategic partners. Our partners are handling these costs, so they are not passed on to our investor community. 

LUXUS is working with strategic partners and with leading luxury industry experts to organize public viewings of the assets for all investors, while minimizing risks and costs for all parties.  These public viewings may take place at the luxury brands’ physical locations or in bespoke gallery spaces displaying LUXUS assets exclusively.

Our LUXUS VIP members will be able to exclusively preview the assets at select events around the world.
`,
  },
  {
    sort: 2,
    question: "HOW DO YOU SELECT THE ASSETS?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        We determine the macro theme within luxury that we think will generate
        the most attractive returns and consult with our strategic partners who
        recommend assets based on their intimate knowledge of specific luxury
        submarkets (i.e. precious gems, high jewelry, fine wines, rare watches
        etc.). We want to make sure we find the best possible assets and
        maintain our focus. Our independent advisory board evaluates these
        selections based on all the criteria we outline in the LUXUS Process.
        Our Advisory Board is made up of industry experts in all the submarkets
        represented by LUXUS assets including IGI and others.
      </p>
    ),
    text: `We determine the macro theme within luxury that we think will generate the most attractive returns and consult with our strategic partners who recommend assets based on their intimate knowledge of specific luxury submarkets (i.e. precious gems, high jewelry, fine wines, rare watches etc.). We want to make sure we find the best possible assets and maintain our focus. Our independent advisory board evaluates these selections based on all the criteria we outline in the LUXUS Process.  Our Advisory Board is made up of industry experts in all the submarkets represented by LUXUS assets including IGI and others.`,
  },
  {
    sort: 3,
    question: "HOW OFTEN WILL YOU OFFER ASSETS?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        LUXUS will offer assets opportunistically based on market demand, the
        speed with which we can navigate regulatory processes, and the state of
        market conditions for our assets.
      </p>
    ),
    text: `LUXUS will offer assets opportunistically based on market demand, the speed with which we can navigate regulatory processes, and the state of market conditions for our assets.`,
  },

  {
    sort: 4,
    question: "I HAVE AN ASSET I WOULD LIKE TO SELL WITH YOU, CAN I?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        Yes! Please email:{" "}
        <a style={{ color: "inherit" }} href="mailto:info@luxusco.com">
          info@luxusco.com
        </a>{" "}
        or visit our Sell with LUXUS feature.
      </p>
    ),
    text: `Yes! Please email: info@luxusco.com or visit our Sell with LUXUS feature.
`,
  },
  {
    sort: 5,
    question: "CAN I BUY THE ENTIRE ASSET?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        Yes! In 2024, the LUXUS Marketplace will have a “Buy-It-Now”
        functionality that allows you to purchase an asset outright and take
        personal possession (just like e-commerce). The price for such an asset
        will be set by our Advisory Board. At that time, the offering will be
        withdrawn and not available to investors. Our strategic partners also
        work to independently sell the assets to provide an exit to investors
        (via their traditional sales channels). Once the asset is sold, proceeds
        will be distributed to investors.
      </p>
    ),
    text: `Yes! In 2024, the LUXUS Marketplace will have a “Buy-It-Now” functionality that allows you to purchase an asset outright and take personal possession (just like e-commerce). The price for such an asset will be set by our Advisory Board.  At that time, the offering will be withdrawn and not available to investors. Our strategic partners also work to independently sell the assets to provide an exit to investors (via their traditional sales channels). Once the asset is sold, proceeds will be distributed to investors.`,
  },
  {
    sort: 6,
    question: "IS IT SAFE TO INVEST IN LUXUS ASSETS?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        All investments are subject to the risk of loss and we cannot guarantee
        any investment is safe. We are committed to making our process as
        transparent as possible and will share any and all information regarding
        each asset on the website (and later in the apps). Investors should make
        their own determination about their personal risk tolerance and the
        amount they are willing to put at risk.
      </p>
    ),
    text: `All of our investments are reviewed and vetted by a FINRA registered by a broker dealer and are subject to SEC oversight.  Additionally, we are committed to making our process as transparent as possible and will share any and all information regarding each asset on the website (and later in the apps).

It should be noted that all investment activity is accompanied by some level of risk and all regulatory disclosures should be consulted in the investment review process.
`,
  },
  {
    sort: 7,
    question: "IS THERE A LIMIT TO HOW MANY SHARES OF ONE ASSET I CAN BUY?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        For any retirement or health plans governed by ERISA, the limit is 25%
        of any given offering. Note, any purchaser who acquires more than 10% of
        an offering, may need to be identified in future regulatory filings made
        by LUXUS.
      </p>
    ),
    text: `For any retirement or health plans governed by ERISA, the limit is 25% of any given offering. Note, any purchaser who acquires more than 10% of an offering, may need to be identified in future regulatory filings made by LUXUS.`,
  },
  {
    sort: 8,
    question: "CAN ANYONE PARTICIPATE IN BUYING SHARES OF THE ASSET?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        Offerings conducted pursuant to Rule 506(c) of Regulation D are only
        available to accredited investors. Non-US investors who are accredited
        may participate in LUXUS offerings depending on the rules of their
        respective jurisdictions. To comply with Rule 506(c), once an investment
        is made, LUXUS is required to take reasonable steps to verify accredited
        investor status. LUXUS may seek qualification of certain offerings under
        Regulation A of the Securities Act. If qualified under Regulation A,
        then both accredited and non-accredited investors may participate.
      </p>
    ),
    text: `Offerings conducted pursuant to Rule 506(c) of Regulation D are only available to accredited investors. Non-US investors who are accredited may participate in LUXUS offerings depending on the rules of their respective jurisdictions. To comply with Rule 506(c), once an investment is made, LUXUS is required to take reasonable steps to verify accredited investor status. LUXUS may seek qualification of certain offerings under Regulation A of the Securities Act. If qualified under Regulation A, then both accredited and non-accredited investors may participate.`,
  },
  {
    sort: 9,
    question: "WILL YOU EVER HAVE ESG COMPLIANT OFFERINGS?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        Whenever possible, LUXUS assets will be ESG compliant. It is very
        important for us to: 1) participate in carbon emissions initiatives
        launched by the diamond industry, 2) use tracing blockchain technology
        to verify that our assets are not from conflict regions, 3) verify that
        fair working conditions were used in the mining of all our precious gems
        and that all gems were Kimberly process compliant and 4) operate under
        any other ESG mandated rules.
      </p>
    ),
    text: `Whenever possible, LUXUS assets will be ESG compliant.  It is very important for us to: 1) participate in carbon emissions initiatives launched by the diamond industry, 2) use tracing blockchain technology to verify that our assets are not from conflict regions, 3) verify that fair working conditions were used in the mining of all our precious gems and that all gems were Kimberly process compliant and 4) operate under any other ESG mandated rules.`,
  },
  {
    sort: 10,
    question: "ARE YOU A BROKER-DEALER?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        LUXUS itself is not a broker-dealer. Certain offerings may be conducted
        through a broker-dealer, or have a broker-dealer serving as escrow
        facilitator for the offering.
      </p>
    ),
    text: `LUXUS itself is not a broker-dealer. Certain offerings may be conducted through a broker-dealer, or have a broker-dealer serving as escrow facilitator for the offering.`,
  },
  {
    sort: 11,
    question: "ARE THERE ANY FEES ASSOCIATED WITH MY PURCHASE OF SHARES?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        We charge a management fee of up to 5% (but have the discretion to
        charge more). The Management Fee includes all the offering costs
        including legal, compliance, accounting and auditing costs. There are no
        transactions or other fees (explicit or hidden in the fine print).
        <br />
        <br />
        Additionally, we charge a Carried Interest of 20% of Net Profits (as
        defined in the legal documents) (subject to an 8% hurdle rate).
      </p>
    ),
    text: `We charge a management fee of up to 5% (but have the discretion to charge more). The Management Fee includes all the offering costs including legal, compliance, accounting and auditing costs. There are no transactions or other fees (explicit or hidden in the fine print).

Additionally, we charge a Carried Interest of 20% of Net Profits (as defined in the legal documents) (subject to an 8% hurdle rate).
`,
  },
  {
    sort: 12,
    question: 'DOES LUXUS HAVE ANY "SKIN IN THE GAME" IN EACH OFFERING?',
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        Yes, LUXUS and/or its strategic partners will maintain at least a 5%
        interest in each IPO. It is very important to us that we align our
        interests with those of our investors.
      </p>
    ),
    text: `Yes, LUXUS and/or its strategic  partners will maintain at least a 5% interest in each IPO. It is very important to us that we align our interests with those of our investors.`,
  },
  {
    sort: 13,
    question: "WHAT IS YOUR VALUATION PROCESS?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        For precious gems and jewelry, LUXUS uses the independent valuation (and
        authentication) services of IGI and the GIA, respectively. We aim to
        determine the most attractive valuation for each IPO, so in addition to
        obtaining independent valuation services, we also have an internal
        advisory board composed of industry experts that must also sign off
        unanimously on each asset valuation before it is finalized.
      </p>
    ),
    text: `For precious gems and jewelry, LUXUS uses the independent valuation (and authentication) services of IGI and the GIA, respectively. We aim to determine the most attractive valuation for each IPO, so in addition to obtaining independent valuation services, we also have an internal advisory board composed of industry experts that must also sign off unanimously on each asset valuation before it is finalized.`,
  },
  {
    sort: 14,
    question: "HOW DO YOU DETERMINE HISTORICAL RETURN INFORMATION?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        For precious gems and jewelry, LUXUS uses research data from the Fancy
        Colored Research Foundation (for colored diamonds) and IDEX (for
        colorless diamonds). LUXUS also uses auction results, mining data and
        other research tools to ascertain market trends, valuations,
        performance, etc. We strongly believe that investors should review and
        rely on independent third-party data to make investment decisions.
        <br />
        <br /> As we introduce other submarkets into our process, additional
        research will be added to our process from the relevant databases
        covering each new category.
      </p>
    ),
    text: `For precious gems and jewelry, LUXUS uses research data from the Fancy Colored Research Foundation (for colored diamonds) and IDEX (for colorless diamonds). LUXUS also uses auction results, mining data and other research tools to ascertain market trends, valuations, performance, etc. We strongly believe that investors should review and rely on independent third-party data to make investment decisions.

As we introduce other submarkets into our process, additional research will be added to our process from the relevant databases covering each new category.
`,
  },
  {
    sort: 15,
    question: "WHAT IS THE ANTICIPATED HOLDING PERIOD FOR EACH INVESTMENT?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        We expect to realize each investment between 2-5 years at most. However,
        we cannot be certain of this timeline and we reserve the right to extend
        or shorten the holding period on an as-needed basis.
      </p>
    ),
    text: `We expect to realize each investment between 2-5 years at most. However, we cannot be certain of this timeline and we reserve the right to extend or shorten the holding period on an as-needed basis.`,
  },
  {
    sort: 16,
    question: "WHAT IS LUXUS VIP?",
    answer: (
      <p style={{ color: "#fff", fontFamily: "Futura PT Book" }}>
        LUXUS VIP is an experiential investment opportunity for{" "}
        <strong>
          those interested in investing – and maintaining such investment –
          above a certain threshold.
        </strong>{" "}
        LUXUS VIP is a one-of-a-kind opportunity to gain access to offers and
        experiences available only to our VIP community. Perks of our different
        sparkling VIP tiers include advanced notice of upcoming LUXUS offerings,
        in person events and exclusive content.
      </p>
    ),
    text: `LUXUS VIP is an experiential investment opportunity for those interested in investing – and maintaining such investment – above a certain threshold. LUXUS VIP is a one-of-a-kind opportunity to gain access to offers and experiences available only to our VIP community. Perks of our different sparkling VIP tiers include advanced notice of upcoming LUXUS offerings, in person events and exclusive content.`,
  },
];
