import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          borderRadius: "4.58px",
          padding: "42px 22px 43px",
          width: "100%",
          maxWidth: "263px",
          height: "248px",
        },
        title: {
          fontSize: "25px",
        },
        img: {
          height: "34px",
          width: "55px",
          marginRight: "10px",
        },
        label: {
          fontSize: "19px",
          marginRight: "13px",
        },
        changeBtn: {
          fontSize: "19px",
        },
        text: {
          fontSize: "19px",
        },
        boldText: {
          fontSize: "19px",
        },
        row: {
          marginBottom: "17px",
          "&:last-child": {
            marginBottom: "0",
          },
        },
      })
    )
);
