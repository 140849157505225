import { useState } from "react";
import classNames from "classnames";

import ActiveTab from "../ActiveTab/ActiveTab";

import { TABS } from "./constants";
import styles from "./AssetDetails.module.scss";
import { Tab } from "./types";

const AssetDetails = () => {
  const [tabs, setTabs] = useState<Array<Tab>>(TABS);
  const [activeId, setActiveId] = useState<number>(tabs[0].id);

  const handleTabChange = (id: number) => {
    const updatedTabs = tabs.map((tab) => ({
      ...tab,
      isActive: tab.id === id,
    }));
    setTabs(updatedTabs);
    setActiveId(id);
  };

  return (
    <section className={styles.container}>
      <ul className={styles.list}>
        {tabs.map((tab) => (
          <li
            className={classNames(styles.listItem, {
              [styles.activeListItem]: tab.isActive,
            })}
            key={tab.id}
          >
            <button
              onClick={() => handleTabChange(tab.id)}
              className={classNames(styles.button, {
                [styles.activeButton]: tab.isActive,
              })}
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
      <ActiveTab id={activeId} />
    </section>
  );
};

export default AssetDetails;
