import React, {FC, memo, useMemo} from 'react';
import {Box, Typography, Grid} from '@mui/material';
import classnames from 'classnames';
import usePaymentMethodFacade from './usePaymentMethodFacade';
import {PaymentType} from '../../../../../types/enums/PaymentType';
import {Qty} from '../../../../../components/simpleComponents/Qty';
import {useStyles} from './styles/';
import {Button} from '../../../../../components/simpleComponents/Button';
import {BankAccountData, CreditCardData} from '../../../../../types/Account';
import {getBankInfo, getCardInfo} from './utils';
import style from './style.module.scss'
import {CustomButton} from "../../../../../components/UIComponents/Button";

export interface PaymentMethodsProps {
  bankData: BankAccountData | undefined;
  cardData: CreditCardData | undefined;
  itemsCount: number;
  changeItemsCount: React.Dispatch<React.SetStateAction<number>>;
  unitPrice: number;
  paymentType: PaymentType;
  handleChangePayment: () => void;
}

const Info: FC<{ msg: string }> = ({msg}) => {
  const {label, labelLong} = useStyles();
  return (
    <Typography
      className={classnames(label, {[labelLong]: msg === 'Wire Transfer'})}
    >
      {msg}
    </Typography>
  );
};

const ImgInfo: FC<{ msg?: string; url?: string }> = ({
                                                       msg = '',
                                                       url = '',
                                                     }) => {
  const {img} = useStyles();
  return (
    <>
      <img src={url} alt={''} className={img}/>
      <Info msg={msg}/>
    </>
  );
};

const PaymentMethods: React.FC<PaymentMethodsProps> = function (
  props
): JSX.Element {
  const classes = useStyles();
  const {isDesktop, sum, getItemCountHandler, onChangeHandler} =
    usePaymentMethodFacade(props);
  const cardInfo = useMemo(() => getCardInfo(props.cardData), [props.cardData]);
  const bankInfo = useMemo(() => getBankInfo(props.bankData), [props.bankData]);

  return (
    <div className={style.container}>
      <h3 className={style.title}>PAYMENT METHOD</h3>

      {/* Payment type */}
      <div className={style.content}>
        {props.paymentType === PaymentType.CARD && (
          <ImgInfo
            url={cardInfo?.url}
            msg={`ending in ${cardInfo?.numberEnd}`}
          />
        )}
        {props.paymentType === PaymentType.BANK && (
          <Info msg={`Bank acc ending in ${bankInfo?.numberEnd}`}/>
        )}
        {props.paymentType === PaymentType.WIRE && <Info msg="Wire Transfer"/>}
        <button
          onClick={props.handleChangePayment}
          className={style.linkBtn}
        >
          CHANGE
        </button>
      </div>

      {/* QTY input */}
      <div className={style.content}>
        <h6>QUANTITY</h6>
        <Qty
          count={props.itemsCount}
          onDecClick={getItemCountHandler('dec')}
          onIncClick={getItemCountHandler('inc')}
          onChange={onChangeHandler}
        />
      </div>

      {/* SUM */}
      <div className={style.content}>
        <h6>TOTAL VALUE</h6>
        <h4>${sum}</h4>
      </div>
    </div>
  );
};

export default PaymentMethods;
