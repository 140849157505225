import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ControledField } from 'pages/Accreditation/components/AccreditationForm/components/ControledField';
import { CustomButton } from 'components/UIComponents/Button';
import ControlledDropdownSeparate from 'components/simpleComponents/ControlledDropdownSeparate/ControlledDropdown';

import stateCodes from 'utils/states';

import { AddressGuestProps, ContactsGuestProps } from '../types';

import { COUNTRIES, USA, UK } from './constants';
import { FormProps } from './types';
import styles from './AddressForm.module.scss';

type AddressFormProps = {
  setGuest: React.Dispatch<
    React.SetStateAction<
      ContactsGuestProps | AddressGuestProps | FormProps | null
    >
  >;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  currentStep: number;
  firstGuest?: ContactsGuestProps | AddressGuestProps | null;
  secondGuest?: ContactsGuestProps | AddressGuestProps | null;
};

const AddressForm = ({
  setGuest,
  currentStep,
  setCurrentStep,
  firstGuest,
}: AddressFormProps) => {
  const [selectedDropdown, setSelectedDropdown] = useState('');

  function changeSelectedDropdown(name: string) {
    name === selectedDropdown
      ? setSelectedDropdown('')
      : setSelectedDropdown(name);
  }

  const addressFormSchema = yup.object().shape({
    address: yup.string().trim().required('This field is required'),
    city: yup.string().trim().required('This field is required'),
    isStateRequired: yup.boolean(),
    isPostCodeRequired: yup.boolean(),
    state: yup.string().when('isStateRequired', {
      is: true,
      then: () => yup.string().required('This field is required!'),
      otherwise: () => yup.string().notRequired(),
    }),
    country: yup.string().trim().required('This field is required'),
    title_company_name: yup.string().trim().required('This field is required'),
    accessibility_needs: yup.string().trim(),
    postcode: yup.string().when('isPostCodeRequired', {
      is: true,
      then: () => yup.string().required('This field is required!'),
      otherwise: () => yup.string().notRequired(),
    }),
  });

  const states = stateCodes.map(({ label, id }) => ({
    label,
    value: id,
  }));

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(addressFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      address: '',
      city: '',
      state: '',
      country: '',
      title_company_name: '',
      accessibility_needs: '',
      postcode: '',
      isStateRequired: true,
      isPostCodeRequired: false,
    },
  });

  function onSubmit(data: FormProps) {
    let finalData = { ...data };
    delete finalData.isPostCodeRequired;
    delete finalData.isStateRequired;

    if (firstGuest && 'tripOption' in firstGuest) {
      setGuest((prevState) => ({
        ...prevState,
        ...finalData,
      }));

      if (currentStep === 2) {
        setCurrentStep((prev) => prev + 1);
        return;
      }
      setCurrentStep((prev) => prev + 1);
    }
  }

  const pickedCountry = getValues('country');

  useEffect(() => {
    if (pickedCountry === UK) {
      setValue('isPostCodeRequired', true);
      setValue('isStateRequired', false);
    } else if (pickedCountry === USA) {
      setValue('isPostCodeRequired', false);
      setValue('isStateRequired', true);
    }
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputsWrapper}>
        <ControledField
          placeholder="ADDRESS"
          control={control}
          variant="white"
          name="address"
          isError={!!errors.address}
          errorMessage={errors.address?.message}
        />
        <ControledField
          placeholder="CITY"
          control={control}
          variant="white"
          name="city"
          isError={!!errors.city}
          errorMessage={errors.city?.message}
        />
        {pickedCountry === UK ? (
          <ControledField
            placeholder="POST CODE"
            control={control}
            variant="white"
            name="postcode"
            isError={!!errors.postcode}
            errorMessage={errors.postcode?.message}
          />
        ) : (
          <ControlledDropdownSeparate
            selectedDropdown={selectedDropdown}
            changeSelectedDropdown={changeSelectedDropdown}
            borderColor="black"
            arrowColor="black"
            control={control}
            dropdownOptions={states}
            placeholder="STATE"
            name="state"
            initValue={{
              label: 'STATE',
              value: 'STATE',
            }}
            isError={!!errors.state}
            errorMessage={errors.state?.message}
            maxHeight="200px"
          />
        )}
        <ControlledDropdownSeparate
          selectedDropdown={selectedDropdown}
          changeSelectedDropdown={changeSelectedDropdown}
          borderColor="black"
          arrowColor="black"
          control={control}
          dropdownOptions={COUNTRIES}
          placeholder="COUNTRY"
          name="country"
          initValue={{
            label: 'COUNTRY',
            value: 'COUNTRY',
          }}
          isError={!!errors.country}
          errorMessage={errors.country?.message}
          maxHeight="250px"
        />
        <ControledField
          placeholder="COMPANY NAME"
          control={control}
          variant="white"
          name="title_company_name"
          isError={!!errors.title_company_name}
          errorMessage={errors.title_company_name?.message}
        />
        <ControledField
          placeholder="ACCESSIBILITY NEEDS"
          control={control}
          variant="white"
          name="accessibility_needs"
          isError={!!errors.accessibility_needs}
          errorMessage={errors.accessibility_needs?.message}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <CustomButton size="long" btnStyle="secondary" type="submit">
          Next step
        </CustomButton>
      </div>
    </form>
  );
};

export default AddressForm;
