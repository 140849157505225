import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../../../utils/theme";

export const useTabletLandscapeStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      nameTitleStyles: {
        fontSize: "24px",
      },
      dateOnMarkStyle: {
        fontSize: "18px",
        marginBottom: "5px",
      },
      boldText: {
        fontSize: "16px",
      },
      reserveShrBtn: {
        fontSize: "14px",
        height: "38px",
        width: "134px",
        padding: "1px 10px",
      },
      image: {
        height: "40px",
        width: "40px",
      },
      popoverPaper: {
        maxWidth: "290px",
      },
      popoverText: {
        fontSize: "15px",
      },
    })
  )
);
