import ReactGA from "react-ga";
import React, { useEffect } from "react";

interface AddressEmptyFormProps {
    increaseStep: () => void;
}

const AddressEmptyForm: React.FC<AddressEmptyFormProps> = function (props): JSX.Element {
    useEffect(() => props.increaseStep(), []);

    return (<></>)
};

export default AddressEmptyForm;