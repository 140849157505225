import axios from 'axios';

import { STEPS } from 'pages/Accreditation/components/AccreditationForm/AccreditationForm.data';

import { FormACH } from 'pages/Accreditation/components/AccreditationForm/components/BankDetailsForm/types';

export type ProfileData = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  citizenshipStatus: string;
  phoneNumber: string;
  flowStep: STEPS;
  sharesQty: number;
  allowedOneShare: boolean;
};

export type AccreditationData = {
  identityNumber?: string;
  identityType?: string;
};

export type GetEnvelopeSignUrlResponse = {
  signUrl: string;
};

export type UserData = {
  selfAttestation?: string[];
  sharesQty: number;
  socialSecurityNumber: string;
  dob: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  createdIpAddress: string;
  streetAddressOptional?: string;
  firstName: string;
  lastName: string;
};

export type UserDataNonResident = {
  selfAttestation?: string[];
  sharesQty: number;
  dob: string;
  streetAddress: string;
  city: string;
  zip: string;
  country: string;
  createdIpAddress: string;
  firstName: string;
  lastName: string;
};

const baseurl = process.env.REACT_APP_BACKEND_BASE_URL || '';

export const lookupByGuid = async (guid: string) => {
  const res = await axios.get<ProfileData>(`${baseurl}/v1/subdoc/${guid}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
    },
  });

  return res.data;
};

export const sendAccreditationData = async (
  guid: string,
  body: AccreditationData
) => {
  return axios.post(`${baseurl}/v1/subdoc/${guid}/accreditation`, body, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
    },
  });
};

export const sendSelfAttestationData = async (
  guid: string,
  items: string[]
) => {
  const res = await axios.post(
    `${baseurl}/v1/subdoc/${guid}/fill-self-attestation`,
    {
      selfAttestation: items,
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
      },
    }
  );

  return res.data;
};

export const sendAccreditationRequest = async (guid: string) => {
  return axios.get(
    `${baseurl}/v1/subdoc/send-accreditation-verification/${guid}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
      },
    }
  );
};

export const getEnvelopeSignUrl = async (guid: string) => {
  const res = await axios.get<GetEnvelopeSignUrlResponse>(
    `${baseurl}/v1/subdoc/get-envelope-sign-url/${guid}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
      },
    }
  );

  return res.data;
};

export const uploadAccreditationFile = async (guid: string, file: File) => {
  const formData = new FormData();
  formData.append('accreditationDocument', file);

  const res = await axios.post(
    `${baseurl}/v1/aws/upload-accreditation-docs/${guid}`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ACCREDITATION_UPLOAD_KEY}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return res.data;
};

export const sendUserDataToInitiateSubdocFlow = async (
  guid: string,
  body: UserData | UserDataNonResident
) => {
  const response = await axios.post(
    `${baseurl}/v1/subdoc/${guid}/initiate-subdoc-flow`,
    body,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
      },
    }
  );

  return response?.data;
};

export const getPdfPreview = async (guid: string) => {
  const res = await axios.get(`${baseurl}/v1/subdoc/${guid}/get-pdf-preview`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
    },
    responseType: 'arraybuffer',
  });

  return res.data;
};

export const signPdfDocument = (guid: string) => {
  return axios.get(`${baseurl}/v1/subdoc/${guid}/sign`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
    },
  });
};

export const initiateAchPaymentFlow = async (
  guid: string,
  body: FormACH | {}
) => {
  const res = await axios.post(
    `${baseurl}/v1/subdoc/${guid}/initiate-ach-payment-flow`,
    body,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
      },
    }
  );

  return res.data;
};

export const initiateCardPaymentFlow = async (
  guid: string,
  signal: AbortSignal
) => {
  const res = await axios.get(
    `${baseurl}/v1/subdoc/${guid}/initiate-cc-payment-flow`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
      },
      signal: signal,
    }
  );

  return res.data;
};

export const attachAchPlaid = async (guid: string) => {
  const res = await axios.get(`${baseurl}/v1/subdoc/${guid}/attach-ach-plaid`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
    },
  });

  return res.data;
};

export const finalizeAchPlaid = async (guid: string) => {
  const res = await axios.get(
    `${baseurl}/v1/subdoc/${guid}/finalize-ach-plaid`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
      },
    }
  );

  return res.data;
};

export const finalizeCardPaymentFlow = async (guid: string) => {
  const res = await axios.get(
    `${baseurl}/v1/subdoc/${guid}/finalize-cc-payment-flow`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
      },
    }
  );

  return res.data;
};

export const uploadIdentityFile = async (
  guid: string,
  file1: File,
  file2: File | null | undefined
) => {
  const formData = new FormData();
  formData.append('identity1', file1);

  if (file2) {
    formData.append('identity2', file2);
  }

  const res = await axios.post(
    `${baseurl}/v1/subdoc/${guid}/upload-identity-docs`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SUBDOC_TOKEN}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return res.data;
};
