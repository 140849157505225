import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC, useState } from "react";
import { BankAccountData, CreditCardData } from "../../../../../types/Account";
import { PaymentType } from "../../../../../types/enums/PaymentType";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { PaymentMode } from "../../../../../types/enums/PaymentMode";
import { Preloader } from "../../../../../components/simpleComponents/Preloader";
import { WireInstructions } from "./components/WireInstructions";
import { Offering } from "../../../../../types/Offerings";
import { useStyles } from "./styles";

import useGetGisplayType from "../../../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../../../types/enums/DisplayTypes";

interface ChangePaymentModalProps {
  isLoading: boolean;
  isOpen: boolean;
  paymentType: PaymentType;
  bankAccountData: BankAccountData | undefined;
  creditCardData: CreditCardData | undefined;
  onClose: (selected: PaymentType) => void;
  onEdit: Function;
  offering: Offering;
}

type CardTypes = "MC" | "VI" | "DI" | "AMEX" | "DIN";

const cardTypes: Record<CardTypes, string> = {
  MC: "Master Card",
  VI: "Visa",
  DI: "Discover",
  DIN: "Dinners",
  AMEX: "Amex",
};

const getCardType = (id: CardTypes): string => {
  return id && cardTypes[id] ? cardTypes[id] : "";
};

const Label: FC<{ label: string; numberEnd: string }> = ({
  label,
  numberEnd,
}) => {
  return (
    <>
      <Typography>{label}</Typography>
      <Typography>{numberEnd}</Typography>
    </>
  );
};

export const ChangePaymentModal: FC<ChangePaymentModalProps> = ({
  isLoading,
  isOpen,
  onClose,
  onEdit,
  paymentType,
  creditCardData,
  bankAccountData,
  offering,
}) => {
  const classes = useStyles();
  const isDesktop = !useGetGisplayType(DisplayTypes.Mobile);

  const [method, setMethod] = useState<PaymentType>(paymentType);
  const [isInstructionsModalActive, setIsInstructionsModalActive] =
    useState(false);
  const handleChangeMethod = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setMethod(value as PaymentType);
  };
  const handleClose = () => {
    onClose(method);
  };
  const getShowInstructionHandler = (type: boolean) => () => {
    setIsInstructionsModalActive(type);
  };

  return isInstructionsModalActive ? (
    <WireInstructions
      show={isInstructionsModalActive}
      onClose={getShowInstructionHandler(false)}
      offering={offering}
    />
  ) : (
    <Modal
      open={isOpen && !isInstructionsModalActive}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        className={classes.paper}
        style={isDesktop ? { padding: "50px" } : { padding: "15px" }}
      >
        {isLoading ? (
          <Preloader />
        ) : (
          <>
            <Box className={classes.headerBox}>
              <Typography className={classes.title}>
                Select Payment Method
              </Typography>
              <Button onClick={handleClose} className={classes.closeBtn}>
                <CloseIcon sx={{ color: "#FFFFFF" }} />
              </Button>
            </Box>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={method}
              name="radio-buttons-group"
              onChange={handleChangeMethod}
            >
              <Grid container className={classes.container}>
                <Grid item sm={4} xs={8}>
                  <FormControlLabel
                    value={PaymentType.WIRE}
                    control={<Radio />}
                    label="Wire transfer"
                  />
                </Grid>
                {isDesktop && (
                  <Grid item sm={4} xs={4}>
                    <Typography>Click to see instructions</Typography>
                  </Grid>
                )}
                <Grid item sm={4} xs={4} sx={{ textAlign: "center" }}>
                  <Button onClick={getShowInstructionHandler(true)}>
                    INSTRUCTIONS
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={classes.container}>
                <Grid item sm={4} xs={8}>
                  <FormControlLabel
                    value={PaymentType.BANK}
                    control={<Radio disabled={!Boolean(bankAccountData)} />}
                    label={
                      <Label
                        label="Bank account"
                        numberEnd={bankAccountData?.AccountNumber || ""}
                      />
                    }
                  />
                </Grid>
                {isDesktop && (
                  <Grid item sm={4} xs={4}>
                    <Typography>Banking</Typography>
                  </Grid>
                )}
                <Grid item sm={4} xs={4} sx={{ textAlign: "center" }}>
                  <Button onClick={onEdit(PaymentType.BANK)}>EDIT</Button>
                </Grid>
              </Grid>
              <Grid container className={classes.lastContainer}>
                <Grid item sm={4} xs={8}>
                  <FormControlLabel
                    value={PaymentType.CARD}
                    control={<Radio disabled={!Boolean(creditCardData)} />}
                    label={
                      <Label
                        label={"Credit card"}
                        numberEnd={creditCardData?.creditCardNumber || ""}
                      />
                    }
                  />
                </Grid>
                {isDesktop && (
                  <Grid item sm={4} xs={4}>
                    <Typography>
                      {getCardType(creditCardData?.cardType as CardTypes)}
                    </Typography>
                  </Grid>
                )}
                <Grid item sm={4} xs={4} sx={{ textAlign: "center" }}>
                  <Button onClick={onEdit(PaymentMode.CARD)}>EDIT</Button>
                </Grid>
              </Grid>
            </RadioGroup>
            <Button
              onClick={onEdit(PaymentMode.NEW)}
              className={classes.addBtn}
            >
              <AddCircleIcon color="primary" sx={{ marginRight: "10px" }} />
              Add Payment method
            </Button>
          </>
        )}
      </Paper>
    </Modal>
  );
};
