import { useState, CSSProperties } from "react";
import { styles } from "./styles";

type ToggleSwitchProps = {
  handleClick?: () => void;
  firstButtonText?: string;
  secondButtonText?: string;
};

const ToggleSwitch = ({
  handleClick = () => {},
  firstButtonText = " CREDIT CARD",
  secondButtonText = "BANK ACCOUNT",
}: ToggleSwitchProps) => {
  const [selectedOption, setSelectedOption] = useState("CREDIT CARD");

  const sliderStyle: CSSProperties = {
    ...styles.slider,
    left: selectedOption === "CREDIT CARD" ? "0%" : "50%",
  };

  const getOptionStyle = (option: string): CSSProperties => ({
    ...styles.toggleOption,
    ...(selectedOption === option
      ? {
          ...styles.selected,
          ...(option === "CREDIT CARD"
            ? styles.rightRadius
            : styles.leftRadius),
        }
      : {}),
  });

  return (
    <div style={styles.toggleContainer}>
      <div style={sliderStyle} />
      <button
        style={getOptionStyle("CREDIT CARD")}
        onClick={() => {
          handleClick();
          setSelectedOption("CREDIT CARD");
        }}
      >
        {firstButtonText}
      </button>
      <button
        style={getOptionStyle("BANK ACCOUNT")}
        onClick={() => {
          handleClick();
          setSelectedOption("BANK ACCOUNT");
        }}
      >
        {secondButtonText}
      </button>
    </div>
  );
};

export default ToggleSwitch;
