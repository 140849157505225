const CheckboxIconWhiteChecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="11.5" stroke="white" />
      <circle cx="12" cy="12" r="5.5" fill="white" stroke="white" />
    </svg>
  );
};

export default CheckboxIconWhiteChecked;
