import { Box } from "@mui/system";
import { useState } from "react";
import { Button, Typography } from "@mui/material";

import CheckboxIconWhiteNotChecked from "assets/icons/CheckboxIconWhiteNotChecked";
import CheckboxIconWhiteChecked from "assets/icons/CheckboxIconWhiteChecked";

import { EFTDisclosure } from "./EFTDisclosure";

type TermOfUseCheckboxProps = {
  onChange: (value: boolean) => void;
  alternativeText?: string;
};

export const TermOfUseCheckbox = ({
  onChange,
  alternativeText = "",
}: TermOfUseCheckboxProps) => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showDisclosure, setShowDisclosure] = useState(false);

  const onTermsAgreedChanged = () => {
    onChange(!termsAgreed);
    setTermsAgreed(!termsAgreed);
  };

  return (
    <Box
      style={{
        marginTop: "17px",
        display: "flex",
      }}
    >
      <Box style={{ display: "flex", alignSelf: "center", padding: "0 10px" }}>
        {termsAgreed ? (
          <div onClick={onTermsAgreedChanged}>
            <CheckboxIconWhiteChecked />
          </div>
        ) : (
          <div onClick={onTermsAgreedChanged}>
            <CheckboxIconWhiteNotChecked />
          </div>
        )}
      </Box>
      {!!alternativeText ? (
        <p style={{ color: "white", fontSize: "14px", marginLeft: "5px" }}>
          {alternativeText}
        </p>
      ) : (
        <Box>
          <Typography
            component="h6"
            align="left"
            sx={{
              color: "#ffffff",
              marginLeft: "5px",
              fontSize: "12px",
            }}
          >
            By checking this box, you authorize this transaction and agree to
            our{" "}
            <a href="/termsOfService" target="_blank">
              Terms of Service
            </a>{" "}
            &{" "}
            <Button
              variant="text"
              size="small"
              sx={{
                fontSize: "12px",
                padding: 0,
                minWidth: 0,
              }}
              onClick={() => {
                setShowDisclosure(true);
              }}
            >
              EFT Disclosure
            </Button>{" "}
          </Typography>
        </Box>
      )}
      <EFTDisclosure
        show={showDisclosure}
        onClose={() => {
          setShowDisclosure(false);
        }}
      />
    </Box>
  );
};

export default TermOfUseCheckbox;
