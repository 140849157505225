import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        progressBarContainer: {
          marginBottom: "19px",
        },
        nameTitleBox: {
          marginBottom: "8px",
        },
        nameTitle: {
          fontSize: "30px",
        },
        infoItem: {
          marginBottom: "5px",
        },
        infoItemTitle: {
          fontSize: "20px",
        },
        hrElem: {
          margin: "19px 0 17px",
        },
        amountInfoItemTitle: {
          fontSize: "20px",
        },
        amountInfoItemValue: {
          fontSize: "20px",
        },
        btnStack: {
          justifyContent: "center",
        },
        soldOutBtn: {
          fontSize: "18px",
          padding: "1px 10px",
          height: "46px",
        },
        browseAssetsBtn: {
          fontSize: "18px",
          padding: "1px 10px",
          height: "46px",
        },
        soldOutBox: {
          justifyContent: "center",
        },
        popoverTriangle: {
          position: "relative",
          "&::before": {
            backgroundColor: "#DFDFE3 !important",
            content: '""',
            display: "block",
            position: "absolute",
            width: 30,
            height: 30,
            left: "calc(35% + 19px)",
            transform: "rotate(45deg)",
            top: -85,
            zIndex: "1",
          },
        },
        buySharesBtn: {
          height: "46px",
          width: "143px",
          fontSize: "18px",
        },
        feeIcon: {
          height: "46px",
          width: "46px",
        },
      })
    )
);
