import { Box, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";

import { CustomButton } from "components/UIComponents/Button";
import BaseLayout from "layout/BaseLayout/baseLayout";
import DigitalItinerary from "./components/DigitalItinerary/DigitalItinerary";
import TripDetails from "./components/TripDetails/TripDetails";
import { RoyalAscotModal } from "./components/RoyalAscotModal/RoyalAscotModal";

import { classes } from "./styles";
import stylesTiara from "./styles.module.scss";
import { MAIN_URL, ROUTES } from "utils/constants";

const RoyalAscot = () => {
  const isMobile = useMediaQuery("(max-width: 575px)");
  const isTablet = useMediaQuery("(max-width: 880px)");
  const isWideScreen = useMediaQuery("(min-width:2440px)");

  const [isModalVisible, setIsModalVisible] = useState(false);

  function changeModalVisible() {
    setIsModalVisible((prev) => !prev);
  }

  return (
    <BaseLayout
      pageName="Royal Ascot page"
      pageUri={`${MAIN_URL}${ROUTES.ROYAL_ASCOT_SIGN_UP}`}
    >
      <Box
        sx={classes.heroContainer(isTablet, isMobile)}
        style={{ overflow: "hidden" }}
      >
        <Typography sx={classes.title(isMobile)}>
          ROYAL Ascot:
          <br />A 4-DAY LUXURY Experience IN ENGLAND
        </Typography>
        {isTablet && <Box sx={classes.line} />}
        <iframe
          title="Royal Ascot"
          src="https://player.vimeo.com/video/930645449?h=17ad83c8c3&byline=0&autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1"
          width="3840"
          height="2160"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            overflow: "hidden",
            overflowX: "hidden",
            overflowY: "hidden",
            zIndex: 1,
            height: isTablet ? "100%" : "",
            width: isTablet ? "" : "100%",
            position: "absolute",
            top: isWideScreen ? "-50%" : isTablet ? "0px" : "-850px",
          }}
        ></iframe>
      </Box>
      <Box
        sx={classes.bodyWrapper(isTablet)}
        style={{
          zIndex: 11,
          position: "relative",
          paddingBottom: "80px",
        }}
      >
        <Box sx={classes.bodyContainer(isTablet)} style={{ gap: "0px" }}>
          <div className={stylesTiara.textsWrapper}>
            <Typography
              sx={{
                ...classes.text(isMobile),
                fontFamily: "Futura PT Light",
                maxWidth: isMobile ? "500px" : "660px",
                marginBottom: "22px",
              }}
            >
              Embark with LUXUS on our first luxury investment journey to
              England this summer, where you'll dive into the splendor of
              English high society.
            </Typography>
            <Typography
              sx={{
                ...classes.text(isMobile),
                fontFamily: "Futura PT Light",
                marginBottom: "50px",
                maxWidth: isMobile ? "500px" : "668px",
              }}
            >
              Experience the epitome of elegance and refinement, from the
              historic strings of Stradivarius to the timeless treasures of
              Garrard jewelers. Cap off your adventure with a day at the Royal
              Ascot, where the thrill of horse racing meets the pinnacle of
              British sophistication.
              <br />
            </Typography>
            <CustomButton onClick={changeModalVisible} btnStyle="primaryLarge">
              Sign Up Here
            </CustomButton>
          </div>
        </Box>
        <TripDetails />
      </Box>
      <DigitalItinerary />
      <RoyalAscotModal
        changeVisible={changeModalVisible}
        isModalVisible={isModalVisible}
      />
    </BaseLayout>
  );
};

export default RoyalAscot;
