export const commonStyles = {
  container: {
    display: "flex",
    placeContent: "center",
    padding: "120px 0 127px 0",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  questionTitle: {
    textAlign: "center",
    color: "#000000",
    fontSize: "28px",
  },
  askText: {
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "15px",
    fontWeight: "300 !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  popover: {
    paddingBottom: "10px",
  },
  popoverPaper: {
    backgroundColor: "#D9D9D9 !important",
    maxWidth: "400px",
    padding: "15px",
    position: "relative",
    overflow: "visible",
  },
  popoverTriangle: {
    position: "relative",
    mt: "10px",
    "&::before": {
      backgroundColor: "#D9D9D9 !important",
      content: '""',
      display: "block",
      position: "absolute",
      width: 30,
      height: 30,
      top: 0,
      transform: "rotate(45deg)",
      left: "calc(50% - 15px)",
      zIndex: "1",
    },
  },
  description: {
    textAlign: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "20px",
    fontWeight: "300",
    marginTop: "15px",
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "10px",
  },
  backBtn: {
    fontSize: "20px!important",
    fontWeight: "300!important",
    marginRight: "15px",
    padding: "6px 30px",
  },
  nextBtn: {
    fontSize: "20px!important",
    fontWeight: "300!important",
    padding: "6px 30px",
  },
  skip: {
    textAlign: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "15px",
    fontWeight: "300",
    cursor: "pointer",
    textDecoration: "underline",
  },
  progressBarRoot: {
    backgroundColor: "#ececec !important",
    height: "18px !important",
  },
  progressBarOne: {
    backgroundColor: "#7b7b8f !important",
  },
};

export type ClassesNames = keyof typeof commonStyles;
