import { forwardRef } from "react";
import { Controller, Control } from "react-hook-form";

import { PhoneDropDownComponent } from "components/simpleComponents/PhoneDropDown/PhoneDropDown";

import styles from "./ControlledPhoneDropDown.module.scss";

type ControlledDropdownProps = {
  name: string;
  placeholder: string;
  control: Control<any>;
  isError: boolean;
  errorMessage?: string;
  placeholderColor?: string;
};

const ControlledPhoneDropDown = forwardRef<
  HTMLDivElement,
  ControlledDropdownProps
>(
  (
    { name, isError, errorMessage, placeholder, control, placeholderColor },
    ref
  ) => {
    return (
      <div>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            return (
              <PhoneDropDownComponent.White
                buttonType="button"
                placeholderColor={placeholderColor}
                value={value}
                onChange={(value) => onChange(value)}
                placeholder={placeholder}
                borderColor={isError ? "#FF0000" : "#7B7B8F"}
              />
            );
          }}
        />
        {isError && <span className={styles.errorMessage}>{errorMessage}</span>}
      </div>
    );
  }
);

ControlledPhoneDropDown.displayName = "ControlledPhoneDropDown";

export default ControlledPhoneDropDown;
