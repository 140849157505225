import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../utils/theme";

export const useTabletLandscapeStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      content: {
        margin: "70px auto",
        width: "769px",
        "& .MuiGrid-container": {
          "& .MuiGrid-item": {
            "&:first-child": {
              paddingLeft: "0",
            },
          },
        },
      },
      imgBox: {
        width: "348px",
        margin: "10px 0",
      },
      title: {
        fontSize: "33px",
      },
      tipTitle: {
        fontSize: "30px",
      },
      tipText: {
        fontSize: "23px",
      },
    })
  )
);
