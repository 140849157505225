import { useNavigate } from "react-router-dom";

import LuxusBigLogo from "assets/icons/LuxusBigLogo";
import Tiara from "../../../../../../assets/images/tiaraImg.png";
import GarrardBigLogoHomePage from "assets/icons/GarrardBigLogoHomePage";

import { CustomButton } from "../../../../../../components/UIComponents/Button";

import styles from "./styles.module.scss";

export const FirstVariant = () => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 575;

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.garrardLogoContainer}>
            <GarrardBigLogoHomePage />
          </div>
          <p className={styles.title}>
            What if you could own a piece of history in the making?
          </p>
          <p className={styles.text}>
            {isMobile
              ? "Accredited investors now have the opportunity to invest in a private offering of a Garrard Tiara designed exclusively for LUXUS"
              : "Your opportunity to invest in a private offering of a Garrard Tiara designed exclusively for LUXUS awaits."}
          </p>
          <CustomButton
            btnStyle={"secondaryBlack"}
            onClick={() => {
              navigate("/tiara");
            }}
          >
            INVEST NOW
          </CustomButton>
          <p className={styles.ps}>For Accredited Investors Only</p>
          <div className={styles.luxusLogoContainer}>
            <LuxusBigLogo />
          </div>
          <p className={styles.commentMobile}>
            Queen Mary's Fringe Tiara made by Garrard is for illustrative
            purposes only. It is not The Tiara that will be offered for
            investment.
          </p>
        </div>
        <div>
          <img src={Tiara} alt={"Tiare"} className={styles.image} />
          <p className={styles.commentDesktop}>
            Queen Mary's Fringe Tiara made by Garrard is for illustrative
            purposes only.
            <br /> It is not The Tiara that will be offered for investment.
          </p>
        </div>
      </div>
    </div>
  );
};
