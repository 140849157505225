import { Autocomplete, SelectChangeEvent, TextField } from "@mui/material";
import classnames from "classnames";
import { FC } from "react";
import { countries } from "../../../utils/CoutriesList";
import { useStyles } from "./styles";

export const CountrySelect: FC<{
  value: string;
  onChange?: (val: string | undefined) => void;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: boolean;
  height?: string;
  helperText?: string;
}> = (props) => {
  const { container } = useStyles();
  const handleChange = (
    e: any,
    val: {
      label: string;
      value: string;
    } | null
  ) => {
    props.onChange && props.onChange(val?.value);
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      className={classnames(container, props.className)}
      options={[...countries, { value: "", label: "" }]}
      onChange={handleChange}
      value={countries.find((country) => country.value === props.value) || null}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          error={props.error}
          style={{ height: props.height, border: "none" }}
          helperText={props.helperText}
        />
      )}
    />
  );
};
