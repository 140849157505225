import themeData from "utils/theme";

export const styles = {
  button: {
    backgroundColor: themeData.BlackColor,
    borderRadius: "50px",
    height: "37px",
    color: themeData.WhiteColor,
    padding: "10px 20px",
    zIndex: 1,
    border: "none",
  },
  whiteButton: {
    backgroundColor: themeData.WhiteColor,
    borderRadius: "50px",
    height: "37px",
    color: themeData.BlackColor,
    padding: "10px 20px",
    zIndex: 1,
    border: "none",
  },
};
