import { FC } from "react";
import { ButtonProps, ButtonType } from "./Button.type";

const ButtonComponent: FC<ButtonProps> = ({
  onClick,
  value,
  theme,
  width,
  disabled,
  styles,
}) => {
  return (
    <button
      disabled={disabled}
      style={{
        borderRadius: "50px",
        width: width ? width : "140px",
        height: "41px",
        textDecoration: "none",
        color: disabled ? "white" : theme === "white" ? "#000" : "#fff",
        backgroundColor: disabled
          ? "transparent"
          : theme === "white"
          ? "#fff"
          : "#000",
        borderColor: disabled ? "white" : "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ...styles,
      }}
      onClick={onClick}
    >
      {value}
    </button>
  );
};

export const Button = {
  Black: (props: ButtonType) => (
    <ButtonComponent
      onClick={props.onClick}
      value={props.value}
      width={props.width}
      theme={"black"}
      disabled={props.disabled}
      styles={props.styles}
    />
  ),
  White: (props: ButtonType) => (
    <ButtonComponent
      onClick={props.onClick}
      value={props.value}
      width={props.width}
      theme={"white"}
      disabled={props.disabled}
      styles={props.styles}
    />
  ),
};
