import { PayloadAction } from "@reduxjs/toolkit";
import {
  LegalEntityDefaultState,
  LegalEntityProfile,
} from "pages/Profile/AddLegalEntity/CompleteLegalEntity/useAccreditationStatusFormFacade";
import {
  BeneficialOwner,
  NewCompliance,
  NewUserProfileForm,
} from "pages/Profile/ConfigureProfile/ConfigureForm/ConfigureForm.type";
import {
  beneficialOwnerData,
  complianceData,
  fileContentValue,
  profileData,
} from "pages/Profile/ConfigureProfile/ConfigureForm/defaultFormValues";
import { CreateUserResonse } from "types/Account";
import { CreateLegalEntityAccountResponse } from "types/LegalEntityAccount";

export interface ConfigureProfileState {
  user: CreateUserResonse | undefined;
  userAccWasCreated: boolean;
  profile: NewUserProfileForm;
  compliance: NewCompliance;
  entityAccWasCreated: boolean;
  fileContent: typeof fileContentValue;
  legalEntityProfile: LegalEntityProfile;
  beneficialOwner: BeneficialOwner;
  legalAccount: {
    accountId: string;
    legalEntityAccResponse: CreateLegalEntityAccountResponse;
  };
}

export const initialState: ConfigureProfileState = {
  user: undefined,
  userAccWasCreated: false,
  profile: profileData,
  compliance: complianceData,
  entityAccWasCreated: false,
  fileContent: fileContentValue,
  legalEntityProfile: LegalEntityDefaultState,
  beneficialOwner: beneficialOwnerData,
  legalAccount: {
    accountId: "",
    legalEntityAccResponse: {} as CreateLegalEntityAccountResponse,
  },
};

export type setFileContentPayload = PayloadAction<{
  type: "front" | "back" | "passport";
  content: string;
  name: string;
}>;
