import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () => createStyles(mixStyleRules(commonStyles, {
      paperFullScreen: {
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "97.5%",
          maxWidth: "100%",
          height: "92%",
          transform: "translate(-50%, -50%)",
          overflowX: "hidden",
          overflowY: "scroll",
          padding: "43px 23px 23px 23px",
          backgroundColor: "#F4F4F4",
          borderRadius: "5px",
      },
      textArea: {
          width: "100%",
          height: "800px",
      },
      closeBtn: {
          right: "0",
      }
  }))
);
