import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { CustomButton } from "components/UIComponents/Button";
import ArrowBottom from "assets/icons/HeaderArrowBottom";
import ArrowTop from "assets/icons/HeaderArrowTop";
import AssetsItemExpand from "./AssetsItemExpand";

import { OfferingTrades } from "types/Trade";
import formatNumber from "utils/FormatNumber";

import styles from "./AssetsItem.module.scss";

type AssetsItemProps = {
  share: OfferingTrades;
};

const AssetsItem = ({ share }: AssetsItemProps) => {
  const [isExpandInformation, setIsExpendInformation] = useState(false);

  const navigate = useNavigate();

  const showExpandInformationCondition =
    isExpandInformation && share?.trades && share.trades?.length > 0;

  const handleExpandButton = () => {
    setIsExpendInformation((prevState) => !prevState);
  };

  const handleViewAssetButton = () => {
    navigate(`/assetDetails/${share.offeringId}`);
  };

  const formattedAmount = formatNumber(share.totalAmount.toString());

  return (
    <li className={styles.itemWrapper}>
      <div>
        <div className={styles.listItemWrapper}>
          <div className={styles.imageNameWrapper}>
            <img
              src={share.imageUrl}
              alt={share.offering.name}
              className={styles.image}
            />
            <span className={styles.text}>{share.offering.name}</span>
          </div>
          <span className={styles.text}>${formattedAmount}</span>
        </div>
        <div className={styles.buttonWrapper}>
          <CustomButton btnStyle="secondary" onClick={handleViewAssetButton}>
            <span className={styles.buttonTextAsset}>VIEW ASSET</span>
          </CustomButton>
          <CustomButton>
            <div
              className={styles.buttonTextWrapper}
              onClick={handleExpandButton}
            >
              <span className={styles.buttonTextDetails}>DETAILS</span>
              {isExpandInformation ? (
                <ArrowTop color="white" />
              ) : (
                <ArrowBottom color="white" />
              )}
            </div>
          </CustomButton>
        </div>
      </div>
      <AnimatePresence>
        {showExpandInformationCondition ? (
          <motion.ul
            className={styles.expandedList}
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: isExpandInformation ? 1 : 0,
              height: isExpandInformation ? "auto" : 0,
            }}
            transition={{
              duration: 0.5,
              opacity: { delay: 0.1 },
            }}
            exit={{ opacity: 0, height: 0 }}
          >
            {share.trades?.map((trade) => (
              <AssetsItemExpand
                key={trade.orderId}
                date={trade.purchaseDate}
                amount={trade.totalAmount}
                status={trade.orderStatus}
              />
            ))}
          </motion.ul>
        ) : null}
      </AnimatePresence>
    </li>
  );
};

export default AssetsItem;
