import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import InputMask from "./InputMask/InputMask";
import { CustomButton } from "components/UIComponents/Button";

import { STEPS } from "../AccreditationForm.data";
import { FormValues } from "./InputMask/InputMask";

import style from "./style.module.scss";

type SsnFormProps = {
  setStep: (step: STEPS) => void;
  setSsnNumber: (ssn: string) => void;
};

const schema = yup
  .object({
    ssn: yup
      .string()
      .required("This field is required!")
      .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN format is XXX-XX-XXXX"),
    birthDate: yup.string().optional(),
  })
  .required();

const SsnForm = ({ setStep, setSsnNumber }: SsnFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver<FormValues>(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const onSubmit = ({ ssn }: FormValues) => {
    setStep(STEPS.ATTESTATIONS_FORM);

    ssn && setSsnNumber(ssn);
  };

  return (
    <div className={style.content}>
      <h2 className={style.title}>THE TIARA OFFERING</h2>
      <p className={style.text}>
        Why are we asking for the social security number?
        <br />
        Since your investment account will be kept at a regulated broker-dealer,
        this information is required. Don’t worry- it will be kept secured,
        encrypted and confidential.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="ssn"
          control={control}
          render={({ field }) => (
            <InputMask
              isHiddenChars
              mask="999-99-9999"
              placeholder="SSN"
              register={register}
              isError={!!errors.ssn}
              errorMessage={errors.ssn?.message}
              {...field}
            />
          )}
        />
        <div className={style.center} style={{ marginTop: "48px" }}>
          <CustomButton type="submit" btnStyle="secondaryBlack">
            CONTINUE
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

export default SsnForm;
