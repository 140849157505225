import LuxusLogoNoTextWhite from 'assets/icons/LuxusLogoNoTextWhite';

import styles from './Hero.module.scss';

const Hero = () => {
  return (
    <section className={styles.heroContainer}>
      <div className={styles.internalContainer}>
        <LuxusLogoNoTextWhite />
        <h1 className={styles.heroTitle}>Hardwicke Stakes 2024</h1>
        <h2 className={styles.heroText}>LIVE STREAM</h2>
        <p className={styles.heroText}>
          Hardwicke Stakes was established in 1879 and is the final race of the
          annual Royal Ascot event. LUXUS is live streaming this year’s race on
          June 22, featuring our inaugural thoroughbred offering, Missed The
          Cut. The 5 year old stallion was trained at Lane’s End by John W.
          Sadler and already has over half a million dollars in winnings.
        </p>
      </div>
    </section>
  );
};

export default Hero;
