import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        alignItems: "center",
        width: "200px",
        paddingBottom: "0",
      },
      dash: {
        margin: "0 4px",
        height: "20px",
      },
      firstField: {
        width: "38px",
        "& .MuiInputBase-input": {
          height: "44px !important",
          padding: "0 2px",
          fontSize: "13px",
          "&::placeholder": {
            fontSize: "13px",
            letterSpacing: "0",
          },
          "&[type=password]": {
            fontSize: "13px",
            letterSpacing: "-5px",
          },
        },
      },
      secondField: {
        width: "38px",
        "& .MuiInputBase-input": {
          height: "44px !important",
          padding: "0 2px",
          fontSize: "13px",
          "&::placeholder": {
            fontSize: "13px",
            letterSpacing: "0",
          },
          "&[type=password]": {
            fontSize: "13px",
            letterSpacing: "-5px",
          },
        },
      },
      thirdField: {
        width: "61px",
        "& .MuiInputBase-input": {
          height: "44px !important",
          padding: "0 2px",
          fontSize: "13px",
          "&::placeholder": {
            fontSize: "13px",
            letterSpacing: "0",
          },
          "&[type=password]": {
            fontSize: "13px",
            letterSpacing: "-5px",
          },
        },
      },
      iconBtn: {
        height: "20px",
        widhtL: "20px",
      },
    })
  )
);
