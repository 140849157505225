type TreeLineSmallProps = {
  width?: string;
};

const TreeLineSmall = ({ width = "70%" }: TreeLineSmallProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="95"
      viewBox="0 0 201 95"
      fill="none"
      style={{ width }}
    >
      <path
        d="M99.6581 1.12489C100.244 0.5391 101.194 0.5391 101.779 1.12489L111.325 10.6708C111.911 11.2566 111.911 12.2064 111.325 12.7921C110.74 13.3779 109.79 13.3779 109.204 12.7921L100.719 4.30687L92.2335 12.7921C91.6477 13.3779 90.6979 13.3779 90.1121 12.7921C89.5264 12.2064 89.5264 11.2566 90.1121 10.6708L99.6581 1.12489ZM200.988 93.1571C200.988 93.9855 200.316 94.6571 199.488 94.6571C198.659 94.6571 197.988 93.9855 197.988 93.1571L200.988 93.1571ZM102.219 2.18555L102.219 29.4351L99.2187 29.4351L99.2188 2.18555L102.219 2.18555ZM116.314 43.5302L183.893 43.5302L183.893 46.5302L116.314 46.5302L116.314 43.5302ZM200.988 60.6253L200.988 93.1571L197.988 93.1571L197.988 60.6253L200.988 60.6253ZM183.893 43.5302C193.334 43.5302 200.988 51.1839 200.988 60.6253L197.988 60.6253C197.988 52.8408 191.677 46.5302 183.893 46.5302L183.893 43.5302ZM102.219 29.4351C102.219 37.2196 108.529 43.5302 116.314 43.5302L116.314 46.5302C106.872 46.5302 99.2187 38.8764 99.2187 29.4351L102.219 29.4351Z"
        fill="#7B7B8F"
      />
      <path
        d="M101.779 1.12489C101.194 0.5391 100.244 0.5391 99.6581 1.12489L90.1121 10.6708C89.5264 11.2566 89.5264 12.2064 90.1121 12.7921C90.6979 13.3779 91.6477 13.3779 92.2335 12.7921L100.719 4.30687L109.204 12.7921C109.79 13.3779 110.74 13.3779 111.325 12.7921C111.911 12.2064 111.911 11.2566 111.325 10.6708L101.779 1.12489ZM0.449673 93.1571C0.449673 93.9855 1.12124 94.6571 1.94967 94.6571C2.7781 94.6571 3.44967 93.9855 3.44967 93.1571L0.449673 93.1571ZM99.2188 2.18555L99.2187 29.4351L102.219 29.4351L102.219 2.18555L99.2188 2.18555ZM85.1236 43.5302L17.5448 43.5302L17.5448 46.5302L85.1236 46.5302L85.1236 43.5302ZM0.449674 60.6253L0.449673 93.1571L3.44967 93.1571L3.44967 60.6253L0.449674 60.6253ZM17.5448 43.5302C8.10342 43.5302 0.449674 51.1839 0.449674 60.6253L3.44967 60.6253C3.44967 52.8408 9.76027 46.5302 17.5448 46.5302L17.5448 43.5302ZM99.2187 29.4351C99.2187 37.2196 92.9081 43.5302 85.1236 43.5302L85.1236 46.5302C94.565 46.5302 102.219 38.8764 102.219 29.4351L99.2187 29.4351Z"
        fill="#7B7B8F"
      />
    </svg>
  );
};

export default TreeLineSmall;
