import {
  Box,
  Divider,
  Popover,
  Stack,
  SxProps,
  Tab,
  Tabs
} from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { themeData } from "../../../../../../../utils/theme";

import { SectionData } from "../../useAssetOtherInfoFacade";

import TabPanel from "../TabPanel/TabPanel";

import useInfoPanelFacade from "./useInfoPanelFacade";

import style from './style.module.scss';
import { useStyles } from "./styles/";

function a11yProps(
  index: number,
  isActive: boolean,
  classes: ReturnType<typeof useStyles>
) {
  const sx: SxProps = {
    backgroundColor: isActive ? "#F2F2F0" : "white",
    border: "2px solid #F2F2F0",
    borderBottom: 'none',
    color: themeData.BlackColor,
  };
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx,
    classes: {
      selected: classes.assetDetailInfoPanelTabSelected,
    },
  };
}

const ItemPopover: FC<{ popoverAnchor: HTMLElement | null }> = ({
                                                                  popoverAnchor,
                                                                }) => {
  const classes = useStyles();
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const popoverContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Box ref={popoverContainerRef}>
      <Popover
        container={popoverContainerRef.current}
        id="mouse-over-popover"
        open={Boolean(popoverAnchor)}
        // open={true}
        anchorEl={popoverAnchor}
        disableRestoreFocus
        sx={{
          pointerEvents: "none",
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        PaperProps={{
          ref: popoverRef,
          style: {
            display: "flex",
            flexDirection: "row-reverse",
            flexShrink: 0,
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            maxWidth: "520px",
          },
        }}
      >
        <Box
          className={classes.popoverText}
          dangerouslySetInnerHTML={{
            __html:
              "<b>Environmental, Social, and Governance (ESG)</b> is meant to serve as way to score the “collective conscientiousness” within a company or industry. The initiative was technically structured as an investment criterion for responsible investing formalised by the United Nations about two decades ago, however, recently it has been used less formally to describe general “responsibility” and “sustainability” efforts by corporations and industries as the concept has grown in popularity. (GJEPC)",
          }}
        />
        <Box className={classes.popoverAnchor}/>
      </Popover>
    </Box>
  );
};

const SectionDataElements: FC<{
  data: SectionData[];
  isDesktop: boolean;
}> = ({data, isDesktop}) => {
  const classes = useStyles();
  const [popoverAnchor, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  return (
    <div>
      {data.map(({label, value}) => (
        <div key={label}>
          <hr className={style.line}/>
          <div>
            {label === "ESG ⓘ" ? (
              <div className={style.datailsItem}>
                <h4 className={style.label}>{label.slice(0, -1)}</h4>
                <h4 className={style.value}>{value}</h4>
              </div>
            ) : (
              <div className={style.datailsItem}>
                <h4 className={style.label}>{label}</h4>
                <h4 className={style.value}>{value}</h4>
              </div>
            )}
            {/* {label === "ESG ⓘ" ? (
                <>
                  <Button
                    className={classes.popoverBtnText}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    {label}
                  </Button>
                  <ItemPopover popoverAnchor={popoverAnchor} />{" "}
                </>
              ) : (
                label
              )}*/}
          </div>
        </div>
      ))}
    </div>
  );
};

interface InfoPanelProps {
  firstSectionData: SectionData[];
  secondSectionData: SectionData[];
  isReadMoreClick: boolean;
  setIsReadMoreClick: React.Dispatch<React.SetStateAction<boolean>>;
  desc: string;
  videoSrc?: string;
  videoPreviewSrc?: string;
}

const InfoPanel: React.FC<InfoPanelProps> = function ({
                                                        firstSectionData,
                                                        secondSectionData,
                                                        isReadMoreClick,
                                                        setIsReadMoreClick,
                                                        desc,
                                                        videoSrc,
                                                        videoPreviewSrc,
                                                      }): JSX.Element {
  const classes = useStyles();
  const [videoRef, onVideoClick, isDesktop] = useInfoPanelFacade();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return isDesktop ? (
    <div className={style.container}>
      <div className={style.description}>
        <h3>DESCRIPTION</h3>
        <p
          className={style.text}
          dangerouslySetInnerHTML={{
            __html: desc.replaceAll(
              "\n\n",
              `<br class='${classes.textBr}'/>`
            ),
          }}
        />
      </div>
      <div>
        <div className={style.details}>
          <h3 className={style.title}>DETAILS</h3>
          <SectionDataElements
            data={firstSectionData}
            isDesktop={isDesktop}
          />
          <SectionDataElements
            data={secondSectionData}
            isDesktop={isDesktop}
          />
        </div>
        <div className={style.details}>
          <h3 className={style.title}>FEES</h3>
          <p>Management Fee: 75 bps</p>
          <p className={style.fee}>Performance Fee: 20% of Net Profits (8% hurdle)</p>
        </div>
      </div>
    </div>
  ) : (
    <>
      {/* Tabs */
      }
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          classes={{
            indicator: classes.assetDetailInfoPanelTabsIndicator,
          }}
          variant={isDesktop ? "standard" : "fullWidth"}
          sx={{marginTop: '24px'}}
        >
          <Tab
            className={style.tab}
            label="DESCRIPTION"
            {...a11yProps(1, value === 0, classes)}
          />
          <Tab
            className={style.tab}
            label="DETAILS"
            {...a11yProps(0, value === 1, classes)}
          />
        </Tabs>
      </div>

      {/*  Panels*/}
      <TabPanel value={value} index={0}>
        <Box className={classes.container}>
          <div>
            {/*{videoSrc && videoPreviewSrc - закомментировано && (
              <Box
                sx={{
                  display: "inline-block",
                  position: "relative",
                  maxWidth: "353px",
                  float: "right",
                  margin: "0 0 13px 30px",
                }}
              >
                <video
                  controls
                  ref={videoRef}
                  onClick={onVideoClick}
                  src={videoSrc}
                  poster={videoPreviewSrc}
                  style={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                  }}
                />
              </Box>
            )}*/}
            <p
              className={style.text}
              dangerouslySetInnerHTML={{
                __html: desc.replaceAll(
                  "\n\n",
                  `<br class='${classes.textBr}'/>`
                ),
              }}
            />
          </div>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box className={classes.container}>
          <Box className={classes.content}>
            <Stack
              direction={{xs: "column", md: "row"}}
              divider={<Divider orientation="vertical" flexItem/>}
              spacing={{xs: 0, md: 4}}
              width="100%"
            >
              <SectionDataElements
                data={firstSectionData}
                isDesktop={isDesktop}
              />
              <SectionDataElements
                data={secondSectionData}
                isDesktop={isDesktop}
              />
            </Stack>
          </Box>
        </Box>
      </TabPanel>
      <div className={style.details}>
        <h3 className={style.title}>FEES</h3>
        <p>Management Fee: 75 bps</p>
        <p className={style.fee}>Performance Fee: 20% of Net Profits (8% hurdle)</p>
      </div>
    </>
  )
    ;
};

export default InfoPanel;
