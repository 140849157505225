import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
export const StyledBlackTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    height: "38px",
    width: "100%",
    color: "#fff",
  },
  "&.Mui-focused fieldset": {
    borderColor: "white",
  },
  "& .MuiInputBase-root input": {
    fontSize: 14,
    paddingLeft: "20px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      borderRadius: "50px",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
}));
