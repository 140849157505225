import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import themeData from 'utils/theme'
export const StyledBlackTextField = styled(TextField)<{
  themes: string
  error: boolean
}>(({ themes, error }) => ({
  '& .MuiInputBase-root': {
    paddingLeft: '5px',
    borderRadius: '50px',
    paddingRight: '0',
    height: '38px',
    fontSize: 14,
    backgroundColor: 'transparent',
    color: themes === 'white' ? themeData.BlackColor : themeData.WhiteColor,
    outline: 'none',
    border: `1px solid ${
      error ? 'red' : themes === 'white' ? themeData.BlackColor : '#7B7B8F'
    }`,
    letterSpacing: '0.7px',

    '&.Mui-focused fieldset': {
      borderColor:
        themes === 'white' || error ? 'transparent' : themeData.WhiteColor,
    },
  },
  '& .MuiInputBase-input:-webkit-autofill': {
    transition: 'background-color 600000s 0s, color 600000s 0s',
  },
}))
