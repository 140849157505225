import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useDesktopStyles: (props?: any) => Classes = makeStyles(() =>
  createStyles(mixStyleRules(commonStyles, {
      buttonsBox: {
          "& button:first-child": {
              "marginRight": "7px",
          }
      }
  }))
);
