import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    placeContent: "center",
    padding: "120px 0 127px 0",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    color: "#000000",
    fontSize: "28px",
    marginBottom: "20px",
  },
  paymentBox: {
    display: "grid",
    gridTemplateColumns: "20% 20% 50% 10%",
    alignItems: "baseline",
    justifyContent: "center",
    justifyItems: "center",
    borderBottom: "1px solid #4E4E56",
    margin: "15px",
    width: "100%",
  },
  paymentName: {
    textAlign: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "18px",
    fontWeight: "300",
    marginRight: "56px",
    height: "100%",
    width: "100%",
  },
  paymentType: {
    textAlign: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "18px",
    fontWeight: "300",
    marginRight: "77px",
  },
  paymentNumber: {
    textAlign: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "18px",
    fontWeight: "300",
    marginRight: "138px",
    width: "fit-content",
  },
  paymentOperandBox: {
    display: "flex",
    flexDirection: "row",
  },
  addPaymentBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "15px",
    marginLeft: "30px",
  },
  addPaymentBtn: {
    display: "flex",
    placeContent: "center",
    height: "50px",
    width: "50px",
    border: "1.5px solid #7B7B8F",
    borderRadius: "40px !important",
    backgroundColor: "#D9D9D9",
    color: "#0F0F0F",
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "14px",
    fontFamily: themeData.DefaultFont,
    "&:hover": {
      background: "#5a6268",
    },
  },
  addPaymentBtnTitle: {
    align: "center",
    color: "#0F0F0F",
    fontSize: "15px",
    fontWeight: "300",
    whiteSpace: "nowrap",
    alignSelf: "center",
    marginLeft: "10px",
  },
  doneBtn: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    display: "block",
    textTransform: "none",
    fontWeight: "normal",
    margin: "0 auto",
    fontSize: "14px",
    justifySelf: "center",
    fontFamily: themeData.DefaultFont,
    "&:hover": {
      background: "#5a6268",
    },
  },
};

export type ClassesNames = keyof typeof commonStyles;
