import { Box, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { ContainerStyles, ParagraphStyle, TitleStyle } from "./style";
import DiamondLayout from "layout/DiamondLayout/DiamondLayout";

const WelcomePage: FC = () => {
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const next = () => {
    navigate("form");
  };
  return (
    <DiamondLayout
      additionalStyles={{ height: isMobileScreen ? "100%" : "100vh" }}
    >
      <Box sx={ContainerStyles(isMobileScreen)}>
        <Typography sx={TitleStyle}>TELL US ABOUT YOURSELF</Typography>
        <Typography sx={ParagraphStyle}>
          Before you get started, let’s find out a little about your
          accreditation status.
        </Typography>
        <Typography sx={ParagraphStyle}>
          The next series of questions are securities law requirements that must
          be answered. Don’t worry, all answers will be encrypted and secure.
        </Typography>
        <Button.White value="CONTINUE" onClick={next} />
      </Box>
    </DiamondLayout>
  );
};

export default WelcomePage;
