import React, { FC, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import { styles } from "./style";
import { CounterProps } from "./Counter.type";

const Counter: FC<CounterProps> = ({
  initialValue = 0,
  inputColor = "#000000",
  borderColor = "#F2F2F0",
  handleOtherCountChange = () => {},
}) => {
  const [count, setCount] = useState(initialValue);

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setCount(newValue);
    }
  };

  useEffect(() => {
    handleOtherCountChange(count);
  }, [count, handleOtherCountChange]);

  return (
    <Box
      display="flex"
      alignItems="center"
      overflow="hidden"
      border={`1px solid ${borderColor}`}
      borderRadius="50px"
      width="fit-content"
      maxHeight="38px"
    >
      <Button sx={styles.leftButton} onClick={handleDecrement}>
        -
      </Button>
      <TextField
        variant="outlined"
        value={count}
        onChange={handleInputChange}
        type="text"
        sx={{
          mx: 2,
          width: "60px",
          height: "40px",
          margin: 0,
          ".MuiInputBase-input": {
            height: "20px",
            padding: "10px",
            textAlign: "center",
            color: inputColor,
          },
        }}
      />
      <Button sx={styles.rightButton} onClick={handleIncrement}>
        +
      </Button>
    </Box>
  );
};

export default Counter;
