/* eslint-disable react-hooks/rules-of-hooks */
import { useServerAPI } from '../apis';

export default async function refreshSession(
  api: ReturnType<typeof useServerAPI>
): Promise<void> {
  const response = await api.refreshSession();

  localStorage.setItem(
    'fullUserName',
    response.data.response.sessionPayload.name
  );
  if (response.data?.response?.sessionPayload?.verifyStatus)
    localStorage.setItem(
      'verifyStatus',
      response.data?.response?.sessionPayload?.verifyStatus
    );
  if (
    response.data?.response?.sessionPayload?.name &&
    response.data?.response?.sessionPayload?.name.length > 0
  )
    localStorage.setItem(
      'userName',
      response.data?.response?.sessionPayload?.name
    );
  if (response.data?.response?.sessionPayload?.firstName)
    localStorage.setItem(
      'userFirstName',
      response.data?.response?.sessionPayload?.firstName
    );
  if (response.data?.response?.sessionPayload?.lastName)
    localStorage.setItem(
      'userLastName',
      response.data?.response?.sessionPayload?.lastName
    );
  if (response.data?.response?.sessionPayload?.completedFinQuestions)
    localStorage.setItem(
      'completedFinQuestions',
      response.data?.response?.sessionPayload?.completedFinQuestions.toString()
    );
  const config = await api.getSystemConfig();
  console.debug(`System Config:`, config);
  localStorage.setItem('SystemConfig', JSON.stringify(config));
}
