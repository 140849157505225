import { makeStyles, createStyles } from "@mui/styles";

export const useStyle = makeStyles(() =>
  createStyles({
    leftCornerTitleBoxClass: {
      backgroundColor: "#7B7B8F",
      borderRadius: "6px",
      color: "#FFFFFF",
      fontSize: "25px",
      fontWeight: "600",
    },
  })
);
