import { useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { useMediaQuery } from "@mui/material";

import { CustomButton } from "components/UIComponents/Button";

import styles from "./PdfViewer.module.scss";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

type PdfViewerProps = {
  pdfPreview: string | undefined;
};

const PdfViewer = ({ pdfPreview }: PdfViewerProps) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(22);

  const isMobileScreen = useMediaQuery("(max-width:575px)");
  const isTabletScreen = useMediaQuery(
    "(min-width:576px) and (max-width:992px)"
  );
  const isDesktopScreen = useMediaQuery(
    "(min-width:993px) and (max-width:1849px)"
  );

  function useDocumentWidth() {
    if (isMobileScreen) {
      return 300;
    }
    if (isTabletScreen) {
      return 450;
    }
    if (isDesktopScreen) {
      return 900;
    }
    return 950;
  }

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function handleDecreasePage(): void {
    if (pageNumber === 1) return;
    setPageNumber((prev) => prev - 1);
  }

  function handleIncreasePage(): void {
    if (pageNumber === numPages) return;
    setPageNumber((prev) => prev + 1);
  }

  return (
    <div>
      <div className={styles.pageContainer}>
        <Document
          className={styles.documentContainer}
          file={pdfPreview}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            className={styles.pagePdf}
            width={useDocumentWidth()}
            pageNumber={pageNumber}
          />
        </Document>
      </div>
      <p className={styles.comment}>
        For your convenience please see the Signature Page above{" "}
      </p>
      <p className={styles.pageCounter}>
        Page {pageNumber} of {numPages}
      </p>
      <div className={styles.buttonContainer}>
        <CustomButton onClick={handleDecreasePage} btnStyle="secondaryBlack">
          PRIOR PAGE
        </CustomButton>
        <CustomButton onClick={handleIncreasePage} btnStyle="secondaryBlack">
          NEXT PAGE
        </CustomButton>
      </div>
    </div>
  );
};

export default PdfViewer;
