import classNames from "classnames";

import styles from "./PaymentDetailsLine.module.scss";
import { PaymentDetailsLineProps } from "./PaymentDetailsLine";

type UseStylesProps = Pick<
  PaymentDetailsLineProps,
  "isLastItem" | "isFirstItem" | "isExpanded"
>;

const useStyles = ({ isLastItem, isFirstItem, isExpanded }: UseStylesProps) => {
  const wrapperClasses = classNames(styles.wrapper, {
    [styles.lastItem]: isLastItem,
    [styles.firstItem]: isFirstItem,
  });

  const textStyles = classNames(styles.text, {
    [styles.reduceText]: !isExpanded,
  });

  const buttonWrapperStyles = classNames(styles.buttonWrapper, {
    [styles.reduceButtonWrapper]: !isExpanded,
  });

  const buttonStyles = classNames(styles.button, {
    [styles.reduceButton]: !isExpanded,
  });

  return { wrapperClasses, textStyles, buttonWrapperStyles, buttonStyles };
};

export default useStyles;
