import styles from './VideoPlayer.module.scss';

const VideoPlayer = () => {
  return (
    <section className={styles.sectionContainer}>
      <iframe
        className={styles.videoPlayer}
        src="https://www.youtube.com/embed/7K2lWuJtc8U?si=NiCNxrQQxGoLA3FC"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        frameBorder="0"
        style={{
          overflow: 'hidden',
          overflowX: 'hidden',
          overflowY: 'hidden',
          zIndex: 1,
          width: '100%',
        }}
      />
    </section>
  );
};

export default VideoPlayer;
