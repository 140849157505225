import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { stateCodes } from "../../../../../../utils/states";
import { NewUserProfileForm } from "../../../ConfigureProfile";
import ReactGA from "react-ga";

import { useStyles } from "./styles";
import { CountrySelect } from "../../../../../../components/simpleComponents/CountrySelect";

type AdressInfo = Pick<
  NewUserProfileForm,
  "primAddress1" | "primState" | "primCity" | "primZip" | "primCountry"
>;

interface AdressInfoProps extends AdressInfo {
  getPropertySetter<T extends keyof NewUserProfileForm>(
    property: T
  ): (value: NewUserProfileForm[T]) => void;
  profile: NewUserProfileForm;
  primCountry: string,
}

const AdressForm: React.FC<AdressInfoProps> = function ({
  getPropertySetter,
  profile,
  primAddress1,
  primCity,
  primState,
  primZip,
  primCountry,
}): JSX.Element {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/AddressForm");
  }, []);

  const [adressInfo, setAdressInfo] = useState<AdressInfo>({
    primAddress1: primAddress1 || "",
    primCity: primCity || "",
    primState: primState || "",
    primZip: primZip || "",
    primCountry: primCountry || "",
  });

  const [isZipErrorActive, setIsZipErrorActive] = useState<boolean>();

  const getOnInputChange =
    (type: keyof AdressInfo) =>
    (event: React.ChangeEvent | SelectChangeEvent): void => {
      const newAdressInfo = { ...adressInfo };
      newAdressInfo[type] = (
        event.target as HTMLInputElement | HTMLSelectElement
      ).value;
      setAdressInfo(newAdressInfo);
      if (type === "primZip") {
        const zipValue = (
          event.target as HTMLInputElement | HTMLSelectElement
        ).value.replaceAll("-", "");
        if (profile.domicile !== "Non US citizen") {
          if (zipValue.replaceAll(/\d/gi, "").length > 0)
            return setIsZipErrorActive(true);
          const isValid = zipValue.length !== 5 && zipValue.length !== 9;
          setIsZipErrorActive(isValid);
          if (isValid) return getPropertySetter("primZip")("");
        } else return getPropertySetter("primZip")(zipValue);
      }
      getPropertySetter(type)(newAdressInfo[type]);
    };

  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <TextField
          placeholder="Street Address*"
          variant="outlined"
          className={classes.streetField}
          onChange={getOnInputChange("primAddress1")}
          value={adressInfo.primAddress1}
        />
        <TextField
          placeholder="City*"
          variant="outlined"
          className={classes.cityField}
          onChange={getOnInputChange("primCity")}
          value={adressInfo.primCity}
        />
        <Box className={classes.content}>
          <FormControl>
            { (profile.domicile === "U.S. citizen" || profile.domicile === "U.S. resident") ? <Select
              displayEmpty
              id="adress-state-select"
              value={adressInfo.primState}
              onChange={getOnInputChange("primState")}
              className={classes.stateSelect}
            >
              <MenuItem disabled value="">
                <span className={classes.selectPlaceholder}>State*</span>
              </MenuItem>
              {stateCodes.map(({ label, id }) => (
                <MenuItem value={id}>{label}</MenuItem>
              ))}
            </Select> :
                  <CountrySelect
                      value={adressInfo.primCountry}
                      onChange={(val) => {
                        getOnInputChange("primCountry")({
                          //@ts-ignore
                          target: { value: val },
                        });
                      }}
                      placeholder="Country of Registration"
                      className={classes.countrySelect}
                      height="20px"
                  />
            }
          </FormControl>
          <TextField
            placeholder="Zip Code*"
            variant="outlined"
            className={classes.zipCodeField}
            onChange={getOnInputChange("primZip")}
            value={adressInfo.primZip}
            inputProps={{
              maxLength: 10,
            }}
          />
        </Box>
      </Box>
      {isZipErrorActive && (
        <Typography className={classes.errorText}>
          Valid ZIP is required.
        </Typography>
      )}
    </>
  );
};

export default AdressForm;
