import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { themeData } from '../utils/theme';

export type SectionTitleProps = {
  title: string;
  subTitle?: string;
  titleColor: string;
  subTitleColor?: string;
  sx?: SxProps<Theme>;
};

export const SectionTitle = ({
  subTitle,
  subTitleColor,
  title,
  titleColor,
  sx,
}: SectionTitleProps) => {
  return (
    <Box sx={sx}>
      <Typography
        sx={{
          mx: 0,
          marginBottom: '.5rem',
          fontWeight: 250,
          color: titleColor,
          textAlign: 'left',
          fontFamily: themeData.PrimaryFont,
          fontSize: '25px',
        }}
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography
          variant="h2"
          sx={{
            mx: 0,
            fontWeight: 400,
            color: subTitleColor ?? titleColor,
            textAlign: 'left',
            fontSize: '16px',
          }}
        >
          {subTitle}
        </Typography>
      )}
    </Box>
  );
};

export default SectionTitle;
