export const commonStyles = {
  container: {
    display: "flex",
    padding: "200px 0 320px 0",
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "423px",
  },
  title: {
    textAlign: "center",
    color: "#000000",
    height: "35px",
    fontSize: "28px",
  },
  text: {
    textAlign: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "15px",
  },
  linkText: {
    textAlign: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "15px",
  },
  link: {
    color: "black !important",
    textDecorationColor: "black !important",
    cursor: "pointer",
  },
  linkDisabled: {
    color: "#9d9d9d !important",
    textDecorationColor: "#9d9d9d !important",
    cursor: "default",
  },
  delimeter: {
    bottom: 0,
    right: 0,
    width: "100%",
  },
};

export type ClassesNames = keyof typeof commonStyles;
