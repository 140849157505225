import HeaderArrowTop from "assets/icons/HeaderArrowTop";
import AccountDropdown from "../AccountDropdown/AccountDropdown";
import HeaderArrowBottom from "assets/icons/HeaderArrowBottom";

import styles from "./AccountMobileMenu.module.scss";

type AccountMobileMenu = {
  handleAccountOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
  isAccountMenuOpen: boolean;
  handleOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccountMobileMenu: React.FC<AccountMobileMenu> = ({
  handleAccountOpenMenu,
  isAccountMenuOpen,
  handleOpenMenu,
}) => {
  // TODO Additionally check this logic
  const userName =
    localStorage.getItem("fullUserName") === "undefined undefined"
      ? "NAME SURNAME"
      : localStorage.getItem("fullUserName");

  return (
    <>
      {isAccountMenuOpen ? (
        <div className={styles.wrapper}>
          <div
            className={styles.aboutButton}
            onClick={() => handleAccountOpenMenu((prevState) => !prevState)}
          >
            <span>ACCOUNT</span>
            <HeaderArrowTop />
          </div>
          <AccountDropdown name={userName} handleOpenMenu={handleOpenMenu} />
        </div>
      ) : (
        <div
          className={styles.accountButton}
          onClick={() => handleAccountOpenMenu((prevState) => !prevState)}
        >
          <span>ACCOUNT</span>
          <HeaderArrowBottom />
        </div>
      )}
    </>
  );
};

export default AccountMobileMenu;
