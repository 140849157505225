import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../../../utils/theme";

export const useTabletPortraitStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      caption: {
        fontSize: "16px",
      },
      btn: {
        fontSize: "18px",
      },
      container: {
        minWidth: "278px",
      },
      text: {
        fontSize: "16px",
      },
    })
  )
);
