import { themeData } from '../../../../../../../utils/theme';

export const commonStyles = {
  dropZoneBox: {
    background: '#F4F4F4',
    borderRadius: '5px',
    width: '350px',
    height: '200px',
    padding: '13px',
  },
  dropZoneBorder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px`,
    borderRadius: '5px',
  },
  dropZoneText: {
    fontStyle: 'italic',
    fontWeight: '300',
    fontSize: '20px',
    lineHeight: '20px',
  },
  dropZoneBtn: {
    fontStyle: 'italic',
    fontWeight: '300',
    fontSize: '20px',
    color: '#6890FF',
    textDecoration: 'none',
    textTransform: 'none',
    lineHeight: '20px',
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
    fontWeight: '450',
    fontSize: '25px',
    marginBottom: '10px',
  },
  hr: {
    width: '100%',
    marginTop: '40px',
  },
  ul: {
    width: '330px',
    fontWeight: '300',
    fontSize: '20px',
    marginTop: 0,
  },
  text: {
    fontWeight: '300',
    fontSize: '20px',
  },
  recomendationContainer: {
    marginBottom: '70px',
    marginTop: '30px',
  },
  fileUploadContainerButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
  },
  fileUploadContainerInput: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1',
  },
  closeIcon: {
    fontSize: '40px',
    color: '#7B7B8F',
    cursor: 'pointer',
  },
  fileName: {
    wordBreak: 'break-all',
    textAlign: 'center',
    fontWeight: '300',
    fontSize: '20px',
    textDecoration: 'none',
    textTransform: 'none',
    lineHeight: '20px',
    margin: '9px',
  },
  icon: {
    fontSize: '70px',
  },
};

export type ClassesNames = keyof typeof commonStyles;
