import { useState, useEffect } from "react";

import { useServerAPI } from "apis";
import { UserProfile } from "types/Account";

import { EditUserDataProps } from "./EditUserData";
import { INITIAL_PASSWORDS } from "./constants";

export type Errors = {
  dob?: string;
  primAddress1?: string;
  primCity?: string;
  primZip?: string;
};

type Passwords = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type PasswordsErrors = {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
};

const useEditUserDataFacade = ({
  setIsEdit,
  profileData,
  fetchProfileData,
  setIsLoading,
}: EditUserDataProps) => {
  const [profileDataState, setProfileDataState] =
    useState<UserProfile>(profileData);
  const [passwords, setPasswords] = useState<Passwords>(INITIAL_PASSWORDS);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordErrors, setPasswordErrors] = useState({});

  const api = useServerAPI();

  const { firstName, lastName, emailAddress } = profileData;

  const userName = firstName + " " + lastName;

  const isPasswordFormEmptyCondition =
    passwords.currentPassword === "" &&
    passwords.newPassword === "" &&
    passwords.confirmPassword === "";

  function validateDate(date: string) {
    const regex = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
    return regex.test(date);
  }

  const validateUserInputsValues = (inputValues: UserProfile) => {
    let errors: Errors = {};
    if (!validateDate(inputValues.dob.trim())) {
      errors.dob = "Invalid date format. Please use MM-DD-YYYY!";
    }
    if (inputValues.primAddress1.trim().length < 1) {
      errors.primAddress1 = "Please add your primary address!";
    }
    if (inputValues.primCity.trim().length < 1) {
      errors.primCity = "Please add your primary city!";
    }
    if (inputValues.primZip.trim().length < 1) {
      errors.primZip = "Please add your primary zip code!";
    }
    return errors;
  };

  const validatePasswords = (passwords: Passwords) => {
    let errors: PasswordsErrors = {};

    if (passwords.currentPassword.trim().length < 3) {
      errors.currentPassword = "Please enter your current password!";
    }
    if (passwords.newPassword.trim().length < 3) {
      errors.newPassword = "Password must be at least 3 characters long!";
    }
    if (passwords.newPassword !== passwords.confirmPassword) {
      errors.confirmPassword = "Passwords do not match!";
    }
    return errors;
  };

  const getProfilePropertySetter = (key: string) => (value: string) => {
    setProfileDataState((previousProfileData) => ({
      ...previousProfileData,
      [key]: value,
    }));
  };

  const getPasswordPropertySetter = (key: string) => (value: string) => {
    setPasswords((previousPasswords) => ({
      ...previousPasswords,
      [key]: value,
    }));
  };

  const handleUserInputsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    getProfilePropertySetter(name)(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    getPasswordPropertySetter(name)(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    isPasswordFormEmptyCondition && setPasswordErrors({});

    setErrors(validateUserInputsValues(profileDataState));

    !isPasswordFormEmptyCondition &&
      setPasswordErrors(validatePasswords(passwords));

    setIsSubmitting(true);
  };

  const finishSubmit = async () => {
    let error = false;

    try {
      if (!isPasswordFormEmptyCondition) {
        const response = await api.changePassword({
          username: emailAddress,
          oldPassword: passwords.currentPassword,
          newPassword: passwords.newPassword,
        });

        if (
          !response.data.response.success &&
          response.data.response.message === "Invalid credentials"
        ) {
          error = true;
          setPasswordErrors((prevPasswordErrors) => ({
            ...prevPasswordErrors,
            currentPassword: "Invalid current password!",
          }));
          throw new Error("Invalid current password!");
        }
      }

      setIsLoading(true);

      await api.updateUserProfile(profileDataState);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);

      if (!error) {
        fetchProfileData();

        setIsEdit(false);
      }
    }
  };

  const submittingCondition =
    Object.keys(errors).length === 0 &&
    Object.keys(passwordErrors).length === 0 &&
    isSubmitting;

  useEffect(() => {
    if (submittingCondition) {
      finishSubmit();
    }
  }, [errors, passwordErrors]);

  return {
    handleSubmit,
    profileDataState,
    getProfilePropertySetter,
    handleUserInputsChange,
    errors,
    userName,
    emailAddress,
    handlePasswordChange,
    passwordErrors,
  };
};

export default useEditUserDataFacade;
