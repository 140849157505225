export const ContainerStyle = (isTabletScreen: boolean) => ({
  backgroundColor: '#030303',
  color: '#fff',
  display: 'flex',
  flexDirection: isTabletScreen ? 'column-reverse' : 'initial',
  //gap: isTabletScreen ? '24px' : '0px',
  height: '100%',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
})

export const ContentContainer = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
}
