import { Icon } from "./types";

export const iconsMobile: Icon[] = [
  {
    id: 1,
    path: "/Icons/hero/forbes.png",
    width: 80,
    height: 21.5,
    alt: "Forbes icon",
    link: "https://www.forbes.com/sites/hanktucker/2022/05/11/new-fintech-makes-diamond-trading-possible-for-retail-investors/?sh=74a6a86e349c",
  },
  {
    id: 2,
    path: "/Icons/hero/business-insider.png",
    width: 80,
    height: 25,
    alt: "Business-insider icon",
    link: "https://www.businessinsider.com/personal-finance/collectible-investments-valuable-types",
  },
  {
    id: 3,
    path: "/Icons/hero/techcrunch.png",
    width: 80,
    height: 12.7,
    alt: "Techcrunch icon",
    link: "https://techcrunch.com/2022/05/11/luxus-crown-jewel-your-portfolio-to-be-diamond-gemstone-luxury-alternative-assets/",
  },
  {
    id: 4,
    path: "/Icons/hero/bloomberg.png",
    width: 80,
    height: 15,
    alt: "Bloomberg icon",
    link: "https://www.bloomberg.com/news/articles/2022-05-12/luxus-brings-fractional-ownership-to-the-fancy-colored-diamond-market",
  },
];

export const iconsDesktop: Icon[] = [
  {
    id: 1,
    path: "/Icons/hero/desktop/financial-times.png",
    width: 110,
    height: 31.5,
    alt: "Financial times icon",
    link: "https://www.ft.com/content/fc735ca5-7fd6-4b70-9c6c-923c95201ad7",
  },
  {
    id: 2,
    path: "Icons/hero/desktop/forbes.png",
    width: 110,
    height: 29.5,
    alt: "Forbes icon",
    link: "https://www.forbes.com/sites/hanktucker/2022/05/11/new-fintech-makes-diamond-trading-possible-for-retail-investors/?sh=74a6a86e349c",
  },
  {
    id: 3,
    path: "/Icons/hero/desktop/business-insider.png",
    width: 110,
    height: 33.9,
    alt: "Business-insider icon",
    link: "https://www.businessinsider.com/personal-finance/collectible-investments-valuable-types",
  },
  {
    id: 4,
    path: "/Icons/hero/desktop/techcrunch.png",
    width: 110,
    height: 17.4,
    alt: "Techcrunch icon",
    link: "https://techcrunch.com/2022/05/11/luxus-crown-jewel-your-portfolio-to-be-diamond-gemstone-luxury-alternative-assets/",
  },
  {
    id: 5,
    path: "/Icons/hero/desktop/bloomberg.png",
    width: 110,
    height: 20.6,
    alt: "Bloomberg icon",
    link: "https://www.bloomberg.com/news/articles/2022-05-12/luxus-brings-fractional-ownership-to-the-fancy-colored-diamond-market",
  },
];
