import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Button, Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

type VerifyingDetailsProps = {
  onComplete?: () => void;
};
const VerifyingDetails = ({ onComplete }: VerifyingDetailsProps) => {
  let navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box
        sx={{ padding: '50px' }}
        display="flex"
        flexDirection="column"
        alignItems={'center'}
      >
        <PersonSearchIcon
          sx={{
            height: '70px',
            width: '70px',
          }}
        />
        <Box sx={{ marginTop: '35px' }}>
          <Typography
            component="p"
            align="center"
            sx={{
              color: '#000000',
            }}
            style={{ fontSize: '28px' }}
          >
            Verifying your information
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '30px',
          }}
        >
          <Typography
            component="p"
            align="center"
            sx={{
              color: '#555555',
            }}
            style={{ fontSize: '16px' }}
          >
            We’re verifying your information. This may take a few hours, you can
            track the status in your profile page after entered into your
            account.
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '30px',
          }}
        >
          <Button
            sx={{
              fontSize: '20px',
              backgroundColor: '#274f85',
              border: '1px solid #274f85',
              color: '#ffffff',
              padding: '8px 5rem',
              borderRadius: '5px',
              '&:hover': {
                background: '#274f85',
              },
            }}
            onClick={() => {
              if (onComplete) {
                onComplete();
                // navigate(thenNavigateTo, {replace: true, state: {success: true}});
                // navigate('/myAccount', {replace: true, state: {success: true}});
              } else {
                navigate(-1);
              }
            }}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyingDetails;
