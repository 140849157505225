export const EyeCloseIcon = ({ color = "white" }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_616_36065)">
      <path
        d="M0.969988 2.6274C0.709988 2.4224 0.332488 2.4699 0.127488 2.7299C-0.0775121 2.9899 -0.0300121 3.3674 0.229988 3.5724L15.03 15.1724C15.29 15.3774 15.6675 15.3299 15.8725 15.0699C16.0775 14.8099 16.03 14.4324 15.77 14.2274L13.14 12.1674C14.13 11.1524 14.8 10.0149 15.1375 9.2074C15.22 9.0099 15.22 8.7899 15.1375 8.5924C14.765 7.6999 13.9825 6.3999 12.8125 5.3149C11.6375 4.2199 10.02 3.2999 7.99999 3.2999C6.29499 3.2999 4.87499 3.9574 3.76749 4.8199L0.969988 2.6274ZM5.57749 6.2374C6.21499 5.6549 7.06749 5.2999 7.99999 5.2999C9.98749 5.2999 11.6 6.9124 11.6 8.8999C11.6 9.5224 11.4425 10.1074 11.165 10.6174L10.2 9.8624C10.41 9.3799 10.465 8.8274 10.32 8.2799C10.0425 7.2424 9.12499 6.5449 8.10499 6.5024C7.95999 6.4974 7.87499 6.6549 7.91999 6.7949C7.97249 6.9549 8.00249 7.1249 8.00249 7.3024C8.00249 7.5574 7.94249 7.7974 7.83749 8.0099L5.57999 6.2399L5.57749 6.2374ZM9.32499 12.2474C8.91499 12.4099 8.46749 12.4999 7.99999 12.4999C6.01249 12.4999 4.39999 10.8874 4.39999 8.8999C4.39999 8.7274 4.41249 8.5599 4.43499 8.3949L2.07749 6.5374C1.50749 7.2799 1.09999 8.0199 0.862488 8.5924C0.779988 8.7899 0.779988 9.0099 0.862488 9.2074C1.23499 10.0999 2.01749 11.3999 3.18749 12.4849C4.36249 13.5799 5.97999 14.4999 7.99999 14.4999C9.19499 14.4999 10.2475 14.1774 11.155 13.6874L9.32499 12.2474Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_616_36065">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
