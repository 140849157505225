import { Box, useMediaQuery } from '@mui/material'
import { ArrowBottomIcon } from 'assets/icons/arrow-bottom'
import { FC } from 'react'
import { classes } from './styles'

export const ImageModal: FC<{
  image: string
  nextImage: () => void
  prevImage: () => void
  setVisible: (visible: boolean) => void
}> = ({ image, nextImage, prevImage, setVisible }) => {
  const isMobileScreen = useMediaQuery('(max-width:776px)')

  return (
    <Box sx={classes.modalWrapper} onClick={() => setVisible(false)}>
      <Box
        sx={classes.modal(isMobileScreen)}
        onClick={e => e.stopPropagation()}
      >
        <Box onClick={nextImage} sx={classes.arrowIcon(90, isMobileScreen)}>
          <ArrowBottomIcon color={'white'} height={'25'} width={'47'} />
        </Box>
        <Box
          component={'img'}
          src={image}
          alt={'modal'}
          sx={{ width: '100%' }}
        />
        <Box onClick={prevImage} sx={classes.arrowIcon(-90, isMobileScreen)}>
          <ArrowBottomIcon color={'white'} height={'25'} width={'47'} />
        </Box>
      </Box>
    </Box>
  )
}
