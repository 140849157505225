import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../utils/theme";

export const useTabletPortraitStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      content: {
        margin: "26px auto 33px",
        width: "377px",
        justifyContent: "center",
      },
      imgBox: {
        width: "376px",
        margin: "0",
      },
      title: {
        fontSize: "31px",
      },
      tipTitle: {
        fontSize: "26px",
      },
      tipText: {
        fontSize: "20px",
      },
    })
  )
);
