import { Box, useMediaQuery } from "@mui/material";
import { CustomButton } from "components/UIComponents/Button";
import { FC, useState } from "react";
import { VipForm } from "./BecomeVipForm";
import { SuccessScreen } from "./SuccessScreen";
import { classes } from "./styles";

export const BecomeVipModal: FC<{
  visible: boolean;
  setModalVisible: (x: boolean) => void;
  isAuth?: boolean;
}> = ({ visible, setModalVisible, isAuth = false }) => {
  const isMobileScreen = useMediaQuery("(max-width:776px)");
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);

  return (
    <Box
      sx={classes.modalWrapper(visible)}
      onClick={() => setModalVisible(false)}
    >
      <Box
        sx={classes.modal(isMobileScreen, visible)}
        onClick={e => e.stopPropagation()}
      >
        <Box sx={classes.btnContainer(isMobileScreen)}>
          <CustomButton
            btnStyle={"iconBtn"}
            onClick={() => setModalVisible(false)}
          >
            CLOSE
          </CustomButton>
        </Box>
        {!isUserSubscribed ? (
          <VipForm
            isUserSubscribed={isUserSubscribed}
            setIsUserSubscribed={setIsUserSubscribed}
            isAuth={isAuth}
          />
        ) : (
          <SuccessScreen isAuth={isAuth} />
        )}
      </Box>
    </Box>
  );
};
