import { ClassNameMap } from "@mui/styles";

import useGetDisplayType from "../../../hooks/useGetDisplayType";
import getClassNameMap from "../../../utils/getClassNameMap";

import type { FooterClasses } from "./common";

import { useDesktopStyles } from "./desktop";
import { useTabletLandscapeStyles } from "./tabletLandscape";
import { useTabletPortraitStyles } from "./tabletPortrait";
import { useMobileStyles } from "./mobile";

export type FooterClassNameMap = ClassNameMap<FooterClasses>;

export const useStyles = (): FooterClassNameMap => {
  return getClassNameMap(
    useGetDisplayType(),
    useDesktopStyles(),
    useMobileStyles(),
    useTabletLandscapeStyles(),
    useTabletPortraitStyles()
  );
};
