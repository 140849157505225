import FamilyNodeSmall from "../FamilyNodeSmall/FamilyNodeSmall";

import styles from "./FamilyTreeSmall.module.scss";

const FamilyTreeSmall = () => {
  return (
    <div className={styles.container}>
      <FamilyNodeSmall
        topImage="/images/TreeNode/1stTree.jpg"
        topName="Missed the Cut"
        firstImage="/images/TreeNode/2ndTree.jpg"
        firstName="Quality Road"
        secondImage="/images/TreeNode/3rdTree.jpg"
        secondName="Beauly"
        gap="100px"
        width="90%"
      />
      <div className={styles.nodesContainer}>
        <FamilyNodeSmall
          firstImage="/images/TreeNode/4thTree.jpg"
          firstName="Elusive Quality"
          secondImage="/images/TreeNode/5thTree.jpg"
          secondName="Kobla"
          gap="20px"
        />
        <FamilyNodeSmall
          firstImage="/images/TreeNode/6thTree.jpg"
          firstName="Sea the Stars"
          secondImage="/images/TreeNode/7thTree.jpg"
          secondName="Pickle"
          gap="20px"
        />
      </div>
    </div>
  );
};

export default FamilyTreeSmall;
