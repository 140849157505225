export const COUPLE_SUCCESS_TEXT =
  'We are thrilled you and your guest will be joining LUXUS for our inaugural experiential investing journey.';

export const SINGLE_SUCCESS_TEXT =
  'We are thrilled you are for joining LUXUS for our inaugural experiential investing journey.';

export const SINGLE_SUCCESS_REDIRECTION_URL =
  'https://events.tixologi.com/buy-tickets/event/5373/tickets?spinup=true&targetOrigin=https%3A%2F%2Fevent.tixologi.com';

export const COUPLE_SUCCESS_REDIRECTION_URL =
  'https://events.tixologi.com/buy-tickets/event/5365/tickets?spinup=true&targetOrigin=https%3A%2F%2Fevent.tixologi.com';

export const SINGLE_ONE_DINNER_SUCCESS_REDIRECTION_URL =
  'https://events.tixologi.com/buy-tickets/event/5420/tickets?spinup=true&targetOrigin=https%3A%2F%2Fevent.tixologi.com';

export const SINGLE_THREE_DINNER_SUCCESS_REDIRECTION_URL =
  'https://events.tixologi.com/buy-tickets/event/5421/tickets?spinup=true&targetOrigin=https%3A%2F%2Fevent.tixologi.com';

export const FORM_SUBMISSION_URL =
  'https://forms.hubspot.com/uploads/form/v2/45463853/e976ca7c-5e9c-4222-9f36-75c04d125ee0';
