import {FC, CSSProperties} from "react";
import classNames from "classnames";
import {Offering} from "../../../../../../types/Offerings";
import styles from './style.module.scss'

import {useStyles} from "./styles/";
import {ButtonStyle, CustomButton} from "../../../../../../components/UIComponents/Button";

export const LinkElement: FC<{
  offer: Offering;
  type?: string;
  name: string;
  style?: CSSProperties;
  btnStyle?: ButtonStyle;
  label?: string;
}> = ({offer, type = "download", name, style, btnStyle, label}) => {
  const {downloadImage, secImage, igiImage, giaImage, fcrfImage} =
    useStyles();

  const item =
    name !== "download"
      ? offer?.assets?.find(
        (asset) => asset.type === type && asset.label.includes(name)
      )
      : offer?.assets?.find(
        (asset) =>
          asset.type === type &&
          !asset.label.includes("GIA") &&
          !asset.label.includes("IGI") &&
          !asset.label.includes("ESG") &&
          !asset.label.includes("FCRF")
      );

  const url =
    type === "secfiling"
      ? "https://www.sec.gov/Archives/edgar/data/1911165/000121465923003678/s1242341apos2.htm"
      : undefined;

  return item ? (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={url ?? item.url}
      style={{textDecoration: "none"}}
      className={styles.link}
    >
      <CustomButton btnStyle={btnStyle}>
        {label}
      </CustomButton>
      {/*<img
        alt=""
        src={item.thumbnail}
        className={classNames(
          { [downloadImage]: name === "download" },
          { [secImage]: name === "SEC" },
          { [giaImage]: name === "GIA" },
          { [igiImage]: name === "IGI" },
          { [fcrfImage]: name === "FCRF" }
        )}
      />*/}
    </a>
  ) : null;
};
