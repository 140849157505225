type StatusMapping = {
  [key: string]: string;
};

export const transformStatus = (
  status: string | object | undefined
): string => {
  const statusMappings: StatusMapping = {
    CREATED: "Pending",
    FUNDED: "Funded",
    "UNWIND PENDING": "Trade Initiated",
    "UNWIND SETTLED": "Trade Completed",
    SETTLED: "Completed",
    CANCELLED: "Cancelled",
  };

  return statusMappings[status as string] || "Unknown";
};
