import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../../utils/theme";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        title: {
          fontSize: "15px",
        },
        text: {
          fontSize: "15px",
          lineHeight: "46px",
        },
        content: {
          padding: "18px 0 34px",
          "& div": {
            "& div": {
              "& div": {
                height: "30px",
              },
            },
          },
        },
        content1: {
          padding: "13px 0 20px",
          fontSize: "15px",
        },
        textBr: {
          height: "15px",
          margin: "0",
        },
        tab: {
          fontSize: "15px",
          lineHeight: "30px",
          padding: "3px 8px",
        },
      })
    )
);
