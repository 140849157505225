import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import ReactGA from "react-ga";

import { AccreditationStatusConfig } from "../../../../../../utils/AccreditationStatusConfig";

import { useStyles } from "./styles";

interface AccreditationStatusFormProps {
  setProfileAccreditationStatus(value: string): void;
  initAccreditationStatus: string;
}

const AccreditationStatusForm: React.FC<AccreditationStatusFormProps> =
  function ({
    setProfileAccreditationStatus,
    initAccreditationStatus,
  }): JSX.Element {
    useEffect(() => {
      ReactGA.pageview(window.location.pathname + "/AccreditationStatusForm");
    }, []);

    const [chosenValue, setChosenValue] = useState(
      initAccreditationStatus ?? ""
    );

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      const currentValues = chosenValue
        .split(",")
        .filter((item) =>
          value !== "notaccredited" ? item !== "notaccredited" : true
        );
      if (value === "notaccredited") {
        const newValue = chosenValue.includes(value) ? "" : "notaccredited";
        setChosenValue(newValue);
        setProfileAccreditationStatus(newValue);
        return;
      }
      let newValue = "";
      if (currentValues.length === 2 && currentValues.includes(value))
        newValue = currentValues.filter((item) => item !== value).join("");

      if (currentValues.length === 1 && currentValues[0] !== "") {
        newValue = currentValues[0] === value ? "" : chosenValue + `,${value}`;
      }

      if (currentValues.length === 1 && currentValues[0] === "")
        newValue = value;

      if (currentValues.length === 0) newValue = value;

      setChosenValue(newValue);
      setProfileAccreditationStatus(newValue);
    };

    const classes = useStyles();

    return (
      <FormControl className={classes.container}>
        <FormGroup>
          {AccreditationStatusConfig.map((status) => (
            <FormControlLabel
              key={status.value}
              label={
                <>
                  <span className={classes.spanBold}>{status.bold}</span>
                  <span className={classes.span}>{status.regular}</span>
                </>
              }
              control={
                <Checkbox
                  color="default"
                  size="medium"
                  checked={chosenValue.split(",").includes(status.value)}
                  onChange={onChange}
                />
              }
              value={status.value}
              className={classes.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  };

export default AccreditationStatusForm;
