import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useServerAPI } from '../../../apis';

import { Offering } from '../../../types/Offerings';

import OfferingNumbersFormatter from '../../../utils/OfferingNumbersFormatter';
import ReplaceOfferImage from '../../../utils/ReplaceOfferImage';
import { SectionData } from '../AssetDetails/components/AssetOtherInfo/useAssetOtherInfoFacade';
import ReactGA from 'react-ga';
import { activeOfferStatuses } from '../../../utils/ActiveOffersStatuses';

import useGetDisplayType from '../../../hooks/useGetDisplayType';
import { DisplayTypes } from '../../../types/enums/DisplayTypes';

export default function useAssetsDetailsFacade(): [
  Offering,
  Offering,
  boolean,
  () => void,
  boolean,
  SectionData[],
  SectionData[],
  boolean,
  () => void,
  () => void,
  boolean
] {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + '/IOIDetails');
  }, []);

  const api = useServerAPI();

  const id = useParams().id;

  const navigate = useNavigate();

  const proceedOffer = (offer: Offering): Offering => {
    return OfferingNumbersFormatter(ReplaceOfferImage([offer])[0]);
  };

  const desktop = useGetDisplayType([
    DisplayTypes.Desktop,
    DisplayTypes.TabletLandscape,
  ]);

  const [activeOffer, setActiveOffer] = useState<Offering>();
  const [similarOffer, setSimilarOffer] = useState<Offering>();
  const [isLoadind, setIsLoading] = useState(false);
  const [notifyDisabled, setNotifyDisabled] = useState<boolean>(false);

  const getSimilarOffer = async (): Promise<void> => {
    const result = await api.getOfferings();
    const similar = result.data.response.items.find(
      (item) => item.status === 'SoldOut' || item.status === 'Active'
    );

    if (similar) setSimilarOffer(proceedOffer(similar));
  };

  const getOfferById = async (): Promise<void> => {
    const result = await api.getOffering(id as string);
    if (result.data.response) {
      setActiveOffer(proceedOffer(result.data.response));
    }
  };

  const getTradesHistory = async (): Promise<void> => {
    const result = await api.getTradesHistory();

    if (result.data.response && id) {
      result.data.response[id]?.offering?.XXstatus === 'IntentToInvest' &&
        setNotifyDisabled(true);
    }
  };

  const getData = async (): Promise<void> => {
    setIsLoading(true);
    await getOfferById();
    await getSimilarOffer();
    await getTradesHistory();
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [id]);

  const onInterestClick = (): void => {
    navigate(`/ioiSubmit/${id}`);
  };

  const firstSectionData: SectionData[] = activeOffer?.details
    ? activeOffer.details
        .slice(0, 5)
        .map(({ label, value }) => ({ label, value }))
    : [];

  const secondSectionData: SectionData[] = activeOffer?.details
    ? activeOffer.details
        .slice(5, 11)
        .map(({ label, value }) => ({ label, value }))
    : [];

  const [isThanks, setIsThanks] = useState(false);

  const handleNotifyClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const result = await api.registerIntentOfInvestment({
        transactionUnits: 1,
        offeringId: Number(id),
        NotifyInLive: true,
      });
      if (result.data.success) {
        setIsThanks(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onBrowseMoreClick = (): void => {
    navigate(`/`);
  };

  return [
    similarOffer as Offering,
    activeOffer as Offering,
    isLoadind,
    onInterestClick,
    desktop,
    firstSectionData,
    secondSectionData,
    isThanks,
    handleNotifyClick,
    onBrowseMoreClick,
    notifyDisabled,
  ];
}
