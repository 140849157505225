import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import { cards } from "./constants";
import "./styles.scss";
import { Card } from "./types";

const SwiperBlock: React.FC = () => {
  return (
    <Swiper
      loop={true}
      slidesPerView={"auto"}
      spaceBetween={16}
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      centeredSlides={true}
      modules={[Autoplay]}
      className="mySwiper"
    >
      <ul className="card-list">
        {cards.map((item: Card, index) => (
          <li key={`${item.id}${index}`}>
            <SwiperSlide key={`${item.id}${index}`}>
              <div className="card-list__item">
                <item.Icon />
                <p className="card-list__text">{item.text}</p>
              </div>
            </SwiperSlide>
          </li>
        ))}
      </ul>
    </Swiper>
  );
};

export default SwiperBlock;
