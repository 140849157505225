import TreeLineBig from "assets/icons/TreeLineBig";

import styles from "./FamilyTree.module.scss";

const BigArrows = () => {
  return (
    <div className={styles.arrowContainer}>
      <div className={styles.arrowWrapper}>
        <div>
          <TreeLineBig />
        </div>
        <div className={styles.mirrored}>
          <TreeLineBig />
        </div>
      </div>
    </div>
  );
};

export default BigArrows;
