import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        preview: {
          height: "266px",
          width: "266px",
          border: "1px solid #DFDFE3",
        },
        thumbs: {
          width: "61px",
          height: "240px",
          "& img": {
            height: "58px",
            width: "58px",
            border: "1px solid #DFDFE3",
          },
        },
      })
    )
);
