import {Box, Grid, Typography} from '@mui/material';
import {FC, useState} from 'react';
import {Button} from '../../../../../components/simpleComponents/Button';
import {Qty} from '../../../../../components/simpleComponents/Qty';
import formatNumber from '../../../../../utils/FormatNumber';
import {useStyles} from './styles/';
import style from './style.module.scss'

interface SubmitBoxProps {
  //onClick: (count: number) => void;
  unitPrice: number;
  count: number
  setCount: (newValue: number) => void;
}

export const SubmitBox: FC<SubmitBoxProps> = ({count, setCount, unitPrice}) => {
  const {container, btn, caption, text, row} = useStyles();
  const setNewValue = (step: number) => () => {
    const newValue = count + step;
    setCount(newValue >= 0 ? newValue : 0);
  };

  return (
    <div className={style.container}>
      <h3>RESERVE SHARES</h3>
      <div className={style.contentBlock}>
        <h6>QUANTITY</h6>
        <Qty
          count={count}
          onDecClick={setNewValue(-1)}
          onIncClick={setNewValue(1)}
          onChange={(e) => setNewValue(parseInt(e.target.value))}
        />
      </div>
      <div className={style.contentBlock}>
        <h6>TOTAL VALUE</h6>
        <h4 className={text}>
          ${formatNumber((count * unitPrice).toString())}
        </h4>
      </div>
    </div>
  );
};
