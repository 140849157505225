import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "85%",
  },
  streetField: {
    width: "314px",
    height: "43px",
    paddingBottom: "15px",
  },
  cityField: {
    width: "314px",
    height: "43px",
  },
  countrySelect: {
    width: "150px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "20px 0",
    width: "314px",
  },
  selectPlaceholder: {
    color: "rgb(148, 148, 148)",
    fontSize: "20px",
  },
  stateSelect: {
    width: "150px",
    height: "52px",
  },
  zipCodeField: {
    width: "150px",
    height: "43px",
  },
  errorText: {
    color: themeData.ErrorTextColor,
    fontSize: "0.75rem",
    lineHeight: "1.66",
    marginTop: "4px",
    marginX: "14px",
    marginBottom: "0px",
  },
};

export type ClassesNames = keyof typeof commonStyles;
