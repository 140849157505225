import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import themeData from '../../../utils/theme';

type PurchaseAgreementCheckboxProps = {
  onChange: (value: boolean) => void;
  documentUrl?: string;
  documentName?: string;
  message?: string;
};

export const PurchaseAgreementCheckbox = ({
  onChange,
  documentUrl,
  documentName,
  message,
}: PurchaseAgreementCheckboxProps) => {
  const [termsAgreed, setTermsAgreed] = useState(false);

  const onTermsAgreedChanged = () => {
    onChange(!termsAgreed);
    setTermsAgreed(!termsAgreed);
  };

  return (
    <Box
      style={{
        marginTop: '17px',
        display: 'flex',
      }}
    >
      <Box>
        {termsAgreed ? (
          <CheckBoxIcon onClick={onTermsAgreedChanged} />
        ) : (
          <CheckBoxOutlineBlankIcon onClick={onTermsAgreedChanged} />
        )}
      </Box>
      <Box>
        <Typography
          component="h6"
          align="left"
          sx={{
            color: '#000000',
            marginLeft: '5px',
            fontSize: '18px',
            fontFamily: themeData.DefaultFontBook,
          }}
        >
          You must check this box to agree to the terms of the Subscription
          Agreement. Click the link to view the agreement, A copy will be
          emailed to you for e-signature to complete the purchase.{' '}
          {/* By checking this box, you agree to this transaction{' '} */}
          <Link
            href={documentUrl}
            target="_blank"
            rel="noreferrer"
            underline="none"
            sx={{
              display: 'inline-flex',
              marginY: 'auto',
              fontSize: '18px',
              textTransform: 'uppercase',
              cursor: 'pointer',
            }}
          >
            {documentName ?? 'Purchase agreement'}
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default PurchaseAgreementCheckbox;
