import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Typography, Popover } from "@mui/material";
import LinearWithValueLabel from "../../../../components/LinearProgressWithLabel";
import BirthdayForm from "./components/BirthdayForm/BirthdayForm";
import AdressForm from "./components/AdressForm/AdressForm";
import PhoneNumberForm from "./components/PhoneNumberForm/PhoneNumberForm";
import CitizenshipStatusForm from "./components/CitizenshipStatusForm/CitizenshipStatusForm";
import SocialSecurityNumberForm from "./components/SocialSecurityNumberForm/SocialSecurityNumberForm";
import AccreditationStatusForm from "./components/AccreditationStatusForm/AccreditationStatusForm";
import AnnualIncomeForm from "./components/AnnualIncomeForm/AnnualIncomeForm";
import FinraLicenseForm from "./components/FinraLicenseForm/FinraLicenseForm";
import DirectorBoardMemberForm from "./components/DirectorBoardMemberForm/DirectorBoardMemberForm";
import CountryForm from "./components/CountryForm/CountryForm";
import { NewUserProfileForm, NewCompliance } from "../ConfigureProfile";
import { CreateUserResonse } from "../../../../types/Account";
import { ipSelector } from "../../../../store/selectors/system";

import useGetDisplayType from "../../../../hooks/useGetDisplayType";

import { DisplayTypes } from "../../../../types/enums/DisplayTypes";
import { useServerAPI } from "../../../../apis";

import { useStyles } from "./styles";

import {
  stepsNonUSCitizenConfig,
  stepsUSResidentConfig,
  stepsUSCitizenConfig,
} from "./config";
import { Button } from "../../../../components/simpleComponents/Button";
import AddressEmptyForm from "./components/AddressEmptyForm/AddressEmptyForm";
import IdForm from "./components/IdForm/IdForm";
import PassportForm from "./components/PassportForm/PassportForm";
import { CountrySelect } from "../../../../components/simpleComponents/CountrySelect";
import { Preloader } from "../../../../components/simpleComponents/Preloader";
import FinraLicenseProfileForm from "./components/FinraLicenseProfileForm/FinraLicenseProfileForm";

interface ICompleteProfile {
  isNextDisableResident(stepNumber: number): boolean;
  isNextDisableCitizen(stepNumber: number): boolean;
  isNextDisableNonResident(stepNumber: number): boolean;
  getProfilePropertySetter: <T extends keyof NewUserProfileForm>(
    key: T
  ) => (value: NewUserProfileForm[T]) => void;
  getCompliancePropertySetter: <T extends keyof NewCompliance>(
    key: T
  ) => (value: NewCompliance[T]) => void;
  close(): void;
  profile: NewUserProfileForm;
  complience: NewCompliance;
}

const CompleteProfile: React.FC<ICompleteProfile> = function ({
  getProfilePropertySetter,
  getCompliancePropertySetter,
  close,
  isNextDisableResident,
  isNextDisableCitizen,
  isNextDisableNonResident,
  profile,
  complience,
}): JSX.Element {
  const desktop = !useGetDisplayType(DisplayTypes.Mobile);
  const displayType = useGetDisplayType();
  const api = useServerAPI();
  const user = useRef<CreateUserResonse>();

  const createdIpAddress = useSelector(ipSelector);

  const [stepNumber, setStepNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const popoverRef = useRef<HTMLDivElement | null>(null);
  const popOverContainer = useRef<HTMLParagraphElement>(null);

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLSpanElement | null>(
    null
  );
  const open = Boolean(popoverAnchor);

  const increaseStep = () => setStepNumber(stepNumber + 1);

  const topDiff: Record<DisplayTypes, number> = {
    desktop: -15,
    mobile: -15,
    tabletLandsape: 0,
    tabletPortrait: 0,
  };

  useEffect(() => {
    setTimeout(() => {
      if (popoverRef.current?.style.top && Boolean(popoverAnchor)) {
        if (popoverRef.current?.style.top) {
          const newTop =
            Number(popoverRef.current?.style.top.replace("px", "")) +
            topDiff[displayType];
          popoverRef.current.style.top = `${newTop}px`;
        }
      }
    }, 0);
  }, [popoverAnchor]);

  // const createUser = async (): Promise<void> => {
  //   // if (validateProfile() && validateCompliance()) {
  //   if (!isHaveProfile)
  //     await api
  //         .createUser({
  //           profileData: profile,
  //           complianceData: complience,
  //         })
  //         .then(() => {
  //           isUserCreated.current = true;
  //         });
  //   else {
  //     await api.updateUserProfile({ ...profile }).then(() => {
  //       isUserCreated.current = true;
  //     });
  //     //}
  //   }
  // };

  const createUser = async (): Promise<void> => {
    // if (validateProfile() && validateCompliance()) {
    setIsLoading(true);
    user.current = await api
      .createUser({
        profileData: profile,
        complianceData: complience,
      })
      .finally(() => setIsLoading(false));
    //}
  };

  const uploadFileForUser = async ({
    documentTitle,
    file_name,
    userfile0,
  }: Record<
    "documentTitle" | "file_name" | "userfile0",
    string
  >): Promise<void> => {
    if (!user.current) return;
    api.uploadFileForTransactApi({
      path: "uploadPartyDocument",
      partyId: user.current.data.response.data.partyId,
      documentTitle,
      file_name,
      userfile0,
      createdIpAddress,
    });
  };

  const classes = useStyles();

  useEffect(() => window.scroll(0, 0), [stepNumber]);

  return isLoading ? (
    <Preloader />
  ) : (
    <>
      <Container maxWidth="sm" className={classes.container}>
        {profile.domicile === "U.S. citizen" ? (
          <Box className={classes.content}>
            <LinearWithValueLabel
              classes={{
                root: classes.progressBarRoot,
                bar1Determinate: classes.progressBarOne,
              }}
              progress={stepsUSCitizenConfig[stepNumber].percent}
            />
            <Typography
              component="h4"
              variant="h6"
              className={classes.questionTitle}
            >
              {stepsUSCitizenConfig[stepNumber].questionTitle}
            </Typography>
            {stepsUSCitizenConfig[stepNumber].popUpMessage && (
              <>
                <Typography
                  ref={popOverContainer}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  component="span"
                  className={classes.askText}
                  onMouseEnter={(event: React.MouseEvent) =>
                    setPopoverAnchor(event.currentTarget as HTMLSpanElement)
                  }
                  onClick={(event: React.MouseEvent) => {
                    if (desktop) return setPopoverAnchor(null);
                    setPopoverAnchor(
                      popoverAnchor
                        ? null
                        : (event.currentTarget as HTMLSpanElement)
                    );
                  }}
                >
                  {stepsUSCitizenConfig[stepNumber].popUpLinkLabel ??
                    "Why do we ask for this?"}
                  <Popover
                    container={popOverContainer.current}
                    id="mouse-over-popover"
                    open={Boolean(popoverAnchor)}
                    anchorEl={popoverAnchor}
                    disableRestoreFocus
                    onMouseLeave={() => setPopoverAnchor(null)}
                    className={classes.popover}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      ref: popoverRef,
                      className: classes.popoverPaper,
                    }}
                  >
                    {stepsUSCitizenConfig[stepNumber].popUpMessage}
                    <Box className={classes.popoverTriangle} />
                  </Popover>
                </Typography>
              </>
            )}
            {stepsUSCitizenConfig[stepNumber].description && (
              <Typography className={classes.description} component="span">
                {stepsUSCitizenConfig[stepNumber].description}
              </Typography>
            )}

            {stepNumber === 0 && (
              <BirthdayForm
                birthday={profile.dob}
                setDate={getProfilePropertySetter("dob")}
              />
            )}
            {stepNumber === 1 && (
              <CitizenshipStatusForm
                setProfileDomicile={getProfilePropertySetter("domicile")}
                initCitizenshipStatus={profile.domicile}
              />
            )}
            {stepNumber === 2 && (
              <AdressForm
                getPropertySetter={getProfilePropertySetter}
                profile={profile}
                primAddress1={profile.primAddress1}
                primCity={profile.primCity}
                primState={profile.primState}
                primZip={profile.primZip}
                primCountry={profile.primCountry}
              />
            )}
            {stepNumber === 3 && (
              <AddressEmptyForm increaseStep={increaseStep} />
            )}
            {stepNumber === 4 && (
              <SocialSecurityNumberForm
                initSsn={profile.socialSecurityNumber}
                setProfileSocialSecurityNumber={getProfilePropertySetter(
                  "socialSecurityNumber"
                )}
              />
            )}
            {stepNumber === 5 && (
              <IdForm
                initIdType={profile.idType}
                initIdNumber={profile.idNumber}
                setProfileIdType={getProfilePropertySetter("idType")}
                setProfileIdNumber={getProfilePropertySetter("idNumber")}
                uploadFile={uploadFileForUser}
                stepNumber={stepNumber}
              />
            )}
            {stepNumber === 6 && (
              <PhoneNumberForm
                initPhoneNumber={profile.phone}
                setProfilePhoneNumber={getProfilePropertySetter("phone")}
              />
            )}
            {stepNumber === 8 && (
              <AccreditationStatusForm
                initAccreditationStatus={complience.accreditation}
                setProfileAccreditationStatus={getCompliancePropertySetter(
                  "accreditation"
                )}
              />
            )}
            {stepNumber === 9 && (
              <AnnualIncomeForm
                initAnnualIncome={complience.annualIncome}
                setProfileAnnualIncome={getCompliancePropertySetter(
                  "annualIncome"
                )}
              />
            )}
            {stepNumber === 10 && (
              <FinraLicenseForm
                setFinraLicenseProfile={getCompliancePropertySetter(
                  "finraLicense"
                )}
                setIsHaveFinraLicenseProfile={getCompliancePropertySetter(
                  "hasFinraLicense"
                )}
                initFinraLicenseProfile={complience.finraLicense}
                initIsHaveLicense={complience.hasFinraLicense}
              />
            )}
            {stepNumber === 11 && complience.hasFinraLicense === "yes" && (
              <FinraLicenseProfileForm
                setFinraLicenseProfile={getCompliancePropertySetter(
                  "finraLicense"
                )}
                initIsHaveLicense={complience.hasFinraLicense}
                initFinraLicenseProfile={complience.finraLicense}
              />
            )}
            {stepNumber === 11 && complience.hasFinraLicense === "no" && (
              <AddressEmptyForm increaseStep={increaseStep} />
            )}
            {stepNumber === 12 && (
              <DirectorBoardMemberForm
                initMemberOfTradedPubliclyProfile={
                  complience.memberOfTradedPublicly
                }
                setMemberOfTradedPubliclyProfile={getCompliancePropertySetter(
                  "memberOfTradedPublicly"
                )}
              />
            )}
            <Box className={classes.buttonsBox}>
              <Button
                view="grayBlack"
                onClick={
                  stepNumber === 0
                    ? () => {
                        window.scroll(0, 0);
                        close();
                      }
                    : stepNumber !== 4
                    ? stepNumber === 12 && complience.hasFinraLicense === "no"
                      ? () => setStepNumber(stepNumber - 2)
                      : () => setStepNumber(stepNumber - 1)
                    : () => setStepNumber(stepNumber - 2)
                }
                className={classes.backBtn}
              >
                Back
              </Button>
              <Button
                view="black"
                onClick={
                  stepNumber !== 12
                    ? stepNumber === 4
                      ? () => {
                          createUser();
                          increaseStep();
                        }
                      : increaseStep
                    : () => {
                        window.scroll(0, 0);
                        close();
                      }
                }
                disabled={isNextDisableCitizen(stepNumber)}
                className={classes.nextBtn}
              >
                {stepsUSCitizenConfig[stepNumber].buttonLabel ?? "Next"}
              </Button>
            </Box>
          </Box>
        ) : profile.domicile === "U.S. resident" ? (
          <Box className={classes.content}>
            <LinearWithValueLabel
              classes={{
                root: classes.progressBarRoot,
                bar1Determinate: classes.progressBarOne,
              }}
              progress={stepsUSResidentConfig[stepNumber].percent}
            />
            <Typography
              component="h4"
              variant="h6"
              className={classes.questionTitle}
            >
              {stepsUSResidentConfig[stepNumber].questionTitle}
            </Typography>
            {stepsUSResidentConfig[stepNumber].popUpMessage && (
              <>
                <Typography
                  ref={popOverContainer}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  component="span"
                  className={classes.askText}
                  onMouseEnter={(event: React.MouseEvent) =>
                    setPopoverAnchor(event.currentTarget as HTMLSpanElement)
                  }
                  onClick={(event: React.MouseEvent) => {
                    if (desktop) return setPopoverAnchor(null);
                    setPopoverAnchor(
                      popoverAnchor
                        ? null
                        : (event.currentTarget as HTMLSpanElement)
                    );
                  }}
                >
                  {stepsUSResidentConfig[stepNumber].popUpLinkLabel ??
                    "Why do we ask for this?"}
                  <Popover
                    container={popOverContainer.current}
                    id="mouse-over-popover"
                    open={Boolean(popoverAnchor)}
                    anchorEl={popoverAnchor}
                    disableRestoreFocus
                    onMouseLeave={() => setPopoverAnchor(null)}
                    className={classes.popover}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      ref: popoverRef,
                      className: classes.popoverPaper,
                    }}
                  >
                    {stepsUSResidentConfig[stepNumber].popUpMessage}
                    <Box className={classes.popoverTriangle} />
                  </Popover>
                </Typography>
              </>
            )}
            {stepsUSResidentConfig[stepNumber].description && (
              <Typography component="span" className={classes.description}>
                {stepsUSResidentConfig[stepNumber].description}
              </Typography>
            )}

            {stepNumber === 0 && (
              <BirthdayForm
                birthday={profile.dob}
                setDate={getProfilePropertySetter("dob")}
              />
            )}
            {stepNumber === 1 && (
              <CitizenshipStatusForm
                setProfileDomicile={getProfilePropertySetter("domicile")}
                initCitizenshipStatus={profile.domicile}
              />
            )}
            {stepNumber === 2 && (
              <CountryForm
                getPropertySetter={getProfilePropertySetter}
                initPrimCountry={profile.primCountry}
              />
            )}
            {stepNumber === 3 && (
              <AdressForm
                getPropertySetter={getProfilePropertySetter}
                profile={profile}
                primAddress1={profile.primAddress1}
                primCity={profile.primCity}
                primState={profile.primState}
                primZip={profile.primZip}
                primCountry={profile.primCountry}
              />
            )}
            {stepNumber === 4 && (
              <SocialSecurityNumberForm
                initSsn={profile.socialSecurityNumber}
                setProfileSocialSecurityNumber={getProfilePropertySetter(
                  "socialSecurityNumber"
                )}
              />
            )}
            {stepNumber === 5 && (
              <IdForm
                initIdType={profile.idType}
                initIdNumber={profile.idNumber}
                setProfileIdType={getProfilePropertySetter("idType")}
                setProfileIdNumber={getProfilePropertySetter("idNumber")}
                uploadFile={uploadFileForUser}
                stepNumber={stepNumber}
              />
            )}
            {stepNumber === 6 && (
              <PhoneNumberForm
                initPhoneNumber={profile.phone}
                setProfilePhoneNumber={getProfilePropertySetter("phone")}
              />
            )}
            {stepNumber === 8 && (
              <AccreditationStatusForm
                initAccreditationStatus={complience.accreditation}
                setProfileAccreditationStatus={getCompliancePropertySetter(
                  "accreditation"
                )}
              />
            )}
            {stepNumber === 9 && (
              <AnnualIncomeForm
                initAnnualIncome={complience.annualIncome}
                setProfileAnnualIncome={getCompliancePropertySetter(
                  "annualIncome"
                )}
              />
            )}
            {stepNumber === 10 && (
              <FinraLicenseForm
                setFinraLicenseProfile={getCompliancePropertySetter(
                  "finraLicense"
                )}
                setIsHaveFinraLicenseProfile={getCompliancePropertySetter(
                  "hasFinraLicense"
                )}
                initFinraLicenseProfile={complience.finraLicense}
                initIsHaveLicense={complience.hasFinraLicense}
              />
            )}
            {stepNumber === 11 && complience.hasFinraLicense === "yes" && (
              <FinraLicenseProfileForm
                setFinraLicenseProfile={getCompliancePropertySetter(
                  "finraLicense"
                )}
                initIsHaveLicense={complience.hasFinraLicense}
                initFinraLicenseProfile={complience.finraLicense}
              />
            )}
            {stepNumber === 11 && complience.hasFinraLicense === "no" && (
              <AddressEmptyForm increaseStep={increaseStep} />
            )}
            {stepNumber === 12 && (
              <DirectorBoardMemberForm
                initMemberOfTradedPubliclyProfile={
                  complience.memberOfTradedPublicly
                }
                setMemberOfTradedPubliclyProfile={getCompliancePropertySetter(
                  "memberOfTradedPublicly"
                )}
              />
            )}
            <Box className={classes.buttonsBox}>
              <Button
                view="grayBlack"
                onClick={
                  stepNumber === 0
                    ? () => {
                        window.scroll(0, 0);
                        close();
                      }
                    : stepNumber === 12 && complience.hasFinraLicense === "no"
                    ? () => setStepNumber(stepNumber - 2)
                    : () => setStepNumber(stepNumber - 1)
                }
                className={classes.backBtn}
              >
                Back
              </Button>
              <Button
                view="black"
                onClick={
                  stepNumber !== 12
                    ? stepNumber === 4
                      ? () => {
                          createUser();
                          increaseStep();
                        }
                      : increaseStep
                    : () => {
                        window.scroll(0, 0);
                        close();
                      }
                }
                disabled={isNextDisableResident(stepNumber)}
                className={classes.nextBtn}
              >
                {stepsUSResidentConfig[stepNumber].buttonLabel ?? "Next"}
              </Button>
            </Box>
          </Box>
        ) : (
          <Box className={classes.content}>
            <LinearWithValueLabel
              classes={{
                root: classes.progressBarRoot,
                bar1Determinate: classes.progressBarOne,
              }}
              progress={stepsNonUSCitizenConfig[stepNumber].percent}
            />
            <Typography
              component="h4"
              variant="h6"
              className={classes.questionTitle}
            >
              {stepsNonUSCitizenConfig[stepNumber].questionTitle}
            </Typography>
            {stepsNonUSCitizenConfig[stepNumber].popUpMessage && (
              <>
                <Typography
                  ref={popOverContainer}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  component="span"
                  className={classes.askText}
                  onMouseEnter={(event: React.MouseEvent) =>
                    setPopoverAnchor(event.currentTarget as HTMLSpanElement)
                  }
                  onClick={(event: React.MouseEvent) => {
                    if (desktop) return setPopoverAnchor(null);
                    setPopoverAnchor(
                      popoverAnchor
                        ? null
                        : (event.currentTarget as HTMLSpanElement)
                    );
                  }}
                >
                  {stepsNonUSCitizenConfig[stepNumber].popUpLinkLabel ??
                    "Why do we ask for this?"}
                  <Popover
                    container={popOverContainer.current}
                    id="mouse-over-popover"
                    open={Boolean(popoverAnchor)}
                    anchorEl={popoverAnchor}
                    disableRestoreFocus
                    onMouseLeave={() => setPopoverAnchor(null)}
                    className={classes.popover}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      ref: popoverRef,
                      className: classes.popoverPaper,
                    }}
                  >
                    {stepsNonUSCitizenConfig[stepNumber].popUpMessage}
                    <Box className={classes.popoverTriangle} />
                  </Popover>
                </Typography>
              </>
            )}
            {stepsNonUSCitizenConfig[stepNumber].description && (
              <Typography component="span" className={classes.description}>
                {stepsNonUSCitizenConfig[stepNumber].description}
              </Typography>
            )}

            {stepNumber === 0 && (
              <BirthdayForm
                birthday={profile.dob}
                setDate={getProfilePropertySetter("dob")}
              />
            )}
            {stepNumber === 1 && (
              <CitizenshipStatusForm
                setProfileDomicile={getProfilePropertySetter("domicile")}
                initCitizenshipStatus={profile.domicile}
              />
            )}
            {stepNumber === 2 && (
              <CountryForm
                getPropertySetter={getProfilePropertySetter}
                initPrimCountry={profile.primCountry}
              />
            )}
            {stepNumber === 3 && (
              <AddressEmptyForm increaseStep={increaseStep} />
            )}
            {stepNumber === 4 && (
              <AdressForm
                getPropertySetter={getProfilePropertySetter}
                profile={profile}
                primAddress1={profile.primAddress1}
                primCity={profile.primCity}
                primState={profile.primState}
                primZip={profile.primZip}
                primCountry={profile.primCountry}
              />
            )}
            {stepNumber === 5 && (
              <PassportForm
                setProfilePassportNumber={getProfilePropertySetter(
                  "passportNumber"
                )}
                uploadFile={uploadFileForUser}
              />
            )}
            {stepNumber === 6 && (
              <PhoneNumberForm
                initPhoneNumber={profile.phone}
                setProfilePhoneNumber={getProfilePropertySetter("phone")}
              />
            )}
            {stepNumber === 8 && (
              <AccreditationStatusForm
                initAccreditationStatus={complience.accreditation}
                setProfileAccreditationStatus={getCompliancePropertySetter(
                  "accreditation"
                )}
              />
            )}
            {stepNumber === 9 && (
              <AnnualIncomeForm
                initAnnualIncome={complience.annualIncome}
                setProfileAnnualIncome={getCompliancePropertySetter(
                  "annualIncome"
                )}
              />
            )}
            {stepNumber === 10 && (
              <FinraLicenseForm
                setFinraLicenseProfile={getCompliancePropertySetter(
                  "finraLicense"
                )}
                setIsHaveFinraLicenseProfile={getCompliancePropertySetter(
                  "hasFinraLicense"
                )}
                initFinraLicenseProfile={complience.finraLicense}
                initIsHaveLicense={complience.hasFinraLicense}
              />
            )}
            {stepNumber === 11 && complience.hasFinraLicense === "yes" && (
              <FinraLicenseProfileForm
                setFinraLicenseProfile={getCompliancePropertySetter(
                  "finraLicense"
                )}
                initIsHaveLicense={complience.hasFinraLicense}
                initFinraLicenseProfile={complience.finraLicense}
              />
            )}
            {stepNumber === 11 && complience.hasFinraLicense === "no" && (
              <AddressEmptyForm increaseStep={increaseStep} />
            )}
            {stepNumber === 12 && (
              <DirectorBoardMemberForm
                initMemberOfTradedPubliclyProfile={
                  complience.memberOfTradedPublicly
                }
                setMemberOfTradedPubliclyProfile={getCompliancePropertySetter(
                  "memberOfTradedPublicly"
                )}
              />
            )}
            <Box className={classes.buttonsBox}>
              <Button
                view="grayBlack"
                onClick={
                  stepNumber === 0
                    ? () => {
                        window.scroll(0, 0);
                        close();
                      }
                    : stepNumber !== 4
                    ? stepNumber === 12 && complience.hasFinraLicense === "no"
                      ? () => setStepNumber(stepNumber - 2)
                      : () => setStepNumber(stepNumber - 1)
                    : () => setStepNumber(stepNumber - 2)
                }
                className={classes.backBtn}
              >
                Back
              </Button>
              <Button
                view="black"
                onClick={
                  stepNumber !== 12
                    ? stepNumber === 4
                      ? () => {
                          createUser();
                          increaseStep();
                        }
                      : increaseStep
                    : () => {
                        window.scroll(0, 0);
                        close();
                      }
                }
                disabled={isNextDisableNonResident(stepNumber)}
                className={classes.nextBtn}
              >
                {stepsNonUSCitizenConfig[stepNumber].buttonLabel ?? "Next"}
              </Button>
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};

export default CompleteProfile;
