import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(mixStyleRules(commonStyles, {
      input: {
          "&.MuiFormControl-root": {
              borderRadius: "5px",
          },
          "& .MuiOutlinedInput-root": {
              paddingLeft: 0,
              fontSize: "15.31px",
              fontWeight: "300",
          },
          "& .MuiInput-underline::before": {
              content: "none",
          },
      },
  }))
);
