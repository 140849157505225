export enum ROUTES {
  TIARA = '/tiara',
  FAQ_TIARA = '/faq-tiara',
  TIARA_FACT_SHEET = '/files/tiara-fact-sheet',
  MISSED_THE_CUT_FACT_SHEET = '/files/missed-the-cut-fact-sheet',
  ASSETS = '/assets',
  PORTFOLIO = '/portfolio',
  PROFILE = '/profile',
  VIP = '/vip',
  DASHBOARD = '/dashboard',
  ASSET_DETAILS = '/assetDetails',
  IOI_DETAILS = '/ioiDetails/:id',
  IOI_SUBMIT = '/ioiSubmit/:id',
  ACCOUNT_VERIFIED = '/accountVerifaed',
  ABOUT_LUXUS = '/AboutLuxus',
  DERBY = '/derby',
  FAQ = '/faq',
  PRIVACY_POLICY = '/privacy',
  ROYAL_ASCOT_SIGN_UP = '/royal-ascot-sign-up',
  TERMS_OF_SERVICE = '/termsOfService',
  TIARA_100WIF = '/tiara-100wif',
  THOROUGHBRED = '/thoroughbred',
  THOROUGHBRED_ANNOUNCE = '/thoroughbred-announce',
  HOME = '/',
  STREAM = '/mtc-stream',
}

export const MAIN_URL = 'https://luxusco.com';
