import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

const height = "41px";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      downloadImage: {
        height,
        widht: "134px",
      },
      secImage: {
        height,
        widht: "87px",
      },
      giaImage: {
        height,
        widht: "94px",
      },
      igiImage: {
        height,
        widht: "80px",
      },
      fcrfImage: {
        height,
        widht: "87px",
      },
    })
  )
);
