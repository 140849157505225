import { InputField } from 'components/simpleComponents/Input/InputField/InputField';

import { SHARES_QUANTITY, SHARE_PRICE } from '../../AccreditationForm.data';

import style from './PurchaseCounterV2.module.scss';
import CounterV2 from 'components/simpleComponents/Counter/CounterV2';

type PurchaseCounterProps = {
  securitiesNumber: number;
  handleCountChange: (newCount: number) => void;
  isError?: boolean;
  minimumNumberOfShares: number;
};

const PurchaseCounterV2 = ({
  securitiesNumber,
  handleCountChange,
  minimumNumberOfShares,
}: PurchaseCounterProps) => {
  return (
    <div className={style.purchaseContainer}>
      <div className={style.counterContainer}>
        <span className={style.smallTitle}>Number of shares</span>
        <CounterV2
          initialValue={securitiesNumber}
          handleOtherCountChange={handleCountChange}
          inputColor="#000"
          borderColor="#000"
        />
      </div>
      {securitiesNumber < minimumNumberOfShares && (
        <span style={{ color: '#FF0000', marginLeft: '10px' }}>
          The minimum number of shares is {minimumNumberOfShares}!
        </span>
      )}
      {securitiesNumber > SHARES_QUANTITY.MAX && (
        <span style={{ color: '#FF0000', marginLeft: '10px' }}>
          The maximum number of shares is {SHARES_QUANTITY.MAX}!
        </span>
      )}
      <div className={style.counterContainer}>
        <span className={style.smallTitle}>Aggregate reserve price</span>
        <div className={style.inputContainer}>
          <InputField.White
            placeholder="SHARES QUANTITY"
            name="phoneNumber"
            onChange={() => {}}
            fontSize={16}
            textAlign={'center'}
            value={`${new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(securitiesNumber * SHARE_PRICE)}`}
          />
        </div>
      </div>
    </div>
  );
};

export default PurchaseCounterV2;
