import { Button, Modal, Paper } from '@mui/material';
import useWindowDimensions from '../../utils/WindowDimensions';
import Iframe from '../../components/IFrame';
import '../../components/Root.css';

type PrivaryPageProps = {
  show: boolean;
  onClose: () => void;
};
export const PrivacyPage = ({ show, onClose }: PrivaryPageProps) => {
  const { height } = useWindowDimensions();

  return (
    <Modal
      open={show}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        sx={{
          size: 'md',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxHeight: '80%',
          height: height,
          minWidth: '400px',
          width: '70%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Iframe
            source="Privacy.html"
            title="Privacy Policy"
            style={{ margin: 20 }}
          />
          <Button
            onClick={onClose}
            sx={{ left: '50%', transform: 'translate(-50%,0)' }}
          >
            OK
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default PrivacyPage;
