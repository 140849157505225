export interface ErrorMessageObj {
  status_message: string;
  reason_code: number;
  status: number;
  severity?: string;
}

export default interface AuthErrorResponse {
  statusCode: number;
  name: string;
  message: string; // This is the stringified ErrorMessageObj.
}

export const makeAuthErrorResponse = (
  error: ErrorMessageObj
): AuthErrorResponse => {
  var msg = error.status_message;
  if (
    error.status_message &&
    error.status_message.startsWith(
      'Session expiry exception (bundle): user session has expired:'
    )
  ) {
    msg = 'Session has expired';
  }
  return {
    statusCode: error.status,
    name: error.severity ?? 'ERROR',
    message: msg,
  };
};
