import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  LegalEntityDefaultState,
  LegalEntityProfile,
} from "pages/Profile/AddLegalEntity/CompleteLegalEntity/useAccreditationStatusFormFacade";
import {
  BeneficialOwner,
  NewCompliance,
  NewUserProfileForm,
} from "pages/Profile/ConfigureProfile/ConfigureForm/ConfigureForm.type";

import { beneficialOwnerData } from "pages/Profile/ConfigureProfile/ConfigureForm/defaultFormValues";
import { CreateUserResonse } from "types/Account";
import { CreateLegalEntityAccountResponse } from "types/LegalEntityAccount";
import {
  ConfigureProfileState,
  initialState,
  setFileContentPayload,
} from "./ConfigureProfileSlice.type";

const configureProfileSlice = createSlice({
  name: "configureProfile",
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<CreateUserResonse>) {
      state.user = payload;
    },
    setUserAccWasCreated(state, { payload }: PayloadAction<boolean>) {
      state.userAccWasCreated = payload;
    },
    setProfile<T extends keyof NewUserProfileForm>(
      state: ConfigureProfileState,
      { payload }: PayloadAction<{ key: T; value: NewUserProfileForm[T] }>
    ) {
      state.profile[payload.key] = payload.value;
    },
    setCompliance<T extends keyof NewCompliance>(
      state: ConfigureProfileState,
      { payload }: PayloadAction<{ key: T; value: NewCompliance[T] }>
    ) {
      state.compliance[payload.key] = payload.value;
    },
    setEntityAccWasCreated(state, { payload }: PayloadAction<boolean>) {
      state.entityAccWasCreated = payload;
    },
    setFileContent(state, { payload }: setFileContentPayload) {
      state.fileContent[payload.type] = {
        content: payload.content,
        name: payload.name,
      };
    },
    setEntityProfile<T extends keyof LegalEntityProfile>(
      state: ConfigureProfileState,
      { payload }: PayloadAction<{ key: T; value: LegalEntityProfile[T] }>
    ) {
      state.legalEntityProfile[payload.key] = payload.value;
    },
    setBeneficialOwner<T extends keyof BeneficialOwner>(
      state: ConfigureProfileState,
      { payload }: PayloadAction<{ key: T; value: BeneficialOwner[T] }>
    ) {
      state.beneficialOwner[payload.key] = payload.value;
    },
    setAccountId(state, { payload }: PayloadAction<string>) {
      state.legalAccount.accountId = payload;
    },
    setLegalEntityAccResponse(
      state,
      { payload }: PayloadAction<CreateLegalEntityAccountResponse>
    ) {
      state.legalAccount.legalEntityAccResponse = payload;
    },
    resetLegalEntityAccount(state) {
      state.legalEntityProfile = LegalEntityDefaultState;
    },
    resetBeneficialOwner(state) {
      state.beneficialOwner = beneficialOwnerData;
    },
  },
});

export const {
  setUser,
  setUserAccWasCreated,
  setProfile,
  setCompliance,
  setEntityAccWasCreated,
  setFileContent,
  setEntityProfile,
  setBeneficialOwner,
  setAccountId,
  setLegalEntityAccResponse,
  resetLegalEntityAccount,
  resetBeneficialOwner,
} = configureProfileSlice.actions;

export default configureProfileSlice.reducer;
