import { Box, Typography } from "@mui/material";

import BaseLayout from "layout/BaseLayout/baseLayout";
import { ChangeEvent, useEffect, useState } from "react";
import { debounce } from "utils/debounce";
import InputSearch from "../../components/simpleComponents/Input/InputSearch/InputSearch";
import TiaraImage from "assets/images/tiaraSvg.svg";
import { FaqData, FaqDataElement, faqs } from "./FaqData";
import FaqSelectOption from "./FaqSelectOption";
import { MAIN_URL, ROUTES } from "utils/constants";

const TiaraFaq = () => {
  const [selectedOption, setSelectedOption] = useState<null | FaqDataElement>(
    null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFaqs, setFilteredFaqs] = useState<FaqData[]>(faqs);

  const select = (option: FaqDataElement) => {
    if (option && option.question === selectedOption?.question) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  function escapeRegExp(query: string) {
    return query.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  }

  const handleSearch = (query: string) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = faqs.reduce<FaqData[]>((prev, cur) => {
      const searchResWhole = cur.collection.filter(
        (item) =>
          new RegExp(`\\b${escapeRegExp(lowercasedQuery)}\\b`, "i").test(
            item.question.toLowerCase()
          ) ||
          new RegExp(`\\b${escapeRegExp(lowercasedQuery)}\\b`, "i").test(
            item.text.toLowerCase()
          )
      );
      const searchResPartial = cur.collection.filter(
        (item) =>
          item.question.toLowerCase().includes(lowercasedQuery) ||
          item.text.toLowerCase().includes(lowercasedQuery)
      );
      if (searchResWhole.length > 0) {
        prev.push({ ...cur, collection: searchResWhole });
        return prev;
      } else if (searchResPartial.length > 0) {
        prev.push({ ...cur, collection: searchResPartial });
        return prev;
      }

      return prev;
    }, [] as FaqData[]);
    setFilteredFaqs(filtered);
  };

  const debouncedSearch = debounce(handleSearch, 500);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <BaseLayout
      pageName="Tiara faq page"
      pageUri={`${MAIN_URL}${ROUTES.FAQ_TIARA}`}
    >
      <Box sx={{ backgroundColor: "#000" }}>
        <Box sx={{ maxWidth: "1040px", margin: "0 auto", padding: "0px 20px" }}>
          <Typography
            fontSize={"30px"}
            lineHeight={"36.43px"}
            fontFamily={"Telugu MN"}
            textAlign="center"
            color="#fff"
            sx={{ padding: "87px 0px 54px 0px" }}
          >
            FAQ <br />
            THE TIARA
          </Typography>
          <Box sx={{ paddingBottom: "54px" }}>
            <InputSearch query={searchQuery} onChange={handleSearchChange} />
          </Box>

          <Box paddingBottom="105px" width={"100%"} color={"white"}>
            {filteredFaqs.length < 1 ? (
              <p
                style={{
                  color: "white",
                  fontSize: "18px",
                  textAlign: "center",
                  fontFamily: "Telugu MN",
                }}
              >
                Try using more general terms to broaden your search!
              </p>
            ) : (
              <div>
                {filteredFaqs.map((faq) => (
                  <div key={faq.title}>
                    <Typography
                      fontSize={"30px"}
                      lineHeight={"36.43px"}
                      fontFamily={"Telugu MN"}
                      textAlign="left"
                      color="#fff"
                      sx={{ padding: "50px 0px 20px 0px" }}
                    >
                      {faq.title}
                    </Typography>
                    {faq.preQuestionText && (
                      <p
                        style={{
                          color: "white",
                          fontSize: "18px",
                          textAlign: "left",
                          fontFamily: "Futura PT",
                          marginBottom: "20px",
                        }}
                      >
                        {faq.preQuestionText}
                      </p>
                    )}
                    <div>
                      {faq.collection.map((el) => (
                        <FaqSelectOption
                          key={el.sort}
                          option={el}
                          selectedOption={selectedOption}
                          onClick={select}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Box>
          {filteredFaqs[0]?.title === "OVERVIEW" && (
            <p
              style={{
                color: "white",
                fontSize: "18px",
                textAlign: "left",
                fontFamily: "Futura PT",
                marginBottom: "20px",
              }}
            >
              <sup>*</sup> We are currently working on a fitting name for The
              Tiara. Stay tuned for more on this.
            </p>
          )}
        </Box>
        <Box
          maxWidth="414px"
          display={"flex"}
          justifyContent={"center"}
          padding={"20px 20px 40px 20px"}
          margin={"0 auto"}
        >
          <img src={TiaraImage} alt="diamond" width="100%" height={"auto"} />
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default TiaraFaq;
