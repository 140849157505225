import { themeData } from "../../../../../../utils/theme";

export const commonStyles = {
  container: {
    flexWrap: "wrap",
    marginBottom: "17px",
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
    },
  },
  checkbox: {
    height: "24px",
    width: "24px",
    color: 'black',
    marginRight: '10px',
    marginTop: '5px',
    "&>svg": {
      height: "24px",
      width: "24px",
      color: 'black',
    },
  },
  text: {
    fontSize: "20px",
    fontWeight: "300",
    lineHeight: "26px",
    marginLeft: "10px",
    marginBottom: "10px",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
};

export type ClassesNames = keyof typeof commonStyles;
