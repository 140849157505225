import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import CloseMenuIcon from "assets/icons/CloseMenuIcon";
import HeaderArrowBottom from "assets/icons/HeaderArrowBottom";
import HeaderArrowTop from "assets/icons/HeaderArrowTop";
import HeaderLogo from "assets/icons/HeaderLogo";
import MenuBarsBig from "assets/icons/MenuBarsBig";
import MenuBarsSmall from "assets/icons/MenuBarsSmall";
import { CustomButton } from "components/UIComponents/Button";
import { Page } from "types/Page";

import { AccreditedInvestorPages } from "components/Header/constants";
import AccountMobileMenu from "../AccountMobileMenu/AccountMobileMenu";
import styles from "./MobileMenu.module.scss";

type MobileMenuProps = {
  color: string;
  pages: Page[];
  aboutMenuPages: Page[];
  isUserAuthorized: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileMenu: React.FC<MobileMenuProps> = ({
  color,
  isMenuOpen,
  setIsMenuOpen,
  pages,
  aboutMenuPages,
  isUserAuthorized,
}) => {
  const isMinWidthMatches = useMediaQuery("(min-width:576px)");

  const navigate = useNavigate();

  const [isAccreditedInvestorMenuOpen, setIsAccreditedInvestorMenuOpen] =
    useState(false);
  const [isAboutMenuOpen, setIsAboutMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const wrapperStyles = classNames(styles.menuWrapper, {
    [styles.menuWrapperOpen]: isMenuOpen,
  });

  const aboutButtonStyles = classNames(styles.aboutButton, {
    [styles.isAboutButtonOpen]: !isAboutMenuOpen,
  });

  const handleOpenMenu = () => setIsMenuOpen((prevState) => !prevState);

  return (
    <div className={styles.navigation}>
      <button className={styles.menuButton} onClick={handleOpenMenu}>
        {isMinWidthMatches ? (
          <MenuBarsSmall color={color} />
        ) : (
          <MenuBarsBig color={color} />
        )}
      </button>
      <div className={wrapperStyles}>
        <div className={styles.menuHeader}>
          <HeaderLogo />
          <button className={styles.closeMenuButton} onClick={handleOpenMenu}>
            <CloseMenuIcon />
          </button>
        </div>
        <nav>
          <ul className={styles.list}>
            {pages.map((page) => (
              <li className={styles.itemList} key={page.id}>
                <NavLink
                  to={page.url}
                  target={page.target}
                  className={styles.link}
                  onClick={handleOpenMenu}
                >
                  {page.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <div
            className={aboutButtonStyles}
            style={{ marginBottom: "0px" }}
            onClick={() =>
              setIsAccreditedInvestorMenuOpen((prevState) => !prevState)
            }
          >
            <span>ACCREDITED INVESTORS</span>
            {!isAccreditedInvestorMenuOpen ? (
              <HeaderArrowBottom />
            ) : (
              <HeaderArrowTop />
            )}
          </div>
          {isAccreditedInvestorMenuOpen && (
            <ul className={styles.aboutList} style={{ marginBottom: "0px" }}>
              {AccreditedInvestorPages.map((page) => (
                <li className={styles.itemList} key={page.id}>
                  <NavLink
                    to={page.url}
                    target={page.target}
                    className={styles.link}
                  >
                    {page.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
          <div
            className={aboutButtonStyles}
            onClick={() => setIsAboutMenuOpen((prevState) => !prevState)}
          >
            <span>ABOUT</span>
            {!isAboutMenuOpen ? <HeaderArrowBottom /> : <HeaderArrowTop />}
          </div>
          {isAboutMenuOpen && (
            <ul className={styles.aboutList}>
              {aboutMenuPages.map((page) => (
                <li className={styles.itemList} key={page.id}>
                  <NavLink
                    to={page.url}
                    target={page.target}
                    className={styles.link}
                  >
                    {page.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </nav>
        {isUserAuthorized ? (
          <AccountMobileMenu
            handleAccountOpenMenu={setIsAccountMenuOpen}
            isAccountMenuOpen={isAccountMenuOpen}
            handleOpenMenu={setIsMenuOpen}
          />
        ) : (
          <div className={styles.signButtonWrapper}>
            {/* <CustomButton
              btnStyle="inlineGrey"
              size="long"
              onClick={() => navigate("/login")}
            >
              SIGN IN
            </CustomButton> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
