import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        image: {
          height: "146px",
          width: "146px",
          marginBottom: "16px",
        },
        offerName: {
          fontSize: "28px",
          marginBottom: "26px",
        },
        text: {
          fontSize: "15px",
          marginBottom: "26px",
        },
        btn: {
          padding: "7px 14.5px",
          fontSize: "17px",
        },
      })
    )
);
