import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useServerAPI } from "../../../apis";
import {
  BankAccountData,
  bankAccountForm,
  CreditCardData,
  creditCardForm,
  ErrorType,
} from "../../../types/Account";
import { PaymentType } from "../../../types/enums/PaymentType";
import { stripHTML } from "../../../utils";
// import PaymentForm from './PaymentForm';
import PaymentForm from "../../../components/NewPaymentPopUp/PaymentForm/PaymentForm";
import "../ProfileForm.css";

export interface PaymentEditProps {
  onCancel?: Function;
  onSave?: Function;
  selectedType?: PaymentType.BANK | PaymentType.CARD | string;
  showModal: boolean;
  handleClose: () => void;
  singleEdit?: boolean;
  bankAccountData?: BankAccountData;
  creditCardData?: CreditCardData;
}

export const PaymentEdit = ({
  onCancel,
  onSave,
  selectedType,
  showModal,
  handleClose,
  singleEdit,
  bankAccountData,
  creditCardData,
}: PaymentEditProps) => {
  let api = useServerAPI();
  const [bankAccountError, setBankAccountError] = useState<string>();
  const [creditCardError, setCreditCardError] = useState<string>();
  const [selectedTab, setSelectedTab] = useState(
    selectedType ?? PaymentType.BANK
  );
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setSelectedTab(selectedType ?? PaymentType.BANK);
  }, [selectedType]);

  const handleOnSubmitBankForm = async (inputs: bankAccountForm) => {
    setProcessing(true);
    setSelectedTab(PaymentType.BANK);
    setBankAccountError(undefined);
    let apiToCall = api.registerBankAccount;
    if (bankAccountData) apiToCall = api.updateBankAccount;
    try {
      if (inputs) {
        const response = await apiToCall(inputs);
        setProcessing(false);
        if (!response.data.response.data.statusCode) {
          if (onSave) onSave();
        }
        const err = response.data.response.data as ErrorType;
        if (err && (err["Error(s)"] || err.statusDesc)) {
          console.error(
            "Bank Error ",
            stripHTML(err["Error(s)"]) ?? err.statusDesc ?? ""
          );
          setBankAccountError(
            stripHTML(err["Error(s)"]) ?? err.statusDesc ?? ""
          );
        }
      }
    } catch (error: any) {
      console.error("err", error.response?.data?.error);
      setProcessing(false);
      setBankAccountError(error);
    }
  };
  const handleOnSubmitCreditCardForm = async (inputs: creditCardForm) => {
    setProcessing(true);
    setCreditCardError(undefined);
    setSelectedTab(PaymentType.CARD);
    let apiToCall = api.registerCreditCard;
    if (creditCardData) apiToCall = api.updateCreditCard;
    try {
      if (inputs) {
        const response = await apiToCall(inputs);
        setProcessing(false);
        if (typeof response.data.response.data === "string") {
          if (onSave) onSave();
        } else {
          console.error(
            `Error saving Credit Card Edit`,
            response.data.response.data
          );
          const err = response.data.response.data as ErrorType;
          if (err && (err["Error(s)"] || err.statusDesc)) {
            console.error(
              "Credit Card Error ",
              stripHTML(err["Error(s)"]) ?? err.statusDesc ?? ""
            );
            setCreditCardError(
              stripHTML(err["Error(s)"]) ?? err.statusDesc ?? ""
            );
          }
        }
      }
    } catch (error: any) {
      console.error("err", error.response?.data?.error);
      setProcessing(false);
      setCreditCardError(error);
    }
  };

  const handleCancel = () => {
    setBankAccountError(undefined);
    setCreditCardError(undefined);
    if (onCancel) onCancel();
  };
  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="Payment Edit"
      aria-describedby="Adding or editing payment types"
    >
      <Box
        sx={{
          backgroundColor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "5px",
          padding: "1rem 0px",
          width: { xs: "100%", md: "auto" },
        }}
      >
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            padding={2}
          >
            <PaymentForm
              onCancel={handleCancel}
              singleEdit={singleEdit}
              processing={processing}
              selectedTab={selectedTab}
              onSaveBank={handleOnSubmitBankForm}
              bankAccountError={bankAccountError}
              onSaveCCard={handleOnSubmitCreditCardForm}
              creditCardError={creditCardError}
              title="Edit your payment method"
              submitTitle="Save"
              bankAccountData={bankAccountData}
              creditCardData={creditCardData}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentEdit;
