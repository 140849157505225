import { useState } from "react";

import ToggleSwitch from "components/simpleComponents/ToggleSwitch/ToggleSwitch";
import CreditCardForm from "./CreditCardForm/CreditCardForm";
import BankDetailsForm from "./BankDetailsForm/BankDetailsForm";

import { STEPS } from "../AccreditationForm.data";

import style from "./style.module.scss";

type FifthStepProps = {
  setStep: React.Dispatch<React.SetStateAction<STEPS>>;
  guid: string;
  securitiesQuantity: number;
};

const FifthStep = ({ setStep, guid, securitiesQuantity }: FifthStepProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAchForm, setIsAchForm] = useState(true);
  const [isCardAccepted, setIsCardAccepted] = useState(false);
  const [isPlaidDataAccepted, setIsPlaidDataAccepted] = useState(false);

  const handleClick = () => {
    setIsAchForm((prevState) => !prevState);
  };

  return (
    <>
      <div className={style.content}>
        {!isLoading && (
          <>
            <h2>THE TIARA OFFERING</h2>
            {securitiesQuantity <= 2 && !isPlaidDataAccepted && (
              <>
                <p className={style.text}>Please select a payment method</p>
                <div className={style.toggleWrapper}>
                  <ToggleSwitch
                    handleClick={handleClick}
                    firstButtonText="BANK ACCOUNT"
                    secondButtonText="CREDIT CARD"
                  />
                </div>
              </>
            )}
          </>
        )}
        {isAchForm ? (
          <BankDetailsForm
            guid={guid}
            isPlaidDataAccepted={isPlaidDataAccepted}
            setIsPlaidDataAccepted={setIsPlaidDataAccepted}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setStep={setStep}
          />
        ) : (
          <CreditCardForm
            guid={guid}
            setStep={setStep}
            setIsCardAccepted={setIsCardAccepted}
            isCardAccepted={isCardAccepted}
          />
        )}
      </div>
    </>
  );
};

export default FifthStep;
