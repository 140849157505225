import { useCallback, useEffect, useRef, useState } from "react";

export default function useCountDown(from: number, immediate = false) {
  const [counter, setCounter] = useState(from);
  const [active, setActive] = useState(immediate);
  const unsubscribe = useRef<NodeJS.Timeout>();

  const clear = useCallback(() => {
    if (unsubscribe.current) {
      clearTimeout(unsubscribe.current);
    }
  }, [unsubscribe]);

  const reset = useCallback(() => {
    clear();
    setCounter(from);
  }, [clear, from]);

  const start = useCallback(() => {
    if (!active) {
      setActive(true);
    }
  }, [active]);

  const stop = useCallback(() => {
    reset();
    setActive(false);
  }, [reset]);

  useEffect(() => {
    if (!isNaN(counter) && counter > 0 && active) {
      unsubscribe.current = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return clear;
  }, [counter, active, clear]);

  return {
    counter,
    reset,
    start,
    stop,
    clear,
  };
}
