import { Offering } from 'types/Offerings';

import { CustomButton } from 'components/UIComponents/Button';
import CurrentOfferingAlt from '../CurrentOfferingAlt';
import OfferingsUnlogged from '../OfferingsUnlogged/OfferingsUnlogged';
import TiaraAsset from 'pages/Dashboard/components/TiaraAsset/TiaraAsset';

import styles from './OurOfferings.module.scss';
import ThoroughbredAsset from 'pages/Dashboard/components/ThoroughbredAsset/ThoroughbredAsset';

type OurOfferingsProps = {
  activeOfferings: Offering[];
  isShowMoreUpcoming: boolean;
  setIsShowMoreUpcoming: (value: React.SetStateAction<boolean>) => void;
};

const OurOfferings: React.FC<OurOfferingsProps> = ({
  activeOfferings,
  isShowMoreUpcoming,
  setIsShowMoreUpcoming,
}) => {
  const isUserAuthorized = !!localStorage.getItem('rppToken');

  return (
    <section className={styles.wrapper}>
      <div className={styles.section}>
        <h2 className={styles.title}>OUR OFFERINGS</h2>
        <p className={styles.text}>
          For the first time, invest in luxury assets by the first SEC qualified
          alternative investment platform.
        </p>
        {isUserAuthorized ? (
          <>
            <TiaraAsset isShowMoreUpcoming={isShowMoreUpcoming} />
            {isShowMoreUpcoming && (
              <CurrentOfferingAlt
                offerings={activeOfferings}
                isShowMoreUpcoming={isShowMoreUpcoming}
              />
            )}
            {!isShowMoreUpcoming && (
              <div className={styles.buttonContainer}>
                <CustomButton
                  onClick={() => setIsShowMoreUpcoming(true)}
                  btnStyle={'secondary'}
                >
                  VIEW ALL ASSETS
                </CustomButton>
              </div>
            )}
          </>
        ) : (
          <OfferingsUnlogged />
        )}
      </div>
    </section>
  );
};

export default OurOfferings;
