export function handleSectionScroll(queryParam: string, elementId: string) {
  const searchParams = new URLSearchParams(window.location.search);

  const element = document.getElementById(elementId);

  const isScrollToElement = searchParams.get(queryParam);
  if (isScrollToElement === "true" && element) {
    element.scrollIntoView({ behavior: "smooth" });
  } else {
    window.scroll({ top: 0, behavior: "smooth" });
  }
}
