import { Tab } from './types';

export const TABS: Tab[] = [
  {
    title: 'Details',
    id: 1,
    isActive: true,
  },
  {
    title: 'Pedigree',
    id: 2,
    isActive: false,
  },
  // {
  //   title: "Benefits",
  //   id: 3,
  //   isActive: false,
  // },
  {
    title: 'Gallery',
    id: 4,
    isActive: false,
  },
];
