import axios, { type AxiosResponse } from "axios";

export function sendPostRequest(url: string, formData: URLSearchParams) {
  const API_KEY = process.env.REACT_APP_API_KEY;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${API_KEY}`,
  };

  return axios.post(url, formData.toString(), { headers });
}

export function getStringifiedFormData(data: Record<string, any>) {
  const formData = new URLSearchParams();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return formData;
}

export function finalizeSubscription(promises: AxiosResponse<any, any>[]) {
  return Promise.all(promises);
}
