import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";

import "./LinearProgressWithLabel.css";

function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number;
    prePercentLabel?: string;
    postPercentLabel?: string;
    classes?: {
      root?: string;
      bar1Determinate?: string;
    };
    linearSx?: LinearProgressProps["sx"];
    mainBoxSx?: BoxProps["sx"];
    percentSx?: TypographyProps["sx"];
  }
) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifySelf: "center",
        marginBottom: "45px",
        ...props.mainBoxSx,
      }}
    >
      <Box sx={{ mr: 1, width: "100%" }}>
        <LinearProgress
          variant="determinate"
          {...props}
          classes={{
            root: props.classes?.root ?? "linear-progress-bar-root",
            bar1Determinate:
              props.classes?.bar1Determinate ?? "linear-progress-bar-1",
          }}
          sx={{ width: "100%", ...props.linearSx }}
        />
      </Box>
      <Box sx={{ position: "relative", minWidth: 35, minHeight: 20 }}>
        <Typography
          variant="body2"
          color="text.secondary"
          whiteSpace={"nowrap"}
          sx={props.percentSx}
        >
          {props.prePercentLabel ? `${props.prePercentLabel} ` : ""}
          {`${Math.round(props.value)}%`}
          {props.postPercentLabel ? ` ${props.postPercentLabel}` : ""}
        </Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel(props: {
  progress: number;
  prePercentLabel?: string;
  postPercentLabel?: string;
  classes?: {
    root?: string;
    bar1Determinate?: string;
  };
  linearSx?: LinearProgressProps["sx"];
  mainBoxSx?: BoxProps["sx"];
  percentSx?: TypographyProps["sx"];
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        value={props.progress}
        postPercentLabel={props.postPercentLabel}
        prePercentLabel={props.prePercentLabel}
        classes={props.classes}
        mainBoxSx={props.mainBoxSx}
        linearSx={props.linearSx}
        percentSx={props.percentSx}
      />
    </Box>
  );
}
