import { useState } from "react";

import AttestationsForm from "./AttestationsForm/AttestationsForm";
import { Preloader } from "components/simpleComponents/Preloader";

import { UserData, sendUserDataToInitiateSubdocFlow } from "apis/backend";
import { KycAmlStatus } from "types/enums/KycAmlStatus";

import { FormData } from "../AccreditationForm.type";
import { STEPS } from "../AccreditationForm.data";

import style from "./style.module.scss";

type SecondStepAlternativeProps = {
  setStep: (step: STEPS) => void;
  form: FormData;
  guid: string;
  ssn: string;
};

const SecondStepAlternative = ({
  setStep,
  form,
  guid,
  ssn,
}: SecondStepAlternativeProps) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [isTouched, setIsTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleCheck(event: React.SyntheticEvent) {
    setIsTouched(true);

    const targetElement = event.target as HTMLInputElement;

    checkedItems.includes(targetElement.value)
      ? setCheckedItems((prevState) =>
          prevState.filter((item) => item !== targetElement.value)
        )
      : setCheckedItems((prevState) => [...prevState, targetElement.value]);
  }

  async function handleSubmit() {
    setIsTouched(true);

    if (checkedItems.length === 0) {
      return;
    }

    const userData: UserData = {
      selfAttestation: checkedItems,
      sharesQty: form.securitiesNumber,
      socialSecurityNumber: ssn,
      dob: form.birthDate,
      streetAddress: form.street,
      city: form.city,
      state: form.primState,
      zip: form.primZip,
      country: "USA",
      createdIpAddress: form.ip,
      streetAddressOptional: form.streetAddressOptional,
      firstName: form.firstName,
      lastName: form.lastName,
    };

    try {
      setIsLoading(true);
      const data = await sendUserDataToInitiateSubdocFlow(guid, userData);

      setIsLoading(false);

      if (data.kycStatus === KycAmlStatus.Disapproved) {
        setStep(STEPS.LOAD_DOCUMENTS_FORM);
        return;
      }
      setStep(STEPS.PDF_PREVIEW);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const isShowErrorCondition = isTouched && checkedItems.length === 0;

  return (
    <>
      {isLoading ? (
        <Preloader isWhite />
      ) : (
        <div className={style.content}>
          <h2>THE TIARA OFFERING</h2>
          <p className={style.text}>
            Please check the following boxes for all that apply as part of
            verifying that you are an accredited investor around income, net
            worth and potential entity used for this investment (we only need
            one but please check all that apply):
          </p>
          <AttestationsForm
            setStep={setStep}
            handleChange={handleCheck}
            isShowError={isShowErrorCondition}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
    </>
  );
};

export default SecondStepAlternative;
