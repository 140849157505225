import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { ControledField } from "../ControledField";
import ControlledDropdown from "../ControlledDropdown/ControlledDropdown";
import { CustomButton } from "components/UIComponents/Button";
import PlaidWindow from "../PlaidWindow/PlaidWindow";
import { Preloader } from "components/simpleComponents/Preloader";
import TermOfUseCheckbox from "pages/BuyShares/components/TermOfUseCheckbox";

import { finalizeAchPlaid, initiateAchPaymentFlow } from "apis/backend";

import { STEPS } from "../../AccreditationForm.data";
import { AchFormData } from "../../AccreditationForm.type";

import styles from "./BankDetailsForm.module.scss";
import { INITIAL_FORM_STATE, ACCOUNT_TYPES, FIELD_NAMES } from "./constants";
import { achFormSchema } from "./schema";

type BankDetailsFormProps = {
  isPlaidDataAccepted: boolean;
  guid: string;
  setIsPlaidDataAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setStep: React.Dispatch<React.SetStateAction<STEPS>>;
};

const BankDetailsForm = ({
  isPlaidDataAccepted,
  guid,
  setIsPlaidDataAccepted,
  setIsLoading,
  isLoading,
  setStep,
}: BankDetailsFormProps) => {
  const [isShowPlaid, setIsShowPlaid] = useState(false);
  const [form, setForm] = useState(INITIAL_FORM_STATE);
  const [isFirstBoxChecked, setIsFirstBoxChecked] = useState(false);

  const {
    clearErrors,
    setError,
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<AchFormData>({
    resolver: yupResolver(achFormSchema),
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: INITIAL_FORM_STATE,
  });

  const accountTypeFromForm = getValues(FIELD_NAMES.ACCOUNT_TYPE);

  const accountType = {
    label: accountTypeFromForm || "ACCOUNT TYPE",
    value: accountTypeFromForm || "ACCOUNT TYPE",
  };

  const plaidProps = {
    isPlaidDataAccepted,
    setIsPlaidDataAccepted,
    guid,
    setForm,
    isShowPlaid,
    setIsShowPlaid,
    setIsFirstBoxChecked,
  };

  async function onSubmit(data: AchFormData) {
    try {
      setIsLoading(true);

      if (isPlaidDataAccepted) {
        await finalizeAchPlaid(guid);

        setIsLoading(false);
        setStep(STEPS.SUCCESS_SCREEN_ACH);
        return;
      }

      await initiateAchPaymentFlow(guid, data);

      setIsLoading(false);

      setStep(STEPS.SUCCESS_SCREEN_ACH);
    } catch (err: any) {
      setIsLoading(false);
      setIsFirstBoxChecked(false);

      if (err?.response?.data?.response?.statusCode === "215") {
        setError(FIELD_NAMES.ROUTING_NUMBER, {
          type: "manual",
          message: "Routing number is invalid!",
        });

        console.error(err.response);
      }

      console.error(err);
    }
  }

  useEffect(() => {
    if (isPlaidDataAccepted) {
      setIsFirstBoxChecked(false);
      reset({
        accountHolderName: form.accountHolderName,
        bankName: form.bankName,
        routingNumber: form.routingNumber,
        accountNumber: form.accountNumber,
        accountType: form.accountType,
      });
    }
  }, [
    form.accountHolderName,
    form.accountNumber,
    form.accountType,
    form.bankName,
    form.routingNumber,
    isPlaidDataAccepted,
    reset,
  ]);

  return (
    <>
      {isLoading ? (
        <Preloader isWhite />
      ) : (
        <>
          <PlaidWindow {...plaidProps} />
          {!isShowPlaid && (
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <p className={styles.text}>
                Complete your investment by securely inputting your ACH payment
                details
              </p>
              {!isPlaidDataAccepted && (
                <div className={styles.rowContainer}>
                  <ControledField
                    placeholder="ACCOUNT HOLDER NAME"
                    name={FIELD_NAMES.ACCOUNT_HOLDER_NAME}
                    control={control}
                    isError={!!errors.accountHolderName}
                    errorMessage={errors.accountHolderName?.message}
                  />
                </div>
              )}
              <ControledField
                disabled={isPlaidDataAccepted}
                placeholder="BANK NAME"
                name={FIELD_NAMES.BANK_NAME}
                isError={!!errors.bankName}
                errorMessage={errors.bankName?.message}
                control={control}
              />
              <div className={styles.rowContainer}>
                <ControledField
                  disabled={isPlaidDataAccepted}
                  placeholder="ROUTING NUMBER"
                  name={FIELD_NAMES.ROUTING_NUMBER}
                  control={control}
                  isError={!!errors.routingNumber}
                  errorMessage={errors.routingNumber?.message}
                  clearErrors={clearErrors}
                />
                <ControledField
                  disabled={isPlaidDataAccepted}
                  placeholder="ACCOUNT NUMBER"
                  name={FIELD_NAMES.ACCOUNT_NUMBER}
                  control={control}
                  isError={!!errors.accountNumber}
                  errorMessage={errors.accountNumber?.message}
                />
              </div>
              {!isPlaidDataAccepted && (
                <ControlledDropdown
                  {...register(FIELD_NAMES.ACCOUNT_TYPE)}
                  placeholderColor="#fff"
                  placeholder="ACCOUNT TYPE"
                  initValue={accountType}
                  dropdownOptions={ACCOUNT_TYPES}
                  control={control}
                  isError={!!errors.accountType}
                  errorMessage={errors.accountType?.message}
                />
              )}
              <div className={styles.checkBoxTermsContainer}>
                <TermOfUseCheckbox
                  onChange={() =>
                    setIsFirstBoxChecked((prevState) => !prevState)
                  }
                />
              </div>
              <div className={styles.buttonContainer}>
                <CustomButton
                  disabled={!isFirstBoxChecked}
                  type="submit"
                  btnStyle="secondaryBlack"
                >
                  SUBMIT
                </CustomButton>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default BankDetailsForm;
