import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      popoverPaper: {
        padding: "10px",
        fontSize: "18px",
      },
      popoverTriangle: {
        position: "relative",
        mt: "10px",
        "&::before": {
          backgroundColor: "#DFDFE3  !important",
          content: '""',
          display: "block",
          position: "absolute",
          width: 30,
          height: 30,
          bottom: "52px",
          transform: "rotate(45deg)",
          left: "calc(60% + 18px)",
          zIndex: "1",
        },
      },
    })
  )
);
