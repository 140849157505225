import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import ReactGA from "react-ga";

import { useStyles } from "./styles";

interface AnnualIncomeFormProps {
  setProfileAnnualIncome(value: string): void;
  initAnnualIncome: string;
}

const AnnualIncomeForm: React.FC<AnnualIncomeFormProps> = function ({
  setProfileAnnualIncome,
  initAnnualIncome,
}): JSX.Element {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/AnnualIncomeForm");
  }, []);

  const [income, setIncome] = useState(initAnnualIncome ?? "");

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIncome(event.target.value);
    setProfileAnnualIncome(event.target.value);
  };

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.sign}>$</Typography>
      <TextField
        placeholder="Annual Income*"
        variant="outlined"
        className={classes.field}
        value={income}
        onChange={onInputChange}
      />
    </Box>
  );
};

export default AnnualIncomeForm;
