import { themeData } from "../../../../utils/theme";

export const commonStyles = {
  container: {
    padding: "88px 0 42px 0",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formContainer: {
    padding: "10px 35px",
    width: "80%",
    minWidth: "490px",
  },
  formContent: {
    width: "100%",
    margin: "0.5rem auto",
  },
  title: {
    textAlign: "center",
    color: themeData.BlackColor,
    height: "30px",
    fontSize: "26px",
  },
  text: {
    textAlign: "center",
    color: themeData.GreyText,
    marginBottom: "0.25rem",
    fontSize: "14px",
  },
  firstNameField: {
    width: "100%",
    fontSize: "17px",
  },
  lastNameField: {
    width: "100%",
    fontSize: "17px",
    "&::placeholder": { textOverflow: "clip" },
  },
  emailField: {},
  passField: {},
  passInputProps: {
    fontFamily: "Verdana",
    letterSpacing: "2px",
  },
  inputAdormnet: {
    color: "black",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  checkBox: {
    height: "30px",
    width: "30px",
  },
  agreemntText: {
    color: "#333333",
    textAlign: "left",
    fontSize: "15px",
    margin: "30px 0",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  error: {
    textAlign: "center",
    color: "red",
    margin: "-1rem auto 0.8rem",
  },
  warning: {
    textAlign: "center",
    color: "#ffa726",
    margin: "-1rem auto 0.8rem",
  },
  singupBtn: {
    width: "133px",
    margin: "1rem auto",
    marginTop: "0.5rem",
    backgroundColor: "#000000",
    display: "block",
    borderRadius: "5px !important",
    fontWeight: "bold",
    "&:hover": {
      background: "#000000",
    },
    // textTransform: 'none',
    padding: "8px 0",
    fontSize: "14px",
  },
  linkContainer: {
    color: "#333333",
    textAlign: "center",
  },
  siginLink: {
    color: "black",
  },

  footerContainer: {
    bottom: 0,
    right: 0,
    width: "100%",
  },
};

export type ClassesNames = keyof typeof commonStyles;
