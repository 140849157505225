type CloseMenuIconProps = {
  color?: string;
};

const CloseMenuIcon: React.FC<CloseMenuIconProps> = ({ color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="40"
      viewBox="0 0 26 40"
      fill="none"
    >
      <g clipPath="url(#clip0_1765_1342)">
        <path
          d="M25.1329 32.1328C24.6444 32.6213 23.854 32.6213 23.3649 32.1328L13.0001 21.7656L2.63289 32.1328C2.14437 32.6213 1.35398 32.6213 0.864922 32.1328C0.376406 31.6443 0.376406 30.8539 0.864922 30.3648L11.2345 20L0.866484 9.63281C0.377969 9.1443 0.377969 8.35391 0.866484 7.86485C1.355 7.37633 2.14539 7.37633 2.63445 7.86485L13.0001 18.2344L23.3673 7.86719C23.8558 7.37867 24.6462 7.37867 25.1352 7.86719C25.6237 8.3557 25.6237 9.1461 25.1352 9.63516L14.7657 20L25.1329 30.3672C25.6251 30.8516 25.6251 31.6484 25.1329 32.1328Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1765_1342">
          <rect
            width="25"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseMenuIcon;
