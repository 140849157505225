import { forwardRef, useState } from "react";
import Inputmask from "react-input-mask";
import classNames from "classnames";
import { Path, UseFormRegister } from "react-hook-form";

import { EyeCloseIcon } from "assets/icons/EyeCloseIcon";
import { EyeIcon } from "assets/icons/eye";
import styles from "./InputMask.module.scss";

export type FormValues = {
  [key: string]: string | undefined;
};

type InputMaskProps = {
  value?: string;
  errorMessage?: string;
  isError?: boolean;
  mask: string;
  placeholder: string;
  name: Path<FormValues>;
  isHiddenChars?: boolean;
  register: UseFormRegister<any>;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
};

const InputMask = forwardRef<HTMLInputElement, InputMaskProps>(
  function InputMask(
    {
      value,
      errorMessage,
      isError,
      mask,
      placeholder,
      name,
      isHiddenChars = false,
      register,
    },
    ref
  ) {
    const [inputType, setInputType] = useState(
      !isHiddenChars ? "text" : "password"
    );

    const inputClasses = classNames(styles.input, {
      [styles.inputError]: isError,
    });

    const togglePasswordVisibility = () => {
      const inputRef = document.getElementById("inputRef");
      if (inputRef) {
        inputRef.focus();
      }

      setInputType((prev) => (prev === "password" ? "text" : "password"));
    };

    return (
      <Inputmask {...register(name)} value={value} mask={mask}>
        {(inputProps: any) => (
          <div className={styles.inputContainer}>
            <input
              ref={ref}
              className={inputClasses}
              {...inputProps}
              placeholder={placeholder}
              name={name}
              type={inputType}
              autoComplete="new-password"
              id="inputRef"
            />
            {isHiddenChars && (
              <span className={styles.eye} onClick={togglePasswordVisibility}>
                {inputType === "text" ? <EyeIcon /> : <EyeCloseIcon />}
              </span>
            )}
            {isError && <span className={styles.error}>{errorMessage}</span>}
          </div>
        )}
      </Inputmask>
    );
  }
);

export default InputMask;
