export const CloseIcon = ({ color = "white", width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1076_967)">
      <g clipPath="url(#clip1_1076_967)">
        <path
          d="M19.2797 19.7797C18.9866 20.0728 18.5124 20.0728 18.2189 19.7797L12 13.5594L5.7797 19.7797C5.48659 20.0728 5.01235 20.0728 4.71892 19.7797C4.42581 19.4866 4.42581 19.0123 4.71892 18.7189L10.9406 12.5L4.71985 6.27968C4.42674 5.98657 4.42674 5.51234 4.71985 5.2189C5.01296 4.92579 5.4872 4.92579 5.78064 5.2189L12 11.4406L18.2203 5.22031C18.5134 4.9272 18.9877 4.9272 19.2811 5.22031C19.5742 5.51342 19.5742 5.98765 19.2811 6.28109L13.0594 12.5L19.2797 18.7203C19.575 19.0109 19.575 19.4891 19.2797 19.7797Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1076_967">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_1076_967">
        <rect
          width="15"
          height="24"
          fill="white"
          transform="translate(4.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
