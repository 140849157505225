import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          marginBottom: "0",
        },
        title: {
          fontSize: "19px",
          marginLeft: "65px",
        },
        text: {
          fontSize: "19px",
          lineHeight: "46px",
          marginLeft: "65px",
        },
        content1: {
          padding: "13px 0 20px",
          fontSize: "19px",
        },
        textBr: {
          height: "25px",
          margin: "0",
        },
        tab: {
          fontSize: "22px",
          lineHeight: "30px",
          padding: "12px 16px",
          minWidth: "50%",
          borderRadius: "10px 10px 0 0 !important",
        },
      })
    )
);
