import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import themeData from "../../../utils/theme";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      fontFamily: themeData.DefaultFont,
      fontWeight: "400",
      fontSize: "25px",
    },
    textButton: {
      color: themeData.WhiteColor,
      backgroundColor: "#6890FF",
      padding: "1px 20px",
      "&:hover": {
        backgroundColor: "#516fc1",
      },
    },
    secondaryButton: {
      fontSize: "21px",
      fontWeight: "450",
      backgroundColor: "#F4F4F4",
      borderRadius: "6px",
      border: "none",
      "&:hover": {
        border: "none",
      },
    },
    linkButton: {
      fontSize: "17px",
      textTransform: "capitalize",
      textDecoration: "underline",
      color: themeData.BlackColor,
    },
    grayButton: {
      color: themeData.WhiteColor,
      backgroundColor: "#7B7B8F",
      padding: "1px 20px",
      "&:hover": {
        backgroundColor: "#b0b0ba",
      },
    },
    blackButton: {
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#5a6268",
      },
    },
    grayBlackButton: {
      backgroundColor: "#F4F4F4",
      color: "black",
      borderRadius: "6px",
      border: "none",
      boxShadow: "none",
      "&:hover": {
        border: "none",
        backgroundColor: "black",
        color: "#F4F4F4",
      },
    },
  })
);
