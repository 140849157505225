import { themeData } from '../../../../utils/theme';

export const commonStyles = {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    margin: '70px 0',
    width: '865px',
    flexShrink: 0,
  },
  title: {
    fontSize: '34px',
    fontWeight: '450',
  },
  subTitle: {
    fontSize: '20px',
    fontWeight: '300',
  },
  imgBox: {
    margin: '10px 20px',
    maxWidth: '400px',
    '& img': {
      width: '100%',
    },
  },
  hr: {
    height: '2.5px',
    backgroundColor: '#E5E5E5',
    border: 'none',
    margin: '20px 0 10px 0',
  },
  tipContainer: {
    background: '#F4F4F4',
    borderRadius: '5px',
    padding: '20px',
    marginTop: '20px',
  },
  tipTitle: {
    fontSize: '34px',
    fontWeight: '450',
    lineHeight: '42px',
    marginBottom: '20px',
  },
  tipText: {
    fontSize: '20px',
    fontWeight: '300',
    lineHeight: '26px',
  },
};

export type ClassNames = keyof typeof commonStyles;
