import GoldMedal from 'assets/icons/GoldMedal';
import SilverMedal from 'assets/icons/SilverMedal';

export const RACES_DETAILS = [
  {
    id: 1,
    text: 'Elkhorn: ',
    position: '2nd',
    Icon: SilverMedal,
  },
  {
    id: 2,
    text: 'San Luis Rey: ',
    position: '1st',
    Icon: GoldMedal,
  },
  {
    id: 3,
    text: 'San Marcos: ',
    position: '1st',
    Icon: GoldMedal,
  },
];
