import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
    createStyles(
        mixStyleRules(commonStyles, {
            formFieldSmall: {
                width: "200px",
                height: "44px",
                marginBottom: "0",
                fontWeight: "300",
                fontSize: "16px",
                "& .MuiOutlinedInput-root": {
                    height: "44px",
                    fontWeight: "300",
                },
                "& .MuiAutocomplete-input": {
                    padding: "7.5px 4px 7.5px 6px !important",
                },
                "& input::placeholder": {
                    fontSize: "16px",
                }
            },
            placeholder: {
                fontSize: "16px",
            },
            uploadMainContainer: {
                flexDirection: "column",
            },
            title: {
                fontSize: "20px",
            },
            uploadContainer: {
                "&:first-of-type": {
                    marginBottom: "15px",
                }
            }
        })
    )
);
