import React, { useState, useEffect, useRef } from "react";

import useGetDisplayType from "../../../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../../../types/enums/DisplayTypes";

import { AssetOtherInfoProps } from "./AssetOtherInfo";

export interface SectionData {
  label: string;
  value: string;
}

export default function useAssetOtherInfoFacade({
  offer,
}: AssetOtherInfoProps): [
  boolean,
  SectionData[],
  SectionData[],
  string,
  string,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  string,
  React.MutableRefObject<HTMLDivElement | null>,
  HTMLImageElement | null,
  boolean,
  (event: React.MouseEvent) => void,
  string,
  string
] {
  const desktop = useGetDisplayType(DisplayTypes.Desktop);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLImageElement | null>(
    null
  );
  const open = Boolean(popoverAnchor);

  const onButtonClick = (event: React.MouseEvent): void => {
    // setPopoverAnchor(
    //   popoverAnchor ? null : (event.currentTarget as HTMLImageElement)
    // );
    window.open("https://luxusco.com/mine-to-market/");
  };

  const [isReadMoreClick, setIsReadMoreClick] = useState(false);

  const offerDetailsLenght = offer?.details.length;
  const halfLenght = Math.ceil(offerDetailsLenght / 2);

  const firstSectionData: SectionData[] = offerDetailsLenght
    ? offer.details
        .slice(0, halfLenght)
        .map(({ label, value }) => ({ label, value }))
    : [];

  const secondSectionData: SectionData[] = offerDetailsLenght
    ? offer.details
        .slice(halfLenght)
        .map(({ label, value }) => ({ label, value }))
    : [];

  const videoAsset = offer?.assets?.find(
    (item) => item.type === "videoDescription"
  );
  const videoSrc = videoAsset?.url ?? "";
  const videoPreview = videoAsset?.thumbnail ?? "";

  const shortDesc = offer?.fulldesc
    ? `${offer.fulldesc.split(".").slice(0, 3).join(".")}.`
    : "";

  return [
    desktop,
    firstSectionData,
    secondSectionData,
    shortDesc,
    offer?.fulldesc ?? "",
    isReadMoreClick,
    setIsReadMoreClick,
    offer?.name ?? "",
    popoverRef,
    popoverAnchor,
    open,
    onButtonClick,
    videoSrc,
    videoPreview,
  ];
}
