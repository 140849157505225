export const ContainerStyles = (isMobileScreen: boolean) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: `${isMobileScreen ? "24px" : "0px"} 10px 0px 10px`,
  maxWidth: "510px",
  textAlign: "center",
});

export const TitleStyle = {
  fontFamily: "Telugu MN",
  fontSize: "30px",
  lineHeight: "36.43px",
  letterSpacing: "1px",
  paddingBottom: "32px",
  color: "#fff",
};

export const ParagraphStyle = {
  fontSize: "20px",
  paddingBottom: "32px",
  color: "#fff",
};
