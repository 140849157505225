import { DisplayTypes } from "../types/enums/DisplayTypes";
import { setDisplayType } from "../store/slices/System";
import { dispatch } from "../store";
/**
 * Get diplay tyep from user screen widht
 * @returns {DisplayTypes}
 */
export function setDisplayTypeInStorage(): void {
  const width = window.screen.width;

  const setInfo = (type: DisplayTypes) => dispatch(setDisplayType(type));

  if (width >= 1400) {
    setInfo(DisplayTypes.Desktop);
    return;
  }
  if (width >= 992) {
    setInfo(DisplayTypes.TabletLandscape);
    return;
  }
  if (width >= 768) {
    setInfo(DisplayTypes.TabletPortrait);
    return;
  }

  setInfo(DisplayTypes.Mobile);
}
