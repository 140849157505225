import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "90%",
      maxWidth: "620px",
      transform: "translate(-50%, -50%)",
      overflow: "scroll",
    },
    container: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 0",
      borderTop: "1px solid #DFDFE3",
    },
    lastContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 0",
      borderTop: "1px solid #DFDFE3",
      borderBottom: "1px solid #DFDFE3",
    },
    addBtn: {
      color: "black",
      textTransform: "capitalize",
      padding: "15px 0",
    },
    headerBox: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "30px",
    },
    title: {
      fontSize: "30px",
      fontWeight: "450",
    },
    closeBtn: {
      width: "28px",
      minWidth: "28px",
      height: "28px",
      background: "#DFDFE3",
      borderRadius: "28px!important",
    },
  })
);
