import { useState } from "react";

import CreditCardForm from "./CreditCardForm/CreditCardForm";

import { STEPS } from "../AccreditationForm.data";

import style from "./style.module.scss";

type SixthStepProps = {
  setStep: (step: STEPS) => void;
  guid: string;
};

const SixthStep = ({ setStep, guid }: SixthStepProps) => {
  const [isCardAccepted, setIsCardAccepted] = useState(false);

  return (
    <>
      <div className={style.content}>
        <h2>THE TIARA OFFERING</h2>
        <CreditCardForm
          guid={guid}
          setStep={setStep}
          setIsCardAccepted={setIsCardAccepted}
          isCardAccepted={isCardAccepted}
        />
      </div>
    </>
  );
};

export default SixthStep;
