import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Box,
  Typography,
} from "@mui/material";
import { domicileChoices } from "../../../../../../types/Account";
import ReactGA from "react-ga";

import { useStyles } from "./styles";

interface CitizenshipStatusFormProps {
  setProfileDomicile(value: string): void;
  initCitizenshipStatus: string;
}

const CitizenshipStatusForm: React.FC<CitizenshipStatusFormProps> = function ({
  setProfileDomicile,
  initCitizenshipStatus,
}): JSX.Element {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/CitizenshipStatusForm");
  }, []);

  const [citizenshipStatus, setCitizenshipStatus] = useState(
    initCitizenshipStatus ?? ""
  );

  const onInputChange = (event: SelectChangeEvent): void => {
    setCitizenshipStatus(event.target.value);
    setProfileDomicile(event.target.value);
  };

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <FormControl className={classes.formBox}>
        <Select
          displayEmpty
          id="adress-state-select"
          value={citizenshipStatus}
          onChange={onInputChange}
          className={classes.select}
        >
          <MenuItem disabled value="">
            <span className={classes.placeholder}>Citizenship Status*</span>
          </MenuItem>
          {domicileChoices.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CitizenshipStatusForm;
