export const DETAILS = [
  {
    title: "16x",
    text: "Leading Stud Farm",
    id: 1,
  },
  {
    title: "$99m+",
    text: "Combined Stallion Progeny Earnings",
    id: 2,
  },
  {
    title: "320+",
    text: "Grade I Winners",
    id: 3,
  },
];
