import {
  Button as MuiButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { OpenInFull, CloseFullscreen } from "@mui/icons-material";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "./styles/";
import { Button } from "../../../../../components/simpleComponents/Button";
import { agreementText } from "./data";
import { userProfileSelector } from "../../../../../store/selectors/user";
import useGetDisplayType from "../../../../../hooks/useGetDisplayType";
import {DisplayTypes} from "../../../../../types/enums/DisplayTypes";

interface AgreementModalProps {
  isDesktop: boolean;
  isOpen: boolean;
  dateSign: Date;
  handleClose: () => void;
  handleDone: (value: string) => void;
}

export const AgreementModal: FC<AgreementModalProps> = ({
  isDesktop,
  isOpen,
  dateSign,
  handleClose,
  handleDone,
}) => {
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [infoNotReaded, setInfoNotReaded] = useState<boolean>(true);

  const mobile = useGetDisplayType(DisplayTypes.Mobile);

  const [sign, setSign] = useState<string>("");
  const [fullScreen, setFullScreen] = useState(false);
  const userProfile = useSelector(userProfileSelector);
  const userFullName = userProfile
    ? `${userProfile.firstName} ${userProfile.lastName}`
    : "";

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const height = scrollHeight - clientHeight - 250;
    if (scrollTop >= height) {
      infoNotReaded && setInfoNotReaded(false);
    }
  };

  const handleSignChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSign(value);
    if (value === userFullName) setIsDisabled(false);
    else setIsDisabled(true);
  };
  const handleClear = () => {
    setSign("");
  };

  const handleDoneClick = () => {
    handleDone(sign);
  };

  const handleIconClick = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper className={fullScreen ? classes.paperFullScreen : classes.paper}>
        <MuiButton onClick={handleClose} className={classes.closeBtn}>
          <CloseIcon />
        </MuiButton>
        <Box className={classes.textBox}>
          <textarea
            className={classes.textArea}
            onScroll={handleScroll}
            style={!isDesktop ? { padding: "10px" } : {}}
            disabled
          >
            {agreementText}
          </textarea>
          {fullScreen ? (
            <CloseFullscreen
              onClick={handleIconClick}
              className={classes.expandIcon}
            />
          ) : (
            <OpenInFull
              onClick={handleIconClick}
              className={classes.expandIcon}
            />
          )}
        </Box>

        { !mobile ? <Stack spacing={2} direction="row">
          <TextField
            className={classes.signField}
            value={sign}
            placeholder="Type your name here to sign"
            onChange={handleSignChange}
          />
          <TextField
            className={classes.dateField}
            value={dateSign.toLocaleDateString("en-US")}
          />
          <Button className={classes.clearBtn} onClick={handleClear}>
            CLEAR
          </Button>
          <Button
            className={classes.doneBtn}
            disabled={infoNotReaded || isDisabled || !sign.length}
            onClick={handleDoneClick}
          >
            DONE
          </Button>
        </Stack> : <Stack spacing={2} direction="column">
          <TextField
              className={classes.signField}
              value={sign}
              placeholder="Type your name here to sign"
              onChange={handleSignChange}
          />
          <Stack spacing={2} direction="row">
            <TextField
                className={classes.dateField}
                value={dateSign.toLocaleDateString("en-US")}
            />
            <Button className={classes.clearBtn} onClick={handleClear}>
              CLEAR
            </Button>
            <Button
                className={classes.doneBtn}
                disabled={infoNotReaded || isDisabled || !sign.length}
                onClick={handleDoneClick}
            >
              DONE
            </Button>
          </Stack>
        </Stack> }
        {(infoNotReaded || isDisabled || !sign.length) && (
          <Typography className={classes.errorText}>
            Please read through the fill subscription agreement and sign "
            {userFullName}" to match the name on your account.
          </Typography>
        )}
      </Paper>
    </Modal>
  );
};
