import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../utils/theme";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        image: {
          height: "105px",
          width: "105px",
          marginBottom: "11px",
        },
        offerName: {
          fontSize: "20px",
          marginBottom: "19px",
        },
        text: {
          fontSize: "11px",
          marginBottom: "19px",
        },
        btn: {
          padding: "5px 12.5px",
          fontSize: "12px",
        },
      })
    )
);
