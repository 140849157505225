import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Container,
  CssBaseline,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { setLoginData } from "store/slices/User";
import { useServerAPI } from "../../../apis";
import { submitZohoLead } from "../../../apis/zoho";

import ReactGA from "react-ga";
import { Preloader } from "../../../components/simpleComponents/Preloader";
import AuthErrorResponse from "../../../types/AuthErrorResponse";
import { accountStatus, useWindowDimensions } from "../../../utils";
import { validateEmail } from "../../../utils/Validators";

import { InputField } from "components/simpleComponents/Input/InputField/InputField";
import InputSubmit from "components/simpleComponents/Input/InputSubmit/InputSubmit";
import RadioButton from "components/simpleComponents/Radio/RadioButton";
import DiamondLayout from "layout/DiamondLayout/DiamondLayout";
import { useStyles } from "./styles";

type formDataType = {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  email: string;
};
type formErrorType = {
  firstName?: string;
  lastName?: string;
  username?: string;
  password?: string;
  email?: string;
};

const SignupPage = () => {
  const dispatch = useDispatch();
  let api = useServerAPI();
  let navigate = useNavigate();
  const desktop = useMediaQuery("(min-width:900px)");
  const { height } = useWindowDimensions();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/signUp");
  }, []);

  const [inputs, setInputs] = useState<formDataType>({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
  });
  const [errors, setErrors] = useState<formErrorType>({});
  const [authError, setAuthError] = useState<AuthErrorResponse | null>(null);
  const [termsAgreedStatus, setTermsAgreedStatus] = useState(false);
  const [termsAgreedError, setTermsAgreedError] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (termsAgreedStatus) {
      setTermsAgreedError(false);
    }
  }, [termsAgreedStatus]);

  useEffect(() => {
    const firstName = localStorage.getItem("signUpFirstName") || "";
    const lastName = localStorage.getItem("signUpLastName") || "";
    const email = localStorage.getItem("signUpEmail") || "";
    setInputs((prev) => ({
      ...prev,
      firstName,
      lastName,
      email,
    }));
  }, []);

  const requiredFieldMsgs: { [key: string]: string } = {
    firstName: "First Name is required.",
    lastName: "Last Name is required.",
    email: "Email is required.",
    // 'username': 'Username is required.',
    password: "Password must be at least 3 character long.",
  };

  const submitSignupForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var currentErrors = errors;

    if (!inputs.firstName || inputs.firstName.length < 1)
      currentErrors.firstName = requiredFieldMsgs.firstName;
    if (!inputs.lastName || inputs.lastName.length < 1)
      currentErrors.lastName = requiredFieldMsgs.lastName;
    if (!inputs.email || !validateEmail(inputs.email))
      currentErrors.email = requiredFieldMsgs.email;
    // if (!inputs.username || inputs.username.length < 1) currentErrors.username = requiredFieldMsgs.username;
    if (!inputs.password || inputs.password.length < 3)
      currentErrors.password = requiredFieldMsgs.password;

    if (!termsAgreedStatus) {
      setTermsAgreedError(true);
    } else {
      setWarningText("");
    }

    if (
      Object.values(currentErrors).filter((i) => !!i).length === 0 &&
      termsAgreedStatus
    ) {
      // setFormSubmitted(true);
      setAuthError(null);
      setIsLoading(true);
      try {
        submitZohoLead({
          email: inputs.email,
          firstName: inputs.firstName,
          lastName: inputs.lastName,
        });

        const response = await api.signUp({
          ...inputs,
          username: inputs.email,
        });

        if (response?.data?.token) {
          if (!accountStatus().isApproved)
            dispatch(
              setLoginData({
                username: inputs.email,
                password: inputs.password,
              })
            );

          localStorage.setItem(
            "fullUserName",
            `${inputs.firstName} ${inputs.lastName}`
          );
          localStorage.setItem("email", inputs.email);
          localStorage.setItem("rppToken", response.data.token);
          navigate("/dashboard");
        }
      } catch (err: any) {
        if (
          err?.response?.data?.error?.message === "Internal Server Error" &&
          err?.response?.data?.error?.statusCode === 500
        ) {
          setIsLoading(false);
          setWarningText("This email is already in use!");
          return;
        }

        setIsLoading(false);
        if (
          err.response?.data?.error?.statusCode === 423 &&
          err.response?.data?.error?.message ===
            "Processing exception (message): Email not validated"
        ) {
          console.log({
            ...inputs,
            username: inputs.email,
          });
          setIsLoading(false);
          localStorage.setItem("userFirstName", inputs.firstName);
          localStorage.setItem("userLastName", inputs.lastName);
          navigate("/pendingValidation", { state: { email: inputs.email } });
        } else if (err.response?.data?.error) {
          setAuthError(err.response?.data?.error);
        }
      }
    } else {
      setErrors(currentErrors);
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAuthError(null);

    if (!value || value.length < 1) {
      console.warn(` Missing ${name} : ${value}`);
      setErrors({ ...errors, [name]: requiredFieldMsgs[name] });
    } else if (name === "password" && value.length < 3) {
      console.warn(` Invalid ${name} : ${value}`);
      setErrors({ ...errors, [name]: requiredFieldMsgs[name] });
    } else {
      if (name === "email" && !validateEmail(value)) {
        console.warn(` Invalid Email address ${name} : ${value}`);
        setErrors({ ...errors, [name]: "Invalid email address format" });
      } else {
        setErrors({ ...errors, [name]: undefined });
      }
    }

    setInputs({ ...inputs, [name]: value });
    if (name === "firstName") localStorage.setItem("signUpFirstName", value);
    if (name === "lastName") localStorage.setItem("signUpLastName", value);
    if (name === "email") localStorage.setItem("signUpEmail", value);
  };

  const classes = useStyles();

  const signupForm = (
    <Box>
      <Box
        component="form"
        className={classes.formContent}
        noValidate
        onSubmit={submitSignupForm}
      >
        <Typography
          fontSize={"30px"}
          lineHeight={"36.43px"}
          fontFamily={"Telugu MN"}
          textAlign={"center"}
          paddingBottom={"16px"}
          color={"#fff"}
        >
          START TO INVEST
        </Typography>
        <Typography
          fontSize={"20px"}
          lineHeight={"25.64px"}
          letterSpacing={"1px"}
          textAlign={"center"}
          paddingBottom={desktop ? "64px" : "16px"}
          color={"#fff"}
        >
          Sign up for an account to start your investment journey.
        </Typography>
        <Stack direction={`${desktop ? "row" : "column"}`} gap={"15px 10px"}>
          <InputField.Black
            placeholder="FIRST NAME"
            value={inputs.firstName}
            onChange={onChangeHandler}
            name="firstName"
            error={errors.firstName ? true : false}
            errorText={errors.firstName}
          />

          <InputField.Black
            placeholder="LAST NAME"
            value={inputs.lastName}
            onChange={onChangeHandler}
            name="lastName"
            error={errors.lastName ? true : false}
            errorText={errors.lastName}
          />
        </Stack>

        <Box sx={{ padding: "10px 0px" }}>
          <InputField.Black
            placeholder="EMAIL ADDRESS"
            value={inputs.email}
            onChange={onChangeHandler}
            name="email"
            error={errors.email ? true : false}
            errorText={errors.email}
          />
        </Box>

        <InputSubmit.Black
          placeholder="PASSWORD"
          value={inputs.password}
          onChange={onChangeHandler}
          name="password"
          buttonText="SIGN UP"
          eye={true}
          onSubmit={submitSignupForm}
          error={errors.password ? true : false}
          errorText={errors.password}
        />
        <Box
          className={classes.checkboxContainer}
          sx={{ padding: `15px 0px ${desktop ? "64px" : "36px"} 0px` }}
        >
          <RadioButton.White
            label=""
            value={termsAgreedStatus}
            onChange={() => {}}
            onClick={() => {
              setTermsAgreedStatus((prev) => !prev);
            }}
            error={termsAgreedError}
          />
          <Typography sx={{ color: "#fff" }}>
            I have read and agree to the{" "}
            <Link target="_blank" style={{ color: "inherit" }} to={"/privacy"}>
              Privacy Policy
            </Link>
            ,{"  "}
            <Link
              target="_blank"
              style={{ color: "inherit" }}
              to={"/termsOfService"}
            >
              Terms of Service
            </Link>
          </Typography>
        </Box>
        {authError && (
          <Typography variant="body2" className={classes.error}>
            {authError?.statusCode === 403
              ? "Account already registered."
              : JSON.parse(authError.message)?.status_message}
          </Typography>
        )}
        {warningText && warningText !== "" && (
          <Typography variant="body2" className={classes.warning}>
            {warningText}
          </Typography>
        )}

        {/* <Typography
          variant="body2"
          fontSize={"20px"}
          textAlign={"center"}
          color={"#fff"}
        >
          Already part of the LUXUS community?
          <Link
            to="/login"
            style={{
              fontSize: "20px",
              color: "#fff",
              marginLeft: "4px",
            }}
          >
            Sign in
          </Link>
        </Typography> */}
      </Box>
    </Box>
  );

  return (
    <>
      <DiamondLayout link="/signup">
        <Container sx={{ maxWidth: "508px" }}>
          <CssBaseline />
          <Box className={classes.content}>
            {isLoading && !errors.email ? <Preloader /> : signupForm}
          </Box>
        </Container>
      </DiamondLayout>
    </>
  );
};

export default SignupPage;
