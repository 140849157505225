import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useTabletPortraitStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          marginRight: "0px",
          overflow: "visible",
        },
        preview: {
          height: "455px",
          width: "455px",
          border: "1px solid #DFDFE3",
        },
        thumbs: {
          width: "105px",
          height: "400px",
          "& img": {
            height: "100px",
            width: "100px",
            border: "1px solid #DFDFE3",
            marginBottom: "9px",
          },
        },
      })
    )
);
