import { createStyles, makeStyles } from "@mui/styles";

export const useOfferGridStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 360px)",
      justifyContent: "space-between",
      justifyItems: "start",
      rowGap: "40px",
      margin: "0 auto",
      width: "1200px",
    },
    containerMobile: {
      marginBottom: "125px",
    },
  })
);
