import { Box, SxProps, Theme } from "@mui/material";
import { FC } from "react";
import classNames from "classnames";

import "./style.css";

type PreloaderProps = {
  sx?: SxProps<Theme>;
  isWhite?: boolean;
  isSmallHeight?: boolean;
};

export const Preloader: FC<PreloaderProps> = ({
  sx,
  isWhite,
  isSmallHeight = false,
}) => {
  const loaderClasses = classNames("preloader__image_animate", {
    preloader__image_animate_white: isWhite,
  });

  const wrapperClasses = classNames("preloader", {
    preloader__small_height: isSmallHeight,
  });

  return (
    <Box className={wrapperClasses} sx={sx}>
      <div className={loaderClasses}></div>
    </Box>
  );
};
