import { useMediaQuery } from '@mui/material';

import { CustomButton } from 'components/UIComponents/Button';
import styles from './Hero.module.scss';
import { RACES_DETAILS } from './constants';

type HeroProps = {
  changeModalVisible: () => void;
};

const Hero = ({ changeModalVisible }: HeroProps) => {
  const isDesktop = useMediaQuery('(min-width: 993px)');

  return (
    <section className={styles.heroContainer}>
      <div className={styles.textsWrapper}>
        <h1 className={styles.heroTitle}>MISSED THE CUT</h1>
        <div className={styles.missedTheCutInfo}>
          <>
            <div className={styles.missedTextWrapper}>
              <p className={styles.missedTextBold}>
                Lane’s End{' '}
                <span className={styles.missedTitleText}>| Kentucky</span>
              </p>
            </div>
            <div
              className={styles.missedTextWrapper}
              style={{ margin: isDesktop ? '0' : '40px 0 0 0' }}
            >
              <p className={styles.missedTitle}>
                Trainer:{isDesktop ? null : <br />}{' '}
                <span className={styles.missedTitleText}>
                  John W. Sadler (2,700 wins)
                </span>
              </p>
            </div>
          </>
          <div
            className={styles.missedSumWrapper}
            style={{ margin: isDesktop ? '0' : '30px 0 0 0' }}
          >
            <p className={styles.missedTitle}>
              Total Winnings:{isDesktop ? null : <br />}{' '}
              <span className={styles.missedPrice}>$ 512,277</span>
            </p>
            <p className={styles.missedTitle}>
              2024 Winnings:{isDesktop ? null : <br />}{' '}
              <span className={styles.missedPrice}>$ 266,500</span>
            </p>
          </div>
          <div
            className={styles.raceListWrapper}
            style={{ margin: isDesktop ? '0' : '30px 0 0 0' }}
          >
            <p className={styles.raceListTitle}>
              Last Three Races {isDesktop ? '-' : ':'}{' '}
            </p>
            <ul className={styles.missedList}>
              {RACES_DETAILS.map(({ id, Icon, text, position }) => {
                return (
                  <li className={styles.missedListItem} key={id}>
                    <span className={styles.missedTitleText}>{text}</span>
                    <span className={styles.missedPosition}>{position}</span>
                    <Icon />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={styles.raceWrapper}>
          <p className={styles.raceTitle}>
            Next Race:{' '}
            <span className={styles.missedTitleText}>Royal Ascot 2024</span>
          </p>
          {/* {!isMobile && ( */}
          {/* <>
            <div className={styles.buttonWrapper}>
              <CustomButton
                onClick={changeModalVisible}
                btnStyle={
                  isDesktop
                    ? "iconBtnPrimaryBackground"
                    : "iconBtnPrimaryBackground"
                }
              >
                INVEST NOW
              </CustomButton>
            </div>
          </> */}
          {/* )} */}
        </div>
        {/* <p className={styles.note}>For Accredited Investors Only</p> */}
      </div>
    </section>
  );
};

export default Hero;
