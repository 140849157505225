import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      content: {
        margin: "25px",
        width: "auto",
      },
      title: {
        fontSize: "30px",
        fontWeight: "450",
      },
    })
  )
);
