import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";
import ReactGA from "react-ga";

import { useStyles } from "./styles";

interface FinraLicenseFormProps {
  setIsHaveFinraLicenseProfile(value: string): void;
  setFinraLicenseProfile(value: string): void;
  initIsHaveLicense: string;
  initFinraLicenseProfile: string;
}

const FinraLicenseForm: React.FC<FinraLicenseFormProps> = function ({
  setIsHaveFinraLicenseProfile,
  initIsHaveLicense,
  setFinraLicenseProfile,
}): JSX.Element {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + "/FinraLicenseForm");
  }, []);

  const [isHaveLicense, setIsHaveLicense] = useState<string>(initIsHaveLicense);

  const onHaveLicenseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const result = event.target.value;
    setIsHaveLicense(result);
    setIsHaveFinraLicenseProfile(result);
  };

  useEffect(() => {
    if (isHaveLicense === "no") {
      setFinraLicenseProfile("");
    }
  }, [isHaveLicense]);

  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.container}>
        <RadioGroup
          name="accreditaion-status-group"
          row
          onChange={onHaveLicenseChange}
        >
          <FormControlLabel
            key="yes"
            label="Yes"
            control={
              <Radio
                color="default"
                size="medium"
                checked={isHaveLicense === "yes"}
              />
            }
            value={"yes"}
            className={classes.label}
          />
          <FormControlLabel
            key="no"
            label="No"
            control={
              <Radio
                color="default"
                size="medium"
                checked={isHaveLicense === "no"}
              />
            }
            value={"no"}
            className={classes.label}
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default FinraLicenseForm;
