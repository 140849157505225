import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      sign: {
        fontSize: "23px",
        lineHeight: "64px",
      },
      field: {
        width: "240px",
        height: "44px",
        fontSize: "15px",
        margin: "15px 0 0",
        "& .MuiInputBase-input": {
          fontSize: "15px",
          padding: "12.5px 14px",
          "&::placeholder": {
            fontSize: "15px",
          },
        },
      },
    })
  )
);
