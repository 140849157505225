import { useEffect, useState } from "react";

import Header from "components/Header/Header";
import { Footer } from "components/Footer/Footer";
import UserDetails from "./components/UserDetails/UserDetails";
// import PaymentDetails from "./components/PaymentDetails/PaymentDetails";
import EntitiesDetails from "./components/EntitiesDetails/EntitiesDetails";
import EditUserData from "./components/EditUserData/EditUserData";
import { Preloader } from "components/simpleComponents/Preloader";

import { HEADER_OPTIONS } from "components/Header/constants";
import { UserProfile } from "types/Account";
import { useServerAPI } from "apis";
import { MAIN_URL, ROUTES } from "utils/constants";

import styles from "./NewProfile.module.scss";
import { INITIAL_USER_DATA } from "./constants";

const NewProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] =
    useState<UserProfile>(INITIAL_USER_DATA);

  const api = useServerAPI();

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      setIsLoading(true);

      const profileResponse = await api.getUserProfile();
      if (profileResponse.data.response.profile)
        setProfileData(profileResponse.data.response.profile);
      if (profileResponse.data.response.profile.emailAddress) {
        setProfileData(profileResponse.data.response.profile);
      } else {
        const tokenData = api.getTokenData();

        if (tokenData?.email) {
          setProfileData({
            ...INITIAL_USER_DATA,
            firstName: tokenData?.name ?? "",
            emailAddress: tokenData.email,
          });
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      console.error("err:myAccount:fetchProfileData:catch", error);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Header color={HEADER_OPTIONS.BLACK} />
      {isLoading ? (
        <Preloader />
      ) : (
        <div className={styles.wrapper}>
          {isEdit ? (
            <EditUserData
              setIsEdit={setIsEdit}
              profileData={profileData}
              fetchProfileData={fetchProfileData}
              setIsLoading={setIsLoading}
            />
          ) : (
            <UserDetails setIsEdit={setIsEdit} profileData={profileData} />
          )}
          {/* <PaymentDetails /> */}
          <EntitiesDetails entities={profileData.associatedAccounts} />
        </div>
      )}
      <Footer
        pageName="Profile page"
        pageUri={`${MAIN_URL}${ROUTES.PROFILE}`}
      />
    </div>
  );
};

export default NewProfile;
