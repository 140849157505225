import React, { useEffect, useState } from 'react';

import { Preloader } from '../../../components/simpleComponents/Preloader';
import ChooseUs from './components/ChooseUs/ChooseUs';
import Hero from './components/Hero/Hero';
import HowItWorks from './components/HowItWorks/HowItWorks';
import IntersectionBlock from './components/IntersectionBlock/IntersectionBlock';
import OurOfferings from './components/OurOfferings/OurOfferings';
import ThoroughbredAnnounce from './components/ThoroughbredAnnounce/ThoroughbredAnnounce';
import StreamCountdown from './components/StreamCountdown/StreamCountdown';

import Header from '../../../components/Header/Header';
import { HEADER_OPTIONS } from '../../../components/Header/constants';
import { FirstVariant } from './components/LuxusXGarrard/FirstVariant/FirstVariant';

import './style.scss';
import useAssetsFacade from './useAssetsFacade';
import JoinForm from './components/JoinForm/JoinForm';

import { MAIN_URL, ROUTES } from 'utils/constants';
import { handleSectionScroll } from 'utils/handleSectionScroll';
import {
  SCROLL_QUERY_PARAM,
  STREAM_SECTION_ID,
} from './components/StreamCountdown/constants';

const AssestsAlt: React.FC = function (): JSX.Element {
  useEffect(() => {
    handleSectionScroll(SCROLL_QUERY_PARAM, STREAM_SECTION_ID);
  }, []);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);

  const [
    desktop,
    isShowMoreCurrent,
    setIsShowMoreCurrent,
    isShowMoreUpcoming,
    setIsShowMoreUpcoming,
    activeOfferings,
    futureOfferings,
    isLoading,
    getOnItemBuyClick,
    getOnItemIOIClick,
    setSortType,
    userFirstName,
  ] = useAssetsFacade();

  return isLoading ? (
    <>
      <Header color={HEADER_OPTIONS.BLACK} />
      <Preloader />
    </>
  ) : (
    <>
      <div className={'assetsContainer'}>
        <Hero />
        <IntersectionBlock />
        <ChooseUs />
        {/* <StreamCountdown /> */}
        <FirstVariant />
        {/* <ThoroughbredAnnounce /> */}
        <OurOfferings
          activeOfferings={activeOfferings}
          isShowMoreUpcoming={isShowMoreUpcoming}
          setIsShowMoreUpcoming={setIsShowMoreUpcoming}
        />
        <HowItWorks />
        <div className={'formContainer'}>
          <h2 className={'title'}>JOIN OUR NEWSLETTER</h2>
          <h6 className={'text'}>
            Subscribe to our newsletter for insights, updates and more
          </h6>
          <JoinForm
            pageName="Assets page"
            pageUri={`${MAIN_URL}${ROUTES.ASSETS}`}
            buttonText={'SUBSCRIBE'}
            setIsUserSubscribed={setIsUserSubscribed}
            isUserSubscribed={isUserSubscribed}
          />
        </div>
      </div>
    </>
  );
};

export default AssestsAlt;
