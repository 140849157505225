import { useNavigate } from "react-router-dom";

import { CustomButton } from "components/UIComponents/Button";

import styles from "./ModalJoinSubscribed.module.scss";

const ModalJoinSubscribed = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>WELCOME TO LUXUS!</p>
      <p className={styles.text}>
        You will start receiving our newsletter. Click the button below to
        create a LUXUS account to start your investment journey.
      </p>
      <div className={styles.buttonWrapper}>
        <CustomButton btnStyle="secondary" onClick={() => navigate("/signup")}>
          FINISH SIGN UP
        </CustomButton>
      </div>
    </div>
  );
};

export default ModalJoinSubscribed;
