import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { Offering } from "../../../../../types/Offerings";
import ActiveOfferingCard from "./ActiveOfferCard";
import FeaturedOfferingCard from "./FeaturedOfferingCard";
import SoldOutOfferingCard from "./SoldOutOfferingCard";
import SoldPrivatelyOfferingCard from "./SoldPrivatelyOfferCard";
import { useStyles } from "./styles/";

export const SimilarOfferingNew: FC<{ offer: Offering }> = ({ offer }) => {

  return (
    <div>
      {offer.status === "Active" && <ActiveOfferingCard offer={offer} />}
      {offer.status === "IOI" && <FeaturedOfferingCard offer={offer} />}
      {offer.status === "SoldOut" && <SoldOutOfferingCard offer={offer} />}
      {offer.status === "soldPrivately" && <SoldPrivatelyOfferingCard offer={offer} />}
    </div>
  );
};
