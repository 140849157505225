import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import {FC} from "react";
import {useStyles} from "./styles/";
import style from './style.module.scss'

export const Toggles: FC<{
  isDesktop: boolean;
  toggleSubscribtion: () => void;
  toggleTerms: () => void;
}> = ({children, isDesktop, toggleSubscribtion, toggleTerms}) => {
  const classes = useStyles();
  return (
    <>
      {/* Subscription toggler */}
      <div className={style.container}>
        <div>
          <FormControlLabel
            sx={{alignItems: "flex-start", marginLeft: 0}}
            control={
              <Checkbox
                className={classes.checkbox}
                onChange={toggleSubscribtion}
              />
            }
            label={
              <p>
                You must agree to the terms in the{" "}
                <Link className={classes.link} href="#">
                  Subscription Agreement
                </Link>{" "}
                to confirm your understanding of the transaction, the number
                of shares being sold and the price per share, and any legally
                binding confidentiality agreements and clauses.
              </p>
            }
          />
        </div>
        <div className={style.btn}>
          {children}
        </div>
      </div>

      {/* Terms toggler */}
      <div className={style.container}>
        <FormControlLabel
          sx={{alignItems: "flex-start", marginLeft: 0}}
          control={
            <Checkbox className={classes.checkbox} onChange={toggleTerms}/>
          }
          label={
            <p>
              By checking this box, you authorize this transaction and agree to
              our{" "}
              <Link target="_blank" className={classes.link} href="#">
                Terms of Service
              </Link>
              .
            </p>
          }
        />
      </div>
    </>
  );
};
