import { FC } from "react";

import { Box, Typography } from "@mui/material";
import { InputFieldProps } from "./InputFiled.type";
import { StyledBlackTextField, StyledWhiteTextField } from "./styledComponent";

const BlackInputField: FC<InputFieldProps> = (props) => {
  return (
    <Box width={"100%"}>
      <StyledBlackTextField
        error={props.error}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        variant="outlined"
        name={props.name}
        style={{ width: "100%" }}
        sx={{
          input: {
            color: "white",
            "&::placeholder": { opacity: 1, fontSize: "14px" },
            letterSpacing: "0.05em",
            lineHeight: "18px",
            textAlign: props?.textAlign ? props.textAlign : "left",
          },
        }}
      />
      {props.error && (
        <Typography sx={{ color: "red", padding: "0px 0px 0px 10px" }}>
          {props.errorText}
        </Typography>
      )}
    </Box>
  );
};

const WhiteInputField: FC<InputFieldProps> = (props) => {
  return (
    <Box width={"100%"}>
      <StyledWhiteTextField
        error={props.error}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        fontSize={props?.fontSize}
        variant="outlined"
        name={props.name}
        style={{ width: "100%" }}
        sx={{
          input: {
            textAlign: props?.textAlign ? props.textAlign : "left",
            color: "#000",
            "&::placeholder": {
              opacity: 1,
              fontSize: props?.fontSize ? `${props.fontSize}px` : "14px",
              color: "#7B7B8F",
            },
            letterSpacing: "0.05em",
            lineHeight: "18px",
          },
        }}
      />
      {props.error && (
        <Typography sx={{ color: "red", padding: "0px 0px 0px 10px" }}>
          {props.errorText}
        </Typography>
      )}
    </Box>
  );
};

export const InputField = {
  Black: (props: InputFieldProps) => (
    <BlackInputField
      placeholder={props.placeholder}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      error={props.error}
      errorText={props.errorText}
      textAlign={props?.textAlign}
    />
  ),
  White: (props: InputFieldProps) => (
    <WhiteInputField
      placeholder={props.placeholder}
      disabled={props.disabled}
      value={props.value}
      fontSize={props?.fontSize}
      textAlign={props?.textAlign}
      onChange={props.onChange}
      name={props.name}
      error={props.error}
      errorText={props.errorText}
    />
  ),
};
