import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(mixStyleRules(commonStyles, {
      paper: {
          height: "90%",
      },
      textArea: {
        height: "550px",
      },
      errorText: {
          fontSize: "12px",
      },
      clearBtn: {
          fontSize: "20px",
          height: "58px",
      },
      doneBtn: {
          fontSize: "20px",
          height: "58px",
      },
      dateField: {
          fontSize: "20px",
      }
  }))
);
