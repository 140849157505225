import { Button, SxProps, Theme } from '@mui/material';
import { themeData } from '../../../utils/theme';

type CancelButtonProps = {
  title: string;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onClick?: () => void;
};

export const CancelButton = ({
  title,
  sx,
  onClick,
  disabled = false,
}: CancelButtonProps) => (
  <Button
    type="submit"
    variant="outlined"
    fullWidth
    disabled={disabled}
    sx={{
      backgroundColor: themeData.CancelButtonBackground,
      border: '0px solid #000000',
      display: 'block',
      borderRadius: '3px !important',
      width: '367px',
      height: '61px',
      fontFamily: themeData.DefaultFontDemi,
      color: themeData.WhiteColor,
      fontWeight: 'bold',
      '&:hover': {
        background: themeData.GreyLabel,
      },
      textTransform: 'uppercase',
      padding: '8px 0',
      fontSize: '22px',
      ...sx,
    }}
    onClick={() => {
      if (onClick) onClick();
    }}
  >
    {title}
  </Button>
);

export default CancelButton;
