import { themeData } from "../../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    backgroundColor: "#F4F4F4",
    padding: "20px",
    borderRadius: "0 0 15px 15px",
    //marginBottom: "30px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    //padding: "26px 0px 40px 0px",
  },
  content1: {
    display: "inline-block",
    fontSize: "24px",
    fontWeight: "300",
    width: "100%",
    textAlign: "justify",
    padding: "26px 0px 40px 0px",
  },
  title: {
    fontWeight: "500",
    flexBasis: "156px",
  },
  text: {
    fontSize: "23px",
    fontWeight: "300",
  },
  assetDetailInfoPanelTabsIndicator: {
    height: "0px !important",
  },
  assetDetailInfoPanelTabSelected: {
    backgroundColor: "#f4f4f4 !important",
    color: "black !important",
  },
  textBr: {
    display: "block",
    height: "20px",
    fontSize: "20px",
    //margin: "25px 0 0 0",
    content: '" "',
  },
  tab: {
    fontSize: "19px",
    lineHeight: "38px",
    borderRadius: "5px 5px 0 0 !important",
    fontWeight: "600",
  },
  popoverBtnText: {
    fontSize: "23px",
    fontWeight: "500",
    flexBasis: "156px",
    padding: 0,
    color: "black",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  popoverText: {
    backgroundColor: "#D9D9D9 !important",
    maxWidth: "567px",
    padding: "20px",
    overflow: "visible",
    fontSize: "20px",
    fontWeight: "300",
    lineHeight: "26px",
  },
  popoverAnchor: {
    position: "relative",
    width: "30px",
    backgroundColor: "transparent",
    flexShrink: 0,
    "&::before": {
      backgroundColor: "#D9D9D9 !important",
      content: '""',
      display: "block",
      position: "absolute",
      width: 30,
      height: 30,
      bottom: "calc(50% - 15px)",
      left: "15px",
      transform: "rotate(45deg)",
      zIndex: "1",
    },
  },
  // anchorMobile: {
  //   position: "relative",
  //   width: "100%",
  //   height: "30px",
  //   backgroundColor: "transparent",
  //   "&::before": {
  //     backgroundColor: "#D9D9D9 !important",
  //     content: '""',
  //     display: "block",
  //     position: "absolute",
  //     width: 30,
  //     height: 30,
  //     left: "calc(75% - 15px)",
  //     top: "15px",
  //     transform: "rotate(45deg)",
  //     zIndex: "1",
  //   },
};

export type ClassesNames = keyof typeof commonStyles;
