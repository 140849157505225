export enum BUTTON_TEXT {
  EDIT = "EDIT PROFILE",
  SAVE = "SAVE PROFILE",
}

export enum BUTTON_OPTIONS {
  EDIT = "buttonTransparentWhiteText",
  SAVE = "primary",
}

export const INITIAL_USER_DATA = {
  associatedAccounts: [],
  domicile: "",
  firstName: "",
  lastName: "",
  socialSecurityNumber: "",
  dob: "",
  phone: "",
  primCountry: "",
  primAddress1: "",
  primAddress2: "",
  primCity: "",
  primState: "",
  primZip: "",
  emailAddress: "",
};
