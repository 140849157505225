export enum ATTESTATIONS {
  INDIVIDUAL_NET_WORTH = "Individual net worth or joint net worth with your spouse or spouse equivalent, is greater than $1M USD (excluding your primary residence)",
  INDIVIDUAL_OR_JOINT_INCOME = "Individual income exceeds $200K in each of the two most recent years or joint income with your spouse or spousal equivalent in excess of $300K in the last two years and have a reasonable expectation of reaching the same income level in the current year ",
  TRUST_ASSETS = "Any trust with total assets in excess of $5M",
  ENTITY_INVESTORS = "Investing using an entity in which all of the equity owners are accredited investors",
  NON_LISTED_ENTITY_INVESTMENTS = "Any entity not listed above and not formed for the specific purpose of acquiring the securities offered, owning investments in excess of $5M ",
  FAMILY_OFFICE = "Any family office with AUM in excess of $5M (not formed for this specific investment and is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment)",
  PROFESSIONAL_DESIGNATION = "Professional designation",
}

export const ATTESTATIONS_OPTIONS = Object.freeze([
  {
    id: 1,
    label: ATTESTATIONS.INDIVIDUAL_NET_WORTH,
    props: {
      name: "individualNetWorth",
      value: "INDIVIDUAL_NET_WORTH",
    },
  },
  {
    id: 2,
    label: ATTESTATIONS.INDIVIDUAL_OR_JOINT_INCOME,
    props: {
      value: "INDIVIDUAL_OR_JOINT_INCOME",
      name: "individualOrJointIncome",
    },
  },
  {
    id: 3,
    label: ATTESTATIONS.TRUST_ASSETS,
    props: {
      value: "TRUST_ASSETS",
      name: "trustAssets",
    },
  },
  {
    id: 4,
    label: ATTESTATIONS.ENTITY_INVESTORS,
    props: {
      value: "ENTITY_INVESTORS",
      name: "entityInvestors",
    },
  },
  {
    id: 5,
    label: ATTESTATIONS.NON_LISTED_ENTITY_INVESTMENTS,
    props: {
      value: "NON_LISTED_ENTITY_INVESTMENTS",
      name: "nonListedEntityInvestments",
    },
  },
  {
    id: 6,
    label: ATTESTATIONS.FAMILY_OFFICE,
    props: {
      value: "FAMILY_OFFICE",
      name: "familyOffice",
    },
  },
  {
    id: 7,
    label: ATTESTATIONS.PROFESSIONAL_DESIGNATION,
    props: {
      value: "PROFESSIONAL_DESIGNATION",
      name: "professionalDesignation",
    },
  },
]);
