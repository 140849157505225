import { makeStyles, createStyles } from "@mui/styles";
import mixStyleRules from "../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { FooterClassNameMap } from "./index";

export const useTabletPortraitStyles: (props?: any) => FooterClassNameMap =
  makeStyles(() =>
    createStyles(
      mixStyleRules(commonStyles, {
        finePrintTitle: {
          // marginBottom: "0",
          paddingBottom: "0",
        },
        finePrint: {
          marginBottom: "15px",
        },
      })
    )
  );
