import InputSubmit from "components/simpleComponents/Input/InputSubmit/InputSubmit";

import { PasswordsErrors } from "../EditUserData/useEditUserDataFacade";

import styles from "./ChangePasswordInputs.module.scss";

type ChangePasswordInputsProps = {
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  passwordErrors: PasswordsErrors;
};

const ChangePasswordInputs = ({
  handlePasswordChange,
  passwordErrors,
}: ChangePasswordInputsProps) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>CHANGE PASSWORD</p>
      <div className={styles.inputsWrapper}>
        <InputSubmit.White
          name="currentPassword"
          placeholder="CURRENT PASSWORD"
          buttonText=""
          eye={true}
          onChange={handlePasswordChange}
          onSubmit={() => {}}
          color="#000000"
          error={!!passwordErrors?.currentPassword}
          errorText={passwordErrors?.currentPassword}
        />
        <InputSubmit.White
          name="newPassword"
          placeholder="NEW PASSWORD"
          buttonText=""
          eye={true}
          onChange={handlePasswordChange}
          onSubmit={() => {}}
          color="#000000"
          error={!!passwordErrors?.newPassword}
          errorText={passwordErrors?.newPassword}
        />
        <InputSubmit.White
          name="confirmPassword"
          placeholder="CONFIRM PASSWORD"
          buttonText=""
          eye={true}
          onChange={handlePasswordChange}
          onSubmit={() => { }}
          color="#000000"
          error={!!passwordErrors?.confirmPassword}
          errorText={passwordErrors?.confirmPassword}
        />
      </div>
    </div>
  );
};

export default ChangePasswordInputs;
