import BigArrows from "./BigArrows";
import FamilyNode from "../FamilyNode/FamilyNode";

import styles from "./FamilyTree.module.scss";

const FamilyTree = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img
          className={styles.image}
          src="/images/TreeNode/1stTree.jpg"
          alt=""
        />
        <p className={styles.text}>Missed the Cut</p>
        <div>
          <BigArrows />
        </div>
        <div className={styles.smallNodesContainer}>
          <FamilyNode
            topImage="/images/TreeNode/2ndTree.jpg"
            topName="Quality Road"
            firstName="Elusive Quality"
            secondName="Kobla"
            firstImage="/images/TreeNode/4thTree.jpg"
            secondImage="/images/TreeNode/5thTree.jpg"
          />
          <FamilyNode
            firstName="Sea the Stars"
            secondName="Pickle"
            firstImage="/images/TreeNode/6thTree.jpg"
            secondImage="/images/TreeNode/7thTree.jpg"
            topImage="/images/TreeNode/3rdTree.jpg"
            topName="Beauly"
          />
        </div>
      </div>
    </div>
  );
};

export default FamilyTree;
