import { CustomButton } from '../../../components/UIComponents/Button';
import { CardType } from '../data';

import styles from './UnloggedCard.module.scss';
import './../../NewAssests/Assets/components/CurrentOfferingAlt/components/BasicOfferCard/style.scss';

type ExtendedCardType = CardType & {
  onByBtnClick: () => void;
  isHideHint?: boolean;
};

export const UnloggedCard: React.FC<ExtendedCardType> = ({
  btnType,
  buttonText,
  name,
  shortdesc,
  rightDetails,
  leftDetails,
  image,
  leftCornerTitle,
  price,
  value,
  onByBtnClick,
  factSheetButtonText,
  factSheetLink,
  children,
  isHideHint = false,
}) => {
  const onFactSheetBtnClick = (): void => {
    window.open(factSheetLink, '_blank');
  };
  return (
    <div className={'container'}>
      {!isHideHint && (
        <div className={'labelItem'}>
          <h2 className={'labelText'}>{leftCornerTitle}</h2>
        </div>
      )}
      <div className={'content'}>
        <img src={image} className={'image'} alt={name} />
        <div className={'contentInfo'}>
          <h2 className={'itemTitle'}>{name}</h2>
          <h3 className={'itemDescription'}>{shortdesc}</h3>
          <div className={'moreDescription'}>
            <div className={'infoContainer'}>
              <hr className={'line'} />
              {leftDetails.map((item) => (
                <div key={item.label}>
                  <div className={'table'}>
                    <h4 className={'labelName'}>{item.label}</h4>
                    <h4 className={'valueText'}>{item.value}</h4>
                  </div>
                  <hr className={'line'} />
                </div>
              ))}
            </div>
            <div className={'infoContainer'}>
              <hr className={'lineMore'} />
              {rightDetails.map((item) => (
                <div key={item.label}>
                  <div className={'table'}>
                    <h4 className={'labelName'}>{item.label}</h4>
                    <h4 className={'valueText'}>{item.value}</h4>
                  </div>
                  <hr className={'line'} />
                </div>
              ))}
            </div>
          </div>
          <div className={'offeringsContainer'}>
            <div className={'offeringsBlock'}>
              <div className={'offering'}>
                <p className={'price'}>${value}</p>
                <p className={'priceDescription'}>Value</p>
              </div>
              <div className={'offering'}>
                <p className={'price'}>${price}</p>
                <p className={'priceDescription'}>/share</p>
              </div>
            </div>
            <div className={'buttonsContainer'}>
              {/* <CustomButton btnStyle={"secondaryBlack"} onClick={onByBtnClick}>
                <span className={styles.buttonText}> VIEW DEAL SHEET</span>
              </CustomButton> */}
              <CustomButton
                onClick={onFactSheetBtnClick}
                btnStyle={'secondaryBlack'}
              >
                <span className={styles.buttonText}>
                  {' '}
                  {factSheetButtonText}
                </span>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
