import React, { FC } from "react";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StyledBlackTextField } from "./styledComponent";
import { InputSearchProps } from "./InputSearch.type";

const InputSearch: FC<InputSearchProps> = ({ query, onChange }) => {
  return (
    <StyledBlackTextField
      value={query}
      onChange={onChange}
      placeholder="SEARCH"
      variant="outlined"
      fullWidth
      sx={{
        input: {
          color: "white",
          "&::placeholder": { opacity: 1, fontSize: "14px" },
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon sx={{ fill: "white" }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputSearch;
