import { CountryCode, getCountryCallingCode } from "libphonenumber-js";
import countries from "i18n-iso-countries";
import { CountryCodeType } from "components/simpleComponents/PhoneDropDown/PhoneDropDown.type";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function getCountryFlagEmoji(countryCode: string) {
  return Array.from(countryCode.toUpperCase())
    .map(char => String.fromCodePoint(127397 + char.charCodeAt(0)))
    .reduce((a, b) => a + b);
}

function getCountryFlag(countryCode: string): string {
  return `/flags/${countryCode.toLowerCase()}.svg`;
}

export function getCountriesWithCodes() {
  const countryCodes = countries.getAlpha2Codes();
  let countryList: CountryCodeType[] = [];

  Object.keys(countryCodes).forEach((countryCode) => {
    try {
      const countryName = countries.getName(countryCode, "en");
      const phoneCode = getCountryCallingCode(countryCode as CountryCode);
      const flagSrc = getCountryFlag(countryCode);
      countryList.push({ name: countryName, code: phoneCode, flag: flagSrc });
    } catch (error) {}
  });

  return countryList;
}
