import { useMediaQuery } from "@mui/material";

import { UserProfile } from "types/Account";
import {
  PhoneFormatter,
  SSNObfuscator,
  AmountFormatter,
  formatFinraLicense,
  transformFirstCharToUpperCase,
} from "utils";
import { getAccreditationStatus } from "utils/getAccreditationStatus";
import parseFieldFromUserProfile from "utils/parseFieldFromUserProfile";

export const useUserDetailsFacade = (profileData: UserProfile) => {
  const {
    dob,
    emailAddress,
    domicile,
    primCountry,
    field1,
    primAddress1,
    primCity,
    primState,
    primZip,
    firstName,
    lastName,
  } = profileData;

  const isWidthMatches = useMediaQuery("(max-width:575px)");

  const addressData =
    primAddress1 + "," + primCity + "," + primState + "," + primZip;

  const userPhone = PhoneFormatter(profileData.phone);

  const userName = firstName + " " + lastName;

  const socialSecurityNumber = SSNObfuscator(profileData.socialSecurityNumber);

  const accreditationInformation = parseFieldFromUserProfile(field1 || "");

  const accreditationStatus = getAccreditationStatus(
    accreditationInformation.accreditationStatus || ""
  );

  const annualIncome = AmountFormatter(
    accreditationInformation.annualIncome || ""
  );

  const finraLicense = formatFinraLicense(
    accreditationInformation.holdFINRA || "",
    accreditationInformation.typeFINRA || ""
  );

  const shareHolders = transformFirstCharToUpperCase(
    accreditationInformation.shareHolders || ""
  );

  const firstUserDetailsArray = [
    {
      id: 1,
      firstColumn: "ADDRESS",
      secondColumn: addressData,
      isFirstItem: true,
      isLastItem: false,
    },
    {
      id: 2,
      firstColumn: "PHONE",
      secondColumn: "+" + userPhone,
      isFirstItem: false,
      isLastItem: false,
    },
    {
      id: 3,
      firstColumn: "DATE OF BIRTH",
      secondColumn: dob,
      isFirstItem: false,
      isLastItem: false,
    },
    {
      id: 4,
      firstColumn: "CITIZENSHIP STATUS",
      secondColumn: domicile,
      isFirstItem: false,
      isLastItem: false,
    },
    {
      id: 5,
      firstColumn: "COUNTRY",
      secondColumn: primCountry,
      isFirstItem: false,
      isLastItem: false,
    },
    {
      id: 6,
      firstColumn: "SSN/EIN",
      secondColumn: socialSecurityNumber,
      isFirstItem: false,
      isLastItem: !isWidthMatches,
    },
  ];

  const secondUserDetailsArray = [
    {
      id: 7,
      firstColumn: "ACCREDITATION STATUS",
      secondColumn: accreditationStatus,
      isFirstItem: !isWidthMatches,
      isLastItem: false,
      isTextOnTop: true,
    },
    {
      id: 8,
      firstColumn: "ANNUAL INCOME",
      secondColumn: annualIncome,
      isFirstItem: false,
      isLastItem: false,
      isTextOnTop: false,
    },
    {
      id: 9,
      firstColumn: "FINRA LICENSE",
      secondColumn: finraLicense,
      isFirstItem: false,
      isLastItem: false,
      isTextOnTop: false,
    },
    {
      id: 10,
      firstColumn: "Household 10%",
      secondColumn: shareHolders,
      isFirstItem: false,
      isLastItem: true,
      isTextOnTop: false,
    },
  ];

  return {
    userName,
    emailAddress,
    firstUserDetailsArray,
    secondUserDetailsArray,
  };
};
