import useStyles from "./useStyles";

import styles from "./PaymentDetailsLine.module.scss";

export type PaymentDetailsLineProps = {
  id: number | string;
  firstColumn: string;
  secondColumn: string;
  thirdColumn?: string;
  onEdit?: (id: number | string) => void;
  isLastItem?: boolean;
  isFirstItem?: boolean;
  isExpanded?: boolean;
};

const PaymentDetailsLine: React.FC<PaymentDetailsLineProps> = ({
  firstColumn,
  secondColumn,
  thirdColumn,
  id,
  onEdit = () => {},
  isLastItem = false,
  isFirstItem = false,
  isExpanded = true,
}) => {
  const { wrapperClasses, textStyles, buttonWrapperStyles, buttonStyles } =
    useStyles({ isLastItem, isFirstItem, isExpanded });

  return (
    <>
      <div className={wrapperClasses}>
        <span className={textStyles}>{firstColumn}</span>
        <span className={textStyles}>{secondColumn}</span>
        {isExpanded && <span className={styles.text}>{thirdColumn}</span>}
        {isExpanded && (
          <div className={buttonWrapperStyles}>
            <button
              type="button"
              className={buttonStyles}
              onClick={() => onEdit(id)}
            >
              EDIT
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentDetailsLine;
