import React, { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import type { OfferingTrades, TradesResponse } from "../../types/Trade";

import { useServerAPI } from "../../apis";
import useGetDisplayType from "../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../types/enums/DisplayTypes";

export type PortfolioItemsSctructure = "grid" | "table";

export default function useNewPortfolioFacade(): [
  OfferingTrades[],
  boolean,
  PortfolioItemsSctructure,
  React.Dispatch<React.SetStateAction<PortfolioItemsSctructure>>,
  boolean,
  (accountId?: string) => void,
  boolean
] {
  const api = useServerAPI();
  const desktop = !useGetDisplayType(DisplayTypes.Mobile);

  const isWidthMatches = useMediaQuery("(max-width:575px)");

  const [shares, setShares] = useState<OfferingTrades[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [portfolioSctructure, setPortfolioSctructure] =
    useState<PortfolioItemsSctructure>("table");
  const [watchFor, setWatch] = useState("INDIVIDUAL");

  const getOfferingsFromTradeResponse = (
    response: TradesResponse
  ): OfferingTrades[] =>
    Object.keys(response.data.response)
      .filter((key) => key !== "summary")
      .map((key) => response.data.response[key]);

  const setWatchFor = (accountId?: string) => {
    if (!accountId) setWatch("INDIVIDUAL");
    else setWatch(accountId);
  };

  const getAccountForIndividual = () => {
    setIsLoading(true);
    const result: OfferingTrades[] = [];
    api
      .getTradesHistory()
      .then((response) => {
        if (response.data.response) {
          return getOfferingsFromTradeResponse(response);
        }
        return Promise.reject(new Error("Can't get a shares"));
      })
      .then((shares) => {
        return Promise.all(
          shares
            .filter((share) => {
              if (
                share.offering.status === "IOI" &&
                share.offering.XXstatus !== "IntentToInvest"
              )
                return false;
              return true;
            })
            .map((share) =>
              api
                .getTradesHistory(share.offering.offeringId)
                .then((response) => {
                  if (response.data.response) {
                    const needOffer = getOfferingsFromTradeResponse(
                      response
                    ).find(
                      (offer) => offer.offeringId === share.offering.offeringId
                    );
                    if (needOffer) result.push(needOffer);
                  }
                })
            )
        );
      })
      .then(() => setShares(result))
      .finally(() => setIsLoading(false));
  };

  const getAccountForOrganization = () => {
    setIsLoading(true);
    api
      .getTradesHistoryForAccount(watchFor)
      .then((response) => {
        if (response.data.response) {
          return setShares(getOfferingsFromTradeResponse(response));
        }
        return Promise.reject(new Error("Can't get a shares"));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (watchFor === "INDIVIDUAL") getAccountForIndividual();
    else getAccountForOrganization();
  }, [watchFor]);

  return [
    shares,
    isLoading,
    portfolioSctructure,
    setPortfolioSctructure,
    desktop,
    setWatchFor,
    isWidthMatches,
  ];
}
