import React, { useState } from "react";
import { CardTypeMap, useMediaQuery } from "@mui/material";

import {
  BankAccountData,
  bankAccountForm,
  CreditCardData,
  creditCardForm,
} from "../../../types/Account";
import { PaymentType } from "../../../types/enums/PaymentType";
import { OnlyNumbersFormatter, SeparatorFormatter } from "../../../utils";

import useGetDisplayType from "../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../types/enums/DisplayTypes";

export type PaymentFormProps = {
  onCancel: Function;
  bankAccountError?: string;
  creditCardError?: string;
  onSaveBank: (props: bankAccountForm) => Promise<void> | void;
  onSaveCCard: (props: creditCardForm) => Promise<void> | void;
  title: string;
  submitTitle?: string;
  selectedTab?: "ACH" | "CREDITCARD" | string;
  processing: boolean;
  singleEdit?: boolean;
  bankAccountData?: BankAccountData;
  creditCardData?: CreditCardData;
};

export default function usePaymentFormFacade(
  props: PaymentFormProps
): [
  boolean,
  bankAccountForm,
  () => void,
  creditCardForm,
  (value: string, name: string) => string,
  boolean,
  string,
  React.Dispatch<React.SetStateAction<string>>,
  (value: bankAccountForm) => Promise<void>,
  (value: bankAccountForm) => Promise<{ [field: string]: string } | null>,
  (value: creditCardForm) => Promise<void>,
  (value: creditCardForm) => Promise<{ [field: string]: string } | null>,
  creditCardForm["cardType"],
  JSX.Element,
  (event: React.ChangeEvent<HTMLInputElement>) => void
] {
  const [cardType, setCardType] = useState<creditCardForm["cardType"]>(null);

  const bankFormFromData = (inputs?: BankAccountData) => {
    const form: bankAccountForm = {
      ExtAccountfullname: inputs?.AccountName ?? "",
      Extnickname: inputs?.AccountNickName ?? "",
      ExtBankname: "",
      ExtRoutingnumber: inputs?.AccountRoutingNumber ?? "",
      ExtAccountnumber: "",
      accountType: inputs?.accountType ?? "Checking",
    };
    return form;
  };

  const ccFormFromData = (inputs?: CreditCardData) => {
    const form: creditCardForm = {
      creditCardName: inputs?.creditCardName ?? "",
      creditCardNumber: "",
      cvvNumber: "",
      expirationDate: inputs?.expirationDate ?? "",
    };
    return form;
  };

  const [paymentMethod, setPaymentMethod] = useState(
    props.selectedTab ?? PaymentType.BANK
  );

  const creditCardForm = ccFormFromData(props.creditCardData);
  const bankAccountForm = bankFormFromData(props.bankAccountData);
  const [hasCanceled, setHasCanceled] = useState(false);

  const desktop = !useGetDisplayType(DisplayTypes.Mobile);

  const handleCancel = () => {
    setHasCanceled(true);
    props.onCancel();
  };

  const formatField = (value: string, name: string): string => {
    if (name === "expirationDate") {
      return SeparatorFormatter(value, 2, "/");
    }
    if (name === "creditCardNumber") {
      return SeparatorFormatter(value, 4, " ");
    }
    if (name === "cvvNumber") {
      return OnlyNumbersFormatter(value);
    }
    return value;
  };

  /***
   * **********************************************************************
   *
   *    Bank Account Form
   *
   * **********************************************************************
   */

  async function onSubmitBankForm(values: bankAccountForm) {
    props.onSaveBank(values);
  }

  async function validateBankForm(values: bankAccountForm) {
    if (hasCanceled) return null;
    var errors: { [field: string]: string } = {};
    if (!!!values.ExtAccountfullname || values.ExtAccountfullname.length < 1) {
      errors.ExtAccountfullname = "Account name cannot be blank";
    }
    if (
      values.ExtAccountnumber &&
      values.ExtAccountnumber.length > 0 &&
      isNaN(Number(values.ExtAccountnumber))
    ) {
      errors.ExtAccountnumber = "Should be only digits";
    } else if (
      !!!values.ExtAccountnumber ||
      values.ExtAccountnumber.length < 1
    ) {
      errors.ExtAccountnumber = "Account Number cannot be blank";
    }
    if (!!!values.ExtBankname || values.ExtBankname.length < 1) {
      errors.ExtBankname = "Bank name cannot be blank";
    }
    if (!!!values.Extnickname || values.Extnickname.length < 1) {
      errors.Extnickname = "Nickname cannot be blank";
    }
    if (
      values.ExtRoutingnumber &&
      values.ExtRoutingnumber.length > 0 &&
      isNaN(Number(values.ExtRoutingnumber))
    ) {
      errors.ExtRoutingnumber = "Should be only digits";
    } else if (
      !!!values.ExtRoutingnumber ||
      values.ExtRoutingnumber.length < 1
    ) {
      errors.ExtRoutingnumber = "Routing Number cannot be blank";
    }
    return Object.keys(errors).length > 0 ? errors : null;
  }

  /***
   * **********************************************************************
   *
   *    Credit Card Form
   *
   * **********************************************************************
   **/

  async function onSubmitCreditCardForm(values: creditCardForm) {
    values.expirationDate = OnlyNumbersFormatter(values.expirationDate);
    values.creditCardNumber = OnlyNumbersFormatter(values.creditCardNumber);
    values.cardType = cardType;
    props.onSaveCCard(values);
  }

  async function validateCreditCardForm(values: creditCardForm) {
    if (hasCanceled) return null;
    var errors: { [field: string]: string } = {};
    if (!!!cardType || cardType.length < 1) {
      errors.cardType = "Select a Card Type";
    }
    if (!!!values.creditCardName || values.creditCardName.length < 1) {
      errors.creditCardName = "Name cannot be blank";
    }
    if (
      values.creditCardNumber &&
      values.creditCardNumber.length > 0 &&
      isNaN(Number(OnlyNumbersFormatter(values.creditCardNumber)))
    ) {
      errors.creditCardNumber = "Should be only digits";
    } else if (
      !!!values.creditCardNumber ||
      values.creditCardNumber.length < 13
    ) {
      errors.creditCardNumber = "Number must be betwen 13 and 16 digits";
    }

    if (!!!values.cvvNumber || values.cvvNumber.length < 3) {
      errors.cvvNumber = "CCV number must be 3 digits";
    }
    if (!!!values.expirationDate || values.expirationDate.length < 4) {
      errors.expirationDate = "Expiry must be 4 digit as MM/YY";
    }
    return Object.keys(errors).length > 0 ? errors : null;
  }

  const cardTypes = [
    {
      value: "DI",
      label: (
        <img
          alt="Discover"
          src="/logo/discover.svg"
          width="57"
          height="37"
          style={{ marginRight: 25, marginLeft: -5 }}
        />
      ),
    },
    {
      value: "MC",
      label: (
        <img
          alt="Master Card"
          src="/logo/master.svg"
          width="57"
          height="37"
          style={{ marginRight: 25, marginLeft: -5 }}
        />
      ),
    },
    {
      value: "VI",
      label: (
        <img
          alt="Visa"
          src="/logo/visa.svg"
          width="57"
          height="37"
          style={{ marginRight: 25, marginLeft: -5 }}
        />
      ),
    },
  ];

  const newCardType = {
    DI: {
      value: "DI",
      label: (
        <img alt="Discover" src="/logo/discover.svg" width="40" height="30" />
      ),
    },
    MC: {
      value: "MC",
      label: (
        <img alt="Master Card" src="/logo/master.svg" width="40" height="40" />
      ),
    },
    VI: {
      value: "VI",
      label: <img alt="Visa" src="/logo/visa.svg" width="40" height="40" />,
    },
    AMEX: {
      value: "AMEX",
      label: <img alt="AMEX" src="/logo/amex.svg" width="57" height="57" />,
    },
    DIN: {
      value: "DIN",
      label: <img alt="AMEX" src="/logo/diners.svg" width="47" height="47" />,
    },
  };

  const recognizeCardType = (
    event: React.FormEvent<HTMLInputElement>
  ): void => {
    const value = OnlyNumbersFormatter(
      (event.target as HTMLInputElement).value
    );
    let cardType: creditCardForm["cardType"] = null;

    const visaRegExp = /^4[\d]/;
    const msRegExp = /^5[\d]/;
    const amexRegExp = /^3(4|7)[\d]/;
    const discoverRegExp = /^6[\d]/;
    const dinersClubRegExp = /^3(0|6|8)[\d]/;

    if (visaRegExp.test(value)) cardType = "VI";
    if (msRegExp.test(value)) cardType = "MC";
    if (amexRegExp.test(value)) cardType = "AMEX";
    if (discoverRegExp.test(value)) cardType = "DI";
    if (dinersClubRegExp.test(value)) cardType = "DIN";
    setCardType(cardType);
  };

  return [
    hasCanceled,
    bankAccountForm,
    handleCancel,
    creditCardForm,
    formatField,
    desktop,
    paymentMethod,
    setPaymentMethod,
    onSubmitBankForm,
    validateBankForm,
    onSubmitCreditCardForm,
    validateCreditCardForm,
    cardType,
    newCardType[cardType as keyof typeof newCardType]?.label ?? null,
    recognizeCardType,
  ];
}
