type HeaderArrowBottomProps = {
  color?: string;
};

const HeaderArrowBottom: React.FC<HeaderArrowBottomProps> = ({
  color = "white",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
    >
      <path
        d="M3.59656 7.40222C3.8197 7.62536 4.18208 7.62536 4.40522 7.40222L7.83264 3.97479C8.05579 3.75165 8.05579 3.38927 7.83264 3.16613C7.60951 2.94299 7.24713 2.94299 7.02399 3.16613L4 6.19012L0.976012 3.16792C0.752873 2.94478 0.390494 2.94478 0.167355 3.16792C-0.0557849 3.39106 -0.0557849 3.75344 0.167355 3.97658L3.59478 7.404L3.59656 7.40222Z"
        fill={color}
      />
    </svg>
  );
};

export default HeaderArrowBottom;
