import classNames from "classnames";

import styles from "./UserDetailsLine.module.scss";

type UserDetailsLineProps = {
  name: string;
  information: string;
  isLastItem?: boolean;
  isFirstItem?: boolean;
  isTextOnTop?: boolean;
};

const UserDetailsLine = ({
  name,
  information,
  isLastItem = false,
  isFirstItem = false,
  isTextOnTop = false,
}: UserDetailsLineProps) => {
  const wrapperClasses = classNames(styles.wrapper, {
    [styles.lastItem]: isLastItem,
    [styles.firstItem]: isFirstItem,
    [styles.textOnTop]: isTextOnTop,
  });

  return (
    <div className={wrapperClasses}>
      <span className={styles.name}>{name}</span>
      <span className={styles.information}>{information}</span>
    </div>
  );
};

export default UserDetailsLine;
