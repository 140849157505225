import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from ".";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
    createStyles(
        mixStyleRules(commonStyles, {
            dropZoneBox: {
                background: "#F4F4F4",
                borderRadius: "5px",
                width: "220px",
                height: "130px",
                padding: "12px",
            },
            dropZoneText: {
                fontSize: "15px",
                lineHeight: "15px",
            },
            fileName: {
                fontSize: "15px",
                margin: "0",
            },
            dropZoneBtn: {
                fontSize: "15px",
                marginBottom: "5px",
            },
            icon: {
                fontSize: "60px",
            },
            closeIcon: {
                fontSize: "30px",
            }
        })
    )
);