import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BasicOfferCard,
  ChildsCardProps
} from "../../../../Assets/components/CurrentOfferingAlt/components/BasicOfferCard/BasicOfferCard";
import Brillaint from './../../../../../AssetsUnlogged/assets/brilliant.png'

import { useStyles } from "./styles/";

type FeaturedOfferingCardProps = ChildsCardProps;

const FeaturedOfferingCard: React.FC<FeaturedOfferingCardProps> = function (
  props
): JSX.Element {
  const navigate = useNavigate();
  const { leftCornerTitleBoxClass } = useStyles();
  const onByBtnClick = (): void => {
    navigate(`/ioiDetails/${props.offer.offeringId}`);
  };
  return (
    <BasicOfferCard
      {...props}
      leftCornerTitleBoxClass={leftCornerTitleBoxClass}
      leftCornerTitle="FEATURED OFFERING"
      buyButtonText="RESERVE SHARES"
      onBuyBtnClick={onByBtnClick}
      lastInfoTitleType="GIA Report Grade"
      type="large"
      btnType={'primary'}
      img={Brillaint}
    />
  );
};

export default FeaturedOfferingCard;
