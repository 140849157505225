import { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";

import DiamondLayout from "../../layout/DiamondLayout/DiamondLayout";
import { AccreditationForm } from "./components/AccreditationForm/AccreditationForm";

import { lookupByGuid } from "apis/backend";

import { FormData } from "./components/AccreditationForm/AccreditationForm.type";
import {
  STEPS,
  initialValues,
} from "./components/AccreditationForm/AccreditationForm.data";

import style from "./style.module.scss";

const Accreditation = () => {
  const [step, setStep] = useState<STEPS>(STEPS.MAIN_FORM);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<FormData>(initialValues);

  const { guid } = useParams();
  const navigate = useNavigate();

  const containerClasses = classNames(style.container, {
    [style.stripeWrapper]: step === STEPS.ACH_CREDIT_CARD_FORM_RESIDENT,
  });

  const getFormSetter = useCallback(
    <T extends keyof FormData>(key: T) =>
      (value: FormData[T]) => {
        setForm((prev) => ({ ...prev, [key]: value }));
      },
    [setForm]
  );

  useEffect(() => {
    if (guid) {
      setIsLoading(true);
      lookupByGuid(guid)
        .then((res) => {
          setIsLoading(false);
          if (res.hasOwnProperty("firstName")) {
            getFormSetter("firstName")(res.firstName);
            getFormSetter("lastName")(res.lastName);
            getFormSetter("email")(res.emailAddress);
            getFormSetter("citizenship")(res.citizenshipStatus);
            getFormSetter("phoneNumber")(res.phoneNumber);
            getFormSetter("allowedOneShare")(res.allowedOneShare);
            res.sharesQty && getFormSetter("securitiesNumber")(res.sharesQty);
            res.flowStep && setStep(res.flowStep);
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    }
  }, [getFormSetter, guid, navigate]);

  return (
    <>
      <DiamondLayout isHideImage={step === STEPS.PDF_PREVIEW}>
        <div className={containerClasses}>
          <AccreditationForm
            step={step}
            setStep={setStep}
            guid={guid}
            isLoading={isLoading}
            form={form}
            setForm={setForm}
          />
        </div>
      </DiamondLayout>
    </>
  );
};

export default Accreditation;
