import { Box, Typography, useMediaQuery } from '@mui/material';
import BaseLayout from 'layout/BaseLayout/baseLayout';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { advisors, partners } from './About.data';
import { classes } from './styles';
import { MAIN_URL, ROUTES } from 'utils/constants';

const WhyLuxusListData = [
  'Invest in a curated selection of luxury assets',
  'Diversify your portfolio and hedge against inflation with “hard” assets',
  'Assets are secured, valued and authenticated by top industry experts',
  'Highly curated investment experiences with a like-minded global investment community',
  'Receive proceeds from sales of assets',
];

const Process = () => {
  const VideoEndPoint = useMediaQuery('(max-width: 526px)');
  const isMobile = useMediaQuery('(max-width:938px)');
  const isTablet = useMediaQuery('(max-width:1336px)');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + '/About');
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <BaseLayout
      pageName="About page"
      pageUri={`${MAIN_URL}${ROUTES.ABOUT_LUXUS}`}
    >
      <Box>
        <Box sx={classes.videoContainer(isMobile, VideoEndPoint)}>
          <iframe
            title="Kentucky Derby"
            src="https://player.vimeo.com/video/905866102?h=e44c82ae4a&byline=0&autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1"
            width="3840"
            height="2160"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              overflow: 'hidden',
              overflowX: 'hidden',
              overflowY: 'hidden',
              zIndex: 1,
              height: isTablet ? '100%' : '',
              width: isTablet ? '' : '100%',
              position: 'absolute',
              top: isTablet ? '0px' : '-700px',
            }}
          ></iframe>
        </Box>
        <Box sx={classes.aboutLuxusContainer(isMobile)}>
          <Box sx={classes.jewelryImageContainer(isMobile)}>
            <Box
              component="img"
              sx={classes.jewelryImage(isMobile)}
              alt="Jewelry"
              src="/images/LuxusJewelry-002-FS.png"
              srcSet="/images/LuxusJewelry-002-FS.png 1500w, /images/LuxusJewelry-002-FS2x.png 1501w"
            />
          </Box>
          <Box sx={classes.aboutLuxusTextBox(isMobile)}>
            <Box sx={{ maxWidth: '520px' }}>
              <Box
                sx={{
                  ...classes.ourTeamTitleContainer(isMobile),
                }}
              >
                <Typography
                  sx={classes.title}
                  style={{ marginBottom: '16px', color: '#fff' }}
                >
                  ABOUT LUXUS
                </Typography>
                <Typography sx={classes.secondTitle} style={{ color: '#fff' }}>
                  WE EMPOWER EVERYONE TO INVEST IN LUXURY ASSETS
                </Typography>
              </Box>
              <Box sx={classes.ourTeamTextContainer(isMobile)}>
                <Typography sx={classes.text} style={{ color: '#fff' }}>
                  LUXUS Alternatives Inc. (“LUXUS”), a venture-backed
                  wealth-tech company, is a luxury alternative asset manager +
                  marketplace. Our primary mission revolves around the curation,
                  securitization, and monetization of investment opportunities
                  in the world’s rarest luxury assets. Through the lens of
                  experiential investing, LUXUS is the first manager to offer a
                  multi-pronged strategy to investing in luxury assets. As the
                  first SEC-qualified luxury alternative investment platform,
                  LUXUS empowers the UHNW and institutional investors worldwide
                  to access luxury assets for the first time ever.
                </Typography>
                <Typography
                  sx={classes.secondTitle}
                  style={{ marginTop: '32px', color: '#fff' }}
                >
                  WHY LUXUS
                </Typography>
                <ul style={{ textAlign: 'start', paddingBottom: '20px' }}>
                  {WhyLuxusListData.map((item) => (
                    <Box
                      component={'li'}
                      key={item}
                      sx={classes.text}
                      style={{ color: '#fff' }}
                    >
                      {item}
                    </Box>
                  ))}
                </ul>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.ourTeamWrapper}>
          <Box>
            <Typography sx={classes.title}>MEET OUR FOUNDER</Typography>
            <Box sx={classes.outTeamContainer(isMobile)}>
              <Box sx={{ maxWidth: '425px' }}>
                <Typography
                  sx={classes.secondTitle}
                  style={{ marginBottom: '24px' }}
                >
                  Dana Auslander, CEO and Founder
                </Typography>
                <Typography sx={classes.text}>
                  Dana is an investment management executive with 23 years of
                  experience in product structuring, marketing, fundraising,
                  investor relations and corporate communications. Before
                  spending the last few years in fintech, Dana worked as a
                  Managing Director at Blackstone, Harbinger and with other
                  prominent hedge funds and family offices. She began her career
                  as a lawyer at Schulte Roth & Zabel. Dana has a BA from the
                  University of Vermont and a JD from Benjamin N. Cardozo School
                  of Law.
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: 'white',
                  padding: '20px',
                  display: 'flex',
                }}
              >
                <Box
                  component="img"
                  alt="Jewelry"
                  src="/images/DanaAuslander.png"
                  sx={{
                    maxWidth: '303px',
                    width: isMobile ? '222px' : '100%',
                    height: isMobile ? '300px' : '387px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.partnersWrapper(isMobile)}>
          <Typography sx={classes.title}>LUXUS PARTNERS</Typography>
          <Box sx={classes.partnersContainer(isMobile)}>
            {partners.map((image) => (
              <Box
                key={image}
                component="img"
                src={image}
                sx={{ maxWidth: '144px' }}
              />
            ))}
          </Box>
        </Box>
        <Box sx={classes.advisorsWrapper(isMobile)}>
          <Typography sx={classes.title}>OUR ADVISORS</Typography>
          <Box sx={classes.advisorsContainer(isMobile)}>
            <Typography
              sx={{
                fontSize: '25px',
                lineHeight: '32.05px',
                fontFamily: 'Futura PT Book',
              }}
            >
              Our team members and advisors have backgrounds from:
            </Typography>
            <Box sx={classes.partnersContainer(isMobile)}>
              {advisors.map((image) => (
                <Box
                  key={image}
                  component="img"
                  src={image}
                  sx={{ maxWidth: '123px' }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default Process;
