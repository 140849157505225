export type FaqProps = {
  option: FaqData;
  selectedOption: FaqData | null;
  onClick: (option: FaqData) => void;
};

export type TiaraFaqCollection = FaqData[];

export type FaqData = {
  title: string;
  preQuestionText?: string;
  collection: FaqDataElement[];
};

export type FaqDataElement = {
  question: string;
  answer: (display?: boolean) => JSX.Element;
  sort: number;
  text: string;
  disabled?: boolean;
};

export const faqs: FaqData[] = [
  {
    title: 'OVERVIEW',
    collection: [
      {
        sort: 0,
        question: 'WHAT IS THE TIARA<sup>*</sup>?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            A bespoke tiara commission designed by Garrard exclusively for
            LUXUS. The one-of-a-kind tiara will consist of precious gemstones
            and investment grade diamonds sourced especially for this unique
            commission. Investors will be taken on a visual journey from
            conceptualisation, showcasing Garrard’s exquisite craftsmanship to
            the finished tiara through a series of events around the world.
            <br />
            <br />
            With almost 300 years of expertise in creating some of the world’s
            most famous tiaras, LUXUS has entrusted Garrard and its
            industry-leading designers, gemmologists and craftspeople with this
            extraordinary tiara commission, that will bear the quintessential
            British Royal hallmarks of heritage and craftsmanship.
          </p>
        ),
        text: 'A bespoke tiara commission designed by Garrard exclusively for LUXUS. The one-of-a-kind tiara will consist of precious gemstones and investment grade diamonds sourced especially for this unique commission. Investors will be taken on a visual journey from conceptualisation, showcasing Garrard’s exquisite craftsmanship to the finished tiara through a series of events around the world. With almost 300 years of expertise in creating some of the world’s most famous tiaras, LUXUS has entrusted Garrard and its industry-leading designers, gemmologists and craftspeople with this extraordinary tiara commission, that will bear the quintessential British Royal hallmarks of heritage and craftsmanship.',
      },
    ],
  },
  {
    title: 'DESIGN AND PRODUCTION',
    collection: [
      {
        sort: 1,
        question:
          'HOW WILL THE DESIGN OF THE TIARA BE CONDUCTED AND WHAT IS THE PRODUCTION TIMELINE?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            The Tiara will be a one-of-a-kind, bespoke piece, set with precious
            gemstones and investment grade diamonds sourced especially for this
            unique commission.
            <br />
            <br />
            Garrard will design The Tiara, taking inspiration from the history
            and elegance of past tiaras crafted by Garrard. The design will draw
            from centuries of crafting heritage jewels for royal clients
            including Queen Victoria and Princess Diana. LUXUS’ jewelry design
            advisor, who represents our investor community, will provide
            feedback on The Tiara design throughout the process.
            <br />
            <br />
            The preliminary sketch of The Tiara will be completed by March 2024
            Following the finalization of the design, The Tiara will go into
            production, a process that will take 6-8 months depending on the
            finished design.
            <br />
            <br />
            LUXUS will keep investors updated on the key milestones in the
            creation of The Tiara. Events will be planned around the world in
            which the progress of The Tiara will be unveiled in its various
            production stages via interactive media tools and private
            gatherings. More to come on what we have planned.
          </p>
        ),
        text: 'The Tiara will be a one-of-a-kind, bespoke piece, made up of investment grade diamonds and precious gems.  It will also be constructed in a way by which it can be broken up into several jewelry pieces (such as a necklace, earrings, rings or possibly cufflinks) to enable maximum usage. Garrard will take the lead on the design of The Tiara. LUXUS’ jewelry design advisor will represent our investor community and product feedback on The Tiara design throughout the process. The final design of The Tiara will be completed by this March and will be unveiled on March 7th at a LUXUS VIP event in NYC on International Women’s Day.  Following the finalization of design, The Tiara will go into production at Garrard’s workshop in Great Britain and will be in production for 6-8 months depending on the final design. LUXUS will keep investors updated on the production milestones by planning several events around the world in which The Tiara will be unveiled in its various production stages via interactive media tools and private gatherings. More to come on what we have planned…',
      },
    ],
  },
  {
    title: 'KEY INVESTMENT TERMS',
    collection: [
      {
        sort: 2,
        question: 'WHAT IS THE MARKET CAP OF THE TIARA?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            Garrard is designing The Tiara to be valued at $3,000,000 USD.
          </p>
        ),
        text: 'Garrard is designing The Tiara to be valued at $3,000,000 USD.',
      },
      {
        sort: 3,
        question: 'WHAT IS THE MINIMUM INVESTMENT?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            Currently, the minimum investment in The Tiara is $5,000, at $2,500
            a share. This minimum investment amount will be increased in the
            upcoming months.
          </p>
        ),
        text: 'Currently, the minimum investment in The Tiara is $5,000 at $2,500 a share. This minimum investment amount will be increased in the upcoming months.',
      },
      {
        sort: 4,
        question: 'WHAT ARE THE FEES ASSOCIATED WITH THE TIARA INVESTMENT?',
        answer: (display) => (
          <div style={{ display: display ? 'block' : 'none' }}>
            <ul>
              <li>Management Fee: 2.5% Management Fee per annum</li>
              <li>
                Performance Fee: 20% of the Net Profits on The Tiara subject to
                an 8% Hurdle
              </li>
              <li>
                Legal + Administrative Fee: 1.00% of the market cap valuation
              </li>
            </ul>
            <p style={{ color: '#fff', fontFamily: 'Futura PT Book' }}>
              There will be{' '}
              <strong style={{ textDecoration: 'underline' }}>
                no other fees
              </strong>{' '}
              associated with The Tiara Investment. All definitions are in the
              LUXUS Argyle LLC offering documents currently on EDGAR.
            </p>
          </div>
        ),
        text: 'Management Fee: 2.5% Management Fee per annum; Performance Fee: 20% of the Net Profits on The Tiara subject to an 8% Hurdle; Legal + Administrative Fee: 1.00% of the market cap valuation. There will be no other fees associated with The Tiara Investment. All definitions are in the LUXUS Argyle LLC offering documents currently on EDGAR.',
      },
      {
        sort: 5,
        question: 'WILL THERE BE A CHARITABLE COMPONENT TO THIS INVESTMENT?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            LUXUS and its strategic partners are discussing adding a charitable
            donation to The Tiara offering. It is very important to LUXUS to
            have an Impact/ESG component to this investment as it is a global
            initiative, so we are still deciding how this charitable component
            will be handled and who will be the recipient.
          </p>
        ),
        text: 'LUXUS and its strategic partners are discussing adding a charitable donation to The Tiara offering.  It is very important to LUXUS to have an Impact/ESG component to this investment as it is a global initiative, so we are still deciding how this charitable component will be handled and who will be the recipient.',
      },
      {
        sort: 6,
        question: 'WHERE WILL THE TIARA BE STORED? CAN I GO TO VIEW IT?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            The Tiara will be stored in Garrard’s flagship boutique in Mayfair,
            London where investors from around the world can experience its
            magnificence in person.
          </p>
        ),
        text: 'The Tiara will be stored in Garrard’s flagship boutique in Mayfair, London where investors from around the world can experience its magnificence in person.',
      },
      {
        sort: 7,
        question:
          'CAN I BUY THE ENTIRE TIARA AT SOME POINT? WHAT IS THE EXIT STRATEGY FOR The Tiara?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            Yes, on completion of The Tiara, it will be physically located in
            London, with potential buyers able to view it in person by visiting
            Garrard’s flagship boutique. In 2025, the LUXUS Marketplace will
            have a “Buy-It-Now” functionality that allows you to purchase The
            Tiara outright and take personal possession (just like e-commerce).
            The Exit price for The Tiara will be set by our Advisory Board. At
            that time, the offering will be withdrawn and not available to
            investors.
            <br />
            <br />
            LUXUS’ strategic partners will also work to independently sell The
            Tiara to provide an exit to investors (via their traditional sales
            channels). Once the asset is sold, proceeds will be distributed to
            investors.
          </p>
        ),
        text: 'Yes, on completion of The Tiara, it will be physically located in London, with potential buyers able to view it in person by visiting Garrard’s flagship boutique. In 2025, the LUXUS Marketplace will have a “Buy-It-Now” functionality that allows you to purchase The Tiara outright and take personal possession (just like e-commerce). The Exit price for The Tiara will be set by our Advisory Board. At that time, the offering will be withdrawn and not available to investors. LUXUS’ strategic partners will also work to independently sell The Tiara to provide an exit to investors (via their traditional sales channels). Once the asset is sold, proceeds will be distributed to investors.',
      },
      {
        sort: 8,
        question: 'IS THERE A LIMIT TO HOW MANY SHARES OF ONE ASSET I CAN BUY?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            Due to the high demand for The Tiara investment opportunity, we are
            limiting each investor to a 10% position. This means the highest
            investment is capped at $300,000 USD.
          </p>
        ),
        text: 'Due to the high demand for The Tiara investment opportunity, we are limiting each investor to a 10% position. This means the highest investment is capped at $400,000 USD.',
      },
      {
        sort: 9,
        question: 'WHAT IS THE ANTICIPATED HOLDING PERIOD FOR EACH INVESTMENT?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            We expect to realize each investment between 2-5 years at most.
            However, we cannot be certain of this timeline, and we reserve the
            right to extend or shorten the holding period on an as-needed basis.
          </p>
        ),
        text: 'We expect to realize each investment between 2-5 years at most. However, we cannot be certain of this timeline, and we reserve the right to extend or shorten the holding period on an as-needed basis.',
      },
      {
        sort: 10,
        question:
          'HOW CAN INVESTORS PARTICIPATE IN THE EXPERIENTIAL INVESTMENT COMPONENT OF THE TIARA?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            As an investor in The Tiara, you will receive VIP invites to events
            on the 2024 LUXUS Roadmap. Join LUXUS on our “roadshow” around the
            world from New York to London for Royal Ascot, to the Hamptons, Asia
            and the Gulf. The Tiara will be unveiled at the Tower of London upon
            its completion at an exclusive event reserved for investors in The
            Tiara and VIP guests.
            <br />
            <br />
            Depending on the level of investment, LUXUS has secured a range of
            benefits for its investors from Garrard. These will include:
            <br />
            <br />
            Tier 1
            <ul>
              <li>Private tour of the Crown Jewels at the Tower of London</li>
              <li>
                Private unveiling of the tiara before the Tower of London event
                in Garrard’s Queen Mary Suite
              </li>
            </ul>
            Tier 2
            <ul>
              <li>
                The rights to vote for the name (the more shares invested, the
                more votes granted)
              </li>
              <li>
                Participation in the gemstone quiz, chosen gemstone will be
                discreetly placed on The Tiara
              </li>
            </ul>
            Tier 3
            <ul>
              <li>
                Private afternoon tea at Garrard’s flagship with Creative
                Director Sara Prentice in the Queen Mary Suite
              </li>
              <li>
                Private Gemmology masterclass with Garrard’s in-house
                gemmologist in the Queen Mary Suite
              </li>
            </ul>
            Tier 4
            <ul>
              <li>Champagne heritage tour in the Queen Mary Suite</li>
            </ul>
          </p>
        ),
        text: 'As an investor in The Tiara, you will receive VIP invites to events on the 2024 LUXUS Roadmap. Join LUXUS on our “roadshow” around the world from New York to London for Royal Ascot, to the Hamptons, Asia and the Gulf. The Tiara will be unveiled at the Tower of London upon its completion at an exclusive event reserved for investors in The Tiara and VIP guests.Depending on the level of investment, LUXUS has secured a range of benefits for its investors from Garrard. These will include: Tier 1 Private tour of the Crown Jewels at the Tower of London Private unveiling of the tiara before the Tower of London event in Garrard’s Queen Mary Suite   Tier 2 The rights to vote for the name (the more shares invested, the more votes granted) Participation in the gemstone quiz, chosen gemstone will be discreetly placed on The Tiara Tier 3 Private afternoon tea at Garrard’s flagship with Creative Director Sara Prentice in the Queen Mary Suite Private Gemmology masterclass with Garrard’s in-house gemmologist in the Queen Mary Suite Tier 4 Champagne heritage tour in the Queen Mary Suite ',
      },
      {
        sort: 11,
        question: 'IS IT SAFE TO INVEST IN THE TIARA?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            All investments are subject to the risk of loss, and we cannot
            guarantee any investment is safe. We are committed to making our
            process as transparent as possible and will share any and all
            information regarding each asset on the website (and later in the
            apps). Investors should make their own determination about their
            personal risk tolerance and the amount they are willing to put at
            risk.
          </p>
        ),
        text: 'All investments are subject to the risk of loss, and we cannot guarantee any investment is safe. We are committed to making our process as transparent as possible and will share any and all information regarding each asset on the website (and later in the apps). Investors should make their own determination about their personal risk tolerance and the amount they are willing to put at risk.',
      },
    ],
  },
  {
    title: 'LEGAL + REGULATORY',
    collection: [
      {
        sort: 12,
        question:
          'HOW DOES THE SECURITIZATION PROCESS WORK AND WHAT STRUCTURE IS BEING USED?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            Like investing in any private investment offering governed under US
            securities laws, The Tiara will be part of LUXUS Argyle LLC, a
            Delaware organized series LLC. LUXUS Argyle LLC will own The Tiara,
            and investors will own part of that series, whose assets are held
            separately from any other series holding separate assets offerings
            by the company. By securitizing the asset in a cost effective legal
            structure, LUXUS enables beneficial ownership to be shared amongst
            all of our investors at a lower formation cost to all investors.
          </p>
        ),
        text: 'Like investing in any private investment offering governed under US securities laws, The Tiara will be part of LUXUS Argyle LLC, a Delaware organized series LLC. LUXUS Argyle LLC will own The Tiara, and investors will own part of that series, whose assets are held separately from any other series holding separate assets offerings by the company. By securitizing the asset in a cost effective legal structure, LUXUS enables beneficial ownership to be shared amongst all of our investors at a lower formation cost to all investors.',
      },
      {
        sort: 13,
        question:
          'CAN ANYONE PARTICIPATE IN BUYING SHARES OF THE ASSET? CAN NON-US INVESTORS PARTICIPATE IN THIS?',
        answer: (display) => (
          <p
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            Offerings conducted pursuant to Rule 506(c) of Regulation D are only
            available to accredited investors. Non-US investors who are
            accredited may participate in LUXUS offerings depending on the rules
            of their respective jurisdictions. To comply with Rule 506(c), once
            an investment is made, LUXUS is required to take reasonable steps to
            verify accredited investor status. LUXUS may seek qualification of
            certain offerings under Regulation A of the Securities Act. If
            qualified under Regulation A, then both accredited and
            non-accredited investors may participate.
            <br />
            <br />
            Non-US investors are welcome to invest if their respective
            jurisdictions allow it. If there is significant interest from
            overseas investors, LUXUS will create an offshore “feeder fund” to
            minimize tax and regulatory for Non-US investors.
          </p>
        ),
        text: 'Offerings conducted pursuant to Rule 506(c) of Regulation D are only available to accredited investors. Non-US investors who are accredited may participate in LUXUS offerings depending on the rules of their respective jurisdictions. To comply with Rule 506(c), once an investment is made, LUXUS is required to take reasonable steps to verify accredited investor status. LUXUS may seek qualification of certain offerings under Regulation A of the Securities Act. If qualified under Regulation A, then both accredited and non-accredited investors may participate. Non-US investors are welcome to invest if their respective jurisdictions allow it.  If there is significant interest from overseas investors, LUXUS will create an offshore “feeder fund” to minimize tax and regulatory for Non-US investors.',
      },
      {
        sort: 14,
        question: 'WHAT ARE THE REQUIREMENTS FOR BEING AN ACCREDITED INVESTOR?',
        answer: (display) => (
          <div
            style={{
              color: '#fff',
              fontFamily: 'Futura PT Book',
              display: display ? 'block' : 'none',
            }}
          >
            <p style={{ color: '#fff', fontFamily: 'Futura PT Book' }}>
              The Tiara is currently available only to accredited investors.
              There is a possibility that non accredited investors may invest in
              The Tiara later in 2024 depending on availability. Accredited
              investors are defined as meeting the following conditions:
            </p>
            <br />
            <ul>
              <li>Income:</li>
              <ul>
                <li>
                  W2’s, 1099’s or tax returns for the past two years to show
                  your individual income exceeds $200,000 (or joint income
                  exceeds $300,000) for each year.
                </li>
              </ul>
              <li>Assets:</li>
              <ul>
                <li>
                  Provide recent financial account statements that show the
                  value of your assets exceed $1,000,000 excluding your primary
                  residence. The financial account statements must include your
                  name and date (must be dated within the last 3 months).
                </li>
                <li>
                  If you are using physical real estate assets (excluding your
                  primary residence), you must provide documentation showing you
                  own the property and the value.
                </li>
              </ul>
              <li>Professional Letter:</li>
              <ul>
                <li>
                  Provide an official written communication from any of the
                  following stating that the professional service provider has a
                  reasonable belief that you are an Accredited Investor. The
                  letter must be on the writer’s letterhead, include your name
                  and date (Must be dated within the last 3 months):
                </li>
                <ul>
                  <li>A licensed CPA</li>
                  <li>Attorney</li>
                  <li>Investment advisor</li>
                  <li>Registered broker-dealer</li>
                </ul>
              </ul>
              <li>Professional Designation:</li>
              <ul>
                <li>
                  Provide your CRD Number to verify you are a licensed
                  individual in good standing that holds an active Series 7,
                  Series 65, or Series 82 registration.
                </li>
              </ul>
            </ul>
          </div>
        ),
        text: 'The Tiara is currently available only to accredited investors. There is a possibility that non accredited investors may invest in The Tiara later in 2024 depending on availability. Accredited investors are defined as meeting the following conditions:Income:W2’s, 1099’s or tax returns for the past two years to show your individual income exceeds $200,000 (or joint income exceeds $300,000) for each year.Assets:Provide recent financial account statements that show the value of your assets exceed $1,000,000 excluding your primary residence. The financial account statements must include your name and date (Must be dated within the last 3 months).If you are using physical real estate assets (excluding your primary residence), you must provide documentation showing you own the property and the value. Professional Letter:Provide an official written communication from any of the following stating that the professional service provider has a reasonable belief that you are an Accredited Investor. The letter must be on the writer’s letterhead, include your name and date (Must be dated within the last 3 months):A licensed CPAAttorneyInvestment advisorRegistered broker-dealerProfessional Designation Provide your CRD Number to verify you are a licensed individual in good standing that holds an active Series 7, Series 65, or Series 82 registration.',
      },
    ],
  },
];
