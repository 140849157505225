import cloneStyleRules from "./cloneStyleRules";

type TargetStyleRules<T extends string> = Record<T, Record<string, any>>;
type SourceStyleRules<T extends string> = Partial<TargetStyleRules<T>>;
/**
 * Mixing class name map rules as object from source object in target.
 * This function not rewrite files in target object, just added info fron source object.
 * Also target and source objects will not change. Result is new object.
 * @param {T extends Record<string, any>} target - this object will be extended by inforamation from source object
 * @param {Partial<T>} source - from this object onformation will add to target object
 * @returns {T} - result of mixinig target and source objects
 */
export default function mixStyleRules<T extends string>(
  target: TargetStyleRules<T>,
  source: SourceStyleRules<T>
): TargetStyleRules<T> {
  const result = cloneStyleRules(target);
  Object.keys(source).forEach((key) => {
    const sourceStyleSheet = cloneStyleRules(
      source[key as keyof typeof source] as Record<string, string>
    ) as Record<string, any>;

    if (sourceStyleSheet)
      Object.keys(sourceStyleSheet).forEach((property) => {
        const targetStyleSheet = result[key as T] as Record<string, any>;
        targetStyleSheet[property] = sourceStyleSheet[property];
      });
  });
  return result;
}
