import { shuffleArray } from "utils/common";

export const settings = {
  dots: false,
  infinite: true,
  speed: 650,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: false,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 5,
      },
    },
  ],
};

const sliderDataOrdered = [
  "https://d1p3kxhg87s54m.cloudfront.net/thoroughbreds-offering/landing-page-assets/images/1stThoroughbredGallery.jpg",
  "https://d1p3kxhg87s54m.cloudfront.net/thoroughbreds-offering/landing-page-assets/images/2ndThoroughbredGallery.jpg",
  "https://d1p3kxhg87s54m.cloudfront.net/thoroughbreds-offering/landing-page-assets/images/3rdThoroughbredGallery.jpg",
  "https://d1p3kxhg87s54m.cloudfront.net/thoroughbreds-offering/landing-page-assets/images/4thThoroughbredGallery.jpg",
  "https://d1p3kxhg87s54m.cloudfront.net/thoroughbreds-offering/landing-page-assets/images/5thThoroughbredGallery.jpg",
  "https://d1p3kxhg87s54m.cloudfront.net/thoroughbreds-offering/landing-page-assets/images/6thThoroughbredGallery.jpg",
];

export const sliderData = shuffleArray(sliderDataOrdered);
