import { makeStyles, createStyles } from "@mui/styles";
import { Classes } from ".";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import { themeData } from "../../../../../../utils/theme";

export const useDesktopStyles: (props?: any) => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        background: "#1D1D1D",
        borderRadius: "5px",
        padding: "25px",
      },
      btn: {
        height: "53px",
      },
    })
  )
);
