import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "85%",
  },
  formBox: {
    width: "60%",
  },
  select: {
    width: "100%",
    height: "52px",
  },
  placeholder: {
    color: "rgb(148, 148, 148)",
  },
  nonUs: {
    margin: "10px",
    color: "#6890FF",
  },
};

export type ClassesNames = keyof typeof commonStyles;
