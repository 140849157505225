import { themeData } from "../../../../utils/theme";

export const commonStyles = {
  container: {
    margin: "48px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  offerName: {
    fontSize: "38px",
    fontWeight: "450",
    marginBottom: "20px",
  },
  image: {
    border: "1px solid #DFDFE3",
    height: "200px",
    width: "200px",
    marginBottom: "24px",
  },
  text: {
    fontSize: "20px",
    fontWeight: "300",
    textAlign: "center",
    marginBottom: "36px",
    whiteSpace: "none",
  },
  brElem: {},
  btn: {
    backgroundColor: "#6890FF",
    padding: "13px 20px",
    fontSize: "23px",
    color: "#FFFFFF",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#6890FF",
      opacity: "0.5",
    },
  },
};

export type ClassesNames = keyof typeof commonStyles;
