import { Box, Typography } from "@mui/material";
import BaseLayout from "layout/BaseLayout/baseLayout";
import { ChangeEvent, useEffect, useState } from "react";
import { debounce } from "utils/debounce";
import Diamond from "../../assets/images/DiamondCushion.png";
import InputSearch from "../../components/simpleComponents/Input/InputSearch/InputSearch";
import { FaqData } from "./Faq.type";
import { faqs } from "./FaqData";
import FaqSelectOption from "./FaqSelectOption";
import { MAIN_URL, ROUTES } from "utils/constants";

const Faq = () => {
  const [selectedOption, setSelectedOption] = useState<null | FaqData>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFaqs, setFilteredFaqs] = useState(faqs);

  const select = (option: FaqData) => {
    if (option && option.question === selectedOption?.question) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  const handleSearch = (query: string) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = faqs.filter(
      (faq) =>
        faq.question.toLowerCase().includes(lowercasedQuery) ||
        faq.text.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredFaqs(filtered);
  };

  const debouncedSearch = debounce(handleSearch, 500);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <BaseLayout pageName="FAQ page" pageUri={`${MAIN_URL}${ROUTES.FAQ}`}>
      <Box sx={{ backgroundColor: "#000" }}>
        <Box sx={{ maxWidth: "1040px", margin: "0 auto", padding: "0px 20px" }}>
          <Typography
            fontSize={"30px"}
            lineHeight={"36.43px"}
            fontFamily={"Telugu MN"}
            textAlign="center"
            color="#fff"
            sx={{ padding: "87px 0px 54px 0px" }}
          >
            FAQ
          </Typography>
          <Box sx={{ paddingBottom: "54px" }}>
            <InputSearch query={searchQuery} onChange={handleSearchChange} />
          </Box>

          <Box paddingBottom="160px" width={"100%"} color={"white"}>
            {filteredFaqs.length < 1 ? (
              <p
                style={{
                  color: "white",
                  fontSize: "18px",
                  textAlign: "center",
                  fontFamily: "Telugu MN",
                }}
              >
                Try using more general terms to broaden your search!
              </p>
            ) : (
              <div>
                {filteredFaqs.map((faq) => (
                  <FaqSelectOption
                    key={faq.sort}
                    option={faq}
                    selectedOption={selectedOption}
                    onClick={select}
                  />
                ))}
              </div>
            )}
          </Box>
        </Box>
        <Box width={"100%"}>
          <img src={Diamond} alt="diamond" width="100%" height={"100%"} />
        </Box>
      </Box>
    </BaseLayout>
  );
};

export default Faq;
