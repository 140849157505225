import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Grid,
  // TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { Box } from "@mui/system";
import { Radios, showErrorOnBlur, TextField } from "mui-rff";
import { Form } from "react-final-form";
import {
  BankAccountData,
  bankAccountForm,
  CreditCardData,
  creditCardForm,
} from "../../../types/Account";
import { PaymentType } from "../../../types/enums/PaymentType";
import ErrorMessage from "../../ErrorMessage";
import { themeData } from "../../../utils/theme";

import usePaymentFormFacade from "./usePaymentFormFacade";

export type PaymentFormProps = {
  onCancel: Function;
  bankAccountError?: string;
  creditCardError?: string;
  onSaveBank: (props: bankAccountForm) => Promise<void> | void;
  onSaveCCard: (props: creditCardForm) => Promise<void> | void;
  title: string;
  submitTitle?: string;
  selectedTab?: "ACH" | "CREDITCARD" | string;
  processing: boolean;
  singleEdit?: boolean;
  bankAccountData?: BankAccountData;
  creditCardData?: CreditCardData;
};

export const PaymentForm = (props: PaymentFormProps) => {
  const [
    hasCanceled,
    bankAccountForm,
    handleCancel,
    creditCardForm,
    formatField,
    desktop,
    paymentMethod,
    setPaymentMethod,
    onSubmitBankForm,
    validateBankForm,
    onSubmitCreditCardForm,
    validateCreditCardForm,
    cardType,
    cardLabel,
    recognizeCardType,
  ] = usePaymentFormFacade(props);

  /***
   * **********************************************************************
   *
   *    Bank Account Form
   *
   * **********************************************************************
   */

  const BankForm = () => {
    return (
      <Form
        onSubmit={onSubmitBankForm}
        initialValues={bankAccountForm}
        validate={validateBankForm}
        validateOnBlur
        render={({ handleSubmit, values }) => (
          <Box component="form">
            <Typography
              component="h6"
              align="left"
              sx={{
                color: themeData.BlackColor,
                fontSize: "16px",
                marginTop: "2rem",
              }}
            >
              Account Type
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              id="name"
              size="small"
              name="ExtAccountfullname"
              autoComplete="name"
              // autoFocus
              placeholder="Account name"
              showError={showErrorOnBlur}
              sx={{
                marginTop: ".5rem",
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              size="small"
              id="name"
              name="Extnickname"
              autoComplete="name"
              placeholder="Give it a nickname"
              showError={showErrorOnBlur}
              sx={{
                marginTop: ".5rem",
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              size="small"
              name="ExtBankname"
              id="bank_name"
              autoComplete="bank_name"
              placeholder="Bank Name"
              showError={showErrorOnBlur}
              sx={{
                marginTop: ".5rem",
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              size="small"
              name="ExtRoutingnumber"
              id="routing_number"
              autoComplete="routing_number"
              placeholder="Routing Number"
              showError={showErrorOnBlur}
              sx={{
                marginTop: ".5rem",
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              size="small"
              name="ExtAccountnumber"
              id="account_number"
              placeholder="Account Number"
              showError={showErrorOnBlur}
              sx={{
                marginTop: ".5rem",
              }}
            />
            {props.bankAccountError ? (
              <ErrorMessage
                message={props.bankAccountError}
                sx={{
                  color: "#ED7F8C",
                  fontSize: "16px",
                  marginTop: "1rem",
                  minHeight: "1rem",
                }}
              />
            ) : null}
            <Grid container spacing={1} marginTop="1em">
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginTop: 1,
                    padding: "10px 2px",
                    my: 2,
                    borderColor: "#E5E5E5",
                    backgroundColor: "#E5E5E5",
                    color: "#7B7B8F",
                    display: "block",
                    textTransform: "none",
                    fontWeight: "normal",
                    fontSize: "14px",
                    marginRight: "15px",
                    fontFamily: themeData.DefaultFont,
                    "&:hover": {
                      borderColor: "#E5E5E5",
                      backgroundColor: "#000000",
                      color: "#FFFFFF",
                    },
                  }}
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    marginTop: 1,
                    padding: "10px 2px",
                    my: 2,
                    backgroundColor: "#000000",
                    color: "#FFFFFF",
                    display: "block",
                    textTransform: "none",
                    fontWeight: "normal",
                    fontSize: "14px",
                    marginRight: "15px",
                    fontFamily: themeData.DefaultFont,
                    "&:hover": {
                      backgroundColor: "#000000",
                      color: "#FFFFFF",
                      opacity: "0.75",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  {props.processing ? (
                    <CircularProgress
                      size={25}
                      sx={{
                        color: "#ffffff",
                      }}
                    />
                  ) : (
                    props.submitTitle?.toUpperCase() ?? "Continue".toUpperCase()
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      />
    );
  };

  /***
   * **********************************************************************
   *
   *    Credit Card Form
   *
   * **********************************************************************
   **/

  const cardTypes = [
    {
      value: "DI",
      label: (
        <img
          alt="Discover"
          src="/logo/discover.svg"
          width="57"
          height="37"
          style={{ marginRight: 25, marginLeft: -5 }}
        />
      ),
    },
    {
      value: "MC",
      label: (
        <img
          alt="Master Card"
          src="/logo/master.svg"
          width="57"
          height="37"
          style={{ marginRight: 25, marginLeft: -5 }}
        />
      ),
    },
    {
      value: "VI",
      label: (
        <img
          alt="Visa"
          src="/logo/visa.svg"
          width="57"
          height="37"
          style={{ marginRight: 25, marginLeft: -5 }}
        />
      ),
    },
  ];

  const newCardType = {
    DI: {
      value: "DI",
      label: (
        <img alt="Discover" src="/logo/discover.svg" width="40" height="30" />
      ),
    },
    MC: {
      value: "MC",
      label: (
        <img alt="Master Card" src="/logo/master.svg" width="40" height="40" />
      ),
    },
    VI: {
      value: "VI",
      label: <img alt="Visa" src="/logo/visa.svg" width="40" height="40" />,
    },
    AMEX: {
      value: "AMEX",
      label: <img alt="AMEX" src="/logo/amex.svg" width="57" height="57" />,
    },
    DIN: {
      value: "DIN",
      label: <img alt="AMEX" src="/logo/diners.svg" width="47" height="47" />,
    },
  };

  const CreditCardForm = () => {
    return (
      <Form
        onSubmit={onSubmitCreditCardForm}
        initialValues={creditCardForm}
        validate={validateCreditCardForm}
        validateOnBlur
        render={({ handleSubmit, values }) => (
          <Box component="form">
            <Typography
              component="h6"
              align="left"
              sx={{
                color: themeData.BlackColor,
                fontSize: "16px",
                marginTop: "1rem",
              }}
            >
              Card Details
            </Typography>
            <Box
              display={"flex"}
              flexDirection="column"
              alignItems={"center"}
              sx={{
                zIndex: "-1",
                width: "0",
                height: "0",
                opacity: "0",
                position: "absolute",
              }}
            >
              <Radios
                label=""
                name="cardType"
                required={true}
                data={cardTypes}
                showError={showErrorOnBlur}
                radioGroupProps={{ row: true }}
                value={cardType ?? ""}
                sx={{
                  color: themeData.BlackColor,
                  fontSize: "16px",
                  cursor: "pointer",
                  "&:active": { color: `${themeData.BlackColor} !important` },
                  ">svg": { color: `${themeData.BlackColor} !important` },
                  ">span": { color: `${themeData.BlackColor} !important` },
                }}
              />
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              // autoFocus
              name="creditCardName"
              size="small"
              id="name"
              autoComplete="name"
              placeholder="Credit Card Name"
              showError={showErrorOnBlur}
              sx={{
                marginTop: "1rem",
              }}
            />
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              required
              name="creditCardNumber"
              id="number"
              size="small"
              autoComplete="ccnumber"
              datatype=""
              showError={showErrorOnBlur}
              // datatype="numbers"
              inputMode="numeric"
              onInput={recognizeCardType}
              placeholder="Card Number"
              InputProps={{
                endAdornment: cardLabel,
              }}
              inputProps={{
                maxLength: 19,
              }}
              fieldProps={{ format: formatField }}
              sx={{
                marginTop: "1rem",
              }}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  required
                  name="expirationDate"
                  id="exp_date"
                  autoComplete="exp_date"
                  size="small"
                  showError={showErrorOnBlur}
                  // type={'month'}
                  placeholder="Expiry MM/YY"
                  sx={{
                    marginTop: "1rem",
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  fieldProps={{ format: formatField }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  required
                  name="cvvNumber"
                  id="cvv"
                  autoComplete="cvv"
                  size="small"
                  showError={showErrorOnBlur}
                  // type={'number'}
                  placeholder="CVV"
                  sx={{
                    marginTop: desktop ? "1rem" : "-10px",
                  }}
                  inputProps={{
                    maxLength: 3,
                  }}
                  fieldProps={{ format: formatField }}
                />
              </Grid>
            </Grid>
            {props.creditCardError ? (
              <ErrorMessage
                message={
                    props.creditCardError === "cardType we allow only VI,MC,DI" ?
                    "We currently accept the following card types: Visa, Mastercard, and Discover" :
                    props.creditCardError
                }
                sx={{
                  color: "#ED7F8C",
                  fontSize: "16px",
                  marginTop: "1rem",
                  minHeight: "1rem",
                }}
              />
            ) : null}
            <Grid container columnSpacing={1} marginTop="1rem">
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginTop: 1,
                    padding: "10px 2px",
                    my: 2,
                    borderColor: "#E5E5E5",
                    backgroundColor: "#E5E5E5",
                    color: "#7B7B8F",
                    display: "block",
                    textTransform: "none",
                    fontWeight: "normal",
                    fontSize: "14px",
                    marginRight: "15px",
                    fontFamily: themeData.DefaultFont,
                    "&:hover": {
                      borderColor: "#E5E5E5",
                      backgroundColor: "#000000",
                      color: "#FFFFFF",
                    },
                  }}
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    marginTop: 1,
                    padding: "10px 2px",
                    my: 2,
                    backgroundColor: "#000000",
                    color: "#FFFFFF",
                    display: "block",
                    textTransform: "none",
                    fontWeight: "normal",
                    fontSize: "14px",
                    marginRight: "15px",
                    fontFamily: themeData.DefaultFont,
                    "&:hover": {
                      backgroundColor: "#000000",
                      color: "#FFFFFF",
                      opacity: "0.75",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  {props.processing ? (
                    <CircularProgress
                      size={25}
                      sx={{
                        color: "#ffffff",
                      }}
                    />
                  ) : (
                    props.submitTitle?.toUpperCase() ?? "Continue".toUpperCase()
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      />
    );
  };

  return (
    <Container maxWidth={desktop ? "xs" : false} sx={{ marginTop: ".5rem" }}>
      <Box width={"xs"}>
        <Typography
          component="h6"
          align="left"
          sx={{
            color: themeData.BlackColor,
            fontSize: "24px",
            marginBottom: "1em",
          }}
        >
          {props.title}
        </Typography>
        {!props.singleEdit && (
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "center",
              flexFlow: "row wrap",
            }}
          >
            <Box sx={{ paddingTop: "0px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "0px",
                }}
              >
                <Checkbox
                  icon={
                    paymentMethod === PaymentType.BANK ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )
                  }
                  checkedIcon={
                    paymentMethod === PaymentType.BANK ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )
                  }
                  name={"paymentType"}
                  onClick={() => {
                    setPaymentMethod(PaymentType.BANK);
                  }}
                  sx={{
                    color: themeData.BlackColor,
                    fontSize: "16px",
                    cursor: "pointer",
                    "&:active": { color: themeData.BlackColor },
                    ">svg": { color: `{themeData.BlackColor}` },
                    ">span": { color: themeData.BlackColor },
                  }}
                />
                <Typography
                  component="h6"
                  align="left"
                  sx={{
                    color: themeData.BlackColor,
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPaymentMethod(PaymentType.BANK);
                  }}
                >
                  Bank Account
                </Typography>
              </Box>
            </Box>
            <Box sx={{ paddingTop: "0px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "0px",
                }}
              >
                <Checkbox
                  icon={
                    paymentMethod === PaymentType.CARD ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )
                  }
                  checkedIcon={
                    paymentMethod === PaymentType.CARD ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )
                  }
                  name={"paymentType"}
                  onClick={() => {
                    setPaymentMethod(PaymentType.CARD);
                  }}
                  sx={{
                    color: themeData.BlackColor,
                    fontSize: "16px",
                    cursor: "pointer",
                    "&:active": { color: `${themeData.BlackColor} !important` },
                    ">svg": { color: `${themeData.BlackColor} !important` },
                    ">span": { color: `${themeData.BlackColor} !important` },
                  }}
                />
                <Typography
                  component="h6"
                  align="left"
                  sx={{
                    color: themeData.BlackColor,
                    fontSize: "16px",
                    cursor: "pointer",
                    "&:active": { color: themeData.BlackColor },
                    ">svg": { color: themeData.BlackColor },
                  }}
                  onClick={() => {
                    setPaymentMethod(PaymentType.CARD);
                  }}
                >
                  Credit Card
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        <hr style={{ marginTop: ".5rem" }} />
        {paymentMethod === PaymentType.BANK ? BankForm() : CreditCardForm()}
      </Box>
    </Container>
  );
};

export default PaymentForm;
