import { useEffect, useRef, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";
import { Link, NavLink } from "react-router-dom";

import ArrowBottom from "assets/icons/HeaderArrowBottom";
import HeaderLogo from "assets/icons/HeaderLogo";
import { ButtonStyle, CustomButton } from "components/UIComponents/Button";
import AccountDropdown from "./components/AccountDropdown/AccountDropdown";
import MobileMenu from "./components/MobileMenu/MobileMenu";

import {
  aboutMenuPages,
  AccreditedInvestorPages,
  HEADER_OPTIONS,
  signedPages,
  unsignedPages,
} from "./constants";
import styles from "./Header.module.scss";

import { handleSectionScroll } from "utils/handleSectionScroll";
import {
  SCROLL_QUERY_PARAM,
  STREAM_SECTION_ID,
} from "pages/NewAssests/Assets/components/StreamCountdown/constants";

type HeaderOptions = "black" | "white";

type HeaderProps = {
  color: HeaderOptions;
};

const Header: React.FC<HeaderProps> = ({ color }) => {
  const [isAccreditedInvestorMenuOpen, setIsAccreditedInvestorMenuOpen] =
    useState(false);
  const [isAboutMenuOpen, setIsAboutMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);

  const menusRef = useRef<HTMLUListElement>(null);
  const menuDivRef = useRef<HTMLDivElement>(null);

  const isWidthMatches = useMediaQuery("(max-width:992px)");

  const handleScroll = () => setIsHeaderScrolled(window.scrollY > 1);

  const headerScrollCondition = isHeaderScrolled && !isWidthMatches;

  const headerClasses = classNames(styles.header, {
    [styles.blackHeader]: color === HEADER_OPTIONS.BLACK,
    [styles.whiteHeader]: color === HEADER_OPTIONS.WHITE,
    [color === HEADER_OPTIONS.BLACK
      ? styles.scrolledHeaderBlack
      : styles.scrolledHeaderWhite]: headerScrollCondition,
  });

  // TODO Additionally check this logic
  const userName =
    localStorage.getItem("fullUserName") === "undefined undefined"
      ? "NAME SURNAME"
      : localStorage.getItem("fullUserName");

  const isUserAuthorized = !!localStorage.getItem("rppToken");

  const pages = isUserAuthorized ? signedPages : unsignedPages;

  function handleMenuOpening(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    menu: string
  ) {
    event.stopPropagation();

    setIsAccreditedInvestorMenuOpen((prevState) =>
      menu === "accreditedInvestor" ? !prevState : false
    );
    setIsAboutMenuOpen((prevState) => (menu === "about" ? !prevState : false));
    setIsAccountMenuOpen((prevState) =>
      menu === "account" ? !prevState : false
    );
  }

  const determineStyle = <T,>(
    baseColor: T,
    scrolledColor: T,
    condition: boolean
  ): T => {
    return condition ? scrolledColor : baseColor;
  };

  const headerLogoColor = determineStyle(
    color === HEADER_OPTIONS.BLACK ? "black" : "white",
    color === HEADER_OPTIONS.BLACK ? "white" : "black",
    headerScrollCondition
  );

  const headerAccountColor = determineStyle(
    color === HEADER_OPTIONS.BLACK ? "white" : "black",
    color === HEADER_OPTIONS.BLACK ? "black" : "white",
    headerScrollCondition
  );

  const btnColor = determineStyle(
    color === HEADER_OPTIONS.BLACK
      ? "buttonTransparentWhiteText"
      : "buttonTransparentBlackText",
    color === HEADER_OPTIONS.BLACK
      ? "buttonTransparentBlackText"
      : "buttonTransparentWhiteText",
    headerScrollCondition
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }

    handleSectionScroll(SCROLL_QUERY_PARAM, STREAM_SECTION_ID);
    // window.scroll(0, 0);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !menusRef.current?.contains(event.target as Node) ||
        !menuDivRef.current?.contains(event.target as Node)
      ) {
        setIsAccreditedInvestorMenuOpen(false);
        setIsAboutMenuOpen(false);
        setIsAccountMenuOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <header className={headerClasses} id="headerId">
      <Link to="/" className={styles.logoLink}>
        <HeaderLogo color={headerLogoColor} />
      </Link>
      {isWidthMatches ? (
        <MobileMenu
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          color={color}
          pages={pages}
          aboutMenuPages={aboutMenuPages}
          isUserAuthorized={isUserAuthorized}
        />
      ) : (
        // Desktop
        <nav className={styles.navigation}>
          <ul className={styles.list}>
            {pages.map((page) => (
              <li className={styles.itemList} key={page.id}>
                <NavLink
                  to={page.url}
                  target={page.target}
                  className={styles.link}
                >
                  {page.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className={styles.aboutWrapper}>
            <button
              className={styles.aboutButton}
              onClick={(event) =>
                handleMenuOpening(event, "accreditedInvestor")
              }
            >
              <span className={styles.label}>ACCREDITED INVESTORS</span>
              <ArrowBottom color={headerLogoColor} />
            </button>
            {isAccreditedInvestorMenuOpen && (
              <ul ref={menusRef} className={styles.aboutList}>
                {AccreditedInvestorPages.map((page) => (
                  <li className={styles.itemList} key={page.id}>
                    <NavLink
                      to={page.url}
                      target={page.target}
                      className={styles.link}
                    >
                      {page.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={styles.aboutWrapper}>
            <button
              className={styles.aboutButton}
              onClick={(event) => handleMenuOpening(event, "about")}
            >
              <span className={styles.label}>ABOUT</span>
              <ArrowBottom color={headerLogoColor} />
            </button>
            {isAboutMenuOpen && (
              <ul ref={menusRef} className={styles.aboutList}>
                {aboutMenuPages.map((page) => (
                  <li className={styles.itemList} key={page.id}>
                    <NavLink
                      to={page.url}
                      target={page.target}
                      className={styles.link}
                    >
                      {page.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {!isUserAuthorized ? (
            <NavLink className={styles.signInLink} to="/login">
              {/* <CustomButton btnStyle={btnColor as ButtonStyle}>
                <span className={styles.label}>SIGN IN</span>
              </CustomButton> */}
            </NavLink>
          ) : (
            <CustomButton
              btnStyle={btnColor as ButtonStyle}
              onClick={(event) => handleMenuOpening(event, "account")}
            >
              <span className={styles.label}>ACCOUNT</span>
              <ArrowBottom color={headerAccountColor} />
            </CustomButton>
          )}
          {isAccountMenuOpen && (
            <AccountDropdown
              ref={menuDivRef}
              setIsAccountMenuOpen={setIsAccountMenuOpen}
              handleOpenMenu={setIsMenuOpen}
              name={userName}
              headerScrollCondition={headerScrollCondition}
            />
          )}
        </nav>
      )}
    </header>
  );
};

export default Header;
