import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import ThoroughbredImage from 'assets/images/thoroughbredAsset.jpg';
import { CustomButton } from 'components/UIComponents/Button';

import { ROUTES } from 'utils/constants';

import { LEFT_COLUMN_DETAILS, RIGHT_COLUMN_DETAILS } from './constants';
import styles from './ThoroughbredAsset.module.scss';

type ThoroughbredAssetProps = {
  isShowMoreUpcoming: boolean;
  isWithOneButton?: boolean;
};

const ThoroughbredAsset = ({
  isShowMoreUpcoming,
  isWithOneButton = false,
}: ThoroughbredAssetProps) => {
  const navigate = useNavigate();

  const containerClasses = classNames(styles.container, {
    [styles.containerWithOneButton]: isWithOneButton,
  });

  const offeringsContainerClasses = classNames(styles.offeringsContainer, {
    [styles.offeringsContainerWithOneButton]: isWithOneButton,
  });

  const navigateToFactSheet = () => {
    const a = document.createElement('a');
    a.href =
      process.env.REACT_APP_FRONTEND_BASE_URL +
      ROUTES.MISSED_THE_CUT_FACT_SHEET;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
    a.remove();
  };

  return (
    <>
      <div className={containerClasses}>
        <div className={styles.labelItem}>
          <h2 className={styles.labelText}>COMPLETED OFFERING</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <img
              src={ThoroughbredImage}
              className={styles.imageTiara}
              alt="Missed The Cut"
            />
          </div>
          <div className={styles.contentInfo}>
            <h2 className={styles.itemTitle}>MISSED THE CUT</h2>
            <h3 className={styles.itemDescription}>
              Trained at Lane's End, this 5 year old stallion is a rising star
              in thoroughbred racing with more than $500,000 in winnings. He is
              the progeny of Quality Road, one of North America's top stallions
              and has been trained by John W. Sadler with 2,700 wins.
            </h3>
            <div className={styles.moreDescription}>
              <div className={styles.infoContainer}>
                <hr className={styles.line} />
                {LEFT_COLUMN_DETAILS.map((item) => (
                  <div key={item.label}>
                    <div className={styles.table}>
                      <h4 className={styles.labelName}>{item.label}</h4>
                      <h4 className={styles.valueText}>{item.value}</h4>
                    </div>
                    <hr className={styles.line} />
                  </div>
                ))}
              </div>
              <div className={styles.infoContainer} style={{ width: '100%' }}>
                <hr className={styles.lineMore} />
                {RIGHT_COLUMN_DETAILS.map((item) => (
                  <div key={item.label}>
                    <div className={styles.table}>
                      <h4 className={styles.labelName}>{item.label}</h4>
                      <h4 className={styles.valueText}>{item.value}</h4>
                    </div>
                    <hr className={styles.line} />
                  </div>
                ))}
              </div>
            </div>
            <div className={offeringsContainerClasses}>
              {!isWithOneButton && (
                <div className={styles.offeringsBlock}>
                  For Accredited Investors Only
                </div>
              )}
              <div className={styles.buttonsContainer}>
                <div>
                  <CustomButton
                    btnStyle="secondaryBlack"
                    onClick={() => navigateToFactSheet()}
                  >
                    <span className={styles.buttonText}>FACT SHEET</span>
                  </CustomButton>
                </div>
                {!isWithOneButton && (
                  <CustomButton
                    btnStyle="inlineGrey"
                    onClick={() => navigate(ROUTES.THOROUGHBRED)}
                  >
                    <span className={styles.buttonText}>LEARN MORE</span>
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThoroughbredAsset;
