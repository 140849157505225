import { themeData } from "../../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    placeContent: "center",
    padding: "120px 0 127px 0",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  title: {
    textAlign: "center",
    color: "#000000",
    fontSize: "28px",
  },
  text: {
    align: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "15px",
    fontWeight: "300",
  },
  buttonsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
  },
  btnBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  btn: {
    display: "flex",
    placeContent: "center",
    height: "50px",
    width: "50px",
    border: "1.5px solid #7B7B8F",
    borderRadius: "40px !important",
    backgroundColor: "#D9D9D9",
    color: "#0F0F0F",
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "14px",
    fontFamily: themeData.DefaultFont,
    "&:hover": {
      background: "#5a6268",
    },
  },
  btnTitle: {
    align: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "15px",
    fontWeight: "300",
  },
  nextBtn: {
    my: 2,
    backgroundColor: "#E5E5E5",
    color: "#7B7B8F",
    display: "block",
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "14px",
    marginBottom: "15px",
    fontFamily: themeData.DefaultFont,
  },
  nextBtnActive: {
    "&:hover": {
      background: "#000000",
      color: "#FFFFFF",
    },
  },
  nextBtnDisabled: {
    "&:hover": {
      background: "#5a6268",
    },
  },
  skipBtn: {
    align: "center",
    color: "#0F0F0F",
    marginBottom: "1.5rem",
    fontSize: "15px",
    fontWeight: "300",
    cursor: "pointer",
    textDecoration: "underline",
  },
};

export type ClassesNames = keyof typeof commonStyles;
