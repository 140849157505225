export type SystemConfigType = {
  showProfileSSN: boolean;
  showProfilePhone: boolean;
  showProfilePaymentMethods: boolean;
  purchaseUseDocusign: boolean;
  systemMessage?: string;
};

export type WireDetailDataType = {
  sort: string;
  label: string;
  text: string;
};
export type WireDetailsType = {
  active: boolean;
  label?: string;
  description?: string;
  details: WireDetailDataType[];
};

export type FaqDataType = {
  question: string;
  answer: string;
  sort: number;
  disabled?: boolean;
};

export type FaqsType = {
  faqs: FaqDataType[];
};

export type InstructionType = {
  [key: string]: string;
};

type EntityType<T> = {
  gameId: string;
  entityId: string;
  ownerId: string | null;
  entityType: string;
  entityIndexedId: string;
  version: number;
  data: T;
  acl: {
    other: number;
  };
  expiresAt: number;
  timeToLive: number | null;
  createdAt: number;
  updatedAt: number;
};

export type SystemConfigResponse = {
  data: {
    entityList: EntityType<SystemConfigType>[];
    entityListCount: number;
    _serverTime: number;
  };
  status: number;
};
export type FaqsResponse = {
  data: {
    entityList: EntityType<FaqsType>[];
    entityListCount: number;
    _serverTime: number;
  };
  status: number;
};
export type InstructionsResponse = {
  data: {
    entityList: EntityType<InstructionType>[];
    entityListCount: number;
    _serverTime: number;
  };
  status: number;
};

export type WireDetailsResponse = {
  data: {
    entityList: EntityType<WireDetailsType>[];
    entityListCount: number;
    _serverTime: number;
  };
  status: number;
};

// Proivide some defaults for SystemConfig
export const DefaultSystemConfig: SystemConfigType = {
  showProfilePaymentMethods: false,
  showProfilePhone: false,
  showProfileSSN: false,
  purchaseUseDocusign: false,
};
