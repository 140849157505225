const QuintessentiallyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
    >
      <g clipPath="url(#clip0_5739_10382)">
        <path
          d="M40.5008 80C30.105 80 20.72 73.4998 16.9921 63.7756C7.1059 60.1199 0.5 50.6277 0.5 40C0.5 29.3723 7.1059 19.8802 16.9921 16.2244C20.72 6.50017 30.105 0 40.5008 0C50.8966 0 60.28 6.50017 64.0079 16.2244C73.8941 19.8802 80.5 29.3723 80.5 40C80.5 50.6277 73.8941 60.1199 64.0079 63.7756C60.28 73.4998 50.8966 80 40.5008 80ZM40.5008 2.06001C30.8074 2.06001 22.0693 8.2174 18.7588 17.3827L18.5912 17.8436L18.1286 18.0054C8.81563 21.2446 2.55837 30.0838 2.55837 40C2.55837 49.9162 8.81563 58.7554 18.1286 61.9946L18.5912 62.1564L18.7588 62.6173C22.0693 71.7826 30.8074 77.94 40.5008 77.94C50.1926 77.94 58.9307 71.7826 62.2411 62.6173L62.4088 62.1564L62.8714 61.9946C72.1844 58.7554 78.4416 49.9162 78.4416 40C78.4416 30.0838 72.1844 21.2446 62.8714 18.0054L62.4088 17.8436L62.2411 17.3827C58.9307 8.2174 50.1926 2.06001 40.5008 2.06001Z"
          fill="#F2F2F2"
        />
        <path
          d="M59.0231 50.3509C64.6523 49.8384 68.8151 44.8417 68.3026 39.2126C67.9647 35.501 65.6738 32.4346 62.5407 30.9186C62.3545 31.5822 62.1409 32.2339 61.898 32.8716C64.2836 34.1243 66.0096 36.5203 66.2715 39.3975C66.682 43.9067 63.3473 47.9093 58.8382 48.3198C58.1669 48.3809 57.5084 48.3546 56.8704 48.2577C56.5719 48.9075 56.2357 49.5372 55.8594 50.141C56.8723 50.3686 57.9347 50.45 59.0231 50.3509Z"
          fill="#F2F2F2"
        />
        <path
          d="M74.3973 40.159C74.3973 33.0174 69.9578 26.8295 63.393 24.4667C63.3938 24.5464 63.399 24.625 63.399 24.705C63.399 25.3493 63.3657 25.9856 63.3136 26.6165C68.7366 28.8461 72.3577 34.1144 72.3577 40.159C72.3577 46.9461 67.7213 52.669 61.4499 54.3317C60.3842 54.6054 59.3123 54.7439 58.2455 54.7439C55.9104 54.7439 53.6262 54.0528 51.6158 52.8809C50.8081 52.4101 50.0409 51.8671 49.3293 51.2607C52.23 48.7693 54.0728 45.0793 54.0728 40.9651C54.0728 33.481 47.9832 27.3915 40.499 27.3915C33.0147 27.3915 26.9268 33.481 26.9268 40.9651C26.9268 48.4491 33.0147 54.5387 40.499 54.5387C43.0961 54.5387 45.5244 53.8042 47.5895 52.5338C47.7701 52.6803 47.9248 52.8064 47.9988 52.8688C48.4819 53.2763 48.984 53.6616 49.5045 54.0202C50.4944 54.7023 51.5518 55.2883 52.6639 55.7461C53.4735 56.0794 54.3115 56.3444 55.1671 56.5309C55.373 56.5758 57.6958 56.9215 57.6958 56.8604C57.7766 56.8604 57.8562 56.8554 57.9367 56.8543C58.0133 56.8556 58.0898 56.8604 58.1665 56.8604C60.1714 56.8604 62.2 56.4383 64.1958 55.6059C64.7586 55.3713 65.2993 55.0912 65.8275 54.789C65.8164 54.7788 65.8045 54.7694 65.7934 54.7592C70.9199 51.9046 74.3973 46.4314 74.3973 40.159ZM28.9851 40.9651C28.9851 34.6166 34.1512 29.4515 40.499 29.4515C46.8484 29.4515 52.0144 34.6166 52.0144 40.9651C52.0144 44.5191 50.3947 47.7015 47.8553 49.8152C47.8437 49.802 47.8313 49.7895 47.8197 49.7762C46.9141 48.738 46.0085 47.6998 45.1029 46.6616C44.6192 46.1072 44.1355 45.5527 43.6518 44.9982L40.8222 45.1442C41.3646 45.7633 41.907 46.3824 42.4494 47.0014C43.4281 48.1185 44.4069 49.2355 45.3856 50.3526C45.595 50.5916 45.8043 50.8305 46.0137 51.0695C44.3749 51.9672 42.4956 52.4786 40.4989 52.4786C34.1512 52.4786 28.9851 47.3135 28.9851 40.9651Z"
          fill="#F2F2F2"
        />
        <path
          d="M23.9142 29.6808C18.2617 29.6808 13.6631 34.2796 13.6631 39.932C13.6631 43.8909 15.9229 47.3259 19.2166 49.033C19.4283 48.3785 19.6678 47.7368 19.9372 47.1106C17.4149 45.7076 15.7026 43.0171 15.7026 39.932C15.7026 35.404 19.3865 31.7203 23.9142 31.7203C24.3595 31.7203 24.7918 31.7697 25.2173 31.8397C25.5977 31.2041 26.0183 30.5961 26.4725 30.0152C25.654 29.802 24.7989 29.6808 23.9142 29.6808Z"
          fill="#F2F2F2"
        />
        <path
          d="M9.00047 40.1478C9.00047 31.4527 16.0745 24.3788 24.7698 24.3788C27.0699 24.3788 29.2507 24.8837 31.2219 25.7737C31.9343 25.3377 32.6804 24.9517 33.4542 24.6177C30.8829 23.1728 27.924 22.3393 24.7698 22.3393C14.95 22.3393 6.96094 30.3282 6.96094 40.1478C6.96094 47.6321 11.6043 54.0483 18.1596 56.6791C18.1499 56.4091 18.1391 56.1394 18.1391 55.8671C18.1391 55.398 18.1585 54.9336 18.1872 54.4718C12.7707 51.9727 9.00047 46.4939 9.00047 40.1478Z"
          fill="#F2F2F2"
        />
        <path
          d="M50.8745 23.1475C50.6598 17.4991 45.8895 13.0786 40.2411 13.2933C36.285 13.4437 32.9382 15.8323 31.3574 19.1884C32.0194 19.3751 32.6698 19.5901 33.3058 19.8354C34.612 17.2617 37.2356 15.4485 40.3185 15.3313C44.8433 15.1593 48.6644 18.7005 48.8364 23.2249C48.8616 23.8878 48.8012 24.5334 48.675 25.1562C49.3092 25.4849 49.9228 25.8475 50.5096 26.2474C50.7813 25.2621 50.9153 24.2221 50.8745 23.1475Z"
          fill="#F2F2F2"
        />
        <path
          d="M39.8485 8.64233C48.5376 8.31207 55.8752 15.1121 56.2055 23.801C56.2968 26.2032 55.834 28.4969 54.9454 30.5722C55.4166 31.226 55.8456 31.9118 56.226 32.6279C57.6189 29.9705 58.3655 26.9312 58.2436 23.7235C57.8706 13.9111 49.5838 6.23133 39.7711 6.60434C32.292 6.88862 26.0567 11.7723 23.6768 18.4226C23.9461 18.4027 24.2153 18.3816 24.4874 18.3712C24.9562 18.3534 25.4209 18.3552 25.8836 18.3664C28.1751 12.859 33.5069 8.8834 39.8485 8.64233Z"
          fill="#F2F2F2"
        />
        <path
          d="M30.5128 57.9669C31.2977 63.5645 36.4907 67.4798 42.0884 66.6949C45.9147 66.1583 48.9429 63.5573 50.2329 60.1803C49.738 60.0035 48.4748 59.5254 48.3264 59.4849C47.2872 62.1775 44.8605 64.2468 41.8052 64.6752C37.321 65.304 33.1613 62.1675 32.5326 57.6837C32.4932 57.403 32.4777 57.1249 32.4682 56.8478C31.771 56.4946 31.0991 56.0993 30.4584 55.6608C30.3913 56.4144 30.4033 57.1859 30.5128 57.9669Z"
          fill="#F2F2F2"
        />
        <path
          d="M55.4662 61.3314C53.4676 66.4946 48.8114 70.4604 42.9501 71.2823C34.339 72.4898 26.3511 66.4668 25.1436 57.8559C24.8056 55.4455 25.0424 53.0876 25.7364 50.9106C25.5512 50.6361 25.3756 50.3547 25.2055 50.0696C24.9003 49.6568 24.6067 49.2345 24.3391 48.7924C23.1366 51.6486 22.663 54.8529 23.1239 58.1391C24.4875 67.8636 33.5087 74.6657 43.2333 73.302C50.0932 72.34 55.4879 67.5633 57.5982 61.4213C57.5445 61.4217 57.4916 61.4254 57.4378 61.4254C56.7727 61.4254 56.1159 61.3908 55.4662 61.3314Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_5739_10382">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuintessentiallyIcon;
