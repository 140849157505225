import { useState, useEffect } from 'react';
import { v1 as uuid } from 'uuid';

import ContactsForm from './ContactsForm/ContactsForm';
import AddressForm from './AddressForm/AddressForm';
import SuccessMessage from './SuccessMessage/SuccessMessage';
import { Preloader } from 'components/simpleComponents/Preloader';

import { TRIP_VARIANTS } from './ContactsForm/constants';
import {
  finalizeSubscription,
  getStringifiedFormData,
  sendPostRequest,
} from './utils';
import {
  COUPLE_SUCCESS_REDIRECTION_URL,
  COUPLE_SUCCESS_TEXT,
  SINGLE_SUCCESS_REDIRECTION_URL,
  SINGLE_SUCCESS_TEXT,
  FORM_SUBMISSION_URL,
  SINGLE_ONE_DINNER_SUCCESS_REDIRECTION_URL,
  SINGLE_THREE_DINNER_SUCCESS_REDIRECTION_URL,
} from './constants';

type RoyalAscotFormProps = {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setIsSubmissionConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmissionConfirmed: boolean;
  setTripVariant: React.Dispatch<React.SetStateAction<TRIP_VARIANTS>>;
};

const RoyalAscotForm = ({
  currentStep,
  setCurrentStep,
  setIsSubmissionConfirmed,
  isSubmissionConfirmed,
  setTripVariant,
}: RoyalAscotFormProps) => {
  const [firstGuest, setFirstGuest] = useState<any>(null);
  const [secondGuest, setSecondGuest] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const singleGuestSuccessCondition =
    currentStep === 3 &&
    (firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_FULL ||
      firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_ONE_DINNER ||
      firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_THREE_DINNERS) &&
    isSubmissionConfirmed;

  const coupleGuestSuccessCondition =
    currentStep === 5 && isSubmissionConfirmed;

  const getSingleSuccessRedirectUrl = () => {
    if (firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_FULL) {
      return SINGLE_SUCCESS_REDIRECTION_URL;
    } else if (firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_ONE_DINNER) {
      return SINGLE_ONE_DINNER_SUCCESS_REDIRECTION_URL;
    } else if (
      firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_THREE_DINNERS
    ) {
      return SINGLE_THREE_DINNER_SUCCESS_REDIRECTION_URL;
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (
      currentStep === 3 &&
      (firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_FULL ||
        firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_ONE_DINNER ||
        firstGuest?.tripOption === TRIP_VARIANTS.INDIVIDUAL_THREE_DINNERS)
    ) {
      (async () => {
        const firstGuestData: Record<string, any> = {
          ...firstGuest,
          group_id: uuid(),
        };

        const firstGuestStringifiedData =
          getStringifiedFormData(firstGuestData);

        try {
          setIsLoading(true);

          const firstRequestPromise = await sendPostRequest(
            FORM_SUBMISSION_URL,
            firstGuestStringifiedData
          );

          await finalizeSubscription([firstRequestPromise]);

          setIsSubmissionConfirmed(true);

          timeoutId = setTimeout(() => {
            window.open(getSingleSuccessRedirectUrl(), '_self');
          }, 3000);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }

    if (currentStep === 5) {
      (async () => {
        const firstGuestData: Record<string, any> = {
          ...firstGuest,
          group_id: uuid(),
          guestEmail: secondGuest.email,
        };

        const secondGuestData: Record<string, any> = {
          ...secondGuest,
          group_id: uuid(),
        };

        const firstGuestStringifiedData =
          getStringifiedFormData(firstGuestData);
        const secondGuestStringifiedData =
          getStringifiedFormData(secondGuestData);

        try {
          setIsLoading(true);

          const firstRequestPromise = await sendPostRequest(
            FORM_SUBMISSION_URL,
            firstGuestStringifiedData
          );
          const secondRequestPromise = await sendPostRequest(
            FORM_SUBMISSION_URL,
            secondGuestStringifiedData
          );

          await finalizeSubscription([
            firstRequestPromise,
            secondRequestPromise,
          ]);

          setIsSubmissionConfirmed(true);

          timeoutId = setTimeout(() => {
            window.open(COUPLE_SUCCESS_REDIRECTION_URL, '_self');
          }, 3000);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [firstGuest, secondGuest, currentStep, setIsSubmissionConfirmed]);

  if (isLoading) {
    return <Preloader isSmallHeight />;
  }

  return (
    <div>
      {currentStep === 1 && (
        <ContactsForm
          currentStep={currentStep}
          setTripVariant={setTripVariant}
          setGuest={setFirstGuest}
          setCurrentStep={setCurrentStep}
        />
      )}
      {currentStep === 2 && (
        <AddressForm
          setGuest={setFirstGuest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          firstGuest={firstGuest}
        />
      )}
      {currentStep === 3 &&
      firstGuest?.tripOption === TRIP_VARIANTS.COUPLE_FULL ? (
        <ContactsForm
          currentStep={currentStep}
          setGuest={setSecondGuest}
          setCurrentStep={setCurrentStep}
        />
      ) : null}
      {currentStep === 4 && (
        <AddressForm
          setGuest={setSecondGuest}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          firstGuest={firstGuest}
          secondGuest={secondGuest}
        />
      )}
      {singleGuestSuccessCondition && (
        <SuccessMessage message={SINGLE_SUCCESS_TEXT} />
      )}
      {coupleGuestSuccessCondition && (
        <SuccessMessage message={COUPLE_SUCCESS_TEXT} />
      )}
    </div>
  );
};

export default RoyalAscotForm;
