import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  IconButton,
  SvgIcon,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import classNames from "classnames";

import { PaymentType } from "../../../../types/enums/PaymentType";
import { themeData } from "../../../../utils/theme";
import PaymentPopUp from "../../../../components/NewPaymentPopUp/PaymentPopUp";
import PaymentMethodsList from "./Components/PaymentMethodsList/PaymentMethodsList";

import { useStyles } from "./styles";

interface AddPaymentMethodsProps {
  onSkipClick(): void;
}

const AddPaymentMethods: React.FC<AddPaymentMethodsProps> = function ({
  onSkipClick,
}): JSX.Element {
  const [isPaymentMethodChosen, setIsPaymentMethodChosen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState<
    "main" | "bank" | "card" | "list"
  >("main");

  const onNextClick = () => {
    setCurrentComponent("list");
  };

  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Box className={classes.content}>
        {currentComponent !== "list" && (
          <>
            <Typography component="h4" variant="h6" className={classes.title}>
              Add payment method
            </Typography>
            <Typography component="span" className={classes.text}>
              Add your bank account and credit cards to your account now to make
              purchasing later easier.
            </Typography>
            <Box className={classes.buttonsBox}>
              <Box className={classes.btnBox}>
                <IconButton
                  onClick={() => setCurrentComponent("card")}
                  className={classes.btn}
                >
                  <SvgIcon component={Add} />
                </IconButton>
                <Typography component="span" className={classes.btnTitle}>
                  Add credit card
                </Typography>
              </Box>
              <Box className={classes.btnBox}>
                <IconButton
                  onClick={() => setCurrentComponent("bank")}
                  className={classes.btn}
                >
                  <SvgIcon component={Add} />
                </IconButton>
                <Typography
                  component="span"
                  align="center"
                  className={classes.btnTitle}
                >
                  Add bank account
                </Typography>
              </Box>
            </Box>
            <Button
              onClick={onNextClick}
              disabled={!isPaymentMethodChosen}
              className={classNames(
                classes.nextBtn,
                { [classes.nextBtnActive]: isPaymentMethodChosen },
                { [classes.nextBtnDisabled]: !isPaymentMethodChosen }
              )}
            >
              Next
            </Button>
            <Typography
              onClick={() => setCurrentComponent("list")}
              component="span"
              className={classes.skipBtn}
            >
              Skip
            </Typography>
          </>
        )}
        {currentComponent === "card" && (
          <PaymentPopUp
            selectedType={PaymentType.CARD}
            showModal
            handleClose={() => setCurrentComponent("main")}
            onCancel={() => setCurrentComponent("main")}
            onSave={() => {
              setIsPaymentMethodChosen(true);
              setCurrentComponent("list");
            }}
          />
        )}
        {currentComponent === "bank" && (
          <PaymentPopUp
            selectedType={PaymentType.BANK}
            showModal
            handleClose={() => setCurrentComponent("main")}
            onCancel={() => setCurrentComponent("main")}
            onSave={() => {
              setIsPaymentMethodChosen(true);
              setCurrentComponent("list");
            }}
          />
        )}
        {currentComponent === "list" && (
          <PaymentMethodsList onDoneButtonClick={onSkipClick} />
        )}
      </Box>
    </Container>
  );
};

export default AddPaymentMethods;
