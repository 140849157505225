import { Box } from "@mui/material";
import { Footer } from "components/Footer/Footer";
import Header from "components/Header/Header";
import React, { FC } from "react";

const BaseLayout: FC<{
  children: React.ReactNode;
  color?: "white" | "black";
  removeForm?: boolean;
  pageName: string;
  pageUri: string;
}> = ({ children, color, removeForm, pageName, pageUri }) => {
  return (
    <Box sx={{ backgroundColor: "inherit", height: "100%", width: "100%" }}>
      <Box sx={{ backgroundColor: "inherit" }}>
        <Header color={color ?? "white"} />
        {children}
        <Footer removeForm={removeForm} pageName={pageName} pageUri={pageUri} />
      </Box>
    </Box>
  );
};

export default BaseLayout;
