import React from "react";
import { useNavigate } from "react-router-dom";
import { useStyle } from "./styles";
import {
  BasicOfferCard,
  ChildsCardProps,
} from "../BasicOfferCard/BasicOfferCard";
import Brilliant from "./../../../../../../AssetsUnlogged/assets/brilliant.png";

type FeaturedOfferingCardProps = ChildsCardProps & {
  isHideHint?: boolean;
};

const FeaturedOfferingCard: React.FC<FeaturedOfferingCardProps> = function (
  props
): JSX.Element {
  const navigate = useNavigate();
  const { leftCornerTitleBoxClass } = useStyle();
  const onByBtnClick = (): void => {
    navigate(`/ioiDetails/${props.offer.offeringId}`);
  };

  const isHideHint = props.offer.name.toLowerCase().includes("kwiat");

  return (
    <BasicOfferCard
      {...props}
      leftCornerTitleBoxClass={leftCornerTitleBoxClass}
      leftCornerTitle="FEATURED OFFERING"
      buyButtonText="RESERVE SHARES"
      onBuyBtnClick={onByBtnClick}
      lastInfoTitleType="GIA Report Grade"
      btnType={"primary"}
      img={Brilliant}
      isHideHint={isHideHint}
    />
  );
};

export default FeaturedOfferingCard;
