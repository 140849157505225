import { Box, useMediaQuery } from "@mui/material";
import { CSSProperties, FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import HeaderLogo from "assets/icons/HeaderLogo";
import Jewelry from "../../assets/images/Jewelry.png";
import Jewelry2x from "../../assets/images/Jewelry2x.png";
import Jewelry3x from "../../assets/images/Jewelry3x.png";
import { ContainerStyle, ContentContainer } from "./style";

const DiamondLayout: FC<{
  children: ReactNode;
  additionalStyles?: CSSProperties;
  step?: string;
  link?: string;
  isHideImage?: boolean;
  image1x?: string;
  image2x?: string;
  image3x?: string;
  imageFitProperty?: "fill" | "contain" | "cover" | "none" | "scale-down";
}> = ({
  children,
  additionalStyles,
  step,
  link,
  isHideImage = false,
  image1x = Jewelry,
  image2x = Jewelry2x,
  image3x = Jewelry3x,
  imageFitProperty = "cover",
}) => {
  const isTabletScreen = useMediaQuery("(max-width:992px)");
  const isDesktop = useMediaQuery("(max-width:1580px)");
  const isWideScreen = useMediaQuery("(max-width:2000px)");
  const navigate = useNavigate();

  return (
    <Box sx={{ ...ContainerStyle(isTabletScreen), ...additionalStyles }}>
      <Box sx={{ ...ContentContainer, flex: 1 }}>{children}</Box>
      {!isHideImage && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
          width={isTabletScreen ? "100%" : "90%"}
          height={isDesktop ? "auto" : isTabletScreen ? "fit-content" : "100%"}
          marginTop={isTabletScreen ? "30px" : "0px"}
        >
          <img
            src={image1x}
            srcSet={`${image1x} 1900w, 
          ${image2x} 2560w, 
          ${image3x} 3500w`}
            style={{
              maxWidth: isTabletScreen ? "300px" : "",
              maxHeight: isTabletScreen ? "500px" : "100vh",
              objectFit: imageFitProperty,
              height: isDesktop ? "100vh" : "100%",
              width: "90%",
            }}
            alt="JewelryImage"
          />
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "19px",
          left: "24px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (link) {
            localStorage.setItem("link", link);
          }
          if (step) {
            localStorage.setItem("configureProfileStep", step);
          }
          navigate("/");
        }}
      >
        <HeaderLogo color="#fff" />
      </Box>
    </Box>
  );
};

export default DiamondLayout;
