import styles from "./SuccessMessage.module.scss";

const SuccessMessage = () => {
  return (
    <>
      <div className={styles.container}>
        <div>
          <h3 className={styles.title}>Success!</h3>
          <p className={styles.text}>
            Thank you for completing the registration form. You will receive
            subscription and funding instructions shortly.
          </p>
        </div>
        <p className={styles.text}>
          If you have not received an email within the next thirty minutes,
          please check your spam.
        </p>
      </div>
    </>
  );
};

export default SuccessMessage;
