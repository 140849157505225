export const dropdownStyles = {
  header: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  listItem: {
    padding: "10px 20px",
    cursor: "pointer",
    listStyle: "none",
    color: "#000",
  },

  headerPlaceholder: {
    color: "#7B7B8F",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
