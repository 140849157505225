import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      container: {
        margin: "0 28px",
        "& .MuiFormControlLabel-label": {
          lineHeight: "16px",
        },
        "& label": {
          "& span:nth-child(2)": {
            textAlign: "justify",
          },
        },
      },
      span: {
        fontSize: "12px",
        textAlign: "justify",
      },
      spanBold: {
        fontSize: "12px",
        fontWeight: "600 !important",
        textAlign: "justify",
      },
      label: {
        width: "100%",
        "&:last-child": {
          marginBottom: "0",
        },
        "& svg": { fontSize: "17px" },
      },
    })
  )
);
