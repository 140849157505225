import RoyalAscotHorses from "assets/images/Horse-opt.jpg";

import styles from "./DigitalItinerary.module.scss";

const DigitalItinerary = () => {
  return (
    <div className={styles.formContainer}>
      <img
        className={styles.image}
        src={RoyalAscotHorses}
        alt="Royal Ascot Horses"
      />
    </div>
  );
};

export default DigitalItinerary;
