import { ArrowBottomIcon } from "assets/icons/arrow-bottom";
import React, { useState } from "react";
import { DropDownProps, DropdownOption } from "./DropDown.type";
import { Container, ListContainer } from "./styledComponents";
import { dropdownStyles } from "./styles";

const Dropdown: React.FC<DropDownProps> = ({
  options,
  value,
  onChange,
  arrowColor,
  borderColor,
  color,
  placeholder,
  placeholderColor = "#7B7B8F",
  maxHeight,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(
    value?.label || placeholder
  );

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (option: DropdownOption) => () => {
    onChange(option);
    setIsOpen(false);
    setSelectedLabel(option.label);
  };

  return (
    <Container
      isOpen={isOpen}
      style={{ borderColor: borderColor, color: color, cursor: "pointer" }}
      onClick={toggling}
    >
      <div
        style={Object.assign(
          {},
          value?.label
            ? dropdownStyles.header
            : dropdownStyles.headerPlaceholder,
          { color: placeholderColor }
        )}
      >
        {selectedLabel}
        <span
          style={{
            transition: "transform 0.3s ease",
            transform: `${isOpen ? "rotate(90deg)" : "rotate(360deg)"}`,
          }}
        >
          <ArrowBottomIcon color={arrowColor ? arrowColor : "#fff"} />
        </span>
      </div>
      <ListContainer isOpen={isOpen} style={{ maxHeight }}>
        {options.map((option) => (
          <li
            onClick={onOptionClicked(option)}
            key={option.value}
            style={dropdownStyles.listItem}
          >
            {option.label}
          </li>
        ))}
      </ListContainer>
    </Container>
  );
};

export default Dropdown;
