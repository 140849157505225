import React from "react";
import {
  Box,
  Typography,
  Button,
  SvgIcon,
  Popover,
  Tabs,
  Tab,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { Offering } from "../../../../../types/Offerings";

import useAssetOtherInfoFacade from "./useAssetOtherInfoFacade";

import InfoPanel from "./components/InfoPanel/InfoPanel";

import celebrity_main from "../../../../../assets/images/celebrity_main.png";
import celebrity_1 from "../../../../../assets/images/celebrity_1.png";
import celebrity_2 from "../../../../../assets/images/celebrity_2.png";
import celebrity_3 from "../../../../../assets/images/celebrity_3.png";
import celebrity_4 from "../../../../../assets/images/celebrity_4.png";

export interface AssetOtherInfoProps {
  offer: Offering;
}

const AssetOtherInfo: React.FC<AssetOtherInfoProps> = function (
  props
): JSX.Element {
  const [
    isDesktop,
    firstSectionData,
    secondSectionData,
    shortDesc,
    fulldesc,
    isReadMoreClick,
    setIsReadMoreClick,
    offerName,
    popoverRef,
    popoverAnchor,
    open,
    onButtonClick,
    videoSrc,
    videoPreviewSrc,
  ] = useAssetOtherInfoFacade(props);

  return (
    <div>
      <InfoPanel
        firstSectionData={firstSectionData}
        secondSectionData={secondSectionData}
        isReadMoreClick={isReadMoreClick}
        setIsReadMoreClick={setIsReadMoreClick}
        desc={fulldesc}
        videoSrc={videoSrc}
        videoPreviewSrc={videoPreviewSrc}
      />

      {/*{isDesktop && (
        <Box
          sx={{
            display: "flex",
            placeContent: "center",
            width: "100%",
          }}
        >
          <Button
            disabled={false}
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={onButtonClick}
            sx={{
              backgroundColor: open ? "#1D1D1D" : "#CBCBCB",
              borderRadius: "6px",
              color: open ? "white" : "#0F0F0F",
              padding: "20px",
            }}
          >
            <Typography
              sx={{
                marginRight: "20px",
                fontSize: "19px",
              }}
            >
              FROM MINE TO MARKET: A DIAMOND'S JOURNEY
            </Typography>
            <Popover
              id="mouse-over-popover"
              open={Boolean(popoverAnchor)}
              anchorEl={popoverAnchor}
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
                paddingBottom: "10px",
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "bottom", horizontal: "left" }}
              PaperProps={{
                ref: popoverRef,
                sx: {
                  backgroundColor: "#1D1D1D !important",
                  maxWidth: "fit-content",
                  padding: "20px",
                  position: "relative",
                  overflow: "visible",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "417px",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  <img src={celebrity_1} />
                  <img src={celebrity_2} />
                  <img src={celebrity_3} />
                  <img src={celebrity_4} />
                </Box>
                <Box
                  sx={{
                    height: "417px",
                  }}
                >
                  <img src={celebrity_main} />
                </Box>
              </Box>
            </Popover>
            <SvgIcon
              sx={{
                marginLeft: "1px",
                height: "20px",
                width: "20px",
                fontSize: "20px",
                lineHeight: "20px",
              }}
            >
              <ArrowForwardIos />
            </SvgIcon>
          </Button>
        </Box>
      )}*/}
    </div>
  );
};

export default AssetOtherInfo;
