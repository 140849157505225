import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../../utils/theme";

export const useMobileStyles: () => Classes = makeStyles(() =>
  createStyles(
    mixStyleRules(commonStyles, {
      numberField: {
        width: "240px",
        height: "44px",
        paddingBottom: "0",
        "& label": {
          marginRight: "15px",
        },
        "& .MuiInputBase-input": {
          height: "44px !important",
          padding: "0 14px",
          fontSize: "18px",
        },
      },
    })
  )
);
