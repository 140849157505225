import { Box, useMediaQuery } from "@mui/material";
import { CustomButton } from "components/UIComponents/Button";
import { classes } from "pages/Vip/components/styles";
import { SuccessScreen } from "pages/Vip/components/SuccessScreen";
import { FC, useEffect } from "react";

export const SuccessModal: FC<{
  visible: boolean;
  setModalVisible: (x: boolean) => void;
}> = ({ visible, setModalVisible }) => {
  const isMobileScreen = useMediaQuery("(max-width:776px)");

  useEffect(() => {
    visible &&
      setTimeout(() => {
        setModalVisible(false);
      }, 5000);
  }, [visible]);

  const isAuth = !!localStorage.getItem("rppToken");

  return (
    <Box
      sx={classes.modalWrapper(visible)}
      onClick={() => setModalVisible(false)}
    >
      <Box
        sx={classes.modal(isMobileScreen, visible)}
        onClick={e => e.stopPropagation()}
      >
        <Box sx={classes.btnContainer(isMobileScreen)}>
          <CustomButton
            btnStyle={"iconBtn"}
            onClick={() => setModalVisible(false)}
          >
            CLOSE
          </CustomButton>
        </Box>
        <SuccessScreen isAuth={isAuth} />
      </Box>
    </Box>
  );
};
