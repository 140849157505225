import styles from "./AscotRace.module.scss";
import { ASCOT_RACE_DATA } from "./constants";

const AscotRace = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <h3 className={styles.title}>Step into the Winner’s Circle</h3>
        <ul className={styles.list}>
          {ASCOT_RACE_DATA.map(({ id, Icon, text }) => {
            return (
              <li key={id} className={styles.listItem}>
                <div>
                  <Icon />
                </div>
                <span className={styles.listItemText}>{text}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.imagesWrapper}>
        <div className={styles.firstImageContainer}>
          <picture>
            <source
              media="(min-width: 993px)"
              srcSet="/images/ascotRace2nd.jpg"
            />
            <source
              media="(max-width: 992px)"
              srcSet="/images/ascotRace2ndMobile.jpg"
            />
            <img
              className={styles.firstImage}
              src="/images/ascotRace2ndMobile.jpg"
              alt="jockey"
            />
          </picture>
        </div>
        <div className={styles.secondImageContainer}>
          <img
            src="/images/ascotRace1st.jpg"
            alt="Jockey Antonio Fresu"
            className={styles.secondImage}
          />
        </div>
      </div>
    </div>
  );
};

export default AscotRace;
