import { Box, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";

import { SuccessModal } from "components/Footer/SuccessModal/SuccessModal";
import { CustomButton } from "components/UIComponents/Button";
import BaseLayout from "layout/BaseLayout/baseLayout";
import { TiaraModal } from "./components/TiaraModal";
import TiaraAsset from "pages/Dashboard/components/TiaraAsset/TiaraAsset";
import JoinForm from "pages/NewAssests/Assets/components/JoinForm/JoinForm";
import LuxusBigLogo from "assets/icons/LuxusBigLogo";
import GarrardBigLogo from "assets/icons/GarrardBigLogo";

import stylesTiara from "./styles.module.scss";
import { icons } from "pages/Derby/Derby.data";
import { classes } from "pages/Derby/styles";
import { MAIN_URL, ROUTES } from "utils/constants";

export const Tiara = () => {
  const isMobile = useMediaQuery("(max-width: 530px)");
  const isTablet = useMediaQuery("(max-width: 880px)");
  const isWideScreen = useMediaQuery("(min-width:2440px)");

  const [modalVisible, setModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const changeModalVisible = () => {
    setModalVisible((prev) => !prev);
  };

  return (
    <BaseLayout
      pageName="Tiara page"
      pageUri={`${MAIN_URL}${ROUTES.TIARA}`}
      removeForm={true}
    >
      <Box
        sx={classes.heroContainer(isTablet, isMobile)}
        style={{ overflow: "hidden" }}
      >
        <Typography
          sx={{
            ...classes.title(isTablet),
            zIndex: 11,
            testTransform: "uppercase",
          }}
        >
          What if you could own a piece of history in the making?
        </Typography>
        {isTablet && <Box sx={classes.line} />}
        <iframe
          title="Tiara"
          src="https://player.vimeo.com/video/905988828?h=17ad83c8c3&byline=0&autoplay=1&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1"
          width="3840"
          height="2160"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            overflow: "hidden",
            overflowX: "hidden",
            overflowY: "hidden",
            zIndex: 1,
            height: isTablet ? "100%" : "",
            width: isTablet ? "" : "100%",
            position: "absolute",
            top: isWideScreen ? "-50%" : isTablet ? "0px" : "-850px",
          }}
        ></iframe>
      </Box>
      <Box
        sx={classes.bodyWrapper(isTablet)}
        style={{
          zIndex: 11,
          position: "relative",
        }}
      >
        <Box sx={classes.bodyContainer(isTablet)}>
          <div>
            <Typography
              sx={{
                ...classes.text(isTablet),
                fontFamily: "Futura PT Light",
                fontSize: "22px",
              }}
            >
              <strong>The Tiara</strong> <br />
              An Exclusive LUXUS Investment Opportunity <br />
            </Typography>
            <Typography
              sx={{
                ...classes.text(isTablet),
                fontFamily: "Futura PT Light",
                fontSize: "22px",
                marginTop: "10px",
              }}
            >
              Invest in a private offering of a masterpiece, <br />
              meticulously designed and crafted by Garrard, <br />
              Royal Jewelers with a celebrated legacy since 1735. <br />
            </Typography>
          </div>
          <div>
            <CustomButton
              btnStyle={"iconBtnPrimaryBackground"}
              onClick={changeModalVisible}
            >
              INVEST NOW
            </CustomButton>
            <p className={stylesTiara.ps}>For Accredited Investors Only</p>
          </div>
          <TiaraAsset isShowMoreUpcoming={false} isWithOneButton={true} />
        </Box>
        <div className={stylesTiara.logoSection}>
          <div className={stylesTiara.luxusLogoContainer}>
            <LuxusBigLogo />
          </div>
          <p className={stylesTiara.logoSectionText}>DESIGNED BY:</p>
          <div>
            <div className={stylesTiara.garrardLogoContainer}>
              <GarrardBigLogo />
            </div>
          </div>
        </div>
        <Typography sx={classes.title(isTablet)}>
          AT THE INTERSECTION
          <br /> OF FINANCE & LUXURY
        </Typography>
        <Box component={"ul"} sx={classes.iconList(isTablet)}>
          {icons.map((link) => (
            <Box component={"li"} key={link[0]}>
              <Box
                component={"img"}
                src={link[0]}
                srcSet={`${link[0]} 2000w, ${link[1]} 2001w`}
                alt={link[0].split("/").pop()}
                sx={classes.icon(isTablet)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <div className={stylesTiara.formContainer}>
        <h2 className={stylesTiara.title}>JOIN OUR NEWSLETTER</h2>
        <h6 className={stylesTiara.text}>
          Subscribe to our newsletter for insights, updates and more
        </h6>
        <JoinForm
          pageName="Tiara page"
          pageUri={`${MAIN_URL}${ROUTES.TIARA}`}
          buttonText="SUBSCRIBE"
        />
      </div>
      <TiaraModal visible={modalVisible} changeVisible={changeModalVisible} />
      <SuccessModal
        visible={successModalVisible}
        setModalVisible={setSuccessModalVisible}
      />
    </BaseLayout>
  );
};
