export const EyeIcon = ({ color = "white" }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9033 7.59379C14.3969 4.65463 11.4147 2.66602 7.99998 2.66602C4.58526 2.66602 1.60221 4.65602 0.0966515 7.59407C0.0331076 7.71977 0 7.85864 0 7.99949C0 8.14033 0.0331076 8.27921 0.0966515 8.4049C1.60304 11.3441 4.58526 13.3327 7.99998 13.3327C11.4147 13.3327 14.3978 11.3427 15.9033 8.40463C15.9669 8.27893 16 8.14006 16 7.99921C16 7.85836 15.9669 7.71949 15.9033 7.59379ZM7.99998 11.9993C7.20886 11.9993 6.4355 11.7648 5.7777 11.3252C5.11991 10.8857 4.60722 10.261 4.30447 9.53008C4.00172 8.79918 3.9225 7.99491 4.07684 7.21899C4.23118 6.44306 4.61215 5.73033 5.17156 5.17092C5.73097 4.61151 6.4437 4.23055 7.21962 4.07621C7.99555 3.92187 8.79981 4.00108 9.53072 4.30383C10.2616 4.60658 10.8863 5.11927 11.3259 5.77707C11.7654 6.43486 12 7.20822 12 7.99935C12.0002 8.52471 11.897 9.04497 11.696 9.53039C11.4951 10.0158 11.2005 10.4569 10.829 10.8283C10.4575 11.1998 10.0164 11.4945 9.53102 11.6954C9.0456 11.8963 8.52534 11.9996 7.99998 11.9993ZM7.99998 5.33268C7.76197 5.33601 7.52548 5.37142 7.29693 5.43796C7.48532 5.69398 7.57573 6.00904 7.55175 6.326C7.52777 6.64296 7.391 6.94083 7.16623 7.1656C6.94147 7.39036 6.6436 7.52714 6.32664 7.55112C6.00968 7.57509 5.69462 7.48469 5.4386 7.29629C5.29281 7.8334 5.31913 8.4027 5.51384 8.92407C5.70856 9.44544 6.06187 9.89262 6.52404 10.2027C6.98622 10.5127 7.53399 10.67 8.09025 10.6525C8.64652 10.6349 9.18327 10.4433 9.62495 10.1047C10.0666 9.76611 10.391 9.29752 10.5524 8.7649C10.7139 8.23228 10.7042 7.66245 10.5248 7.13562C10.3454 6.60878 10.0053 6.15147 9.55238 5.82805C9.09946 5.50462 8.55653 5.33137 7.99998 5.33268Z"
      fill={color}
    />
  </svg>
);
