import { themeData } from "../../../../../../../utils/theme";

export const commonStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "15px",
    width: "55%",
    "& .MuiInputBase-input": { textAlign: "center" },
  },
  firstField: {
    width: "80px",
    height: "43px",
  },
  dash: {
    borderTop: "2px solid black",
    height: "25px",
    width: "5px",
    alignSelf: "end",
  },
  secondField: {
    width: "80px",
    height: "43px",
  },
  thirdField: {
    width: "90px",
    height: "43px",
  },
  iconBtn: {
    height: "52px",
  },
  errorText: {
    color: themeData.ErrorTextColor,
    fontSize: "0.75rem",
    lineHeight: "1.66",
    marginTop: "4px",
    marginX: "14px",
    marginBottom: "0px",
  },
};

export type ClassesNames = keyof typeof commonStyles;
