const UserIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <path
        d="M7.5 17.9043C9.98531 17.9043 12 15.8896 12 13.4043C12 10.919 9.98531 8.9043 7.5 8.9043C4.97344 8.9043 3 10.8777 3 13.4043C3 15.8887 4.97344 17.9043 7.5 17.9043ZM7.5 11.1543C8.74078 11.1543 9.75 12.1635 9.75 13.4043C9.75 14.6451 8.74219 15.6543 7.5 15.6543C6.25781 15.6543 5.25 14.6465 5.25 13.4043C5.25 12.1621 6.25781 11.1543 7.5 11.1543ZM9 19.4043H6C2.68641 19.4043 0 22.0902 0 25.4043C0 26.2326 0.671719 26.9043 1.5 26.9043H13.5C14.3283 26.9043 15 26.2326 15 25.4043C15 22.0902 12.3141 19.4043 9 19.4043ZM2.32547 24.6543C2.67375 22.9434 4.18922 21.6543 6 21.6543H9C10.8108 21.6543 12.3263 22.9448 12.6745 24.6543H2.32547ZM27.375 2.9043H10.125C8.67656 2.9043 7.5 4.0818 7.5 5.5293V6.6543C7.5 7.27539 8.00391 7.7793 8.625 7.7793C9.24609 7.7793 9.75 7.27539 9.75 6.6543V5.5293C9.75 5.32277 9.91847 5.1543 10.125 5.1543H27.375C27.5815 5.1543 27.75 5.32277 27.75 5.5293V19.7793C27.75 19.9858 27.5815 20.1543 27.375 20.1543H16.5C15.8789 20.1543 15.375 20.6582 15.375 21.2793C15.375 21.9004 15.8813 22.4043 16.5 22.4043H27.375C28.8225 22.4043 30 21.2268 30 19.7793V5.5293C30 4.0818 28.8234 2.9043 27.375 2.9043ZM15.3797 10.7324C14.9402 10.293 14.2266 10.293 13.7869 10.7324C13.3472 11.1719 13.3484 11.8846 13.7879 12.3243L17.1619 15.7002C17.4234 15.9215 17.7094 16.0293 18 16.0293C18.2906 16.0293 18.5766 15.9204 18.7964 15.7007L23.25 11.2434V12.2793C23.25 12.9027 23.7563 13.4043 24.375 13.4043C24.9937 13.4043 25.5 12.9027 25.5 12.2793V8.5293C25.5 7.91055 24.9984 7.4043 24.375 7.4043H20.625C20.0063 7.4043 19.5 7.91055 19.5 8.5293C19.5 9.14805 20.0039 9.6543 20.625 9.6543H21.6591L18 13.3105L15.3797 10.7324Z"
        fill="#7B7B8F"
      />
    </svg>
  );
};

export default UserIcon;
