import { createStyles, makeStyles } from "@mui/styles";
import mixStyleRules from "../../../../../../utils/mixStyleRules";
import { commonStyles } from "./common";
import type { Classes } from "./";
import { themeData } from "../../../../../../utils/theme";

export const useTabletLandscapeStyles: (props?: any) => Classes = makeStyles(
  () =>
    createStyles(
      mixStyleRules(commonStyles, {
        container: {
          borderRadius: "4.58px",
          padding: "25px 15px 0",
          width: "100%",
          maxWidth: "263px",
          height: "245px",
        },
        title: {
          fontSize: "20px",
        },
        img: {
          height: "26px",
          width: "43px",
          marginRight: "10px",
        },
        label: {
          fontSize: "15px",
          marginRight: "25px",
        },
        changeBtn: {
          fontSize: "15px",
        },
        text: {
          fontSize: "15px",
        },
        boldText: {
          fontSize: "15px",
        },
        row: {
          marginBottom: "17px",
          "&:last-child": {
            marginBottom: "0",
          },
        },
      })
    )
);
