type ArrowBottomProps = {
  color?: string;
};

const ArrowBottom = ({ color = "black" }: ArrowBottomProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M7.19313 14.6584C7.63941 15.1047 8.36416 15.1047 8.81044 14.6584L15.6653 7.80359C16.1116 7.35731 16.1116 6.63255 15.6653 6.18627C15.219 5.73999 14.4943 5.73999 14.048 6.18627L8 12.2342L1.95202 6.18984C1.50575 5.74356 0.780988 5.74356 0.334709 6.18984C-0.11157 6.63612 -0.11157 7.36088 0.334709 7.80716L7.18956 14.662L7.19313 14.6584Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowBottom;
