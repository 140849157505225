import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useServerAPI } from "../../../apis";
import { Offering } from "../../../types/Offerings";
import OfferingNumbersFormatter from "../../../utils/OfferingNumbersFormatter";
import useGetDisplayType from "../../../hooks/useGetDisplayType";
import { DisplayTypes } from "../../../types/enums/DisplayTypes";

import {
  sortByFeatured,
  sortByPriceAppreciation,
  sortByTotalAssetsValue,
  sortBySharePrice,
  sortByMinFundedPict,
} from "../../../utils/OffersSorters";

export type SortingTypes =
  | "Featured"
  | "Favorites"
  | "PriceAppreciation"
  | "TotalAssetValue"
  | "SharePrice"
  | "Funded";

const offeringsSorters: Record<
  SortingTypes,
  ((a: Offering, b: Offering) => number) | undefined
> = {
  Featured: sortByFeatured,
  Favorites: undefined,
  PriceAppreciation: sortByPriceAppreciation,
  TotalAssetValue: sortByTotalAssetsValue,
  SharePrice: sortBySharePrice,
  Funded: sortByMinFundedPict,
};

export default function useAssetsFacade(): [
  boolean,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  Offering[],
  Offering[],
  boolean,
  (item: string) => () => void,
  (item: string) => () => void,
  React.Dispatch<React.SetStateAction<SortingTypes | undefined>>,
  string
] {
  const desktop = useGetDisplayType([
    DisplayTypes.Desktop,
    DisplayTypes.TabletLandscape,
    DisplayTypes.TabletPortrait,
  ]);
  const api = useServerAPI();
  const navigate = useNavigate();

  const proceedOfferings = (offerings: Offering[]): Offering[] =>
    offerings.map((offer) => OfferingNumbersFormatter(offer));

  const [isShowMoreCurrent, setIsShowMoreCurrent] = useState(false);
  const [isShowMoreUpcoming, setIsShowMoreUpcoming] = useState(false);
  const [offerings, setOfferings] = useState<Offering[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortType, setSortType] = useState<SortingTypes>();
  const [userFirstName, setUserFirstName] = useState<string>("");

  const isUserAuthorized = !!localStorage.getItem("rppToken");

  const getOfferings = async (): Promise<void> => {
    return api.getOfferings().then((response) => {
      if (response.data.response.items)
        setOfferings(proceedOfferings(response.data.response.items));
    });
  };

  const getUserInfo = async (): Promise<void> => {
    return api
      .getUserProfile()
      .then((response) => {
        if (response.data.response.profile?.firstName) {
          setUserFirstName(response.data.response.profile.firstName);
          return undefined;
        }
        return api.getZohoUserInfo();
      })
      .then((response) => {
        if (!response) return;
        if (response?.First_Name) setUserFirstName(response.First_Name);
      });
  };

  const sortOfferings = (offerings: Offering[]): Offering[] => {
    if (sortType) return offerings.sort(offeringsSorters[sortType]);
    return offerings;
  };

  const getFilteredOfferenigs = function <T extends keyof Offering>(
    property: T,
    value: Offering[T] | Offering[T][]
  ): Offering[] {
    return sortOfferings(
      offerings.filter((offering) => {
        if (typeof value === "object")
          return (value as Offering[T][]).includes(offering[property]);
        return offering[property] === value;
      })
    );
  };

  const getActiveOfferings = () =>
    getFilteredOfferenigs("status", [
      "Active",
      "IOI",
      "SoldOut",
      "soldPrivately",
    ]);

  const getFutureOfferings = () => getFilteredOfferenigs("status", "Future");

  useEffect(() => {
    if (isUserAuthorized) {
      setIsLoading(true);
      Promise.all([getOfferings(), getUserInfo()])
        .then(() => setIsLoading(false))
        .catch(() => {
          setIsLoading(false);
          console.error("Sorry, an error occurs");
        });
    }
  }, [isUserAuthorized]);

  const getOnItemBuyClick = (id: string) => (): void => {
    navigate(`/assetDetails/${id}`);
  };

  const getOnItemIOIClick = (id: string) => (): void => {
    navigate(`/ioiDetails/${id}`);
  };

  return [
    desktop,
    isShowMoreCurrent,
    setIsShowMoreCurrent,
    isShowMoreUpcoming,
    setIsShowMoreUpcoming,
    getActiveOfferings().sort(sortByMinFundedPict),
    getFutureOfferings(),
    isLoading,
    getOnItemBuyClick,
    getOnItemIOIClick,
    setSortType,
    userFirstName,
  ];
}
