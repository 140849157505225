import { Box, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';

import { CloseIcon } from 'assets/icons/close';
import RoyalAscotForm from '../RoyalAscotForm/RoyalAscotForm';
import Indicator from '../Indicator/Indicator';
import RoyalAscotHorses from 'assets/images/royalAscotHorses.png';

import { classes } from 'pages/Derby/components/styles';

import styles from './RoyalAscotModal.module.scss';
import { TRIP_VARIANTS } from '../RoyalAscotForm/ContactsForm/constants';

interface Props {
  isModalVisible: boolean;
  changeVisible: () => void;
}

export const RoyalAscotModal: FC<Props> = ({
  isModalVisible,
  changeVisible,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmissionConfirmed, setIsSubmissionConfirmed] = useState(false);
  const [tripVariant, setTripVariant] = useState<TRIP_VARIANTS>(
    TRIP_VARIANTS.INDIVIDUAL_FULL
  );

  const isMobile = useMediaQuery('(max-width: 575px)');

  return (
    <>
      <Box sx={classes.modalWrapper(isModalVisible)} onClick={changeVisible}>
        <Box
          sx={classes.modal(isModalVisible)}
          onClick={(e) => e.stopPropagation()}
          style={{
            maxHeight: isMobile ? '75vh' : '80vh',
            overflowY: 'auto',
            backgroundColor: 'white',
            padding: '60px 50px 40px',
          }}
        >
          <button className={styles.button} onClick={changeVisible}>
            <CloseIcon color="black" />
          </button>
          {!isSubmissionConfirmed && (
            <Indicator tripVariant={tripVariant} currentStep={currentStep} />
          )}
          {isSubmissionConfirmed ? null : (
            <>
              <h2 className={styles.title}>ROYAL Ascot</h2>
              <img
                className={styles.image}
                src={RoyalAscotHorses}
                alt="Royal Horses"
              />
            </>
          )}
          <RoyalAscotForm
            setTripVariant={setTripVariant}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setIsSubmissionConfirmed={setIsSubmissionConfirmed}
            isSubmissionConfirmed={isSubmissionConfirmed}
          />
        </Box>
      </Box>
    </>
  );
};
